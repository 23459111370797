/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useSelector } from 'react-redux';

import { getMyNode } from '~frontendDucks/clusterSetup/clusterSetup.js';
import {
    findVpnServiceFromDevice, getInterfaceItems, getVpnItems, isFromConfiguration
} from '~frontendDucks/hlcfgEditor/glcfgGettersAndSetters.js';
import { LOOPBACK_NAME } from '~frontendDucks/systemComponents/systemComponents.ts';
import { useNetworkInterfacesQuery } from '~frontendQueries/system/hooks.ts';


export const useIsAnyUnknownInterface = () => {
    // TODO: AK-3661: This feature only works for current node. Could be made to work for both at once.
    const { data: networkInterfacesInfo = [] } = useNetworkInterfacesQuery();
    const interfaces: any = useSelector(getInterfaceItems);
    const vpnItems = useSelector(getVpnItems);
    const clusterNodeSelector = useSelector(getMyNode);
    return !!networkInterfacesInfo?.some(item => {
        if (item.name === LOOPBACK_NAME) {
            return false;
        }
        if (item.name?.startsWith('tun') || item.name?.startsWith('tap')) {
            return !findVpnServiceFromDevice(item.name, vpnItems);
        }
        if (interfaces.find(value => isFromConfiguration({ item: item, value: value, clusterNodeSelector }))) {
            return false;
        }
        return true;
    });
};
