/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBRow,  MDBCardTitle } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { connect } from 'react-redux';

import {
    HTTPS_PORT, HTTP_PORT,
    SERVICE_OPTIONS } from '~frontendConstants/index.js';
import { Input, Switch, Select } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import {
    ACCEPT_ACTION,
    WEB_ACTION,
    PF_SRC_TRANSLATION_MASQUERADE,
    DROP_ACTION,
    TRANSPARENT_RULE_NAME,
    TRANSPARENT_S_RULE_NAME,
    FORCE_ACCEPT_ACTION,
    HLCFG_OFF
} from '~sharedConstants/index.ts';
import {
    STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS,
    STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS } from '~sharedLib/staticHlcfgReferenceUtils.js';
import { netport } from '~sharedLib/Netport/Netport.ts';
import {
    getGlcfgValue,
    getProfileProps,
    getHoneypotWithoutFormating,
    getQoSOptions,
    getVerificationErrors,
    setNormalize,
    getTableSchema
} from '~frontendDucks/hlcfgEditor/index.js';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';

import { Conditions } from './Conditions.tsx';
import { Action } from './Action.tsx';


export const getServiceOptions = ({ fake, honeypotPorts, guiAddressesHTTP }) => {
    if (fake) {
        return [
            ...SERVICE_OPTIONS,
            {
                label: (
                    <Message message={
                        `packetFilter:staticRef.${STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS}.title`}
                    />
                ),
                value: STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS,
                tooltipValues: (honeypotPorts || []).map(item => `tcp:${netport(item)}`)
            },
            {
                label: (
                    <Message message={
                        `packetFilter:staticRef.${STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS}.title`}
                    />
                ),
                value: STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS,
                tooltipValues: (guiAddressesHTTP ?
                    [ HTTPS_PORT, HTTP_PORT ] : [ HTTPS_PORT ]).map(item => `tcp:${netport(item)}`)
            },
        ];
    }
    return SERVICE_OPTIONS;
};


export const defaultOptions = [ {
    label: PF_SRC_TRANSLATION_MASQUERADE,
    value: PF_SRC_TRANSLATION_MASQUERADE,
    objectValue: PF_SRC_TRANSLATION_MASQUERADE
} ];

export const getWebProfileOptions = (profiles) => Object.keys(profiles).map(item => {
    return { label: profiles[item].name, value: profiles[item].id };
});


/**
 * Packet filter rule detail view.
 *
 * @param {object} props
 * @param {object} [props.data] - data to show about rule
 * @returns {Component}
 */

const makeMapStateToProps = () => {
    const mapStateToProps = (state, { uuid  }) => {
        return {
            schemas: getTableSchema(state, uuid),
            verificationErrors: getVerificationErrors(state),
            profiles: getProfileProps(state),
            guiAddressesHTTP: getGlcfgValue(state, 'guiAddressesHTTP'),
            honeypot: getHoneypotWithoutFormating(state),
            qosOptions: getQoSOptions(state)
        };
    };
    return mapStateToProps;
};
@withTranslation()
@connect(
    makeMapStateToProps,
    {
        setValue: setNormalize,
    }
)
class RuleDetail extends Component {
    static get propTypes() {
        return {
            setValue: PropTypes.func,
            matches: PropTypes.array,
            rule: PropTypes.object,
            t: PropTypes.func,
            verificationErrors: PropTypes.array,
            profiles: PropTypes.object,
            schemas: PropTypes.object,
            qosOptions: PropTypes.array,
            guiAddressesHTTP: PropTypes.bool,
            honeypot: PropTypes.object,
            smallSpacing: PropTypes.bool,
        };
    }

    doesMatch = (theKey) => {
        const { matches } = this.props;
        return matches.some(match => match === theKey);
    };

    onChangeEvent = ({ value, name }) => {
        const { setValue, rule } = this.props;
        setValue({
            uuid: rule.id,
            key: name,
            value,
        });
    };

    onChangeEventId = ({ value, name, id }) => {
        const { setValue, rule } = this.props;
        setValue({
            uuid: rule.id,
            key: name,
            subkey: id,
            value,
        });
    };

    onChangeDestinationTranslationEvent = ({ name, empty }) => {
        const { setValue, rule } = this.props;
        setValue({
            uuid: rule.id,
            key: name,
            subkey: '__off',
            value: !!empty,
        });
    };


    onActionChange = ({ value, name }) => () => this.onChangeEvent({ value, name });

    getComponentError = (component) => {
        const { verificationErrors, rule } = this.props;
        const errors =  verificationErrors.filter(err => err?.options?.uuid === rule.id);
        const componentError = errors.
            filter(err => err.hlcfgPaths.filter(path => path?.includes(component)).length)[0];
        if (componentError) {
            return componentError?.advancedDesc ?
                [ componentError.title, componentError.desc, componentError.advancedDesc ] :
                [ componentError.title, componentError.desc ];
        }

    };

    render() {
        const { rule, t, profiles, schemas, qosOptions, smallSpacing,
            honeypot, guiAddressesHTTP } = this.props;
        const disabled = Boolean(!rule?.id);
        return (
            <MDBRow className="vertical p-2">

                <MDBCol size="12">
                    <MDBCard>

                        <MDBCardTitle className="nft--title">
                            <MDBRow>
                                <MDBCol lg="4">
                                    <Input
                                        className="dataTableWidget__RowInput"
                                        classNamePrefix="packetFilterSelect"
                                        disabled={rule.fake || disabled || rule[HLCFG_OFF]}
                                        error={this.getComponentError('name')}
                                        formGroupClassName="dataTableWidget__RowInput"
                                        id="packetFilterName"
                                        isName
                                        isRow
                                        name="name"
                                        noWrap
                                        onChange={this.onChangeEvent}
                                        required={rule?.name}
                                        schema={schemas?.name}
                                        value={rule.name}
                                        withoutBorder
                                    />
                                </MDBCol>
                                <MDBCol className="justify-content-around">
                                    <div className="nft--action">
                                        <div className="nft--actionSingle">

                                            <div className="m-1">
                                                <IconWithTooltip
                                                    className={classNames(
                                                        'icon--red',
                                                        { 'icon--textColor': rule.action !== DROP_ACTION ||
                                                        rule[HLCFG_OFF] }
                                                    )}
                                                    iconSize={smallSpacing ? 'md' : 'lg'}
                                                    link
                                                    linkClassName={classNames(
                                                        { 'cursor--auto': rule.fake },
                                                    )}
                                                    name="drop"
                                                    onClick={!rule.fake ? this.onActionChange({
                                                        value: DROP_ACTION, name: 'action' }) : undefined}
                                                    tooltipText={t('packetFilter:drop')}
                                                    withoutTranslation
                                                />
                                            </div>

                                        </div>
                                        <div className="nft--actionSingle">

                                            <div className="m-1">
                                                <IconWithTooltip
                                                    className={classNames(
                                                        'icon--green',
                                                        { 'icon--textColor': (rule.action !== ACCEPT_ACTION &&
                                                            rule.action !== FORCE_ACCEPT_ACTION) ||
                                                        rule[HLCFG_OFF] }
                                                    )}
                                                    iconSize={smallSpacing ? 'md' : 'lg'}
                                                    link
                                                    linkClassName={classNames(
                                                        { 'cursor--auto': rule.fake },
                                                    )}
                                                    name="shield-check-outline"
                                                    onClick={!rule.fake ? this.onActionChange({
                                                        value: ACCEPT_ACTION, name: 'action' }) : undefined}
                                                    tooltipText={t('packetFilter:accept')}
                                                    withoutTranslation
                                                />
                                            </div>

                                        </div>
                                        <div className="nft--actionSingle">
                                            <div className="m-1">
                                                <IconWithTooltip
                                                    className={classNames(
                                                        { 'icon--textColor': rule.action !== WEB_ACTION ||
                                                        rule[HLCFG_OFF] }
                                                    )}
                                                    iconSize={smallSpacing ? 'md' : 'lg'}
                                                    link
                                                    linkClassName={classNames(
                                                        { 'cursor--auto': rule.fake },
                                                    )}
                                                    name="web"
                                                    onClick={!rule.fake ? this.onActionChange({
                                                        value: WEB_ACTION, name: 'action' }) : undefined}
                                                    tooltipText={t('packetFilter:web')}
                                                    withoutTranslation
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol
                                    className="database__reloadIcon"
                                    lg="4"
                                >
                                    <Select
                                        className={'packetFilter__log'}
                                        disabled={rule[HLCFG_OFF]}
                                        id={'log' + rule.id}
                                        isRow
                                        justIcon
                                        name="log"
                                        noDropdownIndicator
                                        noOptionsMessage
                                        noWrap
                                        onChange={this.onChangeEvent}
                                        paste={false}
                                        schema={schemas?.log}
                                        value={rule.log}
                                    />

                                    <Switch
                                        align="left"
                                        checked={rule[HLCFG_OFF]}
                                        className={classNames(
                                            'dataTableWidget__RowInput',
                                            'dataTableWidget__RowInput--sm',
                                            'mt-3'
                                        )}
                                        disabled={disabled}
                                        id="packetFilterActiveSwitch"

                                        mini
                                        name={HLCFG_OFF}
                                        noticeMe={rule[HLCFG_OFF]}
                                        onChange={this.onChangeEvent}
                                        tooltipText={[
                                            TRANSPARENT_RULE_NAME,
                                            TRANSPARENT_S_RULE_NAME
                                        ].includes(
                                            rule.name
                                        ) ? 'packetFilter:source.proxy.active' : ''}
                                        withoutBorder
                                        withoutLabelMargins
                                        withoutMinWidhtHeight
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardTitle>
                    </MDBCard>
                </MDBCol>
                <MDBCol size="12">
                    <Conditions
                        destinationPortOptions={getServiceOptions({ fake: rule.fake,
                            honeypotPorts: honeypot?.ports, guiAddressesHTTP })}
                        disabled={disabled || rule[HLCFG_OFF]}
                        doesMatch={this.doesMatch}
                        getComponentError={this.getComponentError}
                        onChangeEvent={this.onChangeEvent}
                        rule={rule}
                        schemas={schemas}
                        smallSpacing={smallSpacing}
                    />
                </MDBCol>
                <MDBCol size="12">
                    <Action
                        defaultOptions={defaultOptions}
                        disabled={disabled || rule[HLCFG_OFF]}
                        doesMatch={this.doesMatch}
                        getComponentError={this.getComponentError}
                        onChangeDestinationTranslationEvent={this.onChangeDestinationTranslationEvent}
                        onChangeEvent={this.onChangeEvent}
                        onChangeEventId={this.onChangeEventId}
                        profilesOptions={getWebProfileOptions(profiles)}
                        qosOptions={qosOptions}
                        rule={rule}
                        schemas={schemas}
                        smallSpacing={smallSpacing}
                    />
                </MDBCol>
                <MDBCol
                    size="12"
                >
                    <MDBCard>
                        <MDBCardBody className={smallSpacing ? 'px-2 pb-1 pt-0' : 'pb-1 pt-0'}>
                            <MDBRow>

                                <MDBCol
                                    className={classNames({
                                        'packetFilter__ruleDetail--match': this.doesMatch('comment') })}
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <Input
                                        className={classNames(
                                            { 'mb-1': smallSpacing }
                                        )}
                                        disabled={disabled || rule[HLCFG_OFF] || rule.fake}
                                        id="packetFilterComment"
                                        label={t('packetFilter:comment')}
                                        name="comment"
                                        onChange={rule.fake ? null : this.onChangeEvent}
                                        rows={3}
                                        type="textarea"
                                        value={rule.fake ? t(rule.comment) : rule.comment}
                                    />
                                </MDBCol>

                            </MDBRow>

                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>

        );
    }
}


export default RuleDetail;
