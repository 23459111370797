/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { VPN_USER_QR_CODE } from '~frontendConstants/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { QrCodeImage } from '~frontendRoot/components/QrCodeImage.tsx';
import { getVpnUserQrcode, getVpnUserQrcodeFilename, getIsLoading, getIsError } from '~frontendRoot/ducks/vpnUserQrcode/index.ts';


const VpnUserQrCode = () => {
    const dispatch = useDispatch();
    const url = useSelector(getVpnUserQrcode);
    const loading = useSelector(getIsLoading);
    const error = useSelector(getIsError);


    const filename = useSelector(getVpnUserQrcodeFilename);


    const close = () => {
        dispatch(setModalState({ modal: VPN_USER_QR_CODE, value: false, clearValues: true }));

    };

    return (
        <Modal
            body={
                <>
                    {error ? <p className="infobox--alert infobox">{error.message}</p> :
                        <QrCodeImage
                            filename={filename}
                            loading={loading}
                            scale={6}
                            url={url}
                        />
                    }
                </>
            }
            exitHandle={close}
            headerText={'widgets:Vpn.qr.header'}
            modalOpen={true}
            negativeAsPrimary
            position="top-right"
            positiveResponse={close}
            positiveText={'widgets:global.close'}
            withoutNegative
        />
    );
};

export default VpnUserQrCode;
