/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Utility functions for DOM manipulation. Does not make sense to use on the
 * server as there is no document object. It is an amphibious module because
 * it is shared between angular and offline applications.
 *
 * @name dom
 */
const dom = {};

/**
 * Tests whether given element is currently a child of the document node.
 *
 * @memberof dom
 * @param {Element} element
 * @returns {boolean}
 */
dom.isInDocument = function(element) {
    return document.contains ?
        document.contains(element) :
        document.body && document.body.contains(element);
};

dom.addChild = function(container, tagName, className) {
    const element = document.createElement(tagName);
    if (className) {
        element.className = className;
    }
    container.appendChild(element);
    return element;
};

dom.removeAllChildren = function(element) {
    while (element && element.lastChild) {
        element.removeChild(element.lastChild);
    }
};

dom.setText = function(id, content) {
    const element = document.getElementById(id);
    if (!element) {
        return;
    }
    element.textContent = content;
};

dom.addText = function(container, content) {
    const element = document.createTextNode(
        typeof content === 'undefined' ?
            ' ' :
            content
    );
    container.appendChild(element);
    return element;
};

dom.getBtnClass = function(isPrimary, isSelected) {
    return 'btn btn-sm ' +
        (isPrimary ? 'btn-primary ' : 'btn-default ') +
        (isSelected ? 'active ' : '');
};

const getClassNames = element =>
    element.className.split(' ');

dom.addClass = (element, className) => {
    if (getClassNames(element).includes(className)) {
        return;
    }
    element.className += ' ' + className;
};

dom.removeClass = (element, className) => {
    if (!getClassNames(element).includes(className)) {
        return;
    }
    element.className = getClassNames(element).filter(tempClass =>
        tempClass !== className).join(' ');
};

dom.dropdownToggle = function(elContainer, elToggle) {
    let isOpen = false;
    const toggleOpen = function() {
        isOpen = !isOpen;
        if (isOpen) {
            document.addEventListener('click', documentClick);
            dom.addClass(elContainer, 'open');
        } else {
            dom.removeClass(elContainer, 'open');
            document.removeEventListener('click', documentClick);
        }
    };
    const documentClick = function(event) {
        let target = event.target;
        while (target) {
            if (target === elContainer || target === elToggle) {
                return;
            }
            target = target.parentElement;
        }
        if (isOpen) {
            toggleOpen();
        }
    };
    elContainer.addEventListener('destroyed', function() {
        document.removeEventListener('click', documentClick);
    });
    elToggle.onclick = toggleOpen;
};

export default dom;
