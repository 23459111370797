/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBRow, MDBCardTitle, MDBCol } from 'mdbreact';
import classNames from 'classnames';
import Svg from 'react-inlinesvg';

import { Icon, Input, Select } from '~frontendComponents/Generic/index.js';
import {
    getIpsecServiceGetterByUuid,
    getTableSchema,
    setNormalize
} from '~frontendDucks/hlcfgEditor/index.js';
import { setTimeoutComponentFunction, setTimeoutComponentValidator } from '~frontendLib/timeUtils.ts';
import logo from '~frontendRoot/img/svg/logo.svg';
import { selectOptionsListWanLan, getInternalExternal } from
    '~frontendScenes/Configuration/scenes/Network/scenes/Network/components/NetworkDatatable/components/Row/Row.tsx';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice/index.js';
import { BRIDGE_IFACE_TYPE, VLAN_IFACE_TYPE, VPN_IFACE_TYPE } from '~frontendRoot/constants/index.js';

import Phase1 from './Phase1.tsx';
import Phase2 from './Phase2.tsx';
import IpsecStatus from './IpsecStatus.tsx';


const makeMapStateToProps = () => {
    const ipsecServiceUuid = getIpsecServiceGetterByUuid();

    const mapStateToProps = (state, { uuid }) => {
        return {
            data: ipsecServiceUuid(state, uuid),
            schemas: getTableSchema(state, uuid || 'ipsec:'),

        };
    };
    return mapStateToProps;
};
@withTranslation()
@connect(
    makeMapStateToProps,
    {
        setValue: setNormalize,
    }
)
class IpsecSettings extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object,
            uuid: PropTypes.string,
            t: PropTypes.func,
            setValue: PropTypes.func,
            schemas: PropTypes.object,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            shownSettings: true,
        };

    }

    changeValue = ({ value, name }) => {
        const { setValue, uuid } = this.props;
        setValue({ value, uuid, key: name });
    };

    hideSettings = () => {
        const { shownSettings } = this.state;
        this.setState({
            shownSettings: !shownSettings
        });
    };

    changeValueWanLan = ({ value }) => {
        const { setValue, uuid } = this.props;
        switch (value) {
        case 'wan': {
            setValue({ value: true, uuid, key: 'isExternal' });
            setValue({ value: false, uuid, key: 'isInternal' });
            break;
        }
        case 'lan': {
            setValue({ value: false, uuid, key: 'isExternal' });
            setValue({ value: true, uuid, key: 'isInternal' });
            break;
        }
        case 'wanLan': {
            setValue({ value: true, uuid, key: 'isExternal' });
            setValue({ value: true, uuid, key: 'isInternal' });
            break;
        }
        case 'unspecific': {
            setValue({ value: false, uuid, key: 'isExternal' });
            setValue({ value: false, uuid, key: 'isInternal' });
            break;
        }
        default: throw new Error('Unknown state');


        }
    };


    setTimeoutFunc = ({ value, key }) => {
        const { setValue, uuid, } = this.props;
        setValue({ value, uuid, key });
    };

    setTimeout = setTimeoutComponentFunction({ setValue: this.setTimeoutFunc });

    timeoutValidator = setTimeoutComponentValidator(this.props.t);


    render() {
        const { t, data,
            schemas, uuid } = this.props;
        const { shownSettings } = this.state;
        const {
            leftNode, rightNode, leftSubnets, rightSubnets, presharedKey,
            __off, isInternal, isExternal, isLeftMyNode, isRightMyNode,
        } = data;

        return (
            <>
                <MDBCol
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <MDBCard className="card--withoutMH pb-0">
                        <MDBCardTitle className={classNames(
                            'cardHide__title',
                            { 'cardHide__title--noBorderBottom': shownSettings },
                        )}
                        >
                            <div
                                className="clicable"
                                onClick={this.hideSettings}
                            >
                                {t('widgets:Vpn.header.settings')}
                                <Icon
                                    name={shownSettings ? 'chevron-up' : 'chevron-down'}
                                />
                            </div>
                        </MDBCardTitle>

                        <MDBCardBody className={classNames('cardHide__body', 'pb-0',
                            { 'cardHide__body--hide': !shownSettings },
                            { 'cardHide__title--borderBottom': shownSettings })}
                        >
                            <MDBRow>
                                <MDBCol>

                                    {isRightMyNode ?
                                        <Select
                                            disabled={__off}
                                            id={'leftNode'}
                                            isCreatable
                                            isMulti
                                            label={t('differs:tables.ipsec.leftNode')}
                                            name="leftNode"
                                            noDropdownIndicator
                                            noOptionsMessage
                                            onChange={this.changeValue}
                                            schema={schemas.leftNode}
                                            value={leftNode}
                                        /> :
                                        <NetworkInterfaceDevice
                                            disabled={__off}
                                            exceptions={[ VPN_IFACE_TYPE, BRIDGE_IFACE_TYPE ]}
                                            id={'leftNode'}
                                            iface={leftNode}
                                            isCreatable
                                            isMulti
                                            label={t('differs:tables.ipsec.leftNode')}
                                            name="leftNode"
                                            noDropdownIndicator
                                            noOptionsMessage
                                            onChange={this.changeValue}
                                            schema={schemas.leftNode}
                                        />
                                    }
                                    {isLeftMyNode ?
                                        <Svg
                                            className={classNames(
                                                'p-1',
                                                'ipsec--logo',
                                            )}
                                            height={'32'}
                                            src={logo}
                                            width="32"
                                        /> :
                                        <>

                                            <Icon
                                                className={classNames(
                                                    'p-1',
                                                    'ipsec--logo icon--textColor',
                                                )}
                                                name="desktop-classic"
                                            />
                                        </>}
                                    <Select
                                        disabled={__off}
                                        id={'leftSubnets'}
                                        isCreatable
                                        isMulti
                                        label={t('differs:tables.ipsec.leftSubnets')}
                                        name="leftSubnets"
                                        noDropdownIndicator
                                        noOptionsMessage
                                        onChange={this.changeValue}
                                        schema={schemas.leftSubnets}
                                        value={leftSubnets}
                                    />

                                    <Select
                                        disabled={Boolean(__off || isLeftMyNode || !leftNode?.[0])}
                                        id={'wanLanLeft'}
                                        label={t('widgets:Vpn.wanLan.title')}
                                        name="wanLanName"
                                        onChange={this.changeValueWanLan}
                                        options={selectOptionsListWanLan}
                                        paste={false}
                                        value={getInternalExternal(isLeftMyNode ? undefined : isInternal,
                                            isLeftMyNode ? undefined : isExternal)}
                                    />

                                </MDBCol>
                                <MDBCol>
                                    <Input
                                        className="z-index-5"
                                        generate
                                        id={'presharedKey'}
                                        label={t('differs:tables.ipsec.presharedKey')}
                                        name="presharedKey"
                                        onChange={this.changeValue}
                                        paste
                                        resize
                                        rows={3}
                                        type="textarea"
                                        value={presharedKey}
                                    />

                                </MDBCol>

                                <MDBCol>
                                    {isLeftMyNode ?
                                        <Select
                                            disabled={__off}
                                            id={'rightNode'}
                                            isCreatable
                                            isMulti
                                            label={t('differs:tables.ipsec.rightNode')}
                                            name="rightNode"
                                            noDropdownIndicator
                                            noOptionsMessage
                                            onChange={this.changeValue}
                                            schema={schemas.rightNode}
                                            value={rightNode}
                                        /> :
                                        <NetworkInterfaceDevice
                                            disabled={__off}
                                            exceptions={[ VLAN_IFACE_TYPE, BRIDGE_IFACE_TYPE ]}
                                            id={'rightNode'}
                                            iface={rightNode}
                                            isCreatable
                                            isMulti
                                            label={t('differs:tables.ipsec.rightNode')}
                                            name="rightNode"
                                            noDropdownIndicator
                                            noOptionsMessage
                                            onChange={this.changeValue}
                                            schema={schemas.rightNode}
                                        />
                                    }
                                    {isRightMyNode ?
                                        <Svg
                                            className={classNames(
                                                'p-1',
                                                'ipsec--logo',
                                            )}
                                            height={'32'}
                                            src={logo}
                                            width="32"
                                        /> :
                                        <Icon
                                            className={classNames(
                                                'p-1',
                                                'ipsec--otherDevice icon--textColor',
                                            )}
                                            name="desktop-classic"
                                        />}
                                    <Select
                                        disabled={__off}
                                        id={'rightSubnets'}
                                        isCreatable
                                        isMulti
                                        label={t('differs:tables.ipsec.rightSubnets')}
                                        name="rightSubnets"
                                        noDropdownIndicator
                                        noOptionsMessage
                                        onChange={this.changeValue}
                                        schema={schemas.rightSubnets}
                                        value={rightSubnets}
                                    />
                                    <Select
                                        disabled={Boolean(__off || isRightMyNode || !rightNode?.[0])}
                                        id={'wanLanRight'}
                                        label={t('widgets:Vpn.wanLan.title')}
                                        name="wanLanName"
                                        onChange={this.changeValueWanLan}
                                        options={selectOptionsListWanLan}
                                        paste={false}
                                        value={getInternalExternal(isRightMyNode ? undefined : isInternal,
                                            isRightMyNode ? undefined : isExternal)}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol
                    lg="12"
                    md="12"
                    sm="12"
                >

                    <Phase1
                        changeValue={this.changeValue}
                        data={data}
                        schemas={schemas}
                        setTimeout={this.setTimeout}
                        timeoutValidator={this.timeoutValidator}
                    />
                </MDBCol>
                <MDBCol
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <Phase2
                        changeValue={this.changeValue}
                        data={data}
                        schemas={schemas}
                        setTimeout={this.setTimeout}
                        timeoutValidator={this.timeoutValidator}
                    />
                </MDBCol>

                <MDBCol
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <IpsecStatus uuid={uuid} />
                </MDBCol>
            </>
        );
    }
}

export default IpsecSettings;
