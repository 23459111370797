/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function pipe<T1, T2>(
    fn1: (x: T1, idx?: number) => T2,
): (x: T1, idx?: number) => T2
export function pipe<T1, T2, T3>(
    fn1: (x: T1, idx?: number) => T2,
    fn2: (x: T2, idx?: number) => T3
): (x: T1, idx?: number) => T3
export function pipe<T1, T2, T3, T4>(
    fn1: (x: T1, idx?: number) => T2,
    fn2: (x: T2, idx?: number) => T3,
    fn3: (x: T3, idx?: number) => T4,
): (x: T1, idx?: number) => T4
export function pipe<T1, T2, T3, T4, T5>(
    fn1: (x: T1, idx?: number) => T2,
    fn2: (x: T2, idx?: number) => T3,
    fn3: (x: T3, idx?: number) => T4,
    fn4: (x: T4, idx?: number) => T5,
): (x: T1, idx?: number) => T5
export function pipe<T1, T2, T3, T4, T5, T6>(
    fn1: (x: T1, idx?: number) => T2,
    fn2: (x: T2, idx?: number) => T3,
    fn3: (x: T3, idx?: number) => T4,
    fn4: (x: T4, idx?: number) => T5,
    fn5: (x: T5, idx?: number) => T6,
): (x: T1, idx?: number) => T6
export function pipe<T1, T2, T3, T4, T5, T6, T7>(
    fn1: (x: T1, idx?: number) => T2,
    fn2: (x: T2, idx?: number) => T3,
    fn3: (x: T3, idx?: number) => T4,
    fn4: (x: T4, idx?: number) => T5,
    fn5: (x: T5, idx?: number) => T6,
    fn6: (x: T6, idx?: number) => T7,
): (x: T1, idx?: number) => T7
export function pipe<T1, T2, T3, T4, T5, T6, T7, T8>(
    fn1: (x: T1, idx?: number) => T2,
    fn2: (x: T2, idx?: number) => T3,
    fn3: (x: T3, idx?: number) => T4,
    fn4: (x: T4, idx?: number) => T5,
    fn5: (x: T5, idx?: number) => T6,
    fn6: (x: T6, idx?: number) => T7,
    fn7: (x: T7, idx?: number) => T8,
): (x: T1, idx?: number) => T8
export function pipe<T1, T2, T3, T4, T5, T6, T7, T8, T9, TREST extends any[]>(
    fn1: (x: T1, idx?: number) => T2,
    fn2: (x: T2, idx?: number) => T3,
    fn3: (x: T3, idx?: number) => T4,
    fn4: (x: T4, idx?: number) => T5,
    fn5: (x: T5, idx?: number) => T6,
    fn6: (x: T6, idx?: number) => T7,
    fn7: (x: T7, idx?: number) => T8,
    fn8: (x: T8, idx?: number) => T9,
    ...args: TREST
): (x: T1, idx?: number) => T9

export function pipe<T1, T2, T3, T4, T5, T6, T7, T8, T9, TREST extends any[]>(
    fn1: (x: T1, idx?: number) => T2,
    fn2: (x: T2, idx?: number) => T3,
    fn3: (x: T3, idx?: number) => T4,
    fn4: (x: T4, idx?: number) => T5,
    fn5: (x: T5, idx?: number) => T6,
    fn6: (x: T6, idx?: number) => T7,
    fn7: (x: T7, idx?: number) => T8,
    fn8: (x: T8, idx?: number) => T9,
    fn9: (x: T9, idx?: number) => unknown,
    ...args: TREST
): (x: T1, idx?: number) => unknown


export function pipe(
    ...args: 'Input functions do not match their input/output types'[]
): (x, idx?: number) => never

/**
 * A function that pipes the output of one function into the input of another function.
 */
// eslint-disable-next-line func-style
export function pipe(...fns) {
    return (inputVal, idx) => fns.reduce((compositeFn, singleFn) => singleFn(compositeFn, idx), inputVal);
}
