/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useMemo } from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Icon, InputSearch, Switch } from '~frontendComponents/Generic/index.js';
import {
    setNormalize,
} from '~frontendDucks/hlcfgEditor/index.js';
import { getVpnServiceGetterByUuid } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { useBoolean, useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';

import VpnUserTable from './components/VpnUserTable/index.ts';
import { VpnPushToUserContext } from './components/VpnUserTable/Row/Row.tsx';


const VpnPushToUser = ({ uuid }: { uuid: string }) => {

    const [ shownSettings, setShownSettings ] = useBoolean();
    const [ searchValue, setSearchValue ] = useString();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const vpnServiceGetter = useMemo(getVpnServiceGetterByUuid, []);
    const data = useSelector((state) => vpnServiceGetter(state));

    const changeValue = useCallback(({ value, name, }) => {
        dispatch(setNormalize({ value, uuid, key: name }));
    }, [ dispatch, uuid ]);

    const { __off, ccdExclusive } = data;
    return (
        <MDBCard>
            <MDBCardTitle className={classNames(
                'cardHide__title',
                { 'cardHide__title--noBorderBottom': shownSettings },
            )}
            >
                <div
                    className="clicable"
                    onClick={setShownSettings.swap}
                >
                    {t('widgets:Vpn.header.pushToUser')}
                    <Icon
                        name={shownSettings ? 'chevron-up' : 'chevron-down'}
                    />
                </div>
                {shownSettings &&
                    <div className="display--flex">
                        <Switch
                            align="spaceBetween"
                            checked={ccdExclusive}
                            className="mb-3 mt-0 mr-2"
                            disabled={__off}
                            id={'ccdExclusiveId'}
                            label={t('widgets:Vpn.ccdExclusive.title')}
                            message="ccd-exclusive"
                            name={'ccdExclusive'}
                            onChange={changeValue}

                        />
                        <InputSearch
                            className="mb-3 mt-0"
                            id="searchValueIDRoute"
                            search={searchValue}
                            setter={setSearchValue}
                        />
                    </div>}
            </MDBCardTitle>

            <MDBCardBody className={classNames('cardHide__body',
                'p-0',
                { 'cardHide__body--hide': !shownSettings },
                { 'cardHide__title--borderBottom': shownSettings },)}
            >
                {shownSettings &&
                    <VpnPushToUserContext.Provider value={{ search: searchValue, uuid: uuid }}>
                        <VpnUserTable />
                    </VpnPushToUserContext.Provider>
                }
            </MDBCardBody>
        </MDBCard>
    );
};

export default VpnPushToUser;
