/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';

import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';


type TooltipType = {
    content?: React.ReactNode,
    children?: React.ReactNode,
    className?: string,
    onClick?: () => void,

    //cypress
    datacy?: string
};

const EmptyWrapper = ({ children }) => children;
const Tooltip = ({ content, children, className, onClick, datacy }: TooltipType) => {
    // This prevents rendering of tooltip before hovering over the element, because Tippy causes additional renders.
    // Which degrades performance with many tooltips present
    const [ renderTooltip, setRenderTooltip ] = useBoolean(false);
    const [ forceVisible, setForceVisible ] = useState<true|undefined>(undefined);
    const Tag = renderTooltip ? Tippy : EmptyWrapper;
    const spanRef = useRef<HTMLElement|null>(null);
    if (window.Cypress) {
        // This is very specific block to Cypress component testing. This condition will never change at runtime
        // So calling this hook conditionally is fine.
        // Also this useEffect gets run on every render. Which is reason why this should be excluded from non-testing
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            const listener = (opts) => {
                setForceVisible(opts.forceVisible);
                setRenderTooltip.on();
            };
            const ref = spanRef.current;
            ref?.addEventListener('debugSetVisible', listener, { once: false });
            return () => {
                ref?.removeEventListener('debugSetVisible', listener);
            };

        });
    }
    if (content) {
        return (
            <Tag
                content={content}
                visible={forceVisible}
            >
                <span
                    className={className}
                    data-cy={datacy}
                    onClick={onClick}
                    onMouseOver={setRenderTooltip.on}
                    ref={spanRef}
                >
                    {children}
                </span>
            </Tag>
        );
    } else {
        return (
            <>
                {children}
            </>
        );
    }
};

export default Tooltip;
