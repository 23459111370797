/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { IMINAF_CONFIRM } from '~frontendConstants/index.js';
import { imInConfirm } from '~frontendDucks/imInAF/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';


const ImInAFConfirm = () => {
    const dispatch = useDispatch();
    const confirm = () => {
        dispatch(imInConfirm());
    };
    const close = () => {
        dispatch(setModalState({ modal: IMINAF_CONFIRM, value: false }));

    };

    return (
        <Modal
            bodyText={'widgets:AdaptiveFirewall.ImIn.modal.body'}
            exitHandle={close}
            headerText={'widgets:AdaptiveFirewall.ImIn.modal.header'}
            modalOpen={true}
            negativeResponse={close}
            position="top-right"
            positiveResponse={confirm}
        />
    );
};

export default ImInAFConfirm;
