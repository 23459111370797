/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBModalFooter, MDBRow } from 'mdbreact';
import Stepper from 'react-stepper-horizontal';
import classNames from 'classnames';

import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import LogoBlackImage from '~frontendRoot/img/svg/kernun-logo-black.svg';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import { ButtonWithLock, Icon, Input, Modal, Select, SvgInliner, ToggleBox } from '~frontendComponents/Generic/index.js';
import {
    createCluster,
    getClusterSetupAdding,
    getClusterSetupModalOpen,
    getHealthIssues,
    getHealthIssuesRequest,
    getMyNode,
    getSuccessUpload,
    getUploadError,
    getUploadLoading,
    isNodeUnreachable,
    resetUploadSuccess,
    setClusterSetupModalOpen,
    setNodesRequest,
    uploadHlcfg,
} from '~frontendDucks/clusterSetup/index.js';
import {
    getClusterSharedInterface,
    getGlcfgSchema,
    getGlcfgValue,
    getInterfacesNames,
    getIsTreeStoring,
    getTreeChanges,
    getVerificationErrors,
    resetAllChangesToInit,
    setGlcfgObjectValue,
    setGlcfgValueScalar,
    setInterface,
    treeStoreRequest,
} from '~frontendDucks/hlcfgEditor/index.js';
import { cfgActivationRequest, getCfgActivationIsLoading } from '~frontendDucks/cfgActivation/index.js';
import {
    loadSystemComponents,
} from '~frontendDucks/systemComponents/index.js';
import {
    CLUSTER_SHARED,
    CLUSTER_STEP_A,
    CLUSTER_STEP_B,
    COLOR_PRIMARY_MDB,
    ERROR_CODE_NOT_SPECIFIED_NODE,
    FAILURE,
    FIREWALL_CLUSTER,
    FIREWALL_CLUSTER_A_HOSTNAME,
    FIREWALL_CLUSTER_B_HOSTNAME,
    HOSTNAME,
    MASTER,
    NODE_A_ID,
    NODE_A_LETTER,
    NODE_B_ID,
    NODE_B_LETTER,
} from '~frontendRoot/constants/index.js';
import { parseAddress, stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';
import { NetworkDatatable } from '~frontendScenes/Configuration/scenes/Network/scenes/Network/components/index.js';
import Differ from '~frontendRoot/layout/Application/components/Differs/Differs.tsx';
import Divider from '~frontendRoot/components/Divider/index.js';
import { setActiveCard } from '~frontendRoot/ducks/activeCards/index.js';
import { activateConfWithStep, finishClusterSetup } from '~frontendRoot/ducks/clusterSetup/actions.js';
import { useSystemComponentsListQuery } from '~frontendQueries/system/hooks.ts';

import CfgActivationModal from '../CfgActivationModal/index.js';
import DownloadCluster from './DownloadCluster.tsx';


const NOT_POSSIBLE_BACK_FUNCTION_STEPS = [ 2, 3, 6 ];

const getComponentError = (verificationErrors, component) => {
    return verificationErrors.
        filter(err => err?.hlcfgPaths.filter(path => path === component).length)[0]?.desc;
};

const ContinueButton = ({ currentStep,
    isActivationLoading, onClickNext, t,
    verificationErrors, successUpload, addingToCluster, treeChanges,
    clickedDownloadState, isStoring }) => {
    const button = { step: '', disabled: false, loading: false, };
    switch (currentStep) {
    case 0:
        if (addingToCluster) {
            button.step = 'ImportCfgStep';
            button.disabled = !successUpload;
        } else {
            button.step = 'FirstStep';
            button.disabled = treeChanges;
        }
        break;
    case 1:
        button.step = 'SettingConfigurationStep';
        button.disabled = verificationErrors.length || isStoring;
        button.loading = isStoring;
        break;
    case 2:
        button.step = 'CfgActivationStep';
        button.disabled = isActivationLoading;
        break;

    case 3:
        if (addingToCluster) {
            button.step = 'SyncStep';
            button.disabled = false;
        } else {
            button.step = 'Final';
            button.disabled = !clickedDownloadState;
        }
        break;
    case 4:
        button.step = 'CfgActivationStep';
        button.disabled = isActivationLoading;
        break;
    case 5:
        button.step = 'Finalizing';
        button.disabled = false;
        break;
    default:
        break;
    }
    return (
        <MDBBtn
            color="primary"
            disabled={button.disabled}
            onClick={onClickNext}
        >
            {button.loading && <Icon name="loading" />}
            {t(`widgets:cluster.modal.${button.step}.button`)}
        </MDBBtn>
    );
};

ContinueButton.propTypes = {
    currentStep: PropTypes.number,
    isActivationLoading: PropTypes.bool,
    onClickNext: PropTypes.func,
    verificationErrors: PropTypes.array,
    clickedDownloadState: PropTypes.bool,
    successUpload: PropTypes.bool,
    addingToCluster: PropTypes.bool,
    treeChanges: PropTypes.number,
    isStoring: PropTypes.bool,
    t: PropTypes.func,
};

const FirstStep = ({ t, treeChanges }) => (
    <div className={classNames('p-4')}>
        <p className="mw-100">{t('widgets:cluster.modal.FirstStep.desc')}</p>
        {treeChanges ?
            <p className="infobox infobox--info mw-100">
                {t('widgets:cluster.activate')}
            </p> : null}
    </div>
);

FirstStep.propTypes = {
    treeChanges: PropTypes.number,
    t: PropTypes.func,
};

const SettingConfigurationStep = ({ t, clusterSharedInterface, changeValue, addingToCluster, verificationErrors,
    hostnameNodeA, hostnameNodeB, clusterSharedInterfaceSchema, hostname }) => (
    <div className="d-flex">
        <div className="p-4 w-100">
            <span className="cluster__nodeTitle">
                {t('widgets:cluster.modal.SettingConfigurationStep.desc')}

            </span>
            <MDBRow className="p-3">
                <MDBCol>
                    <Input
                        disabled={addingToCluster}
                        error={getComponentError(verificationErrors, 'deployment.firewallCluster.nodeA.hostname')}
                        id={FIREWALL_CLUSTER_A_HOSTNAME}
                        label={t('widgets:cluster.modal.SettingConfigurationStep.node.hostnamenodeA')}
                        onChange={changeValue}
                        value={hostnameNodeA}
                    />
                </MDBCol>
                <Divider vertical />
                <MDBCol>
                    <Input
                        disabled={addingToCluster}
                        id={HOSTNAME}
                        label={t('differs:network.hostname')}
                        onChange={changeValue}
                        value={hostname}
                    />
                </MDBCol>
                <MDBCol>

                    <NetworkInterfaceDevice
                        disabled={addingToCluster}
                        id="clusterShared"
                        iface={clusterSharedInterface ? [ clusterSharedInterface ] : []}
                        label={t('widgets:cluster.modal.SettingConfigurationStep.node.shared.device')}
                        name="interface"
                        onChange={changeValue}
                        paste={false}
                        portaling={false}
                        schema={clusterSharedInterfaceSchema}
                    />

                </MDBCol>
                <Divider vertical />
                <MDBCol>

                    <Input
                        disabled={addingToCluster}
                        error={getComponentError(verificationErrors, 'deployment.firewallCluster.nodeB.hostname')}
                        id={FIREWALL_CLUSTER_B_HOSTNAME}
                        label={t('widgets:cluster.modal.SettingConfigurationStep.node.hostnamenodeB')}
                        onChange={changeValue}
                        value={hostnameNodeB}

                    />

                </MDBCol>
            </MDBRow>
            {t('widgets:cluster.modal.SettingConfigurationStep.interfaceTable')}
            <NetworkDatatable />

        </div>
        <div className="differs ">
            <Differ />
        </div>
    </div>

);

SettingConfigurationStep.propTypes = {
    clusterSharedInterface: PropTypes.string,
    verificationErrors: PropTypes.array,
    addingToCluster: PropTypes.bool,
    changeValue: PropTypes.func,
    t: PropTypes.func,
    hostnameNodeA: PropTypes.string,
    hostnameNodeB: PropTypes.string,
    hostname: PropTypes.string,
    clusterSharedInterfaceSchema: PropTypes.object,

};

const CfgActivationStep = ({ currentStep }) => {
    return (
        <div className="p-4">
            <CfgActivationModal
                isModal={false}
                syncStep={currentStep === 4}
                withoutFooter={true}
            />
        </div>
    );

};

CfgActivationStep.propTypes = {
    currentStep: PropTypes.number,
};

const DownloadCfgStep = ({ addingToCluster, clickedDownload }) => {
    return (
        <div
            className="p-4 "
        >
            <DownloadCluster
                addingToCluster={addingToCluster}
                clickedDownload={clickedDownload}
            />
        </div>
    );

};


DownloadCfgStep.propTypes = {
    addingToCluster: PropTypes.bool,
    clickedDownload: PropTypes.func,
};

const NeedsSync = ({ t }) => {
    return (
        <div
            className="p-4 "
        >
            <span className="cluster__nodeTitle">
                {t('widgets:cluster.modal.NeedSync.desc')}

            </span>
        </div>
    );

};


NeedsSync.propTypes = {
    t: PropTypes.func,
};


const ImportCfgStep = ({ t, handleInputOnChange, upload, errorUpload, uploadLoading, successUpload }) => {
    return (
        <div className="p-4">
            <MDBRow>
                <MDBCol>
                    <p className="mb-0">{t('widgets:cluster.modal.ImportCfgStep.desc')}</p>
                    <Input
                        className="cluster__keytab"
                        id="loginKeytab"
                        maxSize={2000000}
                        name="file"
                        onChange={handleInputOnChange}
                        outline={false}
                        type="file"
                    />
                    {errorUpload?.code && errorUpload?.code !== ERROR_CODE_NOT_SPECIFIED_NODE ?
                        <p className="infobox infobox--alert">{errorUpload?.message}</p> : null}
                    <div className="cluster__flexEnd">
                        <MDBBtn
                            color={errorUpload?.code &&
                                errorUpload?.code !== ERROR_CODE_NOT_SPECIFIED_NODE ?
                                'red' : successUpload ? 'green' : 'primary'}
                            disabled={uploadLoading}
                            onClick={() => upload()}
                        >
                            {uploadLoading || successUpload ?
                                <>
                                    <Icon name={errorUpload?.code &&
                                errorUpload?.code !== ERROR_CODE_NOT_SPECIFIED_NODE ?
                                        'close' : uploadLoading ? 'loading' : 'check'}
                                    />
                                    {uploadLoading ? t('widgets:cluster.modal.AuthenticationStep.loading') :
                                        t('widgets:cluster.modal.AuthenticationStep.done')}
                                </> :
                                t('widgets:cluster.modal.AuthenticationStep.upload')}
                        </MDBBtn>
                    </div>
                </MDBCol>
            </MDBRow>
            <div className={classNames(
                'cluster__choose',
                { 'displayNone': errorUpload?.code !== ERROR_CODE_NOT_SPECIFIED_NODE }
            )}
            >
                <MDBCard className="cluster__chooseCard">
                    <MDBCardHeader>
                        {t('widgets:cluster.modal.ImportCfgStep.error')}
                    </MDBCardHeader>
                    <MDBCardBody>
                        <span>
                            {errorUpload?.message}
                        </span>
                        <div className="cluster__chooseNodes">
                            <div
                                className="cluster__chooseNodes--node"
                                onClick={event => {
                                    event.preventDefault();
                                    upload(NODE_A_ID);
                                }}
                            >{NODE_A_LETTER}
                            </div>
                            <div
                                className="cluster__chooseNodes--node"
                                onClick={event => {
                                    event.preventDefault();
                                    upload(NODE_B_ID);
                                }}
                            >{NODE_B_LETTER}
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </div>
        </div>
    );

};

ImportCfgStep.propTypes = {
    errorUpload: PropTypes.object,
    upload: PropTypes.func,
    handleInputOnChange: PropTypes.func,
    successUpload: PropTypes.bool,
    uploadLoading: PropTypes.bool,
    t: PropTypes.func,
};

const getColToggleBox = ({ clusterInterface, hostname, node, nodeState }) => (
    <MDBCol
        className="mt-4 mb-4"
        size="4"
    >
        <ToggleBox
            blockOpening
            description={
                <ul>
                    <li>
                        <Select
                            className="dataTableWidget__RowInput"
                            fake
                            id={node}
                            isMulti
                            isRow
                            name="address"
                            noDropdownIndicator
                            noOptionsMessage
                            value={stringifyAddress(clusterInterface?.address[node])}
                        />
                    </li>
                    <li>

                        <Select
                            className={classNames(
                                'dataTableWidget__RowInput',
                                'network__rowCell--clusterHw',
                                { 'h-2': name },
                            )}
                            fake
                            id={node}
                            isRow
                            name="device"
                            noDropdownIndicator
                            noOptionsMessage
                            value={clusterInterface?.device[node]}
                        />
                    </li>
                </ul>
            }
            id="current"
            isActive
            isOpen
            label={
                <>
                    {nodeState?.[node] === MASTER &&
                    <Icon
                        className="icon--gold cluster__masterIcon"
                        name="crown"
                    />}
                    {nodeState?.[node] === FAILURE &&
                    <Icon
                        className="icon--warning cluster__masterIcon"
                        name="alert-circle"
                    />}
                    {hostname}
                </>
            }
            labelClassName="upgrade__toggleBoxLabel"
            name={hostname}
            replaceButton={
                <SvgInliner
                    className="logo__svg"
                    src={LogoBlackImage}
                />}
            textClassName="upgrade__toggleBoxText"
        />
    </MDBCol>
);

getColToggleBox.propTypes = {
    node: PropTypes.object,
    nodeState: PropTypes.string,
    clusterInterface: PropTypes.object,
    hostname: PropTypes.string,
};

export const SyncStep = ({ t, healthIssues, hostnameNodeA, hostnameNodeB,
    getHealthIssues, nodeUnreachable, withoutSuccess, className, nodeState,
    interfacesNames, nodeStateError, clusterInterface, clusterSharedInterface, clusterSharedInterfaceSchema,
}) => {
    const isSystemComponentsLoading = useSystemComponentsListQuery().isFetching;
    return (
        <div className={classNames(className || 'p-4')}>
            <MDBRow className="p-3">
                {getColToggleBox({
                    clusterInterface,
                    hostname: hostnameNodeA,
                    nodeState,
                    node: NODE_A_ID,
                    interfacesNames
                })}
                <MDBCol size="4">

                    <div className={nodeUnreachable || healthIssues.length ? '' : 'cluster__connection'} />
                    <div className="cluster__shared cluster__interface">
                        <NetworkInterfaceDevice
                            className={classNames(
                                'dataTableWidget__RowInput',
                                'network__rowCell--clusterHw--syncStep',
                                { 'h-2': name },
                            )}
                            compact
                            fake={true}
                            id="clusterSharedInterfaceShow"
                            iface={clusterSharedInterface ? [ clusterSharedInterface ] : []}
                            isRow
                            name="interface"
                            noDropdownIndicator
                            noOptionsMessage
                            schema={clusterSharedInterfaceSchema}
                        />
                        <div className={nodeUnreachable || healthIssues.length ? '' :
                            'cluster__connection cluster__connection--second'}
                        />
                    </div>
                </MDBCol>
                {getColToggleBox({
                    clusterInterface,
                    hostname: hostnameNodeB,
                    nodeState,
                    node: NODE_B_ID,
                    interfacesNames })}
            </MDBRow>
            {!nodeUnreachable ?
                withoutSuccess || (
                    <p className="infobox infobox--success">
                        {t('widgets:cluster.modal.SyncStep.success')}
                    </p>) :
                <p className="infobox infobox--primary">
                    {t('widgets:cluster.modal.SyncStep.warning')}
                    <Icon
                        className="ml-2"
                        name={isSystemComponentsLoading ? 'loading' : 'reload'}
                        onClick={() => getHealthIssues()}
                    />
                </p>}
            {healthIssues.length ?
                <>
                    <p className="infobox infobox--alert">
                        {
                            healthIssues.map(item => item.errors.map(item => (
                                <>
                                    {
                                        typeof item === 'object' ?
                                            <pre>{JSON.stringify(item)}</pre> :
                                            item
                                    }
                                    <br />
                                </>
                            )))
                        }
                    </p>
                    <p className="infobox infobox--primary">
                        {t('widgets:cluster.modal.SyncStep.todo')}
                    </p>
                </> :
                null}
            {Object.values(nodeStateError || {}).map(item => item?.code &&
                <p className="infobox infobox--alert">
                    {
                        item?.message
                    }
                </p>)
            }
        </div>
    );

};

SyncStep.propTypes = {
    healthIssues: PropTypes.array,
    nodeUnreachable: PropTypes.bool,
    getHealthIssues: PropTypes.func,
    t: PropTypes.func,
    withoutSuccess: PropTypes.bool,
    className: PropTypes.string,
    nodeState: PropTypes.string,
    interfacesNames: PropTypes.object,
    nodeStateError: PropTypes.object,
    hostnameNodeA: PropTypes.string,
    hostnameNodeB: PropTypes.string,
    clusterSharedInterfaceSchema: PropTypes.object,
    clusterInterface: PropTypes.object,
    clusterSharedInterface: PropTypes.string,
};

const getStepsArray = (addingToCluster) => {
    if (addingToCluster) {
        return [ DownloadCfgStep, SettingConfigurationStep, CfgActivationStep, NeedsSync, CfgActivationStep, SyncStep ];
    }
    return [ FirstStep, SettingConfigurationStep, CfgActivationStep,
        DownloadCfgStep ];
};

const getStepsTitleArray = (addingToCluster, translation) => {
    const defaultTitles =  [ {
        title: translation('widgets:cluster.modal.FirstStep.title'),
    }, {
        title: translation('widgets:cluster.modal.SettingConfigurationStep.title'),
    }, {
        title: translation('widgets:cluster.modal.CfgActivationStep.title'),
    }, {
        title: translation('widgets:cluster.modal.DownloadCfgStep.title'),
    } ];
    if (addingToCluster) {
        return [  {
            title: translation('widgets:cluster.modal.ImportCfgStep.title'),
        }, {
            title: translation('widgets:cluster.modal.SettingConfigurationStep.title'),
        }, {
            title: translation('widgets:cluster.modal.CfgActivationStep.title'),
        }, {
            title: translation('widgets:cluster.modal.NeedSync.title'),
        }, {
            title: translation('widgets:cluster.modal.CfgActivationStep.title'),
        }, {
            title: translation('widgets:cluster.modal.SyncStep.title'),
        }  ];
    }
    return defaultTitles;
};

const getCurrentStep = ({ deployment, stepFromHlcfgA, stepFromHlcfgB, }) => {
    if (deployment === FIREWALL_CLUSTER) {
        return 3;
    }
    if (stepFromHlcfgA) {
        return stepFromHlcfgA;
    }
    if (stepFromHlcfgB) {
        return stepFromHlcfgB;
    }
    return 0;
};

@withTranslation()
@connect(
    state => ({
        isOpen: getClusterSetupModalOpen(state),
        addingToCluster: getClusterSetupAdding(state),
        hostnameNodeA: getGlcfgValue(state, FIREWALL_CLUSTER_A_HOSTNAME),
        hostnameNodeB: getGlcfgValue(state, FIREWALL_CLUSTER_B_HOSTNAME),
        hostname: getGlcfgValue(state, HOSTNAME),
        clusterSharedInterface: getGlcfgValue(state, CLUSTER_SHARED),
        clusterSharedInterfaceSchema: getGlcfgSchema(state, CLUSTER_SHARED),
        healthIssues: getHealthIssues(state),
        stepFromHlcfgA: getGlcfgValue(state, CLUSTER_STEP_A),
        stepFromHlcfgB: getGlcfgValue(state, CLUSTER_STEP_B),
        deployment: getGlcfgValue(state, 'deployment'),
        isActivationLoading: getCfgActivationIsLoading(state),
        isStoring: getIsTreeStoring(state),
        verificationErrors: getVerificationErrors(state),
        errorUpload: getUploadError(state),
        uploadLoading: getUploadLoading(state),
        successUpload: getSuccessUpload(state),
        nodeUnreachable: isNodeUnreachable(state),
        treeChanges: getTreeChanges(state),
        myNode: getMyNode(state),
        interfacesNames: getInterfacesNames(state),
        clusterInterface: getClusterSharedInterface(state),

    }),
    {
        doTreeStoreRequest: treeStoreRequest,
        setModal: setClusterSetupModalOpen,
        doCreateCluster: createCluster,
        doSetGlcfgObjectValue: setGlcfgObjectValue,
        setGlcfgValue: setGlcfgValueScalar,
        doCfgActivationRequest: cfgActivationRequest,
        uploadCfg: uploadHlcfg,
        doGetHealthIssues: getHealthIssuesRequest,
        doResetAllChanges: resetAllChangesToInit,
        doResetUploadSuccess: resetUploadSuccess,
        doLoadSystemComponents: loadSystemComponents,
        doSetNodesRequest: setNodesRequest,
        setInterface: setInterface,
        setActiveCard: setActiveCard,
        finishIt: finishClusterSetup,
        activateConfWithStep: activateConfWithStep


    }
)
class ClusterSetup extends Component {
    static get propTypes() {
        return {
            isOpen: PropTypes.bool,
            addingToCluster: PropTypes.bool,
            t: PropTypes.func,
            setModal: PropTypes.func,
            doCreateCluster: PropTypes.func,
            doSetGlcfgObjectValue: PropTypes.func,
            setGlcfgValue: PropTypes.func,
            healthIssues: PropTypes.array,
            doGetHealthIssues: PropTypes.func,
            stepFromHlcfgB: PropTypes.number,
            stepFromHlcfgA: PropTypes.number,
            isActivationLoading: PropTypes.bool,
            verificationErrors: PropTypes.array,
            doResetAllChanges: PropTypes.func,
            doCfgActivationRequest: PropTypes.func,
            deployment: PropTypes.string,
            errorUpload: PropTypes.object,
            uploadCfg: PropTypes.func,
            uploadLoading: PropTypes.bool,
            successUpload: PropTypes.bool,
            doResetUploadSuccess: PropTypes.func,
            treeChanges: PropTypes.number,
            doLoadSystemComponents: PropTypes.func,
            nodeUnreachable: PropTypes.bool,
            doSetNodesRequest: PropTypes.func,
            myNode: PropTypes.string,
            isStoring: PropTypes.bool,
            doTreeStoreRequest: PropTypes.func,
            interfacesNames: PropTypes.object,
            clusterSharedInterface: PropTypes.string,
            hostnameNodeA: PropTypes.string,
            hostnameNodeB: PropTypes.string,
            hostname: PropTypes.string,
            clusterSharedInterfaceSchema: PropTypes.object,
            setInterface: PropTypes.func,
            clusterInterface: PropTypes.object,
            setActiveCard: PropTypes.func,
            finishIt: PropTypes.func,
            activateConfWithStep: PropTypes.func,

        };
    }

    constructor(props) {
        super(props);
        const { t, addingToCluster, deployment, stepFromHlcfgA, stepFromHlcfgB } = props;
        this.state = {
            steps: getStepsTitleArray(addingToCluster, t),
            stepsComponents: getStepsArray(addingToCluster),
            currentStep: getCurrentStep({ deployment, stepFromHlcfgA, stepFromHlcfgB }),
            file: {},
            progress: false,
            clickedDownloadState: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { t, addingToCluster, stepFromHlcfgB, stepFromHlcfgA } = this.props;
        if (addingToCluster !== prevProps.addingToCluster) {
            this.setState({
                steps: getStepsTitleArray(addingToCluster, t),
                stepsComponents: getStepsArray(addingToCluster),
                currentStep: addingToCluster ? stepFromHlcfgB : stepFromHlcfgA || 0,
            });
        }

    }

    changeValueInterface = ({ value }) => {
        const { setInterface, clusterSharedInterface } = this.props;
        setInterface({ value, uuid: clusterSharedInterface, key: 'device', subkey: NODE_B_ID });
    };

    handleInputOnChange = ({ value, name }) => {
        const { doResetUploadSuccess } = this.props;
        this.setState({
            [name]: value
        });
        doResetUploadSuccess();

    };

    changeValue = ({ value, id }) => {
        const { setGlcfgValue } = this.props;
        if (id.includes('Address')) {
            setGlcfgValue(id, parseAddress(value));
        } else {
            setGlcfgValue(id, value);
        }
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal({ open: false, adding: false });
    };

    upload = (node) => {
        const { uploadCfg } = this.props;
        const { file } = this.state;
        uploadCfg({ file, node });
    };

    setValue = ({ value, id }) => {
        const { setGlcfgValue } = this.props;
        setGlcfgValue(id, value);
    };

    onChangeSwitchValue = ({ value, id, name }) => {
        const { doSetGlcfgObjectValue } = this.props;
        doSetGlcfgObjectValue('proxy', value, name, id);
    };

    refreshHealthIssues = () => {
        const { doGetHealthIssues } = this.props;
        doGetHealthIssues();
    };

    clickedDownload = () => {
        this.setState({
            clickedDownloadState: true
        });
    };

    onClickNext = () => {
        const { doCreateCluster,
            addingToCluster,
            doSetNodesRequest, doTreeStoreRequest,
            setActiveCard, finishIt, activateConfWithStep
        } = this.props;
        const { currentStep } = this.state;
        switch (currentStep) {
        case 0:
            if (addingToCluster) {
                doSetNodesRequest();
                setActiveCard({ type: 'changes', value: 'errors' });
            } else {
                doTreeStoreRequest();
                doCreateCluster();
                setActiveCard({ type: 'changes', value: 'errors' });
            }
            break;
        case 1:
            activateConfWithStep({ addingToCluster });
            break;
        case 2:

            break;
        case 3:
            if (!addingToCluster) {
                this.setState({
                    currentStep: 0,
                }, this.closeModal());
            } else {
                finishIt();
            }
            break;
        case 4:
            break;
        default:
            this.setState({
                currentStep: 0,
            }, this.closeModal());
            break;
        }
        this.setState({
            currentStep: currentStep + 1,
        });
    };

    doBack = () => {
        const { doResetAllChanges } = this.props;
        const { currentStep, progress } = this.state;
        if (progress) {
            this.setState({
                progress: false,
            });
            return;
        }
        if (currentStep === 1) {
            doResetAllChanges();
        }
        if (currentStep) {
            this.setState({
                currentStep: currentStep - 1,
            });
        } else {
            this.closeModal();
        }
    };

    resetToDefault = () => {
        const { doResetAllChanges, doCfgActivationRequest, setGlcfgValue, addingToCluster } = this.props;
        const {  currentStep, authenticationEnabledPersist } = this.state;
        if (!addingToCluster && ((currentStep < 3 && !authenticationEnabledPersist) ||
            (currentStep < 4 && authenticationEnabledPersist))) {
            doResetAllChanges();
        } else {
            setGlcfgValue(CLUSTER_STEP_A, 0);
            setGlcfgValue(CLUSTER_STEP_B, 0);
            doCfgActivationRequest();
            this.closeModal();
        }

    };

    render() {
        const {  t, isOpen, healthIssues, isActivationLoading,
            verificationErrors, errorUpload, addingToCluster, uploadLoading, successUpload,
            treeChanges, doLoadSystemComponents,
            doGetHealthIssues, nodeUnreachable, myNode, isStoring, interfacesNames, clusterSharedInterface,
            hostnameNodeA, hostnameNodeB, clusterSharedInterfaceSchema,
            clusterInterface, hostname
        } = this.props;
        const { currentStep, steps, progress, stepsComponents,
            clickedDownloadState } = this.state;
        return (
            <Modal
                className="cluster__modal"
                exitHandle={this.closeModal}
                headerClose={
                    currentStep !== 1 || currentStep !== 4}
                headerText={'widgets:cluster.modal.header'}
                modalOpen={isOpen}
                negativeResponse={() => null}
                position="top-right"
                positiveResponse={this.onDowngrade}
            >
                <Stepper
                    activeColor={COLOR_PRIMARY_MDB}
                    activeStep={currentStep}
                    completeColor={COLOR_PRIMARY_MDB}
                    steps={steps}
                />
                {stepsComponents[currentStep] ? stepsComponents[currentStep]({ t, progress,
                    onChangeSwitchValue: this.onChangeSwitchValue,
                    handleInputOnChange: this.handleInputOnChange,
                    upload: this.upload, clusterSharedInterface, hostnameNodeA, hostnameNodeB, hostname,
                    changeValue: this.changeValue,
                    healthIssues, errorUpload, addingToCluster, uploadLoading, successUpload,
                    verificationErrors, treeChanges, doLoadSystemComponents,
                    clickedDownload: this.clickedDownload,
                    getHealthIssues: doGetHealthIssues, nodeUnreachable, myNode, interfacesNames,
                    clusterSharedInterfaceSchema,
                    setInterface: this.changeValueInterface, clusterInterface, currentStep }) : null}

                <MDBModalFooter className="authentication__footer">
                    <div>
                        <MDBBtn
                            color="secondary"
                            disabled={NOT_POSSIBLE_BACK_FUNCTION_STEPS.includes(currentStep)}
                            onClick={this.doBack}
                        >
                            {t('widgets:global.back')}
                        </MDBBtn>
                        {currentStep <= 5 && !addingToCluster ?
                            <WhenAdvanced>
                                <ButtonWithLock
                                    disabled={isActivationLoading}
                                    messageOnLocked="widgets:global.resetToDefault"
                                    messageOnOpen="widgets:global.resetToDefault"
                                    onClick={() => this.resetToDefault()}
                                />
                            </WhenAdvanced> :
                            null}
                        <WhenAdvanced>
                            <MDBBtn
                                color="secondary"
                                onClick={() =>
                                {
                                    this.setState({
                                        currentStep: currentStep + 1,
                                    });
                                }}
                            >
                                {t('widgets:global.skip')}
                            </MDBBtn>
                        </WhenAdvanced>
                    </div>
                    <ContinueButton
                        addingToCluster={addingToCluster}
                        clickedDownloadState={clickedDownloadState}
                        currentStep={currentStep}
                        isActivationLoading={isActivationLoading}
                        isStoring={isStoring}
                        onClickNext={this.onClickNext}
                        stepsComponentsLength={stepsComponents.length}
                        successUpload={successUpload}
                        t={t}
                        treeChanges={treeChanges}
                        verificationErrors={verificationErrors}
                    />
                </MDBModalFooter>
            </Modal>
        );
    }
}

export default  ClusterSetup;
