/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';

import { Switch } from '~frontendComponents/Generic/index.js';
import {
    getDnssec,
    setGlcfgObjectValue,
} from '~frontendDucks/hlcfgEditor/index.js';
import { DNS } from '~frontendConstants/index.js';


@withTranslation()
@connect(
    (state) => ({
        dnnsec: getDnssec(state),
    }),
    {
        doSetGlcfgObjectValue: setGlcfgObjectValue,
    }
)
class Dnssec extends Component {
    static get propTypes() {
        return {
            dnnsec: PropTypes.bool,
            t: PropTypes.func,
            doSetGlcfgObjectValue: PropTypes.func,
        };
    }

    onChangeEvent = ({ value, id }) => {
        const { doSetGlcfgObjectValue } = this.props;
        doSetGlcfgObjectValue(DNS, value, id);
    };


    render() {
        const { t, dnnsec } = this.props;

        return (
            <MDBCard>
                <MDBCardHeader>
                    {t('widgets:Dnssec.title')}
                </MDBCardHeader>
                <MDBCardBody>
                    {t('widgets:Dnssec.desc')}
                    <Switch
                        align="spaceBetween"
                        checked={dnnsec}
                        falseAsUndefined
                        id="dnssec"
                        label={t('widgets:Dnssec.title')}
                        onChange={this.onChangeEvent}
                    />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default Dnssec;
