/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useSelector } from 'react-redux';

import { Scene } from '~frontendComponents/Scene/index.js';
import { DNS_PROXY_PROFILES } from '~frontendConstants/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { getGlcfgValueHook } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSetters.js';

import DnsProfiles from './DnsProxyProfile/index.js';


const DnsProxyService = () => {
    const dnsProxy = useSelector(getGlcfgValueHook('dnsProxy'));
    return (
        <Scene>
            <Service
                id={DNS_PROXY_PROFILES}
                on={dnsProxy?.__off !== true}
                showService={(
                    <DnsProfiles />
                )}
            />
        </Scene>
    );
};

export default DnsProxyService;
