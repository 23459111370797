/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React from 'react';
import { MDBBtn } from 'mdbreact';

import { netaddrRegexes } from '~commonLib/netaddrRegexes.ts';
import { SCHEMA_TYPE_NETADDR } from '~sharedLib/schemaTypes.ts';

import { pingAddress } from '../../api/index.js';
import ValueDebouncer from '../../lib/ValueDebouncer.js';
import Message from '../Message/index.js';
import { typeSchemaObject } from '../../types/index.js';
import IconWithPopover from '../IconWithPopover/index.js';
import { Loading } from '../Generic/index.js';


const valueDebouncer = new ValueDebouncer();

const StatusIcon = ({ isLoading, isWarning }) => {
    if (isLoading) {
        return 'loading';
    }
    if (isWarning) {
        return 'alert';
    }
    return 'thumb-up-outline';
};

StatusIcon.propTypes = {
    isLoading: PropTypes.bool,
    isWarning: PropTypes.bool,
};

const StatusIconClassName = ({ isLoading, isWarning }) => {
    if (isLoading) {
        return '';
    }
    if (isWarning) {
        return 'icon--red';
    }
    return 'icon--green';
};

StatusIconClassName.propTypes = {
    isLoading: PropTypes.bool,
    isWarning: PropTypes.bool,
};


const StatusTitle = ({ isLoading, isWarning }) => {
    if (isLoading) {
        return (<Message message="lib:Pinger.status.refreshing" />);
    }
    if (isWarning) {
        return (<Message message="lib:Pinger.status.error" />);
    }
    return (<Message message="lib:Pinger.status.ok" />);
};

StatusTitle.propTypes = {
    isLoading: PropTypes.bool,
    isWarning: PropTypes.bool,
};


const getButtonType = (address, isLoading, isWarning) => {
    if (!address || isLoading) {
        return 'default';
    }
    if (isWarning) {
        return 'danger';
    }
    return 'default';
};


/** This is not a React component because Ant Design Popover is weird. */
const getPingerButton = ({ address, isLoading, isWarning, refreshPing }) => {
    if (typeof jest !== 'undefined') {
        return null;
    }
    return (
        <MDBBtn
            onClick={refreshPing}
            outline
            size="sm"
            type={getButtonType(address, isLoading, isWarning)}
        >
            <StatusIcon
                address={address}
                isLoading={isLoading}
                isWarning={isWarning}
            />
        </MDBBtn>
    );
};

getPingerButton.propTypes = {
    address: PropTypes.string,
    isLoading: PropTypes.bool,
    isWarning: PropTypes.bool,
    refreshPing: PropTypes.func,
};


const PingerTitle = ({ isLoading, isWarning }) => (
    <StatusTitle
        isLoading={isLoading}
        isWarning={isWarning}
    />
);

PingerTitle.propTypes = {
    isLoading: PropTypes.bool,
    isWarning: PropTypes.bool,
};


const DisplayPinger = ({ address, isLoading, isRequesting, isWarning, moreInformation, refreshPing }) => {
    return (
        <IconWithPopover
            body={(
                <Loading
                    data
                    isLoading={isRequesting}
                >
                    <pre>
                        {moreInformation}
                    </pre>
                </Loading>
            )}
            className={StatusIconClassName({ isLoading, isWarning })}
            header={(
                <PingerTitle
                    address={address}
                    isLoading={isLoading}
                    isWarning={isWarning}
                />
            )}
            name={StatusIcon({ isLoading, isWarning })}
            onClick={refreshPing}
        />
    );
};

DisplayPinger.propTypes = {
    address: PropTypes.string,
    isLoading: PropTypes.bool,
    isWarning: PropTypes.bool,
    isRequesting: PropTypes.bool,
    moreInformation: PropTypes.node.isRequired,
    refreshPing: PropTypes.func.isRequired
};


class Pinger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.refreshPing = this.refreshPing.bind(this);
    }

    /**
     * Destructor, clears registered callbacks and stored state.
     */
    componentWillUnmount() {
        const { id } = this.props;
        this.isUnmounted = true;
        valueDebouncer.unregister(id);
    }

    getAddress() {
        return this.props.address;
    }

    async doRefreshPing() {
        const newState = {
            ...this.state,
            isRequesting: true,
            hasChangedWhileRefreshing: false,
        };
        this.setState(newState);
        const address = this.getAddress();
        let result;
        let error;
        try {
            result = await pingAddress(address);
        } catch (err) {
            error = err;
        }
        if (this.isUnmounted) {
            return;
        }
        if (this.state.hasChangedWhileRefreshing) {
            return this.doRefreshPing();
        }
        this.setState({
            ...this.state,
            error: error,
            hasChangedWhileRefreshing: false,
            isRequesting: false,
            result: result,
        });
    }

    /**
     * Refreshes the ping. Can be called multiple times which makes the previous refreshing be ignored.
     */
    async refreshPing() {
        const address = this.getAddress();
        if (!address) {
            return;
        }
        if (this.state.isRequesting) {
            this.setState({
                ...this.state,
                hasChangedWhileRefreshing: true,
            });
            return;
        }
        this.doRefreshPing();
    }

    render() {
        const { id, schema } = this.props;
        const address = this.getAddress();
        if (!isPingableNetaddrType(schema[SCHEMA_TYPE_NETADDR]) || !isPingableStringAddress(address)) {
            return null;
        }
        const { error, isRequesting, result } = this.state;
        valueDebouncer.debounce(id, address, this.refreshPing);
        const isLoading = isRequesting || !result;
        const isWarning = error || (result && result.code);
        let moreInformation = result ?
            [ result.stdout, result.stderr ].filter(output => output).join('\n') :
            '';
        if (moreInformation[moreInformation.length - 1] === '') {
            moreInformation = moreInformation.substr(0, moreInformation.length - 1);
        }
        return (
            <DisplayPinger
                address={address}
                isLoading={isLoading}
                isRequesting={isRequesting}
                isWarning={!!isWarning}
                moreInformation={moreInformation}
                refreshPing={this.refreshPing}
            />
        );
    }
}

Pinger.propTypes = {
    address: PropTypes.string,
    id: PropTypes.string.isRequired,
    schema: typeSchemaObject,
};

const isPingableStringAddress = address => {
    return address && (netaddrRegexes.ip4Basic.test(address) || netaddrRegexes.domain.test(address));
};
const isPingableNetaddrType = typeNetaddr => {
    if (!typeNetaddr || typeNetaddr.noPinger) {
        return false;
    }
    return (typeNetaddr.ip4 && !typeNetaddr.mask) || typeNetaddr.domain;
};

export default Pinger;
