/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IconWithPopover from '~frontendComponents/IconWithPopover/index.js';
import {
    getAdaptiveFirewallListsComment,
    getAdaptiveFirewallListsSchema,
    getAdaptiveFirewallLists,
    setGlcfgObjectValue } from '~frontendDucks/hlcfgEditor/index.js';
import { Input } from '~frontendComponents/Generic/index.js';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice/index.js';
import { ADAPTIVE_FIREWALL } from '~frontendRoot/constants/index.js';


@withTranslation()
@connect(
    (state, { list, listComment }) => ({
        listValue: getAdaptiveFirewallLists(state, list),
        listSchema: getAdaptiveFirewallListsSchema(state),
        listValueComment: getAdaptiveFirewallListsComment(state, listComment),
    }),
    {
        doSetGlcfgValue: setGlcfgObjectValue
    }
)
class BlackList extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            doSetGlcfgValue: PropTypes.func,
            listValue: PropTypes.array,
            listValueComment: PropTypes.string,
            list: PropTypes.string,
            listComment: PropTypes.string,
            listSchema: PropTypes.object,

        };
    }

    setValue = ({ id, value }) => {
        const { doSetGlcfgValue } = this.props;
        doSetGlcfgValue(ADAPTIVE_FIREWALL, value, id);
    };

    render() {
        const { t, listValue, listValueComment, list, listComment, listSchema } = this.props;
        return (
            <>
                <MDBCard>
                    <MDBCardTitle className="ips__cardTitle">
                        {t(`widgets:AdaptiveFirewall.${list}.title`)}
                        <IconWithPopover
                            body={t(`widgets:AdaptiveFirewall.${list}.desc`)}
                            divClassName="ml-1"
                            header={t(`widgets:AdaptiveFirewall.${list}.title`)}
                            iconSize="sx"
                            name="information-outline"
                        />
                    </MDBCardTitle>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <NetworkInterfaceDevice
                                    id={list}
                                    iface={listValue}
                                    isCreatable
                                    isMulti
                                    label={t(`widgets:AdaptiveFirewall.${list}.title`)}
                                    name={list + 'Name'}
                                    noDropdownIndicator
                                    onChange={this.setValue}
                                    paste
                                    schema={listSchema}
                                />
                            </MDBCol>
                            <MDBCol
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <Input
                                    id={listComment}
                                    label={t('widgets:AdaptiveFirewall.comment')}
                                    name="comment"
                                    onChange={this.setValue}
                                    rows={3}
                                    type="textarea"
                                    value={listValueComment}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
            </>
        );
    }
}

export default BlackList;
