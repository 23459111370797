/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard } from 'mdbreact';

import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { SELECTABLE_TABLE_VPN_LEASES } from '~frontendRoot/constants/index.js';

import Row from './components/Row/index.js';


const CreateRow = ({ dataIndex,
    spacing, }: CreateRowPropsType) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={dataIndex}
            spacing={spacing}
        />
    );
};

const VpnLeasesDatatable = ({ ids }: {ids: string[]})  => {
    return (
        <MDBCard>
            <DatatableWidget
                className={'pb-0'}
                columnsId={SELECTABLE_TABLE_VPN_LEASES}
                compact
                createRow={CreateRow}
                data={ids}
            />
        </MDBCard>
    );
};


export default VpnLeasesDatatable;
