/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import {  MDBRow, MDBCol } from 'mdbreact';

import { Checkbox, Select } from '~frontendComponents/Generic/index.js';
import { reportSetFilter, getInterfacesForCharts } from '~frontendDucks/reporterEntities/index.js';
import { callResizeEvent } from '~frontendLib/reactUtils.js';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { BASIC_IP_SCHEMA } from '~frontendRoot/constants/index.js';


export const AddressFilter = ({ clientAddress, addressChange, serverAddress, t }) => {
    return (
        <MDBRow className="pt-3">
            <MDBCol
                lg="3"
            >
                <Select
                    id="serverAddress"
                    isCreatable
                    isMulti
                    label={t('widgets:graph.server')}
                    name="serverAddress"
                    noDropdownIndicator
                    noOptionsMessage
                    onChange={addressChange}
                    schema={BASIC_IP_SCHEMA}
                    value={stringifyAddress(serverAddress)}
                />
            </MDBCol>
            <MDBCol
                lg="3"
            >
                <Select
                    id="clientAddress"
                    isCreatable
                    isMulti
                    label={t('widgets:graph.client')}
                    name="clientAddress"
                    noDropdownIndicator
                    noOptionsMessage
                    onChange={addressChange}
                    schema={BASIC_IP_SCHEMA}
                    value={stringifyAddress(clientAddress)}
                />
            </MDBCol>
        </MDBRow>
    );
};
AddressFilter.propTypes = {
    clientAddress: PropTypes.array,
    addressChange: PropTypes.func,
    serverAddress: PropTypes.array,
    t: PropTypes.func,
};

const NetworkCardFilterBase = ({ dashboardId, networkDevices, doReportSetFilter }) =>
    (
        <MDBRow className="pt-3">
            {Object.keys(networkDevices).map(networkCard => {
                return (
                    <Checkbox
                        checked={networkDevices[networkCard].show}
                        className="m-2"
                        disabled={networkDevices[networkCard].disabled}
                        id={networkDevices[networkCard].name}
                        key={networkCard}
                        label={networkDevices[networkCard].name}
                        labelColor={networkDevices[networkCard].color}
                        name={networkCard}
                        onChange={({ value, name }) => {
                            doReportSetFilter({ dashboardId, filter: 'networkCards', value, name });
                            callResizeEvent();
                        }}
                    />
                );
            })}
        </MDBRow>
    );

NetworkCardFilterBase.propTypes = {
    dashboardId: PropTypes.string,
    networkDevices: PropTypes.object,
    doReportSetFilter: PropTypes.func,
};
const mapStateToProps = (state) => ({
    networkDevices: getInterfacesForCharts(state),

});

const mapDispatchToProps = {
    doReportSetFilter: reportSetFilter,
};

export const NetworkCardFilter = connect(mapStateToProps, mapDispatchToProps)(NetworkCardFilterBase);
