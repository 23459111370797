/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

import { SafeSearch, DnsServer, DnsCatch, Dnssec, DnsCache } from './components/index.js';


const Dns = () => {
    return (
        <>
            <MDBRow>
                <MDBCol
                    className="mb-3"
                >
                    <DnsServer />
                </MDBCol>
                <MDBCol
                    className="mb-3"
                >
                    <DnsCatch />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol
                    className="mb-3"
                >
                    <SafeSearch />
                </MDBCol>
                <MDBCol
                    className="mb-3"
                >
                    <Dnssec />
                </MDBCol>
                <MDBCol
                    className="mb-3"
                >
                    <DnsCache />
                </MDBCol>
            </MDBRow>
        </>
    );
};


export default Dns;
