/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getGlcfgValue, getIsCluster } from '~frontendDucks/hlcfgEditor/index.js';
import { getHealthIssues, isNodeUnreachable } from '~frontendDucks/clusterSetup/index.js';
import { NODE_A_ID, NODE_B_ID } from '~frontendRoot/constants/index.js';
import actionStepCfgProcessDependencies
    from '~frontendComponents/ModalWindows/components/CfgActivationModal/lib/actionStepCfgProcessDependencies.tsx';
import {
    ACTION_STEP_CFG_APPLY,
    ACTION_STEP_CFG_GENERATE,
    ACTION_STEP_CFG_PROCESS_DEPENDENCIES,
    ACTION_STEP_CFG_VALIDATE,
    ACTION_STEP_CFG_VERIFY,
    ACTION_STEP_COMPONENTS_ACTIVATE,
    ACTION_STEP_COMPONENTS_ACTIVATION_LIST,
    ACTION_STEP_FRONTEND_RELOAD,
    cfgActivationAbort,
    cfgActivationClose,
    getCfgActivationError,
    getCfgActivationIsAborted,
    getCfgActivationIsLoading,
    getCfgActivationIsOpen,
    getCfgActivationProgress,
    getProgressnodeA,
    getProgressnodeB,
    getReplayingActionSequenceId,
    getReplayTimeCreated,
    getReplayUserName,
} from '~frontendDucks/cfgActivation/index.js';
import Message from '~frontendComponents/Message/index.js';
import { typeApiError, typeProgressArray } from '~frontendTypes/types.js';

import { ActionSequenceModal } from '../../../ActionSequence/index.js';
import actionStepCfgVerify from './lib/actionStepCfgVerify.js';
import actionStepCfgGenerate from './lib/actionStepCfgGenerate.js';
import actionStepCfgApply from './lib/actionStepCfgApply.js';
import actionStepComponentsActivate from './lib/actionStepComponentsActivate.js';
import actionStepComponentsActivationList from './lib/actionStepComponentsActivationList.js';
import actionStepFrontendReload from './lib/actionStepFrontendReload.js';
import actionStepCfgValidate from './lib/actionStepCfgValidate.js';


const CfgActivationAbortedTitle = () => (<Message message="actions:action.sequence.CfgActivation.aborted.title" />);

const CfgActivationDoneTitle = () => (<Message message="actions:action.sequence.CfgActivation.done.title" />);

const CfgActivationErrorTitle = () => (<Message message="actions:action.sequence.CfgActivation.error.title" />);

const CfgActivationLoadingTitle = () => (<Message message="actions:action.sequence.CfgActivation.loading.title" />);

const STEPS = {
    [ACTION_STEP_CFG_VERIFY]: actionStepCfgVerify,
    [ACTION_STEP_CFG_GENERATE]: actionStepCfgGenerate,
    [ACTION_STEP_CFG_VALIDATE]: actionStepCfgValidate,
    [ACTION_STEP_CFG_APPLY]: actionStepCfgApply,
    [ACTION_STEP_CFG_PROCESS_DEPENDENCIES]: actionStepCfgProcessDependencies,
    [ACTION_STEP_COMPONENTS_ACTIVATE]: actionStepComponentsActivate,
    [ACTION_STEP_COMPONENTS_ACTIVATION_LIST]: actionStepComponentsActivationList,
    [ACTION_STEP_FRONTEND_RELOAD]: actionStepFrontendReload,
};

/**
 * Renders configuration activation modal window.
 */
export const CfgActivationModalBase = ({
    doCfgActivationAbort, doCfgActivationClose,
    error, isAborted, isLoading, isOpen, progress,
    replayTimeCreated, replayUserName, replayingActionSequenceId,
    isModal, isCluster, progressnodeA, progressnodeB, isAbortednodeA,
    isAbortednodeB, isLoadingnodeA, isLoadingnodeB, hostnamenodeA, hostnamenodeB,
    errornodeA, errornodeB, nodeUnreachable, healthIssues, syncStep, withoutFooter
}) => (
    <ActionSequenceModal
        {...{ isCluster, error, errornodeA, errornodeB, hostnamenodeA, hostnamenodeB,
            isAborted, isAbortednodeA, isAbortednodeB, isLoading,
            isLoadingnodeA, isLoadingnodeB, isModal, isOpen, nodeUnreachable, progress, progressnodeA, progressnodeB,
            replayingActionSequenceId, replayTimeCreated, replayUserName, healthIssues, syncStep  }}
        maskClosable={false}
        onAbort={doCfgActivationAbort}
        onClose={doCfgActivationClose}
        sequenceAbortedTitle={(<CfgActivationAbortedTitle />)}
        sequenceDoneTitle={(<CfgActivationDoneTitle />)}
        sequenceErrorTitle={(<CfgActivationErrorTitle />)}
        sequenceLoadingTitle={(<CfgActivationLoadingTitle />)}
        sequenceTitle={<Message message="actions:action.sequence.CfgActivation.modal.title" />}
        steps={STEPS}
        withoutFooter={withoutFooter}
    />
);

CfgActivationModalBase.propTypes = {
    replayTimeCreated: PropTypes.string,
    replayUserName: PropTypes.string,
    replayingActionSequenceId: PropTypes.string,
    doCfgActivationAbort: PropTypes.func.isRequired,
    doCfgActivationClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    isAborted: PropTypes.bool,
    error: typeApiError,
    progress: typeProgressArray.isRequired,
    progressnodeA: typeProgressArray,
    progressnodeB: typeProgressArray,
    isModal: PropTypes.bool,
    deployment: PropTypes.string,
    isAbortednodeA: PropTypes.bool,
    isLoadingnodeB: PropTypes.bool,
    isAbortednodeB: PropTypes.bool,
    isLoadingnodeA: PropTypes.bool,
    hostnamenodeA: PropTypes.string,
    hostnamenodeB: PropTypes.string,
    errornodeA: typeApiError,
    errornodeB: typeApiError,
    nodeUnreachable: PropTypes.bool,
    isCluster: PropTypes.bool,
    healthIssues: PropTypes.array,
    syncStep: PropTypes.bool,
    withoutFooter: PropTypes.bool,
};

/**
 * @see Redux.connect()
 */
const mapStateToProps = (state, { isModal, syncStep }) => ({
    isOpen: getCfgActivationIsOpen(state),
    isLoading: getCfgActivationIsLoading(state),
    isAbortednodeA: getCfgActivationIsAborted(state, NODE_A_ID),
    isLoadingnodeB: getCfgActivationIsLoading(state, NODE_B_ID),
    isAbortednodeB: getCfgActivationIsAborted(state, NODE_B_ID),
    isLoadingnodeA: getCfgActivationIsLoading(state, NODE_A_ID),
    errornodeB: getCfgActivationError(state, NODE_B_ID),
    errornodeA: getCfgActivationError(state, NODE_A_ID),
    isAborted: getCfgActivationIsAborted(state),
    error: getCfgActivationError(state),
    progress: getCfgActivationProgress(state),
    progressnodeA: getProgressnodeA(state),
    progressnodeB: getProgressnodeB(state),
    replayTimeCreated: getReplayTimeCreated(state),
    replayUserName: getReplayUserName(state),
    replayingActionSequenceId: getReplayingActionSequenceId(state),
    isModal: isModal,
    syncStep: syncStep,
    isCluster: getIsCluster(state),
    hostnamenodeA: getGlcfgValue(state, 'firewallClusterAHostname'),
    hostnamenodeB: getGlcfgValue(state, 'firewallClusterBHostname'),
    nodeUnreachable: isNodeUnreachable(state),
    healthIssues: getHealthIssues(state)


});

const mapDispatchToProps = {
    doCfgActivationAbort: cfgActivationAbort,
    doCfgActivationClose: cfgActivationClose,
};

/**
 * Activation modal window.
 */
const CfgActivationModal = connect(mapStateToProps, mapDispatchToProps)(CfgActivationModalBase);

export default CfgActivationModal;
