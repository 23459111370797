/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Component } from 'react';
import classNames from 'classnames';
import { MDBCardBody, MDBCard, MDBCardTitle } from 'mdbreact';
import { withTranslation } from 'react-i18next';

import ApiError from '~frontendComponents/Generic/ApiError/index.js';

import colors from './css-colors.json';
import Number from '../components/Number/index.js';
import { typeApiError } from '../../types/index.js';


const optionsForHighcharts = (type, data) => {
    return {
        chart: {
            className: 'HighchartGraph',
            backgroundColor: 'rgba(0,0,0,0)',
            height: 150,
            plotShadow: false,
            shadow: false,
            type: type && type !== 'donut' ? type : 'pie',
            spacingRight: 50,
        },
        title: {
            text: '',
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        legend: {
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical',
            margin: 50,
            symbolHeight: 16,
            symbolWidth: 16,
            symbolRadius: 4,
            itemMarginBottom: 6,
            itemStyle: {
                font: '0.75rem Encode Sans, sans-serif',

            },
        },
        plotOptions: {
            pie: {
                innerSize: type && type === 'pie' ? '0%' : '50%',
                borderWidth: 0,
                size: 90,
                shadow: false,
                allowPointSelect: false,

                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            },
            area: {

            },
            line: {

            },
            bar: {

            },
            column: {

            }
        },
        responsive: {
            rules: [ {
                condition: {
                    minWidth: 300,
                    maxWidth: 350
                },
                // Make the labels less space demanding on mobile
                chartOptions: {
                    chart: {
                        spacingRight: 50
                    },
                    legend: {
                        margin: 50
                    }
                },
                plotOptions: {
                    pie: {
                        size: 50
                    }
                }

            },
            {
                condition: {
                    minWidth: 350
                },
                // Make the labels less space demanding on mobile
                chartOptions: {
                    chart: {
                        spacingRight: 80
                    },
                    legend: {
                        margin: 80
                    }
                },
                plotOptions: {
                    pie: {
                        size: 70
                    }
                }

            },
            {
                condition: {
                    maxWidth: 270
                },
                // Make the labels less space demanding on mobile
                chartOptions: {
                    chart: {
                        spacingRight: 40
                    },
                    legend: {
                        margin: 40
                    }
                    ,
                    plotOptions: {
                        pie: {
                            size: 100
                        }
                    }
                }

            },
            {
                condition: {
                    minWidth: 100,
                    maxWidth: 250
                },
                // Make the labels less space demanding on mobile
                chartOptions: {
                    chart: {
                        spacingRight: 20
                    },
                    legend: {
                        margin: 20
                    }
                    ,
                    plotOptions: {
                        pie: {
                            size: 70
                        }
                    }
                }

            } ]
        },
        series: [
            {
                data: data ? data : null,

            },
        ],
        colors: colors.kernun.map(color => color.rgba)
    };
};
@withTranslation()
class GraphWidget extends Component {
    static get propTypes() {
        return {
            data: PropTypes.array.isRequired,
            className: PropTypes.string,
            //colors: PropTypes.array,               //TODO: add colors to graph
            type: PropTypes.oneOf([ 'pie', 'bar', 'donut', 'line', 'area', 'column' ]),
            title: PropTypes.string.isRequired,
            error: typeApiError,
            isLoading: PropTypes.bool.isRequired,
            t: PropTypes.func
        };
    }

    render() {
        const { data, className, type, title, error, isLoading, t } = this.props;

        return (
            <MDBCard className={classNames(className, 'graph')}>
                <MDBCardTitle>
                    {t(title)}
                </MDBCardTitle>
                <MDBCardBody>
                    <ApiError
                        className="graph__loader"
                        data={data}
                        error={error}
                        isLoading={isLoading}
                    >
                        <div className="graph">
                            {data.length ? data.map(value => (
                                <Number
                                    className="number"
                                    key={value.id}
                                    label={value.name}
                                    value={value.y}
                                    vertical
                                //We store value in Y , reason: highchart'sReact
                                />

                            )) : t('components:NoData')}
                        </div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsForHighcharts(type, data)}
                        />
                    </ApiError>
                </MDBCardBody>
            </MDBCard>
        );
    }
}


export default GraphWidget;
