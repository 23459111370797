/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import { chartTypes, ChartData } from '../../../shared/lib/reporterLibrary/index.js';
import {
    typeColumnsInfo, typeDashboardDefinition, typeRefreshResult, typeReportDefinition, typeReporterTemplates,
    typeReportUsage,
    typeLanguage
} from '../../types/index.js';
import Message from '../Message/index.js';
import ChartErrorBoundary from './ChartErrorBoundary.js';
import LegacyChartWithButtons from './LegacyChartWithButtons.js';
import ChartTable from './ChartTable.js';


const Content = ({
    activeReportDefinition, activeReportUsage,
    chartType, columnsInfo,
    dashboardDefinition,
    frozenReportDefinition, frozenReportUsage,
    refreshResult,
    reporterTemplates,
    selectedLanguage,
    type,
    extraData,
}) => {
    if (!columnsInfo) {
        return (<Message message="reporter:chart.noColumnsInfo.title" />);
    }
    if (chartType in chartTypes.CHART_TYPES) {
        if (chartType === 'table') {
            const chartData = new ChartData({
                activeReportDefinition, activeReportUsage,
                frozenReportDefinition, frozenReportUsage,
                objFilters: {},
                refreshResult,
                reporterTemplates,
            });
            return (
                <ChartTable
                    chartData={chartData}
                    columnsInfo={columnsInfo}
                    selectedLanguage={selectedLanguage}
                />
            );
        }
        return (
            <LegacyChartWithButtons
                activeReportDefinition={activeReportDefinition}
                activeReportUsage={activeReportUsage}
                dashboardDefinition={dashboardDefinition}
                extraData={extraData}
                frozenReportDefinition={frozenReportDefinition}
                frozenReportUsage={frozenReportUsage}
                refreshResult={refreshResult}
                reporterTemplates={reporterTemplates}
                type={type}
            />
        );
    }
    return (
        <Message
            message="reporter:chart.chartNotImplemented.title"
            param={{ chartType }}
        />
    );
};

Content.propTypes = {
    chartType: PropTypes.string.isRequired,
    columnsInfo: typeColumnsInfo,
    dashboardDefinition: typeDashboardDefinition,
    activeReportDefinition: typeReportDefinition,
    activeReportUsage: typeReportUsage,
    frozenReportDefinition: typeReportDefinition,
    frozenReportUsage: typeReportUsage,
    refreshResult: typeRefreshResult,
    reporterTemplates: typeReporterTemplates,
    selectedLanguage: typeLanguage,
    type: PropTypes.string,
    extraData: PropTypes.object
};


const Chart = props => (
    <ChartErrorBoundary>
        <Content {...props} />
    </ChartErrorBoundary>
);

Chart.propTypes = {
    chartType: PropTypes.string.isRequired,
    columnsInfo: typeColumnsInfo,
    dashboardDefinition: typeDashboardDefinition,
    activeReportDefinition: typeReportDefinition,
    activeReportUsage: typeReportUsage,
    frozenReportDefinition: typeReportDefinition,
    frozenReportUsage: typeReportUsage,
    refreshResult: typeRefreshResult,
    reporterTemplates: typeReporterTemplates,
    type: PropTypes.string,
    extraData: PropTypes.object
};

export default Chart;
