/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelector } from 'reselect';

import { all, put, select, takeEvery, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import {
    ACCEPT_ACTION,
    EMPTY_IMMUTABLE_ARR,
    EMPTY_IMMUTABLE_OBJ,
    FORCE_ACCEPT_ACTION,
    HONEYPOT,
    PF_SRC_TRANSLATION_MASQUERADE,
} from '~sharedConstants/index.ts';
import { parseAddress, stringifyAddress } from '~frontendLib/addressUtils.ts';
import { netport } from '~sharedLib/Netport/Netport.ts';
import { getChosenRuleUuid } from '~frontendDucks/packetFilterRules/index.js';
import { netservice } from '~sharedLib/Netservice/Netservice.ts';
import { PACKET_FILTER_RULES,
    NEW_ROW_CONSTANT, TABLES, SECOND_DEFAULT_HEADER_UUID,
    HTTPS_PORT, HTTP_PORT, PROXY, GUI_ADDRESSES_HTTPS,
    GUI_ADDRESSES_HTTP } from '~frontendRoot/constants/index.js';
import { addBefore } from '~commonLib/arrayUtils.ts';
import { HlcfgRowId, hlcfgRowObjectIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { Offable, OffableNftItem,  } from '~frontendRoot/constants/types.ts';
import { STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN, STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS,
    STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS,
    STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES,
    STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS
} from '~sharedLib/staticHlcfgReferenceUtils.js';
import { HlcfgTypeHoneypot } from '~frontendTypes/externalTypes.ts';

import { getNormalizedTableGetters, getGlcfgValue, getVerificationErrors,
    getGlcfgSchema, getGlcfgSetterActionType,
    getDefaultNormalizedTableGetters, } from './glcfgGettersAndSettersUtils.ts';
import { duplicateFromNormalize } from './normalizeTableGettersAndSetters.js';


const doesntExists = (object) => !object || object === EMPTY_IMMUTABLE_OBJ;
export const getPacketFilter = (state) => getGlcfgValue(state, PACKET_FILTER_RULES).rules;
export const getPacketFilterSchema = (state) => getGlcfgSchema(state, PACKET_FILTER_RULES).rules;

const CLEAR_PACKET_FILTER = '/ak/hlcfgEditor/CLEAR_PACKET_FILTER';
const SET_NORMALIZE_ORDER_PF = '/ak/hlcfgEditor/SET_NORMALIZE_ORDER_PF';
const CLOSE_HEADER = 'ak/hlcfgEditor/CLOSE_HEADER';
const START_DUPLICATE = '/ak/hlcfgEditor/START_DUPLICATE';

export const duplicateStartPF = (uuid: HlcfgRowId) => ({ type: START_DUPLICATE, uuid });


const {
    getItem: getTableItem,
} = getDefaultNormalizedTableGetters();

export const {
    getIds: getPacketFilterIds,
    getItems: getPacketFilterItems
} = getNormalizedTableGetters(PACKET_FILTER_RULES);

export const getAreAllRuleFakes = createSelector(getPacketFilterItems,
    (items: Offable<'nftDivider' | 'nftRule'>[]) => !items.find(item => !item.fake));
export const getPacketFilterIdsWithOneEpmtyIfNeeded = createSelector([
    getPacketFilterIds,
    getAreAllRuleFakes
], (ids: HlcfgRowId[], createFake) => {
    if (createFake) {
        const newIds = [ ...ids ];
        return addBefore(newIds, newIds.indexOf(SECOND_DEFAULT_HEADER_UUID), NEW_ROW_CONSTANT);
    }
    return ids;
});
const strinfigyRule = (rule) => ({
    ...rule,
    sourceAddress: stringifyAddress(rule.sourceAddress),
    destinationAddress: stringifyAddress(rule.destinationAddress),
    service: stringifyAddress(rule.service, true, netservice),
    sourceTranslation: rule.sourceTranslation === PF_SRC_TRANSLATION_MASQUERADE ? [ rule.sourceTranslation ] :
        stringifyAddress(rule.sourceTranslation),
    destinationTranslation: {
        address: stringifyAddress(rule.destinationTranslation?.address),
        port: stringifyAddress(rule.destinationTranslation?.port, true, netport)
    },
    iface: rule?.iface ? [ rule.iface ] : EMPTY_IMMUTABLE_ARR
});


export const makeGetPacketFilterUuid = () => {
    return createSelector(
        (state, uuid: HlcfgRowId) => getTableItem(state, uuid),
        (rule) => {
            if (doesntExists(rule)) {
                return EMPTY_IMMUTABLE_OBJ;
            }
            if (hlcfgRowObjectIsFromTable(rule, 'nftDivider')) {
                return rule;
            }
            if (hlcfgRowObjectIsFromTable(rule, 'nftRule')) {
                return strinfigyRule(rule);
            }
        }
    );
};

export const makeSelectPacketFilterIsFake = () => {
    return createSelector([
        (state, uuid: string) => getTableItem(state, uuid),
    ], (rule: Offable<'nftDivider' | 'nftRule'>) => {
        if (doesntExists(rule)) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        if (hlcfgRowObjectIsFromTable(rule, 'nftDivider')) {
            return rule.fake;
        }
        if (hlcfgRowObjectIsFromTable(rule, 'nftRule')) {
            return rule.fake;
        }
        return true;
    });
};

export const getPacketFilterChosen = createSelector([
    state => getTableItem(state, getChosenRuleUuid(state)),
], (rule) => {
    if (!rule) {
        return EMPTY_IMMUTABLE_OBJ;
    }
    return strinfigyRule(rule);
});


export const makeGetHasRuleError = () => createSelector([
    state => getVerificationErrors(state),
    (state, uuid) => uuid,
], (errors, uuid) => {
    return errors.map(error => error?.options?.uuid).includes(uuid);
});


//setters
export const setClosedToPacketFilterRules = ({ uuid, value }) =>
    ({ type: getGlcfgSetterActionType('packetFilterRules'), uuid, value, action: 'close' });

export const clearPacketFilterArray = () =>
    ({ type: CLEAR_PACKET_FILTER, action: 'clear' });

export const closeHeader = (payload) =>
    ({ type: CLOSE_HEADER, payload });

export const setNormalizeOrderPf = (actions) => ({ type: SET_NORMALIZE_ORDER_PF, actions });

const setNormalizeOrder = (props) =>
    ({  ...props, type: getGlcfgSetterActionType(props.type), action: 'order' });

export const setNormalizePfRule = (props) =>
    ({ ...props, type: getGlcfgSetterActionType(TABLES), action: 'setParameters' });

const workerClearPf = function* () {
    const items: OffableNftItem[] = yield select(getPacketFilterItems);
    const newIds: string[] = [];
    items.forEach(item => {
        if (item.fake) {
            newIds.push(item.id);
        }
    });
    yield put(setNormalizeOrder({ type: PACKET_FILTER_RULES, value: newIds }));

};

const workerSetNormalizeOrderPf = function* (props) {
    const { order } = props.actions;
    const items = yield select(getPacketFilterItems);
    const ids = yield select(getPacketFilterIds);
    const secondHeaderIdx = ids.indexOf(SECOND_DEFAULT_HEADER_UUID);

    const secondFakeBlockStart = secondHeaderIdx === -1 ? ids.length : secondHeaderIdx;

    const firstNonFakeRuleIdx = items.findIndex(item => !item.fake);
    const nonFakeBlockStart = firstNonFakeRuleIdx === -1 ? secondFakeBlockStart : firstNonFakeRuleIdx;

    const firstFakeBlock = ids.slice(0, nonFakeBlockStart);
    const secondFakeBlock = ids.slice(secondFakeBlockStart);

    const fakeIds = items.filter(item => item.fake).map(item => item.id);

    const newNonFakeBlock = order.filter(id => !fakeIds.includes(id));

    const newOrder = [
        ...firstFakeBlock,
        ...newNonFakeBlock,
        ...secondFakeBlock,
    ];

    yield put(setNormalizeOrder({ type: PACKET_FILTER_RULES, value: newOrder }));

};

const workerSetCloseHeader = function* (props) {
    const { uuid, value } = props.payload;
    const items = yield select(getPacketFilterItems);
    const ids = yield select(getPacketFilterIds);

    let startClosing = false;
    let closeFake = false;

    yield all(ids.map((id, index) => {
        if (startClosing && id.startsWith('nftDivider') || (closeFake && !items[index].fake)) {
            startClosing = false;
            return;
        }
        if (id === uuid) {
            startClosing = true;
            closeFake = items[index].fake;
        }

        if (startClosing) {
            return put(setNormalizePfRule({
                uuid: id,
                key: 'closed',
                value,
            }));
        }
    }));
};


const getExtraValues = ({ rule, values } : {rule: Offable<'nftRule'>, values: any}) => {
    const extraValues: Partial<Offable<'nftRule'>> = {};
    extraValues.action = rule.action === FORCE_ACCEPT_ACTION ? ACCEPT_ACTION : rule.action;
    if (values[rule.destinationAddress.list[0]?.toString() ?? 'undefined']) {
        extraValues.destinationAddress = {
            list: values[rule.destinationAddress.list[0]?.toString() ?? 'undefined']
        };
    }
    //static reference in service
    if (values[rule.service[0] as any]) {
        extraValues.service = values[rule.service[0] as any];
    }
    extraValues.fake = false;
    return extraValues;
};

const workerDuplicatePf = function* (props) {
    const { uuid } = props;
    const item: OffableNftItem = yield select(state => getTableItem(state, uuid));
    if (!item) {
        return;
    }
    const wpadAddresses = yield select(state =>  getGlcfgValue(state, PROXY)?.wpad?.addresses);
    const guiAddressesHTTPS = yield select(state => getGlcfgValue(state, GUI_ADDRESSES_HTTPS));
    const guiAddressesHTTP = yield select(state => getGlcfgValue(state, GUI_ADDRESSES_HTTP));
    const honeypot: HlcfgTypeHoneypot = yield select(state => getGlcfgValue(state, HONEYPOT));
    yield put(duplicateFromNormalize({
        parent: PACKET_FILTER_RULES,
        uuid: uuid,
        uuidToAddBefore: item.fake ? SECOND_DEFAULT_HEADER_UUID : undefined,
        extraValues: item.fake ? getExtraValues({ rule: strinfigyRule(item), values: {
            [STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN]: wpadAddresses || [],
            [STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS]: guiAddressesHTTPS || [],
            [STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS]: honeypot?.ports || [],
            [STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES]: honeypot?.addresses || [],
            [STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS]: guiAddressesHTTP ?
                [ parseAddress(`${HTTPS_PORT}`, netservice as any),
                    parseAddress(`${HTTP_PORT}`, netservice as any) ] :
                [ parseAddress(`${HTTPS_PORT}`, netservice as any) ]
        } }) : undefined
    }));
};

export const sagasPf = [
    takeEvery(CLEAR_PACKET_FILTER, workerClearPf),
    takeEvery(SET_NORMALIZE_ORDER_PF, workerSetNormalizeOrderPf),
    takeEvery(CLOSE_HEADER, workerSetCloseHeader),
    takeLatest(START_DUPLICATE, workerDuplicatePf)


];
