/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDataHook, getDomainToFindHook, getError, getIsLoadingHook, isInCategoriesRequest } from '~frontendDucks/isInCategories/index.js';
import Message from '~frontendComponents/Message/index.js';
import { Select } from '~frontendComponents/Generic/index.js';
import { netaddrSchema } from '~sharedLib/schemaTypes.ts';
import { NetaddrDomain } from '~sharedLib/Netaddr/NetaddrDomain.ts';
import { StringifyAddressReturnType } from '~frontendRoot/lib/addressUtils.ts';
import { WITHOUT_NAMED_OBJECTS } from '~commonLib/schemaFlags.ts';


const basicDomain = netaddrSchema({ domain: true }, { [WITHOUT_NAMED_OBJECTS]: true });


interface IsInCategoriesPureType {
    isLoading: boolean,
    error?: any,
    onChange: (opts: { value: NetaddrDomain }) => void,
    domain: StringifyAddressReturnType,
    data?: string[] | undefined,
}

const IsInCategoriesPure = ({ data, isLoading, error, onChange, domain }: IsInCategoriesPureType) => {
    return (
        <>
            <Select
                disabled={isLoading}
                error={error}
                id={'cwdbIsIn'}
                isCreatable
                isMulti
                label={<Message message={'widgets:Proxy.cwdb.isIn.title'} />}
                loading={isLoading}
                name="name"
                noDropdownIndicator
                noOptionsMessage
                onChange={onChange}
                paste={false}
                schema={basicDomain}
                value={domain}
            />
            <div className="cwdbIsIn">
                {data?.map(item => (
                    <span
                        className="cwdbIsIn--category"
                        key={item}
                    >{item}
                    </span>
                ))}
            </div>
        </>
    );

};

const IsInCategories = () => {
    const data = useSelector(getDataHook);
    const domainToFind = useSelector(getDomainToFindHook);
    const isLoading = useSelector(getIsLoadingHook);
    const error = useSelector(getError);

    const dispatch = useDispatch();
    const onChange = ({ value }) => {
        dispatch(isInCategoriesRequest({ domain: value }));
    };

    return (
        <IsInCategoriesPure
            data={data}
            domain={domainToFind}
            error={error}
            isLoading={isLoading}
            onChange={onChange}
        />
    );
};

export default IsInCategories;
