/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import {
    getVpnUsersIdsFromService, setNormalize
} from '~frontendDucks/hlcfgEditor/index.js';
import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { NEW_ROW_CONSTANT, SELECTABLE_TABLE_VPN_USERS, SMALL_SIZE } from '~frontendConstants/index.js';

import Row, { VpnPushToUserContext } from './Row/index.ts';


const CreateRow = ({
    dataIndex,
    spacing,
    uuid }: CreateRowPropsType) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid}
            spacing={spacing}
            uuid={uuid}
        />
    );
};

const VpnUserTable = () => {
    const dispatch = useDispatch();
    const { uuid } = useContext(VpnPushToUserContext);

    const users = useSelector(state => getVpnUsersIdsFromService(state, uuid));
    const data = useMemo(() => users?.length ? users : [ NEW_ROW_CONSTANT ], [ users ]);
    const passReorderData = useCallback((data) => {
        dispatch(setNormalize({ value: data, uuid: uuid, key: 'pushToUser' }));
    }, [ dispatch, uuid ]);


    return (
        <DatatableWidget
            columnsId={SELECTABLE_TABLE_VPN_USERS}
            createRow={CreateRow}
            data={data}
            passReorderData={passReorderData}
            spacing={SMALL_SIZE}
        />
    );
};


export default VpnUserTable;
