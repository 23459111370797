/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MDBCol,  } from 'mdbreact';


import Message from '~frontendComponents/Message/index.js';
import moment, { TIME_FORMAT } from '~commonLib/moment.ts';
import { buildnumParse } from '~commonLib/buildnumParse.ts';
import { NiceBox } from '~frontendComponents/Generic/NiceBox.tsx';
import { TestProps, testProps, testPropsPassthrough } from '~commonLib/PageObjectMap.ts';

import { versionDescriptionMap } from './pageObjectMap.ts';


interface VersionCardProps extends TestProps {
    children: ReactNode,
    isActive?: boolean,
    message: string,
}
export const formatTimestamp = (timestamp: string) => {
    return moment(timestamp).format(TIME_FORMAT.userDateTimeFull);
};
const Time = ({ time }: {time: string}) => {
    return formatTimestamp(time);
};
export const VersionCard = ({ children, isActive, message, ...rest }: VersionCardProps) => {
    return (
        <MDBCol
            lg="3"
            md="3"
            sm="12"
            {...testPropsPassthrough(rest)}
        >
            <NiceBox
                isActive={isActive}
                label={<Message message={message} />}
                labelClassName="upgrade__toggleBoxLabel"
            >
                {children}
            </NiceBox>
        </MDBCol>
    );
};
export type VersionToDisplay = {
    buildnum: string,
    installTimestamp?: string,
    droppedAt?: string,
    droppedBy?: {username: string},
    lastUsed?: string,
    lastConfigurationActivation?: string,
}
export const VersionDescription = ({ version, className }: { version?: VersionToDisplay, className?: string }) => {
    const { t } = useTranslation();
    if (!version) {
        return null;
    }
    const parsed = buildnumParse(version.buildnum);
    return (
        <ul
            className={`pl-0 ${className}`}
            {...testProps(versionDescriptionMap.id, { status: version.buildnum })}
        >
            {parsed.productVersion ? (
                <li>
                    <strong>{t('upgrade:version')}</strong>
                    {parsed.productVersion}
                </li>
            ) : null}
            {parsed.timestamp ? (
                <li>
                    <strong>{t('upgrade:released')}</strong>
                    <Time time={parsed.timestamp} />
                </li>
            ) : null}
            {version.installTimestamp ? (
                <li>
                    <strong>{t('upgrade:installed')}</strong>
                    <Time time={version.installTimestamp} />
                </li>
            ) : null}
            {version.lastUsed ? (
                <li>
                    <strong>{t('upgrade:lastUsed')}</strong>
                    <Time time={version.lastUsed} />
                </li>
            ) : null}
            {version.lastConfigurationActivation ? (
                <li>
                    <strong>{t('upgrade:lastActivation')}</strong>
                    <Time time={version.lastConfigurationActivation} />
                </li>
            ) : null}
            {version.droppedAt ? (
                <li>
                    <strong>{t('upgrade:discard')}</strong>
                    <Time time={version.droppedAt} />
                </li>
            ) : null}
            {version.droppedBy ? (
                <li>
                    <strong>{t('upgrade:user')}</strong>
                    {version.droppedBy.username}
                </li>
            ) : null}
        </ul>
    );
};
