/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Select } from '~frontendComponents/Generic/index.js';
import { Offable } from '~frontendRoot/constants/types.ts';
import { ClusterOwnSelector, NODE_A_ID } from '~commonLib/constants.ts';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';
import { NODE_A_LETTER, NODE_B_LETTER, TableSizeType } from '~frontendRoot/constants/index.js';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/TextWithTooltip.js';


interface PoolRowProps{
    pool: Offable<'dhcpPool'>
    node: ClusterOwnSelector
    off: true | undefined
    uuid: string
    spacing: TableSizeType
}

const PoolRow = ({ pool, node, off, spacing, uuid }: PoolRowProps) =>
{
    const { t } = useTranslation();
    if (!pool)
    {
        return <></>;
    }
    return (
        <tr className={classNames(
            { 'dataTableWidget__Row--disable': off },
        )}
        >
            <td className={classNames(
                'dataTableWidget__cell',
                'dataTableWidget__cell--icon',
                'dataTableWidget__cell--textAsIcon',
                { 'dataTableWidget__cell--disable': off },
                { [`dataTableWidget__cell--${spacing}`]: spacing },
            )}
            >
                <TextWithTooltip
                    className="textAsIcon textAsIcon--textColor p-1"
                    text={node === NODE_A_ID ? NODE_A_LETTER : NODE_B_LETTER}
                    tooltipText={t(`widgets:network.cluster.${node}`)}
                    withoutTranslation
                />
            </td>
            <td className={classNames(
                'dataTableWidget__cell',
                { [`dataTableWidget__cell--${spacing}`]: spacing },
            )}
            >
                <Select
                    className="dataTableWidget__RowInput"
                    classNamePrefix="packetFilterSelect"
                    disabled={off}
                    fake
                    id={'poolRowRangeFrom' +  node + uuid}
                    isCreatable
                    isMulti
                    isRow
                    name="destination"
                    noDropdownIndicator
                    noOptionsMessage
                    noWrap
                    value={stringifyAddress(pool.rangeFrom)}
                />
            </td>
            <td className={classNames(
                'dataTableWidget__cell',
                { [`dataTableWidget__cell--${spacing}`]: spacing },
            )}
            >
                <Select
                    className="dataTableWidget__RowInput"
                    classNamePrefix="packetFilterSelect"
                    disabled={off}
                    fake
                    id={'poolRowRangeTo' +  node + uuid}
                    isCreatable
                    isMulti
                    isRow
                    name="destination"
                    noDropdownIndicator
                    noOptionsMessage
                    noWrap
                    value={stringifyAddress(pool.rangeTo)}
                />
            </td>
            {/* fake colums to stratch the row*/}
            <td />
            <td />
        </tr>
    );
};
export default PoolRow;
