/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { MDBListGroupItem, MDBBtn, MDBWaves } from 'mdbreact';
import classNames from 'classnames';

import { TransitionSwipe } from '../../../components/Generic/Transition/index.js';
import Icon from '../../../../frontend/components/Generic/Icon/index.ts';


class ListItem extends Component {
    static get propTypes() {
        return {
            action: PropTypes.object.isRequired,
            handleDelete: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            deleteItem: true,
            cursorPos: {},
            active: false,
            charCount: 66,
        };

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    // Set the wrapper ref
    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    //Alert if clicked on outside of element
    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ active: false, charCount: 66 });
        }
    };

    handleClick = (event) => {
        event.stopPropagation();
        // Waves - Get Cursor Position
        const cursorPos = {
            top: event.clientY,
            left: event.clientX,
            time: Date.now(),
        };
        this.setState({ cursorPos: cursorPos, active: true });
    };

    render() {
        const { action, handleDelete } = this.props;
        const { deleteItem, cursorPos, active, charCount } = this.state;
        let name = action.name;
        if (action.name.length > charCount) {
            name = action.name.substring(0, charCount);
            name = name.substr(0, Math.min(name.length, name.lastIndexOf(' '))) + '\u2026';
        }

        //We need to start with div to set wrapper
        //TODO: Component that create wrapperRef
        return (
            <li
                className={classNames(
                    'listWidgetItemWrap',
                    { 'listWidgetItemWrap--active': !deleteItem }
                )}
                ref={this.setWrapperRef}
            >
                <TransitionSwipe
                    in={deleteItem}
                    timeout={500}
                >
                    <MDBListGroupItem
                        className={classNames(
                            'listWidgetItem',
                            { 'listWidgetItem--active': active }
                        )}
                        onClick={event => {
                            event.defaultPrevented;
                            this.setState({ charCount: 100 });
                            const root = document.documentElement;
                            root.style.setProperty('--heightOfItem', '-' + this.wrapperRef.offsetHeight + 'px');
                        }}
                        onMouseUp={this.handleClick}
                        onTouchStart={this.handleClick}
                        tag="div"
                    >
                        <MDBWaves
                            cursorPos={cursorPos}
                            dark
                        />
                        <div className="listWidgetItem__label">{name}</div>
                        <MDBBtn
                            className={classNames(
                                'listWidgetItem__icon',
                                { 'listWidgetItem__icon--active': active }
                            )}
                            color="primary"
                            onClick={event => {
                                event.defaultPrevented;
                                this.setState({ deleteItem: false });
                                setTimeout(
                                    function() {
                                        handleDelete(action.id);
                                        const root = document.documentElement;
                                        root.style.setProperty('--heightOfItem', 0);
                                    },
                                    1600,
                                );
                            }}
                            outline
                        >
                            <Icon name="close" />
                        </MDBBtn>
                    </MDBListGroupItem>
                </TransitionSwipe>
            </li>
        );
    }
}
export default ListItem;
