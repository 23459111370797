/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';

import { Icon, Input, Select } from '~frontendComponents/Generic/index.js';
import RowDivider from '~frontendComponents/RowDivider.tsx';
import { DNS_PROXY_PROFILE_RULE_COLUMNS_ACTION, DNS_PROXY_PROFILE_RULE_COLUMNS_CLIENT, DNS_PROXY_PROFILE_RULE_COLUMNS_DRAG,
    DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_ADDRESS, DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_TTL,
    DNS_PROXY_PROFILE_RULE_COLUMNS_HOST, DNS_PROXY_PROFILE_RULE_COLUMNS_NAME,
    DNS_PROXY_PROFILE_RULE_COLUMNS_NAME_SERVERS, DNS_PROXY_PROFILE_RULE_COLUMNS_RECORD_TYPE,
    INT_REGEX,
    SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES, userSetting } from '~frontendConstants/constants.ts';
import { getTableSchemaGetter } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSetters.js';
import { getColumnsUtils } from '~frontendLib/columnUtils.ts';
import { useUserSetting } from '~frontendLib/hooks/userSettings.ts';
import { useCombinedRefs } from '~frontendLib/reactUtils.js';
import RowMenuAndSwitch from '~frontendComponents/RowMenuAndSwitch.tsx';
import { DatatableContext } from '~frontendRoot/scenes/Protection/scenes/Waf/WafProfile/components/ProfileRules/ProfileRules.tsx';
import { getObjectMatch } from '~frontendLib/objectUtils.js';
import { makeSelectGetDnsProxyProfileHeader, makeSelectGetDnsProxyProfileRule } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { netaddr } from '~commonLib/Netaddr/Netaddr.ts';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/NetworkInterfaceDevice.tsx';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { HlcfgTableItemSchema } from '~frontendRoot/types/externalTypes.ts';
import createOptionsArray, { OptionObjectType } from '~frontendComponents/Generic/Select/utils.tsx';
import { useConstant } from '~frontendLib/hooks/defaultHooks.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';


export const RenderEmptyRule = (props: { addRow, t, addHeader }) => {

    const { t, addRow, addHeader } = props;
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES);

    return (
        <tr
            className="dataTableWidget__RowFirstRule"
            key="newRule"
        >
            <td />
            <td
                className="dataTableWidget__RowFirstRuleRow"
                colSpan={getColumnsSelectedLength() - 1}
            >
                <div className="pl-2">
                    <h2>{t('profile:dnsProxy.rules.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('profile:dnsProxy.rules.title')}

                        {t('profile:dnsProxy.rules.desc1')}
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            onClick={addRow}
                        >
                            <Icon name="plus" /> {t('profile:dnsProxy.rules.rule')}
                        </MDBBtn>
                        {t('profile:dnsProxy.rules.desc2')}
                        <MDBBtn
                            className="packetFilter__addButtons--button"
                            data-cy="adding-header-button"
                            onClick={addHeader}
                        >
                            <Icon name="plus" /> {t('packetFilter:header')}
                        </MDBBtn>
                        {t('profile:dnsProxy.rules.desc3')}
                    </p>
                    <p>
                        {t('profile:dnsProxy.rules.desc4')}
                        <Icon
                            name="menu"
                            size="sm"
                        />
                        {t('profile:dnsProxy.rules.desc5')}
                    </p>
                    <MDBBtn
                        className="dataTableWidget__RowAddButtons--button"
                        onClick={addRow}
                    >
                        <Icon name="plus" /> {t('profile:dnsProxy.rules.rule')}
                    </MDBBtn>
                    <MDBBtn
                        className="packetFilter__addButtons--button"
                        data-cy="adding-header-button"
                        onClick={addHeader}
                    >
                        <Icon name="plus" /> {t('packetFilter:header')}
                    </MDBBtn>
                </div>
            </td>
        </tr>
    );
};

export const RenderDivider = (props: { provided, spacing, types, uuid, setValue, deleteRow, copyRow, closeRules }) => {

    const { provided, spacing, types, uuid, setValue, deleteRow, copyRow, closeRules } = props;
    const getter = useConstant(makeSelectGetDnsProxyProfileHeader(uuid));
    const data = useSelector(getter);
    const schema: HlcfgTableItemSchema<'dnsProxyHeader'> = useSelector(getTableSchemaGetter(uuid));
    const ref = useCombinedRefs(provided.innerRef);
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] =
        getColumnsUtils(allColumns, SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES);
    if (!data) {
        return null;
    }
    const { id, name, closed } = data;
    if (!id) {
        return null;
    }
    return (
        <>
            <RowDivider
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
            <tr
                ref={ref}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classNames(
                    'dataTableWidget__Row--important',
                    'dataTableWidget__header',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                )}
                key={id}
            >
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                    onClick={() => closeRules({ value: !closed })}
                >

                    <Icon name={closed ? 'chevron-right' : 'chevron-down'} />

                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                    colSpan={getColumnsSelectedLength() - 2}
                >
                    <Input
                        className="dataTableWidget__RowInput"
                        id={'profileName' + id}
                        isName
                        name="name"
                        onChange={setValue}
                        schema={schema?.name}
                        value={name}
                        withoutBorder
                    />

                </td>
                <RowMenuAndSwitch
                    copyFunc={copyRow}
                    deleteButtonDisabled={closed}
                    deleteFunc={deleteRow}
                    id={'dnsProxyHeader' + id}
                    spacing={spacing}
                />
            </tr>
            <RowDivider
                after
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
        </>
    );
};

const actions: OptionObjectType[] = [
    {
        name: 'resolve',
        color: 'green',
        iconName: 'shield-check-outline',
    },
    {
        name: 'root-resolve',
        color: 'green',
        iconName: 'web'
    },
    {
        name: 'deny',
        color: 'red',
        iconName: 'shield-off-outline'
    },
    {
        name: 'forward',
        color: 'primary',
        iconName: 'loopback'
    },
    {
        name: 'fake',
        color: 'primary',
        iconName: 'account-multiple'
    },
];
const options = createOptionsArray(actions, 'profile:dnsProxy.action.');


export const RenderRule = (props: {provided, copyRow, deleteRow, setValue, spacing, types, uuid}) => {

    const { provided, copyRow, deleteRow, setValue, spacing, types, uuid } = props;
    const getter = useConstant(makeSelectGetDnsProxyProfileRule(uuid));
    const data = useSelector(getter);
    const schema: HlcfgTableItemSchema<'dnsProxyRule'> = useSelector(getTableSchemaGetter(uuid));

    const { search } = useContext(DatatableContext);
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ getColumnsShow, getColumnsSelectedLength ] =
     getColumnsUtils(allColumns, SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES);
    const [ matches, setMatches ] = useState([]);
    useEffect(() => {
        setMatches(getObjectMatch({
            object: data, searchValue: search, net: netaddr, exceptions: [], matchesProp: [] }));
        // eslint requires also data as dependencies but for optimalization reasons and that we need to implement
        // better way for searching in table we will ignore it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ search ]);

    const ref = useCombinedRefs(provided.innerRef);
    if (!data) {
        return null;
    }
    const { id, name, matchQname, matchQtype, matchSrc, action, actionFakeAddr,
        actionFakeTtl, actionNsList,  __off, closed } = data;
    if (closed) {
        return null;
    }
    if (!id) {
        return null;
    }

    const shouldDisplayNsList = action === 'resolve' || action === 'forward';
    const shouldDisplayFakeActionParams = action === 'fake';

    const validateTtl = (value) => {
        return value.match(INT_REGEX);
    };

    return (
        <>
            <RowDivider
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
            <tr
                className={classNames(
                    'dataTableWidget__Row',
                    'profiles__row',
                    { 'dataTableWidget__Row--disable': __off },
                    { 'dataTableWidget__Row--match': matches.length },
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
                ref={ref}
                {...provided.draggableProps}
                key={id}
            >
                {getColumnsShow(DNS_PROXY_PROFILE_RULE_COLUMNS_DRAG) ? (
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            'dataTableWidget__cell--icon',
                            { 'dataTableWidget__cell--match': matches.length },
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                        {...provided.dragHandleProps}
                    >
                        <Icon
                            name="drag"
                            size="sm"
                        />
                    </td>
                ) : null}
                {getColumnsShow(DNS_PROXY_PROFILE_RULE_COLUMNS_ACTION) ? (
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            'dataTableWidget__cell--icon',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <Select
                            className={classNames(
                                'dataTableWidget__RowInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                            )}
                            disabled={__off}
                            id={'type' + id}
                            isRow
                            justIcon
                            name={'action'}
                            noDropdownIndicator
                            noOptionsMessage
                            noWrap
                            onChange={setValue}
                            options={options}
                            paste={false}
                            value={action}
                        />
                    </td>
                ) : null}
                {getColumnsShow(DNS_PROXY_PROFILE_RULE_COLUMNS_NAME) ? (
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <Input
                            className={classNames(
                                'dataTableWidget__RowInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                'packetFilter__nameInput'
                            )}
                            disabled={__off}
                            id={'name' + id}
                            inputClass="'dataTableWidget__cell--leftPadding'"
                            isName
                            name="name"
                            onChange={setValue}
                            schema={schema?.name}
                            value={name}
                            withoutBorder
                            withoutPaddingLeft
                        />
                    </td>
                ) : null}
                {getColumnsShow(DNS_PROXY_PROFILE_RULE_COLUMNS_HOST) ? (
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <Select
                            className={classNames(
                                'dataTableWidget__RowInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                            )}
                            disabled={__off}
                            id={'matchQame' + id}
                            isCreatable
                            isMulti
                            isRow
                            maxValueShown={3}
                            name="matchQname"
                            noDropdownIndicator
                            nowrap
                            onChange={setValue}
                            paste={false}
                            schema={schema.matchQname}
                            value={matchQname}
                        />
                    </td>
                ) : null}
                {getColumnsShow(DNS_PROXY_PROFILE_RULE_COLUMNS_CLIENT) ? (
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <NetworkInterfaceDevice
                            className={classNames(
                                'dataTableWidget__RowInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                            )}
                            disabled={__off}
                            id={'client' + id}
                            iface={matchSrc}
                            isCreatable
                            isMulti
                            isRow
                            maxValueShown={3}
                            name="matchSrc"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={setValue}
                            schema={schema.matchSrc}
                        />
                    </td>
                ) : null}
                {getColumnsShow(DNS_PROXY_PROFILE_RULE_COLUMNS_RECORD_TYPE) ? (
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <Select
                            className={classNames(
                                'dataTableWidget__RowInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                            )}
                            disabled={__off}
                            enumTranslationDifferPath={'differs:tables.dnsProxyRule.matchQtype'}
                            id={'matchQtype' + id}
                            isMulti
                            isRow
                            maxValueShown={3}
                            name="matchQtype"
                            onChange={setValue}
                            schema={schema.matchQtype}
                            value={matchQtype}
                        />
                    </td>
                ) : null}
                {getColumnsShow(DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_ADDRESS) ? (
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        {shouldDisplayFakeActionParams ?
                            <NetworkInterfaceDevice
                                className={classNames(
                                    'dataTableWidget__RowInput',
                                    { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                )}
                                disabled={__off}
                                id={'fakeAddress' + id}
                                iface={actionFakeAddr}
                                isCreatable
                                isRow
                                name="actionFakeAddr"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={setValue}
                                schema={schema.actionFakeAddr}
                            /> :
                            <IconWithTooltip
                                className="icon--secondary"
                                iconSize="sx"
                                link
                                name="minus"
                                tooltipText="profile:dnsProxy.onlyFakeAction"
                            />}
                    </td>
                ) : null}
                {getColumnsShow(DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_TTL) ? (
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        {shouldDisplayFakeActionParams ?
                            <Select
                                className={classNames(
                                    'dataTableWidget__RowInput',
                                    { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                )}
                                disabled={__off}
                                id={'actionFakeTtl' + id}
                                isCreatable
                                isMulti
                                isRow
                                name="actionFakeTtl"
                                noDropdownIndicator
                                noWrap
                                onChange={setValue}
                                paste={false}
                                placeholder={schema.actionFakeTtl[DEFAULT_SCHEMA_VALUE]}
                                schema={schema.actionFakeTtl}
                                validator={validateTtl}
                                value={actionFakeTtl !== undefined ? [ actionFakeTtl ] : []}
                            /> :
                            <IconWithTooltip
                                className="icon--secondary"
                                iconSize="sx"
                                link
                                name="minus"
                                tooltipText="profile:dnsProxy.onlyFakeAction"
                            />}
                    </td>
                ) : null}
                {getColumnsShow(DNS_PROXY_PROFILE_RULE_COLUMNS_NAME_SERVERS) ? (
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        {shouldDisplayNsList ?
                            <NetworkInterfaceDevice
                                className={classNames(
                                    'dataTableWidget__RowInput',
                                    { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                )}
                                disabled={__off}
                                id={'nameServers' + id}
                                iface={actionNsList}
                                isCreatable
                                isMulti
                                isRow
                                maxValueShown={3}
                                name="actionNsList"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={setValue}
                                schema={schema.actionNsList}
                            /> :
                            <IconWithTooltip
                                className="icon--secondary"
                                iconSize="sx"
                                link
                                name="minus"
                                tooltipText="profile:dnsProxy.onlyResolvForwardActions"
                            />}
                    </td>
                ) : null}
                <RowMenuAndSwitch
                    __off={__off}
                    copyFunc={copyRow}
                    deleteFunc={deleteRow}
                    id={'dnsProxyRuleMenu' + id}
                    onChange={setValue}
                    spacing={spacing}
                />
            </tr>
            <RowDivider
                after
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
        </>
    );
};
