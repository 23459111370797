/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { notEmpty } from '~commonLib/arrayUtils.ts';


export interface Difference {
    path: string[],
    fromValue: unknown,
    toValue: unknown,
}

const isObj = (val): val is object => {
    return typeof val === 'object' && val;
};
const simpleArraysAreSame = (fromArr: unknown[], toArr: unknown[]) => {
    // JSON.stringify is not proper way to do this, but in our use case
    // we expect the objects in arrays to be very small, so it should work fine
    // and doing it properly(much faster) here is not required (for now).
    return fromArr.length === toArr.length && JSON.stringify(fromArr) === JSON.stringify(toArr);
};

/**
 * @description Simple arrays means that arrays are deep compared
 *                and if they do not match, path of the array is reported.
 *                In another words, reported path will never point "into" array
 */
export const createDiffListForObjectWithSimpleArrays =
    (fromObj: object, toObj: object): Difference[] => {
        const diffList: Difference[] = [];


        const recurseObj = ({ fromObj, toObj, path }) => {
            const fromKeys = Object.keys(fromObj);
            const toKeys = Object.keys(toObj);
            const allKeys = new Set([ ...fromKeys, ...toKeys ]);


            allKeys.forEach(key => {
                const fromVal = fromObj[key];
                const toVal = toObj[key];
                const diff = {
                    path: [ ...path, key ],
                    fromValue: fromVal,
                    toValue: toVal,
                };
                if (notEmpty(fromVal) !== notEmpty(toVal)) {
                    diffList.push(diff);
                    return;
                }
                if (typeof fromVal  !== typeof toVal) {
                    diffList.push(diff);
                    return;
                }
                if (Array.isArray(fromVal) && Array.isArray(toVal)) {
                    if (!simpleArraysAreSame(fromVal, toVal)) {
                        diffList.push(diff);
                    }
                    return;
                }
                if (isObj(fromVal) && isObj(toVal)) {
                    recurseObj({
                        fromObj: fromVal, toObj: toVal,
                        path: [ ...path, key ],
                    });
                    return;
                }
                if (fromVal !== toVal) {
                    diffList.push(diff);
                    return;
                }
            });
        };
        recurseObj({ fromObj, toObj, path: [] });
        return diffList;
    };
