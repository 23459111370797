/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from 'mdbreact';
import classNames from 'classnames';

import { Icon } from '~frontendComponents/Generic/index.js';
import { setDataRequest, getDhcpdData, getIsLoading } from '~frontendDucks/dhcpd/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/index.js';
import { SELECTABLE_TABLE_DHCP_ACTUAL_LEASES } from '~frontendRoot/constants/index.js';

import { DhcpdDatatableContext } from '../DhcpdDatatableContext/index.ts';
import DhcpdDatatable from '../DhcpdDatatable/index.ts';


const makeMapStateToProps = () => {
    const dhcpGetter = getDhcpdData();
    const mapStateToProps = (state, { uuid }) => {
        return {
            data: dhcpGetter(state, uuid) || [],
            loading: getIsLoading(state)

        };
    };
    return mapStateToProps;
};
@withTranslation()
@connect(
    makeMapStateToProps,
    {
        request: setDataRequest
    }
)
class DhcpLeases extends Component {
    static get propTypes() {
        return {
            uuid: PropTypes.string,
            t: PropTypes.func,
            request: PropTypes.func,
            data: PropTypes.array,
            loading: PropTypes.bool,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            shownLeases: false,
        };
    }

    hideLeases = () => {
        const { shownLeases } = this.state;
        this.setState({
            shownLeases: !shownLeases
        });
    };

    reload = () => {
        const { request } = this.props;
        request();
    };

    render() {
        const { t, data, loading, uuid
        } = this.props;
        const { shownLeases } = this.state;


        return (
            <MDBCard>
                <MDBCardTitle className={classNames(
                    'cardHide__title',
                    { 'cardHide__title--noBorderBottom': shownLeases },
                )}
                >
                    <div
                        className="clicable"
                        onClick={this.hideLeases}
                    >
                        {t('widgets:Dhcpd.header.leases')}
                        <Icon
                            name={shownLeases ? 'chevron-up' : 'chevron-down'}
                        />
                    </div>
                    <MDBRow className="">
                        <MDBCol className="profile__icons">
                            <TextWithTooltip
                                className={classNames(
                                    { 'icon--green': data.length },
                                    { 'icon--pulsing': data.length },
                                    { 'icon--secondary': !data.length },
                                    'm-1'
                                )}
                                text={t('widgets:Dhcpd.actualLeases.active.title', { active: data.length })}
                                tooltipText={t('widgets:Dhcpd.actualLeases.active.desc')}
                                withoutTranslation

                            />
                            <IconWithTooltip
                                className="icon--secondary m-1"
                                iconSize="sm"
                                link
                                loading={loading}
                                name="reload"
                                onClick={this.reload}
                                tooltipText={t('widgets:global.reload')}
                                withoutTranslation
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardTitle>

                <MDBCardBody  className={classNames('cardHide__body', 'p-0',
                    { 'cardHide__body--hide': !shownLeases },
                    { 'cardHide__title--borderBottom': shownLeases },)}
                >
                    <DhcpdDatatableContext.Provider value={
                        {  parentUuid: uuid,
                            type: SELECTABLE_TABLE_DHCP_ACTUAL_LEASES
                        }}
                    >
                        <DhcpdDatatable
                            ids={data}
                        />
                    </DhcpdDatatableContext.Provider>

                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default DhcpLeases;
