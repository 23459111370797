/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useTranslation } from 'react-i18next';


type TranslationParams = {
    [k: string]: string | {
        message: string,
        dontTranslate: boolean
    }
}

type Props = {
    message: string,
    params?: TranslationParams,
    translateParams?: boolean
}

const Message = ({ message, params, translateParams }: Props) => {
    const { t } = useTranslation();
    if (translateParams) {
        const translatedParams = {};
        for (const param in params) {
            const translationItem = params[param];
            if (typeof translationItem === 'object') {
                if (translationItem.dontTranslate) {
                    translatedParams[param] = translationItem.message;
                } else {
                    translatedParams[param] = t(translationItem.message, {});
                }
            } else {
                translatedParams[param] = t(translationItem, {});
            }

        }
        return (<>{t(message, translatedParams)}</>);
    } else {
        return (<>{t(message, params)}</>);
    }
};


export default Message;
