/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { MDBCard, MDBCardTitle, MDBListGroup, MDBCardFooter, MDBListGroupItem } from 'mdbreact';
import classNames from 'classnames';

import Message from '~frontendComponents/Message/index.js';
import ApiError from '~frontendComponents/Generic/ApiError/index.js';

import ListItem from '../components/ListItem/index.js';
import { typeApiError } from '../../types/index.js';


class ListWidget extends Component {
    static get propTypes() {
        return {
            data: PropTypes.array.isRequired,
            className: PropTypes.string,
            title: PropTypes.string.isRequired,
            doRemoveFromList: PropTypes.func,
            isLoading: PropTypes.bool,
            error: typeApiError
        };
    }

    constructor(props) {
        super(props);
        const { data } = this.props;
        this.state = {
            data: data,
        };
    }

    handleDelete = (id) => {
        this.props.doRemoveFromList(id);
    };

    render() {
        const { data } = this.state;
        const { title, className, error, isLoading } = this.props;
        return (
            <MDBCard className={classNames(
                className,
                'listWidget'
            )}
            >
                <MDBCardTitle>
                    <Message message={title} />
                </MDBCardTitle>
                {
                    //class to create borders between items
                }
                <ApiError
                    className="listWidget__loader"
                    data={data}
                    error={error}
                    isLoading={isLoading}
                >
                    <MDBListGroup className="list-group-flush listWidget__list">
                        {data.length !== 0 ?
                            data.map(action => (
                                <ListItem
                                    action={action}
                                    handleDelete={this.handleDelete}
                                    key={action.id}
                                />
                            )) :
                            <MDBListGroupItem className="listWidget__resolve">
                                <Message message="components:EverythingGood" />
                            </MDBListGroupItem>}
                    </MDBListGroup>
                </ApiError>
                {data.length > 4 ? <MDBCardFooter className="listWidget__footer" /> : null}
            </MDBCard>
        );
    }
}

export default ListWidget;
