/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardHeader } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Switch } from '~frontendComponents/Generic/index.js';
import {
    getAntivirusPolicy,
    getAntivirusPolicySchema,
    setGlcfgObjectValue,
    getAntivirusIgnoreError
} from '~frontendDucks/hlcfgEditor/index.js';
import Message from '~frontendComponents/Message/index.js';
import { ANTIVIRUS } from '~frontendConstants/index.js';
import Slider from '~frontendComponents/Slider/Slider.tsx';


const AntivirusService = () => {

    const [ t ] = useTranslation();
    const Dispatch = useDispatch();
    const antivirusPolicy = useSelector(state => getAntivirusPolicy(state));
    const antivirusPolicySchema = useSelector(state => getAntivirusPolicySchema(state));
    const antivirusIgnoreError = useSelector(state => getAntivirusIgnoreError(state));

    const changeValue = useCallback(({ value, name }) => {
        Dispatch(setGlcfgObjectValue(ANTIVIRUS, value, name));
    }, [ Dispatch ]);

    return (
        <MDBRow>
            <MDBCol
                className="mb-4"
                lg="4"
                md="4"
                sm="12"
            >
                <MDBCard>
                    <MDBCardHeader>
                        <Message message="widgets:Antivirus.title" />
                    </MDBCardHeader>
                    <MDBCardBody>
                        <Slider
                            id="policy"
                            label={t('widgets:Antivirus.policy.title')}
                            maxValue={antivirusPolicySchema?.enum?.length - 1}
                            minValue={0}
                            name="policy"
                            onChange={changeValue}
                            schema={antivirusPolicySchema}
                            value={antivirusPolicy}
                        />
                        <>
                            <h5>{t(`help:validInputs.enum.${antivirusPolicy}.title`)}</h5>
                            <p>
                                {t(`help:validInputs.enum.${antivirusPolicy}.desc`)}
                            </p>
                        </>
                        <Switch
                            align="spaceBetween"
                            checked={antivirusIgnoreError}
                            id="ignoreError"
                            label={t('widgets:Antivirus.ignoreError.title')}
                            name="ignoreError"
                            onChange={changeValue}
                        />
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
};

export default AntivirusService;
