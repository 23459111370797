/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCol, MDBBtn } from 'mdbreact';
import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import assert from 'assert';

import { Switch, Icon, Select, InputFile } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import { VPN_SETTING_MENU_ADVANCED, VPN_SETTING_MENU_FILES, VPN_SETTING_MENU_ROUTE } from '~frontendRoot/constants/index.js';
import { selectOptionsListWanLan, getInternalExternal } from
    '~frontendScenes/Configuration/scenes/Network/scenes/Network/components/NetworkDatatable/components/Row/Row.tsx';
import { hlcfgRowIdIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import { MDBColSizeProp } from '~frontendConstants/types.ts';


const VpnSwitchPropTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.bool,
    func: PropTypes.func.isRequired,
    __off: PropTypes.bool,
    schema: PropTypes.object, //just for easier control, but we know here its boolean schema
    message: PropTypes.string,
    size: MDBColSizeProp,
};

export const VpnSwitch = (
    { id, value, func, __off, message, schema, size }:InferProps<typeof VpnSwitchPropTypes>
) => {
    assert(size !== 'auto', 'auto not supported in lg');
    return (
        <MDBCol lg={size ?? undefined}>
            <Switch
                align="spaceBetween"
                checked={value}
                disabled={__off}
                id={id}
                label={<Message message={`widgets:Vpn.${id}.title`} />}
                message={message}
                name={id}
                onChange={func}
                schema={schema}
            />
        </MDBCol>
    ); };
VpnSwitch.propTypes = VpnSwitchPropTypes;


const VpnInputPropTypes = {
    id: PropTypes.string,
    uuid: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.shape({
        name: PropTypes.string,
    }),
    func: PropTypes.func,
    __off: PropTypes.bool,
    hlcfgPath: PropTypes.string
};

export const VpnInput = (
    { id, name, func, value, __off, uuid, hlcfgPath }: InferProps<typeof VpnInputPropTypes>
) => {
    const translationKey = uuid && hlcfgRowIdIsFromTable(uuid, hlcfgTableName.openvpnClient) ? 'client' + id : id;
    return (
        <InputFile
            className="license__upload"
            disabled={Boolean(__off)}
            fakeFile={value?.name}
            hlcfgPath={`${hlcfgPath}.${name}.${id}`}
            id={id}
            label={<Message message={`widgets:Vpn.files.${translationKey}.title`} />}
            name={name}
            onChange={func}
            renderId={uuid}
        />
    );
};
VpnInput.propTypes = VpnInputPropTypes;


const VpnInputWithColPropTypes = {
    id: PropTypes.string,
    uuid: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.object,
    func: PropTypes.func,
    __off: PropTypes.bool,
    hlcfgPath: PropTypes.string
};

export const VpnInputWithCol = (
    { id, name, func, value, __off, uuid, hlcfgPath }: InferProps<typeof VpnInputWithColPropTypes>
) => (
    <MDBCol>
        <VpnInput
            __off={__off}
            func={func}
            hlcfgPath={hlcfgPath}
            id={id}
            name={name}
            uuid={uuid}
            value={value}
        />
    </MDBCol>
);
VpnInputWithCol.propTypes = VpnInputWithColPropTypes;


const VpnOptionButtonPropTypes = {
    item: PropTypes.string.isRequired,
    value: PropTypes.string,
    func: PropTypes.func.isRequired,
    __off: PropTypes.bool,
};


export const VpnOptionButton = ({ item, value, func, __off }: InferProps<typeof VpnOptionButtonPropTypes>) => (
    <MDBBtn
        color={value === item ? 'primary' : undefined}
        disabled={Boolean(__off)}
        onClick={() => {
            func({ value: item, name: 'tlsAuth', id: 'option' });
        }}
        outline={value !== item}
    >
        {item?.length ? item : <Message message="widgets:Vpn.tlsAuth.option.nullValue" />}
    </MDBBtn>
);

VpnOptionButton.propTypes = VpnOptionButtonPropTypes;


const getMenuIcon = (item) => {
    switch (item) {
    case VPN_SETTING_MENU_FILES:
        return 'file-upload-outline';
    case VPN_SETTING_MENU_ROUTE:
        return 'table-icon';
    case VPN_SETTING_MENU_ADVANCED:
        return 'settings-outline';
    default:
        throw new Error('Unknown item type');
    }
};

const MenuItemPropTypes = {
    item: PropTypes.string,
    selected: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ]),
};

const MenuItem = ({ item, selected }: InferProps<typeof MenuItemPropTypes>) => {
    return (
        <div className={classNames('scrollMenu__item',
            'card-title',
            'scrollMenu__item--primary',
            { 'scrollMenu__item--selected': selected })}
        >
            <Icon
                className="icon--textColor mr-1"
                name={getMenuIcon(item)}
                size="sm"
            />
            <Message message={`widgets:Vpn.${item}.title`} />
        </div>
    );
};
MenuItem.propTypes = MenuItemPropTypes;

const CreateMenuPropTypes = {
    selected: PropTypes.string,
};

export const CreateMenu = ({ selected }: InferProps<typeof CreateMenuPropTypes>) => {
    return (
        [ VPN_SETTING_MENU_FILES, VPN_SETTING_MENU_ROUTE, VPN_SETTING_MENU_ADVANCED ].map(
            item => {
                return (
                    <MenuItem
                        item={item}
                        key={item}
                        selected={selected}
                    />
                );
            }
        )
    );
};
CreateMenu.propTypes = CreateMenuPropTypes;


export const GetIcon = () => {
    return (
        <Icon
            className="vpn__chevron"
            name="chevron-right"
        />
    );
};


const GetSelectWithOptionsPropTypes = {
    __off: PropTypes.bool,
    changeValue: PropTypes.func,
    interfaceTopology: PropTypes.string,
    uuid: PropTypes.string,
    changeValueWanLan: PropTypes.func,
    isInternal: PropTypes.bool,
    isExternal: PropTypes.bool,
    interfaceTopologySchema: PropTypes.object,
    size: MDBColSizeProp
};

export const GetSelectWithOptions = ({ __off, changeValue, interfaceTopology, uuid, changeValueWanLan,
    isInternal, isExternal,  interfaceTopologySchema, size } : InferProps<typeof GetSelectWithOptionsPropTypes>) => {
    const lg = size ?? '2';
    assert(lg !== 'auto', 'auto not supported for lg');
    return (
        <>
            <MDBCol lg={lg}>
                <Select
                    disabled={__off}
                    id="interfaceTopologyId"
                    label={<Message message="widgets:Vpn.interfaceTopology.title" />}
                    message="dev topology"
                    name="interfaceTopology"
                    noOptionsMessage
                    onChange={changeValue}
                    paste={false}
                    schema={interfaceTopologySchema}
                    value={interfaceTopology}
                />
            </MDBCol>
            <MDBCol lg={lg}>
                <Select
                    disabled={__off}
                    id={'wanLan' + uuid}

                    label={<Message message="widgets:Vpn.wanLan.title" />}
                    name="wanLanName"
                    onChange={changeValueWanLan}
                    options={selectOptionsListWanLan}
                    paste={false}
                    value={getInternalExternal(isInternal, isExternal)}
                />
            </MDBCol>
        </>
    );
};

GetSelectWithOptions.propTypes = GetSelectWithOptionsPropTypes;
