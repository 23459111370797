/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { InvalidPortError, netport } from '~sharedLib/Netport/Netport.ts';
import { InvalidNetaddrError, netaddr } from '~sharedLib/Netaddr/Netaddr.ts';


export const existingSuricataAddressVariables = [
    '$HOME_NET',
    '$EXTERNAL_NET',
    '$HTTP_SERVERS',
    '$SMTP_SERVERS',
    '$SQL_SERVERS',
    '$DNS_SERVERS',
    '$TELNET_SERVERS',
    '$AIM_SERVERS',
    '$DNP3_SERVER',
    '$DNP3_CLIENT',
    '$MODBUS_CLIENT',
    '$MODBUS_SERVER',
    '$ENIP_CLIENT',
    '$ENIP_SERVER',
];
export const existingSuricataPortVariables = [
    '$HTTP_PORTS',
    '$SHELLCODE_PORTS',
    '$ORACLE_PORTS',
    '$SSH_PORTS',
    '$DNP3_PORTS',
    '$MODBUS_PORTS',
    '$FILE_DATA_PORTS',
    '$FTP_PORTS'
];

const suricataAddressVariablesValidation = (varValue, errors) => {
    try {
        if (netaddr(varValue).isValid()) {
            // valid ip4 address
            return;
        }
    } catch (error) {
        if (!(error instanceof InvalidNetaddrError)) {
            throw error;
        }
    }
    if (varValue.startsWith('$')) {
        if (existingSuricataAddressVariables.includes(varValue)) {
            // valid variable
            return;
        }
    }
    errors.push({
        title: 'cfg:hlcfgValidationErrors.suricataVariables.address.title',
        desc: 'cfg:hlcfgValidationErrors.suricataVariables.address.desc',
    });
};

const suricataPortsVariablesValidation = (varValue, errors) => {
    try {
        if (netport(varValue).isValid()) {
            // valid port
            return;
        }
    } catch (error) {
        if (!(error instanceof InvalidPortError)) {
            throw error;
        }
    }
    if (varValue.startsWith('$')) {
        if (existingSuricataPortVariables.includes(varValue)) {
            // valid variable
            return;
        }
    }
    errors.push({
        title: 'cfg:hlcfgValidationErrors.suricataVariables.port.title',
        desc: 'cfg:hlcfgValidationErrors.suricataVariables.port.desc',
    });
};

const suricataVariablesValidation = (varValue, isAddressVar = true) => {
    if (!varValue) {
        return [];
    }
    const errors = [];
    for (const variable of varValue) {
        const strVar = String(variable);
        const noNegVar = strVar.startsWith('!') ? strVar.substr(1) : strVar;

        if (isAddressVar) {
            suricataAddressVariablesValidation(noNegVar, errors);
        } else {
            suricataPortsVariablesValidation(noNegVar, errors);
        }
    }
    return errors;
};

export default suricataVariablesValidation;
