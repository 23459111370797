/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import { withTranslation } from 'react-i18next';

import { SystemComponentButton } from
    '~frontendRoot/scenes/System/scenes/Management/SystemComponents/components/components/index.js';
import { SYSTEM_COMPONENT_CALLHOME } from '~commonLib/systemComponentsDeclaration.ts';


@withTranslation()
class RemoteHelp extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
        };
    }

    render() {
        const { t } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    {t('widgets:SystemComponents.components.RemoteHelp.title')}
                </MDBCardHeader>
                <MDBCardBody>
                    <SystemComponentButton
                        datacy="remoteHelp"
                        fullSize
                        name={SYSTEM_COMPONENT_CALLHOME}
                    />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default RemoteHelp;
