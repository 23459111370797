/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createContext } from 'react';
import { OutputSelector, createSelector } from 'reselect';

import { AddressesType, ADDRESSES_TYPE_SSH } from '~frontendRoot/constants/index.js';
import { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';


export const SocketAddressesFuncContext = createContext({
    add: () => {},
    copy: () => {},
    del: () => {},
    passReorderData: () => {},
    addressesType: ADDRESSES_TYPE_SSH,
    selector: createSelector(() => false, () => false) } as {
    add: (uuid: HlcfgRowId, addingAfter: boolean) => void,
    copy: (uuid: HlcfgRowId) => void,
    del: (uuid: HlcfgRowId) => void,
    passReorderData: (value: string[]) => void,
    addressesType: AddressesType,
    selector: OutputSelector<unknown, any, (res: any) => any>,
});
