/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useSelector } from 'react-redux';

import { Scene } from '~frontendComponents/Scene/index.js';
import { getProxyEnabled } from '~frontendDucks/hlcfgEditor/index.js';
import Service from '~frontendComponents/Service/index.ts';

import AntivirusScene from './components/AntivirusScene/index.js';


const Antivirus = () => {
    const proxyEnabled = useSelector(getProxyEnabled);
    return (
        <Scene>
            <Service
                id="ProxyAntivirus"
                on={proxyEnabled}
                showService={(
                    <AntivirusScene />
                )}
            />
        </Scene>
    );
};

export default Antivirus;
