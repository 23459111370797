/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import charts from '~sharedLib/reporterLibrary/charts.js';
import chartTypes from '~sharedLib/reporterLibrary/chartTypes.js';
import colValue from '~sharedLib/reporterLibrary/colValue.js';
import dom from '~sharedLib/reporterLibrary/dom.js';
import localizedName from '~sharedLib/reporterLibrary/localizedName.js';
import modals from '~sharedLib/reporterLibrary/modals.js';
import reportTypes from '~sharedLib/reporterLibrary/reportTypes.js';
import tFilter from '~sharedLib/reporterLibrary/tFilter.js';
import vFilter from '~sharedLib/reporterLibrary/vFilter.js';
import whereAmI from '~sharedLib/reporterLibrary/whereAmI.js';
import { Icon } from '~frontendComponents/Generic/index.js';
import Loader from '~frontendRoot/components/Loader/index.js';
import NoData from '~frontendRoot/components/NoData/index.js';


const reportDrawer = {};

const displayDrilldownButton = false; // the value was: whereAmI.angular;

const displayChartConfig = false;

const getFormGroupDropdownClass = function(isOpen) {
    return 'form-group dropdown' + (isOpen ? ' open' : '');
};

const getChartTypeClick = (reporterTemplates, chartCfgContainer, chartDataContainer, chartObject, aChartType) =>
    () => {
        setTimeout(function() {
            chartObject.setChartType(aChartType);
            redrawChart(reporterTemplates, chartCfgContainer, chartDataContainer, chartObject);
        });
    };

const drawChartTypeButton = function(
    chartConfigList, chartTypeReportUsage, chartTypeReportDefinition,
    aChartType, onClick
) {
    const li = dom.addChild(
        chartConfigList,
        'li',
        chartTypeReportUsage === aChartType ? 'active' : ''
    );
    const button = dom.addChild(li, 'a');
    const graphLogo = dom.addChild(button, 'span', 'k-chart-icon');
    graphLogo.innerHTML = chartTypes.CHART_TYPE_BUTTONS[
        aChartType ? aChartType : chartTypeReportDefinition
    ] || '?';
    dom.addText(
        button,
        tFilter(
            'report:chart.type.' + aChartType,
            {
                chartTypeReportDefinition:
                    tFilter('report:chart.type.' + chartTypeReportDefinition)
            }
        )
    );
    button.title = tFilter(
        'report:chart.type.' + aChartType + '-tooltip',
        undefined,
        ''
    );
    button.onclick = onClick;
};

/**
 * Draws chart configuration toolbar.
 *
 * @memberof reportDrawer
 * @private
 * @param reporterTemplates
 * @param {Element} chartCfgContainer
 * @param {Element} chartDataContainer
 * @param chartObject
 */
const drawChartConfig = function(
    reporterTemplates, chartCfgContainer, chartDataContainer, chartObject
) {
    dom.removeAllChildren(chartCfgContainer);
    const chartData = chartObject.data;
    if (!chartData) {
        return;
    }
    const chartConfigContainer = dom.addChild(
        chartCfgContainer, 'div', getFormGroupDropdownClass()
    );
    chartConfigContainer.style.marginRight = '15px';
    const chartConfigToggle = dom.addChild(
        chartConfigContainer, 'a', dom.getBtnClass()
    );
    const chartConfigList = dom.addChild(
        chartConfigContainer, 'ul', 'dropdown-menu'
    );
    const chartTypeReportDefinition = chartObject.getReportDefinitionChartType();
    const chartTypeReportUsage = chartObject.getReportUsageChartType();
    const selectedChartType = chartObject.getSelectedChartType();
    const reportCfg = chartData.frozenReportDefinition.report;
    const reportParams = reportCfg.params;
    const selectedReportType = reportCfg.type;
    const nCats = chartObject.getNumCategories();
    const nMets = chartObject.getNumMetrics();
    const availableChartTypes = chartObject.getAvailableChartTypes();
    if (!reportParams) {
        throw new Error('No report params');
    }
    if (!selectedReportType) {
        throw new Error('No report type');
    }
    if (!chartData && !(selectedReportType in reportTypes)) {
        throw new Error(
            'No chart data, also reportType "' +
            selectedReportType +
            '" does not exist'
        );
    }
    const isTogglable = nCats || nMets;
    if (isTogglable) {
        dom.dropdownToggle(chartConfigContainer, chartConfigToggle);
    }
    // this doesn't work properly, innerHTML cannot be mixed with ReactDOM.render
    chartConfigToggle.innerHTML =
        chartTypes.CHART_TYPE_BUTTONS[selectedChartType] || '?';
    if (isTogglable) {
        dom.addText(chartConfigToggle, ' ');
        ReactDOM.render(<Icon name="chevron-down" />, chartConfigToggle);
    }
    chartConfigToggle.title = tFilter(
        'report:chart.type.' + selectedChartType + '-tooltip',
        undefined,
        ''
    );
    const doDrawChartTypeButton = function(aChartType) {
        drawChartTypeButton(
            chartConfigList,
            chartObject.useReportUsageChartType ?
                chartTypeReportUsage :
                chartTypeReportDefinition,
            chartTypeReportDefinition,
            aChartType,
            getChartTypeClick(
                reporterTemplates,
                chartCfgContainer,
                chartDataContainer,
                chartObject,
                aChartType
            )
        );
    };
    if (chartObject.useReportUsageChartType) {
        doDrawChartTypeButton(null);
        dom.addChild(chartConfigList, 'li', 'divider');
    }
    availableChartTypes.forEach(function(objChartType) {
        doDrawChartTypeButton(objChartType.key);
    });
};

const getArrayOfLength = function(length) {
    const result = [];
    for (let index = 0; index < length; ++index) {
        result.push(index);
    }
    return result;
};

const addPaginationLi = function(ul, isDisabled, isActive, text, onclick) {
    const classes = [
        'ant-pagination-item'
    ];
    if (isDisabled) {
        classes.push('ant-pagination-disabled');
    }
    if (isActive) {
        classes.push('ant-pagination-item-active');
    }
    const liPagination = dom.addChild(ul, 'li', classes.join(' '));
    const aPagination = dom.addChild(liPagination, 'a');
    if (typeof text === 'function') {
        text(aPagination);
    } else {
        aPagination.textContent = text;
    }
    if (onclick && !isDisabled) {
        aPagination.onclick = onclick;
    }
    return aPagination;
};

const getGetGoToPage = function(reporterTemplates, tableContainer, chartObject, paginationContainer) {
    return function(iPage) {
        return function() {
            const paginated = chartObject.data.paginated;
            paginated.page = iPage;
            paginated.actualize();
            drawTable(reporterTemplates, tableContainer, chartObject, paginationContainer);
        };
    };
};

const getPerPageText = function(itemsPerPage) {
    return itemsPerPage === charts.MAX_SAFE_INTEGER ?
        tFilter('report:chart.all-items-per-page') :
        itemsPerPage + ' ' + tFilter('report:chart.items-per-page');
};

const confirmMaxRowsPerPage = function(max, fn) {
    const title = tFilter('report:chart.row-limit-confirm-title');
    const message = tFilter('report:chart.row-limit-confirm-message', {
        max: max
    });
    if (whereAmI.offline) {
        // eslint-disable-next-line no-alert
        if (window.confirm(title + '\n\n' + message)) {
            fn();
        }
        return;
    }
    modals.confirm({
        title: title,
        message: message,
        okButton: tFilter('global:modal.cancel'),
        cancelButton: tFilter('global:modal.ok'),
    }).cancelled(function() {
        fn();
    });
};

const PerPageToggle = ({ paginated }) => (
    <>
        {getPerPageText(paginated.itemsPerPage)}
        <> </>
        <Icon name="chevron-down" />
    </>
);

PerPageToggle.propTypes = {
    paginated: PropTypes.object,
};

const TABLE_MAX_ROWS = 300;
const addPaginationRow = function(reporterTemplates, tableContainer, chartObject, chartCfgContainer) {
    const chartData = chartObject.data;
    const paginated = chartData.paginated;
    let groupPaginationIsOpen = false;
    const getGroupPaginationClass = function() {
        return 'form-group dropdown ' +
            (groupPaginationIsOpen ? ' open' : '');
    };
    const groupPagination = dom.addChild(chartCfgContainer, 'div');
    const updatePaginationRow = function() {
        dom.removeAllChildren(groupPagination);
        groupPagination.className = getGroupPaginationClass();
        const perPageToggle = dom.addChild(
            groupPagination, 'a', dom.getBtnClass() + ' btn-sm'
        );
        perPageToggle.style.marginLeft = '15px';
        perPageToggle.style.marginRight = '15px';
        const chartCfgObject = chartObject.getSelectedChartCfgObject();
        if (chartCfgObject.config.rowsPerPage) {
            paginated.itemsPerPage = chartCfgObject.config.rowsPerPage;
            paginated.actualize();
        }
        ReactDOM.render(<PerPageToggle paginated={paginated} />, perPageToggle);
        dom.dropdownToggle(groupPagination, perPageToggle);
        const ulGroupPagination = dom.addChild(
            groupPagination, 'ul', 'dropdown-menu'
        );
        charts.ROWS_PER_PAGE_OPTIONS.forEach(function(rowsPerPage) {
            const liText = getPerPageText(rowsPerPage);
            const setRowsPerPage = function() {
                chartCfgObject.config.rowsPerPage = rowsPerPage;
                paginated.itemsPerPage = rowsPerPage;
                paginated.actualize();
                groupPaginationIsOpen = false;
                drawTable(reporterTemplates, tableContainer, chartObject, null);
                updatePaginationRow();
            };
            addPaginationLi(
                ulGroupPagination,
                false,
                rowsPerPage === paginated.itemsPerPage,
                liText,
                function() {
                    if (rowsPerPage === charts.MAX_SAFE_INTEGER &&
                        chartData.rows.length > TABLE_MAX_ROWS)
                    {
                        confirmMaxRowsPerPage(
                            chartData.rows.length,
                            setRowsPerPage
                        );
                        return;
                    }
                    setRowsPerPage();
                }
            );
        });
    };
    updatePaginationRow();
};

/**
 * Adds pagination container.
 *
 * @memberof reportDrawer
 * @private
 * @param reporterTemplates
 * @param {Element} tableContainer
 * @param chartObject
 * @param {boolean} [isSecond=false]
 * @returns {Element?}
 */
const addPaginationContainer = function(reporterTemplates, tableContainer, chartObject, isSecond) {
    if (!chartObject.data) {
        return;
    }
    const paginationContainer = dom.addChild(
        tableContainer, 'form', 'form-horizontal'
    );
    const paginated = chartObject.data.paginated;
    const ulPagination = dom.addChild(
        paginationContainer, 'ul', 'ant-pagination mini'
    );
    ulPagination.style.display = 'inline-block';
    const isFirst = paginated.page === 1 || paginated.page === 0;
    const isLast = paginated.page === paginated.pages;
    const getGoToPage = getGetGoToPage(
        reporterTemplates,
        tableContainer,
        chartObject,
        isSecond && paginationContainer
    );
    addPaginationLi(
        ulPagination,
        isFirst,
        false,
        aPagination => {
            ReactDOM.render(<Icon name="chevron-down" />, aPagination);
        },
        getGoToPage(1)
    );
    addPaginationLi(
        ulPagination,
        isFirst,
        false,
        aPagination => {
            ReactDOM.render(<Icon name="arrow-left" />, aPagination);
        },
        getGoToPage(paginated.page - 1)
    );
    const nDisplayedPages = Math.min(5, paginated.pages);
    const displayedPageBegin = Math.max(
        1,
        paginated.page - Math.floor(nDisplayedPages / 2)
    );
    const displayedPageEnd = Math.min(
        paginated.pages,
        1 + paginated.page + Math.ceil(nDisplayedPages / 2)
    );
    getArrayOfLength(nDisplayedPages).forEach(function(iDisplayedPages) {
        const iPage = Math.min(
            displayedPageBegin + iDisplayedPages,
            displayedPageEnd - nDisplayedPages + iDisplayedPages + 1
        );
        addPaginationLi(
            ulPagination,
            false,
            iPage === paginated.page,
            iPage,
            getGoToPage(iPage)
        );
    });
    addPaginationLi(
        ulPagination,
        isLast,
        false,
        aPagination => {
            ReactDOM.render(<Icon name="arrow-right" />, aPagination);
        },
        getGoToPage(paginated.page + 1)
    );
    addPaginationLi(
        ulPagination,
        isLast,
        false,
        aPagination => {
            ReactDOM.render(<Icon name="chevron-down" />, aPagination);
        },
        getGoToPage(paginated.pages)
    );
    const pagClassName = 'ant-pagination mini' + (isSecond ? ' k-hide-print' : '');
    const paginationItemNumberUl = dom.addChild(paginationContainer, 'ul', pagClassName);
    paginationItemNumberUl.style.display = 'inline-block';
    const paginationItemNumberLi = dom.addChild(paginationItemNumberUl, 'li', 'ant-pagination-item');
    paginationItemNumberLi.style.cursor = 'default';
    paginationItemNumberLi.style.marginLeft = '15px';
    paginationItemNumberLi.textContent =
        paginated.to ?
            (paginated.from + 1) + '.–' + paginated.to + '. ' +
            tFilter('report:chart.item') + ' ' +
            paginated.totalItems :
            tFilter('report:chart.empty-table');
    return paginationContainer;
};

const isDrilldown = function(chartCfgObject) {
    return chartCfgObject.config.type === 'drilldown';
};

const TableTdContent = ({ isDrill, isOuterRow, isCategory, isFirstCol, isExpanded, onClick, text }) => {
    if (isDrill) {
        if (isOuterRow) {
            if (isCategory && isFirstCol) {
                return (
                    <a onClick={onClick}>
                        {isExpanded ? <Icon name="chevron-down" /> : <Icon name="chevron-right" />}
                        <> </>
                        {text}
                    </a>
                );
            } else if (!isCategory) {
                return (
                    <b>
                        {text}
                    </b>
                );
            }
        }
    }
    return <BadgeWithSeverity text={text} />;
};

TableTdContent.propTypes = {
    isDrill: PropTypes.bool,
    isOuterRow: PropTypes.bool,
    isCategory: PropTypes.bool,
    isFirstCol: PropTypes.bool,
    isExpanded: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.node
};

const addTableTd = function(
    elTr, isDrill, isOuterRow, isCategory, isFirstCol, isAlignedRight,
    isExpanded, text, onClick
) {
    const elTd = dom.addChild(elTr, 'td');
    if (!isCategory && isAlignedRight) {
        elTd.style.textAlign = 'right';
    }
    ReactDOM.render(
        <TableTdContent
            isCategory={isCategory}
            isDrill={isDrill}
            isExpanded={isExpanded}
            isFirstCol={isFirstCol}
            isOuterRow={isOuterRow}
            onClick={onClick}
            text={text}
        />,
        elTd
    );
};

const BadgeWithSeverity = ({ text }) => {
    switch (text) {
    default:
        return text;
    }
};

/**
 * Returns a function that adds a row to the table that is being drawn.
 *
 * @memberof reportDrawer
 * @private
 * @param reporterTemplates
 * @param {Element} tableContainer
 * @param {Element} elTbody
 * @param chartObject
 * @returns {Function}
 */
const getAddRow = function(reporterTemplates, tableContainer, elTbody, chartObject) {
    const chartCfgObject = chartObject.getSelectedChartCfgObject();
    return function(outerRow, row, numberingText, isOuterRow) {
        if (!row) {
            return;
        }
        const chartData = chartObject.data;
        const dataDrilldown = chartData.drilldown;
        const dataTable = chartData.table;
        const elTr = dom.addChild(elTbody, 'tr', 'ant-table-row');
        const elTd = dom.addChild(elTr, 'td');
        dom.addText(elTd, numberingText);
        const isDrill = isDrilldown(chartCfgObject);
        const addColumn = function() {
            return function(dataCol) {
                const col = row[dataCol.index];
                const isCategory = dataCol.isCategory;
                addTableTd(
                    elTr,
                    isDrill,
                    isOuterRow,
                    isCategory,
                    !dataCol.index,
                    true,
                    outerRow && outerRow.isExpanded,
                    // the first categorical column of a drilldown should be
                    // formatted, the second and other columns not
                    isDrill && isOuterRow && isCategory && !dataCol.isFirstCategory ?
                        col :
                        colValue(reporterTemplates, col, dataCol, { noEscape: true }),
                    function() {
                        dataTable.toggleExpand(outerRow);
                        drawTable(reporterTemplates, tableContainer, chartObject, null);
                    }
                );
                if (isDrill && !isCategory) {
                    const percent = row[dataCol.index] /
                        (isOuterRow ?
                            dataDrilldown.sums :
                            outerRow.sums
                        )[dataCol.metricIndex];
                    addTableTd(
                        elTr,
                        isDrill,
                        isOuterRow,
                        isCategory,
                        false,
                        false,
                        false,
                        vFilter(percent, 'percent')
                    );
                }
            };
        };
        // keep this consistent with Chart.prototype.computeTable(),
        // function table.orderBy()
        chartData.cols.forEach(addColumn());
        if (displayDrilldownButton) {
            const tdPointClick = dom.addChild(elTr, 'td');
            if (!isDrill || !isOuterRow) {
                const aPointClick = dom.addChild(tdPointClick, 'a');
                ReactDOM.render(<Icon name="search" />, aPointClick);
                aPointClick.onclick = function() {
                    chartObject.pointClick(row);
                };
            }
        }
    };
};

const getScrollContainer = function(element) {
    while (element && !element.scrollTop) {
        element = element.parentNode;
    }
    return element;
};

const ColOrderedBy = ({ colOrderedBy }) => {
    if (!colOrderedBy) {
        return null;
    }
    return (
        <>
            {colOrderedBy.isAscending ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
            <> </>
        </>
    );
};

ColOrderedBy.propTypes = {
    colOrderedBy: PropTypes.object,
};

/**
 * Draws a table.
 *
 * @memberof reportDrawer
 * @private
 * @param reporterTemplates
 * @param {Element} tableContainer
 * @param chartObject
 * @param {Element?} formerPaginationContainer
 */
const drawTable = function(reporterTemplates, tableContainer, chartObject, formerPaginationContainer) {
    const formerScrollContainer = getScrollContainer(tableContainer);
    let formerScrollHeight = 0;
    if (formerPaginationContainer && formerScrollContainer) {
        formerScrollHeight = formerPaginationContainer.offsetTop -
            formerScrollContainer.scrollTop;
    }
    const chartData = chartObject.data;
    dom.removeAllChildren(tableContainer);
    addPaginationContainer(reporterTemplates, tableContainer, chartObject, false);
    const elContainerTableTable = dom.addChild(tableContainer, 'div', 'ant-table ant-table-small');
    elContainerTableTable.style.overflowY = 'auto';
    elContainerTableTable.style.whiteSpace = 'nowrap';
    elContainerTableTable.style.width = '100%';
    const elContainerTableContent = dom.addChild(elContainerTableTable, 'div', 'ant-table-content');
    const elContainerTableBody = dom.addChild(elContainerTableContent, 'div', 'ant-table-body');
    const elTable = dom.addChild(elContainerTableBody, 'table', 'table k-table k-chart-table');
    const elThead = dom.addChild(elTable, 'thead', 'ant-table-thead');
    const elTbody = dom.addChild(elTable, 'tbody', 'ant-table-tbody');
    const elTheadTr = dom.addChild(elThead, 'tr');
    const numberingTh = dom.addChild(elTheadTr, 'th');
    numberingTh.textContent = '#';
    numberingTh.style.width = '45px';
    const dataTable = chartData.table;
    const chartCfgObject = chartObject.getSelectedChartCfgObject();

    chartData.cols.forEach(function(column) {
        const th = dom.addChild(elTheadTr, 'th');
        const colText = column.constiant ?
            tFilter('report:chart.column-constiant.' + column.constiant, undefined, column.constiant) +
                ' (' + column.title + ')' :
            column.title;
        if (dataTable.isDrilldown) {
            dom.addText(th, colText);
        } else {
            const reorderBtn = dom.addChild(th, 'a');
            const paginated = chartData.paginated;
            const colOrderedBy = paginated.orderedByCols[column.index];
            const toggleOrdersAsc = !colOrderedBy ||
                !colOrderedBy.isAscending;
            ReactDOM.render(
                <>
                    <ColOrderedBy colOrderedBy={colOrderedBy} />
                    {colText}
                </>,
                reorderBtn
            );
            reorderBtn.title = tFilter('report:chart.order-' + (
                toggleOrdersAsc ? 'ascending' : 'descending'));
            reorderBtn.onclick = function() {
                paginated.page = 1;
                paginated.orderBy(column.index, toggleOrdersAsc);
                dataTable.orderBy(paginated);
                drawTable(reporterTemplates, tableContainer, chartObject, formerPaginationContainer);
            };
        }
        if (!column.isCategory) {
            if (isDrilldown(chartCfgObject)) {
                th.style.textAlign = 'center';
                th.colSpan = 2;
            } else {
                th.style.textAlign = 'right';
            }
        }
    });
    if (displayDrilldownButton) {
        dom.addChild(elTheadTr, 'th').style.width = '45px';
    }
    const fromIndex = chartData.paginated.from;
    const toIndex = chartData.paginated.to;

    const paginated = chartData.paginated;
    const rowGetter = dataTable.getRowGetter(fromIndex);
    const addRow = getAddRow(reporterTemplates, tableContainer, elTbody, chartObject, chartData);
    getArrayOfLength(toIndex - fromIndex).forEach(function(index) {
        const outerRow = rowGetter.getOuterRow();
        const numberingText = rowGetter.getRowNumbering(false);
        const outerNumberingText = rowGetter.getRowNumbering(true);
        const isOuterRow = rowGetter.isOuterRow();
        const row = rowGetter.getNextRow();
        if (!index && !isOuterRow && outerRow) {
            addRow(outerRow, outerRow.row, outerNumberingText, !isOuterRow);
        }
        addRow(outerRow, row, numberingText, isOuterRow);
    });
    if (paginated.itemsPerPage >= 20) {
        const lastPagination = addPaginationContainer(reporterTemplates, tableContainer, chartObject, true);
        const scrollContainer = getScrollContainer(tableContainer);
        if (scrollContainer && formerScrollHeight) {
            scrollContainer.scrollTop = lastPagination.offsetTop -
                formerScrollHeight;
        }
    }
};

let uniqueId = 0;

const TABLE_TYPES = {
    table: true,
    drilldown: true,
    drilldown_anomalies: true
};

const addAdditionalBtns = function(chartCfgContainer) {
    const chartAdditionalBtns = dom.addChild(
        chartCfgContainer,
        'div',
        'form-group'
    );
    chartAdditionalBtns.style.marginRight = '15px';
    return dom.addChild(
        chartAdditionalBtns,
        'div',
        'btn-group btn-group-sm'
    );
};

const addLoaderStatusText = function(chartDataContainer, statusText) {
    ReactDOM.render(
        <Loader
            className="loader--middle loader--column"
            label={statusText}
            labelClassName="loader__label--color"
        />,
        chartDataContainer
    );
};

const addErrorStatusText = function(chartDataContainer, statusText) {
    ReactDOM.render(
        statusText,
        chartDataContainer
    );
};

const addNoDataStatusText = function(chartDataContainer, statusText) {
    ReactDOM.render(
        <NoData labelClassName="loader__label--color">{statusText}</NoData>,
        chartDataContainer
    );
};

/**
 * Displays an error in drawing a chart.
 *
 * @param {Element} chartDataContainer
 * @param {Element} highchartContainer
 * @param {string} message
 */
const chartDrawingError = function(chartDataContainer, highchartContainer, message) {
    dom.addChild(chartDataContainer, 'p').innerHTML =
        tFilter('report:chart.error') +
        (message ?
            ': ' + message :
            '');
};

const StatusText = ({ chartObject }) => (
    <>
        <Icon name="alert" />
        <> </>
        {tFilter(
            chartObject.error?.type === 'NO_DATA' ?
                'report:chart.no-data' :
                chartObject.error?.type === 'SCHEMA_VALIDATION_FAILED' ?
                    'report:reporter-error-verification' :
                    'report:reporter-error'
        )}
    </>
);

StatusText.propTypes = {
    chartObject: PropTypes.object.isRequired,
};

/**
 * Draws the actual chart or table.
 *
 * @memberof reportDrawer
 * @private
 * @param reporterTemplates
 * @param {Element} chartCfgContainer
 * @param {Element} chartAdditionalCfgContainer
 * @param {Element} chartDataContainer
 * @param chartObject
 */
const drawChartData = function(
    reporterTemplates,
    chartCfgContainer, chartAdditionalCfgContainer, chartDataContainer,
    chartObject
) {
    dom.removeAllChildren(chartAdditionalCfgContainer);
    dom.removeAllChildren(chartDataContainer);
    const chartData = chartObject.data;
    if (!chartDataContainer) {
        return;
    }
    if (!chartData) {
        return;
    }
    if (chartObject.error) {
        addErrorStatusText(
            chartDataContainer,
            <StatusText chartObject={chartObject} />
        );
        return;
    }
    if (chartData.noData) {
        addLoaderStatusText(
            chartDataContainer,
            tFilter('report:report-result-not-in-cache-loading')
        );
        return;
    }
    if (!chartData.rows.length) {
        addNoDataStatusText(
            chartDataContainer,
            tFilter('report:no-rows-in-report')
        );
        return;
    }
    ++uniqueId;
    const highchartContainer = dom.addChild(chartDataContainer, 'div');
    highchartContainer.setAttribute('id', 'k-chart-' + uniqueId);
    highchartContainer.style.height = null;
    chartObject.draw(highchartContainer, function(error) {
        if (error) {
            chartDataContainer.removeChild(highchartContainer);
            let message = '';
            if (/Highcharts error #19/.test(error + '')) {
                message += tFilter('report:chart.too-many-points');
            } else {
                // eslint-disable-next-line no-console
                console.error('drawChartData', 'error', error, 'chartObject', chartObject);
            }
            chartDrawingError(
                chartDataContainer,
                highchartContainer,
                message
            );
        }
        highchartContainer.style.overflow = 'initial';
    });
    let tableContainer;
    const chartCfgObject = chartObject.getSelectedChartCfgObject();
    const chartTypeObj = chartTypes.CHART_TYPES[chartCfgObject.config.type];
    if (chartCfgObject.config.type in TABLE_TYPES) {
        tableContainer = dom.addChild(chartDataContainer, 'div');
        addPaginationRow(reporterTemplates, tableContainer, chartObject, chartCfgContainer);
        drawTable(reporterTemplates, tableContainer, chartObject, null);
    }
    if (!chartTypeObj) {
        chartDrawingError(
            chartDataContainer,
            highchartContainer,
            tFilter('report:chart.unselected-chart')
        );
        return;
    }
    let chartAdditionalBtns;
    if (chartTypeObj.key === 'drilldown') {
        chartAdditionalBtns = addAdditionalBtns(
            chartAdditionalCfgContainer
        );
        const collapseUp = dom.addChild(chartAdditionalBtns, 'a',
            dom.getBtnClass(false, !chartCfgObject.config.drilldownExpanded));
        const collapseDown = dom.addChild(chartAdditionalBtns, 'a',
            dom.getBtnClass(false, chartCfgObject.config.drilldownExpanded));
        chartData.table.forceExpansion(chartCfgObject.config.drilldownExpanded);
        collapseDown.title = tFilter(
            'report:chart.drilldown-expand-all'
        );
        collapseUp.title = tFilter(
            'report:chart.drilldown-collapse-all'
        );
        ReactDOM.render(<Icon name="arrow-down" />, collapseDown);
        ReactDOM.render(<Icon name="arrow-up" />, collapseUp);
        collapseDown.onclick = function() {
            chartCfgObject.config.drilldownExpanded = false;
            collapseDown.className = dom.getBtnClass(false, true);
            collapseUp.className = dom.getBtnClass(false, false);
            chartData.table.forceExpansion(true);
            drawTable(reporterTemplates, tableContainer, chartObject, null);
        };
        collapseUp.onclick = function() {
            chartCfgObject.config.drilldownExpanded = true;
            collapseUp.className = dom.getBtnClass(false, true);
            collapseDown.className = dom.getBtnClass(false, false);
            chartData.table.forceExpansion(false);
            drawTable(reporterTemplates, tableContainer, chartObject, null);
        };
    }
    if (chartTypeObj.isLogarithmic && chartObject.canBeLogarithmic()) {
        chartAdditionalBtns = addAdditionalBtns(
            chartAdditionalCfgContainer
        );
        const btnLogarithmic = dom.addChild(
            chartAdditionalBtns,
            'a',
            dom.getBtnClass(false, chartCfgObject.config.isLogarithmic)
        );
        btnLogarithmic.textContent = 'log';
        btnLogarithmic.onclick = function() {
            chartCfgObject.config.isLogarithmic = !chartCfgObject.config.isLogarithmic;
            btnLogarithmic.className = dom.getBtnClass(
                false, chartCfgObject.config.isLogarithmic
            );
            redrawChart(reporterTemplates, chartCfgContainer, chartDataContainer, chartObject);
        };
    }
    if (chartData.rows.length > chartTypeObj.maxRows) {
        chartAdditionalBtns = addAdditionalBtns(
            chartAdditionalCfgContainer
        );
        const btnMaxRows = dom.addChild(chartAdditionalBtns, 'a');
        const glyphMaxRows = dom.addChild(btnMaxRows, 'span');
        dom.addText(btnMaxRows, ' ');
        const titleMaxRows = dom.addChild(btnMaxRows, 'span');
        const setMaxRowDesc = function() {
            btnMaxRows.className = dom.getBtnClass(
                false,
                chartCfgObject.config.showAllRows
            );
            // it would have to be refactored to work, but it is not shown anyway
            glyphMaxRows.textContent = chartCfgObject.config.showAllRows ?
                'glyphicon glyphicon-eye-open' :
                'glyphicon glyphicon-eye-close';
            btnMaxRows.title = tFilter(
                'report:chart.row-limit-' + (
                    chartCfgObject.config.showAllRows ? 'on' : 'off'
                ) + '-message',
                {
                    current: chartTypeObj.maxRows,
                    max: chartData.rows.length
                }
            );
            titleMaxRows.textContent = tFilter(
                'report:chart.row-limit-' + (
                    chartCfgObject.config.showAllRows ? 'on' : 'off'
                ) + '-title',
                {
                    current: chartTypeObj.maxRows,
                    max: chartData.rows.length
                }
            );
        };
        setMaxRowDesc();
        dom.addText(btnMaxRows, tFilter());
        const toggleMaxRow = function() {
            chartCfgObject.config.showAllRows = !chartCfgObject.config.showAllRows;
            setMaxRowDesc();
            redrawChart(reporterTemplates, chartCfgContainer, chartDataContainer, chartObject);
        };
        btnMaxRows.onclick = function() {
            if (chartCfgObject.config.showAllRows) {
                return toggleMaxRow();
            }
            confirmMaxRowsPerPage(chartData.rows.length, toggleMaxRow);
        };
    }
};

/**
 * Draws report chart. Used in online GUI as well as from
 * reportDrawer.drawReport().
 *
 * @memberof reportDrawer
 * @param reporterTemplates
 * @param {Element} chartCfgContainer
 * @param {Element} chartDataContainer
 * @param chartObject
 */
const redrawChart = function(reporterTemplates, chartCfgContainer, chartDataContainer, chartObject) {
    drawChartConfig(reporterTemplates, chartCfgContainer, chartDataContainer, chartObject);
    const chartAdditionalCfgContainer = dom.addChild(chartCfgContainer, 'div');
    chartAdditionalCfgContainer.style.display = 'inline-block';
    drawChartData(reporterTemplates, chartCfgContainer, chartAdditionalCfgContainer, chartDataContainer, chartObject);
};

/**
 * Draws report together with its chart. Used in offline GUI.
 *
 * @memberof reportDrawer
 * @param reporterTemplates
 * @param {Element} reportContainer
 * @param chartObject
 */
reportDrawer.drawReport = function(
    reporterTemplates, reportContainer, chartObject
) {
    dom.removeAllChildren(reportContainer);
    const panel = dom.addChild(reportContainer, 'div', 'panel panel-default');
    const panelHeading = dom.addChild(panel, 'div', 'panel-heading');
    const reportCfg = chartObject.data.frozenReportDefinition.report;
    panelHeading.textContent = localizedName.getName(reportCfg);
    const panelBody = dom.addChild(panel, 'div', 'panel-body');
    const description = localizedName.getDescription(reportCfg);
    if (description) {
        dom.addChild(panelBody, 'p').textContent = description;
    }
    const template = dom.addChild(panelBody, 'div', 'k-report-result');
    const chartCfgContainer = dom.addChild(
        template, 'div', 'form k-report-chart-config form-inline'
    );
    if (!displayChartConfig) {
        chartCfgContainer.style.display = 'none';
    }
    const chartDataContainer = dom.addChild(template, 'div', 'chart');
    redrawChart(reporterTemplates, chartCfgContainer, chartDataContainer, chartObject);
    return panel;
};

export default reportDrawer;
