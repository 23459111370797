/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import TitleAndDesc from '../../../../TitleAndDesc/index.js';
import Message from '../../../../Message/index.js';
import { ComponentNameTranslator } from '../../../../SystemComponents/ComponentNameTranslator.js';


const ComponentActionTitle = ({ action, componentType }) => {
    switch (action) {
    case 'status':
        return (
            <>
                <Message message="actions:componentAction.status.title" />
                <ComponentNameTranslator componentType={componentType} />
            </>
        );
    case 'start':
        return (
            <>
                <Message message="actions:componentAction.start.title" />
                <ComponentNameTranslator componentType={componentType} />
            </>
        );
    case 'stop':
        return (
            <>
                <Message message="actions:componentAction.stop.title" />
                <ComponentNameTranslator componentType={componentType} />
            </>
        );
    case 'restart':
        return (
            <>
                <Message message="actions:componentAction.restart.title" />
                <ComponentNameTranslator componentType={componentType} />
            </>
        );
    case 'reload':
        return (
            <>
                <Message message="actions:componentAction.reload.title" />
                <ComponentNameTranslator componentType={componentType} />
            </>
        );
    case 'enable':
        return (
            <>
                <Message message="actions:componentAction.enable.title" />
                <ComponentNameTranslator componentType={componentType} />
            </>
        );
    case 'disable':
        return (
            <>
                <Message message="actions:componentAction.disable.title" />
                <ComponentNameTranslator componentType={componentType} />
            </>
        );
    default:
        return (
            <>
                <Message message="actions:componentAction.unknown.title" />
                <ComponentNameTranslator componentType={componentType} />
            </>
        );
    }
};

ComponentActionTitle.propTypes = {
    action: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired,
};


const ComponentActionDesc = ({ action, componentType }) => {
    switch (action) {
    case 'status':
        return (
            <>
                <Message message="actions:componentAction.status.desc1" />
                <b><ComponentNameTranslator componentType={componentType} /></b>
                <Message message="actions:componentAction.status.desc2" />
            </>
        );
    case 'start':
        return (
            <>
                <Message message="actions:componentAction.start.desc1" />
                <b><ComponentNameTranslator componentType={componentType} /></b>
                <Message message="actions:componentAction.start.desc2" />
            </>
        );
    case 'stop':
        return (
            <>
                <Message message="actions:componentAction.stop.desc1" />
                <b><ComponentNameTranslator componentType={componentType} /></b>
                <Message message="actions:componentAction.stop.desc2" />
            </>
        );
    case 'restart':
        return (
            <>
                <Message message="actions:componentAction.restart.desc1" />
                <b><ComponentNameTranslator componentType={componentType} /></b>
                <Message message="actions:componentAction.restart.desc2" />
            </>
        );
    case 'reload':
        return (
            <>
                <Message message="actions:componentAction.reload.desc1" />
                <b><ComponentNameTranslator componentType={componentType} /></b>
                <Message message="actions:componentAction.reload.desc2" />
            </>
        );
    case 'enable':
        return (
            <>
                <Message message="actions:componentAction.enable.desc1" />
                <b><ComponentNameTranslator componentType={componentType} /></b>
                <Message message="actions:componentAction.enable.desc2" />
            </>
        );
    case 'disable':
        return (
            <>
                <Message message="actions:componentAction.disable.desc1" />
                <b><ComponentNameTranslator componentType={componentType} /></b>
                <Message message="actions:componentAction.disable.desc2" />
            </>
        );
    default:
        return (
            <>
                <Message message="actions:componentAction.unknown.desc1" />
                <b>{action}</b>
                <Message message="actions:componentAction.unknown.desc2" />
                <b><ComponentNameTranslator componentType={componentType} /></b>
                <Message message="actions:componentAction.unknown.desc3" />
            </>
        );
    }
};

ComponentActionDesc.propTypes = {
    action: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired,
};


const ComponentActionLine = ({ action, componentType }) => (
    <TitleAndDesc
        desc={(
            <ComponentActionDesc
                action={action}
                componentType={componentType}
            />
        )}
        title={(
            <ComponentActionTitle
                action={action}
                componentType={componentType}
            />
        )}
    />
);

ComponentActionLine.propTypes = {
    action: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired,
};

export default ComponentActionLine;
