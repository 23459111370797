/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useMemo } from 'react';
import { MDBCard, MDBCardTitle  } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { InputSearch } from '~frontendComponents/Generic/index.js';
import {
    getNamedObjectNetaddrScalarIds,
    getNamedObjectNetaddrVectorIds,
    setNormalizeOrder } from '~frontendDucks/hlcfgEditor/index.js';
import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { NEW_ROW_CONSTANT, SELECTABLE_TABLE_NAMED_OBJECT } from '~frontendRoot/constants/index.js';
import { useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';

import Row, { RowContext } from './Row/index.ts';


const CreateRow = ({ dataIndex,
    spacing,
    uuid }: CreateRowPropsType) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid}
            spacing={spacing}
            uuid={uuid}
        />
    );
};


type NamedObjectNetaddrProps = {
    isScalar?: boolean;
}

const NamedObjectNetaddr = ({ isScalar }: NamedObjectNetaddrProps) => {
    const [ search, setSearch ] = useString('');
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const namedObjectsIds: string[] = useSelector(
        isScalar ? getNamedObjectNetaddrScalarIds : getNamedObjectNetaddrVectorIds
    );
    const ids = useMemo(() => namedObjectsIds.length ?  namedObjectsIds : [ NEW_ROW_CONSTANT ], [ namedObjectsIds ]);
    const passReorderData = useCallback((value) => {
        dispatch(setNormalizeOrder({
            type: isScalar ? 'namedObjectsNetaddrScalar' : 'namedObjectsNetaddrVector', value
        }));
    }, [ isScalar, dispatch ]);


    return (
        <MDBCard className="packetFilter__card">
            <MDBCardTitle className="profiles__title">
                {t(`widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}.title`)}
                <InputSearch
                    className="mb-3 mt-0"
                    id={isScalar ? 'searchValueIDScalar' : 'searchValueIDVector'}
                    search={search}
                    setter={setSearch}
                />
            </MDBCardTitle>
            <RowContext.Provider value={{ isScalar, search }}>
                <DatatableWidget
                    columnsId={SELECTABLE_TABLE_NAMED_OBJECT}
                    createRow={CreateRow}
                    data={ids}
                    isDraggable
                    passReorderData={passReorderData}
                />
            </RowContext.Provider>
        </MDBCard>
    );
};


export default NamedObjectNetaddr;
