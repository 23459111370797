/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { MDBRow, MDBCol, MDBCard } from 'mdbreact';

import { QoSBody } from './components/index.js';


@withTranslation()

class QoSService extends Component {
    render() {
        return (
            <MDBRow>
                <MDBCol
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <MDBCard className="card--smaller">
                        <QoSBody />
                    </MDBCard>
                </MDBCol>

            </MDBRow>
        );
    }
}

export default QoSService;
