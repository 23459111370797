/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const Divider = ({ text, vertical }) => {
    return (
        <div className={classNames('divider__container',
            {
                'divider__container--vertical': vertical

            })}
        >
            <div className={classNames('divider__border',
                {
                    'divider__border--vertical': vertical

                })}
            />
            {text &&
            <span className={classNames('divider__content',
                {
                    'divider__content--vertical': vertical

                })}
            >
                {text}
            </span>}
            <div className={classNames('divider__border',
                {
                    'divider__border--vertical': vertical

                })}
            />
        </div>
    );
};

Divider.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    vertical: PropTypes.bool,
};

export default Divider;
