/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import classNames from 'classnames';
import { MDBCard, MDBCardTitle, MDBCardBody } from 'mdbreact';

import Message from '~frontendComponents/Message/index.js';
import ApiError from '~frontendComponents/Generic/ApiError/index.js';

import Number from '../components/Number/Number.js';
import { typeApiError } from '../../types/index.js';


class NumberWidget extends Component {
    static get propTypes() {
        return {
            data: PropTypes.array.isRequired,
            className: PropTypes.string,
            vertical: PropTypes.bool,
            title: PropTypes.string.isRequired,
            isLoading: PropTypes.bool,
            error: typeApiError
        };
    }

    render() {
        const { data, vertical, className, title, isLoading, error } = this.props;

        return (

            <MDBCard className={className}>
                <MDBCardTitle>
                    <Message message={title} />
                </MDBCardTitle>
                <MDBCardBody className={classNames('numberWidget', {
                    'flex-wrap': !vertical
                })}
                >
                    <ApiError
                        className="numberWidget__loader"
                        data={data}
                        error={error}
                        isLoading={isLoading}
                    >
                        {data ? data.map(value => (
                            <Number
                                className="number"
                                color={value.numColor}
                                key={value.id}
                                label={value.name}
                                size={value.size}
                                value={value.y}
                                //We store value in Y , reason: highchair'sReact
                                vertical={vertical}
                            />
                        )) : null}
                    </ApiError>
                </MDBCardBody>

            </MDBCard>

        );


    }
}


export default NumberWidget;
