/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBRow, MDBCol, } from 'mdbreact';
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Scene } from '~frontendComponents/Scene/index.js';
import { getComponentEnabled, getMultihomingIds } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { MULTIHOMING } from '~frontendRoot/constants/index.js';

import { RouteTable, Multihoming } from './components/index.js';


@connect(
    (state,) => ({
        multihomingEnabled: getComponentEnabled(state, MULTIHOMING),
        multihomingGateways: getMultihomingIds(state)
    }),
    {

    }
)
class Routing extends Component {
    static get propTypes() {
        return {
            multihomingEnabled: PropTypes.bool,
            multihomingGateways: PropTypes.array,
        };
    }

    render() {
        const { multihomingEnabled, multihomingGateways } = this.props;

        return (
            <Scene>
                <MDBRow>
                    <MDBCol
                        className="mb-2 mb-lg-4 mb-md-auto"
                        size={multihomingEnabled ? '12' : '4'}
                    >
                        <Multihoming
                            multihoming={multihomingEnabled}
                            multihomingGateways={multihomingGateways}
                        />
                    </MDBCol>

                    <MDBCol
                        className="mb-2 mb-lg-4 mb-md-auto"

                    >
                        <RouteTable multihoming={multihomingEnabled} />
                    </MDBCol>
                </MDBRow>
            </Scene>
        );
    }
}

export default Routing;
