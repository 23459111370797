/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

import { NtpClient } from './components/index.js';


const Ntp = () => {

    return (
        <MDBRow>
            <MDBCol
                className="mb-3"
                lg="5"
                md="5"
                sm="12"
            >

                <NtpClient />
            </MDBCol>
        </MDBRow>
    );
};


export default Ntp;
