/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {  MDBBtn, MDBModalFooter } from 'mdbreact';


import { Input,  Modal } from '~frontendComponents/Generic/index.js';
import {  getAuthenticationLoginIsOpen, setAuthenticationLoginModal,
    authenticationStart
} from '~frontendDucks/authentication/index.js';


@withTranslation()
@connect(
    state => ({
        isOpen: getAuthenticationLoginIsOpen(state, 'isOpenUser')

    }),
    {
        setModal: setAuthenticationLoginModal,
        authenticate: authenticationStart
    }
)
class AuthenticationUserModal extends Component {
    static get propTypes() {
        return {
            isOpen: PropTypes.bool,
            t: PropTypes.func,
            setModal: PropTypes.func,
            authenticate: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }

    handleInputOnChange = ({ value, name }) => {
        this.setState({
            [name]: value
        });
    };

    onSubmit = () => {
        this.openProgressModal();
        this.closeModal();
    };


    backModal = () => {
        const { setModal } = this.props;
        setModal({ name: 'isOpenUser', value: false });
        setModal({ name: 'isOpenLogin', value: true });
        this.setState({
            password: ''
        });
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal({ name: 'isOpenUser', value: false });
        this.setState({
            password: ''
        });
    };

    openProgressModal = () => {
        const {  authenticate,  setModal } = this.props;
        const { username, password } = this.state;
        setModal({ name: 'isOpenUser', value: false });
        authenticate({ username, password, keytab: false });
        setModal({ name: 'isOpenProgress', value: true });
    };

    render() {
        const {  t, isOpen } = this.props;
        const { username, password } = this.state;
        return (
            <Modal
                className="packetFilter__modal"
                exitHandle={this.closeModal}
                headerClose
                headerText={'widgets:Authentication.modal.login.header'}
                modalOpen={isOpen}
                position="top-right"
            >
                <form
                    onSubmit={this.onSubmit}
                >
                    <p className="p-3">
                        {t('widgets:Authentication.modal.login.desc')}
                        <Input
                            autoComplete="username"
                            className="loginForm__username mt-4"


                            id="loginUsername"
                            label={t('widgets:Authentication.modal.login.username')}
                            name="username"
                            onChange={this.handleInputOnChange}
                            outline={false}
                            type="text"
                            value={username}
                        />
                        <Input
                            autoComplete="current-password"
                            className="loginForm__password"
                            id="loginPassword"
                            label={t('widgets:Authentication.modal.login.password')}
                            name="password"
                            onChange={this.handleInputOnChange}
                            onEnterPress={this.onSubmit}
                            outline={false}
                            type="password"
                            value={password}
                        />
                    </p>


                    <MDBModalFooter className="authentication__footer">
                        <MDBBtn
                            color="secondary"
                            onClick={this.backModal}
                        >
                            {t('widgets:Authentication.modal.login.back')}
                        </MDBBtn>
                        <MDBBtn
                            color="primary"
                            disabled={!username || !password}
                            onClick={this.onSubmit}
                        >
                            {t('widgets:Authentication.modal.login.login')}
                        </MDBBtn>
                    </MDBModalFooter>
                </form>
            </Modal>

        );
    }
}

export default  AuthenticationUserModal;
