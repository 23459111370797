/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from 'mdbreact';
import classNames from 'classnames';

import { Icon } from '~frontendComponents/Generic/index.js';
import {  getIsLoading, getVpnLeases, getVpnLeasesRequest } from '~frontendDucks/openvpn/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/index.js';

import VpnLeasesDatatable from './components/VpnLeasesDatatable/index.ts';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            data: getVpnLeases(state),
            loading: getIsLoading(state)

        };
    };
    return mapStateToProps;
};
@withTranslation()
@connect(
    makeMapStateToProps,
    {
        request: getVpnLeasesRequest
    }
)
class VpnLeases extends Component {
    static get propTypes() {
        return {
            uuid: PropTypes.string,
            t: PropTypes.func,
            request: PropTypes.func,
            data: PropTypes.array,
            loading: PropTypes.bool,
        };
    }

    constructor(props) {
        super(props);
        const { request, uuid } = props;
        if (uuid) {
            request(uuid);
        }
        this.state = {
            shownLeases: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { request, uuid } = this.props;
        if (uuid !== prevProps.uuid) {
            request(uuid);
        }
    }

    hideLeases = () => {
        const { shownLeases } = this.state;
        this.setState({
            shownLeases: !shownLeases
        });
    };

    reload = () => {
        const { request, uuid } = this.props;
        request(uuid);
    };

    render() {
        const { t, data, loading, uuid
        } = this.props;
        const { shownLeases } = this.state;
        return (
            <MDBCard>
                <MDBCardTitle className={classNames(
                    'cardHide__title',
                    { 'cardHide__title--noBorderBottom': shownLeases },
                )}
                >
                    <div
                        className="clicable"
                        onClick={this.hideLeases}
                    >
                        {t('widgets:Vpn.header.leases')}
                        <Icon
                            name={shownLeases ? 'chevron-up' : 'chevron-down'}
                        />
                    </div>
                    <MDBRow className="">
                        <MDBCol className="profile__icons">
                            <TextWithTooltip
                                className={classNames(
                                    { 'icon--green': data.length },
                                    { 'icon--pulsing': data.length },
                                    { 'icon--secondary': !data.length },
                                    'm-1'
                                )}
                                text={t('widgets:Vpn.leases.count.title', { active: data.length })}
                                tooltipText={t('widgets:Vpn.leases.count.desc')}
                                withoutTranslation

                            />
                            <IconWithTooltip
                                className="icon--secondary m-1"
                                iconSize="sm"
                                link
                                loading={loading}
                                name="reload"
                                onClick={this.reload}
                                tooltipText={t('widgets:global.reload')}
                                withoutTranslation
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardTitle>

                <MDBCardBody  className={classNames('cardHide__body',
                    { 'cardHide__body--hide': !shownLeases },
                    { 'cardHide__title--borderBottom': shownLeases },)}
                >
                    <VpnLeasesDatatable
                        ids={data.map(item => item.commonName)}
                        parentUuid={uuid}
                        type="actualLeases"
                    />

                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default VpnLeases;
