/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import PropTypes from 'prop-types';

import { ChartData, ChartObject } from '../../../shared/lib/reporterLibrary/index.js';
import reportDrawer from '../../lib/reportDrawer.js';
import { typeReportDefinition, typeReportUsage, typeRefreshResult, typeDashboardDefinition, typeReporterTemplates } from '../../types/index.js';


/**
 * Displays a chart by using library ported from KBI.
 */
class LegacyChartWithButtons extends React.Component {
    constructor(props) {
        super(props);
        this.reportContainer = React.createRef();
    }

    createChart() {
        const {
            activeReportDefinition, activeReportUsage,
            dashboardDefinition, refreshResult,
            frozenReportDefinition, frozenReportUsage,
            reporterTemplates, extraData,
        } = this.props;
        const objFilters = {};
        const chartData = new ChartData({
            activeReportDefinition, activeReportUsage,
            frozenReportDefinition, frozenReportUsage,
            objFilters,
            refreshResult,
            reporterTemplates,
        });
        const chartObject = new ChartObject(chartData, dashboardDefinition, extraData);
        reportDrawer.drawReport(reporterTemplates, this.reportContainer.current, chartObject);
    }

    // this could be optimized so that the chart is refreshed instead of recreated each time the data changes
    componentDidUpdate(prevProps) {
        const { refreshResult } = this.props;
        if (refreshResult !== prevProps.refreshResult) {
            this.createChart();
        }
    }

    render() {
        return (
            <div ref={this.reportContainer} />
        );
    }
}

LegacyChartWithButtons.propTypes = {
    activeReportDefinition: typeReportDefinition,
    activeReportUsage: typeReportUsage,
    dashboardDefinition: typeDashboardDefinition,
    frozenReportDefinition: typeReportDefinition,
    frozenReportUsage: typeReportUsage,
    refreshResult: typeRefreshResult,
    reporterTemplates: typeReporterTemplates,
    extraData: PropTypes.object,
};

export default LegacyChartWithButtons;
