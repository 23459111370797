/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {  ApiError, Icon } from '~frontendComponents/Generic/index.js';
import { typeApiError } from '~frontendTypes/index.js';


class StateWidget extends Component {
    static get propTypes() {
        return {
            error: PropTypes.oneOfType([
                PropTypes.node,
                PropTypes.string
            ]),
            apiError: typeApiError,
            warning: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]),
            success: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]),
            isLoading: PropTypes.bool,
            info: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.node
            ]),
            datacy: PropTypes.string,
        };
    }

    render() {

        const {  error, warning, isLoading, success, apiError, info, datacy } = this.props;

        if (apiError?.code) {
            return (
                <ApiError error={apiError} />
            );
        }
        if (info) {
            return (
                <p
                    className="infobox infobox--info m-2"
                    data-cy={datacy}
                >
                    {info}
                </p>
            );
        }
        if (error) {
            return (
                <p
                    className="infobox infobox--alert m-2"
                    data-cy={datacy}
                >
                    {error}
                </p>
            );}
        if (warning) {
            return (
                <p
                    className="infobox infobox--primary m-2"
                    data-cy={datacy}
                >
                    {warning}
                </p>
            );}
        if (isLoading) {
            return (
                <p
                    className="infobox infobox--primary m-2"
                    data-cy={datacy}
                >
                    <Icon
                        className="ml-2"
                        name="loading"
                    />
                </p>
            );}
        if (success) {
            return (
                <p
                    className="infobox infobox--success m-2"
                    data-cy={datacy}
                >
                    {success}
                </p>
            );}
        return null;
    }
}

export default StateWidget;
