/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCardHeader, MDBCard, MDBCardBody } from 'mdbreact';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Select } from '~frontendComponents/Generic/index.js';
import { getGlcfgValueHook, getGlcfgSchemaHook, setGlcfgObjectValue } from '~frontendDucks/hlcfgEditor/index.js';
import { JOURNAL } from '~frontendConstants/index.js';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';


const KbiUpload: React.FC = () => {
    const uploadAddressesUdp = useSelector(getGlcfgValueHook(JOURNAL))?.uploadAddressesUdp;
    const uploadAddressesUdpSchema = useSelector(getGlcfgSchemaHook(JOURNAL))?.properties?.uploadAddressesUdp;
    const dispatch = useDispatch();
    const setAddress = ({ value, id }) => {
        dispatch(setGlcfgObjectValue(JOURNAL, value, id));
    };
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardHeader>
                {t('widgets:database.kbiUpload.title')}
            </MDBCardHeader>
            <MDBCardBody>
                <Select
                    id="uploadAddressesUdp"
                    isCreatable
                    isMulti
                    label={t('differs:services.journal.uploadAddressesUdp')}
                    name="journal"
                    onChange={setAddress}
                    schema={uploadAddressesUdpSchema}
                    value={stringifyAddress(uploadAddressesUdp)}
                />
            </MDBCardBody>
        </MDBCard>
    );
};

export default KbiUpload;
