/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useContext, useCallback,
    useMemo } from 'react';
import { useDispatch   } from 'react-redux';
import { MDBCardTitle, MDBCard } from 'mdbreact';
import { useTranslation } from 'react-i18next';


import {
    setNormalize,
} from '~frontendDucks/hlcfgEditor/index.js';
import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { SMALL_SIZE, VPN_ROUTES_TYPE_PUSH_TO_USER, VPN_ROUTES_TYPE_PUSH_TO_CLIENT, OPENVPN_USER, SELECTABLE_TABLE_VPN_ROUTING, NEW_ROW_CONSTANT } from '~frontendConstants/index.js';
import { InputSearch } from '~frontendComponents/Generic/index.js';
import { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';

import Row, { vpnRoutesTypeToHlcfgTableMap, VpnRoutingTableContext } from './Row/index.ts';


const CreateRow = ({
    dataIndex,
    spacing,
    search, uuid }: CreateRowPropsType) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid}
            search={search}
            spacing={spacing}
            uuid={uuid}
        />
    );
};


const VpnRoutingTable = ({ data, className }: {data: HlcfgRowId[], className?: string, }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ search, setSearch ] = useString('');
    const { userUuid, vpnUuid, type } = useContext(VpnRoutingTableContext);

    /*
        VPN routes could be generated from VPN users as a site-to-site implementation.
        Its crutial to show user those routes.
    */
    const filteredData = useMemo(() => data?.find(item => !item?.startsWith(OPENVPN_USER)) ? data : [
        ...data?.filter(item => item?.startsWith(OPENVPN_USER)) || [],
        NEW_ROW_CONSTANT
    ], [ data ]);

    const passReorderData = useCallback((data) => {
        if (type === VPN_ROUTES_TYPE_PUSH_TO_USER) {
            dispatch(setNormalize({ value: data, uuid: userUuid, key: 'routes' }));
        } else if (type === VPN_ROUTES_TYPE_PUSH_TO_CLIENT) {
            dispatch(setNormalize({ value: data, uuid: vpnUuid, key: 'pushToClient', subkey: 'routes' }));
        } else {
            dispatch(setNormalize({
                value: data.filter(item => item.startsWith(vpnRoutesTypeToHlcfgTableMap[type])),
                uuid: vpnUuid,
                key: 'routes',
            }));
        }
    }, [ dispatch, userUuid, vpnUuid, type ]);
    return (
        <MDBCard className={className}>

            <MDBCardTitle className="profiles__title">
                {t(`widgets:Vpn.routeTable.${type}.title`)}
                <InputSearch
                    className="mb-3 mt-0"
                    id="searchValueIDRoute"
                    search={search}
                    setter={setSearch}
                />
            </MDBCardTitle>

            <DatatableWidget
                columnsId={SELECTABLE_TABLE_VPN_ROUTING}
                createRow={CreateRow}
                data={filteredData}
                passReorderData={passReorderData}
                search={search}
                spacing={SMALL_SIZE}
            />
        </MDBCard>

    );
};

export default VpnRoutingTable;
