/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getGlcfgValue, getIsCluster } from '~frontendDucks/hlcfgEditor/index.js';
import { getHealthIssues } from '~frontendDucks/clusterSetup/index.js';
import { typeApiError, typeProgressArray } from '~frontendRoot/types/index.js';
import { EVENT_PREFIX_ADAPTIVE_FIREWALL_UPDATE, NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';
import { STEP_APPLY_NEW_SETS, STEP_CREATE_NEW_SETS, STEP_DOWNLOAD, STEP_WRITE_NEW_SETS } from '~sharedConstants/index.ts';
import {
    adaptiveFirewallUpdateClose,
    getAdaptiveFirewallUpdateError,
    getAdaptiveFirewallUpdateIsAborted,
    getAdaptiveFirewallUpdateIsLoading,
    getAdaptiveFirewallUpdateIsOpen,
    getAdaptiveFirewallUpdateProgress,
} from '~frontendDucks/adaptiveFirewallUpdatePerform/index.js';

import { ActionSequenceModal } from '../../../ActionSequence/index.js';
import Message from '../../../Message/index.js';


const RulesUpdateAbortedTitle = () => (
    <Message message="actions:action.sequence.AdaptiveFirewallRulesUpdate.aborted.title" />
);

const RulesUpdateDoneTitle = () => (
    <Message message="actions:action.sequence.AdaptiveFirewallRulesUpdate.done.title" />
);

const RulesUpdateErrorTitle = () => (
    <Message message="actions:action.sequence.AdaptiveFirewallRulesUpdate.error.title" />
);

const RulesUpdateLoadingTitle = () => (
    <Message message="actions:action.sequence.AdaptiveFirewallRulesUpdate.loading.title" />
);


const RulesUpdateStepProgress = ({ event }) => {
    const { currentDone } = event;

    switch (currentDone) {
    case STEP_CREATE_NEW_SETS:
        return <Message message="actions:action.step.updateAdaptiveFirewall.createNewSets.title" />;
    case STEP_WRITE_NEW_SETS:
        return <Message message="actions:action.step.updateAdaptiveFirewall.writeNewSets.title" />;
    case STEP_APPLY_NEW_SETS:
        return <Message message="actions:action.step.updateAdaptiveFirewall.applyNewSets.title" />;
    case STEP_DOWNLOAD:
        return <Message message="actions:action.step.updateAdaptiveFirewall.downloading.title" />;
    default:
        return <Message message="actions:action.step.updateAdaptiveFirewall.started.title" />;
    }
};

RulesUpdateStepProgress.propTypes = {
    event: PropTypes.object
};

const STEPS = {
    [EVENT_PREFIX_ADAPTIVE_FIREWALL_UPDATE]: {
        StepAbortedTitle: RulesUpdateAbortedTitle,
        StepDoneTitle: RulesUpdateDoneTitle,
        StepErrorTitle: RulesUpdateErrorTitle,
        StepLoadingTitle: RulesUpdateLoadingTitle,
        StepProgress: RulesUpdateStepProgress,
    },
};

/**
 * Renders configuration activation modal window.
 */
export const RulesUpdateModalBase = ({
    isOpen, isLoading, isAborted, error, onAbort, progress, isLoadingnodeA, isAbortednodeA, errornodeA, progressnodeA,
    isLoadingnodeB, isAbortednodeB, errornodeB, progressnodeB, doRulesUpdateClose, isCluster, hostnamenodeA,
    hostnamenodeB, healthIssues
}) => (
    <ActionSequenceModal
        {...{ isLoadingnodeA, isAbortednodeA, errornodeA, progressnodeA,
            isLoadingnodeB, isAbortednodeB, errornodeB, progressnodeB, isCluster, hostnamenodeA,
            hostnamenodeB, healthIssues }}
        error={error}
        isAborted={isAborted}
        isLoading={isLoading}
        isOpen={isOpen}
        onAbort={onAbort}
        onClose={doRulesUpdateClose}
        progress={progress}
        sequenceAbortedTitle={(<RulesUpdateAbortedTitle />)}
        sequenceDoneTitle={(<RulesUpdateDoneTitle />)}
        sequenceErrorTitle={(<RulesUpdateErrorTitle />)}
        sequenceLoadingTitle={(<RulesUpdateLoadingTitle />)}
        sequenceTitle={(<Message message="actions:action.sequence.AdaptiveFirewallRulesUpdate.modal.title" />)}
        steps={STEPS}
    />
);

RulesUpdateModalBase.propTypes = {
    doRulesUpdateClose: PropTypes.func.isRequired,
    error: typeApiError,
    errornodeA: typeApiError,
    errornodeB: typeApiError,
    isAborted: PropTypes.bool,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onAbort: PropTypes.func,
    progress: typeProgressArray.isRequired,
    isAbortednodeA: PropTypes.bool,
    isLoadingnodeA: PropTypes.bool,
    isOpennodeA: PropTypes.bool,
    onAbortnodeA: PropTypes.func,
    progressnodeA: typeProgressArray.isRequired,
    isAbortednodeB: PropTypes.bool,
    isLoadingnodeB: PropTypes.bool,
    isOpennodeB: PropTypes.bool,
    onAbortnodeB: PropTypes.func,
    progressnodeB: typeProgressArray.isRequired,
    isCluster: PropTypes.bool,
    hostnamenodeA: PropTypes.string,
    hostnamenodeB: PropTypes.string,
    healthIssues: PropTypes.array
};

/**
 * @see Redux.connect()
 */
const mapStateToProps = state => ({
    isOpen: getAdaptiveFirewallUpdateIsOpen(state),
    isLoading: getAdaptiveFirewallUpdateIsLoading(state),
    isAborted: getAdaptiveFirewallUpdateIsAborted(state),
    error: getAdaptiveFirewallUpdateError(state),
    progress: getAdaptiveFirewallUpdateProgress(state),
    isLoadingnodeA: getAdaptiveFirewallUpdateIsLoading(state, NODE_A_ID),
    isAbortednodeA: getAdaptiveFirewallUpdateIsAborted(state, NODE_A_ID),
    errornodeA: getAdaptiveFirewallUpdateError(state, NODE_A_ID),
    progressnodeA: getAdaptiveFirewallUpdateProgress(state, NODE_A_ID),
    isLoadingnodeB: getAdaptiveFirewallUpdateIsLoading(state, NODE_B_ID),
    isAbortednodeB: getAdaptiveFirewallUpdateIsAborted(state, NODE_B_ID),
    errornodeB: getAdaptiveFirewallUpdateError(state, NODE_B_ID),
    progressnodeB: getAdaptiveFirewallUpdateProgress(state, NODE_B_ID),
    isCluster: getIsCluster(state),
    hostnamenodeA: getGlcfgValue(state, 'firewallClusterAHostname'),
    hostnamenodeB: getGlcfgValue(state, 'firewallClusterBHostname'),
    healthIssues: getHealthIssues(state)
});

const mapDispatchToProps = {
    doRulesUpdateClose: adaptiveFirewallUpdateClose,
};

/**
 * Activation modal window.
 */
const RulesUpdateModal = connect(mapStateToProps, mapDispatchToProps)(RulesUpdateModalBase);

export default RulesUpdateModal;
