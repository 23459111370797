/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../../index.js';


class DatatableCellWithIcon extends Component {
    static get propTypes() {
        return {
            title: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired
        };
    }

    render() {
        const { icon, title } = this.props;
        return (
            <div className="d-flex align-items-center">
                {
                    //flex-shrink-0 to prevent shrinking on icon
                }
                <Icon
                    className="flex-shrink-0"
                    name={icon}
                    size="sm"
                />
                {title}
            </div>
        );
    }
}

export default DatatableCellWithIcon;
