/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Select } from '~frontendComponents/Generic/index.js';
import suricataAddressVariablesValidation, {
    existingSuricataAddressVariables, existingSuricataPortVariables
} from '~sharedLib/suricataVariablesValidation.js';

import { setSuricataVariable,
} from '../../ducks/hlcfgEditor/index.js';
import Message from '../Message/index.js';


const addNegateValueToArray = (array) => {
    const returnArray = [];
    array.map(value => {
        returnArray.push(value);
        returnArray.push('!' + value);
    });
    return returnArray;
};


const SuricataVariablesInputBase = ({
    port, name, value, doSetValue, schema
}) => {
    return (
        <Select
            id={name}
            isCreatable
            isMulti
            label={<Message message={`widgets:Suricata.variables.${name}.title`} />}
            noDropdownIndicator
            onChange={({ value }) => doSetValue('suricataVariables', port, name, value)}
            options={port ?
                addNegateValueToArray(existingSuricataPortVariables).map(item => {
                    return {
                        value: item,
                        label: item
                    };
                }) : addNegateValueToArray(existingSuricataAddressVariables).map(item => {
                    return {
                        value: item,
                        label: item
                    };})}
            paste={false}
            schema={schema}
            smallError
            validator={inputValue =>
                !suricataAddressVariablesValidation([ inputValue ], !port).length
            }
            value={value}
        />
    );
};


const mapStateToProps = () => ({

});

const mapDispatchToProps = {
    doSetValue: setSuricataVariable
};

const SuricataVariablesInput = connect(mapStateToProps, mapDispatchToProps)(SuricataVariablesInputBase);

SuricataVariablesInput.propTypes = {
    port: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
    doSetValue: PropTypes.func,
    schema: PropTypes.object,
};

SuricataVariablesInputBase.propTypes = {
    ...SuricataVariablesInput.propTypes,
};

export default SuricataVariablesInput;
