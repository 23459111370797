/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Modal } from '~frontendComponents/Generic/index.js';
import {  setHardwareInfoModalOpen, getHardwareInfoModalOpen, doReboot, doShutdown } from '~frontendDucks/systemInfo/index.js';
import { frontendReplace } from '~frontendLib/reactUtils.js';


@withTranslation()
@connect(
    state => ({
        shutdownModal: getHardwareInfoModalOpen(state, 'shutdownModal'),
        rebootModal: getHardwareInfoModalOpen(state, 'rebootModal')
    }),
    {
        doSetHardwareInfoModalOpen: setHardwareInfoModalOpen,
        letsDoShutdown: doShutdown,
        letsDoReboot: doReboot,
    }
)

class HardwareModal extends Component {
    static get propTypes() {
        return {
            doSetHardwareInfoModalOpen: PropTypes.func,
            letsDoReboot: PropTypes.func,
            letsDoShutdown: PropTypes.func,
            shutdownModal: PropTypes.bool,
            rebootModal: PropTypes.bool,
        };
    }

    closeModal = ({ name }) => {
        const { doSetHardwareInfoModalOpen } = this.props;
        doSetHardwareInfoModalOpen({ name, value: false });
    };

    shutdown = () => {
        const { letsDoShutdown } = this.props;
        letsDoShutdown();
        frontendReplace({
            first: { message: 'widgets:device.shutdown.header' },
            second: { message: 'widgets:device.shutdown.shuttingdown' },
            shuttingDown: true
        });
    };

    reboot = () => {
        const { letsDoReboot } = this.props;
        letsDoReboot();
        frontendReplace({
            first: { message: 'widgets:device.reboot.header' },
            second: { message: 'widgets:device.reboot.canTakeSomeTime' },
            third: { message: 'widgets:device.reboot.manualReload' },
            reload: true
        }, true);
    };


    render() {
        const { shutdownModal, rebootModal } = this.props;

        return (
            <Modal
                bodyText={shutdownModal ? 'widgets:device.shutdown.body' : 'widgets:device.reboot.body'}
                exitHandle={() => this.closeModal({ name: shutdownModal ? 'shutdownModal' : 'rebootModal' })}
                headerText={shutdownModal ? 'widgets:device.shutdown.modalHeader' :
                    'widgets:device.reboot.modalHeader'}
                modalOpen={shutdownModal || rebootModal}
                negativeResponse={() => this.closeModal({ name: shutdownModal ? 'shutdownModal' : 'rebootModal' })}
                position="top-right"
                positiveResponse={shutdownModal ? this.shutdown : this.reboot}
            />
        );
    }
}

export default HardwareModal;
