/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {  MDBBtn, MDBModalFooter } from 'mdbreact';


import { Input,  Modal } from '~frontendComponents/Generic/index.js';
import {  getAuthenticationLoginIsOpen, setAuthenticationLoginModal,
    authenticationStart
} from '~frontendDucks/authentication/index.js';


@withTranslation()
@connect(
    state => ({
        isOpen: getAuthenticationLoginIsOpen(state, 'isOpenKeytab')

    }),
    {
        setModal: setAuthenticationLoginModal,
        authenticate: authenticationStart
    }
)
class AuthenticationKeytabModal extends Component {
    static get propTypes() {
        return {
            isOpen: PropTypes.bool,
            t: PropTypes.func,
            setModal: PropTypes.func,
            authenticate: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            file: {},
        };
    }

    handleInputOnChange = ({ value }) => {
        this.setState({
            file: value
        });
    };

    onSubmit = () => {
        this.openProgressModal();
        this.closeModal();
    };


    backModal = () => {
        const { setModal } = this.props;
        setModal({ name: 'isOpenKeytab', value: false });
        setModal({ name: 'isOpenLogin', value: true });

    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal({ name: 'isOpenKeytab', value: false });

    };

    openProgressModal = () => {
        const {  authenticate,  setModal } = this.props;
        const { file } = this.state;
        setModal({ name: 'isOpenKeytab', value: false });
        authenticate({ file, keytab: true });
        setModal({ name: 'isOpenProgress', value: true });
    };

    render() {
        const {  t, isOpen } = this.props;
        const { file } = this.state;
        return (
            <Modal
                className="packetFilter__modal"
                exitHandle={this.closeModal}
                headerClose
                headerText={'widgets:Authentication.modal.login.header'}
                modalOpen={isOpen}
                position="top-right"
            >
                <form
                    onSubmit={this.onSubmit}
                >
                    <p className="pl-3 pr-3 pt-3">
                        {t('widgets:Authentication.modal.keytab.warning')}
                    </p>
                    <div className="p-3 pt-0">
                        <Input
                            autoComplete="username"
                            className="loginForm__username mt-4"
                            id="loginUsername"
                            maxSize={2000000}
                            name="username"
                            onChange={this.handleInputOnChange}
                            outline={false}
                            type="file"
                        />
                    </div>


                    <MDBModalFooter className="authentication__footer">
                        <MDBBtn
                            color="secondary"
                            onClick={this.backModal}
                        >
                            {t('widgets:Authentication.modal.login.back')}
                        </MDBBtn>
                        <MDBBtn
                            color="primary"
                            disabled={!file.name}
                            onClick={this.onSubmit}
                        >
                            {t('widgets:Authentication.modal.login.login')}
                        </MDBBtn>
                    </MDBModalFooter>
                </form>
            </Modal>

        );
    }
}

export default  AuthenticationKeytabModal;
