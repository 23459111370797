/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createNotification } from '~frontendLib/reactUtils.js';


let connected = true;
export const noConnection = () => {
    if (!connected) {
        return;
    }
    connected = false;
    createNotification({
        title: 'notifications:connection.lost.title',
        desc: '',
        type: 'danger',
        group: 'connection',
        persistent: true
    });
};

export const yesConnection = () => {
    if (connected) {
        return;
    }
    connected = true;
    createNotification({
        title: 'notifications:connection.restored.title',
        desc: '',
        type: 'success',
        group: 'connection',
        persistent: true
    });
};
