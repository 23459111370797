/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { HlcfgSchemaJSON } from '~backendRoot/schemas/hlcfg.schema.ts';
import { HLCFG_OFF } from '~sharedConstants/index.ts';
import { SCHEMA_TYPE_ROW_ID, SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY } from '~sharedLib/schemaTypes.ts';
import { HlcfgRowId, hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import type { HlcfgPath } from '~sharedLib/types.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { getTypeOf } from '~commonLib/getTypeOf.js';


interface Opts {
    filterOff?: boolean
}

export const traverseHlcfg = (hlcfgTree, hlcfgSchema: HlcfgSchemaJSON, { filterOff = true }: Opts = {}) => {
    const activeRowIds = new Set<string>();
    const reachableRowIds = new Set<string>();
    const nonExistentRowIds = new Set<string>();
    const pathsContainingRowIds = new Set<HlcfgPath>();
    const pathsBySecondaryRowRef = new Map<string, HlcfgPath[]>();
    const rowTree = {};

    const { tables, ...cfgPart } = hlcfgTree;

    const getRowSchema = (tableName: string) =>
        hlcfgSchema.properties.tables.properties[tableName].additionalProperties;
    const getRowTable = (tableName: string) => tables[tableName];

    const resolvedCfg = { cfgPart: {} };


    const traverse = ({
        itemParent, itemKey, itemParentResolved, itemSchema, isOff, descriptivePath, containingRowTree
    }) => {
        const rowIsEnabled = (rowId: HlcfgRowId, rowSchema) => {
            const tableName = hlcfgTableNameByRowId(rowId);
            const rowObject = getRowTable(tableName)?.[rowId];
            pathsContainingRowIds.add(descriptivePath);

            if (rowObject) {
                if (!rowSchema[SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY]) {
                    reachableRowIds.add(rowId);
                    containingRowTree[rowId] = {};
                } else {
                    const paths = pathsBySecondaryRowRef.get(rowId) || [];
                    pathsBySecondaryRowRef.set(rowId, [ ...paths, descriptivePath ]);
                }
            } else {
                nonExistentRowIds.add(rowId);
            }
            const rowEnabled = rowObject && !rowObject[HLCFG_OFF];
            if (!isOff && rowEnabled && !rowSchema[SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY]) {
                activeRowIds.add(rowId);
            }
            return rowEnabled;
        };

        const item = itemParent?.[itemKey] === undefined ? itemSchema?.[DEFAULT_SCHEMA_VALUE] : itemParent?.[itemKey];
        if (item === undefined) {
            return;
        }
        const resolved = isOff && filterOff ? {} : itemParentResolved;

        const itemType = getTypeOf(item, false);

        if (itemType !== 'object' && itemType !== 'array') {
            if (typeof item === 'string' && itemSchema && itemSchema[SCHEMA_TYPE_ROW_ID]) {
                const rowIsNotOff = rowIsEnabled(item, itemSchema);
                if (itemSchema[SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY]) {
                    resolved[itemKey] = item;
                    return;
                }
                if (rowIsNotOff) {
                    resolved[itemKey] = {
                        $id: {
                            [item]: {}
                        }
                    };
                }
                const tableName = hlcfgTableNameByRowId(item);
                const tableObject = getRowTable(tableName);
                traverse({
                    itemParent: tableObject,
                    itemKey: item,
                    descriptivePath: [ ...descriptivePath, '$id', item ],
                    itemParentResolved: resolved[itemKey]?.$id || {},
                    itemSchema: tableObject ? getRowSchema(tableName) : undefined,
                    isOff: isOff || !rowIsNotOff,
                    containingRowTree: itemSchema[SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY] ?
                        containingRowTree :
                        containingRowTree[item]
                });
                return;
            } else {
                resolved[itemKey] = item;
                return;
            }
        }

        if (itemType === 'object') {
            const isRecord = itemSchema?.additionalProperties;
            if (!item[HLCFG_OFF] || !filterOff) {
                resolved[itemKey] = {};
            }
            const schemaProps = Object.keys(
                (isRecord ? itemSchema?.additionalProperties : itemSchema?.properties) || {}
            );
            const itemProps = Object.keys(item); // must check also item props, because __off
            [ ...new Set([ ...schemaProps, ...itemProps ]) ].forEach(key => {
                if (key === HLCFG_OFF) {
                    if (!filterOff) {
                        resolved[itemKey][key] = item[key];
                    }
                    return;
                }
                traverse({
                    itemParent: item,
                    itemKey: key,
                    descriptivePath: [ ...descriptivePath, key ],
                    itemParentResolved: resolved[itemKey],
                    itemSchema: isRecord ? itemSchema.additionalProperties : itemSchema?.properties?.[key],
                    isOff: isOff || item[HLCFG_OFF],
                    containingRowTree,
                });
            });
        } else {
            const schema = itemSchema?.items;
            if (schema?.[SCHEMA_TYPE_ROW_ID]) {
                const notOffedIds = item.filter(id => (typeof id === 'string') && rowIsEnabled(id, schema));
                if (schema[SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY]) {
                    resolved[itemKey] = item;
                    return;
                }
                const ids = filterOff ? notOffedIds : item;
                resolved[itemKey] = {
                    $ids: item,
                    $byId: ids.reduce((acc, rowId) => {
                        return {
                            ...acc,
                            [rowId]: {}
                        };
                    }, {})
                };
                item.forEach(rowId => {
                    const tableName = hlcfgTableNameByRowId(rowId);
                    const tableObject = getRowTable(tableName);
                    traverse({
                        itemParent: tableObject,
                        itemKey: rowId,
                        descriptivePath: [ ...descriptivePath, '$byId', rowId ],
                        itemParentResolved: resolved[itemKey]?.$byId || {},
                        itemSchema: tableObject ? getRowSchema(tableName) : undefined,
                        isOff: isOff || !notOffedIds.includes(rowId),
                        containingRowTree: itemSchema[SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY] ?
                            containingRowTree :
                            containingRowTree[rowId]
                    });
                });
                return;

            } else {
                resolved[itemKey] = item;
                return;
            }
        }
    };

    traverse({
        itemParent: { cfgPart },
        itemKey: 'cfgPart',
        itemSchema: hlcfgSchema,
        isOff: false,
        itemParentResolved: resolvedCfg,
        descriptivePath: [],
        containingRowTree: rowTree,
    });

    const allRowIds = Object.values(tables).map((table: object) => Object.values(table).map(item => item.id)).flat();


    return {
        resolvedCfg: resolvedCfg.cfgPart,
        nonExistentRows: [ ...nonExistentRowIds ],
        unreachableRows: allRowIds.filter(id => !reachableRowIds.has(id)),
        inactiveRows: allRowIds.filter(id => !activeRowIds.has(id)),
        descriptivePathsContainingRowIds: [ ...pathsContainingRowIds ],
        pathsBySecondaryRowRefMap: pathsBySecondaryRowRef,
        rowChildrenByRowId: getChildrenByIdFromRowTree(rowTree),
    };
};

const getChildrenByIdFromRowTree = rowTree => {
    const rowChildrenByRowId = {};
    const traverseRowTree = (rowBranch, parentRows: string[]) => {
        Object.keys(rowBranch).forEach(rowId => {
            if (!rowChildrenByRowId[rowId]) {
                rowChildrenByRowId[rowId] = [];
            }
            parentRows.forEach(parent => rowChildrenByRowId[parent].push(rowId));
            traverseRowTree(
                rowBranch[rowId],
                [ ...parentRows, rowId ]
            );
        });
    };
    traverseRowTree(rowTree, []);
    return rowChildrenByRowId;
};
