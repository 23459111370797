/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const okResponseSchema = <const T>(innerSchema: T) => <const>{
    description: 'OK',
    content: {
        'application/json': {
            schema: innerSchema,
        },
    },
};

export const okResponsePlaintextSchema = <const>{
    description: 'OK',
    content: {
        'text/plain': {},
    },
};

export const emptyOkResponseSchema = okResponseSchema({
    type: 'object',
    properties: {},
    additionalProperties: false,
});
