/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useEffect } from 'react';
// eslint-disable-next-line node/file-extension-in-import
import ReactSelect from 'react-select/creatable';
import { components } from 'react-select';
import assert from 'assert';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { getReactContentWrapper } from '~frontendLib/reactUtils.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/TextWithTooltip.js';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { SECOND } from '~commonLib/constants.ts';
import Popover from '~frontendComponents/Popover/Popover.tsx';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/IconWithTooltip.js';
import { testProps } from '~commonLib/PageObjectMap.ts';
import { SelectV2Context, useSelectV2Model, useSelectContext } from '~frontendComponents/Generic/SelectV2/state.ts';
import { SelectV2Props, SelectOption } from '~frontendComponents/Generic/SelectV2/types.ts';
import { CONTROL_CLASS_BY_STATE, SELECT_PO_LABELS } from '~frontendComponents/Generic/SelectV2/constants.ts';
import { isDeepEqual, objectPick } from '~commonLib/objectUtils.ts';
import Message from '~frontendComponents/Message/Message.tsx';


const UNICODE_REGEX = /[\u0300-\u036f]/g;
const normalizeForSearch = (str: string) => {
    return str.normalize('NFD').replace(UNICODE_REGEX, '').toLowerCase();
};
const stringMatches = (toMatch: string, searchValue: string) => {
    return normalizeForSearch(toMatch).includes(normalizeForSearch(searchValue));
};

const NO_PLACEHOLDER = '';

export const SelectV2 = <T, >(props: SelectV2Props<T>) => {
    assert(props.value || props.defaultValue, 'Must specify value or defaultValue');
    assert(!props.value || !props.defaultValue, 'Can not specify both value and defaultValue');
    if (props.parse || props.clipboardParse) {
        assert(props.prepareOption, 'Must specify prepareOption when parse or clipboardParse is specified');
    }

    const {
        selectContext, inputOrder, onFocusCapture, getColor, maxItemsReached, ...selectPropsFromModel
    } = useSelectV2Model(props);
    const { t } = useTranslation();
    return (
        <div
            onFocusCapture={onFocusCapture}
            {...testProps(props.id)}
        >
            <SelectV2Context.Provider value={selectContext}>
                <ReactSelect
                    className={classNames('form-group select2', {
                        disabled: props.disabled,
                    })}
                    classNamePrefix={'select'}
                    classNames={{
                        multiValue: (item: any) => {
                            if (item.data.notRemovable || selectContext.selectIsNotEditable) {
                                return 'not-removable';
                            }
                            return '';
                        },
                    }}
                    components={{
                        SelectContainer,
                        MultiValue,
                        MultiValueRemove,
                        IndicatorsContainer,
                        MultiValueLabel,
                        Option,
                        Control,
                        Input,
                    }}
                    createOptionPosition="first"
                    filterOption={(candidate, input: string) => {
                        const { label, value, searchStrings, tooltip } = candidate.data as SelectOption<T>;

                        const possiblyJsxFiltered = [ value, label, tooltip ].map(it => {
                            switch (typeof it) {
                            case 'string':
                                return it;
                            case 'boolean':
                            case 'bigint':
                            case 'number':
                                return it.toString();
                            default:
                                return undefined;
                            }
                        }).filter(it => it !== undefined);

                        const toMatch = [ ...possiblyJsxFiltered, ...searchStrings ?? [] ];
                        return toMatch.some(it => {
                            return stringMatches(it, input);
                        });
                    }}
                    formatCreateLabel={(value) => {
                        assert(props.parse && props.prepareOption);
                        const parsed = props.parse(value);
                        assert(parsed);

                        if (parsed.suggest) {
                            return parsed.suggest.suggestDescription;
                        }
                        return `${t('packetFilter:create')}: "${value}"`;
                    }}
                    getOptionValue={(opt: SelectOption<T>) => {
                        if (!props.stringify) {
                            return opt.value as any;
                        }
                        return props.stringify(opt.value);
                    }}
                    isClearable={false}

                    isMulti={true}

                    isValidNewOption={(value, values: SelectOption<T>[], existingOptions: SelectOption<T>[]) => {
                        if (!props.parse) {
                            return false;
                        }
                        const parsed = props.parse(value);
                        if (!parsed) {
                            return false;
                        }
                        const theValue = parsed.parsed ?? parsed.suggest?.value;
                        assert(theValue !== undefined);
                        return [ ...values, ...existingOptions ].every(it => !isDeepEqual(it.value, theValue));
                    }}
                    menuPlacement="auto"
                    menuPortalTarget={getReactContentWrapper() || document.body}
                    noOptionsMessage={getNoOptionsComponent({ maxItemsReached })}
                    styles={{
                        menu: (styles) => {
                            return {
                                ...styles,
                                backgroundColor: undefined,
                                boxRadius: undefined,
                                boxShadow: undefined,
                                width: 'fit-content',
                                minWidth: '9rem',
                                zIndex: 1000000000000,

                            };
                        },
                        menuPortal: base => ({ ...base, zIndex: 1000 }),
                        multiValueRemove: disableEmotion,
                        option: disableEmotion,
                        indicatorSeparator: disableEmotion,
                        indicatorsContainer: disableEmotion,
                        valueContainer: disableEmotion,
                        multiValueLabel: disableEmotion,
                        container: disableEmotion,
                        placeholder: disableEmotion,
                        control: disableEmotion,
                        multiValue: (_styles, { data, index }) => {
                            return {
                                ...getColor(data as SelectOption<T>),
                                order: index * 2,
                            };
                        },
                        input: () => {
                            return {
                                order: inputOrder,
                            };
                        },
                    }}
                    {...selectPropsFromModel}
                    placeholder={NO_PLACEHOLDER}
                    {...objectPick(props, [ 'id', 'placeholder', 'label', 'closeMenuOnSelect' ])}
                />
            </SelectV2Context.Provider>
        </div>
    );
};

const disableEmotion = () => null as any;

const IndicatorsContainer = (props: Parameters<typeof components.IndicatorsContainer>[0]) => {
    return (
        <components.IndicatorsContainer {...props}>
            <ErrorIcon />
            <WarningIcon />
            <MoreItemsIcon {...props} />
            <CopyIcon />
        </components.IndicatorsContainer>
    );
};
const ErrorIcon = () => {
    const { controlState } = useSelectContext();
    if (controlState.state !== 'invalid') {
        return null;
    }
    return (
        <IconWithTooltip
            iconSize="sm"
            name="alert-rhombus-outline"
            tooltipText={controlState.msg}
        />
    );

};
const WarningIcon = () => {
    const { controlState } = useSelectContext();
    if (controlState.state !== 'warning') {
        return null;
    }
    return (
        <IconWithTooltip
            iconSize="sm"
            name="alert-outline"
            tooltipText={controlState.msg}
        />
    );

};
const CopyIcon = () => {
    const { allValues, stringifyForCopy, copyToClipboard } = useSelectContext();
    const onCopy = useCallback((event: React.SyntheticEvent) => {
        event.stopPropagation();
        copyToClipboard();
    }, [ copyToClipboard ]);

    if (!allValues.length || !stringifyForCopy) {
        return null;
    }

    return (
        <div
            onMouseDownCapture={onCopy}
        >
            {copyIconRendered}
        </div>
    );
};
const copyIconRendered = (
    <IconWithTooltip
        className="icon--grey icon--clicable"
        iconSize="sm"
        name="content-copy"
        tooltipPlace={'top'}
        tooltipText={'widgets:global.copy'}
        {...testProps(SELECT_PO_LABELS.copyButton)}
    />
);
const MaxItemsReached = () => <Message message="components:Select.maxItems" />;
const getNoOptionsComponent = ({ maxItemsReached }: {maxItemsReached: boolean}) => {
    if (maxItemsReached) {
        return MaxItemsReached;
    }
    return () => null;
};

const MoreItemsIcon = (props: Parameters<typeof components.IndicatorsContainer>[0]) => {
    const { value, allValues, maxItemsToDisplay, getColor } = useSelectContext();
    if (!maxItemsToDisplay || allValues.length <= maxItemsToDisplay) {
        return null;
    }
    const howManyMore = allValues.length - value.length;
    return (
        <Popover
            body={
                <>
                    {allValues.map((item, idx) => {
                        const innerProps = {
                            className: 'select__box select__multi-value__label',
                            style: getColor(item),
                        };
                        return (
                            <MultiValueLabel
                                data={item}
                                innerProps={innerProps}
                                key={`label-${idx}`}
                                selectProps={props.selectProps}
                            >
                                {item.label}
                            </MultiValueLabel>
                        );
                    })}
                </>
            }
        >

            <i
                className={'icon--black select__box'}
            >
                {`+${howManyMore}`}
            </i>

        </Popover>
    );
};
const MultiValueRemove = (props: Parameters<typeof components.MultiValueRemove>[0]) => {
    const [ highlight, setHighlight ] = useBoolean(false);
    const { setSelectFocus, stringify, selectIsNotEditable } = useSelectContext();
    useEffect(() => {
        if (highlight) {
            const turnOffHighlightTimeout = setTimeout(() => {
                setHighlight.off();
            }, SECOND);

            return () => {
                clearTimeout(turnOffHighlightTimeout);
            };
        }
    }, [ highlight, setHighlight ]);

    assert(
        typeof props.data === 'object' && props.data && 'value' in props.data,
        'Unexpected data in Remove'
    );

    if (
        ('notRemovable' in props.data && props.data.notRemovable) ||
        ('disabled' in props.selectProps && props.selectProps.disabled) ||
        selectIsNotEditable
    ) {
        return null;
    }
    return (
        <div
            className={classNames('select__multi-value__remove', { highlight })}
            onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (highlight) {
                    const onClick = props.innerProps.onClick;
                    assert(onClick, 'react-select should have provided onclick');
                    onClick(event);
                } else {
                    setHighlight.on();
                    setSelectFocus(true);
                }
            }}
            {...testProps(SELECT_PO_LABELS.removeValPrefix + stringify(props.data.value))}
        >
            {CrossRendered}
        </div>
    );
};

// eslint-disable-next-line max-len
const svgPath = 'M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z';
const CrossIcon = () => (
    <svg
        aria-hidden="true"
        focusable="false"
        height="14"
        viewBox="0 0 20 20"
        width="14"
    ><path d={svgPath}></path>
    </svg>
);
// Micro-optimization, but it seems to help a tiny bit
const CrossRendered = <CrossIcon />;

const MultiValueLabel = (props: Parameters<typeof components.MultiValueLabel>[0]) => {
    const ctx = useSelectContext();
    return (
        <TextWithTooltip
            tooltipText={props.data.tooltip}
            withoutTranslation={true}
        >
            <div onClick={() => ctx.startEditingItem(props.data)}>
                <components.MultiValueLabel
                    {...props}
                    innerProps={{
                        ...props.innerProps,
                        ...testProps(SELECT_PO_LABELS.valLabelPrefix + ctx.stringify(props.data.value)),
                    }}
                />
            </div>
        </TextWithTooltip>
    );
};
const Option = (props: Parameters<typeof components.Option>[0] & {data: SelectOption}) => {
    const ctx = useSelectContext();
    return (
        <TextWithTooltip
            tooltipText={props.data.tooltip}
            withoutTranslation={true}
        >
            <components.Option
                {...props}
                innerProps={{
                    ...props.innerProps,
                    ...testProps(SELECT_PO_LABELS.menuOptPrefix + ctx.stringify(props.data.value)),
                }}
            />
        </TextWithTooltip>
    );
};
const MultiValue = (props: Parameters<typeof components.MultiValue>[0] & {data: SelectOption}) => {
    const ctx = useSelectContext();
    return (
        <components.MultiValue
            {...props}
            getStyles={disableEmotion}
            innerProps={{
                style: props.getStyles('multiValue', props) as any,
                ...testProps(SELECT_PO_LABELS.valBoxPrefix + ctx.stringify(props.data.value)),
            }}
        />
    );

};
const Input = (props: Parameters<typeof components.Input>[0]) => {
    const ctx = useSelectContext();
    if (ctx.disabled || ctx.selectIsNotEditable) {
        return null;
    }
    return (
        <div
            style={props.getStyles('input', props) as any}
        >
            <components.Input
                {...props}
                getStyles={disableEmotion}
                {...testProps(SELECT_PO_LABELS.input)}
            />
        </div>
    );
};

const Control = (props: Parameters<typeof components.Control>[0]) => {
    const { controlState } = useSelectContext();
    return (
        <components.Control
            {...props}
            className={classNames(
                'form-control select',
                CONTROL_CLASS_BY_STATE[controlState.state],
                CONTROL_CLASS_BY_STATE[props.isFocused ? 'focused' : 'notFocused'],
                props.className,
            )}
            innerProps={{
                ...props.innerProps,
                ...testProps(SELECT_PO_LABELS.control),
            }}
        >
            {props.children}
            <SelectLabel {...props} />
        </components.Control>
    );
};

const SelectLabel = (props: Parameters<typeof components.Control>[0]) => {
    const { label, id, placeholder, inputValue } = props.selectProps as any;
    const { isFocused, hasValue } = props;
    if (!label) {
        return null;
    } else {
        const selectIsEmpty = hasValue === false && inputValue === '' && placeholder === NO_PLACEHOLDER;
        return (
            <label
                className={classNames(
                    'form-control__label',
                    {
                        'active': isFocused || !selectIsEmpty,
                    },
                )}
                htmlFor={id}
            >
                {label}
            </label>
        );
    }
};

const SelectContainer = (props: Parameters<typeof components.SelectContainer>[0]) => {
    const { tooltip } = useSelectContext();
    return (
        <components.SelectContainer
            {...props}
        >
            <TextWithTooltip
                className="select--tooltip"
                tooltipText={tooltip}
            >
                {
                    // First child is some accessibility stuff using emotion that can not be overriden,
                    // Which is hurting performance making renders up to 40% longer. So it is removed here.
                    (props.children as any).slice(1)
                }
            </TextWithTooltip>
        </components.SelectContainer>
    );
};
