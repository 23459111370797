/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardHeader, MDBRow, MDBCol, MDBCardBody } from 'mdbreact';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Svg from 'react-inlinesvg';

import logo from '~frontendRoot/img/svg/logo.svg';
import NoData from '~frontendComponents/NoData/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/index.js';
import { setHelp } from '~frontendDucks/layout/ducks/navBarLeft.js';
import { onMouseEnter, onMouseLeave } from '~frontendLib/onMouse.js';
import { Icon } from '~frontendComponents/Generic/index.js';
import { DEFAULT_ZOOM, LOG, MAX_ZOOM, MIN_ZOOM } from '~frontendConstants/constants.ts';
import Message from '~frontendComponents/Message/index.js';
import { VPN_INTERFACE_TOPOLOGY_TAP, VPN_INTERFACE_TOPOLOGY_TUN_NET30, VPN_INTERFACE_TOPOLOGY_TUN_P2P,
    VPN_INTERFACE_TOPOLOGY_TUN_SUBNET } from '~sharedConstants/index.ts';
import { getValueExamplesBySchema } from '~frontendLib/getValueExamplesBySchema.ts';
import { IconNameType, IconSize } from '~frontendComponents/Generic/Icon/Icon.tsx';
import { createPathGetter } from '~commonLib/objectUtils.ts';
import { HlcfgInputTree } from '~frontendTypes/externalTypes.ts';
import { HlcfgPath } from '~sharedLib/types.ts';
import { NODE_SHARED } from '~commonLib/constants.ts';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';
import { getRecoveryMode } from '~frontendDucks/backup/index.js';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { userSetting } from '~frontendRoot/constants/index.js';
import { useUserSetting } from '~frontendRoot/lib/hooks/userSettings.ts';
import { getRouterPath } from '~frontendDucks/userAuthentication/userAuthentication.js';
import { getCwdbV2Enabled } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';

import { getTranslationFromReactPath } from '../NavBarTop/index.js';


const Help = ({ schema }: { schema: any }) => {
    const routerPath = useSelector(getRouterPath);
    const [ zoomLevel = DEFAULT_ZOOM, setZoomLevel ] = useUserSetting(userSetting.helperZoomLevel);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ withValues, setWithValues ] = useBoolean();
    const recoveryMode = useSelector(getRecoveryMode);
    const keywords = getKeywords(routerPath);

    const closeHelp = useCallback(() => {
        dispatch(setHelp());
    }, [ dispatch ]);

    const handleExamples = useCallback(() => {
        setWithValues.swap();
    }, [ setWithValues ]);

    const handleZoomOut = useCallback(() => {
        setZoomLevel(zoomLevel - 1);
    }, [ zoomLevel, setZoomLevel ]);

    const handleZoomIn = useCallback(() => {
        setZoomLevel(zoomLevel + 1);
    }, [ zoomLevel, setZoomLevel ]);
    return (
        <MDBCard className="help__Card">
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol lg="8">
                        {getTranslationFromReactPath(routerPath, t)}

                    </MDBCol>
                    <MDBCol
                        className="database__reloadIcon"
                        lg="4"
                    >
                        <IconWithTooltip
                            className={'icon--secondary'}
                            disabled={zoomLevel === MIN_ZOOM}
                            iconSize="sm"
                            link
                            name="magnify-minus-outline"
                            onClick={handleZoomOut}
                            tooltipText={'help:zoom:out'}
                        />
                        <IconWithTooltip
                            className={'icon--secondary'}
                            disabled={zoomLevel === MAX_ZOOM}
                            iconSize="sm"
                            link
                            name="magnify-plus-outline"
                            onClick={handleZoomIn}
                            tooltipText={'help:zoom:in'}
                        />
                        <IconWithTooltip
                            className={withValues ? 'icon--secondary' : 'icon--primary'}
                            htmlFor="Help-Icon"
                            iconSize="sm"
                            link
                            name="help"
                            onClick={handleExamples}
                            tooltipText={'help:hideEg'}
                        />
                        <IconWithTooltip
                            iconSize="sm"
                            link
                            name="close"
                            onClick={closeHelp}
                            tooltipText={'widgets:global.close'}
                        />
                    </MDBCol>

                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody className="help__CardBody">
                {recoveryMode &&
                    <p className="infobox infobox--primary noticeMe">
                        <Message message="widgets:global.recoveryMode" />
                    </p>
                }
                {keywords.length === 0 ?
                    <NoData><Message message="widgets:global.withoutData" /></NoData> :
                    <>
                        <h3 />
                        <ul>
                            {keywords.map(item => (
                                <KeywordRenderer
                                    item={item}
                                    key={item.key}
                                    routerPath={routerPath}
                                    schema={schema}
                                    swapAll={withValues}
                                    zoom={zoomLevel}
                                />
                            ))}
                        </ul>
                    </>
                }
            </MDBCardBody>
        </MDBCard>
    );
};

export default Help;

const KeywordRenderer = ({ item, schema, routerPath, swapAll, zoom }:
    {item: Keyword, schema: any, routerPath: string, swapAll: boolean, zoom: number | undefined}) => {
    const { t } = useTranslation();
    const [ shouldDisplayExample, setShouldDisplayExample ] = useBoolean(swapAll);
    const translationBasePath = `help:scene${routerPath.replaceAll('/', '.')}.${item.key}`;

    const cwdbV2IsEnabled = useSelector(getCwdbV2Enabled);

    useEffect(() => {
        if (swapAll) {
            setShouldDisplayExample.on();
        } else {
            setShouldDisplayExample.off();
        }
    }, [ swapAll, setShouldDisplayExample ]);

    if (item.header) {
        return (
            <h3>
                {t(`help:headers.${item.header}`)}
            </h3>
        );
    }

    if (item.key === 'politics' && !cwdbV2IsEnabled) {
        return null;
    }

    return (
        <>
            <li
                className={'help__li__zoom__' + zoom}
                id={`help__${item.key}--text`}
                key={item.key}
                onMouseEnter={onMouseEnter(`help__${item.key}`)}
                onMouseLeave={onMouseLeave(`help__${item.key}`)}
            >
                {item.hlcfgPath &&
                <IconWithTooltip
                    className={shouldDisplayExample ?
                        'icon--secondary icon--example' : 'icon--primary icon--example'}
                    datacy={item.key + 'Info'}
                    iconSize="sx"
                    name="information"
                    onClick={setShouldDisplayExample.swap}
                    tooltipText={'help:hideEg'}
                />}
                <strong data-cy={item.key + 'Title'}>
                    {t(`${translationBasePath}.title`)}
                </strong>
                {': '}
                {t(`${translationBasePath}.desc`)}
                {item.icons?.length &&
                    <ul>
                        {item.icons.map((icon, index) => (
                            <li key={index}>
                                {'custom' in icon ? icon.custom :
                                    <Icon
                                        className={icon.className}
                                        name={icon.icon}
                                        size={icon.size || 'sm'}
                                    />}
                                {t(
                                    `${translationBasePath}.desc${index}`
                                )}

                            </li>
                        ))}
                    </ul>
                }
                {shouldDisplayExample && item.hlcfgPath &&
                    <ul data-cy={item.key + 'List'}>
                        <ExampleRenderer
                            item={item}
                            schema={schema}
                        />
                    </ul>}
            </li>
        </>
    );
};

const ExampleRenderer = ({ item, schema }: {item: Keyword, schema: any}) => {
    const { t } = useTranslation();
    if (!item.hlcfgPath) {
        return null;
    }
    const eg = getValueExamplesBySchema(
        findSchemaByObjectPathAndSchema(
            item.hlcfgPath,
            schema
        )
    );
    if (item.descriptiveEnum) {
        return (
            <>
                {
                    eg.value.map(item => (
                        <li
                            className="help__li--primary"
                            key={item}
                        >
                            {t([ `help:validInputs.enum.${item}.title`, `widgets:enum.${item}.title` ])}
                            {': '}
                            <i>
                                {t([ `help:validInputs.enum.${item}.desc`, `widgets:enum.${item}.desc` ])}
                            </i>
                        </li>
                    ))
                }
            </>
        );
    }
    return (
        <>
            <li className="help__li--primary">
                <i>
                    {t('help:validInputs.eg', { eg: eg?.value.join(', ') })}
                </i>
            </li>
            {eg.additionalToDisplay?.map(additional => (
                <li
                    className="help__li--primary"
                    key={additional.translation}
                >
                    <i>
                        {t(additional.translation, { eg: additional.value?.join(', ') })}
                    </i>
                </li>
            ))}
        </>
    );
};


const hlcfgPathGet = createPathGetter<HlcfgInputTree>();

const { tables, network, services, system, protection } = hlcfgPathGet;


type KeywordIcon = {
    className?: string,
    size?: IconSize,
    icon: IconNameType,
} | {
    custom: React.ReactNode
};

type Keyword = {
    header?: string,
    key?: string,
    icons?: KeywordIcon[],
    hlcfgPath?: HlcfgPath,
    descriptiveEnum?: boolean
    noData?: boolean
}

type Keywords = Keyword[]

export const getKeywords = (path: string): Keywords => {
    switch (path) {
    case '/protection/proxy/profiles':
        return profilesNewKeywords;
    case '/monitoring/system/mainDashboard':
        return monitoringDashboardKeywords;
    case '/monitoring/traffic/networkEvents':
        return monitoringTrafficIpsKeywords;
    case '/monitoring/traffic/systemEvents':
        return monitoringTrafficSystemKeywords;
    case '/configuration/network/interfaces':
        return configurationNetworkKeywords;
    case '/configuration/network/resolver':
        return dnsClientKeywords;
    case '/configuration/network/routing':
        return routingKeywords;
    case '/configuration/services/snmp':
        return SNMPKeywords;
    case '/configuration/services/remoteAccess':
        return remoteAccessKeywords;
    case '/configuration/services/feedback':
        return feedbackKeywords;
    case '/configuration/services/dhcpServer':
        return dhcpKeywords;
    case '/configuration/services/ntp':
        return timeKeywords;
    case '/protection/ips/settings':
        return ipsKeywords;
    case '/protection/honeypot':
        return honeypotKeywords;
    case '/protection/proxy/antivirus':
        return antivirusKeywords;
    case '/protection/adaptiveFirewall/settings':
        return adaptiveFirewallKeywords;
    case '/protection/packetFilter':
        return packetFilterKeywords;
    case '/protection/proxy/settings':
        return proxyDefaultKeywords;
    case '/protection/waf/profiles':
        return wafProfilesKeywords;
    case '/protection/dnsProxyProfile':
        return dnsProxyProfilesKeywords;
    case '/system/management/device':
        return systemDeviceKeywords;
    case '/system/management/upgrade':
        return systemManagementUpgradeKeywords;
    case '/system/management/components':
        return componentsKeywords;
    case '/system/authentication':
        return authenticationKeywords;
    case '/system/database':
        return databaseKeywords;
    case '/users/list':
        return usersKeywords;
    case '/profile/settings':
        return userKeywords;
    case '/configuration/services/dns':
        return dnsKeywords;
    case '/configuration/namedObject/netaddr':
        return netaddrKeywords;
    case '/configuration/services/vpnServer':
        return vpnKeywords;
    case '/configuration/services/ipsec':
        return ipsecKeywords;
    case '/configuration/network/QoS':
        return qosKeywords;
    case '/monitoring/traffic/servicescharts':
        return serviceChartsKeywords;
    case '/monitoring/traffic/protectioncharts':
        return protectionChartsKeywords;
    case '/monitoring/traffic/systemcharts':
        return systemChartsKeywords;
    case '/monitoring/traffic/systemlogs':
        return systemlogsKeywords;
    case '/system/adminInterface':
        return adminInterfaceKeywords;
    default:
        return  [];
    }
};

const wafProfilesKeywords: Keywords = [
    { key: 'comment' },
    { key: 'language' },
    { key: 'contact' },
    { key: 'timeout' },
    { header: 'rule' },
    { key: 'action' },
    { key: 'name' },
    { key: 'client', hlcfgPath: tables.wafProfileRule.ID.client.getPath() },
    { key: 'server', hlcfgPath: tables.wafProfileRule.ID.server.getPath() },
    { key: 'sendToServerAddr', hlcfgPath: tables.wafProfileRule.ID.sendToServerAddr.getPath() },
    { key: 'sendToServerPort', hlcfgPath: tables.wafProfileRule.ID.sendToServerPort.getPath() },
];

const systemChartsKeywords: Keywords = [
    { header: 'CPU' },
    { key: 'usage' },
    { key: 'process' },
    { key: 'load' },
    { header: 'RAM' },
    { key: 'usageRam' },
    { key: 'usageSwap' },
    { header: 'Disks' },
    { key: 'usageDisks' },
    { key: 'openFiles' },
    { key: 'readWriteTime' },
    { key: 'readWrite' },
    { key: 'activity' },
];

const systemlogsKeywords: Keywords = [
    { header: 'Filters' },
    { key: 'units' },
    { key: 'message' },
    { key: 'pids' },
    { key: 'boot' },
    { key: 'logLevel', icons: [ { custom: (<></>) }, { custom: (<></>) }, { custom: (<></>) },
        { custom: (<></>) }, { custom: (<></>) }, { custom: (<></>) }, { custom: (<></>) }, { custom: (<></>) } ] },
    { key: 'time' },
    { key: 'tailMode' },
    { key: 'noTime' },
    { key: 'fullMessages' },
    { key: 'create' },
    { key: 'apply' },
];

const protectionChartsKeywords: Keywords = [
    { header: 'SF' },
    { key: 'connections' },
    { key: 'acceptingRules' },
    { key: 'blockingRules' },
    { key: 'allowedTraffic' },
    { key: 'droppedTraffic' },
    { header: 'AF' },
    { key: 'connectionsTo' },
    { key: 'connectionsFrom' },
    { key: 'dangerousAddresses' },
    { key: 'attackedPorts' },
    { key: 'addressesToDangerous' },
    { key: 'portsToDangerous' },
    { header: 'IPS' },
    { key: 'dangerousConnections' },
    { key: 'attackTypes' },
    { key: 'commonProtocols' },
    { header: 'Proxy' },
    { key: 'allowedBlocked' },
    { key: 'traffic' },
    { key: 'destServers' },
    { key: 'sourceIps' },
];

const serviceChartsKeywords: Keywords = [
    { header: 'Interfaces' },
    { key: 'newConnections' },
    { key: 'inOutByte' },
    { key: 'inOutPacket' },
    { key: 'inOutDropedPacket' },
    { key: 'inOutErrorPacket' },
];

const monitoringDashboardKeywords: Keywords = [
    { key: 'version' },
    { key: 'attacks' },
    { key: 'susDevices' },
    { key: 'proxy' },
    { key: 'problemComponents' },
    { key: 'upgrade' },
    { key: 'callhome' },
    { key: 'cluster' },
    { key: 'dbStatus' },
    { key: 'adaptive' },
    { key: 'certificateExpiration' },
    { key: 'revisionList' },
];

const monitoringTrafficIpsKeywords: Keywords = [
    { key: 'overview' },
];

const monitoringTrafficSystemKeywords: Keywords = [
    { key: 'overview' },
    { key: 'cpu' },
    { key: 'memory' },
    { key: 'harddisk' },
];

const configurationNetworkKeywords: Keywords = [
    {
        key: 'state', icons: [
            {
                icon: 'circle',
                className: 'icon--green ml-1 mr-1'
            },
            {
                icon: 'circle',
                className: 'icon--red ml-1 mr-1'
            },
            {
                icon: 'ethernet-cable-off',
                className: 'ml-1 mr-1'
            },
            {
                icon: 'ethernet',
                className: 'icon--green ml-1 mr-1'
            } ]
    },
    {
        key: 'type', icons: [
            {
                icon: 'expansion-card-variant',
                className: 'icon--secondary ml-1 mr-1'
            },
            {
                icon: 'lan',
                className: 'icon--secondary ml-1 mr-1'
            },
            {
                icon: 'merge',
                className: 'icon--secondary ml-1 mr-1'
            },
        ]
    },
    { key: 'name', hlcfgPath: tables.hwIface.ID.name.getPath() },
    { key: 'ip4', hlcfgPath: tables.hwIface.ID.address.getPath() },
    {
        key: 'wanLan', icons: [
            {
                custom: (
                    <TextWithTooltip
                        className="textAsIcon textAsIcon--secondary mx-1"
                        text="widgets:network.wanLan.wan.title"
                        tooltipText="widgets:network.wanLan.wan.desc"
                    />
                ),
            },
            {
                custom: (
                    <TextWithTooltip
                        className="textAsIcon textAsIcon--secondary mx-1"
                        text="widgets:network.wanLan.lan.title"
                        tooltipText="widgets:network.wanLan.lan.desc"
                    />
                ),
            },
            {
                custom: (
                    <TextWithTooltip
                        className="textAsIcon textAsIcon--secondary mx-1"
                        text="widgets:network.wanLan.wanLan.title"
                        tooltipText="widgets:network.wanLan.wanLan.desc"
                    />
                ),
            },
            {
                custom: (
                    <IconWithTooltip
                        className="icon--secondary mx-1"
                        iconSize="sm"
                        link
                        name="circle-outline-off"
                        tooltipText="widgets:network.wanLan.off"
                    />
                ),
            },
        ]
    },
    { key: 'indexTag', hlcfgPath: tables.vlanIface.ID.vlanTag.getPath() },
    { key: 'vlanInterface' },
    { key: 'antispoofing', icons: [ { custom: (<></>) }, { custom: (<></>) }, { custom: (<></>) } ] },
    { key: 'ip6', hlcfgPath: tables.hwIface.ID.address6.getPath() },
    { key: 'mac' },
];

const dnsClientKeywords: Keywords = [
    { key: 'resolver' },
    { key: 'hostname', hlcfgPath: network.hostname[NODE_SHARED].getPath() },
    { key: 'domain', hlcfgPath: network.domain.getPath() },
    {
        key: 'dnsServer', icons: [
            { custom: <b><Message message="widgets:Resolver.type.dhcp" /></b> },
            { custom: <b><Message message="widgets:Resolver.type.localDns.title" /></b> },
            { custom: <b><Message message="widgets:Resolver.type.nameservers" /> </b> },
        ]
    },
    { key: 'dnssec' },
    { header: 'hosts' },
    { key: 'hosts' },
    { key: 'hostsDomains', hlcfgPath: tables.host.ID.domain.getPath()  },
    { key: 'hostsAddress', hlcfgPath: tables.host.ID.address.getPath() },

];

const routingKeywords: Keywords = [
    { key: 'gateway', hlcfgPath: network.gw4.addr.getPath() },
    { key: 'gateway6', hlcfgPath: network.gw6.getPath() },
    { key: 'routingEnabled' },
    { key: 'multihoming' },
    { key: 'preemptive', hlcfgPath: network.multihoming.preemptAfterSeconds.getPath()  },
    { key: 'primary' },
    { key: 'secondary' },
    { key: 'routingEnabled' },
    { key: 'addr',  hlcfgPath: tables.multihomingGateway.ID.addr.getPath() },
    { key: 'servers',  hlcfgPath: tables.multihomingGateway.ID.pingIps.getPath() },
    { key: 'ping', hlcfgPath: tables.multihomingGateway.ID.considerDownAfterSeconds.getPath() },
    { header: 'routingTable' },
    { key: 'routingTable' },
    { key: 'routingTableDestination', hlcfgPath: tables.route.ID.destination.getPath() },
    { key: 'routingTableGateway', hlcfgPath: tables.route.ID.gateway.getPath() },
];

const adminInterfaceKeywords: Keywords = [
    { key: 'guiAddressesHTTPS', hlcfgPath: system.guiAddresses.https.getPath() },
    { key: 'guiAddressesHTTP' },
    { key: 'guiPubkey' },
    { key: 'guiPrivkey' },
];

const SNMPKeywords: Keywords = [
    { key: 'snmp' },
    { key: 'enabled' },
    { key: 'addresses', hlcfgPath: services.snmp.addresses.getPath() },
    { key: 'user' },
    { key: 'authentication' },
    { key: 'encryption' },
];

const remoteAccessKeywords: Keywords = [
    { key: 'remoteAccess' },
    { key: 'sockets' },
    { key: 'enabled' },
    { key: 'userKeys' },
    { key: 'techniciansKernun' },
    { key: 'callhome' },
];

const feedbackKeywords: Keywords = [
    { key: 'feedback' },
    { key: 'ips' },
    { key: 'systemState' },
    { key: 'database' },
    { key: 'errors' },
];

const dhcpKeywords: Keywords = [
    { key: 'server' },
    { key: 'relay' },
    { key: 'leaseTime' },
    { key: 'defaultDomain', hlcfgPath: tables.dhcpServer.ID.defaultDomain.getPath() },
    { key: 'subnetAddr', hlcfgPath: tables.dhcpServer.ID.subnetAddr.getPath() },
    { key: 'gateways', hlcfgPath: tables.dhcpServer.ID.gateways.getPath() },
    { key: 'nameservers', hlcfgPath: tables.dhcpServer.ID.nameservers.getPath() },
    { key: 'routes' },
    { key: 'leases' },
    { header: 'pool' },
    { key: 'rangeFrom', hlcfgPath: tables.dhcpPool.ID.rangeFrom.getPath() },
    { key: 'rangeTo', hlcfgPath: tables.dhcpPool.ID.rangeTo.getPath() },
    { key: 'nodes' },
    { header: 'lease' },
    { key: 'ip', hlcfgPath: tables.dhcpLease.ID.ip.getPath() },
    { key: 'mac' },
];


const vpnKeywords: Keywords = [
    { key: 'ras' },
    { key: 'client' },
    { key: 'serverAddressAddress', hlcfgPath: tables.openvpnRas.ID.serverAddress.addr.getPath() },
    { key: 'serverAddressPort', hlcfgPath: tables.openvpnRas.ID.serverAddress.port.getPath() },
    { key: 'protocol' },
    { key: 'vpnAddress', hlcfgPath: tables.openvpnRas.ID.vpnAddress.getPath() },
    { key: 'routeNopull' },
    { key: 'routingTypeServer' },
    { key: 'clientToClient' },
    { key: 'duplicateCn' },
    { key: 'compressMigrate' },
    { key: 'remoteCertTls' },
    { key: 'googleAuthEnabled' },
    { key: 'renegSec' },
    { key: 'keepalivePing', hlcfgPath: tables.openvpnRas.ID.keepalive.interval.getPath() },
    { key: 'keepalivePingRestart', hlcfgPath: tables.openvpnRas.ID.keepalive.timeout.getPath() },
    { key: 'maxClients', hlcfgPath: tables.openvpnRas.ID.maxClients.getPath() },
    {
        key: 'interfaceTopology', icons: [
            { custom: <b>{VPN_INTERFACE_TOPOLOGY_TUN_SUBNET}</b> },
            { custom: <b>{VPN_INTERFACE_TOPOLOGY_TUN_NET30}</b> },
            { custom: <b>{VPN_INTERFACE_TOPOLOGY_TUN_P2P}</b> },
            { custom: <b>{VPN_INTERFACE_TOPOLOGY_TAP}</b> },
        ]
    },
    {
        key: 'wanLan', icons: [
            {
                custom: (
                    <TextWithTooltip
                        className="textAsIcon textAsIcon--secondary mx-1"
                        text="widgets:network.wanLan.wan.title"
                        tooltipText="widgets:network.wanLan.wan.desc"
                    />
                ),
            },
            {
                custom: (
                    <TextWithTooltip
                        className="textAsIcon textAsIcon--secondary mx-1"
                        text="widgets:network.wanLan.lan.title"
                        tooltipText="widgets:network.wanLan.lan.desc"
                    />
                ),
            },
            {
                custom: (
                    <TextWithTooltip
                        className="textAsIcon textAsIcon--secondary mx-1"
                        text="widgets:network.wanLan.wanLan.title"
                        tooltipText="widgets:network.wanLan.wanLan.desc"
                    />
                ),
            },
            {
                custom: (
                    <IconWithTooltip
                        className="icon--secondary mx-1"
                        iconSize="sm"
                        link
                        name="circle-outline-off"
                        tooltipText="widgets:network.wanLan.off"
                    />
                ),
            },
        ]
    },
    { key: 'crlVerify' },
    { key: 'tryCrlVerify' },
    { key: 'tlsAuth' },
    { key: 'pushToClient' },
    { key: 'defaultGateway' },
    { key: 'nameserversForce' },
    { key: 'routingTypeClient' },
    { key: 'pushToUser' },
    { key: 'user' },
    { key: 's2sUser' },
    { key: 'block' },
    { key: 'ccdExclusive' },
];

const ipsecKeywords: Keywords = [
    { key: 'leftNode', hlcfgPath: tables.ipsec.ID.leftNode.getPath() },
    { key: 'leftSubnets', hlcfgPath: tables.ipsec.ID.leftSubnets.getPath() },
    { key: 'rightNode', hlcfgPath: tables.ipsec.ID.rightNode.getPath() },
    { key: 'rightSubnets', hlcfgPath: tables.ipsec.ID.rightSubnets.getPath() },
    { key: 'presharedKey' },
    {
        key: 'wanLan', icons: [
            {
                custom: (
                    <TextWithTooltip
                        className="textAsIcon textAsIcon--secondary mx-1"
                        text="widgets:network.wanLan.wan.title"
                        tooltipText="widgets:network.wanLan.wan.desc"
                    />
                ),
            },
            {
                custom: (
                    <TextWithTooltip
                        className="textAsIcon textAsIcon--secondary mx-1"
                        text="widgets:network.wanLan.lan.title"
                        tooltipText="widgets:network.wanLan.lan.desc"
                    />
                ),
            },
            {
                custom: (
                    <TextWithTooltip
                        className="textAsIcon textAsIcon--secondary mx-1"
                        text="widgets:network.wanLan.wanLan.title"
                        tooltipText="widgets:network.wanLan.wanLan.desc"
                    />
                ),
            },
            {
                custom: (
                    <IconWithTooltip
                        className="icon--secondary mx-1"
                        iconSize="sm"
                        link
                        name="circle-outline-off"
                        tooltipText="widgets:network.wanLan.off"
                    />
                ),
            },
        ]
    },
    { key: 'keyexchange' },
    { key: 'lifetimeSeconds', hlcfgPath: tables.ipsec.ID.lifetimeSeconds.getPath() },
    { key: 'ikeLifetimeSeconds', hlcfgPath: tables.ipsec.ID.ikeLifetimeSeconds.getPath() },
    { key: 'ikeEncryption', hlcfgPath: tables.ipsec.ID.ikeEncryption.getPath() },
    { key: 'ikeHashing', hlcfgPath: tables.ipsec.ID.ikeHashing.getPath() },
    { key: 'ikeDhGroup', hlcfgPath: tables.ipsec.ID.ikeDhGroup.getPath() },
    { key: 'espEncryption', hlcfgPath: tables.ipsec.ID.espEncryption.getPath() },
    { key: 'espHashing', hlcfgPath: tables.ipsec.ID.espHashing.getPath() },
    { key: 'espDhGroup', hlcfgPath: tables.ipsec.ID.espDhGroup.getPath() },
];

const timeKeywords: Keywords = [
    { key: 'time' },
    { key: 'current' },
    { key: 'timezone' },
    { key: 'ntpClient' },
    { key: 'primaryNtp', hlcfgPath: services.ntpdClient.ntp1.getPath() },
    { key: 'secondaryNtp', hlcfgPath: services.ntpdClient.ntp2.getPath() },

];

const qosKeywords: Keywords = [
    { key: 'qos' },
    { key: 'queues' },
    { key: 'nodes' },
    { key: 'ceilBandwidthMbit', hlcfgPath: tables.tcNode.ID.ceilBandwidthMbit.getPath() },
    { key: 'guaranteedBandwidthMbit', hlcfgPath: tables.tcNode.ID.guaranteedBandwidthMbit.getPath() },

];

const ipsKeywords: Keywords = [
    { key: 'ipsMod' },
    { key: 'interface' },
    { key: 'rulesDatabase' },
    { key: 'blocklist', hlcfgPath: protection.suricata.service.blacklist.getPath() },
    { key: 'allowlist', hlcfgPath: protection.suricata.service.whitelist.getPath() },
];

const dnsProxyProfilesKeywords: Keywords = [
    { key: 'addresses' },
    { key: 'port' },
    { key: 'ignoreAdditional' },

    { header: 'rule' },
    { key: 'action', icons: [
        {
            icon: 'shield-check-outline',
            className: 'icon--green ml-1 mr-1'
        },
        {
            icon: 'web',
            className: 'icon--green ml-1 mr-1'
        },
        {
            icon: 'shield-off-outline',
            className: 'icon--red ml-1 mr-1'
        },
        {
            icon: 'loopback',
            className: 'icon--primary ml-1 mr-1'
        },
        {
            icon: 'account-multiple',
            className: 'icon--primary ml-1 mr-1'
        },
    ] },
    { key: 'name' },
    { key: 'host' },
    { key: 'client' },
    { key: 'recordType' },
    { key: 'actionFakeAddrs' },
    { key: 'actionFakeTtl' },
    { key: 'nameServerList' },
];

const honeypotKeywords: Keywords = [
    { key: 'honeypot' },
    { key: 'addresses', hlcfgPath: protection.honeypot.addresses.getPath() },
    { key: 'ports', hlcfgPath: protection.honeypot.ports.getPath() },
    { key: 'blacklistTime', hlcfgPath: protection.honeypot.blacklistTime.getPath()  },
    { key: 'isInHP' }
];

const antivirusKeywords: Keywords = [
    { key: 'antivirus' },
    {
        key: 'policy', icons: [
            { custom: <b><Message message="widgets:enum.strict.title" /></b> },
            { custom: <b><Message message="widgets:enum.moderate.title" /></b> },
            { custom: <b><Message message="widgets:enum.soft.title" /></b> },
        ]
    },
    { key: 'ignoreError' },

];

const adaptiveFirewallKeywords : Keywords = [
    { key: 'policy' },
    { key: 'interface' },
    { key: 'isInAf' },
    { key: 'searchAddress' },
    { key: 'imInAf' },
    { key: 'ipAddress' },
    { key: 'firstFound' },
    { key: 'lastFound' },
    { key: 'numberOfFound' },
    { key: 'understand' },
    { key: 'rulesDatabase' },
    { key: 'blocklist', hlcfgPath: protection.adaptiveFirewall.blacklist.getPath() },
    { key: 'ignorelist', hlcfgPath: protection.adaptiveFirewall.ignorelist.getPath() },
];


const packetFilterKeywords: Keywords = [
    { key: 'packetFilter' },
    {
        key: 'rule', icons: [
            {
                custom:
                    <Svg
                        className="p-1"
                        height={'32'}
                        key="kernun"
                        src={logo}
                        width="32"
                    />
            }, ]
    },
    { key: 'kernunRules' },
    { key: 'header' },
    { key: 'userRules' },
    { key: 'outRule' },
    { key: 'inRule' },
    { key: 'anyRule' },
    { header: 'rule' },
    {
        key: 'action', icons: [
            {
                icon: 'shield-check-outline',
                className: 'packetFilter__icon--green ml-1 mr-1'
            },
            {
                icon: 'drop',
                className: 'packetFilter__icon--red ml-1 mr-1'
            },
            {
                icon: 'web',
                className: 'packetFilter__icon--primary ml-1 mr-1'
            } ]
    },
    { key: 'name', hlcfgPath: tables.nftRule.ID.name.getPath() },
    { key: 'sourceAddress', hlcfgPath: tables.nftRule.ID.sourceAddress.getPath() },
    { key: 'destinationAddress', hlcfgPath: tables.nftRule.ID.destinationAddress.getPath()  },
    { key: 'service', hlcfgPath: tables.nftRule.ID.service.getPath()  },
    {
        key: 'log', icons: [
            { custom: <span className="profile__noLog">{LOG}</span> },
            { custom: <span className="icon--green mx-1">{LOG}</span> },
            { icon: 'chart-bar', className: 'icon--textColor ml-1 mr-1' },
        ]
    },
    { key: 'interface' },
    { key: 'active' },
    {
        key: 'sourceTranslation', icons: [
            { icon: 'drama-masks', className: 'ml-1 mr-1' },
            { icon: 'masksWithRedirect', className: 'ml-1 mr-1' }
        ]
    },
    {
        key: 'destinationTranslation', icons: [
            { icon: 'call-missed', className: 'ml-1 mr-1' },
            { icon: 'loopback', className: 'ml-1 mr-1' },
            { icon: 'masksWithRedirect', className: 'ml-1 mr-1' }
        ]
    },
    { key: 'destinationTranslationPort', hlcfgPath: tables.nftRule.ID.destinationTranslation.port.getPath()  },
    { key: 'qosNode' },
    { key: 'comment' },
    {
        key: 'edit', icons: [
            { icon: 'wrench-outline', className: 'ml-1 mr-1' },
        ]
    },
    { header: 'ruleActions' },
    { key: 'menu' },
    { key: 'addHeader' },
    { key: 'duplicate' },
    { key: 'copy' },
    { key: 'paste' },
    { key: 'add' },
    { key: 'delete' },
    { header: 'packetFilterActions' },
    { key: 'factoryReset' },
    { key: 'smallerRow' },

];

const proxyDefaultKeywords: Keywords = [
    { key: 'proxyDefault', },
    { key: 'tlsInspection' },
    { key: 'log', hlcfgPath: protection.proxy.log.operation.level.getPath(), descriptiveEnum: true },
    { key: 'caCertFile' },
    { key: 'caPrivKeyFile' },
    { key: 'errorPage', },
    { key: 'defaultLanguage', },
    { key: 'email', },
    { key: 'contact', },
    { key: 'clearWeb', },
    { key: 'isInCategories' },
    { key: 'reload', },
    { key: 'wpad', hlcfgPath: protection.proxy.wpad.addresses.getPath() },
    { key: 'wpadServerExceptions', hlcfgPath: protection.proxy.wpad.exceptions.server.getPath() },
    { key: 'wpadClientExceptions', hlcfgPath: protection.proxy.wpad.exceptions.client.getPath() },

];


const systemDeviceKeywords: Keywords = [
    {
        key: 'device', icons: [
            { icon: 'reload', className: 'ml-1 mr-1 device__rebootIcon' },
            { icon: 'power', className: 'ml-1 mr-1' },
        ]
    },
    {
        key: 'cluster', icons: [
            { icon: 'reload', className: 'ml-1 mr-1' },
            { icon: 'server-remove', className: 'ml-1 mr-1' },
            { icon: 'chevron-right-box-outline', className: 'ml-1 mr-1' },
            { icon: 'settings-outline', className: 'ml-1 mr-1' },
            { icon: 'shape-square-plus', className: 'ml-1 mr-1' },

        ]
    },
    { key: 'databaseReplication' },
    { key: 'backup' },
    { key: 'backupPassword' },


];

const systemManagementUpgradeKeywords: Keywords = [
    { key: 'deployment' },
    { key: 'version' },
    { key: 'released' },
    { key: 'installed' },
    { key: 'lastTimeInUse' },
    { key: 'lastTimeActivated' },
    { key: 'automaticDownloads' },
    { key: 'experimental' },
    { key: 'withConfirming' },
    { key: 'waitTime' },
    { key: 'downloadSource' },
    { key: 'userNotice' }
];

const componentsKeywords: Keywords = [
    {
        key: 'components', icons: [
            { icon: 'checkbox-blank-circle', className: 'ml-2 mr-2 icon--green', size: 'sx' },
            { icon: 'cancel', className: 'ml-2 mr-2 icon--red', size: 'sx' },
            { icon: 'play', className: 'ml-1 mr-1' },
            { icon: 'pause', className: 'ml-1 mr-1' },
            { icon: 'repeat', className: 'ml-1 mr-1' },
            { icon: 'restore', className: 'ml-1 mr-1' },
        ]
    }
];

const authenticationKeywords: Keywords = [
    {
        key: 'authentication', icons: [
            { icon: 'play', className: 'ml-1 mr-1 ', },
            { icon: 'swap-vertical', className: 'ml-1 mr-1 ', },
        ]
    },
    { key: 'domain', hlcfgPath: services.authentication.realm.getPath() },
    { key: 'domainControllers', hlcfgPath: services.authentication.domainControllers.getPath() },
    { key: 'alternativeFqdn', hlcfgPath: protection.proxy.alternativeFqdn.getPath() }
];

const databaseKeywords: Keywords = [
    {
        key: 'database', icons: [
            { icon: 'reload', className: 'ml-1 mr-1 ', },
        ]
    },
    { key: 'size' },
    { key: 'oldest' },
    { key: 'newest' },
    { key: 'delete' },
    { key: 'reinitialization' },
];

const usersKeywords: Keywords = [
    { key: 'list' },
    { key: 'userSettings' },
    { key: 'userActivity' },
];

const userKeywords: Keywords = [
    { key: 'password' },
    { key: 'language' },
];

const dnsKeywords: Keywords = [
    {
        key: 'dnsServer', icons: [
            { custom: <b><Message message="widgets:Dns.type.dhcp" /></b> },
            { custom: <b><Message message="widgets:Dns.type.recursive" /></b> },
            { custom: <b><Message message="widgets:Dns.type.nameservers" /> </b> },
        ]
    },
    { key: 'dnsCatch' },
    { key: 'safeSearch' },
    { key: 'dnsCache' },
];

const netaddrKeywords: Keywords = [
    { header: 'scalarHeader' },
    { key: 'scalar' },
    { key: 'scalarName', hlcfgPath: tables.netaddrScalar.ID.name.getPath() },
    { key: 'scalarValue', hlcfgPath: tables.netaddrScalar.ID.value.getPath() },
    { header: 'vectorHeader' },
    { key: 'vector' },
    { key: 'vectorName', hlcfgPath: tables.netaddrVector.ID.name.getPath()  },
    { key: 'vectorValue', hlcfgPath: tables.netaddrVector.ID.value.getPath()  },
];

const profilesNewKeywords: Keywords = [
    { key: 'profile' },
    { key: 'name' },
    { key: 'sourceAddress', hlcfgPath: tables.profile.ID.parameters.sourceAddress.getPath() },
    { key: 'comment' },
    { key: 'authentication' },
    { key: 'tls' },
    { key: 'disableNiceBlocking' },
    { key: 'timeouts', hlcfgPath: tables.profile.ID.parameters.timeout.both.getPath()  },
    { key: 'outgoingConnection' },
    { key: 'both' },
    { key: 'header' },
    { key: 'rule' },
    { header: 'rule' },
    {
        key: 'action', icons: [
            {
                icon: 'shield-check-outline',
                className: 'icon--green ml-1 mr-1'
            },
            {
                icon: 'block',
                className: 'icon--red ml-1 mr-1'
            },
            {
                icon: 'alert-circle',
                className: 'icon--primary ml-1 mr-1'
            },
            {
                custom: (
                    <>
                        <Icon
                            className="icon--green"
                            name="checkbox-marked-circle"
                            size="sx"
                        />
                        <Icon
                            className="icon--primary"
                            name="alert-circle"
                            size="sx"
                        />
                        <Icon
                            className="icon--red"
                            name="close-circle"
                            size="sx"
                        />
                    </>)
            },
            /*  { icon: 'drama-masks',
                className: 'packetFilter__icon--red ml-1 mr-1' },
            { icon: 'server-network',
                className: 'packetFilter__icon--red ml-1 mr-1' },
            { icon: 'download',
                className: 'packetFilter__icon--red ml-1 mr-1' },*/
        ]
    },
    { key: 'ruleName' },
    { key: 'user' },
    { key: 'group' },
    { key: 'client', hlcfgPath: tables.profileRule.ID.client.getPath() },
    { key: 'server', hlcfgPath: tables.profileRule.ID.server.getPath()  },
    { key: 'intervals', hlcfgPath: tables.profileRule.ID.timeConstraints.getPath() },
    { key: 'weekdays' },
    { key: 'politics', icons: [
        {
            icon: 'speedometerSlow',
            className: 'icon--green ml-1 mr-1',
        },
        {
            icon: 'speedometerMedium',
            className: 'icon--yellow ml-1 mr-1',
        },
        {
            icon: 'speedometer',
            className: 'icon--red ml-1 mr-1',
        },
        {
            icon: 'shield-account',
            className: 'icon--secondary ml-1 mr-1'
        },
    ] },
    { key: 'category' },
    { key: 'content' },
    {
        key: 'log', icons: [
            { custom: <span className="profile__noLog mx-1">{LOG}</span> },
            { custom: <span className="icon--green mx-1">{LOG}</span> },
            { icon: 'chart-bar', className: 'icon--textColor ml-1 mr-1' },
        ]
    },
    { key: 'actionParam' },

];
