/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';


export const versionDescriptionMap = poDef.statusBox('versionDescription');

export const upgradeVersionCardMap = poDef.container('upgradeVersionCard', {
    versionDescription: versionDescriptionMap,
    infoDisplay: poDef.statusBox('infoDisplay'),
    versionSelect: poDef.select('selectVersion'),
    upgradeCardButton: poDef.clickableStatusBox('upgradeCardButton'),
});

const upgradeStateMap = poDef.container('upgradeState', {
    card: upgradeVersionCardMap,
    checkNewVersion: poDef.clickableStatusBox('checkNewVersion')
});

export const upgradeSceneMap = poDef.container('upgradeScene', { upgradeState: upgradeStateMap });
