/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';

import { Scene } from '~frontendComponents/Scene/index.js';
import { ipsecStatusRequest } from '~frontendRoot/ducks/ipsec/index.ts';

import Ipsec from './components/Ipsec/index.js';


const IpsecScene = () => {
    const dispetch = useDispatch();

    useEffect(() => {
        dispetch(ipsecStatusRequest());
    }, [ dispetch ]);

    return (
        <Scene>
            <Ipsec />
        </Scene>
    );
};

export default IpsecScene;
