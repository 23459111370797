/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import getValue from 'get-value';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import { all, put, select, takeEvery } from '~commonLib/reduxSagaEffects.ts';
import { isNamedObject, namedObjectToString } from '~sharedLib/namedObjectUtils.ts';
import {
    DHCP_SERVER_TYPE,
    EMPTY_IMMUTABLE_ARR,
    EMPTY_IMMUTABLE_OBJ,
    REDIRECT_DNS_RULE_UUID, NODE_A_ID, VPN_INTERFACE_TOPOLOGY_TUN_SUBNET,
    HLCFG_OFF
} from '~sharedConstants/index.ts';
import { NODE_SHARED } from '~commonLib/constants.ts';
import {
    GLCFG_SETTER_PREFIX,
    NEW_ROW_CONSTANT,
    HW_IFACE_TYPE,
    VLAN_IFACE_TYPE,
    HW_TYPE,
    VLAN_TYPE,
    VPN_IFACE_TYPE
} from '~frontendConstants/constants.ts';
import { findComponentsErrorsWarnings } from '~frontendLib/findComponentsErrorsWarnings.js';
import { createSelectorArrayOfObjectsShallow, createSelectorArrayOfStrings } from '~frontendLib/reduxUtils.ts';
import { getDefaultNameserversForDhcpServer } from '~sharedLib/getDefaultNameserversForDhcpServer.ts';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { netport } from '~sharedLib/Netport/Netport.ts';
import { stringify } from '~commonLib/arrayUtils.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { getDhcpdData } from '~frontendDucks/dhcpd/index.js';
import { noUndefinedProps } from '~commonLib/objectUtils.ts';
import { getActiveCard } from '~frontendDucks/activeCards/index.js';
import { ADAPTIVE_FIREWALL, ANTIVIRUS, CLUSTER_SHARED, DASHBOARDS, DHCPD, DNS, DOMAIN, HONEYPOT, MULTIHOMING,
    ROUTING_TABLE,
    GW6,
    GW4,
    HOSTS,
    INTERFACES,
    VPN,
    QOS,
    RESOLVER,
    PROFILES,
    SURICATA_VARIABLES,
    PROXY,
    QOS_NODE_TABLE_TYPE,
    TABLES,
    IPSEC,
    ADDRESSES_TABLE,
    WAF_PROFILES,
    ROUTE,
    GUI_ADDRESSES_HTTPS,
    PACKET_FILTER_RULES,
    DNS_PROXY_PROFILES, } from '~frontendRoot/constants/index.js';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import { vpnSvcName } from '~sharedLib/uncategorizedUtils.ts';
import { hlcfgRowObjectIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import { isAddressesSelector } from '~sharedLib/addressesSelectorUtils.ts';
import { getTheLowestPossibleNumber } from '~frontendLib/stringUtils.js';
import { DEFAULT_ANTIVIRUS_IGNORE_ERROR, DEFAULT_ANTIVIRUS_POLICY } from '~libProxyScript/constants.ts';

import { getGetGlcfgSetterActionType } from '../../lib/glcfgUtils.js';
import { getDefaultNormalizedTableGetters, getNormalizedTableGetters, getVerificationErrors } from './glcfgGettersAndSettersUtils.ts';
import { getStatus } from '../ipsec/index.ts';
import { getNamedObjectNetaddrAllValues } from './namedObjectsGettersAndSetters.ts';
import { getState, getGlcfgNodeValue, getGlcfgValue } from './baseGlcfgGettersAndSetters.js';


export { getState, getGlcfgNodeValue, getIsCluster, getGlcfgValue } from './baseGlcfgGettersAndSetters.js';
//data accesors

export const getInitGlcfgTree = rootState => getState(rootState).initGlcfgTree;

export const getGlcfgTree = rootState => getState(rootState).glcfgTree;

const getInitGlcfgNodeValue = (rootState, key) => getValue(getInitGlcfgTree(rootState), key);

const getSchemaRoot = rootState => getState(rootState).glcfgSchema;

export const doesntExists = (object) => !object || object === EMPTY_IMMUTABLE_OBJ;

// configuration getters and setters
const getGlcfgNodeSchema = (state, key) => {
    const schemaRoot = getSchemaRoot(state);
    return schemaRoot[key];
};

const getGlcfgSetterActionType = getGetGlcfgSetterActionType(GLCFG_SETTER_PREFIX);

export const getComponentEnabled = createSelector(
    (state, component) => getGlcfgValue(state, component),
    (component) => {
        if (component) {
            return !component.__off;
        }
        return false;
    }
);

export const getComponentEnabledHook = (component, key) => createSelector(
    (state) => {
        const componentState = getGlcfgValue(state, component);
        if (key) {
            return componentState[key];
        }
        return componentState;
    },
    (component) => {
        if (component) {
            return !component.__off;
        }
        return false;
    }
);

export const getComponentEnabledGetter = (component, key) =>
    (state) => {
        const componentState = getGlcfgValue(state, component);
        if (key) {
            if (componentState[key]) {
                return !componentState[key].__off;
            }
            return false;
        }
        if (componentState) {
            return !component.__off;
        }
        return false;
    };

// ***** NTPD *****
export const getNtpdClientEnabled = rootState => getGlcfgNodeValue(rootState, 'ntpdClientEnabled');

// ***********************************************************

// ***** SSHD *****
export const getSshKeys = rootState => getGlcfgNodeValue(rootState, 'sshKeys');
export const getSchemaSshKeys = rootState => getGlcfgNodeSchema(rootState, 'sshKeys');
export const setSshKeys = payload => ({ type: getGlcfgSetterActionType('sshKeys'), payload });

export const getGuiAddressesSchema = createSelector(
    state => getGlcfgSchema(state, 'guiAddressesHTTPS'),
    schemas => schemas
);

// ***********************************************************

// ***** PROXY *****


export const getProxyEnabled = getComponentEnabledHook(PROXY)
;

export const getWhereProfileIsUsed = () => createSelectorArrayOfObjectsShallow(
    (state) =>  {
        const items = getPacketFilterItems(state);
        const activeProfile = getActiveCard(state, PROFILES);
        return items.filter(item => item.webProfile && item.webProfile === activeProfile && !item.__off).map(
            item => ({ name: item.name, id: item.id })
        );},
    (rules) => rules.map(item => ({ name: item.name, id: item.id }))
);

export const setGlcfgProxyValue = (value, key, subkey, action) =>
    ({ type: getGlcfgSetterActionType(PROXY), value, key, subkey, action });
export const setGlcfgProxyFiles = ({ value, key }) =>
    ({ type: getGlcfgSetterActionType(PROXY), value, key, action: 'setTlsClienFiles' });

export const getProxyTlsClientGenerateCertificate = (rootState) => {
    return getGlcfgValue(rootState, PROXY).tls.client.generateCertificate;
};

export const getProxyTlsClientGenerateCertificateSchema = (rootState) => {
    return getGlcfgSchema(rootState, PROXY).properties.tls.properties.client.properties.generateCertificate.properties;
};

export const getProxyAntivirus = (rootState) => {
    return getGlcfgValue(rootState, ANTIVIRUS);
};

export const getAntivirusPolicy = createSelector(
    getProxyAntivirus,
    (antivirus) => {
        if (antivirus) {
            return antivirus.policy || DEFAULT_ANTIVIRUS_POLICY;
        }
        return DEFAULT_ANTIVIRUS_POLICY;
    }
);

export const getAntivirusIgnoreError = createSelector(
    getProxyAntivirus,
    (antivirus) => {
        if (antivirus) {
            return antivirus.ignoreError || DEFAULT_ANTIVIRUS_IGNORE_ERROR;
        }
        return DEFAULT_ANTIVIRUS_IGNORE_ERROR;
    }
);

export const getAntivirusPolicySchema = (rootState) => {
    return getGlcfgSchema(rootState, ANTIVIRUS).properties.policy;
};


export const getProxyWpadExceptions = rootState => {
    return getGlcfgValue(rootState, 'proxy').wpad.exceptions;
};

export const getProxyWpadOff = rootState => {
    return getGlcfgValue(rootState, 'proxy').wpad.__off;
};

//TODO: These needs to get better, some recursive function that will return it,
//TODO: just dont have the time to do this right now.
export const getProxyWpadExceptionsSchema = (rootState, type) => {
    return getGlcfgSchema(rootState, 'proxy').properties.wpad.properties.exceptions.properties[type];
};

export const getProxyWpadAddresses = rootState => {
    return getGlcfgValue(rootState, 'proxy')?.wpad.addresses;
};

export const getProxyWpadAddressesSchema = createSelector(
    state => getGlcfgSchema(state, 'proxy').properties.wpad.properties.addresses,
    schemas => schemas
);

export const getProxyWpad = createSelector(
    [ getProxyWpadAddresses, getProxyWpadAddressesSchema ],
    (addresses, schema) => {
        return { addresses, schema };
    }
);


// ***********************************************************


export const getGuiHttps = createSelector(
    [
        (state) => getGlcfgValue(state, GUI_ADDRESSES_HTTPS),
        (state) => getGlcfgSchema(state, GUI_ADDRESSES_HTTPS)
    ],
    (addresses, schema) => {
        return { addresses, schema };
    }
);


export const getHoney = createSelector(
    [
        (state) => getGlcfgValue(state, HONEYPOT),
        (state) => getGlcfgSchema(state, HONEYPOT)
    ],
    (honeypot, schema) => {
        return { addresses: honeypot?.addresses, schema: schema.properties.addresses };
    }
);


// ****** SURICATA ********
export const getSuricataVariables = rootState => getGlcfgValue(rootState, SURICATA_VARIABLES);
export const getSuricataVariablesSchema = rootState => getGlcfgSchema(rootState, SURICATA_VARIABLES);

export const getSuricataInitHomeNetVariable = rootState =>
    getInitGlcfgValue(rootState, SURICATA_VARIABLES).addressGroups.homeNet || [];

export const getSuricataAddressVariables = rootState => getSuricataVariables(rootState).addressGroups;
export const getSuricataAddressVariablesSchema = rootState =>
    getSuricataVariablesSchema(rootState)?.properties?.addressGroups;

export const getSuricataPortsVariablesSchema = rootState =>
    getSuricataVariablesSchema(rootState)?.properties?.portGroups;

export const getSuricataPortVariables = rootState => getSuricataVariables(rootState).portGroups;
export const setSuricataVariable = (glcfgKey, port, name, value) =>
    ({ type: getGlcfgSetterActionType(glcfgKey), port, name, value });

// Generic getter and setter for glcfg
export const getGlcfgValueHook = glcfgKey => (rootState) => getGlcfgNodeValue(rootState, glcfgKey);
export const useGlcfgValue = glcfgKey => useSelector(getGlcfgValueHook(glcfgKey));


export const getInitGlcfgValue = (rootState, glcfgKey) => getInitGlcfgNodeValue(rootState, glcfgKey);
export const getInitGlcfgValueHook = (glcfgKey) => (rootState) => getInitGlcfgNodeValue(rootState, glcfgKey);

export const getGlcfgSchema = (rootState, glcfgKey) => getGlcfgNodeSchema(rootState, glcfgKey);
export const getGlcfgSchemaHook = glcfgKey => (rootState) => getGlcfgNodeSchema(rootState, glcfgKey);
export const useGlcfgSchema = glcfgKey => useSelector(getGlcfgSchemaHook(glcfgKey));

export const setGlcfgValueScalar = (glcfgKey, value) =>
    ({ type: getGlcfgSetterActionType(glcfgKey), payload: value });

export const setGlcfgObjectValue = (glcfgKey, value, key, subkey, subsubkey) =>
    ({ type: getGlcfgSetterActionType(glcfgKey), value: value, key: key,
        subkey: subkey, subsubkey, action: 'objectChange' });

// ***********************************************************

// ***********************  POLICY  ***********************

export const getUpgradeNotice = rootState => getGlcfgValue(rootState, 'upgrade').upgradeNotice;

export const getProfileRuleUuid = () => {
    return createSelector([
        getTableItem,
    ], (rule) => {
        if (!rule) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        if (rule.type !== 'rule') {
            return rule;
        }
        return {
            ...rule,
            server: stringifyAddress(rule.server),
            client: stringifyAddress(rule.client)
        };
    });
};


export const {
    getById: getProfilesById,
    getIds: getProfilesIds,
    getItems: getProfilesItems
} = getNormalizedTableGetters(PROFILES);


export const {
    getById: getWafProfilesById,
    getIds: getWafProfilesIds,
    getItems: getWafProfilesItems
} = getNormalizedTableGetters(WAF_PROFILES);

export const {
    getIds: getDnsProxyProfilesIds,
    getItems: getDnsProxyProfileItems,
    getByIdType: getDnsProxyProfileProps,
} = getNormalizedTableGetters(DNS_PROXY_PROFILES);

const {
    getItems: getPacketFilterItems
} = getNormalizedTableGetters(PACKET_FILTER_RULES);

const findProfileWithAuth = profiles => profiles.filter(item =>
    item.parameters?.authentication && !item.parameters?.authentication?.__off);
export const getProfilesWithAuthNames = createSelectorArrayOfObjectsShallow(
    state => findProfileWithAuth(getProfilesItems(state)).map(item => item.name),
    names => names
);

export const getProfilesWithAuthIds = createSelectorArrayOfObjectsShallow(
    state => findProfileWithAuth(getProfilesItems(state)).map(item => item.id),
    names => names
);

export const getProfileUuid = () => {
    return createSelector([
        (state) => getTableItem(state, getActiveCard(state, PROFILES)),
    ], (profile) => {
        if (!profile) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        return {
            ...profile,
        };
    });
};

export const getProfileUuidRules = createSelectorArrayOfStrings([
    (state) => getTableItem(state, getActiveCard(state, PROFILES))?.rules,
], (profile) => {
    if (!profile) {
        return EMPTY_IMMUTABLE_ARR;
    }
    return profile;
});

export const isProfileAuthenticationEnabled = createSelector(
    [ (state) => getTableItem(state, getActiveCard(state, PROFILES)) ],
    (profile) => {
        if (!profile) {
            return false;
        }
        const auth = profile.parameters?.authentication;
        return auth && !auth.__off;
    }
);

export const isTlsInspectionEnabled = createSelector(
    [ (state) => getTableItem(state, getActiveCard(state, PROFILES)) ],
    (profile) => {
        if (!profile) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        const tlsInsp = profile.parameters?.tlsInsp;
        return tlsInsp && !tlsInsp.__off;
    }
);

export const getProfileProps = createSelectorArrayOfObjectsShallow([
    (state) => {
        const items = getProfilesItems(state);
        const packetFilterRules = getPacketFilterItems(state);
        const usage = packetFilterRules.filter(item => item.webProfile);
        const addressTables = getTableById(state, ADDRESSES_TABLE);
        return items.map(profile => ({
            name: profile.name,
            __off: profile.__off,
            color: profile.color,
            id: profile.id,
            usage: usage.filter(item => item.webProfile === profile.id && !item.__off)?.length +
             (profile?.parameters?.addressesTable?.filter(item => !addressTables[item]?.__off)?.length || 0),
            deleteServiceDisabled: items.length === 1
        }));
    },
], (array) => {
    const values = {};
    array.forEach(item => values[item.id] = item);
    return values;
});


export const getWafProfileProps = createSelectorArrayOfObjectsShallow([
    (state) => {
        const items = getWafProfilesItems(state);
        const addressTables = getTableById(state, ADDRESSES_TABLE);
        return items.map(profile => ({
            name: profile.name,
            __off: profile.__off,
            color: profile.color,
            id: profile.id,
            usage: profile?.addressesTable?.filter(item => !addressTables[item]?.__off)?.length || 0,
        }));
    },
], (array) => {
    const values = {};
    array.forEach(item => values[item.id] = item);
    return values;
});

export const getProfileRulesIds = (state) => {
    return getProfileUuid()(state)?.rules;
};


const getNewProfileAddressIds = (state) => {
    return getProfileUuid()(state)?.parameters?.addressesTable;
};

export const getProfileAddressTableIds  = () => {
    return createSelector(
        getNewProfileAddressIds,
        (ids) =>  ids
    );
};


export const getWafProfileUuid = (state) => {
    const item = getTableItem(state, getActiveCard(state, WAF_PROFILES));
    if (!item) {
        return EMPTY_IMMUTABLE_OBJ;
    }
    return {
        ...item,
    };
};

const getWafProfileAddressIds = (state) => {
    return getWafProfileUuid(state)?.addressesTable;
};


export const getWafProfileRulesIds = createSelectorArrayOfObjectsShallow(
    (state) =>  getTableItem(state, getActiveCard(state, WAF_PROFILES))?.rules || EMPTY_IMMUTABLE_ARR,
    (ids) => ids
);

export const getWafProfileAddressTableIds  = () => {
    return createSelector(
        getWafProfileAddressIds,
        (ids) =>  ids
    );
};


export const getProfileAddressTable = (state, uuid) => {
    const item = getTableItem(state, uuid);
    if (doesntExists(item)) {
        return EMPTY_IMMUTABLE_OBJ;
    }

    return {
        ...item,
        ports: stringifyAddress(item.ports, true, netport),
        addresses: stringifyAddress(item.addresses)
    };
};

export const CLOSE_HEADER_PROFILE = 'ak/hlcfgEditor/CLOSE_HEADER_PROFILE';

export const closeHeaderProfile = (payload) =>
    ({ type: CLOSE_HEADER_PROFILE, payload });

export const setNormalizeProfileRule = (props) =>
    ({ ...props, type: getGlcfgSetterActionType(TABLES), action: 'setParameters' });

export const setProxyActionCategories = (props) =>
    ({ ...props, type: getGlcfgSetterActionType(TABLES), value: noUndefinedProps(props.value),
        key: 'action', subkey: 'accordingToCwCategoryV2', action: 'setParameters' });

export const setProxyCategories = (props) =>
    ({ ...props, type: getGlcfgSetterActionType(TABLES), value: noUndefinedProps(props.value),
        key: 'cwCategoriesV2', action: 'setParameters' });

export const workerSetCloseProfileHeader = function* (props) {
    const { uuid, value, type } = props.payload;

    const ids = yield select(state => getTableItem(state, getActiveCard(state, type)).rules);
    const items = yield select(getTableItems, ids);
    const headerPrefix = {
        [WAF_PROFILES]: 'profileHeader',
        [PROFILES]: 'profileHeader',
        [DNS_PROXY_PROFILES]: 'dnsProxyHeader',
    };
    let startClosing = false;

    yield all(ids.map((id, index) => {
        if (startClosing && (
            id.startsWith(headerPrefix[type]) || id.startsWith('profileSpecialItem') || items[index].fake)) {
            startClosing = false;
            return;
        }
        if (id === uuid) {
            startClosing = true;
        }

        if (startClosing) {
            return put(setNormalizeProfileRule({
                uuid: id,
                key: 'closed',
                value,
            }));
        }
    }));
};

// ***********************************************************

// ******* ADAPTIVE FIREWALL *******

export const getAdaptiveFirewallPolicySchema = (state) => {
    return getGlcfgSchema(state, ADAPTIVE_FIREWALL).properties.policy;
};

export const getAdaptiveFirewallInterfacesSchema = (state) => {
    return getGlcfgSchema(state, ADAPTIVE_FIREWALL).properties.interfaces;
};

export const getAdaptiveFirewallListsSchema = (state) => {
    return getGlcfgSchema(state, ADAPTIVE_FIREWALL).properties.blacklist;
};

export const getAdaptiveFirewallLists = (state, list) => {
    return stringifyAddress(getGlcfgValue(state, ADAPTIVE_FIREWALL)[list]);
};

export const getAdaptiveFirewallListsHook = (list) => (state) => {
    return stringifyAddress(getGlcfgValue(state, ADAPTIVE_FIREWALL)[list]);
};

export const getAdaptiveFirewallListsHookInit = (list) => state => {
    return stringifyAddress(getInitGlcfgValue(state, ADAPTIVE_FIREWALL)[list]);
};

export const getAdaptiveFirewallListsComment = (state, listComment) => {
    return getGlcfgValue(state, ADAPTIVE_FIREWALL)[listComment];
};


// ****************************************************************


export const getComponentVerificationErrors = (rootState, component) =>
    findComponentsErrorsWarnings({
        allErrorsOrWarnings: getVerificationErrors(rootState),
        componentGlcfgPath: component,
    });


// *********************** DEFAULT GETTERS ****************

export const getInitGlcfgTables = (state) => getInitGlcfgValue(state, TABLES);
export const getGlcfgTables = (state) => getGlcfgValue(state, TABLES);


export const {
    getItem: getTableItem,
    getItems: getTableItems,
    getByIdType: getTableById,
    getByIdTypes: getTableByIdAllTypes,
    getSchema: getTableSchema,
    getSchemaGetter: getTableSchemaGetter,
    getLengthByType: getTableLengthByType,
    getLowestPossibleIndex: getTableLowestPossibleIndex,
    getItemSiblings: getTableItemSiblings,
    getHlcfgPath: getHlcfgPath
} = getDefaultNormalizedTableGetters();

export const {
    getItem: getTableItemInit,
} = getDefaultNormalizedTableGetters(getInitGlcfgValue);

export const {
    getIds: getHostsIds,
    getFromParentChildTypes: getHostsTypes,
} = getNormalizedTableGetters(HOSTS);

export const {
    getIds: getRoutingTable,
    getFromParentChildTypes: getRoutingTableChildTypes,
} = getNormalizedTableGetters(ROUTING_TABLE);

export const {
    getIds: getVpnServersIds,
    getItems: getVpnItems,
    getFromParentChildTypes: getVpnTypes,
} = getNormalizedTableGetters(VPN);

export const {
    getIds: getIpsecIds,
    getItems: getIpsecItems,
} = getNormalizedTableGetters(IPSEC);

export const {
    getItems: getVpnItemsInit,
} = getNormalizedTableGetters(VPN, false, getInitGlcfgValue);

export const {
    getIds: getInterfaceIds,
    getByIdType: getInterfaceById,
    getItems: getInterfaceItems,
    getFromParentChildTypes: getInterfacesTypes,
} = getNormalizedTableGetters(INTERFACES);

export const {
    getIds: getInterfaceIdsInit,
    getItems: getInterfaceItemsInit,
    getSchemaChild: getInterfaceSchemaInit,
    getFromParentChildTypes: getInterfacesTypesInit,
} = getNormalizedTableGetters(INTERFACES, false, getInitGlcfgValue);

export const {
    getIds: getQoSIds,
    getByIdType: getQoSByIdType,
    getItems: getQoSItems,
    getFromParentChildTypes: getQoSTypes,
} = getNormalizedTableGetters(QOS);

export const {
    getIds: getDhcpdIds,
    getItems: getDhcpdItems,
    getFromParentChildTypes: getDhcpdTypes,
} = getNormalizedTableGetters(DHCPD);


export const getRoutesSchema = () => createSelector([
    (state) => getGlcfgSchema(state, TABLES)?.properties[ROUTE]?.additionalProperties?.properties
], (schema) => {
    if (!schema) {
        return EMPTY_IMMUTABLE_OBJ;
    }
    return schema;
});


// *********************** HOSTS ******************************


export const getHostUuid = () => {
    return createSelector([
        getTableItem,
    ], (host) => {
        if (!host) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        return {
            ...host,
            domain: stringifyAddress(host.domain),
            address: stringifyAddress(host.address),
        };
    });
};

// ****************************** NETWORK ************************************

export const getInterfacesTypesCreatable = createSelector(getInterfacesTypes,
    types => types.filter(item => item !== HW_IFACE_TYPE));


export const getAllHwAndVlanInterfaces = createSelector([
    getInterfaceItems,
],
(interfaces) => (interfaces || []).map(item => {
    if (item.type === HW_TYPE || item.type === VLAN_TYPE) {
        return item;
    }
    return undefined;
}).filter(Boolean));


export const getInterfacesFromConfiguration = createSelector([
    getInterfaceItems,
    getVpnItems,
],
(interfaces, vpn) => (interfaces || []).filter(item => !item.__off)
    .concat((vpn || []).filter(item => !item.__off).map(item => ({ ...item, type: VPN_IFACE_TYPE }))));

export const getNetaddrSelectInterfaces = createSelector([ getInterfaceItems, getVpnItems ], (interfaces, vpns) => {
    const vpnsUnified =  vpns.filter(it => it.id.startsWith('openvpnRas')).map((it) => {
        return {
            id: it.id,
            name: it.name,
            color: it.color,
            __off: it.__off,
            isExternal: it.isExternal,
            isInternal: it.isInternal,
            dhcp: false,
            address: { shared: [ netaddr(it.vpnAddress).asIp4().incrementIp() ] }
        };
    });
    return [ ...interfaces, ...vpnsUnified ].filter(it => it.__off !== true);
});

export const getEnabledNetaddrSelectInterfaceNamesById = createSelectorArrayOfObjectsShallow(
    [ getNetaddrSelectInterfaces ], result => Object.fromEntries(
        result.map(iface => [ iface.id, iface.name ])
    )
);

export const getInterfacesFromConfigurationInit = createSelector(
    (state) => getInterfaceItemsInit(state),
    (items) => items.filter(item => !item.__off)
);

export const getVpnFromConfigurationInit = createSelector(
    (state) => getVpnItemsInit(state),
    (items) => items.filter(item => !item.__off)
);

export const getOffInterfacesCount = createSelectorArrayOfObjectsShallow([
    (state) => {
        const items = getInterfaceItems(state);
        return items.map(item => item.__off).filter(Boolean);
    },
], (ifaces) => ifaces.length);

export const getHwInterfaces = createSelectorArrayOfObjectsShallow(
    [ (state) => getInterfaceIds(state).filter(item => !item.startsWith(HW_IFACE_TYPE)) ], result => result
);


export const isFromConfiguration = ({ item, value, clusterNodeSelector }) =>
    value && (item.name === value.device?.[clusterNodeSelector] || item.name === value.device?.[clusterNodeSelector] ||
        `vlan${value.vlanTag}` === item.name ||
        `br${value.ifaceTag}` === item.name ||
        `bond${value.ifaceTag}` === item.name)
    ;

export const addNewInterface = ({ value, uuid, hwParams, name, fromInterface, returnAction, rowIndex, addingAfter,
    hide }) =>
    ({
        type: getGlcfgSetterActionType('interfaces'), action: 'add', value,
        uuid, name, hwParams, fromInterface, returnAction, rowIndex, addingAfter, hide
    });

export const setInterface = ({ value, uuid, key, subkey, }) =>
    ({ type: getGlcfgSetterActionType('interfaces'), action: 'setParameters', value, uuid, key, subkey });

export const deleteInterface = ({ uuid }) =>
    ({ type: getGlcfgSetterActionType('interfaces'), action: 'delete', uuid });

export const copyInterface = ({ uuid }) =>
    ({ type: getGlcfgSetterActionType('interfaces'), action: 'copy', uuid });

export const setInterfaceOrder = ({ order }) =>
    ({ type: getGlcfgSetterActionType('interfaces'), action: 'order', value: order });


export const getInterfacesNames = rootState => {
    const names = {};
    getInterfacesFromConfiguration(rootState).forEach(item => names[item.id] = item.name);
    return names;
};

export const getInterfacesNamesFromInit = rootState => {
    const names = { lo: 'loopback' };
    getInterfacesFromConfigurationInit(rootState).forEach(item =>
        names[item.device?.[NODE_A_ID] || `vlan${item.vlanTag}`] = item.name);
    return names;
};

export const getClusterSharedInterface = createSelector([
    (state) => getInterfaceById(state, HW_IFACE_TYPE),
    (state) => getGlcfgValue(state, CLUSTER_SHARED),
],
(byId, clusterId) => byId[clusterId]);
// *****************************************************************************

// ************************** DHCPD ********************************************

//--- setters ---
export const addDhcpService = ({ value, uuid, parentUuid, name, returnAction, kids, cfgItem, addingAfter }) =>
    ({
        type: getGlcfgSetterActionType(DHCPD), action: 'add', value,
        uuid, parentUuid, name, returnAction, kids, cfgItem, addingAfter
    });

export const setDhcpService = ({ value, uuid, key, subkey, cfgItem }) =>
    ({ type: getGlcfgSetterActionType(DHCPD), action: 'setParameters', value, uuid, key, subkey, cfgItem });

export const deleteDhcpService = ({ uuid, cfgItem, parentUuid }) =>
    ({ type: getGlcfgSetterActionType(DHCPD), action: 'delete', uuid, cfgItem, parentUuid });

export const copyDhcpService = ({ uuid, parentUuid }) =>
    ({ type: getGlcfgSetterActionType(DHCPD), action: 'copy', uuid, parentUuid });

export const setDhcpServiceOrder = ({ order, pools, parentUuid }) =>
    ({ type: getGlcfgSetterActionType(DHCPD), action: 'order', value: order, key: pools, parentUuid });

export const setDhcpOrder = ({ order }) =>
    ({ type: getGlcfgSetterActionType(DHCPD), action: 'orderService', value: order, });

//--- getters ---

const getDefaultNameserversForDhcpdService = ({ dhcpService, interfaces, dns, resolver }) => {
    try {
        const addresses = getDefaultNameserversForDhcpServer({
            dnsServerIsEnabled: !dns.__off,
            dnsServerAddresses: dns.addresses,
            dhcpServerInterface: interfaces[dhcpService.iface],
            dhcpServerInterfaceAddresses: interfaces[dhcpService.iface]?.address[NODE_SHARED],
            resolverType: resolver.type,
            resolverNameservers: resolver.nameservers,
        });
        return (addresses || []).map(item => `${netaddr(item).noMask()}`).join(', ');
    } catch (error) {
        return undefined;
    }
};

export const getDhcpdServiceGetterByUuid = () => {
    return createSelector([
        getTableItem,
        state => getTableById(state, HW_IFACE_TYPE),
        state => getGlcfgValue(state, DOMAIN),
        state => getGlcfgValue(state, DNS),
        state => getGlcfgValue(state, RESOLVER)

    ], (dhcpService, interfaces, domain, dns, resolver) => {
        if (!dhcpService || dhcpService === EMPTY_IMMUTABLE_OBJ) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        const ifaceSharedAddrs = interfaces[dhcpService.iface]?.address[NODE_SHARED];
        return {
            ...dhcpService,
            relay: stringifyAddress(dhcpService.relay),
            iface: dhcpService.iface && [ dhcpService.iface ],
            gateways: stringifyAddress(dhcpService.gateways),
            nameservers: stringifyAddress(dhcpService.nameservers),
            defaultDomain: stringifyAddress(dhcpService.defaultDomain),
            pools: dhcpService.pools?.length ? dhcpService.pools : [ NEW_ROW_CONSTANT ],
            leases: dhcpService.leases?.length ? dhcpService.leases : [ NEW_ROW_CONSTANT ],
            subnetAddr: stringifyAddress(dhcpService.subnetAddr),
            placeholders: {
                gateways: ifaceSharedAddrs?.map(item => item ? netaddr(item).noMask() : '').join(', '),
                nameservers: getDefaultNameserversForDhcpdService({ dhcpService, interfaces, dns, resolver }),
                subnetAddr: ifaceSharedAddrs?.[0] && [
                    netaddr(ifaceSharedAddrs[0]).toNetworkOrSimpleAddr().toString()
                ],
                defaultDomain: domain,
            }
        };
    });
};


export const getPoolByUuid = () => {
    return createSelector(
        getTableItem,
        (service) => {
            if (!service) {
                return EMPTY_IMMUTABLE_OBJ;
            }
            return {
                ...service,
                rangeFrom: stringifyAddress(service.rangeFrom),
                rangeTo: stringifyAddress(service.rangeTo),

            };
        }
    );
};


export const getLeasesUuid = () => {
    return createSelector(
        getTableItem,
        (service) => {
            if (!service) {
                return EMPTY_IMMUTABLE_OBJ;
            }
            return {
                ...service,
                ip: stringifyAddress(service.ip),
                mac: service.mac && [ service.mac ],
            };
        }
    );
};

export const getDhcpdProps = createSelectorArrayOfObjectsShallow([
    (state) => {
        const items = getDhcpdItems(state);
        const dataGetter = getDhcpdData();

        return items.map(item => ({
            type: item.type,
            name: item.name,
            __off: item.__off,
            color: item.color,
            icon: item.type === DHCP_SERVER_TYPE ? 'dns' : 'send-circle-outline',
            id: item.id,
            state: dataGetter(state, item.id).length ? 'active' : 'inactive'
        }));
    },
], (array) => {
    const values = {};
    array.forEach(item => values[item.id] = item);
    return values;
});


// ***************************************************************************************


// *************************** VPN *******************************************************
//--- getters ---


export const getVpnProps = createSelectorArrayOfObjectsShallow([
    (state) => {
        const items = getVpnItems(state);
        return items.map(item => ({
            name: item.name,
            __off: item.__off,
            color: item.color,
            icon: hlcfgRowObjectIsFromTable(item, hlcfgTableName.openvpnRas) ? 'server-network' : 'vpn',
            id: item.id,
            type: hlcfgRowObjectIsFromTable(item, hlcfgTableName.openvpnRas) ? 'ras' : 'client'
        }));
    },
], (array) => {
    const values = {};
    array.forEach(item => values[item.id] = item);
    return values;
});


export const findVpnServiceFromDevice = (name, vpnItems) => {
    return vpnItems.find(device => `${(device.interfaceTopology ||
         VPN_INTERFACE_TOPOLOGY_TUN_SUBNET).substring(0, 3)
    }${device.tunIndex}` === name);
};
export const getVpnServiceFromDevice = (state, name) => {
    return findVpnServiceFromDevice(name, getVpnItems(state));
};

export const getLowestPossibleTunIndex = createSelector(
    [ state => getVpnItems(state) ], vpn => getTheLowestPossibleNumber(
        vpn?.map(item => item.tunIndex)
    )
);

export const getIsVpnFromInit = () => {
    return createSelector(
        (state, uuid) => getInitGlcfgValue(state, 'vpn').find(item => item === uuid),
        isFromInit => Boolean(isFromInit)
    );
};

export const getIsGoogleAuthInit = (state, uuid) => getTableItemInit(state, uuid)?.googleAuthEnabled;
export const getIsGoogleAuth = (state, uuid) => getTableItem(state, uuid)?.googleAuthEnabled;


export const getVpnUsersIdsFromService = (state, uuid) => getTableItem(state, uuid)?.pushToUser;

export const getVpnUsersIds = () => {
    return createSelector(
        getVpnUsersIdsFromService,
        ids => ids
    );
};

export const getVpnUsers = (rootState) => getGlcfgValue(rootState, 'openvpnUsers') || EMPTY_IMMUTABLE_OBJ;


export const getIsVpnUserFromInit = (state, uuid) => {
    const user = getTableItemInit(state, uuid);
    return Boolean(user);
};


// ********************** IPSEC *******************************


export const getIpsecProps = createSelectorArrayOfObjectsShallow([
    (state) => {
        const items = getIpsecItems(state);
        const status = getStatus(state);

        return items.map(item => ({
            name: item.name,
            __off: item.__off,
            color: item.color,
            id: item.id,
            state: status?.[item.id]?.isEstablished ? 'active' : 'inactive',
        }));
    },
], (array) => {
    const values = {};
    array.forEach(item => values[item.id] = item);
    return values;
});

const findAddress = (ifaces, addr) => ifaces.find(item => {
    return item.address?.[NODE_SHARED]?.filter(sharedAddr => {
        return sharedAddr && addr ? netaddr(sharedAddr).noMask().isEqualTo(netaddr(addr)) : false;
    }).length;
});


const isMyAddress = (ifaces, addr, namedObjects) => {
    if (isAddressesSelector(addr)) {
        return true;
    }
    if (isNamedObject(addr) && namedObjects[namedObjectToString(addr)]) {
        return namedObjects[namedObjectToString(addr)].flat().some(item =>  {
            return findAddress(ifaces, item);
        });
    }
    return Boolean(findAddress(ifaces, addr));
};

export const getIpsecServiceGetterByUuid = () => {
    return createSelector([
        getTableItem,
        getAllHwAndVlanInterfaces,
        getNamedObjectNetaddrAllValues,
    ], (ipsecService, ifaces, namedObjects) =>  {
        if (!ipsecService || ipsecService === EMPTY_IMMUTABLE_OBJ) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        return {
            ...ipsecService,
            leftNode: stringifyAddress(ipsecService.leftNode),
            leftSubnets: stringifyAddress(ipsecService.leftSubnets, true),
            rightSubnets: stringifyAddress(ipsecService.rightSubnets, true),
            rightNode: stringifyAddress(ipsecService.rightNode),
            ikeLifetimeSeconds: ipsecService.ikeLifetimeSeconds ?
                valueFormatter.formatSeconds(ipsecService.ikeLifetimeSeconds) : undefined,
            lifetimeSeconds: ipsecService.lifetimeSeconds ?
                valueFormatter.formatSeconds(ipsecService.lifetimeSeconds) : undefined,
            isLeftMyNode: isMyAddress(ifaces, ipsecService.leftNode, namedObjects),
            isRightMyNode: isMyAddress(ifaces, ipsecService.rightNode, namedObjects),
        };
    });
};


export const getIsIpsecServiceFromInit = () => {
    return createSelector([
        getTableItemInit,
    ], (ipsecService) =>  {
        if (!ipsecService || ipsecService === EMPTY_IMMUTABLE_OBJ) {
            return false;
        }
        return true;
    });
};


// ********************** QoS ********************************


const findChildrens = ({ nodes, start, result, color }) => {
    if (nodes[start]?.childrenIds?.length) {
        nodes[start].childrenIds.forEach(item => findChildrens({ nodes, start: item, result, color }));
    } else {
        if (nodes[start]) {
            result.push(
                {
                    label: nodes[start].name || 'unknown',
                    value: nodes[start].id || start,
                    color: color,
                    name: 'leaf'
                }
            );
        }
    }

};

export const getLowestPossibleClassId = createSelector(
    [ state => getTableById(state, QOS_NODE_TABLE_TYPE) ], nodes => getTheLowestPossibleNumber(
        Object.values(nodes)?.map(item => item.classId)
    )
);


export const getQoSOptions = createSelector(
    [ state => getTableById(state, QOS_NODE_TABLE_TYPE), getQoSItems, getInterfaceItems ],
    (nodes, queues, interfaces) => {
        if (!(nodes && queues)) {
            return [];
        }
        const returnOptions = [];
        for (const queue of queues) {
            if (queue.__off) {
                continue;
            }
            if (queue.rootNodeId) {
                const options = [];
                findChildrens({
                    nodes,
                    start: queue.rootNodeId,
                    result: options,
                    color: interfaces.find(item => item.id === queue.interfaceId)?.color
                }),
                returnOptions.push({
                    label: queue.name,
                    options: options,
                    color: interfaces.find(item => item.id === queue.interfaceId)?.color
                });
            }
        }
        return returnOptions;
    }
);


export const getNodesGuaranteedBandwidthMbit = createSelectorArrayOfObjectsShallow( //optimalization
    [ (state, uuid) => {
        const nodes =  getTableItemSiblings(state, uuid);
        return Object.values(nodes)?.map(item => ({
            id: item.id,
            guaranteedBandwidthMbit: item.guaranteedBandwidthMbit
        }));
    }
    ], items => items
);


export const getQoSNode = () => {
    return createSelector(
        [ getTableItem, getNodesGuaranteedBandwidthMbit, getQoSService ],
        (node, nodes, queues) => {
            if (!node) {
                return EMPTY_IMMUTABLE_OBJ;
            }
            let childrensUsage = (node.childrenIds || []).reduce((all, item) =>
                nodes.find(val => val.id === item)?.guaranteedBandwidthMbit ?
                    nodes.find(val => val.id === item)?.guaranteedBandwidthMbit + all : all, 0);
            if (queues.rootNodeId === node.id) {
                queues.secondTree.forEach(tree => {
                    childrensUsage += nodes.find(val => val.id === tree.rootNodeId)?.guaranteedBandwidthMbit || 0;
                });
            }
            return {
                canBeUsed: (node.guaranteedBandwidthMbit || 0)  - childrensUsage,
                ...node,
            };
        }
    );
};

export const getQoSQueuesById = createSelector(
    getQoSByIdType,
    queues => queues || EMPTY_IMMUTABLE_OBJ
);


export const getQoS = createSelector([
    (state, uuid) => getTableItem(state, uuid),
    getInterfaceItems,
], (activeQos, interfaces) => {
    if (doesntExists(activeQos)) {
        return EMPTY_IMMUTABLE_OBJ;
    }
    return {
        ...activeQos,
        color: interfaces.find(item => activeQos.interfaceId === item.id)?.color
    };
});

export const getQoSProps = createSelectorArrayOfObjectsShallow([
    (state) => {
        const items = getQoSItems(state);
        return items.map(item => ({
            name: item.name,
            __off: item.__off,
            color: getTableItem(state, item.interfaceId)?.color,
            id: item.id
        }));
    },
], (array) => {
    const values = {};
    array.forEach(item => values[item.id] = item);
    return values;
});

export const getQoSService = createSelector([
    state => getTableItem(state, getActiveCard(state, QOS)),
    getQoSItems,
    getInterfaceItems,
], (activeQos, qos, interfaces) => {
    if (doesntExists(activeQos)) {
        return EMPTY_IMMUTABLE_OBJ;
    }
    return {
        secondTree: qos?.filter(item => item.interfaceId?.startsWith(VLAN_IFACE_TYPE) &&
        interfaces.find(iface => iface.id === item?.interfaceId)?.vlanIface  === activeQos?.interfaceId),
        ...activeQos,
        color: interfaces.find(item => activeQos.interfaceId === item.id)?.color
    };
});

// ********************** DNS ********************************

export const getResolverNameservers = createSelector(
    state => getGlcfgValue(state, RESOLVER).nameservers,
    servers => stringifyAddress(servers)
);

export const getResolverNameserversSchema = (state) => getGlcfgSchema(state, RESOLVER).properties.nameservers;

export const getResolverDnssecSchema = (state) => getGlcfgSchema(state, RESOLVER).properties.dnssec;

export const getResolverType = (state) => getGlcfgValue(state, RESOLVER).type;

export const getResolverDnssec = (state) => getGlcfgValue(state, RESOLVER).dnssec;

export const getDnssec = (state) => getGlcfgValue(state, DNS).dnssec;

export const getDnsCache = (state) => getGlcfgValue(state, DNS).queryCacheAny;


export const getDnsServerDisabled = (state) => getGlcfgValue(state, DNS).__off;
export const getDnsServerType = (state) => getGlcfgValue(state, DNS).type;
export const getDnsServerDnsCatch = (state) => {
    return !getTableItem(state, REDIRECT_DNS_RULE_UUID)[HLCFG_OFF];
};
export const getDnsServerNameservers = createSelector(
    state => getGlcfgValue(state, DNS).nameservers,
    servers => stringifyAddress(servers)
);
export const getDnsServerAddresses = (state) => getGlcfgValue(state, DNS).addresses;

export const getDnsServerNameserversSchema = (state) => getGlcfgSchema(state, DNS).properties.nameservers;
export const getDnsServerAddressesSchema = (state) => getGlcfgSchema(state, DNS).properties.addresses;


// ************************* SAFESEARCH *****************************

export const getIsSafeSearchEnabled = getComponentEnabledHook(DNS, 'safesearch');
export const getIsSafeSearchGoogle = (state) => getGlcfgValue(state, DNS).safesearch.google;
export const getIsSafeSearchBing = (state) => getGlcfgValue(state, DNS).safesearch.bing;


// ************************* MULTIHOMING ****************************

export const getMultihomingIds = (state) => getGlcfgValue(state, MULTIHOMING)?.gateways;

export const getMultihomingUuid = () => {
    return createSelector([
        getTableItem,
    ], (gateways) => {
        if (!gateways) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        return {
            ...gateways,
            addr: stringifyAddress(gateways.addr),
            pingIps: stringifyAddress(gateways.pingIps)
        };
    });
};


export const getGateway = createSelector([
    state => getGlcfgValue(state, GW4),
    state => getGlcfgValue(state, GW6),
    state => getGlcfgValue(state, MULTIHOMING),
], (gw4, gw6, multihoming) => {
    return {
        addr: stringifyAddress(gw4?.addr),
        addr6: stringifyAddress(gw6),
        preemptAfterSeconds: multihoming?.preemptAfterSeconds
    };
});


// ******************** HONEYPOT **********************************


export const getHoneypot = createSelector(
    state => getGlcfgValue(state, HONEYPOT),
    (honeypot) => {
        if (!honeypot) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        return {
            ...honeypot,
            addresses: stringifyAddress(honeypot.addresses),
            ports: (honeypot.ports || []).map(netport).map(stringify),
        };
    }
);

export const getHoneypotWithoutFormating = createSelector(
    state => getGlcfgValue(state, HONEYPOT),
    (honeypot) => {
        if (!honeypot) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        return {
            ...honeypot,
        };
    }
);

export const getHoneypotSchema = (state) => {
    return getGlcfgSchema(state, HONEYPOT).properties;
};


// **************** OPTIONS ****************

const getWebProfileOptions = (profiles) => ({
    label: 'Profile',
    options: Object.keys(profiles).map(item => {
        return { label: profiles[item].name, value: profiles[item].id, color: profiles[item].color };
    }),
    color: '',
});

export const getAllOptions = createSelector(
    [
        getQoSOptions,
        getProfileProps,
    ],
    (qos, profiles) => {
        return [ ...qos, getWebProfileOptions(profiles) ];
    }
);


// ********* SYSTEM COMPONENTS ***********

// just vpn for now, probably just vpn for ever, generic name tho
export const getSystemComponentsBasedOnName = createSelector(
    [
        getVpnItemsInit,
        (state, name) => name
    ],
    (vpns, id) => {
        if (!id) {
            return undefined;
        }
        return vpns?.find(item => vpnSvcName(item.id) === id.split('@')?.[1])?.name;
    }
);

// *********************** CHARTS **********************

export const getDashboardsIds =  createSelector([
    (state, type) => getGlcfgValue(state, DASHBOARDS)[type],
],
(dashboards) => {
    if (!dashboards) {
        return EMPTY_IMMUTABLE_ARR;
    }
    return dashboards;
});

export const setDashboardsOrder = ({ order, type }) =>
    ({ type: getGlcfgSetterActionType(DASHBOARDS),  value: order, key: type });


export const sagasGlcfg = [
    takeEvery(CLOSE_HEADER_PROFILE, workerSetCloseProfileHeader),
];
