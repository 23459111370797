/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment, { DurationInputArg1, unitOfTime } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { UseBooleanFuncType, useBoolean } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { Icon, Input, InputRangeTime, Select } from '~frontendComponents/Generic/index.js';
import { logInitializeParamsSchema } from '~sharedLib/guiLogs/schemas.ts';
import { TIME_FORMAT } from '~commonLib/moment.ts';
import { BOOT, CREATE_NEW_FILTER, DISABLE_TIME, FOLLOW, GREP, GREP_FLAGS,
    LOG_LEVEL, PIDS, SINCE_TIME, UNITS, UNTIL_TIME,
    userSetting } from '~frontendConstants/constants.ts';
import { Params, UserFilter, getNewParams, getShowFullMessage, setNewParams, swapShowFullMessage, GrepFlags, pickUserFilter, RelativeTime } from '~frontendDucks/systemLogs/index.ts';
import { setModalState } from '~frontendDucks/modals/index.js';
import { useUserSetting } from '~frontendLib/hooks/userSettings.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { RowMenuAndSwitchBody } from '~frontendComponents/RowMenuAndSwitch.tsx';

import { ButtonType, ButtonsTimePicker } from '../../../TimeFilter.tsx';


const UNIT_OBJECTS = {
    'ak-backend': 'systemLogs:units.akBackend',
    'ak-sysmgr': 'systemLogs:units.akSysmgr',
    'ak-sysmon': 'systemLogs:units.akSysmon',
    callhome: 'systemLogs:units.callhome',
    'reporter-db': 'systemLogs:units.reporterDB',
    honeypot: 'systemLogs:units.honeypot',
    keepalived: 'systemLogs:units.keepalived',
    klogd: 'systemLogs:units.klogd',
    'kpf-resolver': 'systemLogs:units.kpfResolver',
    named: 'systemLogs:units.named',
    ipmon: 'systemLogs:units.ipmon',
    rsyslog: 'systemLogs:units.rsyslog',
    ssh: 'systemLogs:units.ssh',
    suricata: 'systemLogs:units.suricata',
    systemd: 'systemLogs:units.systemd',
    'systemd-journald': 'systemLogs:units.systemdJournal',
};

const ERRORS = {
    emergency: 'systemLogs:logLevels.emergency',
    alert: 'systemLogs:logLevels.alert',
    critical: 'systemLogs:logLevels.critical',
    error: 'systemLogs:logLevels.error',
    warning: 'systemLogs:logLevels.warning',
    notice: 'systemLogs:logLevels.notice',
    info: 'systemLogs:logLevels.info',
    debug: 'systemLogs:logLevels.debug',
};

const boots = [
    {
        id: '0',
        label: '0',
        value: 0,
    },
    {
        id: '-1',
        label: '-1',
        value: -1,
    },
    {
        id: '-2',
        label: '-2',
        value: -2,
    }
];

const BUTTONS: ButtonType = [
    { shortUnit: 'm', unit: 'minutes', amount: 5 },
    { shortUnit: 'm', unit: 'minutes', amount: 15 },
    { shortUnit: 'm', unit: 'minutes', amount: 30 },
    { shortUnit: 'h', unit: 'hour', amount: 2 },
    { shortUnit: 'h', unit: 'hour', amount: 12 },
    { shortUnit: 'd', unit: 'day', amount: 1 },
];

const defaultUserFilters: Record<string, UserFilter> = {
    Adaptivita: {
        fake: true,
        id: 'Adaptivita',
        name: 'systemLogs:defaultFilters.adaptive',
        parameters: {
            tailMode: true,
            grep: '^BAF ',
            pids: [ 0 ],
        },
        relativeTime: {
            duration: 12,
            units: 'hour'
        }
    },
    PacketFilter: {
        fake: true,
        id: 'PacketFilter',
        name: 'systemLogs:defaultFilters.packetFilter',
        parameters: {
            tailMode: true,
            grep: '^NFT ',
            pids: [ 0 ],
        },
        relativeTime: {
            duration: 12,
            units: 'hour'
        }
    },
    Proxy: {
        fake: true,
        id: 'Proxy',
        name: 'systemLogs:defaultFilters.proxy',
        parameters: {
            tailMode: true,
            units: [ 'proxy' ],
        },
        relativeTime: {
            duration: 12,
            units: 'hour'
        }
    }
};

const Filters = (props: {
    setInitParams: (params:Params) => void,
    setAutoScroll: UseBooleanFuncType,
    initParams: Params }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const newParams = useSelector(state => getNewParams(state));
    const showOrHide = useSelector(state => getShowFullMessage(state));
    const [ showFilters, setShowFilters ] = useBoolean();
    const [ disableTime, setDisableTime ] = useBoolean(false);
    const [ relativeTime, setRelativeTime ] = useState<RelativeTime>();
    const units = Object.keys(UNIT_OBJECTS).map(key => ({ id: key, label: t(UNIT_OBJECTS[key]), value: key }));
    const logPrio = Object.keys(ERRORS).map((key, index) => ({ id: key, label: t(ERRORS[key]), value: index }));
    const { setInitParams, initParams, setAutoScroll } = props;

    const setValue = ({ ...args }) => {
        if (args.name === GREP_FLAGS) {
            dispatch(setNewParams({
                newParams: {
                    [args.name]: { ...newParams.grepFlags,
                        [args.id]: newParams.grepFlags?.[args.id] ? undefined : true }
                }
            }));
        } else {
            dispatch(setNewParams({
                newParams: {
                    [args.name]: args.name === PIDS ? args.value.map((str: string) => parseInt(str)) : args.value,
                }
            }));
        }
    };

    const handleTimeRange = ({ ...props }) => {
        dispatch(setNewParams({
            newParams: {
                sinceTime: props.sinceTime ? moment(props.sinceTime).format(TIME_FORMAT.systemdTime) :
                    newParams.sinceTime,
                untilTime: props.untilTime ? moment(props.untilTime).format(TIME_FORMAT.systemdTime) :
                    newParams.untilTime,
            }
        }));
        setRelativeTime(undefined);
    };

    const shortcutSetTime = (amount: DurationInputArg1, unit: unitOfTime.Base) => {
        const start = moment().subtract(amount, unit).format(TIME_FORMAT.systemdTime);
        const end = moment().format(TIME_FORMAT.systemdTime);
        setValue({ name: SINCE_TIME, value: start });
        setValue({ name: UNTIL_TIME, value: newParams.tailMode ? undefined : end });
        setRelativeTime({ duration: amount, units: unit });
    };

    const handleNewFilters = () => {
        const realParams = {
            ...newParams,
            untilTime: newParams?.untilTime && !newParams?.tailMode ? newParams.untilTime : undefined,
        };
        setInitParams(realParams);
        dispatch(setNewParams({
            newParams: realParams
        }));
        if (realParams.tailMode) {
            setAutoScroll.on();
        }
    };

    const handleClearFilters = () => {
        dispatch(setNewParams({
            newParams: {}
        }));
        setRelativeTime(undefined);
    };

    const handleCreateFilter = () => {
        dispatch(setModalState({ modal: CREATE_NEW_FILTER, value: true, specialValues: { newParams, relativeTime } }));
    };

    const handleFollow = () => {
        setValue({ name: FOLLOW, value: newParams?.[FOLLOW] ? undefined : true });
    };

    const handleTimeConstraints = () => {
        if (disableTime) {
            setValue({ name: SINCE_TIME, value: moment().subtract(2, 'hours').format(TIME_FORMAT.systemdTime) });
        } else {
            setValue({ name: SINCE_TIME, value: undefined });
            setValue({ name: UNTIL_TIME, value: undefined });
        }
        setDisableTime.swap();
        setRelativeTime(undefined);
    };

    const validateNumbers = (inputValue: string, id: string) => {
        const check = parseInt(inputValue);
        if (id === BOOT && check > 0) {
            return false;
        }
        if (!isNaN(check) && !newParams.pids?.includes(check)) {
            return true;
        }
        return false;
    };

    const handleShowOrHide = () => {
        dispatch(swapShowFullMessage({ value: !showOrHide }));
    };

    const isFilterEqual = () => {
        const newInitParams = { ...initParams, sinceTime: initParams.sinceTime?.slice(0, -2),
            untilTime: initParams.untilTime?.slice(0, -2) };
        const newNewParams = { ...newParams, sinceTime: newParams.sinceTime?.slice(0, -2),
            untilTime: newParams.untilTime?.slice(0, -2) };
        return isEqual(newInitParams, newNewParams);
    };

    return (
        <MDBCard>
            <MDBCardTitle className={classNames(
                'cardHide__title',
                { 'cardHide__title--noBorderBottom': showFilters },
            )}
            >
                <div
                    className="clicable"
                    onClick={setShowFilters.swap}
                >
                    {t('systemLogs:filters.title')}
                    <Icon
                        name={showFilters ? 'chevron-up' : 'chevron-down'}
                    />
                    {!isFilterEqual() && !showFilters ?
                        <IconWithTooltip
                            className={'icon--gold'}
                            name={'alert-outline'}
                            size={'sm'}
                            tooltipText={'systemLogs:filters.notApplied'}
                        /> : null
                    }
                    {isFilterEqual() && !showFilters ?
                        <IconWithTooltip
                            className={'icon--greyOff'}
                            name={'information-outline'}
                            size={'sm'}
                            tooltipText={'systemLogs:filters.applied'}
                        /> : null
                    }
                </div>
            </MDBCardTitle>
            {showFilters ?
                <MDBCardBody className="py-0">
                    <MDBRow className={'row--center favFilters pl-3'}>
                        <span className="favFilters--items">
                            {t('systemLogs:filters.favorite')}
                        </span>
                        <PredefinedFilters
                            setDisableTime={setDisableTime}
                            setRelativeTime={setRelativeTime}
                        />
                    </MDBRow>
                    <MDBRow>
                        <MDBCol
                            lg="3"
                            md="6"
                            sm="12"
                        >
                            <Select
                                className={'mb-0'}
                                disabled={false}
                                id="SystemUnits"
                                isCreatable
                                isMulti
                                label={t('systemLogs:filters.params.units')}
                                maxValueShown={3}
                                name={UNITS}
                                onChange={setValue}
                                options={units}
                                schema={logInitializeParamsSchema.properties.units}
                                value={newParams.units}
                            />
                        </MDBCol>
                        <MDBCol
                            lg="3"
                            md="6"
                            sm="12"
                        >
                            <Input
                                className={'mb-0'}
                                dontDebounce
                                icon={
                                    <GrepFlagsChips
                                        grepFlags={newParams.grepFlags}
                                        onSet={setValue}
                                    />}
                                id="SystemMessage"
                                inputClass="messageInput"
                                label={t('systemLogs:filters.params.grep.title')}
                                name={GREP}
                                onChange={setValue}
                                schema={logInitializeParamsSchema.properties.grep}
                                value={newParams.grep}
                            />
                        </MDBCol>
                        <MDBCol
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <Select
                                className={'mb-0'}
                                disabled={false}
                                id="SystemPIDs"
                                isCreatable
                                isMulti
                                label={t('systemLogs:filters.params.pids')}
                                maxValueShown={3}
                                name={PIDS}
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={setValue}
                                schema={logInitializeParamsSchema.properties.pids}
                                validator={validateNumbers}
                                value={newParams.pids}
                            />
                        </MDBCol>
                        <MDBCol
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <Select
                                className={'mb-0'}
                                disabled={false}
                                id="SystemBoots"
                                isCreatable
                                isMulti
                                label={t('systemLogs:filters.params.boot')}
                                name={BOOT}
                                noDropdownIndicator
                                onChange={setValue}
                                options={boots}
                                paste={false}
                                schema={logInitializeParamsSchema.properties.bootRelative}
                                validator={validateNumbers}
                                value={newParams?.bootRelative !== undefined ? [ newParams.bootRelative ] : []}
                            />
                        </MDBCol>
                        <MDBCol
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <Select
                                className={'mb-0'}
                                disabled={false}
                                id="SystemLevel"
                                isMulti
                                label={t('systemLogs:filters.params.level')}
                                name={LOG_LEVEL}
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={setValue}
                                options={logPrio}
                                paste={false}
                                schema={logInitializeParamsSchema.properties.logPriority}
                                value={newParams?.logPriority !== undefined ? [ newParams.logPriority ] : []}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol
                            lg="6"
                            md="12"
                            sm="12"
                        >
                            <div className="logFilter--formGroupMargins">
                                <ButtonsTimePicker
                                    buttons={BUTTONS}
                                    disabled={disableTime}
                                    relativeTime={relativeTime}
                                    shortcutSetTime={shortcutSetTime}
                                    wrap
                                />
                                <InputRangeTime
                                    className={'timeInputs'}
                                    disabledUntil={newParams.tailMode || disableTime}
                                    disableSince={disableTime}
                                    endDate={newParams.untilTime}
                                    endId={UNTIL_TIME}
                                    onChange={handleTimeRange}
                                    startDate={newParams.sinceTime}
                                    startId={SINCE_TIME}
                                    withoutValue
                                />
                            </div>
                        </MDBCol>
                        <MDBCol
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <div className={'chips'}>
                                <i
                                    className="chips--icon"
                                    id={FOLLOW}
                                    onClick={handleFollow}
                                >
                                    <IconWithTooltip
                                        className={newParams.tailMode ? 'icon--primary' : 'icon--grey'}
                                        iconSize="sm"
                                        link
                                        name="autorenew"
                                        tooltipPlace={'top'}
                                        tooltipText={t('systemLogs:filters.params.follow')}
                                        withoutTranslation
                                    />
                                </i>
                                <i
                                    className="chips--icon"
                                    id={DISABLE_TIME}
                                    onClick={handleTimeConstraints}
                                >
                                    <IconWithTooltip
                                        className={disableTime ? 'icon--primary' : 'icon--grey'}
                                        iconSize="sm"
                                        link
                                        name="timer-off-outline"
                                        tooltipPlace={'top'}
                                        tooltipText={t('systemLogs:withoutTime')}
                                        withoutTranslation
                                    />
                                </i>
                                <i
                                    className="chips--icon"
                                    onClick={handleShowOrHide}
                                >
                                    <IconWithTooltip
                                        className={showOrHide ? 'icon--primary' : 'icon--grey'}
                                        iconSize="sm"
                                        link
                                        name="message-processing-outline"
                                        tooltipPlace={'top'}
                                        tooltipText={t('systemLogs:showOrHide')}
                                        withoutTranslation
                                    />
                                </i>
                            </div>
                        </MDBCol>
                        <MDBCol
                            lg="4"
                            md="8"
                            sm="12"
                        >
                            <div className="logFilter--formGroupMargins">
                                <div
                                    className="btn-outline-secondary btn btn-full"
                                    onClick={handleClearFilters}
                                >
                                    {t('systemLogs:filters.action.clear')}
                                </div>
                                <div
                                    className="btn-outline-secondary btn btn-full"
                                    onClick={handleCreateFilter}
                                >
                                    {t('systemLogs:filters.action.addToFav')}
                                </div>
                                <div
                                    className={classNames(
                                        { ['btn-outline-secondary']: isFilterEqual() },
                                        'btn',
                                        'btn-full',
                                        { ['btn-primary']: !isFilterEqual() },
                                        { ['activateConfiguration']: !isFilterEqual() }
                                    )}
                                    onClick={handleNewFilters}
                                >
                                    {t('systemLogs:filters.action.apply')}
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody> : null}
        </MDBCard>
    );
};


type GrepFlagsType = {
    grepFlags: GrepFlags | undefined
    onSet: ({ id, name }) => void
}

const GrepFlagsChips = ({ grepFlags, onSet }: GrepFlagsType) => {
    const { t } = useTranslation();
    return (
        <div className="spaceBetween">
            <i
                className={classNames(
                    'form-control__eye',
                    'form-control__eye--grepIgnoreCase',
                )}
                id="ignoreCase"
                onClick={() => onSet({ id: 'ignoreCase', name: GREP_FLAGS })}
            >
                <IconWithTooltip
                    className={grepFlags?.ignoreCase ? 'icon--primary' : 'icon--grey'}
                    iconSize="sm"
                    link
                    name="format-letter-case"
                    tooltipPlace={'top'}
                    tooltipText={t('systemLogs:filters.params.grep.ignorCase')}
                    withoutTranslation
                />
            </i>
            <i
                className={classNames(
                    'form-control__eye',
                    'form-control__eye--grepInverseMatch',
                )}
                id="invertMatch"
                onClick={() => onSet({ id: 'invertMatch', name: GREP_FLAGS })}
            >
                <IconWithTooltip
                    className={grepFlags?.invertMatch ? 'icon--primary' : 'icon--grey'}
                    iconSize="sm"
                    link
                    name="not-equal"
                    tooltipPlace={'top'}
                    tooltipText={t('systemLogs:filters.params.grep.invertMatch')}
                    withoutTranslation
                />
            </i>
            <i
                className={classNames(
                    'form-control__eye',
                    'form-control__eye--grepAbsoluteMatch',
                )}
                id="fixedStrings"
                onClick={() => onSet({ id: 'fixedStrings', name: GREP_FLAGS })}
            >
                <IconWithTooltip
                    className={grepFlags?.fixedStrings ? 'icon--primary' : 'icon--grey'}
                    iconSize="sm"
                    link
                    name="format-letter-matches"
                    tooltipPlace={'top'}
                    tooltipText={t('systemLogs:filters.params.grep.fixedStrings')}
                    withoutTranslation
                />
            </i>
        </div>
    );
};

type PredefinedFiltersType = {
    setRelativeTime: (RelativeTime?: RelativeTime) => void,
    setDisableTime: UseBooleanFuncType,
}

const PredefinedFilters = ({ setRelativeTime, setDisableTime }: PredefinedFiltersType) => {
    const dispatch = useDispatch();
    const [ userFilters = {}, setUserFilters ] = useUserSetting(userSetting.systemLogsFilters);
    const filters = useMemo(
        () => [ ...Object.values(defaultUserFilters), ...Object.values(userFilters) ],
        [ userFilters ]
    );
    const onDelete = (id: string) => {
        const newFilters = { ...userFilters };
        delete newFilters[id];
        setUserFilters(newFilters);
    };
    const onSet = (id: string) => {
        const filter = filters.find((filter) => filter.id === id);
        if (filter) {
            if (filter.relativeTime) {
                const { duration, units } = filter.relativeTime;
                const paramsWithRelativeTime = {
                    ...filter.parameters,
                    sinceTime: moment().subtract(duration, units).format(TIME_FORMAT.systemdTime),
                };
                setDisableTime.off();
                dispatch(pickUserFilter({ newParams: paramsWithRelativeTime }));
                setRelativeTime({ duration, units });
            } else {
                dispatch(pickUserFilter({ newParams: filter.parameters }));
                setRelativeTime(undefined);
            }
        }
    };

    return (
        <>
            {filters.map(({ id, name, fake }) => {
                return (
                    <FilterButtons
                        fake={fake}
                        id={id}
                        key={id}
                        name={name}
                        onDelete={onDelete}
                        onSet={onSet}
                    />
                );
            })}
        </>
    );
};

type FilterButtonsType = {
    id: string | undefined,
    name: string | undefined,
    fake: boolean | undefined,
    onDelete: (filterId: string) => void,
    onSet: (filterId: string) => void,
};

const FilterButtons = ({ id, name, fake, onDelete, onSet }: FilterButtonsType) => {
    const { t } = useTranslation();
    if (!id || !name) {
        return;
    }
    return (
        <div
            className={classNames(
                'userFilterBtn',
                'clicable',
                'favFilters--items',
                { ['userFilterBtn--default']: fake }
            )}
        >
            <div
                id={id}
                onClick={() => onSet(id)}
            >
                {fake ? t(name) : name}
            </div>
            {!fake ?
                <RowMenuAndSwitchBody
                    deleteFunc={() => onDelete(id)}
                    id={id}
                    popupOnRight={true}
                    small
                /> : null
            }
        </div>
    );
};


export default Filters;
