/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { produce } from 'immer';

import { addOrDeleteBeforeNormalize, notEmpty } from '~commonLib/arrayUtils.ts';
import {
    STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS
} from '~sharedLib/staticHlcfgReferenceUtils.js';
import * as glcfgDefinitionConstants from '~frontendConstants/glcfgConstants.js';
import Message from '~frontendComponents/Message/index.js';
import { setValue } from '~commonLib/objectUtils.ts';

import { updateInterface } from './InterfaceActions/index.js';
import { add, set, setParent, copy } from './Normalize/index.js';


/**
 * GUI-level configuration definition structure. Key is a glcfg node name, value is a definition structure.
 *
 * @type {Object<string, object>} a map of glcfg keys to GlcfgObjects
 */
const glcfgDefinition = {
    [glcfgDefinitionConstants.VERSION]: {
        hlcfgPath: 'version',
        title: '',
        category: '',
    },
    [glcfgDefinitionConstants.NTP1]: {
        hlcfgPath: 'services.ntpdClient.ntp1',
        title: <Message message="widgets:Ntp.ntp1.title" />,
        desc: <Message message="widgets:Ntp.ntp1.desc" />,
        category: 'ntpd',
    },
    [glcfgDefinitionConstants.NTP2]: {
        hlcfgPath: 'services.ntpdClient.ntp2',
        title: <Message message="widgets:Ntp.ntp2.title" />,
        desc: <Message message="widgets:Ntp.ntp2.desc" />,
        category: 'ntpd',
    },
    [glcfgDefinitionConstants.RESOLVER]: {
        hlcfgPath: 'network.resolver',
        title: <Message message="widgets:Resolver.title" />,
        desc: <Message message="widgets:Resolver.desc" />,
        category: 'dns',
        setter: ({ state, action }) => setObjectValue({ state, ...action }),

    },
    [glcfgDefinitionConstants.DNS]: {
        hlcfgPath: 'services.dns',
        title: <Message message="widgets:Dns.title" />,
        desc: <Message message="widgets:Dns.desc" />,
        category: glcfgDefinitionConstants.DNS,
        setter: ({ state, action }) => setObjectValue({ state, ...action }),
    },
    [glcfgDefinitionConstants.GW4]: {
        hlcfgPath: 'network.gw4',
        title: <Message message="widgets:Network.gw4.title" />,
        desc: <Message message="widgets:Network.gw4.desc" />,
        category: 'network',
    },
    [glcfgDefinitionConstants.GW6]: {
        hlcfgPath: 'network.gw6',
        title: <Message message="widgets:Network.gw6.title" />,
        desc: <Message message="widgets:Network.gw6.desc" />,
        category: 'network',
    },
    [glcfgDefinitionConstants.ROUTING_ENABLED]: {
        hlcfgPath: 'network.routingEnabled',
        title: <Message message="widgets:Network.routingEnabled.title" />,
        desc: <Message message="widgets:Network.routingEnabled.desc" />,
        category: 'network',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.DOMAIN]: {
        hlcfgPath: 'network.domain.domain.name',
        title: <Message message="widgets:Network.domain.title" />,
        desc: <Message message="widgets:Network.domain.desc" />,
        category: 'network',
    },
    [glcfgDefinitionConstants.HOSTNAME]: {
        hlcfgPath: 'network.hostname.shared',
        title: <Message message="widgets:Network.hostname.title" />,
        desc: <Message message="widgets:Network.hostname.desc" />,
        category: 'network',
    },
    [glcfgDefinitionConstants.TIMEZONE]: {
        hlcfgPath: 'system.timezone',
        title: <Message message="widgets:Ntp.timeZone.title" />,
        desc: <Message message="widgets:Ntp.timeZone.desc" />,
        category: 'timezone',
        displayValuesTitle: {
            cest: <Message message="widgets:Ntp.timeZone.values.cest.title" />,
            utc: <Message message="widgets:Ntp.timeZone.values.utc.title" />,
        },
        displayValuesDesc: {
            cest: <Message message="widgets:Ntp.timeZone.values.cest.desc" />,
            utc: <Message message="widgets:Ntp.timeZone.values.utc.desc" />,
        }
    },
    [glcfgDefinitionConstants.CLUSTER_SHARED]: {
        hlcfgPath: 'cluster.sharedSettings.communicationInterface',
        title: <Message message="widgets:Deployment.firewallClusterANode.title" />,
        desc: <Message message="widgets:Deployment.firewallClusterANode.desc" />,
        category: 'deployment',
    },
    //Cluster A
    [glcfgDefinitionConstants.FIREWALL_CLUSTER_A_HOSTNAME]: {
        hlcfgPath: 'network.hostname.clusterNode:nodeA',
        title: <Message message="widgets:Network.hostname.title" />,
        desc: <Message message="widgets:Network.hostname.desc" />,
        category: 'deployment',
    },
    //Cluster B
    [glcfgDefinitionConstants.FIREWALL_CLUSTER_B_HOSTNAME]: {
        hlcfgPath: 'network.hostname.clusterNode:nodeB',
        title: <Message message="widgets:Network.hostname.title" />,
        desc: <Message message="widgets:Network.hostname.desc" />,
        category: 'deployment',
    },
    [glcfgDefinitionConstants.AK_EVENTS_FEEDBACK_ENABLED]: {
        hlcfgPath: 'services.feedback.akEvents',
        desc: <Message message="widgets:Feedback.akEvents.desc" />,
        title: <Message message="widgets:Feedback.akEvents.title" />,
        category: 'feedback',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.CW_EVENTS_FEEDBACK_ENABLED]: {
        // there is currently no editor for this item displayed in GUI because there is no ClearWeb on AK devices
        hlcfgPath: 'services.feedback.cwEvents',
        desc: <Message message="widgets:Feedback.cwEvents.desc" />,
        title: <Message message="widgets:Feedback.cwEvents.title" />,
        category: 'feedback',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.SYSTEM_STATUS_FEEDBACK_ENABLED]: {
        hlcfgPath: 'services.feedback.systemStatus',
        desc: <Message message="widgets:Feedback.systemStatus.desc" />,
        title: <Message message="widgets:Feedback.systemStatus.title" />,
        category: 'feedback',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.CONFIGURATION_FEEDBACK_ENABLED]: {
        hlcfgPath: 'services.feedback.configuration',
        desc: <Message message="widgets:Feedback.configuration.desc" />,
        title: <Message message="widgets:Feedback.configuration.title" />,
        category: 'feedback',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.CLOUD_BACKUP_FEEDBACK_ENABLED]: {
        hlcfgPath: 'services.feedback.cloudBackup',
        desc: <Message message="widgets:Feedback.cloudBackup.desc" />,
        title: <Message message="widgets:Feedback.cloudBackup.title" />,
        category: 'feedback',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.REPORTER_FEEDBACK_ENABLED]: {
        hlcfgPath: 'services.feedback.reporter',
        desc: <Message message="widgets:Feedback.reporter.desc" />,
        title: <Message message="widgets:Feedback.reporter.title" />,
        category: 'feedback',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.ERRORS_FEEDBACK_ENABLED]: {
        hlcfgPath: 'services.feedback.errors',
        desc: <Message message="widgets:Feedback.errors.desc" />,
        title: <Message message="widgets:Feedback.errors.title" />,
        category: 'feedback',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.HONEYPOT]: {
        hlcfgPath: 'protection.honeypot',
        desc: <Message message="widgets:Honeypot.desc" />,
        title: <Message message="widgets:Honeypot.title" />,
        category: 'honeypot',

        setter: ({ state, action }) => setObjectValue({ state, ...action }),
    },
    [glcfgDefinitionConstants.MULTIHOMING]: {
        hlcfgPath: 'network.multihoming',
        desc: <Message message="widgets:Feedback.errors.desc" />,
        title: <Message message="widgets:Feedback.errors.title" />,
        category: 'routingTable',
        setter: ({ state, action }) => setObjectWithNormalize({ state, ...action }),

    },
    [glcfgDefinitionConstants.SSH_KEYS]: {
        hlcfgPath: 'services.sshd.authorizedKeys',
        title: <Message message="widgets:Sshd.sshInput.title" />,
        category: 'sshd',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.SSHD_SOCKETS]: {
        hlcfgPath: 'services.sshd.sockets',
        title: <Message message={'differs:services.sshd.sockets.title'} />,
        desc: <Message message={'differs:services.sshd.sockets.title'} />,
        category: glcfgDefinitionConstants.SSHD_SOCKETS,
        setter: ({ state, action }) =>
            setNormalizeIds({
                state, ...action
            }),
    },
    [glcfgDefinitionConstants.LICENSE]: {
        hlcfgPath: 'system.license',
        title: <Message message="widgets:license.title" />,
        desc: <Message message="widgets:license.desc" />,
        category: '',
    },
    [glcfgDefinitionConstants.GUI_PUB_KEY]: {
        hlcfgPath: 'system.guiPubkey',
        title: <Message message="differs:system.guiPubkey" />,
        desc: <Message message="differs:system.guiPubkey" />,
        category: '',
    },
    [glcfgDefinitionConstants.GUI_PRIV_KEY]: {
        hlcfgPath: 'system.guiPrivkey',
        title: <Message message="differs:system.guiPrivkey" />,
        desc: <Message message="differs:system.guiPrivkey" />,
        category: '',
    },
    [glcfgDefinitionConstants.JOURNAL]: {
        hlcfgPath: 'services.journal',
        title: <Message message="widgets:Journal.title" />,
        desc: <Message message="widgets:Journal.desc" />,
        category: '',
        setter: ({ state, action }) => setObjectValue({ state, ...action }),
    },
    [glcfgDefinitionConstants.REPORTER]: {
        hlcfgPath: 'system.reporter',
        title: <Message message="widgets:Reporter.title" />,
        desc: <Message message="widgets:Reporter.desc" />,
        category: '',
        setter: ({ state, action }) => setObjectValue({ state, ...action }),

    },
    [glcfgDefinitionConstants.GUI_ADDRESSES_HTTP]: {
        hlcfgPath: 'system.guiAddresses.http',
        title: <Message message="widgets:System.AdminInterface.http.title" />,
        desc: <Message message="widgets:System.AdminInterface.http.title" />,
        category: '',
    },
    [glcfgDefinitionConstants.GUI_ADDRESSES_HTTPS]: {
        hlcfgPath: 'system.guiAddresses.https',
        title: <Message message={`packetFilter:staticRef.${STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS}.title`} />,
        desc: <Message message={`packetFilter:staticRef.${STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS}.title`} />,
        category: '',
    },
    [glcfgDefinitionConstants.DHCPD]: {
        hlcfgPath: 'services.dhcpd',
        title: <Message message="widgets:Dhcpd.title" />,
        desc: <Message message="widgets:Dhcpd.desc" />,
        setter: ({ state, action }) =>
            setNormalizeIds({
                state, ...action
            }),
        category: glcfgDefinitionConstants.DHCPD,
    },
    [glcfgDefinitionConstants.VPN]: {
        hlcfgPath: 'services.vpn.openvpn',
        title: <Message message="widgets:Vpn.title" />,
        desc: <Message message="widgets:Vpn.desc" />,
        setter: ({ state, action, initValue }) =>
            setNormalizeIds({ state, ...action, initValue }),
        category: 'vpn',
    },
    [glcfgDefinitionConstants.IPSEC]: {
        hlcfgPath: 'services.vpn.ipsec',
        title: <Message message="widgets:Ipsec.title" />,
        desc: <Message message="widgets:Ipsec.desc" />,
        setter: ({ state, action, initValue }) =>
            setNormalizeIds({ state, ...action, initValue }),
        category: 'vpn',
    },
    [glcfgDefinitionConstants.QOS]: {
        hlcfgPath: 'network.trafficControl.queues',
        title: <Message message="widgets:QoS.title" />,
        desc: <Message message="widgets:QoS.desc" />,
        setter: ({ state, action, initValue }) =>
            setNormalizeIds({ state, ...action, initValue }),
        category: '',
    },
    [glcfgDefinitionConstants.SNMP_USERNAME]: {
        category: 'snmp',
        hlcfgPath: 'services.snmp.username',
        title: <Message message="widgets:Snmp.username.title" />
    },
    [glcfgDefinitionConstants.SNMP_RIGHTS]: {
        category: 'snmp',
        desc: <Message message="widgets:Snmp.rights.desc" />,
        displayValuesDesc: {
            RO: <Message message="widgets:Snmp.rights.options.ro.desc" />,
            RW: <Message message="widgets:Snmp.rights.options.rw.desc" />
        },
        displayValuesTitle: {
            RO: <Message message="widgets:Snmp.rights.options.ro.title" />,
            RW: <Message message="widgets:Snmp.rights.options.rw.title" />
        },
        hlcfgPath: 'services.snmp.rights',
        title: <Message message="widgets:Snmp.rights.title" />
    },
    [glcfgDefinitionConstants.SNMP_CIPHER]: {
        hlcfgPath: 'services.snmp.cipher',
        title: <Message message="widgets:Snmp.cipher.title" />,
        desc: <Message message="widgets:Snmp.cipher.desc" />,
        category: 'snmp',
        displayValuesDesc: {
            AES: <Message message="widgets:Snmp.cipher.options.aes.desc" />,
            DES: <Message message="widgets:Snmp.cipher.options.des.desc" />,
        },
        displayValuesTitle: {
            AES: <Message message="widgets:Snmp.cipher.options.aes.title" />,
            DES: <Message message="widgets:Snmp.cipher.options.des.title" />,
        },
    },
    [glcfgDefinitionConstants.SNMP_HASH]: {
        hlcfgPath: 'services.snmp.hash',
        title: <Message message="widgets:Snmp.hash.title" />,
        desc: <Message message="widgets:Snmp.hash.desc" />,
        category: 'snmp',
        displayValuesDesc: {
            SHA: <Message message="widgets:Snmp.hash.options.sha.desc" />,
            MD5: <Message message="widgets:Snmp.hash.options.md5.desc" />,
        },
        displayValuesTitle: {
            SHA: <Message message="widgets:Snmp.hash.options.sha.title" />,
            MD5: <Message message="widgets:Snmp.hash.options.md5.title" />,
        },
    },
    [glcfgDefinitionConstants.SNMP_AUTH_PASSWORD]: {
        hlcfgPath: 'services.snmp.authPassword',
        title: <Message message="widgets:Snmp.authPassword.title" />,
        category: 'snmp',

    },
    [glcfgDefinitionConstants.SNMP_ENC_PASSWORD]: {
        hlcfgPath: 'services.snmp.encPassword',
        title: <Message message="widgets:Snmp.encPassword.title" />,
        category: 'snmp',

    },
    [glcfgDefinitionConstants.SNMP_OFF]: {
        hlcfgPath: 'services.snmp.__off',
        title: <Message message="widgets:Snmp.enabled.title" />,
        desc: <Message message="widgets:Snmp.enabled.desc" />,
        category: 'snmp',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.off" />,
            undefined: <Message message="widgets:global.checkbox.on" />,
        },
    },
    [glcfgDefinitionConstants.SNMP_ADDRESSES]: {
        hlcfgPath: 'services.snmp.addresses',
        title: <Message message="widgets:Snmp.addresses.title" />,
        desc: <Message message="widgets:Snmp.addresses.desc" />,
        category: 'snmp',
    },
    [glcfgDefinitionConstants.ADAPTIVE_FIREWALL]: {
        hlcfgPath: 'protection.adaptiveFirewall',
        title: <Message message="widgets:SuricataCfg.enabled.title" />,
        desc: <Message message="widgets:SuricataCfg.enabled.desc" />,
        category: 'suricata',
        setter: ({ state, action }) => setObjectValue({ state, ...action }),
    },
    [glcfgDefinitionConstants.SURICATA_IPS_MODE]: {
        hlcfgPath: 'protection.suricata.service.ipsMode',
        title: <Message message="widgets:SuricataCfg.mode.title" />,
        desc: <Message message="widgets:SuricataCfg.mode.desc" />,
        category: 'suricata',
        displayValuesTitle: {
            true: <Message message="widgets:SuricataCfg.mode.values.IPS" />,
            false: <Message message="widgets:SuricataCfg.mode.values.IDS" />
        },
    },
    [glcfgDefinitionConstants.SURICATA_AUTO_UPDATE]: {
        hlcfgPath: 'protection.suricata.service.autoUpdate',
        title: <Message message="widgets:SuricataCfg.autoUpdate.title" />,
        desc: <Message message="widgets:SuricataCfg.autoUpdate.desc" />,
        category: 'suricata',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.SURICATA_BETA_SERVER_ENABLED]: {
        hlcfgPath: 'protection.suricata.updates.betaServer',
        title: <Message message="widgets:SuricataCfg.betaServer.title" />,
        category: 'suricata',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.SURICATA_BLACKLIST]: {
        hlcfgPath: 'protection.suricata.service.blacklist',
        title: <Message message="widgets:SuricataCfg.blacklist.title" />,
        desc: <Message message="widgets:SuricataCfg.blacklist.desc" />,
        category: 'suricata',
    },
    [glcfgDefinitionConstants.SURICATA_WHITELIST]: {
        hlcfgPath: 'protection.suricata.service.whitelist',
        title: <Message message="widgets:SuricataCfg.whitelist.title" />,
        desc: <Message message="widgets:SuricataCfg.whitelist.desc" />,
        category: 'suricata',
    },
    [glcfgDefinitionConstants.SURICATA_BLACKLIST_COMMENT]: {
        hlcfgPath: 'protection.suricata.service.blacklistComment',
        title: <Message message="widgets:SuricataCfg.blacklistComment.title" />,
        desc: <Message message="widgets:SuricataCfg.blacklistComment.desc" />,
        category: 'suricata',
    },
    [glcfgDefinitionConstants.SURICATA_WHITELIST_COMMENT]: {
        hlcfgPath: 'protection.suricata.service.whitelistComment',
        title: <Message message="widgets:SuricataCfg.whitelistComment.title" />,
        desc: <Message message="widgets:SuricataCfg.whitelistComment.desc" />,
        category: 'suricata',
    },
    [glcfgDefinitionConstants.SURICATA_VARIABLES]: {
        hlcfgPath: 'protection.suricata.service.variables',
        title: <Message message="widgets:Suricata.variables.title" />,
        desc: <Message message="widgets:Suricata.variables.desc" />,
        category: 'suricataVariables',
        setter: ({ state, action }) => setSuricataVariable({ state, ...action }),
    },
    [glcfgDefinitionConstants.SURICATA_INTERFACES]: {
        category: 'suricata',
        desc: <Message message="widgets:Suricata.interfaces.desc" />,
        displayValuesTitle: {
            '$isExternal_ipv4_address': <Message message="widgets:global.allExternal.address" />,
            '$isExternal_ipv4_network': <Message message="widgets:global.allExternal.network" />,
            '$isInternal_ipv4_address': <Message message="widgets:global.allInternal.address" />,
            '$isInternal_ipv4_network': <Message message="widgets:global.allInternal.network" />
        },
        hlcfgPath: 'protection.suricata.service.interfaces',
        title: <Message message="widgets:Suricata.interfaces.title" />
    },
    [glcfgDefinitionConstants.INTERFACES]: {
        hlcfgPath: 'interfaces',
        setter: ({ state, action }) =>
            setNormalizeIds({ state, ...action }),
        title: <Message message="widgets:SystemComponents.NetworkInterfacesTable.columns.name.title" />,
        category: 'networkInterfaces',
    },
    [glcfgDefinitionConstants.IPV6_ENABLED]: {
        hlcfgPath: 'network.ipv6Enabled',
        title: <Message message="widgets:ipv6Enabled.title" />,
        category: 'networkInterfaces',
        displayValuesTitle: {
            true: <Message message="widgets:global.checkbox.on" />,
            false: <Message message="widgets:global.checkbox.off" />,
        },
    },
    [glcfgDefinitionConstants.PACKET_FILTER_RULES]: {
        hlcfgPath: 'protection.nftables.rules',
        setter: ({ state, action }) => setNormalizeIds({ state, ...action }),
        title: <Message message="widgets:SystemComponents.PacketRules.title" />,
        category: 'packetFilterRules',
    },
    [glcfgDefinitionConstants.ROUTING_TABLE]: {
        hlcfgPath: 'network.routingTable',
        setter: ({ state, action }) => setNormalizeIds({ state, ...action }),
        title: <Message message="widgets:Network.routingTable.title" />,
        category: 'routingTable',
    },
    [glcfgDefinitionConstants.PROXY]: {
        hlcfgPath: 'protection.proxy',
        title: <Message message="widgets:Proxy.title" />,
        category: 'proxy',
        setter: ({ state, action }) => setProxyValue({ state, ...action }),
    },
    [glcfgDefinitionConstants.ANTIVIRUS]: {
        hlcfgPath: 'protection.antivirus',
        title: <Message message="widgets:Antivirus.title" />,
        category: glcfgDefinitionConstants.ANTIVIRUS,
        setter: ({ state, action }) => setObjectValue({ state, ...action }),
    },
    [glcfgDefinitionConstants.UPGRADE]: {
        hlcfgPath: 'upgrade',
        title: <Message message="widgets:upgrade.title" />,
        desc: <Message message="widgets:upgrade.desc" />,
        category: 'upgrade',
        setter: ({ state, action }) => setObjectValue({ state, ...action }),
    },
    [glcfgDefinitionConstants.PROFILES]: {
        hlcfgPath: 'protection.policy.profiles',
        title: <Message message="widgets:Profiles.title" />,
        category: 'profiles',
        setter: ({ state, action }) =>
            setNormalizeIds({ state, ...action }),
    },
    [glcfgDefinitionConstants.WAF_PROFILES]: {
        hlcfgPath: 'protection.policy.wafProfiles',
        title: <Message message="widgets:wafProfile.title" />,
        category: 'wafProfiles',
        setter: ({ state, action }) =>
            setNormalizeIds({ state, ...action }),
    },
    [glcfgDefinitionConstants.DNS_PROXY_PROFILES]: {
        hlcfgPath: 'protection.dnsProxy.profiles',
        title: <Message message="widgets:dnsProxyProfile.title" />,
        category: 'dnsProxyProfiles',
        setter: ({ state, action }) =>
            setNormalizeIds({ state, ...action }),
    },
    [glcfgDefinitionConstants.PROFILE_RULES]: {
        hlcfgPath: 'protection.policy.profilesRules',
        title: <Message message="widgets:Profiles.title" />,
        category: 'profiles',
        setter: ({ state, action }) =>
            setNormalizeIds({ state, ...action }),
    },
    [glcfgDefinitionConstants.DOMAIN_CONTROLLERS]: {
        hlcfgPath: 'services.authentication.domainControllers',
        title: <Message message="widgets:Authentication.domainControllers.title" />,
        desc: <Message message="widgets:Authentication.domainControllers.desc" />,
        category: 'authentication',
    },
    [glcfgDefinitionConstants.REALM]: {
        hlcfgPath: 'services.authentication.realm',
        title: <Message message="widgets:Authentication.realm.title" />,
        desc: <Message message="widgets:Authentication.realm.desc" />,
        category: 'authentication',
    },
    [glcfgDefinitionConstants.SECURITY_ADMIN]: {
        hlcfgPath: 'system.securityAdmin',
        title: <Message message="widgets:Authentication.securityAdmin.title" />,
        category: 'securityAdmin',
    },
    [glcfgDefinitionConstants.HOSTS]: {
        hlcfgPath: 'system.hosts',
        title: <Message message="widgets:Hosts.host" />,
        category: 'hosts',
        setter: ({ state, action }) => setNormalizeIds({ state, ...action }),
    },
    [glcfgDefinitionConstants.IS_CLUSTER]: {
        hlcfgPath: 'system.isCluster',
        category: '',
    },
    [glcfgDefinitionConstants.CLUSTER_STEP_A]: {
        hlcfgPath: 'system.clusterStepA',
        category: '',
    },
    [glcfgDefinitionConstants.CLUSTER_STEP_B]: {
        hlcfgPath: 'system.clusterStepB',
        category: '',
    },
    [glcfgDefinitionConstants.NAMED_OBJECTS_NETADDR_VECTOR]: {
        hlcfgPath: 'namedObjects.netaddrVector',
        title: <Message message="widgets:NamedObjects.netaddrVector.title" />,
        category: 'namedObjectNetaddrVector',
        setter: ({ state, action }) =>
            setNormalizeIds({ state, ...action }),
    },
    [glcfgDefinitionConstants.NAMED_OBJECTS_NETADDR_SCALAR]: {
        hlcfgPath: 'namedObjects.netaddrScalar',
        title: <Message message="widgets:NamedObjects.netaddrScalar.title" />,
        category: 'namedObjectNetaddrScalar',
        setter: ({ state, action }) =>
            setNormalizeIds({ state, ...action }),
    },
    [glcfgDefinitionConstants.DASHBOARDS]: {
        hlcfgPath: 'dashboards',
        title: <Message message="widgets:dashboards.title" />,
        category: '',
        setter: ({ state, action }) => setObjectValue({ state, ...action }),
    },
    //tables
    [glcfgDefinitionConstants.TABLES]: {
        hlcfgPath: 'tables',
        setter: ({ state, action }) => setNormalizeTable({ state, ...action }),
        title: <Message message="widgets:tables.title" />,
        category: '',
    }
};

const setNormalizeIds =  ({ state, newUuid, action, value,
    addingAfter, uuidToAddBefore
}) => produce(state, nextState => {
    switch (action) {
    case 'add':
    case 'delete':
    case 'copy':
        return addOrDeleteBeforeNormalize({
            array: nextState,
            uuidToAddBefore: uuidToAddBefore,
            value: newUuid || value,
            addingAfter
        });
    case 'order':
        return value;
    default: throw new Error('Unknown value of action: ' + action);
    }
});


const setObjectWithNormalize =  ({ state, newUuid, action, uuid, value,
    addingAfter, key, subkey,
}) => produce(state, nextState => {
    switch (action) {
    case 'add':
    case 'delete':
    case 'copy':
        nextState[key] = addOrDeleteBeforeNormalize({
            array: nextState[key],
            uuidToAddBefore: uuid,
            value: newUuid,
            addingAfter,
        });
        break;
    case 'setParameters':
        return setParent({ nextState, value, key, subkey });
    case 'order':
        return nextState[key] = value;
    default: throw new Error('Unknown value of action: ' + action);
    }
});

const setNormalizeTable = ({ state, value, key, action, uuid, newUuid, newObject, subkey, subsubkey,
    hwParams, isCluster, myNode, ipv6, array, addingAfter, uuidToAddBefore, reverse, extraValues, names,
    nameMaxLength, emptyArrayAsUndefined
}) => produce(state, nextState => {
    switch (action) {
    case 'add':
        add({
            nextState,
            newObject,
        });
        break;
    case 'setParameters':
        return set({ nextState, value, key, uuid, subkey, subsubkey, array, addingAfter, uuidToAddBefore,
            emptyArrayAsUndefined });
    case 'update': //special case for interfaces
        return updateInterface({ nextState, hwParams, uuid, isCluster, myNode, ipv6 });
    case 'copy':
        return copy({ nextState, uuid, newUuid, reverse, extraValues, names, nameMaxLength });
    default: throw new Error('Unknown value of action: ' + action);
    }
});

const setProxyValue = ({ state, value, key, subkey, action, subsubkey }) =>
    produce(state, nextState => {
        switch (action) {
        case 'setWpadExceptions':
            setValue(nextState, [ 'wpad', 'exceptions', key ], value);
            break;
        case 'setTlsClienFiles':
            setValue(nextState, [ 'tls', 'client', 'generateCertificate', key ], value);
            break;
        case 'setWpadAddress':
            setValue(nextState, [ 'wpad', 'addresses' ], value);
            break;
        case 'objectChange': {
            const pathToSet = [ key, subkey, subsubkey ].filter(notEmpty);
            setValue(nextState, pathToSet, value);
            break;
        }
        default: throw new Error('Unknown value of action: ' + action);
        }
    });


const setObjectValue = ({ state, value, key, subkey, subsubkey }) => produce(state, nextState => {
    const pathToSet = [ key, subkey, subsubkey ].filter(notEmpty);
    if (!nextState) {
        const returnState = {};
        setValue(returnState, pathToSet, value);
        return returnState;
    }

    setValue(nextState, pathToSet, value);
});

const setSuricataVariable = ({ state, port, name, value }) => {
    const group = `${port ? 'port' : 'address'}Groups`;
    return {
        ...state,
        [group]: {
            ...state[group],
            [name]: value
        }
    };
};

export default glcfgDefinition;
