/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import {
    MDBBtn } from 'mdbreact';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Overlay, Icon, Cards } from '~frontendComponents/Generic/index.js';
import { getDhcpdIds, getDhcpdProps, addToNormalize,
    getDhcpdTypes, deleteNormalize, setNormalize, setNormalizeOrder,
    duplicateFromNormalize } from '~frontendDucks/hlcfgEditor/index.js';
import { DHCP_RELAY_TYPE, DHCP_SERVER_TYPE } from '~sharedConstants/index.ts';
import { getActiveCard } from '~frontendDucks/activeCards/index.js';
import { setDataRequest } from '~frontendDucks/dhcpd/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { DELETE_CONFIRM, DHCPD } from '~frontendConstants/index.js';
import TurnedOff from '~frontendComponents/TurnedOff/index.js';


import DhcpdService from '../DhcpdService/index.ts';


@withTranslation()
@connect(
    (state) => ({
        ids: getDhcpdIds(state),
        dhcpdProps: getDhcpdProps(state),
        types: getDhcpdTypes(state),
        activeCard: getActiveCard(state, DHCPD)
    }),
    {
        add: addToNormalize,
        setValue: setNormalize,
        setOrder: setNormalizeOrder,
        request: setDataRequest,
        doSetModal: setModalState,
        del: deleteNormalize,
        copy: duplicateFromNormalize,

    }
)
class DHCPScene extends Component {
    static get propTypes() {
        return {
            ids: PropTypes.array,
            t: PropTypes.func,
            add: PropTypes.func,
            setValue: PropTypes.func,
            dhcpdProps: PropTypes.object,
            setOrder: PropTypes.func,
            activeCard: PropTypes.string,
            request: PropTypes.func,
            copy: PropTypes.func,
            doSetModal: PropTypes.func,
            types: PropTypes.array,
            del: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            shownSettings: false,
            shownRules: false,
        };
    }

    componentDidMount() {
        const { request } = this.props;
        request();
    }

    addDhcpServer = () => {
        const { add, types, activeCard } = this.props;
        add({
            type: types[0],
            typeId: DHCPD,
            uuidToAddBefore: activeCard,
            successText: `widgets:Dhcpd.${DHCP_SERVER_TYPE}.added`,
            extraValues: {
                type: DHCP_SERVER_TYPE
            }
        });
    };

    addDhcpRelay = () => {
        const { add, types, activeCard } = this.props;
        add({
            type: types[1],
            typeId: DHCPD,
            uuidToAddBefore: activeCard,
            successText: `widgets:Dhcpd.${DHCP_RELAY_TYPE}.added`,
            extraValues: {
                type: DHCP_RELAY_TYPE
            }
        });
    };

    deleteService = ({ uuid }) => {
        const {  doSetModal, dhcpdProps } = this.props;
        doSetModal({ modal: DELETE_CONFIRM, value: true, specialValues: {
            uuid,
            action: this.delete,
            service: 'Dhcpd',
            name: dhcpdProps[uuid].name } });
    };

    delete = ({ uuid }) => {
        const { del } = this.props;
        del({ type: DHCPD, value: uuid });
    };

    copyService = ({ uuid }) => {
        const { copy } = this.props;
        copy({
            uuid: uuid,
            parent: DHCPD
        });
    };

    changeValue = ({ value, uuid, key, name }) => {
        const { setValue, activeCard } = this.props;
        setValue({ type: DHCPD, uuid: uuid || activeCard, value, key: key || name });
    };

    setOrder = ({ order }) => {
        const { setOrder } = this.props;
        setOrder({ type: DHCPD, value: order });
    };


    render() {
        const { t, ids,
            dhcpdProps, activeCard } = this.props;
        return (
            <>
                <div className="userNavigation">
                    <MDBBtn
                        className="navigation__button pulse"
                        onClick={this.addDhcpServer}
                        size="sm"
                        type="button"
                    >
                        <Icon name="plus" />
                        {t('widgets:Dhcpd.type.server')}
                    </MDBBtn>
                    <MDBBtn
                        className="navigation__button ml-1 pulse"
                        onClick={this.addDhcpRelay}
                        size="sm"
                        type="button"
                    >
                        <Icon name="plus" />
                        {t('widgets:Dhcpd.type.relay')}
                    </MDBBtn>
                </div>
                <div className={classNames(
                    { 'withOverlay': !ids?.length }
                )}
                >
                    <Cards
                        copyService={this.copyService}
                        deleteService={this.deleteService}
                        ids={ids}
                        menuItemProps={dhcpdProps}
                        setOrder={this.setOrder}
                        setValue={this.changeValue}
                        type="dhcpd"
                        wrapClassName="vpn__scene"
                    />

                    <div
                        className="overlay--div"

                    >
                        <DhcpdService uuid={activeCard} />
                        <TurnedOff
                            disabled={dhcpdProps[activeCard]?.__off}
                            onChange={this.changeValue}
                        />
                    </div>
                    {!ids?.length &&
                            <Overlay
                                active={!ids?.length}
                            >
                                <div className="dhcpd__overlay">
                                    <h2>{t('widgets:Dhcpd.title')}</h2>
                                    <p className="mb-2">
                                        {t('widgets:Dhcpd.desc')}
                                    </p>
                                    <p className="dataTableWidget__Rowtext">
                                        {t('widgets:Dhcpd.server.desc1')}
                                        <MDBBtn
                                            className="navigation__button"
                                            onClick={this.addDhcpServer}
                                            size="sm"
                                            type="button"
                                        >
                                            <Icon name="plus" />
                                            {t('widgets:Dhcpd.type.server')}
                                        </MDBBtn>
                                        {t('widgets:Dhcpd.server.desc2')}
                                        {t('widgets:Dhcpd.relay.desc1')}
                                        <MDBBtn
                                            className="navigation__button ml-1"
                                            onClick={this.addDhcpRelay}
                                            size="sm"
                                            type="button"
                                        >
                                            <Icon name="plus" />
                                            {t('widgets:Dhcpd.type.relay')}
                                        </MDBBtn>
                                    </p>
                                    <MDBBtn
                                        className="navigation__button"
                                        onClick={this.addDhcpServer}
                                        size="sm"
                                        type="button"
                                    >
                                        <Icon name="plus" />
                                        {t('widgets:Dhcpd.type.server')}
                                    </MDBBtn>
                                    <MDBBtn
                                        className="navigation__button ml-1"
                                        onClick={this.addDhcpRelay}
                                        size="sm"
                                        type="button"
                                    >
                                        <Icon name="plus" />
                                        {t('widgets:Dhcpd.type.relay')}
                                    </MDBBtn>

                                </div>
                            </Overlay>}
                </div>
            </>
        );
    }
}

export default DHCPScene;
