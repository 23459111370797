/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCardBody, MDBBtn, MDBCard, MDBCardTitle } from 'mdbreact';

import {
    getQoSService,
    setNormalize,
    addToNormalize,
    getQoS,
    getTableSchema,
    getLowestPossibleClassId,
} from '~frontendDucks/hlcfgEditor/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { Input } from '~frontendComponents/Generic/index.js';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice/index.js';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';
import { xToGlcfg } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersUtils.ts';


@withTranslation()
@connect(
    (state, { withName, uuid }) => ({
        data: withName ? getQoS(state, uuid) : getQoSService(state),
        schema: getTableSchema(state, uuid),
        lowestClassId: getLowestPossibleClassId(state)

    }),
    {
        setValue: setNormalize,
        doSetModal: setModalState,
        add: addToNormalize,
    }
)
class QoSRoot extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object,
            uuid: PropTypes.string,
            setValue: PropTypes.func,
            add: PropTypes.func,
            t: PropTypes.func,
            withName: PropTypes.bool,
            schema: PropTypes.object,
            lowestClassId: PropTypes.number,
        };
    }


    addNode = () => {
        const { data, schema, add, lowestClassId } = this.props;
        add({
            key: 'rootNodeId',
            type: xToGlcfg(schema.rootNodeId)[0],
            uuid: data.id,
            successText: 'widgets:QoS.added',
            extraValues: {
                ceilBandwidthMbit: data.ceilBandwidthMbit,
                classId: lowestClassId,
            },
            array: false,
        });
    };


    changeValue = ({ value, name  }) => {
        const { setValue, uuid } = this.props;
        setValue({ value, uuid, key: name, });
    };

    render() {
        const { data, t, withName, schema
        } = this.props;

        const {
            interfaceId, rootNodeId, color, id, name } = data;
        return (
            <MDBCard
                className="treeChart__card"
                style={
                    color ? {
                        boxShadow: `0 3px 6px ${color}`
                    } : {}
                }
            >
                {withName &&
                <MDBCardTitle className="cardHide__title">
                    <div className="flex-center">

                        <Input
                            className="treeChart__card__title"
                            disabled
                            id={'name' + id}
                            name="name"
                            onChange={this.changeValue}
                            schema={schema?.name}
                            value={name}
                            withoutBorder
                            withoutPaddingLeft
                        />
                    </div>

                </MDBCardTitle>}
                <MDBCardBody id="panning-center">
                    <NetworkInterfaceDevice
                        disabled={withName}
                        exceptions={[ 'bridge' ]}
                        id="interfacceId"
                        iface={stringifyAddress(interfaceId)}
                        name={'interfaceId'}
                        onChange={this.changeValue}
                        paste={false}
                        schema={schema.interfaceId}
                    />
                    {!rootNodeId && !withName &&
                        <MDBBtn
                            className="treeChart__addButton"
                            color="green"
                            onClick={this.addNode}
                        >
                            {t('widgets:QoS.node')}
                        </MDBBtn>
                    }
                </MDBCardBody>
            </MDBCard>

        );
    }
}

export default QoSRoot;
