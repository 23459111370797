/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Scene } from '~frontendComponents/Scene/index.js';

import  SnmpScene from './components/SnmpScene/SnmpScene.js';


const Snmp = () => (
    <Scene>
        <SnmpScene />
    </Scene>
);

export default Snmp;
