/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardHeader } from 'mdbreact';

import { Switch } from '~frontendComponents/Generic/index.js';
import {
    getGlcfgValue,
    setGlcfgValueScalar,
} from '~frontendDucks/hlcfgEditor/index.js';
import Message from '~frontendComponents/Message/index.js';
import glcfgDefinition from '~frontendLib/glcfgDefinition/index.js';
import { AK_EVENTS_FEEDBACK_ENABLED, CLOUD_BACKUP_FEEDBACK_ENABLED,
    CONFIGURATION_FEEDBACK_ENABLED } from '~frontendRoot/constants/index.js';


export const schema = {
    'events': [
        AK_EVENTS_FEEDBACK_ENABLED,
        'systemStatusFeedbackEnabled',
        'reporterFeedbackEnabled'
    ],

    'configuration': [
        // 'configurationFeedbackEnabled',
        'cloudBackupFeedbackEnabled'
    ],


    'errors': [ 'errorsFeedbackEnabled' ]
};

const FeedbackSwitch = ({ values, switchKey, onChange }) => (
    <Switch
        align="spaceBetween"
        checked={values[switchKey]}
        id={switchKey}
        label={glcfgDefinition[switchKey].title}
        labledTooltipText={glcfgDefinition[switchKey].desc}
        name={switchKey}
        onChange={onChange}
    />
);

FeedbackSwitch.propTypes = {
    values: PropTypes.object,
    switchKey: PropTypes.string,
    onChange: PropTypes.func,
};


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            values: {
                akEventsFeedbackEnabled: getGlcfgValue(state, AK_EVENTS_FEEDBACK_ENABLED),
                systemStatusFeedbackEnabled: getGlcfgValue(state, 'systemStatusFeedbackEnabled'),
                configurationFeedbackEnabled: getGlcfgValue(state, CONFIGURATION_FEEDBACK_ENABLED),
                cloudBackupFeedbackEnabled: getGlcfgValue(state, CLOUD_BACKUP_FEEDBACK_ENABLED),
                reporterFeedbackEnabled: getGlcfgValue(state, 'reporterFeedbackEnabled'),
                errorsFeedbackEnabled: getGlcfgValue(state, 'errorsFeedbackEnabled'),
            }
        };
    };
    return mapStateToProps;
};
@connect(
    makeMapStateToProps,
    {
        setValue: setGlcfgValueScalar,
    }
)
class FeedbackService extends Component {
    static get propTypes() {
        return {
            setValue: PropTypes.func,
            values: PropTypes.object,

        };
    }

    changeValue = ({ value, name,  }) => {
        const { setValue } = this.props;
        setValue(name, value);
    };

    render() {
        const {
            values
        } = this.props;
        return (
            <MDBRow>
                <MDBCol
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <MDBCard className="pt-0">
                        <MDBCardHeader>
                            <Message message="widgets:Feedback.title" />
                        </MDBCardHeader>
                        <MDBCardBody>
                            {Object.keys(schema).map(item => (
                                <React.Fragment key={item}>
                                    <h3><Message message={`widgets:Feedback.header.${item}`} /></h3>
                                    <MDBRow>
                                        {schema[item].map(
                                            switchKey => (
                                                <MDBCol
                                                    key={switchKey}
                                                    size="4"
                                                >
                                                    <FeedbackSwitch
                                                        onChange={this.changeValue}
                                                        switchKey={switchKey}
                                                        values={values}
                                                    />
                                                </MDBCol>
                                            )
                                        )}
                                    </MDBRow>
                                </React.Fragment>
                            ))}
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        );
    }
}

export default FeedbackService;
