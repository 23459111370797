/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { createContext, useCallback } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MDBCardTitle, MDBCard, } from 'mdbreact';
import classNames from 'classnames';

import { Icon, SelectColumns, InputSearch } from '~frontendComponents/Generic/index.js';
import { getWafProfileRulesIds, setNormalize } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import DatatableWidget from '~frontendWidgets/DatatableWidget/index.ts';
import Message from '~frontendComponents/Message/index.js';
import {
    NEW_ROW_CONSTANT,
    SELECTABLE_TABLE_WAF_PROFILE_RULES,
    WAF_PROFILES
} from '~frontendConstants/index.js';
import { getActiveCardHook } from '~frontendDucks/activeCards/index.js';
import { useBoolean, useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { HlcfgTableItem } from '~frontendRoot/types/externalTypes.ts';

import Row from '../Row/index.ts';


export const DatatableContext = createContext({ search: '' } as {
    search: string
});

const CreateRowProps = {
    dataIndex: PropTypes.number.isRequired,
    spacing: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
};

const CreateRow = ({ dataIndex, uuid, spacing }: InferProps<typeof CreateRowProps>) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid || 'addingRow'}
            spacing={spacing}
            uuid={uuid}
        />
    );
};

CreateRow.propTypes = CreateRowProps;


const ProfileRules = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const ids = useSelector<any, HlcfgTableItem<'wafProfile'>['rules']>(getWafProfileRulesIds);

    const [ hide, setHide ] = useBoolean();
    const [ searchValue, setSearchValue ] = useString();

    const active = useSelector(getActiveCardHook(WAF_PROFILES));


    const passReorderData = useCallback((value) => {
        if (!value.includes(NEW_ROW_CONSTANT)) {
            dispatch(setNormalize({
                uuid: active,
                key: 'rules',
                value
            }));
        }
    }, [ active, dispatch ]);
    return (
        <MDBCard className={classNames('profile__card')}>
            <MDBCardTitle className={classNames(
                'profiles__title',
                { 'profiles__title--noBorderBottom': !hide },
            )}
            >
                <div> {t('profile:waf.rules.title')}
                    <Icon
                        name={hide ? 'chevron-down' : 'chevron-up'}
                        onClick={setHide.swap}
                    />
                </div>
                <div className="profiles__titleActions">

                    {!hide &&
                 <>
                     <SelectColumns id="wafProfileRules" />
                     <InputSearch
                         className="mb-3 mt-0"
                         id="searchValueIDVlans"
                         search={searchValue}
                         setter={setSearchValue}
                     />
                 </>
                    }
                </div>
            </MDBCardTitle>
            <div className={classNames(
                'profile__body',
                { 'profile__body--hide': hide },
                { 'profiles__title--borderBottom': !hide }
            )}
            >
                <DatatableContext.Provider value={{ search: searchValue }}>
                    <DatatableWidget
                        columnsId={SELECTABLE_TABLE_WAF_PROFILE_RULES}
                        createRow={CreateRow}
                        data={ids?.length === 1 ? [ NEW_ROW_CONSTANT, ...ids ] : ids}
                        passReorderData={passReorderData}
                    />
                </DatatableContext.Provider>
            </div>
        </MDBCard>

    );
};


export default ProfileRules;


export const selectOptions = (value) => [
    { value: 'allow',
        label: (
            <div className="policy__selectIcon">
                <Icon
                    className="policy__icon icon--green"
                    name="checkbox-marked-circle"
                    size="sm"
                />
                <span className="pl-2"><Message message={`profile:allow.${value}`} /></span>
            </div>) },
    { value: 'bypass',
        label: (
            <div className="policy__selectIcon">
                <Icon
                    className="policy__icon icon--primary"
                    name="alert-circle"
                    size="sm"
                />
                <span className="pl-2"><Message message={`profile:bypass.${value}`} /></span>
            </div>) },
    { value: 'block',
        label: (
            <div className="policy__selectIcon">
                <Icon
                    className="policy__icon icon--red"
                    name="close-circle"
                    size="sm"
                />
                <span className="pl-2"><Message message={`profile:block.${value}`} /></span>
            </div>) }
];
