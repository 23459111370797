/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, Checkbox } from '~frontendComponents/Generic/index.js';
import { getIsCluster, getOffInterfacesCount } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { getShowHidden, setShowHidden } from '~frontendDucks/interfaces/index.js';


const Footer = () => {
    const { t } = useTranslation();
    const isCluster = useSelector(getIsCluster);
    const dispatch = useDispatch();
    const setCheckboxValue = useCallback(({ value }) => {
        dispatch(setShowHidden(value));
    }, [ dispatch ]);
    const showHidden = useSelector(getShowHidden);
    const hiddenCount = useSelector(getOffInterfacesCount);

    return (
        <tfoot className="dataTableWidgetFooterRow dataTableWidgetFooterRow__custom">
            <tr className="dataTableWidgetFooterRow__state">
                <td className="dataTableWidgetFooterRow__stateItem">
                    <Icon
                        className="icon--green"
                        name="circle"
                        size="sx"
                    />
                    <span className="m-1">
                        {t('widgets:Interfaces.state.online')}
                    </span>
                </td>
                <td className="dataTableWidgetFooterRow__stateItem">
                    <Icon
                        className="icon--red"
                        name="circle"
                        size="sx"
                    />
                    <span className="m-1">
                        {t('widgets:Interfaces.state.disable')}
                    </span>
                </td>
                {isCluster &&
                <td className="dataTableWidgetFooterRow__stateItem">
                    <Icon
                        className="icon--primary"
                        name="circle"
                        size="sx"
                    />
                    <span className="m-1">
                        {t('widgets:Interfaces.state.clusterError')}
                    </span>
                </td>}
                <td className="dataTableWidgetFooterRow__stateItem">
                    <Icon
                        name="ethernet-cable-off"
                        size="sx"
                    />
                    <span className="m-1">
                        {t('widgets:Interfaces.state.disconnected')}
                    </span>
                </td>
                <td className="dataTableWidgetFooterRow__stateItem">
                    <Icon
                        className="dataTableWidgetFooterRow__stateItem--green"
                        name="ethernet"
                        size="sx"
                    />
                    <span className="m-1">
                        {t('widgets:Interfaces.state.connected')}
                    </span>
                </td>
                <td className="dataTableWidgetFooterRow__stateItem">

                    <Checkbox
                        checked={showHidden}
                        className="mt-0 mb-0"
                        id="showHidden"
                        label={t('widgets:Interfaces.showHidden', { hiddenCount })}
                        name="showHidden"
                        onChange={setCheckboxValue}
                    />
                </td>
            </tr>
        </tfoot>
    );
};

export default Footer;
