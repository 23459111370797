/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Icon } from '~frontendComponents/Generic/index.js';
import { saveChosenRuleUuid } from '~frontendDucks/packetFilterRules/index.js';
import { getWhereProfileIsUsed } from '~frontendDucks/hlcfgEditor/index.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/TextWithTooltip.js';
import { PROTECTION_PACKET_FILTER_SCENE_PATH } from '~frontendScenes/Protection/scenes/constants.js';


const makeMapStateToProps = () => {
    const getProfileUsage = getWhereProfileIsUsed();
    const mapStateToProps = (state, { profile }) => {
        return {
            usedIn: getProfileUsage(state, profile),
        };
    };
    return mapStateToProps;
};
@withTranslation()
@connect(
    makeMapStateToProps,
    {
        doSaveChosenRuleUuid: saveChosenRuleUuid,
    }
)
class ProfileUsage extends Component {
    static get propTypes() {
        return {
            profile: PropTypes.string,
            t: PropTypes.func,
            usedIn: PropTypes.array,
            doSaveChosenRuleUuid: PropTypes.func,
            openUsage: PropTypes.bool,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            shownSettings: false,
            shownRules: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { profile, openUsage } = this.props;
        if (profile !== prevProps.profile) {
            this.setState({
                shownRules: false
            });
        }
        if (openUsage && openUsage !== prevProps.openUsage) {
            this.setState({
                shownRules: true
            });
        }
    }


    hideRules = () => {
        const { shownRules } = this.state;
        this.setState({
            shownRules: !shownRules
        });
    };

    render() {
        const { t,  usedIn, doSaveChosenRuleUuid } = this.props;
        const { shownRules } = this.state;
        return (
            <MDBCard>
                <MDBCardTitle  className={classNames(
                    'profiles__title',
                    { 'profiles__title--noBorderBottom': shownRules },
                )}
                >
                    <div className="position-relative">
                        {t('profile:usedIn.header')}
                        <Icon
                            name={shownRules ? 'chevron-up' : 'chevron-down'}
                            onClick={() => this.setState({
                                shownRules: !shownRules
                            })}
                        />
                        {
                            usedIn?.length ?
                                <TextWithTooltip
                                    className="profiles__number"
                                    datacy="usageInRule"
                                    tooltipPlace="left"
                                    tooltipText={'profile:usedIn.usage'}
                                >
                                    {usedIn?.length}
                                </TextWithTooltip> :
                                null
                        }

                    </div>
                </MDBCardTitle>
                <MDBCardBody
                    className={classNames('profile__body',
                        { 'profile__body--hide': !shownRules })}
                >
                    {t('profile:usedIn.title')}
                    <div className="profile__pfRules">
                        {(usedIn || []).map(item => (
                            <div
                                className="profile__pfRules--rule"
                                key={item.id}
                                onClick={() => {
                                    doSaveChosenRuleUuid(item.id);

                                }}
                            >
                                <Link
                                    to={{
                                        pathname: PROTECTION_PACKET_FILTER_SCENE_PATH,
                                        state: {
                                            openFromLink: true
                                        } }}
                                >
                                    {item.name}
                                </Link>
                            </div>
                        ))}
                    </div>
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default ProfileUsage;
