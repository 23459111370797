/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import classNames from 'classnames';

import Message from '~frontendComponents/Message/index.js';


export default class Number extends Component {
    static get propTypes() {
        return {
            label: PropTypes.string.isRequired,
            className: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
            color: PropTypes.oneOf([ 'red', 'green', 'black' ]),
            //md is default
            size: PropTypes.oneOf([ 'sm', 'lg' ]),
            vertical: PropTypes.bool,
        };
    }

    render() {

        const { label, value, color, size, vertical, className } = this.props;

        return (

            <div className={classNames(className, {
                'number--ver': vertical },
            { [`number--${size}`]: size
            })}
            >
                <div className="number__label">
                    <Message message={label} />
                </div>
                <div className={classNames({
                    'number__value': true },
                { [`number--${color}`]: color
                })}
                >
                    {value}
                </div>
            </div>

        );
    }
}
