/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { renderQrCode } from '~frontendLib/renderQrCode.ts';

import { Icon } from './Generic/index.js';
import Message from './Message/index.js';
import { DownloadLink } from './DownloadLink.tsx';


const propTypes = {
    url: PropTypes.string.isRequired,
    scale: PropTypes.number, // determines how many pixels of canvas per pixel of QR code
    filename: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired
};

/**
 * @see {@link renderQrCode}
 */
export const QrCodeImage = ({ url, scale, filename, loading }: PropTypes.InferProps<typeof propTypes>) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [ href, setHref ] = useState('');

    useEffect(() => {
        if (!canvasRef.current) {
            throw new Error('Failed to get canvas ref');
        }
        renderQrCode(canvasRef.current, url, scale).then(() => {
            setHref(canvasRef?.current?.toDataURL() || '');
        }).catch(
            // not expecting this to fail (it never did even during development) - so just console.error
            err => console.error(err) // eslint-disable-line no-console
        );
    }, [ scale, url ]);

    return (
        <div className="flex-column align-items-center d-flex">
            <p className="infobox p-0"><Message message="widgets:Vpn.qr.desc" /></p>
            {loading && <Icon name="loading" />}
            <canvas ref={canvasRef} />
            <DownloadLink
                filename={filename}
                link={href}
                loading={loading}
            />
        </div>
    );
};

QrCodeImage.propTypes = propTypes;
