/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDBRow, MDBCol } from 'mdbreact';

import { DEFAULT_GW } from '~frontendRoot/constants/index.js';

import { Gateway } from '../Gateway.tsx';
import { GatewayMultihoming } from '../GatewayMultihoming.tsx';


class Multihoming extends Component {
    static get propTypes() {
        return {
            multihoming: PropTypes.bool,
            multihomingGateways: PropTypes.array,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            update: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { multihoming } = this.props;
        if (multihoming !== prevProps.multihoming) {
            this.setState({
                update: true
            });
        }
    }

    render() {
        const { multihoming, multihomingGateways } = this.props;
        const { update } = this.state;
        const Tag = multihoming ? MDBRow : React.Fragment;
        const TagCol = multihoming ? MDBCol : React.Fragment;

        return (
            <Tag>
                <TagCol>
                    <Gateway
                        type={DEFAULT_GW}
                    />
                </TagCol>

                {(multihomingGateways || []).map((item, index) => (
                    <MDBCol
                        className={!multihoming && 'displayNone'}
                        key={item}
                    >
                        <GatewayMultihoming
                            index={index}
                            key={item}
                            type={item}
                            update={update}

                        />
                    </MDBCol>
                ))}
            </Tag>
        );
    }
}

export default Multihoming;
