/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Service from '~frontendComponents/Service/index.ts';
import { Scene } from '~frontendComponents/Scene/index.js';
import moment, { TIME_FORMAT } from '~commonLib/moment.ts';
import { getCurrentServerTimestamp } from '~frontendDucks/currentTime/currentTime.js';
import { useHlcfgOffableValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';

import { Ntp } from './components/index.js';


const NtpScene = () => {
    const { t } = useTranslation();
    const currentServerTimestamp = useSelector(getCurrentServerTimestamp);
    const ntp = useHlcfgOffableValue(it => it.services.ntpdClient);
    return (
        <Scene>
            <Service
                id="Ntp"
                on={ntp.isOn}
                showService={(<Ntp />)}
                turnOff={ntp.setOff}
                turnOn={ntp.setOn}
            >
                <p className="pt-3 mb-0">
                    {t('widgets:Ntp.time.title')}

                </p>
                <h4 className="mb-4">
                    {moment(currentServerTimestamp).format(TIME_FORMAT.userDateTimeFull)}
                </h4>
            </Service>
        </Scene>
    );
};

export default NtpScene;
