/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useEffect } from 'react';
import { MDBCard, MDBBtn } from 'mdbreact';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import assert from 'assert';
import Tippy from '@tippyjs/react';

import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';

import Icon from './Generic/Icon/Icon.tsx';
import ColorInput from './ColorInput.tsx';


export const RowMenuButtonProps = {
    color: PropTypes.string,
    colorFunc: PropTypes.func,
    deleteFunc: PropTypes.func,
    copyFunc: PropTypes.func,
    deleteButtonDisabled: PropTypes.bool,
    popupOnRight: PropTypes.bool,
    small: PropTypes.bool,
};

export type RowMenuButtonType = InferProps<typeof RowMenuButtonProps>;


const EmptyWrapper = ({ children }) => children;
const RowMenuButton = ({ colorFunc, deleteFunc, copyFunc, color, deleteButtonDisabled,
    popupOnRight = false, small = false }: RowMenuButtonType) => {
    const [ menuIsOpen, setMenuIsOpen ] = useBoolean();
    // Hacking because copy can be really long operation. ( +700ms ) so we want to have some protection against
    // multiple click, not very effective but its better then nothing. Real solution would be to have faster
    // rendering
    const [ hacking, setHacking ] = useBoolean();

    const [ deleteWasClickedOnce, setDeleteWasClickedOnce ] = useBoolean();
    const { t } = useTranslation();

    useEffect(() => {
        if (hacking) {
            assert(copyFunc);
            copyFunc();
            setHacking.off();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ hacking ]);

    const deleteButton = useCallback(event => {
        event.preventDefault();
        assert(deleteFunc);
        if (deleteWasClickedOnce) {
            deleteFunc();
        }
        else {
            setDeleteWasClickedOnce.on();
        }
    }, [ deleteFunc, setDeleteWasClickedOnce, deleteWasClickedOnce ]);


    const copyButton = useCallback(event => {
        if (!hacking) {
            event.preventDefault();
            setHacking.on();
            setMenuIsOpen.off();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ setHacking, setMenuIsOpen ]);

    const TippyWrap = menuIsOpen ? Tippy : EmptyWrapper;

    return (
        <TippyWrap
            allowHTML={true}
            appendTo={document.body}
            content={(
                <div>
                    <MDBCard className="packetFilter__menu">
                        {colorFunc && color &&
                            <div className="packetFilter__menuButtonPopup">
                                <Icon name="format-color-fill" />
                                <ColorInput
                                    __off={Boolean(deleteButtonDisabled)}
                                    callback={setMenuIsOpen.on}
                                    onChange={colorFunc}
                                    value={color}
                                />
                            </div>
                        }
                        {copyFunc &&
                             <MDBBtn
                                 className="packetFilter__menuButtonPopup
                                                        packetFilter__menuButtonPopup--copy"
                                 onClick={copyButton}
                                 outline
                             >
                                 <Icon
                                     name="content-copy"
                                     size="sm"
                                 />
                                 {t('widgets:global.copy')}
                             </MDBBtn>
                        }
                        {deleteFunc &&
                            <MDBBtn
                                className="packetFilter__menuButtonPopup"
                                disabled={Boolean(deleteButtonDisabled)}
                                onClick={deleteButton}
                                outline
                            >
                                <Icon
                                    className={classNames(
                                        { 'packetFilter__delete--open': deleteWasClickedOnce }
                                    )}

                                    name="trash-can-outline"
                                />
                                <span>{deleteWasClickedOnce ? t('widgets:global.reallyDelete') :
                                    t('widgets:global.delete')}
                                </span>
                            </MDBBtn>
                        }
                    </MDBCard>
                </div>
            )}
            interactive={true}
            onClickOutside={setMenuIsOpen.off}
            placement={popupOnRight ? 'right' : 'left'}
            visible={menuIsOpen}
        >
            <span>
                <MDBBtn
                    className={classNames(
                        { ['packetFilter__menuButton--small']: small },
                        'packetFilter__menuButton'
                    )}
                    onClick={setMenuIsOpen.swap}
                    outline
                >
                    <Icon
                        className={small ? 'icon menu--small' : ''}
                        name="menu"
                    />
                </MDBBtn>
            </span>
        </TippyWrap>
    );
};

export default RowMenuButton;
