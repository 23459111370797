/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import {  MDBRow, MDBCol } from 'mdbreact';
import { useSelector } from 'react-redux';

import { getIsCluster } from '~frontendRoot/ducks/hlcfgEditor/index.js';

import { HardwareController, Cluster, License, Timezone, Backup, DbReplicationStatus } from './components/index.js';


const Machine = () => {

    const isCluster = useSelector(getIsCluster);

    return (
        <MDBRow>
            <MDBCol
                className="mb-4"
            >
                <HardwareController />
            </MDBCol>
            <MDBCol
                className="mb-4"
                lg="9"
            >
                <Cluster />
            </MDBCol>
            <MDBCol
                className="mb-4"
            >
                <License />
            </MDBCol>
            {isCluster ?
                <MDBCol
                    className="mb-4"
                >
                    <DbReplicationStatus />
                </MDBCol> : null}
            <MDBCol
                className="mb-4"
            >
                <Timezone />
            </MDBCol>
            <MDBCol
                className="mb-4"
            >
                <Backup />
            </MDBCol>
        </MDBRow>
    );
};

export default Machine;
