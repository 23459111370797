/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Droppable } from '@hello-pangea/dnd';
import React, { useEffect, useRef } from 'react';

/**
 * This is Droppable element from @hello-pangea/dnd that is hacked to improve performance.
 * It heavily relies on knowledge of @hello-pangea/dnd internals.
 * The original sets styles to <head> outside of react.
 * This causes all styles to be recalculated which causes noticeable lag spike.
 * This hack hooks into DOM and modified the relevant style element that causes this.
 * It catches the edit, prevents it, and instead ensures the correct style in its own way.
 * The own way is by having overlay element which sets the cursor to dragging, when dragging,
 * and by having some static styles that set grab cursor and transition on Draggables.
 */
export const DroppableHacked: typeof Droppable = (props) => {
    const droppableHtmlElement = useRef<HTMLElement>();
    useEffect(() => {
        if (!droppableHtmlElement.current) {
            throw new Error('Droppable element not bound');
        }
        const id = droppableHtmlElement.current.getAttribute('data-rfd-droppable-context-id');
        ensureGlobalStyleEl();
        const testOverlay = getOverlay();
        const head = document.querySelector('head');
        if (!head) {
            throw new Error('Head not found');
        }
        const styleEl = head.querySelector(`style[data-rfd-dynamic="${id}"]`);
        Object.defineProperty(styleEl, 'textContent', {
            set: function(value) {
                if (value.includes('pointer-events: none')) {
                    testOverlay.classList.add('rbdHackDragging');
                } else {
                    testOverlay.classList.remove('rbdHackDragging');
                }
            },
            configurable: true,
        });
    }, [ droppableHtmlElement ]);
    return (
        <Droppable {...props}>
            {(provided, snapshot) => {
                const { innerRef, ...otherProvided } = provided;
                const newInnerRef = (element: HTMLElement) => {
                    droppableHtmlElement.current = element;
                    innerRef(element);
                };
                const newProvided = { innerRef: newInnerRef, ...otherProvided };
                // eslint-disable-next-line react/prop-types
                return props.children(newProvided, snapshot);
            }}
        </Droppable>
    );
};

const getOverlay = () => {
    const body = document.body;
    const existingEl = body.querySelector(':scope > div#rbdHackOverlay');
    if (existingEl) {
        return existingEl;
    }
    const el = document.createElement('div');
    el.setAttribute('id', 'rbdHackOverlay');
    el.classList.add('rbdHackOverlay');
    body.prepend(el);
    return el;
};
const ensureGlobalStyleEl = () => {
    const head = document.querySelector('head');
    if (!head) {
        throw new Error('Head not found');
    }
    const globalStyleEl = head.querySelector('style#rbdHackGlobalStyle');
    if (globalStyleEl) {
        return;
    }

    const el = document.createElement('style');

    el.setAttribute('id', 'rbdHackGlobalStyle');
    el.textContent = `
.rbdHackDragging.rbdHackDragging {
    pointer-events: all;
    cursor: grabbing;
    z-index: 5001;
}
.rbdHackOverlay {
    pointer-events: none;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
[data-rfd-draggable-context-id] {
    transition: transform 0.2s cubic-bezier(0.2, 0, 0, 1);
}
[data-rfd-drag-handle-context-id] {
    cursor: -webkit-grab;
    cursor: grab;
}
`;
    head.append(el);
};
