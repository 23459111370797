/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBRow, MDBCol, } from 'mdbreact';
import { Component } from 'react';

import { Scene } from '~frontendComponents/Scene/index.js';

import { Hosts, Hostname } from './components/index.js';
import { Resolver } from './components/Resolver.tsx';
import { Dnssec } from './components/Dnssec.tsx';


class Dns extends Component {

    render() {
        return (
            <Scene>
                <MDBRow>
                    <MDBCol
                        className="mb-2 mb-lg-4 mb-md-2"
                    >
                        <Hostname />
                    </MDBCol>
                    <MDBCol
                        className="mb-2 mb-lg-4 mb-md-2"
                    >
                        <Resolver />
                    </MDBCol>
                    <MDBCol className="mb-2 mb-lg-4 mb-md-2">
                        <Dnssec />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol
                        className="mb-2 mb-lg-4 mb-md-auto"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <Hosts />
                    </MDBCol>
                </MDBRow>
            </Scene>
        );
    }
}

export default Dns;
