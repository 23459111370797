/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Cards, Icon, Overlay, Switch } from '~frontendComponents/Generic/index.js';
import {
    duplicateFromNormalize,
    addToNormalize,
    setNormalize,
    setNormalizeOrder,
    getHlcfgRowUuid,
    deleteNormalize,
    getDnsProxyProfilesIds,
    getDnsProxyProfileProps } from '~frontendDucks/hlcfgEditor/index.js';
import { getActiveCardHook } from '~frontendDucks/activeCards/index.js';
import { DELETE_CONFIRM, DNS_PROXY_PROFILES, MEDIUM_SIZE, SMALL_SIZE, userSetting } from '~frontendConstants/index.js';
import { setModalState } from '~frontendDucks/modals/modals.js';
import TurnedOff from '~frontendComponents/TurnedOff/index.js';
import { useUserSettingToggleable } from '~frontendLib/hooks/userSettings.ts';
import { getDnsProxyActiveProfile } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { HlcfgTableItem } from '~frontendRoot/types/externalTypes.ts';

import DnsProfileSettings from './components/DnsProxySettings/index.ts';
import ProfileRules from './components/DnsProxyRules/index.ts';


const Profiles = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const activeProfile = useSelector(getActiveCardHook(DNS_PROXY_PROFILES));
    const profiles = useSelector(getDnsProxyProfilesIds);
    const profile = useSelector(getDnsProxyActiveProfile);
    const profileProps: HlcfgTableItem<'dnsProxyProfile'> = useSelector(getDnsProxyProfileProps);
    const [ hyperCompactModeEnabled, toggleCompactMode ] =
        useUserSettingToggleable(userSetting.hyperCompactDnsProxyTable);
    //Row tables, tables and other components are using this prop as class editor,
    //eg. `dataTableWidget__cell--${spacing}`
    const spacing = hyperCompactModeEnabled ? SMALL_SIZE : MEDIUM_SIZE;

    const setProfileParameters = useCallback(({ value, key }) => {
        dispatch(setNormalize({ uuid: activeProfile, value, key }));
    }, [ dispatch, activeProfile ]);

    const addProfile = useCallback(() => {
        const newUuid = getHlcfgRowUuid(DNS_PROXY_PROFILES);
        dispatch(addToNormalize({
            type: DNS_PROXY_PROFILES,
            typeId: DNS_PROXY_PROFILES,
            newUuid,
        }));
    }, [ dispatch ]);

    const deleteFunc = useCallback(({ uuid }) => {
        dispatch(deleteNormalize({ type: DNS_PROXY_PROFILES, value: uuid }));
    }, [ dispatch ]);

    const copyService = useCallback(({ uuid }) => {
        dispatch(duplicateFromNormalize({
            uuid,
            parent: DNS_PROXY_PROFILES
        }));
    }, [ dispatch ]);

    const deleteProfile = useCallback(({ uuid }) => {
        dispatch(setModalState({ value: true, modal: DELETE_CONFIRM, specialValues: {
            uuid, name: profile?.name,
            action: deleteFunc, service: DNS_PROXY_PROFILES
        } }));
    }, [ dispatch, deleteFunc, profile ]);

    const setOrder = useCallback(({ order }) => {
        dispatch(setNormalizeOrder({ type: DNS_PROXY_PROFILES, value: order }));
    }, [ dispatch ]);

    const turnOffProfile = ({ value, uuid }) => {
        dispatch(setNormalize({ uuid: uuid || activeProfile, value, key: '__off' }));
    };

    return (
        <>
            <div className="userNavigation">
                <Switch
                    align="spaceBetween"
                    bothActive
                    checked={spacing === SMALL_SIZE}
                    className="m-0 h-2 mr-1"
                    id="spacing"
                    inputStyle
                    isMessage
                    labelClassName="h-2 mt-0 mb-0"
                    messageOff={t('packetFilter:spacing.small')}
                    messageOn={t('packetFilter:spacing.normal')}
                    mini
                    onChange={toggleCompactMode}
                    switchClassName="h-2 hmi-2"
                    tooltipText={t('packetFilter:spacing.title')}
                />
                <MDBBtn
                    className="navigation__button"
                    id="addProfile"
                    onClick={addProfile}
                    size="sm"
                    type="button"
                >
                    <Icon name="plus" />
                    {t(`widgets:${DNS_PROXY_PROFILES}.title`)}
                </MDBBtn>
            </div>
            <div>
                <Cards
                    copyService={copyService}
                    deleteService={deleteProfile}
                    ids={profiles}
                    menuItemProps={profileProps}
                    setOrder={setOrder}
                    setValue={setProfileParameters}
                    type={DNS_PROXY_PROFILES}
                    wrapClassName="vpn__scene"
                />

                <div className="overlay--div">
                    <MDBRow>
                        <MDBCol
                            className={classNames('profile__profile')}
                        >
                            <MDBRow>
                                <MDBCol size="12">
                                    <DnsProfileSettings profileId={activeProfile} />
                                </MDBCol>
                                <MDBCol size="12">
                                    <ProfileRules
                                        activeProfile={activeProfile}
                                        spacing={spacing}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <TurnedOff
                        disabled={profileProps[activeProfile]?.__off}
                        onChange={turnOffProfile}
                    />
                </div>
                {!profiles.length ? (
                    <Overlay
                        active={!profiles.length}
                    >
                        <div className="dhcpd__overlay">
                            <h2>{t(`widgets:${DNS_PROXY_PROFILES}.title`)}</h2>
                            <p className="mb-2">
                                {t(`widgets:${DNS_PROXY_PROFILES}.desc`)}
                            </p>
                            <p className="dataTableWidget__Rowtext">
                                {t(`widgets:${DNS_PROXY_PROFILES}.desc1`)}
                                <MDBBtn
                                    className="navigation__button ml-1"
                                    onClick={addProfile}
                                    size="sm"
                                    type="button"
                                >
                                    <Icon name="plus" />
                                    {t(`widgets:${DNS_PROXY_PROFILES}.title`)}
                                </MDBBtn>

                            </p>
                            <MDBBtn
                                className="navigation__button ml-1"
                                onClick={addProfile}
                                size="sm"
                                type="button"
                            >
                                <Icon name="plus" />
                                {t(`widgets:${DNS_PROXY_PROFILES}.title`)}
                            </MDBBtn>
                        </div>
                    </Overlay>) :
                    null}
            </div>
        </>
    );
};

export default Profiles;
