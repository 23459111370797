/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBCard, MDBCardTitle, MDBRow, MDBCol, MDBCardBody } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import assert from 'assert';

import Message from '~frontendComponents/Message/index.js';
import { PROXY } from '~frontendRoot/constants/index.js';
import { getGlcfgSchemaHook, getGlcfgValueHook, setGlcfgObjectValue } from '~frontendDucks/hlcfgEditor/index.js';
import { HlcfgSchemaJSON } from '~frontendTypes/externalTypes.ts';
import Slider from '~frontendRoot/components/Slider/Slider.tsx';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { HlcfgInputTree } from '~sharedLib/HlcfgInputTree.ts';


const ProxyLogSelect = () => {
    const data: HlcfgInputTree['protection']['proxy'] = useSelector(getGlcfgValueHook(PROXY));
    assert(data, 'We expect that proxy configuration always exists');
    const schema: HlcfgSchemaJSON['properties']['protection']['properties']['proxy'] =
        useSelector(getGlcfgSchemaHook(PROXY));
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onChange = ({ value, name, id }) => {
        dispatch(setGlcfgObjectValue(PROXY, value, name, 'operation', id));
    };
    const localSchema = schema.properties.log.properties.operation.properties.level;
    const value = data.log?.operation?.level || localSchema[DEFAULT_SCHEMA_VALUE];
    return (
        <>
            <Slider
                id="level"
                label={<Message message={'differs:protection.proxy.log.operation.level.title'} />}
                maxValue={localSchema.enum.length - 1}
                minValue={0}
                name="log"
                onChange={onChange}
                schema={localSchema}
                value={data.log?.operation?.level}
            />
            {value && value !== 'off' && value !== 'full' ?  (
                <>
                    <h5>{t(`help:validInputs.enum.${value}.title`)}</h5>
                    <p>
                        {t(`help:validInputs.enum.${value}.desc`)}
                    </p>
                </>
            ) : null}

        </>
    );

};

const ProxyLog = () => {
    return (
        <MDBCard>
            <MDBCardTitle>
                <Message message="proxy:proxy.log.title" />
            </MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >

                        <ProxyLogSelect />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default ProxyLog;
