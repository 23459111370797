/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import getValue from 'get-value';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {  MDBCardBody, MDBCardHeader, MDBCard, MDBCol } from 'mdbreact';

import {  ApiError } from '~frontendComponents/Generic/index.js';
import {
    typeReportUsage, typeReportDefinition, typeChartType, typeColumnsInfo, typeDashboardDefinition,
    typeApiError, typeRefreshResult, typeLanguage, typeReporterTemplates,
} from '~frontendTypes/index.js';
import {
    getChartType, getDashboardDefinition, getFrozenReportDefinition, getFrozenReportUsage, getIsReportRefreshing,
    getRefreshError, getRefreshResult, getReportDatabaseName, getReportDefinition, getReportDesc, getReportId,
    getReportTitle, getReporterTemplates,
} from '~frontendDucks/reporterEntities/index.js';
import { getSelectedLanguage } from '~frontendDucks/language/index.js';
import { getColumnsInfo } from '~frontendDucks/reporterDbStructure/index.js';
import Message from '~frontendRoot/components/Message/index.js';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/index.js';
import { getInterfacesNamesFromInit } from '~frontendRoot/ducks/hlcfgEditor/index.js';

import Chart from '../../../../../components/Chart/Chart.js';


const valueInAdditionalParameters = (activeReportDefinition, node) => {
    return getValue(
        activeReportDefinition,
        'charts.0.config.additionalParameters.' + node
    );
};
/*
Maybe I will use it in the future
const isTitleCentered = (activeReportDefinition) => {
    return valueInAdditionalParameters(activeReportDefinition, 'chartNameCentered') ? true : undefined;
};
*/

const showTitle = (activeReportDefinition) => {
    return valueInAdditionalParameters(activeReportDefinition, 'showTitle') === false ? false : true;
};


@connect(
    (state, { dashboardId, reportUsage }) => {
        const reportId = getReportId(reportUsage);
        const selectedLanguage = getSelectedLanguage(state);
        const reportDefinition = getReportDefinition(state, reportId);
        const databaseName = getReportDatabaseName(reportDefinition);
        const extraData = {};
        extraData['interfaces'] = getInterfacesNamesFromInit(state);
        return {
            activeReportDefinition: reportDefinition,
            activeReportUsage: reportUsage,
            chartType: getChartType(state, reportId, reportUsage),
            columnsInfo: getColumnsInfo(state, databaseName),
            dashboardDefinition: getDashboardDefinition(state, dashboardId),
            error: getRefreshError(reportUsage),
            frozenReportDefinition: getFrozenReportDefinition(reportUsage),
            frozenReportUsage: getFrozenReportUsage(reportUsage),
            isLoading: getIsReportRefreshing(reportUsage),
            refreshResult: getRefreshResult(reportUsage),
            reportTitle: getReportTitle(state, reportId, selectedLanguage),
            reportDesc: getReportDesc(state, reportId, selectedLanguage),
            reporterTemplates: getReporterTemplates(reportDefinition),
            selectedLanguage: selectedLanguage,
            reportId: reportId,
            extraData: extraData
        };
    },
    {
    }
)
class ReportObject extends Component {
    render() {
        const {
            activeReportDefinition, activeReportUsage, chartType, columnsInfo, dashboardDefinition, error,
            frozenReportDefinition, frozenReportUsage, isLoading, refreshResult, reportTitle, reportDesc,
            reporterTemplates,
            selectedLanguage, reportId, extraData, type, col, title, withoutCol, titleColor
        } = this.props;
        const OnAdvanced = valueInAdditionalParameters(activeReportDefinition, 'whenAdvanced') ?
            WhenAdvanced :
            React.Fragment;
        const ColType = withoutCol ?
            'div' :
            MDBCol;
        return (
            <OnAdvanced
                key={reportId}
            >
                <ColType
                    className={classNames('graphCol',
                        { 'graphCol--right': valueInAdditionalParameters(activeReportDefinition, 'right') },
                        valueInAdditionalParameters(activeReportDefinition, 'className'))}
                    lg={col || valueInAdditionalParameters(activeReportDefinition, 'col')}
                    md={col || valueInAdditionalParameters(activeReportDefinition, 'col')}
                    sm={col || valueInAdditionalParameters(activeReportDefinition, 'col')}

                >
                    {(title || valueInAdditionalParameters(activeReportDefinition, 'traffic')) &&
                     <h3 className="p-1">{title || <Message message="charts:traffic.inLastTwoWeeks" />}
                     </h3>}
                    {titleColor &&
                     <div
                         className={titleColor && 'network__rowCell--deviceColor--charts'}
                         style={
                             titleColor  ? {
                                 backgroundColor: titleColor,
                             } : {}
                         }
                     />}
                    <MDBCard
                        className="graphCard"
                        desc={reportDesc}
                    >
                        {showTitle(activeReportDefinition) ?
                            <MDBCardHeader className="graphCardHeader">
                                {reportTitle}
                            </MDBCardHeader> :
                            null}
                        <MDBCardBody className={classNames(
                            'graphCardBody',
                            { 'graphCardBody--error': error },
                            { 'pr-0 pl-0': withoutCol }
                        )}
                        >
                            <div className={dashboardDefinition.className}>
                                <ApiError
                                    data={true}
                                    error={error}
                                    isLoading={isLoading}
                                    messageOnError={'widgets:apiError.chart'}
                                >
                                    <Chart
                                        activeReportDefinition={activeReportDefinition}
                                        activeReportUsage={activeReportUsage}
                                        chartType={chartType}
                                        columnsInfo={columnsInfo}
                                        dashboardDefinition={dashboardDefinition}
                                        extraData={extraData}
                                        frozenReportDefinition={frozenReportDefinition}
                                        frozenReportUsage={frozenReportUsage}
                                        refreshResult={refreshResult}
                                        reporterTemplates={reporterTemplates}
                                        selectedLanguage={selectedLanguage}
                                        type={type}
                                    />
                                </ApiError>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </ColType>
            </OnAdvanced>
        );
    }
}


ReportObject.propTypes = {
    activeReportDefinition: typeReportDefinition,
    activeReportUsage: typeReportUsage,
    chartType: typeChartType,
    columnsInfo: typeColumnsInfo,
    dashboardDefinition: typeDashboardDefinition,
    error: typeApiError,
    frozenReportDefinition: typeReportDefinition,
    frozenReportUsage: typeReportUsage,
    isLoading: PropTypes.bool,
    refreshResult: typeRefreshResult,
    reportTitle: PropTypes.node,
    reportDesc: PropTypes.node,
    reporterTemplates: typeReporterTemplates,
    selectedLanguage: typeLanguage,
    reportId: PropTypes.string,
    type: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
    extraData: PropTypes.object,
    col: PropTypes.number,
    title: PropTypes.string,
    withoutCol: PropTypes.bool,
    titleColor: PropTypes.string,
};


export default ReportObject;
