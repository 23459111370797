/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { makeStringConstsObj } from '~commonLib/objectUtils.ts';


export const UPGRADE_DISPLAY_TYPE = makeStringConstsObj([
    'version', 'newerVersion', 'notFound',
    'discarded', 'loading', 'failedInfo'
]);
export const UPGRADE_BUTTON_TYPE = makeStringConstsObj([
    'tryAgain', 'tryNow', 'prepare', 'error', 'upgrade', 'preparing', 'downloading',
    'empty', 'errorTryAgain', 'confirming',
]);
