/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardTitle, MDBBtnGroup, MDBBtn } from 'mdbreact';
import classNames from 'classnames';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { debounce } from 'throttle-debounce';

import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { Switch, Select, Input, Icon } from '~frontendComponents/Generic/index.js';
import {
    getTableSchema,
    getIsVpnFromInit,
    setNormalize,
    getIsTreeDirty,
    getHlcfgPath
} from '~frontendDucks/hlcfgEditor/index.js';
import { getVpnServiceGetterByUuid } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import {
    DEFAULT_KEEPALIVE_INTERVAL, DEFAULT_KEEPALIVE_TIMEOUT, VPN_SETTING_MENU_ADVANCED,
    VPN_SETTING_MENU_FILES, VPN_SETTING_MENU_ROUTE,
    VPN_ROUTES_TYPE_SETTINGS, VPN_CRL_VERIFY_FILE_TYPE,
    VPN_CRL_VERIFY_URL_TYPE,
    RESET_HASH_MODAL,
    ALL_SPACES_REGEX,
    VPN_CLIENT_ROUTES_TYPE_SETTINGS,
    BRIDGE_IFACE_TYPE,
    VPN_IFACE_TYPE
} from '~frontendConstants/index.js';
import Divider from '~frontendRoot/components/Divider/index.js';
import Message from '~frontendComponents/Message/index.js';
import { uploadFileRequest } from '~frontendDucks/uploadFile/index.js';
import { isUploadLocator } from '~sharedLib/resourceLocatorUtils.ts';
import { setModalState } from '~frontendDucks/modals/index.js';
import { generateTlsAuthRequest } from '~frontendDucks/openvpn/index.js';
import { setTimeoutComponentFunction, setTimeoutComponentValidator } from '~frontendLib/timeUtils.ts';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import { getOpenVPNCfgDownloadFilename } from '~sharedLib/uncategorizedUtils.ts';
import { hlcfgRowIdIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import { crlUrlTestRequest, getIsLoading, getError, isData, getTestedVpn, resetErrorCrl } from '~frontendDucks/crlUrlTest/index.js';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';

import VpnRouteTable from '../VpnRouteTable/index.js';
import { VpnSwitch, CreateMenu, VpnOptionButton, GetSelectWithOptions, VpnInput, VpnInputWithCol } from '../vpnUtils.tsx';
import { VpnRoutingTableContext } from '../VpnRouteTable/Row/index.ts';


const makeMapStateToProps = () => {
    const vpnServiceUuid = getVpnServiceGetterByUuid();
    const isVpnFromInit = getIsVpnFromInit();

    const mapStateToProps = (state, { uuid }) => {
        return {
            hlcfgPath: getHlcfgPath(state, uuid),
            data: vpnServiceUuid(state, uuid),
            schemas: getTableSchema(state, uuid),
            isInit: isVpnFromInit(state, uuid),
            crlUrlTestIsLoading: getIsLoading(state),
            crlUrlTestError: getError(state),
            crlUrlTestIsData: isData(state),
            treeDirty: getIsTreeDirty(state),
            testedVpn: getTestedVpn(state),
        };
    };
    return mapStateToProps;
};
@withTranslation()
@connect(
    makeMapStateToProps,
    {
        setValue: setNormalize,
        uploadFile: uploadFileRequest,
        doSetModal: setModalState,
        generateTlsAuth: generateTlsAuthRequest,
        crlUrlTest: crlUrlTestRequest,
        resetError: resetErrorCrl

    }
)
class VpnSettings extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object,
            uuid: PropTypes.string,
            t: PropTypes.func,
            setValue: PropTypes.func,
            schemas: PropTypes.object,
            uploadFile: PropTypes.func,
            doSetModal: PropTypes.func,
            isInit: PropTypes.bool,
            generateTlsAuth: PropTypes.func,
            crlUrlTestIsLoading: PropTypes.bool,
            crlUrlTest: PropTypes.func,
            crlUrlTestIsData: PropTypes.bool,
            crlUrlTestError: PropTypes.string,
            treeDirty: PropTypes.bool,
            testedVpn: PropTypes.string,
            resetError: PropTypes.func,
            hlcfgPath: PropTypes.string,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            shownSettings: true,
            selected: isUploadLocator(props.data?.files?.pkcs) ||
                isUploadLocator(props.data?.files?.ca) ?
                VPN_SETTING_MENU_ROUTE :
                VPN_SETTING_MENU_FILES,

        };
        this.debouncedOnChangeColor = debounce(1000, this.changeValue);

    }


    changeRadioValue = ({ value, name, listId }) => {
        const { setValue, uuid } = this.props;
        setValue({ value: value === 'fullTunnelMode', uuid, key: name, subkey: listId });
    };

    changeValue = ({ value, name, listId }) => {
        const { setValue, uuid } = this.props;
        setValue({ value, uuid, key: name, subkey: listId, });
    };

    changeValueNcpCipher = ({ value, name }) => {
        const { setValue, uuid } = this.props;
        setValue({ value, uuid, key: name, emptyArrayAsUndefined: true });
    };

    changeValueDataCiphersFallback = ({ value, name, listId, }) => {
        const { setValue, uuid } = this.props;
        setValue({ value, uuid, key: name, listId, emptyArrayAsUndefined: true });
    };

    changeNumber = ({ value, name, listId }) => {
        const { setValue, uuid } = this.props;
        setValue({ value: value || undefined, uuid, key: name, subkey: listId });
    };

    changeObjectValue = ({ value, name, id }) => {
        const { setValue, uuid } = this.props;
        setValue({ value, uuid, key: name, subkey: id });
    };

    resetErrorAndChange = ({ value, name, id }) => {
        const { resetError } = this.props;
        resetError();
        this.changeObjectValue({ value, name, id });
    };
    changeObjectValueWithTrim = ({ value, name, id }) => {
        const { setValue, uuid } = this.props;
        setValue({ value: value.replace(ALL_SPACES_REGEX, ''), uuid, key: name, subkey: id });
    };

    changeCrlVerifyTypeValue = ({ value, name, id }) => {
        const { setValue, uuid } = this.props;
        setValue({
            value: value ? VPN_CRL_VERIFY_FILE_TYPE : VPN_CRL_VERIFY_URL_TYPE, uuid, key: name, subkey: id });
    };

    changeValueServerAddressAddr = ({ value, name, id }) => {
        const { setValue, uuid } = this.props;
        setValue({ uuid: uuid, key: name, value: value, subkey: id });
    };

    changeValueServerAddressPort = ({ value, name, id }) => {
        const { setValue, uuid } = this.props;
        setValue({ uuid: uuid, key: name, value: value, subkey: id });
    };

    changeValueNetAddress = ({ value, name }) => {
        const { setValue, uuid } = this.props;
        setValue({ uuid: uuid, key: name, value: value });
    };

    changeValueNetAddresses = ({ value, name, }) => {
        const { setValue, uuid } = this.props;
        setValue({ uuid: uuid, key: name, value: value });
    };

    setFile = (value, props) => {
        const { setValue, uuid } = this.props;
        setValue({ uuid, key: props[1], subkey: props[0], value });
    };

    uploadFile = ({ value, id, name }) => {
        const { setValue, uploadFile, uuid } = this.props;
        setValue({ uuid, key: name, subkey: id, value: undefined });
        if (value instanceof File) {
            uploadFile({ value, funcToCall: this.setFile, props: [ id, name ] });
        }
    };

    setGeneratedHash = (data) => {
        const { setValue, uuid } = this.props;
        if (data.generated) {
            setValue({ value: data.generated, uuid, key: 'tlsAuth', subkey: 'hash' });
        }
    };

    generateHash = () => {
        const { uuid, data, doSetModal, generateTlsAuth } = this.props;
        if (data.tlsAuth.hash) {
            doSetModal({ modal: RESET_HASH_MODAL, value: true, specialValues: { uuid } });

        } else {
            generateTlsAuth(this.setGeneratedHash);
        }
    };

    uploadAdvancedFile = ({ value, id, name }) => {
        const { setValue, uploadFile, uuid } = this.props;
        setValue({ uuid, key: id, subkey: name, value: undefined });
        if (value instanceof File) {
            uploadFile({ value, funcToCall: this.setFile, props: [ name, id ] });
        }
    };

    testCrlUrl = () => {
        const { uuid, crlUrlTest } = this.props;
        crlUrlTest(uuid);
    };


    hideSettings = () => {
        const { shownSettings } = this.state;
        this.setState({
            shownSettings: !shownSettings
        });
    };

    changeValueWanLan = ({ value }) => {
        const { setValue, uuid } = this.props;
        switch (value) {
        case 'wan': {
            setValue({ value: true, uuid, key: 'isExternal' });
            setValue({ type: 'vpn', value: false, uuid, key: 'isInternal' });
            break;
        }
        case 'lan': {
            setValue({ value: false, uuid, key: 'isExternal' });
            setValue({ value: true, uuid, key: 'isInternal' });
            break;
        }
        case 'wanLan': {
            setValue({ value: true, uuid, key: 'isExternal' });
            setValue({ value: true, uuid, key: 'isInternal' });
            break;
        }
        case 'unspecific': {
            setValue({ value: false, uuid, key: 'isExternal' });
            setValue({ value: false, uuid, key: 'isInternal' });
            break;
        }
        default: throw new Error('Unknown state');
        }
    };

    changeSelected = (value) => {
        const { selected } = this.state;
        if (selected === value) {
            this.setState({
                selected: '',
            });
        } else {
            this.setState({
                selected: value,
            });
        }
    };

    setTimeoutValue = (vals) => {
        const { setValue, uuid } = this.props;
        setValue({
            ...vals,
            uuid: uuid
        });
    };

    setRenegSec = ({ value, key }) => {
        const { setValue, uuid } = this.props;
        setValue({
            key,
            value,
            uuid: uuid
        });
    };

    setTimeout = setTimeoutComponentFunction({ setValue: this.setTimeoutValue, uuid: this.props.uuid, type: 'vpn',
        schema: this.props.schemas });

    setRenegSecTimeout = setTimeoutComponentFunction({ setValue: this.setRenegSec, uuid: this.props.uuid, type: 'vpn',
        schema: this.props.schemas });

    timeoutValidator = setTimeoutComponentValidator(this.props.t);

    render() {
        const { t, data, uuid,
            schemas, isInit, crlUrlTestIsLoading, crlUrlTestIsData, crlUrlTestError,
            treeDirty, testedVpn, hlcfgPath } = this.props;
        const { shownSettings, selected } = this.state;
        const {
            serverAddress, isTcp, __off,
            vpnAddress, keepalive, clientToClient, duplicateCn, compressMigrate,
            interfaceTopology, maxClients, routes, remoteCertTls, files, crlVerify, tlsAuth,
            isInternal, isExternal, routeNopull, dataCiphers, googleAuthEnabled,
            renegSec, authAlg, dataCiphersFallback
        } = data;
        const isRas = uuid && hlcfgRowIdIsFromTable(uuid, hlcfgTableName.openvpnRas);
        const isClient = uuid && hlcfgRowIdIsFromTable(uuid, hlcfgTableName.openvpnClient);
        return (
            <MDBCard className="card--withoutMH pb-0">
                <MDBCardTitle className={classNames(
                    'cardHide__title',
                    { 'cardHide__title--noBorderBottom': shownSettings },
                )}
                >
                    <div
                        className="clicable"
                        onClick={this.hideSettings}
                    >
                        {t('widgets:Vpn.header.settings')}
                        <Icon
                            name={shownSettings ? 'chevron-up' : 'chevron-down'}
                        />
                    </div>
                    <div>

                        <MDBCol className="profile__icons">
                            {isRas &&
                                <IconWithTooltip
                                    className="m-1"
                                    disabled={!isInit}
                                    download={isInit}
                                    iconSize="sm"
                                    link={`/api/download/${getOpenVPNCfgDownloadFilename(uuid || '')}`}
                                    name="download"
                                    tooltipText={t('widgets:Vpn.download.client')}
                                    withoutTranslation
                                />
                            }
                        </MDBCol>
                    </div>
                </MDBCardTitle>

                <MDBCardBody className={classNames('cardHide__body', 'pb-0',
                    { 'cardHide__body--hide': !shownSettings },
                    { 'cardHide__title--borderBottom': shownSettings })}
                >
                    <MDBRow>
                        <MDBCol>
                            <NetworkInterfaceDevice
                                disabled={__off}
                                exceptions={[ VPN_IFACE_TYPE, BRIDGE_IFACE_TYPE ]}
                                id={'addr'}
                                iface={serverAddress?.addr}
                                isCreatable
                                isMulti
                                label={t('widgets:Vpn.serverAddress.addr.title')}
                                message={isRas ? 'local' : 'remote'}
                                name="serverAddress"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={this.changeValueServerAddressAddr}
                                schema={schemas?.serverAddress?.properties.addr}
                            />

                        </MDBCol>
                        <MDBCol>
                            <Select
                                disabled={__off}
                                id="port"
                                isCreatable
                                isMulti
                                label={t('widgets:Vpn.serverAddress.port.title')}
                                message={isRas ? 'lport' : 'remote'}
                                name="serverAddress"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={this.changeValueServerAddressPort}
                                schema={schemas?.serverAddress?.properties.port}
                                value={serverAddress?.port}

                            />

                        </MDBCol>

                        <MDBCol>
                            <Switch
                                align="spaceBetween"
                                bothActive
                                checked={isTcp}
                                disabled={__off}
                                id="isTcp"
                                inputStyle
                                isMessage
                                label={t('widgets:Vpn.isTcp.title')}
                                message="proto"
                                messageOff={t('widgets:Vpn.isTcp.tcp.title')}
                                messageOn={t('widgets:Vpn.isTcp.udp.title')}
                                name="isTcp"
                                onChange={this.changeValue}
                                schema={schemas?.isTcp}

                            />
                        </MDBCol>

                        <MDBCol>
                            {isRas &&
                                <Select
                                    disabled={__off}
                                    id={'vpnAddress'}
                                    isCreatable
                                    isMulti
                                    label={t('widgets:Vpn.vpnAddress.title')}
                                    message="server"
                                    name="vpnAddress"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={this.changeValueNetAddress}
                                    schema={schemas.vpnAddress}
                                    value={vpnAddress}
                                />
                            }
                            {isClient &&
                                <Switch
                                    align="spaceBetween"
                                    checked={routeNopull}
                                    disabled={__off}
                                    id="routeNopull"
                                    label={t('widgets:Vpn.routeNopull.title')}
                                    message="route-nopull"
                                    name="routeNopull"
                                    onChange={this.changeValue}
                                    schema={schemas?.routeNopull}

                                />
                            }
                        </MDBCol>

                    </MDBRow>
                    <MDBRow
                        className="mt-3"
                    >
                        <MDBCol
                            className="justify-space-around"
                            size="12"
                        >
                            <ScrollMenu.default
                                alignCenter={false}
                                data={CreateMenu({ selected })}
                                dragging={false}
                                hideArrows={true}
                                hideSingleArrow={true}
                                itemClass="scrollMenu__wrapperItem"
                                onSelect={this.changeSelected}
                                selected={selected}
                                transition={1}
                            />
                            {selected && <Divider />}
                        </MDBCol>
                    </MDBRow>
                    {
                        //UPLOAD FILE
                    }
                    <MDBRow className={classNames('mt-1', 'cardHide__body',
                        { 'cardHide__body--hide': selected !== VPN_SETTING_MENU_FILES })}
                    >
                        <MDBCol
                            className="mt-2"
                            size="12"
                        >
                            <MDBRow>
                                <VpnInputWithCol
                                    __off={(isUploadLocator(files?.ca) ||
                                        isUploadLocator(files?.cert)  ||
                                        isUploadLocator(files?.key)) && !isUploadLocator(files?.pkcs)
                                    }
                                    func={this.uploadFile}
                                    id="pkcs"
                                    name="files"
                                    uuid={uuid}
                                    value={files?.pkcs}
                                />
                                <Divider
                                    text={t('widgets:global.or')}
                                    vertical
                                />
                                <VpnInputWithCol
                                    __off={isUploadLocator(files?.pkcs) && !isUploadLocator(files?.ca)}
                                    func={this.uploadFile}
                                    hlcfgPath={hlcfgPath}
                                    id="ca"
                                    name="files"
                                    uuid={uuid}
                                    value={files?.ca}
                                />
                                <VpnInputWithCol
                                    __off={isUploadLocator(files?.pkcs) && !isUploadLocator(files?.cert)}
                                    func={this.uploadFile}
                                    hlcfgPath={hlcfgPath}
                                    id="cert"
                                    name="files"
                                    uuid={uuid}
                                    value={files?.cert}
                                />
                                <VpnInputWithCol
                                    __off={isUploadLocator(files?.pkcs) && !isUploadLocator(files?.key)}
                                    func={this.uploadFile}
                                    hlcfgPath={hlcfgPath}
                                    id="key"
                                    name="files"
                                    uuid={uuid}
                                    value={files?.key}
                                />
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    {
                        // ADVANCED
                    }
                    <MDBRow className={classNames('mt-1', 'cardHide__body',
                        { 'cardHide__body--hide': selected !== VPN_SETTING_MENU_ADVANCED })}
                    >
                        <MDBCol size="12">
                            <MDBRow>
                                {isRas &&
                                    <>
                                        <VpnSwitch
                                            __off={__off}
                                            func={this.changeValue}
                                            id="clientToClient"
                                            message={'client-to-client'}
                                            schema={schemas.clientToClient}
                                            value={clientToClient}
                                        />
                                        <VpnSwitch
                                            __off={__off}
                                            func={this.changeValue}
                                            id="duplicateCn"
                                            message={'duplicate-cn'}
                                            schema={schemas.duplicateCn}
                                            value={duplicateCn}
                                        />
                                        <VpnSwitch
                                            __off={__off}
                                            func={this.changeValue}
                                            id="compressMigrate"
                                            message={'compress-migrate'}
                                            schema={schemas.compressMigrate}
                                            value={compressMigrate}
                                        />
                                    </>
                                }
                                <VpnSwitch
                                    __off={__off}
                                    func={this.changeValue}
                                    id="remoteCertTls"
                                    message={'remote-cert-tls'}
                                    schema={schemas.remoteCertTls}
                                    value={remoteCertTls}
                                />
                            </MDBRow>

                        </MDBCol>
                        <MDBCol size="12">
                            <MDBRow>
                                <MDBCol lg={isClient ? '4' : '3'}>
                                    <Input
                                        disabled={__off}
                                        id={'interval'}
                                        label={t('widgets:Vpn.keepalive.interval.title')}
                                        message="ping"
                                        name="keepalive"
                                        onChange={this.setTimeout}
                                        placeholder={DEFAULT_KEEPALIVE_INTERVAL}
                                        schema={schemas?.keepalive?.properties?.interval}
                                        validator={this.timeoutValidator}
                                        value={keepalive?.interval ?
                                            valueFormatter.formatSeconds(keepalive?.interval) : ''}
                                    />
                                </MDBCol>
                                <MDBCol lg={isClient ? '4' : '3'}>
                                    <Input
                                        disabled={__off}
                                        id={'timeout'}
                                        label={t('widgets:Vpn.keepalive.timeout.title')}
                                        message="ping-restart"
                                        name="keepalive"
                                        onChange={this.setTimeout}
                                        placeholder={DEFAULT_KEEPALIVE_TIMEOUT}
                                        schema={schemas?.keepalive?.properties?.timeout}
                                        validator={this.timeoutValidator}
                                        value={keepalive?.timeout ?
                                            valueFormatter.formatSeconds(keepalive?.timeout) : ''}
                                    />

                                </MDBCol>

                                {isClient ?
                                    <GetSelectWithOptions
                                        __off={__off}
                                        changeValue={this.changeValue}
                                        changeValueWanLan={this.changeValueWanLan}
                                        interfaceTopology={interfaceTopology}
                                        interfaceTopologySchema={schemas.interfaceTopology}
                                        isExternal={isExternal}
                                        isInternal={isInternal}
                                        t={t}
                                        uuid={uuid}
                                    /> :
                                    <>
                                        <VpnSwitch
                                            __off={__off}
                                            func={this.changeValue}
                                            id="googleAuthEnabled"
                                            size={googleAuthEnabled ? '3' : '6'}
                                            t={t}
                                            value={googleAuthEnabled}
                                        />
                                        {googleAuthEnabled &&
                                            <MDBCol lg="3">
                                                <Input
                                                    disabled={__off}
                                                    id={'renegSec'}
                                                    label={t('differs:tables.openvpnRas.renegSec')}
                                                    message="reneg-sec"
                                                    name="renegSec"
                                                    onChange={this.setRenegSecTimeout}
                                                    placeholder={
                                                        valueFormatter.formatSeconds(
                                                            schemas?.renegSec?.[DEFAULT_SCHEMA_VALUE]
                                                        )}
                                                    schema={schemas?.renegSec}
                                                    validator={this.timeoutValidator}
                                                    value={renegSec ?
                                                        valueFormatter.formatSeconds(renegSec) : ''}
                                                />
                                            </MDBCol>
                                        }
                                    </>
                                }
                            </MDBRow>
                        </MDBCol>
                        <MDBCol size="12">
                            <MDBRow>
                                {!isClient &&
                                    <>
                                        <GetSelectWithOptions
                                            __off={__off}
                                            changeValue={this.changeValue}
                                            changeValueWanLan={this.changeValueWanLan}
                                            interfaceTopology={interfaceTopology}
                                            interfaceTopologySchema={schemas.interfaceTopology}
                                            isExternal={isExternal}
                                            isInternal={isInternal}
                                            size="3"
                                            t={t}
                                            uuid={uuid}
                                        />

                                        <MDBCol lg="3">
                                            <Input
                                                disabled={__off}
                                                id={'maxClients'}
                                                label={t('widgets:Vpn.maxClients.title')}
                                                message="max-clients"
                                                name="maxClients"
                                                number
                                                onChange={this.changeNumber}
                                                placeholder={t('widgets:Vpn.maxClients.nullValue')}
                                                value={maxClients}
                                            />
                                        </MDBCol>
                                        <MDBCol lg="3">
                                            <Select
                                                disabled={__off}
                                                enumTranslationDifferPath={'differs:tables.openvpnRas.authAlg'}
                                                id={'authAlg'}
                                                label={t('differs:tables.openvpnRas.authAlg.title')}
                                                message="auth-alg"
                                                name="authAlg"
                                                onChange={this.changeValue}
                                                paste={false}
                                                schema={schemas.authAlg}
                                                value={authAlg}
                                            />

                                        </MDBCol>
                                    </>
                                }
                            </MDBRow>
                        </MDBCol>
                        {isRas &&
                        <MDBCol size="12">
                            <MDBRow>

                                <MDBCol lg="9">
                                    <Select
                                        disabled={__off}
                                        enumTranslationDifferPath={'differs:tables.openvpnRas.dataCiphers'}
                                        id={'data-ciphers'}
                                        isMulti
                                        label={t('differs:tables.openvpnRas.dataCiphers.title')}
                                        message="data-ciphers"
                                        name="dataCiphers"
                                        onChange={this.changeValueNcpCipher}
                                        schema={schemas.dataCiphers}
                                        value={dataCiphers}
                                    />
                                </MDBCol>
                                <MDBCol lg="3">
                                    <Select
                                        disabled={__off}
                                        enumTranslationDifferPath={'differs:tables.openvpnRas.dataCiphersFallback'}
                                        id={'data-ciphers-fallback'}
                                        isMulti
                                        label={t('differs:tables.openvpnRas.dataCiphersFallback.title')}
                                        message="data-ciphers-fallback"
                                        name="dataCiphersFallback"
                                        onChange={this.changeValueDataCiphersFallback}
                                        paste={false}
                                        required={false}
                                        schema={schemas.dataCiphersFallback}
                                        value={dataCiphersFallback ? [ dataCiphersFallback ] : []}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        }

                        <MDBCol size="12">
                            <MDBRow>
                                {isRas &&
                                    <MDBCol>
                                        <div className="vpn__crlVerify">
                                            <MDBCardTitle className={classNames(
                                                'cardHide__title',
                                                'pt-2',
                                                { 'cardHide__title--noBorderBottom':
                                                 crlVerify ? crlVerify?.__off : true },
                                            )}
                                            >
                                                <Message message={'widgets:Vpn.crlVerify.title'} />
                                                <Switch
                                                    align="left"
                                                    // switch default on __off is to revertValue
                                                    // __off: true means switch is OFF
                                                    // if object doesnt exist it also means switch is OFF,
                                                    // but we need to revert it
                                                    checked={crlVerify ? crlVerify?.__off : true}
                                                    className={classNames(
                                                        'dataTableWidget__RowInput',
                                                        'dataTableWidget__RowInput--sm',
                                                        'mr-2',
                                                        'justify-content-center',
                                                    )}
                                                    id={'__off'}
                                                    mini
                                                    name={'crlVerify'}
                                                    onChange={this.changeObjectValue}
                                                    withoutBorder
                                                    withoutLabelMargins
                                                    withoutMinWidhtHeight

                                                />
                                            </MDBCardTitle>
                                            <MDBCardBody className={classNames(
                                                'cardHide__body',
                                                'pb-0',
                                                { 'cardHide__body--hide ': crlVerify ?  crlVerify?.__off : true },
                                                { 'pt-2': !crlVerify?.__off },
                                            )}
                                            >

                                                <Switch
                                                    align="spaceBetween"
                                                    bothActive
                                                    checked={crlVerify?.type === VPN_CRL_VERIFY_FILE_TYPE}
                                                    disabled={__off || crlVerify?.__off}
                                                    id="type"
                                                    inputStyle
                                                    isMessage
                                                    label={t('widgets:Vpn.crlVerify.type.title')}
                                                    messageOff={t('widgets:Vpn.crlVerify.file.title')}
                                                    messageOn={t('widgets:Vpn.crlVerify.url.title')}
                                                    name="crlVerify"
                                                    onChange={this.changeCrlVerifyTypeValue}
                                                />
                                                {!crlVerify?.type || crlVerify?.type === VPN_CRL_VERIFY_URL_TYPE ?
                                                    <>
                                                        <Input
                                                            disabled={__off || crlVerify?.__off}
                                                            error={testedVpn === uuid ? crlUrlTestError : undefined}
                                                            id={'url'}
                                                            label={t('widgets:Vpn.crlVerify.url.title')}
                                                            loading={crlUrlTestIsLoading}
                                                            name="crlVerify"
                                                            onChange={this.resetErrorAndChange}
                                                            success={testedVpn === uuid && crlUrlTestIsData ?
                                                                t('widgets:Vpn.crlVerify.success') :
                                                                undefined}
                                                            value={crlVerify?.url}
                                                        />
                                                        <MDBBtn
                                                            className="mb-3"
                                                            disabled={crlUrlTestIsLoading || treeDirty}
                                                            onClick={this.testCrlUrl}
                                                        >
                                                            {(crlUrlTestIsLoading || treeDirty) &&
                                                            <Icon name="loading" />
                                                            }
                                                            {t('widgets:Vpn.crlVerify.urlTry')}
                                                        </MDBBtn>
                                                    </> :
                                                    <VpnInput
                                                        __off={__off || crlVerify?.__off}
                                                        func={this.uploadAdvancedFile}
                                                        id="crlVerify"
                                                        name="file"
                                                        value={crlVerify?.file}
                                                    />
                                                }
                                            </MDBCardBody>
                                        </div>
                                    </MDBCol>
                                }
                                <MDBCol>
                                    <div className="vpn__crlVerify">
                                        <MDBCardTitle className={classNames(
                                            'cardHide__title', 'pt-2',
                                            { 'cardHide__title--noBorderBottom': tlsAuth ? tlsAuth?.__off : true },

                                        )}
                                        >
                                            <Message message={'widgets:Vpn.tlsAuth.title'} />
                                            <Switch
                                                align="left"
                                                // switch default on __off is to revertValue
                                                // __off: true means switch is OFF
                                                // if object doesnt exist it also means switch is OFF,
                                                // but we need to revert it
                                                checked={tlsAuth ? tlsAuth?.__off : true}
                                                className={classNames(
                                                    'dataTableWidget__RowInput',
                                                    'dataTableWidget__RowInput--sm',
                                                    'mr-2',
                                                    'justify-content-center'
                                                )}
                                                id={'__off'}
                                                mini
                                                name={'tlsAuth'}
                                                onChange={this.changeObjectValue}
                                                withoutBorder
                                                withoutLabelMargins
                                                withoutMinWidhtHeight
                                            />
                                        </MDBCardTitle>
                                        <MDBCardBody className={classNames(
                                            'cardHide__body',
                                            'pb-0',
                                            { 'cardHide__body--hide': tlsAuth ? tlsAuth?.__off : true },
                                            { 'pt-2': !crlVerify?.__off },
                                        )}
                                        >
                                            <Input
                                                className="mb-0"
                                                copy
                                                disabled={__off || tlsAuth?.__off}
                                                generate
                                                generateClick={this.generateHash}
                                                id="hash"
                                                label={t('widgets:Vpn.tlsAuth.hash.title')}
                                                name="tlsAuth"
                                                onChange={this.changeObjectValueWithTrim}
                                                paste
                                                resize
                                                type="textarea"
                                                value={tlsAuth?.hash}
                                            />
                                            <div className="form-group mt-3">
                                                <MDBBtnGroup className="form-control mb-2 border-0 px-0 pt-0">
                                                    {[ '', '0', '1' ].map(item => (
                                                        <VpnOptionButton
                                                            __off={__off || tlsAuth?.__off}
                                                            func={this.changeObjectValue}
                                                            item={item}
                                                            key={item}
                                                            value={tlsAuth?.option}
                                                        />
                                                    ))}

                                                </MDBBtnGroup>
                                                <label className="form-control__label active
                                                form-control__label--buttons"
                                                >
                                                    <Message message={'widgets:Vpn.tlsAuth.option.title'} />
                                                </label>
                                            </div>
                                        </MDBCardBody>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    {
                        // ROUTE TABLE
                    }
                    <MDBRow className={classNames('mt-1', 'cardHide__body',
                        { 'cardHide__body--hide': selected !== VPN_SETTING_MENU_ROUTE })}
                    >
                        <MDBCol>
                            <VpnRoutingTableContext.Provider
                                value={
                                    {
                                        type: isRas ? VPN_ROUTES_TYPE_SETTINGS :
                                            VPN_CLIENT_ROUTES_TYPE_SETTINGS,
                                        vpnUuid: uuid
                                    }
                                }
                            >
                                <VpnRouteTable
                                    data={routes}
                                />
                            </VpnRoutingTableContext.Provider>
                        </MDBCol>
                    </MDBRow>

                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default VpnSettings;
