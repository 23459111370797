/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Switch expression with default value
 *
 * @param value value to match
 * @param cases object with cases to match
 * @param defaultValue to return if no match
 * @returns matched value or default value
 * @example switchExpr('a', { a: 1, b: 2 }, 3) // returns 1
 * @example switchExpr('c', { a: 1, b: 2 }, 3) // returns 3
 */
export const switchExpr = <T>(value: string, cases: { [key: string]: T }, defaultValue: T): T => {
    if (cases[value]) {
        return cases[value];
    } else {
        return defaultValue;
    }
};
