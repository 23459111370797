/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { MDBRow } from 'mdbreact';
import PropTypes from 'prop-types';


import { IpsecSettings } from './components/index.js';


@withTranslation()
class IpsecService extends Component {
    static get propTypes() {
        return {
            uuid: PropTypes.string,
        };
    }

    render() {
        const {  uuid } = this.props;
        return (
            <MDBRow>
                <IpsecSettings uuid={uuid} />
            </MDBRow>
        );
    }
}

export default IpsecService;
