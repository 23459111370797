/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createScene } from '~frontendLib/scenes.js';
import { createLazy } from '~frontendLib/createLazy.tsx';


const MainDashboard = createLazy(() => import('./MainDashboard/index.ts'));

const SYSTEM_MAIN_DASHBOARD_SCENE = createScene({
    path: '/monitoring/system/mainDashboard',
    component: MainDashboard,
    title: 'scenes:scenes.Monitoring.scenes.System.scenes.MainDashboard.title',
});

const scenes = () => {
    return [
        SYSTEM_MAIN_DASHBOARD_SCENE,
    ];
};

export default scenes;
