/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { Select } from '~frontendRoot/components/Generic/index.js';
import Message from '~frontendRoot/components/Message/index.js';
import { BackendApiDefinitionType } from '~frontendTypes/externalTypes.ts';
import type { PostResponseType } from '~commonLib/apiUtils.ts';
import { getDataFromHP, getErrorHP, getIpToFindHP, getIsLoadingHP, isIpRequest } from '~frontendDucks/isInHP/index.ts';
import { BASIC_IP_SCHEMA } from '~frontendRoot/constants/index.js';
import { NetaddrIp4 } from '~commonLib/Netaddr/NetaddrIp4.ts';
import { StringifyAddressReturnType } from '~frontendRoot/lib/addressUtils.ts';
import { TIME_FORMAT } from '~commonLib/moment.ts';


interface AddrIsInHPType {
    isLoading: boolean,
    error?: any,
    onChange: (opts: { value: NetaddrIp4 }) => void,
    ip: StringifyAddressReturnType,
    data?: PostResponseType<BackendApiDefinitionType['/utils/honeypotCheckAddress']>,
}

const AddrIsInHP = ({ isLoading, error, onChange, ip, data }: AddrIsInHPType) => {
    return (
        <>
            <Select
                disabled={isLoading}
                id={'isInHP'}
                isCreatable
                isMulti
                label={<Message message={'widgets:AdaptiveFirewall.isIn.label'} />}
                loading={isLoading}
                name="name"
                noDropdownIndicator
                noOptionsMessage
                onChange={onChange}
                schema={BASIC_IP_SCHEMA}
                value={ip}
            />
            {!isLoading &&
            <p
                className={
                    classNames('infobox',
                        { 'infobox--primary': data && !data?.isBlocked },
                        { 'infobox--alert': error && ip },
                        { 'infobox--success': data && data?.isBlocked })
                }
            >
                <span className="headerInfobox">
                    {error && ip && <Message message={error.code} />}
                    {data && data?.isBlocked && <Message message="widgets:Honeypot.addressIs.in.title" />}
                    {data && !data?.isBlocked && <Message message="widgets:Honeypot.addressIs.notIn.title" />}
                </span>
                {error && ip && <Message message={error.message} />}
                {data && (data?.expirationDateUnixMs ?
                    <Message
                        message="widgets:Honeypot.addressIs.in.desc"
                        params={{
                            time: {
                                message:
                                    `${moment.utc(data.expirationDateUnixMs).local().format(
                                        TIME_FORMAT.userDateTimeFull
                                    )}`,
                                dontTranslate: true
                            }
                        }}
                        translateParams
                    /> : <Message message="widgets:Honeypot.addressIs.notIn.desc" />)}
            </p>
            }
        </>
    );
};

export const AddrInfo = () => {

    const data: PostResponseType<BackendApiDefinitionType['/utils/honeypotCheckAddress']> | undefined =
        useSelector(getDataFromHP);
    const ip = useSelector(getIpToFindHP);
    const isLoading: boolean = useSelector(getIsLoadingHP);
    const error = useSelector(getErrorHP);
    const dispatch = useDispatch();
    const onChange = ({ value }) => {
        dispatch(isIpRequest({ ip: value }));
    };

    return (
        <MDBCard>
            <MDBCardHeader>
                <Message message="widgets:Honeypot.addressIs.title" />
            </MDBCardHeader>
            <MDBCardBody className="relative">
                <AddrIsInHP
                    data={data}
                    error={error}
                    ip={ip}
                    isLoading={isLoading}
                    onChange={onChange}
                />
            </MDBCardBody>
        </MDBCard>
    );
};

export default AddrInfo;
