/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getComponentEnabled, setGlcfgObjectValue } from '~frontendDucks/hlcfgEditor/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { ANTIVIRUS } from '~frontendRoot/constants/index.js';

import { AntivirusService } from './components/index.js';


@connect(
    (state) => ({
        antivirus: getComponentEnabled(state, ANTIVIRUS),
    }),
    {
        setValue: setGlcfgObjectValue,
    }
)
class AntivirusScene extends Component {
    static get propTypes() {
        return {
            antivirus: PropTypes.bool,
            setValue: PropTypes.func,
        };
    }

    enableService = () => {
        const { setValue } = this.props;
        setValue(ANTIVIRUS, false, '__off');

    };

    disableService = () => {
        const { setValue } = this.props;
        setValue(ANTIVIRUS, true, '__off');
    };

    render() {
        const { antivirus } = this.props;
        return (
            <Service
                id="Antivirus"
                on={antivirus}
                showService={(<AntivirusService />)}
                turnOff={this.disableService}
                turnOn={this.enableService}
            />
        );
    }
}

export default AntivirusScene;
