/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from 'mdbreact';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { Icon, Select, Switch } from '~frontendComponents/Generic/index.js';
import { setNormalize } from '~frontendDucks/hlcfgEditor/normalizeTableGettersAndSetters.js';
import { getTableSchemaGetter } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSetters.js';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/NetworkInterfaceDevice.tsx';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { HlcfgTableItemSchema } from '~frontendRoot/types/externalTypes.ts';
import { getDnsProxyActiveProfile } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';


const DnsProfileSettings = (props: { profileId: string }) => {
    const { profileId } = props;

    const [ showSettings, setShowSettings ] = useBoolean();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const schema: HlcfgTableItemSchema<'dnsProxyProfile'> = useSelector(getTableSchemaGetter(profileId));
    const profile = useSelector(getDnsProxyActiveProfile);

    const setProfileParameters = useCallback(({ value, name }) => {
        dispatch(setNormalize({ uuid: profileId, value, key: name }));
    }, [ profileId, dispatch ]);

    if (!profile) {
        return null;
    }
    const { listenAddrs, listenPort, ignoreAdditional } = profile;
    return (
        <MDBCard>
            <MDBCardTitle className={classNames(
                'profiles__title',
                { 'profiles__title--noBorderBottom': showSettings },
            )}
            >
                <div>
                    {t('profile:settings.header')}
                    <Icon
                        name={showSettings ? 'chevron-up' : 'chevron-down'}
                        onClick={setShowSettings.swap}
                    />
                </div>
            </MDBCardTitle>
            <MDBCardBody
                className={classNames(
                    'profile__body', 'pt-0', 'pb-0',
                    { 'profile__body--hide': !showSettings },
                    { 'profile__title--borderBottom': showSettings },
                )}
            >
                <MDBRow className={'py-2'}>
                    <MDBCol>
                        <NetworkInterfaceDevice
                            id={'listenAddrs' + profileId}
                            iface={stringifyAddress(listenAddrs)}
                            isCreatable
                            isMulti
                            isRow
                            label={t('profile:column.addresses.title')}
                            maxValueShown={3}
                            name={'listenAddrs'}
                            onChange={setProfileParameters}
                            schema={schema.listenAddrs}
                        />
                    </MDBCol>
                    <MDBCol>
                        <Select
                            id={'listenPort' +  profileId}
                            isCreatable
                            isMulti
                            isRow
                            label={t('profile:column.port.title')}
                            name={'listenPort'}
                            noOptionsMessage
                            onChange={setProfileParameters}
                            placeholder={schema?.listenPort?.[DEFAULT_SCHEMA_VALUE]?.port?.toString()}
                            schema={schema.listenPort}
                            value={listenPort ? [ listenPort?.port.toString() ] : []}
                        />
                    </MDBCol>
                    <MDBCol>
                        <Switch
                            align={'spaceBetween'}
                            checked={ignoreAdditional}
                            className={'m-0'}
                            id={'ignoreAdditional' + profileId}
                            label={t('profile:dnsProxy.ignoreAdditional')}
                            name={'ignoreAdditional'}
                            onChange={setProfileParameters}
                            schema={schema.ignoreAdditional}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default DnsProfileSettings;
