/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import isEqual from 'lodash.isequal';
import assert from 'assert';

import { addToNormalize, closeHeaderProfile, duplicateFromNormalize, getTableById, setNormalize
} from '~frontendDucks/hlcfgEditor/index.js';
import { getActiveCardHook } from '~frontendDucks/activeCards/index.js';
import {  PROFILES, NEW_ROW_CONSTANT, PROXY_TIME_INTERVALS } from '~frontendConstants/index.js';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { getCwdbV2Enabled, makeSelectGetProfileRule } from '~frontendRoot/ducks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { Offable } from '~frontendRoot/constants/types.ts';
import { CW_BLOCK_LOW_CATEGORIES, CW_LOW_CATEGORIES, PROFILE_RULE_ACTION_BLOCK } from '~sharedConstants/constants.ts';
import { objectWithKeys } from '~commonLib/objectUtils.ts';

import { RenderEmptyUserRules, RenderDivider, RenderRule } from './renderers.tsx';
import { TimeInterval, timeIntervalObj } from './timeIntervalObj.ts';


const Row = ({ uuid, dataIndex, spacing, search }: CreateRowPropsType) => {
    const dispatch = useDispatch();
    const getter = useMemo(makeSelectGetProfileRule, []);
    const data = useSelector<any, Offable<'profileRule'>>(state => getter(state, uuid));
    const intervalsFromTable = useSelector(state => getTableById(state, PROXY_TIME_INTERVALS));

    const cwdbV2IsEnabled = useSelector(getCwdbV2Enabled);


    const active = useSelector(getActiveCardHook(PROFILES));

    const addAllowRow = useCallback(() => {
        dispatch(addToNormalize(
            {
                type: 'profileRule',
                typeId: PROFILES,
                key: 'rules',
                uuid: active,
                uuidToAddBefore: uuid,
                successText: 'profile:rule.added',
                extraValues: {
                    type: 'rule',
                    cwCategoriesV2: objectWithKeys(CW_LOW_CATEGORIES, false),
                }
            }
        ));
    }, [ active, uuid, dispatch ]);

    const addBlockRow = useCallback(() => {
        dispatch(addToNormalize(
            {
                type: 'profileRule',
                typeId: PROFILES,
                key: 'rules',
                uuid: active,
                uuidToAddBefore: uuid,
                successText: 'profile:rule.added',
                extraValues: {
                    action: {
                        type: PROFILE_RULE_ACTION_BLOCK
                    },
                    type: 'rule',
                    cwCategoriesV2: objectWithKeys(CW_BLOCK_LOW_CATEGORIES, false),
                }
            }
        ));
    }, [ active, uuid, dispatch ]);


    const addByCategoryRow = useCallback(() => {
        dispatch(addToNormalize(
            {
                type: 'profileRule',
                typeId: PROFILES,
                key: 'rules',
                uuid: active,
                uuidToAddBefore: uuid,
                successText: 'profile:rule.added',
                extraValues: {
                    type: 'rule',
                    action: {
                        type: 'accordingToCategory',
                        accordingToCwCategoryV2: objectWithKeys(CW_LOW_CATEGORIES, PROFILE_RULE_ACTION_BLOCK),
                    }
                }
            }
        ));
    }, [ active, uuid, dispatch ]);


    const addRowHeader = useCallback(() => {
        dispatch(addToNormalize(
            {
                type: 'profileHeader',
                typeId: PROFILES,
                key: 'rules',
                uuid: active,
                uuidToAddBefore: uuid,
                successText: 'profile:header.added',
                extraValues: {
                    type: 'header'
                }
            }
        ));
    }, [ active, uuid, dispatch ]);


    const copyRow = useCallback(() => {
        dispatch(duplicateFromNormalize({
            uuid: uuid,
            parentKey: 'rules',
            parentUuid: active,
        }));
    }, [ active, uuid, dispatch ]);

    const delRow = useCallback(() => {
        dispatch(setNormalize({ uuid: active
            , value: uuid, key: 'rules' }));
    }, [ active, uuid, dispatch ]);


    const setValue = useCallback(({ name, value }) => {
        dispatch(setNormalize({ type: 'profileRules',  value, key: name, uuid, }));
    }, [ uuid, dispatch ]);


    const setValueObject = useCallback(({ name, value, id }) => {
        dispatch(setNormalize({ type: 'profileRules', value, key: name, subkey: id, uuid }));
    }, [ uuid, dispatch ]);

    const handleTimeIntervals = useCallback(({ value, name, id }:
            {value: TimeInterval[], name: string, id: string}) => {
        if (data.timeConstraints?.times?.length && value.length < data.timeConstraints?.times?.length) {
            const newValue: string[] = [];
            data.timeConstraints?.times?.forEach(interval => {
                const from = intervalsFromTable[interval].from;
                const to = intervalsFromTable[interval].to;
                value.forEach(item => {
                    if (isEqual(item.from, from) && isEqual(item.to, to)) {
                        newValue.push(intervalsFromTable[interval].id);
                    }
                });
            });
            dispatch(setNormalize({ value: newValue, uuid, key: name, subkey: id }));
        }
        else {
            const int: TimeInterval[] | undefined = data.timeConstraints?.times?.map(int => intervalsFromTable[int]);
            const indexOfAddedElement = value.findIndex((element: TimeInterval) => {
                return !timeIntervalObj.stringify(int).includes(timeIntervalObj.stringify([ element ])[0]);
            });
            assert(indexOfAddedElement !== -1,
                'We just checked that element was added. Element not being found is a bug.');
            dispatch(addToNormalize({
                type: PROXY_TIME_INTERVALS,
                key: name,
                subkey: id,
                uuid,
                successText: 'profile:timeInterval.added',
                extraValues: value[indexOfAddedElement]
            }));
        }
    }
    , [ dispatch, uuid, data, intervalsFromTable ]);

    const closeRules = useCallback(({ value }) => {
        dispatch(closeHeaderProfile({ uuid, value: value, type: PROFILES }));
    }, [ dispatch, uuid ]);

    const validateUser = useCallback((inputValue) => {
        return inputValue !== '';
    }, []);

    const CW2types = useMemo(() => [
        { addFunc: addAllowRow, translation: 'profile:profiles.rules.allow' },
        { addFunc: addBlockRow, translation: 'profile:profiles.rules.block' },
        { addFunc: addByCategoryRow, translation: 'profile:profiles.rules.byCategory' },
        { addFunc: addRowHeader, translation: 'packetFilter:header' },
    ], [ addAllowRow, addBlockRow, addByCategoryRow, addRowHeader ]);

    const types = useMemo(() => [
        { addFunc: addAllowRow, translation: 'profile:profiles.rules.rule' },
        { addFunc: addRowHeader, translation: 'packetFilter:header' },
    ], [ addAllowRow, addRowHeader ]);

    if (uuid === NEW_ROW_CONSTANT) {
        return RenderEmptyUserRules({
            addRow: addAllowRow,
            addHeader: addRowHeader,
        });
    }
    return (
        <Draggable
            draggableId={uuid}
            index={dataIndex}
            isDragDisabled={false}
            key={uuid}
        >
            {(provided: DraggableProvided) =>  {
                if (hlcfgTableNameByRowId(uuid) === 'profileRule') {
                    return RenderRule({
                        //props
                        spacing,
                        types: cwdbV2IsEnabled ? CW2types : types,
                        uuid,
                        provided,
                        search,
                        cwdbV2IsEnabled,

                        //functions
                        setValue,
                        setValueObject,
                        handleTimeIntervals,
                        delRow,
                        copyRow,
                        validateUser
                    });
                }
                return RenderDivider({
                    //props
                    spacing,
                    types: cwdbV2IsEnabled ? CW2types : types,
                    uuid,
                    provided,
                    //functions
                    setValue,
                    delRow,
                    copyRow,
                    closeRules,
                });}
            }
        </Draggable>
    );
};

export default Row;
