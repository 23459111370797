/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * @file
 * HLCFG contains tables that are normalized using
 * https://redux.js.org/usage/structuring-reducers/normalizing-state-shape.
 * A single table row is at the following path and has the following structure:
 * ```
 * hlcfgTree.tables[hlcfgTableName][hlcfgRowId] = { id: hlcfgRowId, ...otherValues }
 * ```
 * The order of the table rows is not stored at path `hlcfgTree.tables`.
 * It is stored at the place that references the table rows.
 */

import { ValuesType } from 'utility-types';

import type { HlcfgSchemaTS } from '~backendLib/HlcfgSchemaTS.ts';
import type { HlcfgTableDirtyItem, HlcfgTableGetter } from '~backendLib/newHlcfgGetters/hlcfgPathsAndTypes.ts';
import { HlcfgGettersV4 } from '~backendRoot/lib/newHlcfgGetters/getSettersFromSchema/gettersV4.ts';
import { makeStringConstsObj } from '~commonLib/objectUtils.ts';
import { TypeBrand } from '~commonLib/types.ts';
import { HlcfgInputTree } from '~sharedLib/HlcfgInputTree.ts';


/**
 * A unique identifier of a HLCFG row.
 * The actual type should be `` `${HlcfgTableName}:${string}` `` but that would require much refactoring.
 */
export type HlcfgRowId = string & TypeBrand<'HlcfgRowId'>;

/**
 * Converts HLCFG row identifier to HLCFG table name.
 * This function should return a type HlcfgTableName but that would require much refactoring.
 */
export const hlcfgTableNameByRowId = (id: HlcfgRowId): string => {
    return id.split(':')[0];
};

/**
 * Converts HLCFG row identifier to pure uuid.
 */
export const hlcfgUuidByRowId = (id: HlcfgRowId): string => {
    return id.split(':')[1];
};

/** Obtains HLCFG row by its identifier. */
export const hlcfgRowById = (hlcfg: HlcfgSchemaTS|HlcfgInputTree, id: HlcfgRowId) => {
    const table = hlcfgTableNameByRowId(id);
    return hlcfg.tables[table][id];
};
export const hlcfgV4RowGetById = (v4: HlcfgGettersV4, id: HlcfgRowId) => {
    const table = hlcfgTableNameByRowId(id);
    return v4.tables[table][id];
};

export const createRowId = (tableName: HlcfgTableName, uuid: string) => `${tableName}:${uuid}`;

/**
 * All HLCFG table names.
 * A HLCFG table is a structure that follows https://redux.js.org/usage/structuring-reducers/normalizing-state-shape.
 */
export const hlcfgTableName = makeStringConstsObj([
    'openvpnRas',
    'openvpnClient',
    'openvpnUser',
    'route',
    'vpnRoute',
    'profileRule',
    'profileHeader',
    'profileSpecialItem',
    'addressesTable',
    'tcNode',
    'tcQueue',
    'host',
    'nftRule',
    'nftDivider',
    'profile',
    'multihomingGateway',
    'hwIface',
    'vlanIface',
    'bridgeIface',
    'bondIface',
    'netaddrScalar',
    'netaddrVector',
    'netportScalar',
    'netserviceScalar',
    'dhcpLease',
    'dhcpServer',
    'dhcpRelay',
    'dhcpPool',
    'clusterNode',
    'ipsec',
    'wafProfile',
    'wafProfileRule',
    'profileRuleTimeInterval',
    'dnsProxyRule',
    'dnsProxyHeader',
    'dnsProxyProfile',
    'routeMixedIp',
]);

/**
 * A HLCFG table name.
 * A HLCFG table is a structure that follows https://redux.js.org/usage/structuring-reducers/normalizing-state-shape.
 */
export type HlcfgTableName = ValuesType<typeof hlcfgTableName>

/**
 * This type is a simplified version of HLCFG table row getter.
 * It drastically simplifies inference because TS does not have to compare full type of getter we pass in.
 */
type SimplifiedHlcfgRowGetter = {
    id: {
        _get: any
    },
    _get: any,
}

/**
 * This type is a simplified version of HLCFG table row.
 * Uses the same principle as {@link SimplifiedHlcfgRowGetter}.
 */
type SimplifiedHlcfgRowObject = {
    id: string
}

/**
 * Returns true if provided HLCFG row getter {@link rowGetter} is from given {@link tableName}.
 */
export const hlcfgRowGetterIsFromTable = <T extends HlcfgTableName>(
    rowGetter: SimplifiedHlcfgRowGetter, tableName: T
): rowGetter is HlcfgTableGetter<T> => {
    return hlcfgTableNameByRowId(rowGetter.id._get) === tableName;
};

/**
 * Returns true if provided HLCFG row {@link rowObject} is from given {@link tableName}.
 */
export const hlcfgRowObjectIsFromTable = <T extends HlcfgTableName>(
    rowObject: SimplifiedHlcfgRowObject, tableName: T
): rowObject is HlcfgTableDirtyItem<T> => {
    return hlcfgTableNameByRowId(rowObject.id) === tableName;
};

/**
 * Returns true if provided HLCFG row id {@link rowIdentifier} is from given {@link tableName}.
 */
export const hlcfgRowIdIsFromTable = <T extends HlcfgTableName>(
    rowIdentifier: HlcfgRowId, tableName: T
): rowIdentifier is `${T}:${string}` => {
    return hlcfgTableNameByRowId(rowIdentifier) === tableName;
};
