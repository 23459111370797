/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { NEED_RESET_CONFIGURATION } from '~frontendConstants/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { cfgActivationRequest } from '~frontendDucks/cfgActivation/index.js';
import { getMyNode, getOtherNode, } from '~frontendDucks/clusterSetup/index.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/index.js';
import Message from '~frontendRoot/components/Message/index.js';
import { NODE_SELF } from '~commonLib/constants.ts';


const NeedsResetConfiguration = () => {
    const isCluster = useSelector<any, boolean>(getIsCluster);

    const otherNode = useSelector<any, string>(getOtherNode);
    const myNode = useSelector<any, string>(getMyNode);
    const dispatch = useDispatch();
    const confirm = () => {
        const nodes = isCluster ? [ otherNode, myNode ] : [ NODE_SELF ];
        dispatch(cfgActivationRequest({ isOpen: true, nodes }));
    };

    const close = () => {
        dispatch(setModalState({ modal: NEED_RESET_CONFIGURATION, value: false }));
    };

    return (
        <Modal
            body={
                <>
                    <Message message="widgets:NeedsResetConfiguration.modal.body" />
                    <span className="bold"><Message message="widgets:NeedsResetConfiguration.modal.body1" /></span>
                    <Message message="widgets:NeedsResetConfiguration.modal.body2" />

                </>}
            exitHandle={close}
            headerText={'widgets:NeedsResetConfiguration.modal.header'}
            modalOpen={true}
            negativeAsPrimary
            negativeResponse={close}
            position="top-right"
            positiveResponse={confirm}
        />
    );
};

export default NeedsResetConfiguration;
