/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import  * as baseTsResult from 'base-ts-result';

// Little helper file to deal with ESM vs CJS compatibility issues.
export type { Result, ResultPromise, ReturnedResult, AsyncResultPromise, ReturnedResultPromise } from 'base-ts-result';

export const {
    Err, Ok, createAsyncResult, AsyncResult, toResult,
    resultify, toAsyncResult, asyncResultify
    // This generates warnings when bundling and rightfully so. This is extremely weird behaviour of TSX which
    // adds "default" to the module. I was about to file bug when I found out this behaves correctly
    // with Node 22. So maybe it actually is Node issue...
    //
    // TODO AK-3724: Check if this can be removed.
    // Tldr; This helper file could probably be removed after upgrade to Node 22. Or at the very least,
    // the .default can be removed. And re-export probably can be done as export {...} from '...';
} = ((baseTsResult as any).default as typeof baseTsResult) ?? baseTsResult;
