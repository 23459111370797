/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SocketIOReduxObject, dispatchOnEventInNamespaceAction, stopDispatchingOnEventInNamespaceAction } from '~frontendDucks/socketIO/index.js';
import { createNotification } from '~frontendLib/reactUtils.js';
import {
    SOCKET_IO_EVENT_CFG_ACTIVATION_FINISHED,
    SOCKET_IO_EVENT_CONFIGURATION_CHANGED,
    SOCKET_IO_EVENT_DATABASE_UPDATED,
    SOCKET_IO_EVENT_LOCAL_ADDR_IN_KCS_EVENT,
    SOCKET_IO_EVENT_USER_LOGIN,
    SOCKET_IO_EVENT_WILL_LISTEN_ON_ADDRESSES,
} from '~sharedLib/socketIOEvents.ts';
import { databaseInfoRequestSuccess } from '~frontendDucks/protectionDatabases/index.js';
import { needsResetSessionHlcfg } from '~frontendDucks/hlcfgEditor/index.js';
import { imIpRequest } from '~frontendDucks/imInAF/index.js';
import moment, { TIME_FORMAT } from '~commonLib/moment.ts';
import {
    CONFIGURATION_INFO_PRIV, SURICATA_INFO_PRIV, CONFIGURATION_MANAGEMENT_PRIV
} from '~sharedLib/privileges.ts';
import { startCheckingAvailability, stopCheckingAvailability } from '~frontendRoot/ducks/cfgActivation/index.js';


const GlobalNotifications = ({
    dispatchOnEventInNamespaceAction,
    stopDispatchingOnEventInNamespaceAction,
}) => {
    // add additional useEffect to add additional global notifications
    useEffect(() => {
        const configurationInfoPrivSocket: SocketIOReduxObject = {
            namespace: CONFIGURATION_INFO_PRIV,
            eventsWithActions: [
                {
                    event: SOCKET_IO_EVENT_CONFIGURATION_CHANGED,
                    actionCreator: [ () => needsResetSessionHlcfg(true) ], //dispatch to redux
                    actionCustom: () => {
                        createNotification({
                            title: 'widgets:global.warning',
                            desc: 'notifications:configuration.configurationUpdated',
                            type: 'warning',
                            persistent: true,
                            group: 'configurationUpdated',
                        });
                    }
                },
                {
                    event: SOCKET_IO_EVENT_DATABASE_UPDATED,
                    actionCreator: databaseInfoRequestSuccess, //dispatch to redux
                    actionCustom: databaseUpdateHandler,
                },
                {
                    event: SOCKET_IO_EVENT_LOCAL_ADDR_IN_KCS_EVENT,
                    actionCreator: imIpRequest, //dispatch to redux
                    actionCustom: () => {
                        createNotification({
                            title: 'widgets:AdaptiveFirewall.ImIn.notif.title',
                            desc: 'widgets:AdaptiveFirewall.ImIn.notif.desc',
                            type: 'warning',
                            persistent: true,
                            group: 'imInAF',
                        });
                    }
                },
                {
                    event: SOCKET_IO_EVENT_WILL_LISTEN_ON_ADDRESSES,
                    actionCreator: startCheckingAvailability,
                },
                {
                    event: SOCKET_IO_EVENT_CFG_ACTIVATION_FINISHED,
                    actionCreator: stopCheckingAvailability,
                },
            ],
        };

        const suricataInfoPrivSocket: SocketIOReduxObject = {
            namespace: SURICATA_INFO_PRIV,
            eventsWithActions: [
                {
                    event: SOCKET_IO_EVENT_DATABASE_UPDATED,
                    actionCreator: databaseInfoRequestSuccess,
                    actionCustom: databaseUpdateHandler,
                }
            ],
        };

        const configurationManagementPrivSocket: SocketIOReduxObject = {
            namespace: CONFIGURATION_MANAGEMENT_PRIV,
            eventsWithActions: [
                {
                    event: SOCKET_IO_EVENT_USER_LOGIN,
                    actionCustom: (data) => {
                        createNotification({
                            title: 'widgets:GlobalNotifications.userLogin.title',
                            desc: 'widgets:GlobalNotifications.userLogin.desc',
                            type: 'info',
                            descParams: {
                                username: data.username,
                                time: moment().format(TIME_FORMAT.userDateTimeShort),
                            },
                            persistent: true,
                        });
                    }
                }
            ],
        };

        const allSockets = [
            configurationInfoPrivSocket, suricataInfoPrivSocket, configurationManagementPrivSocket
        ];
        allSockets.forEach(socket => {
            dispatchOnEventInNamespaceAction(socket);
        });

        return () => {
            allSockets.forEach(socket => {
                stopDispatchingOnEventInNamespaceAction(socket);
            });
        };
    }, [ dispatchOnEventInNamespaceAction, stopDispatchingOnEventInNamespaceAction ]);

    return null;
};

GlobalNotifications.propTypes = {
    dispatchOnEventInNamespaceAction: PropTypes.func,
    stopDispatchingOnEventInNamespaceAction: PropTypes.func,
};

export default connect(null, {
    dispatchOnEventInNamespaceAction,
    stopDispatchingOnEventInNamespaceAction,
})(GlobalNotifications);

const databaseUpdateHandler = data => {
    for (const database of Object.keys(data)) {
        createNotification({
            title: `notifications:database.update.success.${database}.title`,
            desc: `notifications:database.update.success.${database}.desc`,
            type: 'success',
        });
    }
};
