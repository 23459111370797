/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


import {
    getGlcfgValue,
    setGlcfgObjectValue,
    getAdaptiveFirewallPolicySchema,
    getAdaptiveFirewallInterfacesSchema
} from '~frontendDucks/hlcfgEditor/index.js';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice/index.js';
import { ADAPTIVE_FIREWALL } from '~frontendRoot/constants/index.js';
import Slider from '~frontendRoot/components/Slider/Slider.tsx';


@withTranslation()
@connect(
    state => ({
        adaptiveFirewall: getGlcfgValue(state, ADAPTIVE_FIREWALL),
        policySchema: getAdaptiveFirewallPolicySchema(state),
        interfacesSchema: getAdaptiveFirewallInterfacesSchema(state),
    }),
    {
        doGlcfgObjectValue: setGlcfgObjectValue,

    }
)
class AdaptiveFirewallSettings extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            adaptiveFirewall: PropTypes.object,
            policySchema: PropTypes.object,
            doGlcfgObjectValue: PropTypes.func,
            interfacesSchema: PropTypes.object
        };
    }

    setValue = ({ value, id }) => {
        const { doGlcfgObjectValue, } = this.props;
        doGlcfgObjectValue(ADAPTIVE_FIREWALL, value, id);
    };

    render() {
        const { t, adaptiveFirewall, policySchema,
            interfacesSchema } = this.props;
        return (
            <MDBCard>
                <MDBCardTitle>
                    {t('widgets:AdaptiveFirewall.title')}
                </MDBCardTitle>
                <MDBCardBody>
                    <Slider
                        id="policy"
                        label={t('widgets:AdaptiveFirewall.policy.title')}
                        maxValue={policySchema?.enum?.length - 1}
                        minValue={0}
                        name="log"
                        onChange={this.setValue}
                        schema={policySchema}
                        value={adaptiveFirewall.policy}
                    />
                    <NetworkInterfaceDevice
                        id={'interfaces'}
                        iface={adaptiveFirewall.interfaces}
                        label={t('widgets:AdaptiveFirewall.interface.title')}
                        name={'adaptiveFirewallInterface'}
                        onChange={this.setValue}
                        paste={false}
                        schema={interfacesSchema}
                    />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default AdaptiveFirewallSettings;
