/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createLazy } from '~frontendLib/createLazy.tsx';
import { PROTECTION_PACKET_FILTER_SCENE_PATH } from '~frontendScenes/Protection/scenes/constants.js';
import { HONEYPOT_LICENSE_REQUIRED } from '~frontendConstants/constants.ts';

import { createScene } from '../../../lib/scenes.js';
import IpsRouters, { ipsScene } from './Ips/index.js';
import AdaptiveFirewallRouters, { adaptiveFirewallScenes } from './AdaptiveFirewall/index.js';
import ProxyRouter, { proxyScene } from './Proxy/index.js';
import WafRouter, { wafScene } from './Waf/index.js';


const PacketFilter = createLazy(() => import('./PacketFilter/index.js'));
const Honeypot = createLazy(() => import('./Honeypot/index.js'));
const DnsProxy = createLazy(() => import('./DnsProxy/index.js'));


const PROTECTION_IPS_SCENE = createScene({
    component: IpsRouters,
    scenes: ipsScene,
    path: '/protection/ips',
    title: 'scenes:scenes.Protection.scenes.Ips.title',
});

const PROTECTION_ADAPTIVE_FIREWALL_SCENE = createScene({
    component: AdaptiveFirewallRouters,
    scenes: adaptiveFirewallScenes,
    path: '/protection/adaptiveFirewall',
    title: 'scenes:scenes.Protection.scenes.AdaptiveFirewall.title',
});

const PROTECTION_PROXY_SCENE = createScene({
    component: ProxyRouter,
    path: '/protection/proxy',
    title: 'scenes:scenes.Protection.scenes.Proxy.title',
    scenes: proxyScene,

});

const PROTECTION_WAF_SCENE = createScene({
    component: WafRouter,
    path: '/protection/waf',
    title: 'scenes:scenes.Protection.scenes.Waf.title',
    scenes: wafScene,

});

const PROTECTION_PACKET_FILTER_SCENE = createScene({
    component: PacketFilter,
    path: PROTECTION_PACKET_FILTER_SCENE_PATH,
    title: 'scenes:scenes.Protection.scenes.PacketFilter.title',
});

const PROTECTION_DNS_PROXY_SCENE = createScene({
    component: DnsProxy,
    path: '/protection/dnsProxyProfile',
    title: 'scenes:scenes.Protection.scenes.DnsProxyProfile.title',
    isAdvanced: true,
});

const PROTECTION_HONEYPOT_SCENE = createScene({
    component: Honeypot,
    path: '/protection/honeypot',
    title: 'scenes:scenes.Protection.scenes.Honeypot.title',
    requiredLicensePermission: HONEYPOT_LICENSE_REQUIRED,
});

const scenes = () => [
    PROTECTION_PACKET_FILTER_SCENE,
    PROTECTION_ADAPTIVE_FIREWALL_SCENE,
    PROTECTION_IPS_SCENE,
    PROTECTION_PROXY_SCENE,
    PROTECTION_WAF_SCENE,
    PROTECTION_DNS_PROXY_SCENE,
    PROTECTION_HONEYPOT_SCENE
];

export default scenes;
