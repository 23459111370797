/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { MDBCol, MDBRow, } from 'mdbreact';


import { Scene } from '../../../../components/Scene/index.js';
import { Interface } from './components/index.js';
import GuiKeys from './components/GuiKeys/index.js';


@withTranslation()
class AdminInterface extends Component {
    render() {
        return (
            <Scene>
                <MDBRow>
                    <MDBCol
                        className="mb-4"
                    >
                        <Interface />
                    </MDBCol>
                    <MDBCol
                        className="mb-4"
                    >
                        <GuiKeys />
                    </MDBCol>
                </MDBRow>
            </Scene>
        );
    }
}

export default  AdminInterface;
