/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// The user should not be able to distinguish between a nonexisting user and an invalid password. We don't want to tell
// the attacker that the user exists.
export const WRONG_USER_OR_PASSWORD = 'Wrong user or password';

export const ACTION_SEQUENCE_START = 'ACTION_SEQUENCE_START';
export const LOGIN_DB_ERROR = 'LOGIN_DB_ERROR';
export const LOGIN_NO_USER = 'LOGIN_NO_USER';
export const LOGIN_DEACTIVATED_USER = 'LOGIN_DEACTIVATED_USER';
export const LOGIN_PASSWORD_COMPARE_ERROR = 'LOGIN_PASSWORD_COMPARE_ERROR';
export const LOGIN_WRONG_PASSWORD = 'LOGIN_WRONG_PASSWORD';

export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';
export const FORCED_LOGOUT_DEACTIVATION = 'FORCED_LOGOUT_DEACTIVATION';
export const FORCED_LOGOUT_ACCOUNT_CHANGE = 'FORCED_LOGOUT_ACCOUNT_CHANGE';
export const FORCED_LOGOUT_FAILED = 'FORCED_LOGOUT_FAILED';

export const USER_DOESNT_EXIST = 'USER_DOESNT_EXIST';
export const USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS';

export const LOGOUT_USER_IS_NOT_LOGGED_IN = 'LOGOUT_USER_IS_NOT_LOGGED_IN';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';

export const PASSWORD_CHANGE_SUCCESSFUL = 'PASSWORD_CHANGE_SUCCESSFUL';
export const PASSWORD_CHANGE_FAILED = 'PASSWORD_CHANGE_FAILED';

export const PASSWORD_SELF_RESET_SUCCESSFUL = 'PASSWORD_SELF_RESET_SUCCESSFUL';
export const PASSWORD_SELF_RESET_FAIL = 'PASSWORD_SELF_RESET_FAIL';

export const ADMIN_PASSWORD_RESET_SUCCESSFUL = 'ADMIN_PASSWORD_RESET_SUCESSFUL';
export const ADMIN_PASSWORD_RESET_FAIL = 'ADMIN_PASSWORD_RESET_FAIL';
export const ADMIN_PASSWORD_RESET_SELF_RESET_FAIL = 'ADMIN_PASSWORD_RESET_SELF_RESET_FAIL';

export const MODIFY_DEACTIVATED_USER_FAIL = 'MODIFY_DEACTIVATED_USER_FAIL';

export const GET_USER_NO_USER_FAIL = 'GET_USER_NO_USER_FAIL';

export const USER_PASSWORD_DOES_NOT_MATCH = 'USER_PASSWORD_DOES_NOT_MATCH';
export const TWO_PASSWORDS_DONT_MATCH = 'TWO_PASSWORDS_DONT_MATCH';

export const REMOVE_USER_SELF_REMOVAL_FAIL = 'REMOVE_USER_SELF_REMOVAL_FAIL';
export const REMOVE_USER_ALREADY_REMOVED_FAIL = 'REMOVE_USER_ALREADY_REMOVED_FAIL';

export const REACTIVATE_USER_SELF_REACTIVATION_FAIL = 'REACTIVATE_USER_SELF_REACTIVATION_FAIL';
export const REACTIVATE_USER_ALREADY_ACTIVE_FAIL = 'REACTIVATE_USER_ALREADY_ACTIVE_FAIL';

export const SESSION_AUTH_GET_USER_KEY_FAIL = 'SESSION_AUTH_GET_USER_KEY_FAIL';
export const SESSION_AUTH_INVALID_SESSION_KEY = 'SESSION_AUTH_INVALID_SESSION_KEY';
export const SESSION_AUTH_WRONG_USER_OR_PASSWORD = 'SESSION_AUTH_WRONG_USER_OR_PASSWORD';

export const USER_SAVE_ERROR = 'USER_SAVE_ERROR';
export const USER_UNDEFINED_OR_EMPTY = 'USER_UNDEFINED_OR_EMPTY';
