/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getComponentEnabled, setGlcfgObjectValue, setNormalize } from '~frontendDucks/hlcfgEditor/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { HONEYPOT } from '~frontendConstants/index.js';
import { HLCFG_OFF, HONEYPOT_UUID } from '~sharedConstants/index.ts';

import { HoneypotService } from './components/index.js';


@connect(
    (state) => ({
        honeypotEnabled: getComponentEnabled(state, HONEYPOT),
    }),
    {
        setValue: setGlcfgObjectValue,
        setValueNormalize: setNormalize
    }
)
class HoneypotScene extends Component {
    static get propTypes() {
        return {
            honeypotEnabled: PropTypes.bool,
            setValue: PropTypes.func,
            setValueNormalize: PropTypes.func,
        };
    }

    enableService = () => {
        const { setValue, setValueNormalize } = this.props;
        setValue(HONEYPOT, false, '__off');
        setValueNormalize({
            type: 'packetFilterRules',
            key: HLCFG_OFF,
            value: false,
            uuid: HONEYPOT_UUID
        });
    };

    disableService = () => {
        const { setValue, setValueNormalize } = this.props;
        setValue(HONEYPOT, true, '__off');
        setValueNormalize({
            type: 'packetFilterRules',
            key: HLCFG_OFF,
            value: true,
            uuid: HONEYPOT_UUID
        });
    };

    render() {
        const {  honeypotEnabled,  } = this.props;
        return (
            <Service
                id="Honeypot"
                on={honeypotEnabled}
                showService={<HoneypotService />}
                turnOff={this.disableService}
                turnOn={this.enableService}
            />
        );
    }
}

export default HoneypotScene;
