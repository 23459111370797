/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import {
    MDBBtn } from 'mdbreact';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Overlay, Icon, Cards } from '~frontendComponents/Generic/index.js';
import { getVpnServersIds, getVpnProps,
    deleteNormalize, duplicateFromNormalize, setNormalize, addToNormalize, setNormalizeOrder,
    getLowestPossibleTunIndex
} from '~frontendDucks/hlcfgEditor/index.js';
import { getActiveCard } from '~frontendDucks/activeCards/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { DELETE_CONFIRM, VPN } from '~frontendConstants/index.js';
import TurnedOff from '~frontendComponents/TurnedOff/index.js';
import { hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';

import VpnService from './components/VpnService/index.js';


@withTranslation()
@connect(
    (state) => ({
        ids: getVpnServersIds(state),
        vpnProps: getVpnProps(state),
        activeVpnService: getActiveCard(state, 'vpn'),
        lowestPossibleTunIndex: getLowestPossibleTunIndex(state)
    }),
    {
        add: addToNormalize,
        setValue: setNormalize,
        setOrder: setNormalizeOrder,
        doSetModal: setModalState,
        del: deleteNormalize,
        copy: duplicateFromNormalize,

    }
)
class VPNScene extends Component {
    static get propTypes() {
        return {
            ids: PropTypes.array,
            t: PropTypes.func,
            add: PropTypes.func,
            activeVpnService: PropTypes.string,
            setValue: PropTypes.func,
            setOrder: PropTypes.func,
            vpnProps: PropTypes.object,
            doSetModal: PropTypes.func,
            del: PropTypes.func,
            copy: PropTypes.func,
            lowestPossibleTunIndex: PropTypes.number,

        };
    }

    addVpnAccessServer = () => {
        const { add, activeVpnService, lowestPossibleTunIndex } = this.props;
        add({
            type: hlcfgTableName.openvpnRas, //just one type
            typeId: VPN,
            uuidToAddBefore: activeVpnService,
            successText: 'widgets:Vpn.accessServer.added',
            extraValues: {
                tunIndex: lowestPossibleTunIndex,
            }
        });
    };

    addVpnClient = () => {
        const { add, activeVpnService, lowestPossibleTunIndex } = this.props;
        add({
            type: hlcfgTableName.openvpnClient, //just one type
            typeId: VPN,
            uuidToAddBefore: activeVpnService,
            successText: 'widgets:Vpn.client.added',
            extraValues: {
                tunIndex: lowestPossibleTunIndex,
            }
        });
    };

    deleteService = ({ uuid }) => {
        const { doSetModal,  vpnProps } = this.props;
        doSetModal({ modal: DELETE_CONFIRM, value: true, specialValues: { uuid, name: vpnProps[uuid]?.name,
            action: this.delete, service: 'Vpn' } });
    };

    delete = ({ uuid }) => {
        const { del } = this.props;
        del({ type: VPN, value: uuid });
    };

    copyService = ({ uuid }) => {
        const { copy, lowestPossibleTunIndex  } = this.props;
        copy({
            uuid: uuid,
            parent: VPN,
            extraValues: {
                tunIndex: lowestPossibleTunIndex,
            }
        });
    };

    changeValue = ({ value, uuid, key, name }) => {
        const { setValue, activeVpnService } = this.props;
        setValue({ type: 'vpn', uuid: uuid || activeVpnService, value, key: key || name });
    };

    setOrder = ({ order }) => {
        const { setOrder } = this.props;
        setOrder({ type: VPN, value: order });
    };

    render() {
        const { t, ids, vpnProps,  activeVpnService, } = this.props;

        return (
            <>
                <div className="userNavigation">
                    <MDBBtn
                        className="navigation__button pulse"
                        data-cy="addingAccessServer"
                        onClick={this.addVpnAccessServer}
                        size="sm"
                        type="button"
                    >
                        <Icon name="plus" />
                        {t('widgets:Vpn.accessServer.title')}
                    </MDBBtn>
                    <MDBBtn
                        className="navigation__button ml-1"
                        data-cy="addingClient1"
                        onClick={this.addVpnClient}
                        size="sm"
                        type="button"
                    >
                        <Icon name="plus" />
                        {t('widgets:Vpn.client.title')}
                    </MDBBtn>
                </div>
                <div  className={classNames(
                    { 'withOverlay': !ids.length }
                )}
                >
                    <Cards
                        copyService={this.copyService}
                        deleteService={this.deleteService}
                        ids={ids}
                        menuItemProps={vpnProps}
                        setOrder={this.setOrder}
                        setValue={this.changeValue}
                        type="vpn"
                        wrapClassName="vpn__scene"
                    />

                    <div className="overlay--div">

                        <VpnService
                            uuid={activeVpnService}
                        />
                        <TurnedOff
                            disabled={vpnProps[activeVpnService]?.__off}
                            onChange={this.changeValue}
                        />
                    </div>
                    {!ids.length &&
                            <Overlay
                                active={!ids.length}
                            >
                                <div className="dhcpd__overlay">
                                    <h2>{t('widgets:Vpn.title')}</h2>
                                    <p className="mb-2">
                                        {t('widgets:Vpn.desc')}
                                    </p>
                                    <p className="dataTableWidget__Rowtext">
                                        {t('widgets:Vpn.accessServer.desc')}
                                        <MDBBtn
                                            className="navigation__button"
                                            data-cy="addingAccessServer"
                                            onClick={this.addVpnAccessServer}
                                            size="sm"
                                            type="button"
                                        >
                                            <Icon name="plus" />
                                            {t('widgets:Vpn.accessServer.title')}
                                        </MDBBtn>

                                    </p>
                                    <p className="dataTableWidget__Rowtext">
                                        {t('widgets:Vpn.client.desc')}
                                        <MDBBtn
                                            className="navigation__button"
                                            data-cy="addingClient2"
                                            onClick={this.addVpnClient}
                                            size="sm"
                                            type="button"

                                        >
                                            <Icon name="plus" />
                                            {t('widgets:Vpn.client.title')}
                                        </MDBBtn>

                                    </p>
                                    <MDBBtn
                                        className="navigation__button pulse"
                                        data-cy="addingAccessServer"
                                        onClick={this.addVpnAccessServer}
                                        size="sm"
                                        type="button"
                                    >
                                        <Icon name="plus" />
                                        {t('widgets:Vpn.accessServer.title')}
                                    </MDBBtn>
                                    <MDBBtn
                                        className="navigation__button ml-1"
                                        onClick={this.addVpnClient}
                                        size="sm"
                                        type="button"
                                    >
                                        <Icon name="plus" />
                                        {t('widgets:Vpn.client.title')}
                                    </MDBBtn>

                                </div>
                            </Overlay>}

                </div>
            </>
        );
    }
}

export default VPNScene;
