/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';


const ColumValueSimple = ({ value }) => {
    if (typeof value === 'undefined') {
        return null;
    }
    return value;
};

ColumValueSimple.propTypes = {
    value: PropTypes.node,
};

export default ColumValueSimple;
