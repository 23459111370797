/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { addToNormalize, duplicateFromNormalize, setNormalize } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import {
    ADDRESSES_TABLE,
    ADDRESSES_TYPE_WAF,
    TableSizeType,
    WAF_PROFILES } from '~frontendConstants/index.js';
import SocketAddresses, { SocketAddressesFuncContext } from '~frontendRoot/components/SocketAddresses/index.ts';
import { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { getAddressTableWafSelector } from '~frontendRoot/ducks/hlcfgEditor/glcfgGettersAndSettersTS.ts';


type ProfileAddressesProps = {
    active: HlcfgRowId,
    spacing?: TableSizeType
}

const ProfileAddresses = ({ active, spacing }: ProfileAddressesProps) => {

    const dispatch = useDispatch();

    const passReorderData = useCallback((value: HlcfgRowId[]) => {
        dispatch(setNormalize({
            uuid: active,
            key: 'addressesTable',
            value
        }));

    }, [ active,  dispatch ]);

    const add = useCallback((uuid: HlcfgRowId, addingAfter?: boolean) => {
        dispatch(addToNormalize({
            type: ADDRESSES_TABLE,
            addingAfter: typeof addingAfter === 'boolean',
            uuid: active,
            successText: 'profile:waf.addressTable.added',
            uuidToAddBefore: uuid,
            typeId: WAF_PROFILES,
            key: ADDRESSES_TABLE,
        }));

    }, [ active, dispatch ]);


    const copy = useCallback((uuid: HlcfgRowId) => {
        dispatch(duplicateFromNormalize({
            uuid: uuid,
            parentKey: ADDRESSES_TABLE,
            parentUuid: active,
        }));

    }, [ active, dispatch ]);

    const del = useCallback((uuid: HlcfgRowId) => {
        dispatch(setNormalize({
            uuid: active,
            value: uuid,
            typeId: WAF_PROFILES,
            key: ADDRESSES_TABLE,
        }));

    }, [ active, dispatch ]);


    return (
        <SocketAddressesFuncContext.Provider value={{
            add, copy, del, passReorderData,
            addressesType: ADDRESSES_TYPE_WAF,
            selector: getAddressTableWafSelector
        }}
        >
            <SocketAddresses
                isHiddenTableShow
                spacing={spacing}
            />
        </SocketAddressesFuncContext.Provider>
    );
};

export default ProfileAddresses;
