/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { PROFILE_STILL_WITH_AUTH } from '~frontendConstants/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { setModalState } from '~frontendDucks/modals/index.js';
import Message from '~frontendRoot/components/Message/index.js';
import { getProfilesWithAuthIds, getProfilesWithAuthNames, setHlcfgValues } from '~frontendRoot/ducks/hlcfgEditor/index.js';


const ProfileStillWithAuth = () => {
    const dispatch = useDispatch();
    const profilesWithAuthIds: string[] = useSelector(getProfilesWithAuthIds);
    const profilesWithAuthNames: string[] = useSelector(getProfilesWithAuthNames);


    const confirm = () => {
        const values = profilesWithAuthIds.map(id => ({
            hlcfgPath: hlcfgPathGetter.tables.profile[id].parameters.authentication.__off.getPath(),
            value: true
        }));
        dispatch(setHlcfgValues(values));
    };

    const close = () => {
        dispatch(setModalState({ modal: PROFILE_STILL_WITH_AUTH, value: false }));

    };

    return (
        <Modal
            body={
                <>
                    <Message message={'widgets:Authentication.modal.stillWithAuth.body'} />
                    <b className="mt-2">{profilesWithAuthNames?.join(', ')}</b>
                </>
            }
            exitHandle={close}
            headerText={'widgets:Authentication.modal.stillWithAuth.title'}
            modalOpen={true}
            negativeResponse={close}
            negativeText={'widgets:Authentication.modal.stillWithAuth.negative'}
            position="top-right"
            positiveResponse={confirm}
            positiveText={'widgets:Authentication.modal.stillWithAuth.positive'}
        />
    );
};

export default ProfileStillWithAuth;
