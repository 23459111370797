/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBModalHeader } from 'mdbreact';

import { Icon, Loading, Modal } from '~frontendComponents/Generic/index.js';
import { closeModalWindow, getFirstHlcfgTree, getSecondHlcfgTree,
    getIsLoading, getInfoFirstRevision, getInfoSecondRevision } from '~frontendDucks/hlcfgDiffer/hlcfgDiffer.js';
import Message from '~frontendRoot/components/Message/index.js';
import { getGlcfgTree, getHlcfgSchema, getIncomprehensibleHlcfgNodes } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import DifferCard from '~frontendRoot/layout/Application/components/Differs/DifferCard.tsx';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/constants.ts';
import { diffHlcfg, HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import { prepareHlcfgDiffsForDisplay } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/prepareHlcfgDiffsForDisplay.ts';
import { HlcfgContext } from '~frontendRoot/layout/Application/components/Differs/Differs.tsx';
import { transformGlcfgToHlcfg } from '~frontendLib/glcfgTransformers/index.js';
import NoData from '~frontendRoot/components/NoData/index.js';
import Loader from '~frontendComponents/Loader/Loader.tsx';


const HlcfgRevisionModal = () => {
    const dispatch = useDispatch();
    const firstHlcfg = useSelector(getFirstHlcfgTree);
    const secondHlcfg = useSelector(getSecondHlcfgTree);
    const loading = useSelector(getIsLoading);
    const firstRevision = useSelector(getInfoFirstRevision);
    const secondRevision = useSelector(getInfoSecondRevision);
    const glcfgTree = useSelector(getGlcfgTree);
    const incomprehensibleHlcfgNodes = useSelector(getIncomprehensibleHlcfgNodes);
    const hlcfgTree = transformGlcfgToHlcfg(glcfgTree, incomprehensibleHlcfgNodes);
    const schema = useSelector(getHlcfgSchema);
    if ((!firstHlcfg || !secondHlcfg) && !loading) {
        return null;
    }
    if (!schema) {
        return <Loader />;
    }
    const diffRaw: HlcfgDiff[] = loading ? EMPTY_IMMUTABLE_ARR : diffHlcfg(firstHlcfg, secondHlcfg, schema);
    const diff = loading ? EMPTY_IMMUTABLE_ARR : prepareHlcfgDiffsForDisplay(
        diffRaw,
        { from: firstHlcfg, to: secondHlcfg },
        schema
    ) as HlcfgDiff[];
    const closeModal = () => {
        dispatch(closeModalWindow());
    };
    return (
        <Modal
            body={
                <div className="minHeight-10">
                    <Loading
                        className={'loader--vertical'}
                        isLoading={loading || !schema}
                        key="loader"
                    >
                        {schema ? (
                            <HlcfgContext.Provider value={{ hlcfg: firstHlcfg, revisionHlcfg: hlcfgTree, schema }}>
                                {diff?.map(item => (
                                    <DifferCard
                                        diff={item}
                                        key={item.hlcfgDescriptivePath + 'key'}
                                        numberOfSameDiffChanges={
                                            diff.filter(value =>
                                                value.hlcfgDescriptivePath[0] === item.hlcfgDescriptivePath[0]).length}
                                    />
                                ))}
                                {diff?.length === 0 &&
                                    <NoData><Message message="widgets:global.withoutData" /></NoData>}
                            </HlcfgContext.Provider>
                        ) : null}
                    </Loading>
                </div>

            }
            classNameBody={'differs__changes p-2 m-0'}
            clickOutsideClose
            exitHandle={closeModal}
            header={
                <MDBModalHeader  toggle={closeModal}>
                    <Message message={'widgets:ConfigurationRevisionList.columns.changed.displayDiff.title'} />
                    {!loading &&
                    <>
                        <span className="m-1">
                            {firstRevision?.commitHash}
                            <Icon
                                name="arrow-right"
                                size="sm"
                            />
                            {secondRevision?.commitHash}
                        </span>
                        <small>
                            {secondRevision.date}
                        </small>
                    </>
                    }
                </MDBModalHeader>}
            headerClose
            modalOpen={true}
            position="top-right"
            positiveResponse={closeModal}
        >
            <></>
        </Modal>
    );
};

export default HlcfgRevisionModal;
