/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import { setValue } from '~commonLib/objectUtils.ts';
import { FromToHlcfg, LeafDiffInfo } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/types.ts';
import { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import {
    getHlcfgVal,
    replaceValuesWithUndefinedIfBothDefault
} from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/helpers.ts';
import { deepCloneAndMerge } from '~commonLib/deepCloneAndMerge/deepCloneAndMerge.ts';
import { descriptiveHlcfgPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath.ts';


const liftValue = (key: 'fromValue' | 'toValue') => info => {
    if (!(key in info.diff)) {
        return {};
    }
    const pathWithinLeaf = info.diff.hlcfgDescriptivePath.slice(info.leafPath.length);
    const liftedObj = {};
    setValue(liftedObj, pathWithinLeaf, info.diff[key]);
    return liftedObj;
};
export const liftedLeafDiffInfoListToRegularDiff =
    (leafInfoList: LeafDiffInfo[], hlcfgTree: FromToHlcfg): HlcfgDiff => {
        assert(leafInfoList.length);
        const fromValues = leafInfoList.map(liftValue('fromValue'));
        const toValues = leafInfoList.map(liftValue('toValue'));

        const pathLengthDiff = leafInfoList[0].diff.hlcfgDescriptivePath.length - leafInfoList[0].leafPath.length;


        return {
            ...replaceValuesWithUndefinedIfBothDefault(
                deepCloneAndMerge(
                    getHlcfgVal(hlcfgTree.from, descriptiveHlcfgPathToRealPath(leafInfoList[0].leafPath)),
                    ...fromValues
                ),
                deepCloneAndMerge(
                    getHlcfgVal(hlcfgTree.to, descriptiveHlcfgPathToRealPath(leafInfoList[0].leafPath)),
                    ...toValues
                ),
                leafInfoList[0].leafSchema
            ),
            hlcfgRealPath: descriptiveHlcfgPathToRealPath(leafInfoList[0].leafPath),
            hlcfgDescriptivePath: leafInfoList[0].diff.hlcfgDescriptivePath
                .slice(0, leafInfoList[0].diff.hlcfgDescriptivePath.length - pathLengthDiff),
            alsoRemovesRefsFromPaths: [], // lifted diffs should never remove rows.
        };
    };
