/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React from 'react';

import {
    SEQUENCE_ABORTED,
    STEP_ABORTED,
    STEP_DONE,
    STEP_ERROR,
    STEP_PROGRESS,
    STEP_STARTED,
} from '~frontendLib/actionSequence/lib.ts';
import ApiError from '~frontendComponents/Generic/ApiError/index.js';

import { typeApiError, typeTimestamp } from '../../../types/index.js';
import Moment from '../../Moment/index.js';
import Message from '../../Message/index.js';


const STYLE_PROGRESS_STEP_STARTED = {};

const STYLE_PROGRESS_STEP_OTHER = {
    marginLeft: '1rem',
};

const ProgressStepAborted = ({ actionStep, event, steps }) => {
    const { StepAbortedTitle } = steps[actionStep];
    return (<StepAbortedTitle event={event} />);
};

ProgressStepAborted.propTypes = {
    actionStep: PropTypes.string.isRequired,
    event: PropTypes.object,
    steps: PropTypes.object.isRequired,
};


const ProgressStepStarted = ({ actionStep, event, steps }) => {
    const { StepLoadingTitle } = steps[actionStep];
    return (<StepLoadingTitle event={event} />);
};

ProgressStepStarted.propTypes = {
    actionStep: PropTypes.string.isRequired,
    event: PropTypes.object,
    steps: PropTypes.object.isRequired,
};


const ProgressStepDone = ({ actionStep, event, steps }) => {
    const { StepDoneTitle } = steps[actionStep];
    return (<StepDoneTitle event={event} />);
};

ProgressStepDone.propTypes = {
    actionStep: PropTypes.string.isRequired,
    event: PropTypes.object,
    steps: PropTypes.object.isRequired,
};


const ProgressStepError = ({ actionStep, error, event, steps }) => {
    const { StepErrorTitle } = steps[actionStep];
    return (
        <StepErrorTitle
            error={error}
            event={event}
        />
    );
};

ProgressStepError.propTypes = {
    actionStep: PropTypes.string.isRequired,
    error: typeApiError,
    event: PropTypes.object,
    steps: PropTypes.object.isRequired,
};


const ProgressStepProgress = ({ actionStep, event, steps, filter, title }) => {
    const { StepProgress } = steps[actionStep];
    return (
        <StepProgress
            event={event}
            filter={filter}
            title={title}
        />
    );
};

ProgressStepProgress.propTypes = {
    actionStep: PropTypes.string.isRequired,
    event: PropTypes.object,
    steps: PropTypes.object.isRequired,
    filter: PropTypes.bool,
    title: PropTypes.bool,
};


const ProgressStepInner = ({ actionStep, error, event, status, steps, title }) => {
    if (!actionStep && status === SEQUENCE_ABORTED) {
        // aborted by user request
        return null;
    }
    if (!steps[actionStep]) {
        throw new Error(`Action step "${actionStep}" is not defined in steps`);
    }
    switch (status) {
    case STEP_ABORTED:
        return (
            <ProgressStepAborted
                actionStep={actionStep}
                event={event}
                steps={steps}
            />
        );
    case STEP_PROGRESS:
        return (
            <ProgressStepProgress
                actionStep={actionStep}
                event={event}
                steps={steps}
                title={title}
            />
        );
    case STEP_STARTED:
        return (
            <ProgressStepStarted
                actionStep={actionStep}
                steps={steps}
            />
        );
    case STEP_DONE:
        return (
            <ProgressStepDone
                actionStep={actionStep}
                steps={steps}
            />
        );
    case STEP_ERROR:
        if (title) {
            return (
                <ProgressStepError
                    actionStep={actionStep}
                    event={event}
                    steps={steps}
                />
            );
        }
        return (
            <ApiError
                error={error}
                isInline={false}
                title={(
                    <ProgressStepError
                        actionStep={actionStep}
                        event={event}
                        steps={steps}
                    />
                )}
            />
        );
    default:
        throw new Error(`Unknown action step progress status "${status}"`);
    }
};

ProgressStepInner.propTypes = {
    actionStep: PropTypes.string,
    error: typeApiError,
    event: PropTypes.object,
    status: PropTypes.string.isRequired,
    steps: PropTypes.object.isRequired,
    title: PropTypes.bool
};


const Timestamp = ({ timestamp }) => {
    if (!timestamp) {
        return null;
    }
    return (
        <>
            <Moment
                format="LTS"
                value={timestamp}
            />
            <> </>
        </>
    );
};

Timestamp.propTypes = {
    timestamp: typeTimestamp,
};


/**
 * Renders a single progress step of an action sequence.
 */
class ActionSequenceProgressStep extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    render() {
        const { objProgress, steps, textCopyArray, title, filter } = this.props;
        if (!objProgress) {
            return null;
        }
        const { actionStep, status, event, error, timestamp } = objProgress;
        const isStepStarted = title || status === STEP_STARTED;
        if (!filter && (event?.currentDone?.stderr || event?.currentDone?.stderr === '')  && !title) {
            return null;
        }
        textCopyArray.push(this.myRef);
        return (
            <div
                ref={this.myRef}
                style={
                    isStepStarted ?
                        STYLE_PROGRESS_STEP_STARTED :
                        STYLE_PROGRESS_STEP_OTHER
                }
            >
                {isStepStarted ? null : '    '}
                <Timestamp timestamp={title ? null : timestamp} />
                <ProgressStepInner
                    actionStep={actionStep}
                    error={error}
                    event={event}
                    status={status}
                    steps={steps}
                    title={title}
                />
                {title && event?.currentDone?.actionObject?.takesLongTime ?
                    <Message message="actions:action.takesLongTime" /> :
                    null}
            </div>
        );
    }
}

ActionSequenceProgressStep.propTypes = {
    objProgress: PropTypes.object,
    textCopyArray: PropTypes.array.isRequired,
    steps: PropTypes.object.isRequired,
    title: PropTypes.bool,
    filter: PropTypes.bool,
};

export default ActionSequenceProgressStep;
