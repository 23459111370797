/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import {  deleteNormalize } from '~frontendDucks/hlcfgEditor/index.js';
import { getModalState, getSpecialValues, setModalState } from '~frontendDucks/modals/index.js';
import {  DELETE_CONFIRM_PROFILE, PROFILES } from '~frontendConstants/index.js';


@connect(
    state => ({
        confirmModal: getModalState(state, DELETE_CONFIRM_PROFILE),
        specialValues: getSpecialValues(state),


    }),
    {
        doSetModalState: setModalState,
        del: deleteNormalize
    }
)
class ConfirmDeleteProfile extends Component {
    static get propTypes() {
        return {
            del: PropTypes.func,
            specialValues: PropTypes.object,
            doSetModalState: PropTypes.func,
            confirmModal: PropTypes.bool,
        };
    }

    confirm = () => {
        const { del, specialValues } = this.props;
        del({ type: PROFILES, value: specialValues.profile });
    };

    closeModal = () => {
        const { doSetModalState } = this.props;
        doSetModalState({ modal: DELETE_CONFIRM_PROFILE,
            value: false });
    };

    render() {
        const {  confirmModal,
        } = this.props;
        return (
            <Modal
                bodyText="profile:modal.body"
                exitHandle={this.closeModal}
                headerText="profile:modal.header"
                modalOpen={confirmModal}
                negativeResponse={this.closeModal}
                position="top-right"
                positiveResponse={this.confirm}
            />
        );
    }
}

export default ConfirmDeleteProfile;
