/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { createContext, useCallback, useContext } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import assert from 'assert';
import { useSelector } from 'react-redux';

import { Icon } from '~frontendComponents/Generic/index.js';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { SelectOption, SelectParserResult, SelectV2BaseProps, SelectV2Group } from '~frontendComponents/Generic/SelectV2/types.ts';
import { useSingleValueSelectWrapper } from '~frontendComponents/Generic/SelectV2/utils.ts';
import Message from '~frontendComponents/Message/Message.tsx';
import { DOMAIN_COLOR, ERROR_COLOR, INTERFACE_COLOR, NAMED_OBJECT_COLOR, NEGATED_COLOR, NETWORK_ADDRESS_COLOR } from '~frontendConstants/constants.ts';
import { ADDRESS_SELECTOR_KEY, AddressesSelector, isAddressesSelector } from '~sharedLib/addressesSelectorUtils.ts';
import { isNamedObjectObjRef, NamedObjectReference } from '~sharedLib/namedObjectUtils.ts';
import { netaddr, stringifyAsNetaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { netaddrValidate } from '~sharedLib/Netaddr/netaddrValidate.ts';
import { NetaddrDataObj, TypeNetaddr } from '~sharedLib/types.ts';
import { NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';
import { AddressSelectorResolutionErr, AddressSelectorResolutionOk, AddressSelectorResolver, getNamedObjectOptions, getViableMultiAddressSelectorOptions, getViableSingleAddressSelectorOptions, NamedObjectResolutionErr, NamedObjectResolutionOk, NamedObjectResolver, useAddressSelectorResolver, useNamedObjectRefResolver } from '~frontendDucks/hlcfgEditor/netaddrHlcfgSelectResolvers.ts';
import { testProps } from '~commonLib/PageObjectMap.ts';
import { SELECT_PO_LABELS } from '~frontendComponents/Generic/SelectV2/constants.ts';
import { useConstant } from '~frontendLib/hooks/defaultHooks.ts';
import { getEnabledNetaddrSelectInterfaceNamesById } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSetters.js';
import { isNetaddrDhcpData } from '~commonLib/Netaddr/NetaddrDhcp.ts';
import { getNamedObjectNetaddrConfigured } from '~frontendDucks/hlcfgEditor/namedObjectsGettersAndSetters.ts';
import { JSXElement } from '~commonLib/types.ts';
import { objectPick } from '~commonLib/objectUtils.ts';


export type NetaddrSelectValue = NetaddrDataObj|NonNetaddrSelectValue;

export const NetaddrSelectBase = (props: SingleVal & NetaddrSelectBaseProps) => {
    return (
        <NetaddrCtxProvider {...pickCtx(props)}>
            <SelectV2
                {...useNetaddrSelectModel(props)}
                {...props}
                {...useSingleValueSelectWrapper(props)}
            />
        </NetaddrCtxProvider>
    );
};
export const NetaddrSelect = (props: SingleVal & NetaddrSelectCommonProps) => {
    return (
        <NetaddrSelectBase
            {...useSelectHlcfgBindings({ singleValue: true })}
            {...props}
        />
    );
};

export const NetaddrArraySelectBase = (props: ArrayVal & NetaddrSelectBaseProps) => {
    return (
        <NetaddrCtxProvider {...pickCtx(props)}>
            <SelectV2
                {...useNetaddrSelectModel({ ...props, isRequired: false })}
                {...props}
            />
        </NetaddrCtxProvider>
    );
};
export const NetaddrArraySelect = (props: ArrayVal & NetaddrSelectCommonProps) => {
    return (
        <NetaddrArraySelectBase
            {...useSelectHlcfgBindings({ singleValue: false })}
            {...props}
        />
    );
};

const defaultNegatableList: NegatableList = { list: [] };
export const NegatableNetaddrListSelectBase = (props: NegatableVal & NetaddrSelectBaseProps) => {
    const { onChange, value = defaultNegatableList, ...rest } = props;
    const negated = value.negated;
    const onChangeClbk = useCallback(newValue => {
        onChange({ list: newValue, negated: negated });
    }, [ negated, onChange ]);
    return (
        <div>
            <button
                onClick={() => onChange({ list: value.list, negated: !value.negated })}
                {...testProps(SELECT_PO_LABELS.negatableButton)}
            >TOGGLE ME! Negated: {(!!value.negated).toString()}
            </button>
            <NetaddrCtxProvider {...pickCtx(props)}>
                <SelectV2
                    {...useNetaddrSelectModel({
                        ...rest,
                        isRequired: false,
                        colors: value.negated ? negatedNetaddrColors : defaultNetaddrColors
                    })}
                    {...rest}
                    onChange={onChangeClbk}
                    value={value.list}
                />
            </NetaddrCtxProvider>
        </div>
    );
};
export const NegatableNetaddrListSelect = (props: NegatableVal & NetaddrSelectCommonProps) => {
    return (
        <NegatableNetaddrListSelectBase
            {...useSelectHlcfgBindings({ singleValue: false })}
            {...props}
        />
    );

};

type NegatableList = {list: NetaddrSelectValue[], negated?: boolean};
interface NegatableVal {
    value?: NegatableList,
    onChange: (newValue: NegatableList) => void,
}
interface ArrayVal {
    value: NetaddrSelectValue[],
    onChange: (newValue: NetaddrSelectValue[]) => void,
}
type NonNetaddrSelectValue = AddressesSelector|NamedObjectReference
interface NetaddrSelectCommonProps extends SelectV2BaseProps {
    netaddrType: TypeNetaddr,
}
interface NetaddrSelectBaseProps extends NetaddrSelectCommonProps, NetaddrModelProps {}

interface NetaddrSelectCtxProps {
    stringifyNamedObject?: (value: NamedObjectReference) => string,
    stringifyAddressesSelector?: (value: AddressesSelector) => string,
    isCluster?: boolean,
    hostnameB?: string
    hostnameA?: string
}
interface NetaddrModelProps extends NetaddrSelectCtxProps {
    netaddrType: TypeNetaddr,
    resolveNamedObjectAddresses?: NamedObjectResolver,
    resolveSelectorAddresses?: AddressSelectorResolver,
    colors?: NetaddrColor,
}

interface SingleVal {
    isRequired: boolean,
    value: NetaddrSelectValue|undefined,
    onChange: (newValue: NetaddrSelectValue|undefined) => void,
}

const pickCtx = (props: NetaddrSelectCtxProps) => {
    return objectPick(props, [
        'stringifyNamedObject', 'stringifyAddressesSelector', 'isCluster', 'hostnameA', 'hostnameB'
    ]);
};
const NetaddrCtxProviderNoMemo = (props: NetaddrSelectCtxProps&{children: JSXElement}) => {
    const { children, ...ctx } = props;
    return (
        <NetaddrSelectContext.Provider value={ctx}>
            {children}
        </NetaddrSelectContext.Provider>
    );
};
const NetaddrCtxProvider = React.memo(NetaddrCtxProviderNoMemo);
type HlcfgBindingsOpts = {singleValue?: boolean};
const useSelectHlcfgBindings = (opts: HlcfgBindingsOpts) => {
    const interfaceNamesById = useSelector(getEnabledNetaddrSelectInterfaceNamesById);
    const namedObjectAll = useSelector(getNamedObjectNetaddrConfigured);
    const { t } = useTranslation();
    return {
        resolveNamedObjectAddresses: useNamedObjectRefResolver(),
        resolveSelectorAddresses: useAddressSelectorResolver(),
        stringifyAddressesSelector: useCallback((selector: AddressesSelector) =>
            stringifyAddrSelector(selector, interfaceNamesById, t), [ interfaceNamesById, t ]),
        stringifyNamedObject: useCallback((namedObjectReference: NamedObjectReference) =>
            stringifyNamedObject(namedObjectReference, namedObjectAll, t), [ namedObjectAll, t ]),
        options: useAddressSelectorAndNamedObjectOptions(opts),
    };
};

const useAddressSelectorAndNamedObjectOptions = (opts: HlcfgBindingsOpts) => {
    const getter = opts.singleValue ? getViableSingleAddressSelectorOptions : getViableMultiAddressSelectorOptions;
    const addressSelectors = useSelector(getter);
    const namedObjects = useSelector(getNamedObjectOptions);
    return [ ...namedObjects, ...addressSelectors ];
};

type NetaddrColor = {
    addressSelector: string,
    error: string,
    namedObject: string,
    domain: string,
    network: string,
    interface: string,
    simpleAddr?: string,
}
const defaultNetaddrColors: NetaddrColor = {
    addressSelector: INTERFACE_COLOR,
    error: ERROR_COLOR,
    namedObject: NAMED_OBJECT_COLOR,
    domain: DOMAIN_COLOR,
    network: NETWORK_ADDRESS_COLOR,
    interface: INTERFACE_COLOR,
};
const negatedNetaddrColors: NetaddrColor = {
    addressSelector: INTERFACE_COLOR,
    error: ERROR_COLOR,
    namedObject: NAMED_OBJECT_COLOR,
    domain: DOMAIN_COLOR,
    network: NEGATED_COLOR,
    interface: NEGATED_COLOR,
    simpleAddr: NEGATED_COLOR,
};
const NetaddrSelectContext = createContext(undefined as undefined|Partial<NetaddrModelProps>);
const useNetaddrSelCtx = () => {
    return useContext(NetaddrSelectContext) ?? {};
};
const useNetaddrSelectModel = (
    {
        isRequired,
        netaddrType,
        resolveNamedObjectAddresses,
        resolveSelectorAddresses,
        colors = defaultNetaddrColors,
        stringifyAddressesSelector,
        stringifyNamedObject,
    }: Pick< SingleVal, 'isRequired' >&NetaddrModelProps
) => {
    const { t } = useTranslation();
    const prepareOption = useCallback((value: NetaddrSelectValue): SelectOption<NetaddrSelectValue> => {
        if (isAddressesSelector(value)) {
            assert(resolveSelectorAddresses, 'Address selector in select without address selector resolver provided');
            assert(stringifyAddressesSelector, 'Address selector in select without address selector stringify');
            const result = resolveSelectorAddresses(value);
            if (result.isOk()) {
                const { addrsByNode, color } = result.unwrap();
                const allAddrs = [ ...addrsByNode.shared, ...addrsByNode[NODE_A_ID], ...addrsByNode[NODE_B_ID] ];
                return {
                    label: <AddressSelectorLabel selector={value} />,
                    value,
                    groupId: GROUP.addrSel,
                    notRemovable: isRequired,
                    searchStrings: [ stringifyAddressesSelector(value), ...allAddrs.map(stringifyAsNetaddr) ],
                    tooltip: <AddressSelectorTooltip addrsByNode={addrsByNode} />,
                    backgroundColor: color ?? colors.addressSelector,
                };
            } else {
                const res = result.unwrapErr();
                return {
                    label: <AddressSelectorLabel selector={value} />,
                    value,
                    groupId: GROUP.addrSel,
                    notRemovable: isRequired,
                    searchStrings: [ stringifyAddressesSelector(value) ],
                    tooltip: <AddressSelectorFailureTooltip {...res} />,
                    backgroundColor: colors.error,
                };
            }
        }
        if (isNamedObjectObjRef(value)) {
            assert(resolveNamedObjectAddresses, 'Named object in select without named object resolver provided');
            assert(stringifyNamedObject, 'Named object in select without named object stringify');
            const result = resolveNamedObjectAddresses(value);
            if (result.isOk()) {
                const allAddrs = result.unwrap();
                return {
                    label: <NamedObjectLabel namedObject={value} />,
                    value,
                    notRemovable: isRequired,
                    groupId: GROUP.namedObj,
                    searchStrings: [ stringifyNamedObject(value), ...allAddrs.map(stringifyAsNetaddr) ],
                    tooltip: <NamedObjectTooltip addresses={allAddrs} />,
                    backgroundColor: colors.namedObject,
                };
            } else {
                const res = result.unwrapErr();
                return {
                    label: <NamedObjectLabel namedObject={value} />,
                    value,
                    groupId: GROUP.namedObj,
                    notRemovable: isRequired,
                    searchStrings: [ stringifyNamedObject(value) ],
                    tooltip: <NamedObjectFailureTooltip {...res} />,
                    backgroundColor: colors.error,
                };
            }
        }

        return {
            label: stringifyAsNetaddr(value), value,
            notRemovable: isRequired, backgroundColor: getNetaddrColor(value, colors),
        };
    }, [
        isRequired, resolveNamedObjectAddresses, resolveSelectorAddresses,
        colors, stringifyAddressesSelector, stringifyNamedObject
    ]);

    const parse = useCallback((strVal: string): SelectParserResult<NetaddrSelectValue> => {
        try {
            const netaddrObj = netaddr(strVal);
            const errors = netaddrValidate(it => it, netaddrObj, netaddrType);
            if (errors.length) {
                if (netaddrObj.isIp() && netaddrObj.isNetIfAddress()) {
                    const network = netaddrObj.toNetworkOrSimpleAddr();
                    const errors = netaddrValidate(it => it, network, netaddrType);
                    if (errors.length) {
                        return undefined;
                    }
                    return {
                        suggest: {
                            value: network.toObject(),
                            suggestDescription: (
                                <SuggestNetwork
                                    network={network.toString()}
                                    origAddr={netaddrObj.toString()}
                                />
                            )
                        }
                    };

                }
                return undefined;
            }
            return { parsed: netaddrObj.toObject() };
        } catch (err) {
            return undefined;
        }
    }, [ netaddrType ]);

    const stringify = useCallback((value: NetaddrSelectValue) => {
        if (isAddressesSelector(value)) {
            assert(stringifyAddressesSelector);
            return stringifyAddressesSelector(value);
        }
        if (isNamedObjectObjRef(value)) {
            assert(stringifyNamedObject);
            return stringifyNamedObject(value);
        }
        return stringifyAsNetaddr(value);
    }, [ stringifyAddressesSelector, stringifyNamedObject ]);

    const groups: SelectV2Group[] = useConstant([
        { groupId: GROUP.namedObj, label: t('widgets:NamedObjects.title') },
        { groupId: GROUP.addrSel, label: t('widgets:network.interfaces') },
    ]);
    return {
        prepareOption,
        parse,
        groups,
        options: [],
        stringify: stringify,
    };
};
const GROUP = {
    namedObj: 'namedObj',
    addrSel: 'addrSel',
};

const getNetaddrColor = (value: NetaddrDataObj, colors: NetaddrColor) => {
    const addr = netaddr(value);
    if (addr.isDomain()) {
        return colors.domain;
    }

    if (addr.isIp()) {
        if (addr.isNetworkAddress()) {
            return colors.network;
        }
        if (addr.isNetIfAddress()) {
            return colors.interface;
        }
        return colors.simpleAddr;
    }
};

const SuggestNetwork = ({ network, origAddr }: {network: string, origAddr: string}) => {
    return  (
        <div>
            <div>
                <Icon
                    className="icon--yellow mr-1 ml-2 select--createNetworkIcon"
                    name="alert-outline"
                    size="sm"
                />
            </div>
            <span className="select--createNetwork">
                <Message
                    message="widgets:global.createNetwork.part1"
                    params={{
                        value: origAddr,
                    }}
                />
                <strong>{network}</strong>
                <Message message="widgets:global.createNetwork.part2" />
            </span>
        </div>
    );
};

const stringifyAddrSelector = (
    selector: AddressesSelector,
    interfaceNamesById: Record<string, string>,
    t: TFunction
): string => {
    const content = selector[ADDRESS_SELECTOR_KEY];
    const { ipVersion, addressType } = content;
    const trKey = selectorTranslationKeyMap[ipVersion][addressType];
    if ('ifaceType' in content) {
        const { ifaceType } = content;
        assert(ifaceType === 'isInternal' || ifaceType === 'isExternal');
        const typeTrKey = ifaceType === 'isInternal' ? 'allInternal' : 'allExternal';
        return t(`widgets:global.${typeTrKey}.${trKey}`);

    }
    const { ifaceId } = content;
    const ifaceName = interfaceNamesById[ifaceId];
    return t(`widgets:network.selector.${trKey}`, { interface: ifaceName ?? t('widgets:global.unknownIface') });
};

const stringifyNamedObject = (
    namedObjectRef: NamedObjectReference,
    namedObjectsById: Record<string, {name: string}>,
    t: TFunction
): string => {
    return namedObjectsById[namedObjectRef.__namedObjectReference]?.name ?? t('widgets:global.unknownNamed');
};

const stringifyAddrs = (addrs: NetaddrDataObj[], t: TFunction): string => {
    return addrs.map(it => {
        if (isNetaddrDhcpData(it)) {
            return t('widgets:network.selector.dhcpUnknown');
        }
        return stringifyAsNetaddr(it);
    }).join(', ');
};
const AddressSelectorTooltip = ({ addrsByNode }: AddressSelectorResolutionOk) => {
    const { isCluster, hostnameA, hostnameB } = useNetaddrSelCtx();
    const { t } = useTranslation();
    if (!isCluster) {
        return stringifyAddrs(addrsByNode.shared, t);
    }
    return (
        <div>
            <Message message="widgets:network.cluster.clusterNode.shared" />: {
                stringifyAddrs(addrsByNode.shared, t)
            }<br />
            {hostnameA}: {stringifyAddrs(addrsByNode[NODE_A_ID], t)}<br />
            {hostnameB}: {stringifyAddrs(addrsByNode[NODE_B_ID], t)}<br />
        </div>
    );
};
const AddressSelectorFailureTooltip = ({ type, value }: AddressSelectorResolutionErr) => {
    const { t } = useTranslation();
    if (type === 'missingInterface') {
        const selectorContent = value[ADDRESS_SELECTOR_KEY];
        assert('ifaceId' in selectorContent, 'If this ifaceId doesnt exist, then the error is clearly wrong');
        return t('widgets:network.selector.missingInterface', { ifaceId: selectorContent.ifaceId });
    } else {
        return t('widgets:network.selector.emptySelector');
    }
};
const selectorTranslationKeyMap = {
    ipv6: {
        address: 'address6',
        network: 'network6',
        main_address: 'primaryAddress6',
    },
    ipv4: {
        address: 'address',
        network: 'network',
        main_address: 'primaryAddress',
    }
};
const AddressSelectorLabel = ({ selector }: { selector: AddressesSelector }) => {
    const { stringifyAddressesSelector } = useNetaddrSelCtx();
    assert(stringifyAddressesSelector, 'Address selector in select without address selector stringify in label');
    return stringifyAddressesSelector(selector);
};
const NamedObjectLabel = ({ namedObject }: { namedObject: NamedObjectReference }) => {
    const { stringifyNamedObject } = useNetaddrSelCtx();
    assert(stringifyNamedObject, 'Named object in select without named object stringify in label');
    return stringifyNamedObject(namedObject);
};
const NamedObjectFailureTooltip = ({ type, value }: NamedObjectResolutionErr) => {
    const { t } = useTranslation();
    switch (type) {
    case 'missingThisNamedObject':
        return t('widgets:NamedObjects.missingThis', { id: value.__namedObjectReference });
    case 'emptyNamedObject':
        return t('widgets:NamedObjects.empty');
    case 'circularNamedObject':
        return t('widgets:NamedObjects.circular', { name: value.name });
    case 'missingNestedNamedObject':
        return t('widgets:NamedObjects.missingNested', { name: value.name });
    default:
        throw new Error('Unreachable');
    }
};
const NamedObjectTooltip = ({ addresses }: {addresses: NamedObjectResolutionOk}) => {
    return addresses.map(stringifyAsNetaddr).join(', ');
};
