/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { addToNormalize, duplicateFromNormalize, setNormalize } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import {
    ADDRESSES_TABLE,
    ADDRESSES_TYPE_PROFILE,
    TableSizeType } from '~frontendConstants/index.js';
import SocketAddresses, { SocketAddressesFuncContext } from '~frontendRoot/components/SocketAddresses/index.ts';
import { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { getAddressTableProfileSelector } from '~frontendRoot/ducks/hlcfgEditor/glcfgGettersAndSettersTS.ts';


type ProfileAddressesProps = {
    active: HlcfgRowId,
    spacing: TableSizeType
}

const ProfileAddresses = ({ active, spacing }: ProfileAddressesProps) => {

    const dispatch = useDispatch();


    const passReorderData = useCallback((value) => {
        dispatch(setNormalize({
            uuid: active,
            key: 'parameters',
            subkey: 'addressesTable',
            value
        }));

    }, [ active,  dispatch ]);


    const add = useCallback((uuid, addingAfter) => {
        dispatch(addToNormalize({
            type: ADDRESSES_TABLE,
            addingAfter: typeof addingAfter === 'boolean',
            uuid: active,
            successText: 'profile:profiles.addressTable.added',
            uuidToAddBefore: uuid,
            typeId: 'profile',
            key: 'parameters',
            subkey: ADDRESSES_TABLE,
        }));

    }, [ active, dispatch ]);


    const copy = useCallback((uuid: HlcfgRowId) => {
        dispatch(duplicateFromNormalize({
            uuid: uuid,
            parentKey: 'parameters',
            parentSubkey: ADDRESSES_TABLE,
            parentUuid: active,
        }));

    }, [ active, dispatch ]);

    const del = useCallback((uuid: HlcfgRowId) => {
        dispatch(setNormalize({
            uuid: active,
            value: uuid,
            typeId: 'profile',
            key: 'parameters',
            subkey: ADDRESSES_TABLE,
        }));

    }, [ active, dispatch ]);


    return (
        <SocketAddressesFuncContext.Provider value={{
            add, copy, del, passReorderData,
            addressesType: ADDRESSES_TYPE_PROFILE,
            selector: getAddressTableProfileSelector
        }}
        >
            <SocketAddresses
                isHiddenTableShow
                spacing={spacing}
            />
        </SocketAddressesFuncContext.Provider>
    );
};

export default ProfileAddresses;
