/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useSelector, useDispatch } from 'react-redux';
import React, { useCallback, useEffect } from 'react';
import { MDBCard, MDBCardTitle } from 'mdbreact';
import { useTranslation } from 'react-i18next';

import { SELECTABLE_TABLE_CHANGES, SMALL_SIZE, TableSizeType } from '~frontendConstants/index.js';
import DatatableWidget from '~frontendWidgets/DatatableWidget/index.ts';
import {
    getCurrentPage,
    getError, getIsLoading,
    getRowsPerPage, getLength,
    getRows, setCurrentPage, setRowsPerPage, cfgRevisionListRequest
} from '~frontendDucks/cfgRevisionList/index.js';

import Row from './components/Row/index.js';


const CreateRow = ({ dataIndex, spacing, uuid }: {dataIndex: number, spacing: TableSizeType, uuid: any}) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={'commit-' + uuid.commitHash}
            spacing={spacing}
            uuid={uuid}
        />
    );
};

const RevisionTable = () => {

    const isLoading = useSelector(getIsLoading);
    const error = useSelector(getError);
    const rows = useSelector(getRows);
    const length = useSelector(getLength);
    const currentPage = useSelector(getCurrentPage);
    const rowsPerPage = useSelector(getRowsPerPage);


    const { t } = useTranslation();
    const dispatch = useDispatch();

    const doSetCurrentPage = useCallback((page: number) => {
        dispatch(setCurrentPage(page));
    }, [ dispatch ]);

    const doSetRowsPerPage = useCallback((rowsPerPage: number) => {
        dispatch(setRowsPerPage(rowsPerPage));
    }, [ dispatch ]);

    useEffect(() => {
        dispatch(cfgRevisionListRequest());
    }, [ dispatch ]);

    return (
        <MDBCard className="card--withoutMH">
            <MDBCardTitle className="profiles__title">
                {t('widgets:ConfigurationRevisionList.title')}
            </MDBCardTitle>
            <DatatableWidget
                className="pb-0"
                columnsId={SELECTABLE_TABLE_CHANGES}
                count={length}
                createRow={CreateRow}
                data={rows}
                error={error}
                isDraggable={false}
                isLoading={isLoading}
                onChangePage={doSetCurrentPage}
                onChangeRowsPerPage={doSetRowsPerPage}
                page={currentPage}
                pagination
                rowsPerPage={rowsPerPage}
                spacing={SMALL_SIZE}
            />
        </MDBCard>
    );
};


export default RevisionTable;
