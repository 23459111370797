/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { MDBCard, MDBCardTitle, MDBCardBody, MDBBtn } from 'mdbreact';
import { withTranslation } from 'react-i18next';

import { Icon, Input, InputGroup, Loading } from '~frontendComponents/Generic/index.js';
import { typeApiError } from '~frontendTypes/index.js';


@withTranslation()
class PasswordChange extends Component {
    static get propTypes() {
        return {
            changePassword: PropTypes.func.isRequired,
            changePasswordRequest: PropTypes.func.isRequired,
            onChange: PropTypes.func.isRequired,
            isLoading: PropTypes.bool,
            differentPassword: PropTypes.bool.isRequired,
            error: typeApiError,
            done: PropTypes.bool,
            isVisible: PropTypes.bool.isRequired,
            generatedPassword: PropTypes.string.isRequired,
            handleGeneratedPassword: PropTypes.func.isRequired,
            t: PropTypes.func.isRequired
        };
    }

    handleOnChange = ({ value, id, name }) => {
        this.props.onChange(value, id, name);
    };

    render() {
        const { changePassword,
            isVisible,
            t,
            generatedPassword,
            handleGeneratedPassword,
            changePasswordRequest,
            isLoading,
            done,
            differentPassword,
            error
        } = this.props;
        if (done && isVisible) {
            setTimeout(
                function() {
                    changePassword(false);
                },
                1600,
            );
        }
        return (

            <MDBCard className={classNames('passwordChange',
                { 'passwordChange--active': isVisible })}
            >
                <MDBCardTitle>{t('users:user.menu.changePwd.title')}</MDBCardTitle>
                {isVisible ?
                    <Loading
                        className="passwordChange__loader"
                        data={done}
                        error={false}
                        isLoading={isLoading}
                    >
                        {done ?
                            <MDBCardBody className="passwordChangeBody passwordChangeBody--active">
                                <p className="infobox infobox--success ">
                                    {t('users:user.menu.changePwd.done.title')}
                                </p>
                            </MDBCardBody> :
                            <MDBCardBody className="passwordChangeBody passwordChangeBody--active">
                                <Input
                                    className="mockForm__field"
                                    error={error ? error.message : null}
                                    id="userProfileInputId1"
                                    label={t('users:user.menu.changePwd.component.currentPwd.title')}
                                    name="password"
                                    onChange={this.handleOnChange}
                                    required
                                    type="password"
                                />
                                <InputGroup
                                    className="mockForm__field"
                                    error={differentPassword ? t('users:user.menu.changePwd.diffPwd.title') : null}
                                    icon="reload"
                                    id="userProfileInputId2"
                                    isPasswordReadable
                                    label={t('users:user.menu.changePwd.component.newPwd.title')}
                                    name="newPassword1"
                                    onChange={this.handleOnChange}
                                    onClick={handleGeneratedPassword}
                                    required
                                    type="password"
                                    value={generatedPassword}
                                />
                                <Input
                                    className="mockForm__field"
                                    error={differentPassword ? t('users:user.menu.changePwd.diffPwd.title') : null}
                                    id="userProfileInputId3"
                                    label={t('users:user.menu.changePwd.component.confirmPwd.title')}
                                    name="newPassword2"
                                    onChange={this.handleOnChange}
                                    required
                                    type="password"
                                />
                                <MDBBtn
                                    className="passwordChangeBody__button btn-full"
                                    color="primary"
                                    onClick={changePasswordRequest}
                                >
                                    <Icon name="check" />
                                    {t('users:user.menu.changePwd.title')}
                                </MDBBtn>
                            </MDBCardBody>
                        }
                    </Loading> :
                    <MDBCardBody className="passwordChangeBody">

                        <p className="passwordChangeBody__text">
                            <strong>
                                {t('users:user.menu.changePwd.component.text.desc1')}
                            </strong>&nbsp;
                            {t('users:user.menu.changePwd.component.text.desc2')}
                        </p>
                        <MDBBtn
                            className="passwordChangeBody__button btn-full"
                            color="secondary"
                            onClick={() => {
                                changePassword(true);
                            }}
                            outline
                        >
                            <Icon name="key-remove" />
                            {t('users:user.menu.changePwd.title')}
                        </MDBBtn>
                    </MDBCardBody>
                }
            </MDBCard>
        );
    }
}


export default PasswordChange;
