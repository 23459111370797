/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const CONTROL_CLASS_BY_STATE = {
    disabled: 'form-control--disabled',
    invalid: 'form-control--invalid',
    warning: 'form-control--warning',
    ok: 'form-control--ok',
    focused: 'form-control--focused',
    notFocused: 'form-control--not-focused',
};

export const SELECT_PO_LABELS = {
    control: 'Control',
    valBoxPrefix: 'MultiValue-',
    removeValPrefix: 'Remove-',
    valLabelPrefix: 'Label-',
    menuOptPrefix: 'Option-',
    copyButton: 'copyToClipboard',
    negatableButton: 'negatableButton',
    input: 'Input',
};
