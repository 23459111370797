/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';

import { Select, Icon, Switch } from '~frontendComponents/Generic/index.js';
import {
    getTableSchema,
    setNormalize,
} from '~frontendDucks/hlcfgEditor/index.js';
import { getVpnServiceGetterByUuid } from '~frontendRoot/ducks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { VPN_ROUTES_TYPE_PUSH_TO_CLIENT } from '~frontendRoot/constants/index.js';

import VpnRouteTable from '../VpnRouteTable/index.js';
import { VpnRoutingTableContext } from '../VpnRouteTable/Row/index.ts';


const makeMapStateToProps = () => {
    const vpnServiceUuid = getVpnServiceGetterByUuid();
    const mapStateToProps = (state, { uuid }) => {
        return {
            data: vpnServiceUuid(state, uuid),
            schemas: getTableSchema(state, uuid)?.pushToClient?.properties,

        };
    };
    return mapStateToProps;
};
@withTranslation()
@connect(
    makeMapStateToProps,
    {
        setValue: setNormalize,
    }
)
class VpnPushToClient extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object,
            uuid: PropTypes.string,
            t: PropTypes.func,
            setValue: PropTypes.func,
            schemas: PropTypes.object,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            shownSettings: false,
        };
    }

    changeValueNetAddressesPushToClient = ({ value, name }) => {
        const { setValue, uuid } = this.props;
        setValue({ uuid: uuid, key: 'pushToClient',
            value: value,
            subkey: name });
    };

    changeValueObjectNetAddressesPushToClient = ({ value, name, id }) => {
        const { setValue, uuid } = this.props;
        setValue({ uuid: uuid, key: 'pushToClient',
            value: value,
            subkey: name, subsubkey: id });
    };

    changeValueNetAddressPushToClient = ({ value, name }) => {
        const { setValue, uuid } = this.props;
        setValue({  uuid: uuid, key: 'pushToClient',
            value: value,
            subkey: name });
    };


    changeValue = ({ value, name }) => {
        const { setValue,  uuid } = this.props;
        setValue({ value, uuid, key: 'pushToClient', subkey: name });
    };

    changeValueObject = ({ value, name, id }) => {
        const { setValue,  uuid } = this.props;
        setValue({ value, uuid, key: 'pushToClient', subkey: name, subsubkey: id });
    };


    hideSettings = () => {
        const { shownSettings } = this.state;
        this.setState({
            shownSettings: !shownSettings
        });
    };

    render() {
        const { t, data, uuid, schemas
        } = this.props;
        const { shownSettings } = this.state;
        const {
            __off, pushToClient = {} } = data;
        return (
            <MDBCard>
                <MDBCardTitle className={classNames(
                    'cardHide__title',
                    { 'cardHide__title--noBorderBottom': shownSettings },
                )}
                >
                    <div
                        className="clicable"
                        onClick={this.hideSettings}
                    >
                        {t('widgets:Vpn.header.pushToClient')}
                        <Icon
                            name={shownSettings ? 'chevron-up' : 'chevron-down'}
                        />
                    </div>
                </MDBCardTitle>

                <MDBCardBody  className={classNames('cardHide__body',
                    { 'cardHide__body--hide': !shownSettings },
                    { 'cardHide__title--borderBottom': shownSettings },)}
                >
                    {shownSettings &&
                     <MDBRow>
                         <MDBCol
                             lg="8"
                             md="8"
                             sm="12"
                         >
                             <VpnRoutingTableContext.Provider
                                 value={
                                     {
                                         type: VPN_ROUTES_TYPE_PUSH_TO_CLIENT,
                                         vpnUuid: uuid
                                     }
                                 }
                             >
                                 <VpnRouteTable
                                     data={pushToClient?.routes}
                                 />
                             </VpnRoutingTableContext.Provider>
                         </MDBCol>
                         <MDBCol
                             lg="4"
                             md="4"
                             sm="12"
                         >

                             <Select
                                 disabled={__off}
                                 id={'addresses'}
                                 isCreatable
                                 isMulti

                                 label={t('widgets:Vpn.pushToClient.nameservers.addresses.title')}
                                 message="dhcp-option DNS (windows only option)"
                                 name="nameservers"
                                 noDropdownIndicator
                                 noOptionsMessage
                                 onChange={this.changeValueObjectNetAddressesPushToClient}
                                 schema={schemas.nameservers.properties.addresses}
                                 value={pushToClient.nameservers}
                             />
                             <Switch
                                 align="spaceBetween"
                                 checked={pushToClient.force && Boolean(pushToClient.nameservers.length)}
                                 disabled={__off || !pushToClient.nameservers?.length}
                                 id={'force'}
                                 label={t('widgets:Vpn.pushToClient.nameservers.force.title')}
                                 message={'push "block-outside-dns"'}
                                 name={'nameservers'}
                                 onChange={this.changeValueObject}
                                 schema={schemas.nameservers.properties.force}

                             />
                             <Switch
                                 checked={pushToClient.defaultGateway}
                                 id="defaultGatewayId"
                                 label={t('widgets:Vpn.pushToClient.defaultGateway.title')}
                                 message={'push "redirect-gateway def1"'}
                                 name="defaultGateway"
                                 onChange={this.changeValue}
                                 schema={schemas.defaultGateway}
                             />
                             <Select
                                 disabled={__off}
                                 id={'ntpClient'}
                                 isCreatable
                                 isMulti

                                 label={t('widgets:Vpn.pushToClient.ntp.title')}
                                 message="dhcp-option NTP (windows only option)"
                                 name="ntp"
                                 noDropdownIndicator
                                 noOptionsMessage
                                 onChange={this.changeValueNetAddressesPushToClient}
                                 schema={schemas.ntp}
                                 value={pushToClient.ntp}
                             />
                             <Select
                                 disabled={__off}
                                 id="domainClient"
                                 isCreatable
                                 isMulti

                                 label={t('widgets:Vpn.pushToClient.domain.title')}
                                 message="dhcp-option DOMAIN (windows only option)"
                                 name="domain"
                                 noDropdownIndicator
                                 noOptionsMessage
                                 onChange={this.changeValueNetAddressPushToClient}
                                 schema={schemas.domain}
                                 value={pushToClient.domain}
                             />

                         </MDBCol>
                     </MDBRow>}
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default VpnPushToClient;
