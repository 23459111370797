/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * @file
 * This directory contains constants that are used throughout the frontend application or are considered very important
 * and possibly configurable in the future.
 */

export * from './constants.ts';
export * from './constantPaths.ts';
export * from './glcfgConstants.js';
export * from './reportsIdConstants.js';
export * from './dashboardsIdConstants.js';
export { NODE_B_ID } from '~sharedConstants/index.ts';
export { NODE_A_ID } from '~sharedConstants/index.ts';
