/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Reducer, useReducer } from 'react';
// eslint-disable-next-line node/file-extension-in-import
import { ActionMeta } from 'react-select';
import assert from 'assert';
import isDeepEqual from 'lodash.isequal';

import { useConstant } from '~frontendLib/hooks/defaultHooks.ts';
import { SelectOption, SelectV2InternalProps } from '~frontendComponents/Generic/SelectV2/types.ts';
import { createNotification } from '~frontendLib/reactUtils.js';


export const useSelectReducer = <T>(props: SelectV2InternalProps<T>) => {
    const initialState: SelectReducerState<T> = {
        values: props.value ?? props.defaultValue ?? [],
        inputValue: '',
        focused: false,
    };
    type Reduce = Reducer<SelectReducerState<T>, SelectReducerAction<T>>
    const reduce: Reduce = (state, action) => {
        const valueToUse = props.value ?? state.values;
        switch (action.type) {
        case 'set-options': {
            return {
                ...state,
                values: action.value
            };
        }
        case 'paste': {
            assert(props.clipboardParse);
            assert(props.prepareOption);
            const values = props.clipboardParse(action.value);
            if (values.length === 0) {
                createNotification({ title: 'widgets:global.pasteFailed', type: 'error' });
                return state;
            }
            const newValues = values.filter(it => state.values.every(existing => !isDeepEqual(it, existing.value)));
            if (newValues.length === 0) {
                createNotification({ title: 'widgets:global.pasteNoNewValues', type: 'warning' });
                return state;
            }
            const newValue = [ ...state.values, ...newValues.map(props.prepareOption) ];
            return {
                ...state,
                values: newValue
            };
        }
        case 'set-editing-item': {
            const newEditingPosition = action.value ? valueToUse.indexOf(action.value) : undefined;
            if (state.editingPosition === newEditingPosition) {
                return state;
            }
            const item = action.value;
            if (item?.notRemovable) {
                return state;
            }
            let inputValue = state.inputValue;
            if (item) {
                if (props.stringify) {
                    inputValue = props.stringify(item.value);
                } else if (typeof item.label === 'string') {
                    inputValue = item.label;
                } else {
                    throw new Error('Must provide stringify or labels must be string');
                }
            } else {
                inputValue = '';
            }
            return {
                ...state,
                editingPosition: newEditingPosition,
                inputValue,
            };
        }
        case 'set-input-value': {
            return {
                ...state,
                inputValue: action.value,
            };
        }
        case 'clear-input': {
            const stopEditState = reduce(state, { type: 'set-editing-item', value: undefined });
            if (stopEditState.editingPosition === state.editingPosition && state.inputValue === '') {
                return state;
            }
            return {
                ...stopEditState,
                inputValue: '',
            };
        }
        case 'set-focused': {
            if (action.value === state.focused) {
                return state;
            }
            if (action.value === false) {
                return {
                    ...reduce(state, { type: 'set-editing-item', value: undefined }),
                    focused: false,
                };
            }
            return {
                ...state,
                focused: true,
            };
        }
        case 'create-option': {
            assert(props.parse);
            assert(props.prepareOption);
            const parsed = props.parse(action.value);
            if (!parsed) {
                return state;
            }
            const theValue = parsed.parsed ?? parsed.suggest?.value;
            assert(theValue !== undefined);
            const newOption = props.prepareOption(theValue);
            if (state.editingPosition !== undefined) {
                return {
                    ...state,
                    inputValue: '',
                    editingPosition: undefined,
                    values: state.values.toSpliced(state.editingPosition, 1, newOption),
                };
            }
            return {
                ...state,
                inputValue: '',
                values: [ ...state.values, newOption ],
            };
        }
        case 'react-select-on-change': {
            const [ data, opts ] = action.value;
            let dataToSet = [ ...data ];
            if (opts.action === 'remove-value' || opts.action === 'pop-value') {
                if (opts.removedValue?.notRemovable) {
                    return state;
                }
            }
            if (state.editingPosition !== undefined) {
                if (opts.action === 'create-option' || opts.action === 'select-option') {
                    const addedItem = dataToSet.pop()!;
                    dataToSet.splice(state.editingPosition, 0, addedItem);
                } else if (opts.action === 'remove-value') {
                    dataToSet = valueToUse.filter(it => it !== opts.removedValue);
                } else {
                    throw new Error('Unsupported operation');
                }
            }

            return {
                ...state,
                inputValue: '',
                editingPosition: undefined,
                values: dataToSet,
            };
        }
        case 'copy-to-clipboard': {
            assert(props.stringifyForCopy);
            const toCopy = props.value ?? state.values;
            assert(toCopy.length);
            // Having side effects inside reducer - very ugly, but it works if we
            // dont care about the result or errors outside of the reducer
            const stringified = props.stringifyForCopy(toCopy.map(it => it.value));
            void navigator.clipboard.writeText(stringified).then(() => {
                createNotification({ title: 'widgets:global.copied', type: 'info' });
            }).catch(() => {
                createNotification({ title: 'widgets:global.copyFailed', type: 'error' });
            });
            return state;
        }
        default:
            throw new Error('Invalid action');
        }

    };

    const wrappedWithChangeCallback: Reduce = (state, action) => {
        const resultingState = reduce(state, action);
        if (resultingState.values !== state.values) {
            props.onChange?.(resultingState.values.map(it => it.value));
        }
        return resultingState;
    };

    const [ state, dispatch ] = useReducer(wrappedWithChangeCallback, initialState);

    const createDispatcher = <Type extends ActionType>(type: Type) =>
        (value: ActionValue<T, Type>) => dispatch({ type, value });
    const dispatchers = useConstant({
        setOptions: createDispatcher('set-options'),
        paste: createDispatcher('paste'),
        setEditingPosition: createDispatcher('set-editing-item'),
        setInputValue: createDispatcher('set-input-value'),
        onChange: createDispatcher('react-select-on-change'),
        setFocused: createDispatcher('set-focused'),
        clearInput: createDispatcher('clear-input'),
        createOption: createDispatcher('create-option'),
        copyToClipboard: createDispatcher('copy-to-clipboard'),
    });

    return [ state, dispatchers ] as const;
};

type ReactSelectOnChange<T> = (data: SelectOption<T>[], opts: ActionMeta<SelectOption<T>>) => void

type Action<Name extends string, Value> = {type: Name, value: Value}

type SelectReducerAction<T> =
    Action<'set-options', SelectOption<T>[]> |
    Action<'paste', string> |
    Action<'react-select-on-change', Parameters<ReactSelectOnChange<T>>> |
    Action<'set-input-value', string> |
    Action<'create-option', string> |
    Action<'set-focused', boolean> |
    Action<'clear-input', void> |
    Action<'copy-to-clipboard', void> |
    Action<'set-editing-item', SelectOption<T>|undefined>;

type ActionValue<T, Type extends ActionType> = Extract<SelectReducerAction<T>, {type: Type, value: any}>['value'];
type ActionType = SelectReducerAction<any>['type'];

type SelectReducerState<T> = {
    values: SelectOption<T>[],
    editingPosition?: number|undefined,
    inputValue: string,
    focused: boolean,
}
