/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBRow,  MDBCardTitle } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Select } from '~frontendComponents/Generic/index.js';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import {
    ACCEPT_ACTION,
    FORCE_ACCEPT_ACTION,
    WEB_ACTION
} from '~sharedConstants/index.ts';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/index.js';
import type { HlcfgSchemaJSON, HlcfgInputTree } from '~frontendTypes/externalTypes.ts';
import { InputCallbackFunction } from '~frontendConstants/types.ts';


interface ConditionsProps {
    rule: HlcfgInputTree['tables']['nftRule'][''],
    disabled: boolean,
    doesMatch: (value: string) => boolean,
    getComponentError:  (value: string) => boolean,
    schemas?: HlcfgSchemaJSON['properties']['tables']['properties']['nftRule']['additionalProperties']['properties']
    onChangeEvent: InputCallbackFunction,
    destinationPortOptions: any[],
    smallSpacing: boolean,
}

export const Conditions = ({ rule, disabled, doesMatch, getComponentError, schemas, smallSpacing,
    onChangeEvent, destinationPortOptions }: ConditionsProps) => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardTitle
                className={classNames(
                    { 'pt-1': smallSpacing }
                )}
            >
                <MDBRow>
                    <MDBCol lg="8">
                        {t('packetFilter:conditions.title')}
                    </MDBCol>
                </MDBRow>
            </MDBCardTitle>
            <MDBCardBody className={smallSpacing ? 'py-0' : 'pt-2 pb-1'}>
                <MDBRow>
                    <MDBCol
                        className={classNames(
                            { 'px-1': smallSpacing },
                            { 'packetFilter__ruleDetail--match': doesMatch('sourceAddress') }
                        )}
                        size={smallSpacing ? '4' : '6'}
                    >

                        <NetworkInterfaceDevice
                            disabled={disabled}
                            error={getComponentError('sourceAddress')}
                            fake={rule.fake}
                            formGroupClassName={smallSpacing ? 'mb-1' : 'mb-3'}
                            id="packetFilterSourceAddress"
                            iface={rule?.sourceAddress}
                            isCreatable
                            isMulti
                            label={t('packetFilter:source.address')}
                            name="sourceAddress"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={onChangeEvent}
                            placeholder="packetFilter:any"
                            schema={schemas?.sourceAddress}
                            withAllValues
                        />

                    </MDBCol>
                    <MDBCol
                        className={classNames(
                            { 'px-1': smallSpacing },
                            { 'packetFilter__ruleDetail--match': doesMatch('destinationAddress') }
                        )}
                        size={smallSpacing ? '4' : '6'}
                    >
                        <NetworkInterfaceDevice
                            disabled={disabled}

                            error={getComponentError('destinationAddress')}
                            fake={rule.fake}
                            formGroupClassName={smallSpacing ? 'mb-1' : 'mb-3'}
                            id="packetFilterDestinationAddress"
                            iface={rule?.destinationAddress}
                            isCreatable
                            isMulti
                            label={t('packetFilter:destination.address')}
                            name="destinationAddress"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={onChangeEvent}
                            placeholder="packetFilter:anyDstPort"
                            schema={schemas?.destinationAddress}
                            withAllValues
                        />

                    </MDBCol>
                    <MDBCol
                        className={classNames(
                            { 'px-1': smallSpacing },
                            { 'packetFilter__ruleDetail--match': doesMatch('service') }
                        )}
                        size={smallSpacing ? '4' : '6'}
                    >
                        <Select
                            classNamePrefix="packetFilterSelect"

                            disabled={rule.fake || disabled}
                            error={getComponentError('service')}
                            formGroupClassName={smallSpacing ? 'mb-1' : 'mb-3'}
                            id={rule.id + 'service'}
                            isCreatable
                            isMulti
                            label={t('packetFilter:destination.port')}
                            name="service"
                            noDropdownIndicator
                            onChange={onChangeEvent}
                            options={destinationPortOptions}
                            paste={false}
                            placeholder="packetFilter:any"
                            schema={schemas?.service}
                            value={rule.service}
                        />

                    </MDBCol>
                    {rule.action !== WEB_ACTION &&
                            <WhenAdvanced>
                                <>
                                    <MDBCol
                                        className={classNames(
                                            { 'packetFilter__ruleDetail--match': doesMatch('user') }
                                        )}
                                        size={'6'}
                                    >
                                        <Select
                                            classNamePrefix="packetFilterSelect"

                                            disabled={rule.fake || disabled}
                                            error={getComponentError('user')}
                                            formGroupClassName={smallSpacing ? 'mb-1' : 'mb-3'}
                                            id="packetFilterUser"
                                            isCreatable
                                            isMulti
                                            label={t('packetFilter:user')}
                                            name="user"
                                            noDropdownIndicator
                                            noOptionsMessage
                                            onChange={onChangeEvent}
                                            paste
                                            value={rule?.user || []}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        className={classNames(
                                            { 'packetFilter__ruleDetail--match': doesMatch('group') }
                                        )}
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <Select
                                            classNamePrefix="packetFilterSelect"
                                            disabled={rule.fake || disabled}
                                            error={getComponentError('group')}
                                            id="packetFilterGroup"
                                            isCreatable
                                            isMulti
                                            label={t('packetFilter:group')}
                                            name="group"
                                            noDropdownIndicator
                                            noOptionsMessage
                                            onChange={onChangeEvent}
                                            paste
                                            value={rule?.group || []}
                                        />
                                    </MDBCol>
                                </>
                            </WhenAdvanced>}
                    {(rule.action !== ACCEPT_ACTION &&
                                            rule.action !== FORCE_ACCEPT_ACTION) &&
                    <MDBCol className={classNames(
                        { 'px-1': smallSpacing }
                    )}
                    >

                        <NetworkInterfaceDevice
                            disabled={disabled}
                            error={getComponentError('iface')}
                            fake={rule.fake}
                            formGroupClassName={smallSpacing ? 'mb-1' : 'mb-3'}
                            id="packetFilterInterface"
                            iface={rule?.iface}
                            label={t('packetFilter:interface')}
                            maxNumberOfValues={1}
                            name="iface"
                            onChange={onChangeEvent}
                            paste={false}
                            schema={schemas?.iface}
                        />
                    </MDBCol>
                    }
                </MDBRow>
            </MDBCardBody>
        </MDBCard>

    );
};
