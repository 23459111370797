/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {  MDBRow, MDBCol } from 'mdbreact';

import Service from '~frontendComponents/Service/index.ts';
import { getComponentEnabled, setGlcfgObjectValue } from '~frontendDucks/hlcfgEditor/index.js';
import { databaseInfoRequest, databaseStateRequest } from '~frontendDucks/database/index.js';
import { REPORTER } from '~frontendRoot/constants/index.js';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/index.js';

import { DatabaseState, DatabaseActions, KbiUpload } from './components/index.js';


@connect(
    (state) => ({
        enabled: getComponentEnabled(state, REPORTER)
    }),
    {
        doDatabaseInfoRequest: databaseInfoRequest,
        doDatabaseStateRequest: databaseStateRequest,
        setValue: setGlcfgObjectValue,


    }
)
class Database extends Component {
    static get propTypes() {
        return {
            doDatabaseInfoRequest: PropTypes.func,
            doDatabaseStateRequest: PropTypes.func,
            enabled: PropTypes.bool,
            setValue: PropTypes.func,
        };
    }

    componentDidMount() {
        const { doDatabaseInfoRequest, doDatabaseStateRequest } = this.props;
        doDatabaseInfoRequest();
        doDatabaseStateRequest();
    }

    enableService = () => {
        const { setValue } = this.props;
        setValue(REPORTER, false, '__off');
    };

    disableService = () => {
        const { setValue } = this.props;
        setValue(REPORTER, true, '__off');
    };


    render() {
        const { enabled } = this.props;
        return (
            <Service
                id="Reporter"
                on={enabled}
                showService={(
                    <>
                        <MDBRow className="mb-3">
                            <MDBCol
                                lg="5"
                                md="5"
                                sm="12"
                            >
                                <DatabaseState />
                            </MDBCol>
                            <MDBCol>
                                <DatabaseActions />
                            </MDBCol>
                        </MDBRow>
                        <WhenAdvanced>
                            <MDBRow>
                                <MDBCol
                                    lg="5"
                                    md="5"
                                    sm="12"
                                >
                                    <KbiUpload />
                                </MDBCol>
                            </MDBRow>
                        </WhenAdvanced>
                    </>
                )}
                turnOff={this.disableService}
                turnOn={this.enableService}
            />
        );
    }
}

export default Database;
