/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type {
    HlcfgTypeNamedObjectScalar, HlcfgTypeNamedObjectVector
} from '~backendLib/newHlcfgGetters/hlcfgPathsAndTypes.ts';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';


export type NamedObject = HlcfgTypeNamedObjectScalar|HlcfgTypeNamedObjectVector;
export type NamedObjectReference = {__namedObjectReference: string}
export const isNamedObject = (obj: unknown) => {
    return  isNamedObjectObjRef(obj) || isNamedObjectStrRef(obj);
};
export const isNamedObjectStrRef = (obj: unknown) => {
    if (!obj) {
        return false;
    }
    if (typeof obj !== 'string') {
        return false;
    }
    return hlcfgRowIdIsFromTable(obj, 'netaddrScalar') || hlcfgRowIdIsFromTable(obj, 'netaddrVector');
};
export const isNamedObjectObjRef = (obj: unknown): obj is NamedObjectReference => {
    if (typeof obj !== 'object' || !obj) {
        return false;
    }
    return '__namedObjectReference' in obj;
};

export const namedObjectToString = obj => {
    if (typeof obj === 'string') {
        return obj;
    }
    return obj?.__namedObjectReference || '';
};

export const namedObjectStringToObject = <T extends string|undefined>(
    strNamedObject: T
): T extends string ? NamedObjectReference : undefined => {
    type TSPlsImproveThis = T extends string ? NamedObjectReference : undefined
    if (typeof strNamedObject === 'string') {
        return {
            __namedObjectReference: strNamedObject,
        } as TSPlsImproveThis;
    }
    return undefined as TSPlsImproveThis;
};
