/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';


import { getVpnLeasesSpecific } from '~frontendDucks/openvpn/index.js';
import { Select } from '~frontendRoot/components/Generic/index.js';
import moment, { TIME_FORMAT } from '~commonLib/moment.ts';


const makeMapStateToProps = () => {
    const mapStateToProps = (state, { dataIndex }) => {
        return {
            data: getVpnLeasesSpecific(state, dataIndex),
        };
    };
    return mapStateToProps;
};

@connect(
    makeMapStateToProps,
    {
    }
)
class Row extends Component {
    static get propTypes() {
        return {
            dataIndex: PropTypes.number,
            data: PropTypes.object,
            spacing: PropTypes.string,
        };
    }


    render() {
        const {
            data,
            spacing,
            dataIndex
        } = this.props;
        if (!data) {
            return null;
        }
        const { commonName, realAddress, virtualAddress,
            connectedSince,
        } = data;
        return (
            <tr
                className={classNames(
                    'dataTableWidget__Row',
                    'network__row',
                )}
                key={dataIndex + 'key'}
            >
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}

                >
                    {commonName}
                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}

                >
                    {
                        <Select
                            className="dataTableWidget__RowInput"
                            fake
                            id={'realAddress' + dataIndex}
                            isMulti
                            name="realAddress"
                            noDropdownIndicator
                            noOptionsMessage
                            noWrap
                            onChange={() => {
                                throw new Error('This shouldnt be set');
                            }}
                            value={[ realAddress ]}
                        />
                    }
                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >
                    <Select
                        className="dataTableWidget__RowInput"
                        fake
                        id={'virtualAddress' + dataIndex}
                        isMulti
                        name="virtualAddress"
                        noDropdownIndicator
                        noOptionsMessage
                        noWrap
                        onChange={() => {
                            throw new Error('This shouldnt be set');
                        }}
                        value={[ virtualAddress ]}
                    />
                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    {moment(connectedSince).format(TIME_FORMAT.userDateTimeShort)}
                </td>
            </tr>
        );
    }
}

export default Row;
