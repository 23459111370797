/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Input, Modal } from '~frontendComponents/Generic/index.js';
import { getSpecialValues, setModalState } from '~frontendRoot/ducks/modals/index.js';
import { CREATE_NEW_FILTER, userSetting } from '~frontendRoot/constants/index.js';
import { useUserSetting } from '~frontendLib/hooks/userSettings.ts';
import { useString } from '~frontendLib/hooks/defaultHooks.ts';
import { getUuid } from '~commonLib/uuid.ts';


export const CreateNewFilter = () => {

    const dispatch = useDispatch();
    const { newParams, relativeTime } = useSelector((state) => getSpecialValues(state));
    const close = () => {
        dispatch(setModalState({ modal: CREATE_NEW_FILTER, value: false }));
    };
    const { t } = useTranslation();
    const [ newName, setNewName ] = useString();
    const [ userFilters, setUserFilters ] = useUserSetting(userSetting.systemLogsFilters);
    const createNewFilter = () => {
        const filterId = `UserFilter:${getUuid()}`;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { sinceTime, untilTime, ...paramsWithoutTime } = newParams;
        setUserFilters({
            ...userFilters,
            [filterId]: {
                fake: false,
                id: filterId,
                name: newName,
                parameters: paramsWithoutTime,
                relativeTime: relativeTime,
            }
        });
    };

    return (
        <Modal
            body={
                <div>
                    <Input
                        dontDebounce
                        id={'CreateFilterName'}
                        label={t('systemLogs:createNewFilter.placeholder')}
                        name={'FilterName'}
                        onChange={setNewName.inputSet}
                        value={newName}
                    />
                </div>
            }
            clickOutsideClose
            exitHandle={close}
            headerText={'systemLogs:createNewFilter.header'}
            modalOpen={true}
            negativeResponse={close}
            negativeText={'widgets:global.close'}
            position={'top'}
            positiveResponse={createNewFilter}
            positiveText={'Create'}
            requiredIsEmpty={!newName}
            size={'lg'}
        />
    );
};
