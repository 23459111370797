/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import EventEmitter from 'stream';

import { EventAwaiter } from '~commonLib/EventAwaiter.ts';
import { range } from '~commonLib/arrayUtils.ts';
import { MAX_32_BIT_UNSIGNED_INT } from '~commonLib/constants.ts';
import { debounce, getTaskQueue } from '~commonLib/functionUtils.ts';
import { objectOmit } from '~commonLib/objectUtils.ts';
import { DataWindowType } from '~sharedLib/guiLogs/types.ts';


export type LogDataWindow<T> = {
    windowType: DataWindowType, data: T[]
}
export type LogDataWindowsRecord<T> = Record<number, LogDataWindow<T>>;
type FetchingInterface<DataT, InitOptsT> = {
    init: (initOptions: InitOptsT) => Promise<void>,
    getDataWindow: (fromWindow: number, toWindow: number) =>
        Promise<LogDataWindowsRecord<DataT>|'log-freed'>,
    stop: () => Promise<void>,
}

export type LogDataRenderOptions = {
    startSpacerHeight: number,
    endSpacerHeight: number,
    startStatus: 'loading'|'freed',
    endStatus: 'loading'|'finished-ended'|'finished-limited'|'finished-errored'|'freed',
}

/**
 * Windows to be rendered. It is array of entries.
 * The value of each entry is guaranteed to preserve referential equality.
 * The entries are guaranteed to be sorted from lowest to hightest by index.
 * Value of entry is expected to be passed to memoized react component to avoid re-rendering.
 */
export type WindowsToRender<DataT> = [idx: number, value: DataT[]][]
type UiInterface<DataT> = {
    windowsUpdated: (
        windowsToRender: WindowsToRender<DataT>,
        renderOptions: LogDataRenderOptions,
    ) => void,
}
export type LogDataLoaderModelOpts<DataT, InitOptsT> = {
    initOpts: InitOptsT,
    lineHeight: number,
    displayElementHeight: number,
    preloadWindowsCount: number,
    prerenderWindowsCount?: number,
    linesPerWindowCount: number,
    debounceMs: number,
    /**
     * Defaults to double of debounceMs
     */
    debounceMaxWaitMs?: number,
    /**
     * If loader receives incomplete window, it will refetch after this many ms
     */
    incompleteRetryMs: number,
    fetcher: FetchingInterface<DataT, InitOptsT>,
    ui: UiInterface<DataT>,
}

export class LogDataLoaderModel<DataT, InitOptsT> {

    private windowsToRenderCount: number;
    private windowHeight: number;
    private state = this.getDefaultState();
    private refetchTimeout: ReturnType<typeof setTimeout>|undefined;
    private taskQueue = getTaskQueue();

    private emitter = new EventEmitter();
    public event = new EventAwaiter(this.emitter);

    public setScrollPosition: (pos: number) => void;
    constructor(private opts: LogDataLoaderModelOpts<DataT, InitOptsT>) {
        assert((opts.prerenderWindowsCount ?? 0) <= opts.preloadWindowsCount, 'Prerender must be smaller than preload');
        this.windowHeight = opts.lineHeight * opts.linesPerWindowCount;
        this.windowsToRenderCount = Math.ceil(opts.displayElementHeight / this.windowHeight) + 1;
        this.setScrollPosition = this.opts.debounceMs ?
            debounce(
                pos => this.undebouncedSetScrollPosition(pos),
                this.opts.debounceMs,
                { maxWait: this.opts.debounceMaxWaitMs ?? this.opts.debounceMs * 2 }
            ) :
            pos => this.undebouncedSetScrollPosition(pos);
    }

    public async initialize() {
        await this.opts.fetcher.init(this.opts.initOpts);
        await this.update();
    }

    public async stop() {
        clearTimeout(this.refetchTimeout);
        await this.opts.fetcher.stop();
        await this.flushUnstartedAndAwaitPendingTasks();
    }

    private async update() {
        await this.flushUnstartedAndAwaitPendingTasks();
        this.dropLoadedOutOfBoundsWindows();
        if (this.state.uiUpdateQueued) {
            this.state.uiUpdateQueued = false;
            this.updateUi();
        }
        await this.loadWindows();
    }

    private undebouncedSetScrollPosition(pos: number) {
        assert(pos >= 0);
        const origFirstToRender = this.getFirstWindowToRender();
        const origWindowsToRender = this.getWindowIndexesToRender();
        this.state.scrollPos = pos;

        const windowsHaveShifted = origFirstToRender !== this.getFirstWindowToRender();
        if (!windowsHaveShifted) {
            return;
        }


        const newWindowsToRender = this.getWindowIndexesToRender();
        const hasLoadedWindowThatNeedsRendering = newWindowsToRender.some(idx => !origWindowsToRender.includes(idx));
        if (hasLoadedWindowThatNeedsRendering) {
            this.state.uiUpdateQueued = true;
        } else {
            const hasRenderedWindowsThatNeedToBeDropped =
            origWindowsToRender.some(idx => !newWindowsToRender.includes(idx));
            if (hasRenderedWindowsThatNeedToBeDropped) {
                this.state.uiUpdateQueued = true;
            }
        }

        void this.update();
    }
    private getDefaultState() {
        return {
            scrollPos: 0,
            uiUpdateQueued: false,
            firstLoaded: MAX_32_BIT_UNSIGNED_INT,
            lastLoaded: -1,
            maximumRenderedHeight: this.opts.lineHeight,
            windows: {} as LogDataWindowsRecord<DataT>,
            logHasBeenFreed: false,

        };
    }
    private calculateRenderOptions(windowsToRender: WindowsToRender<DataT>): LogDataRenderOptions {
        const endStatus = this.getEndStatus();
        const startStatus = this.state.logHasBeenFreed ? 'freed' : 'loading';
        if (!windowsToRender.length) {
            return { endStatus, startStatus, startSpacerHeight: 0, endSpacerHeight: this.state.maximumRenderedHeight };
        }
        const indexes = windowsToRender.map(entry => entry[0]);


        const minIndex = indexes[0];
        const startSpacerHeight = this.windowHeight * minIndex;
        const fullWindowsHeight = (windowsToRender.length - 1) * this.windowHeight;

        const maxIndex = indexes.at(-1);
        assert(maxIndex !== undefined);


        const lastWindowHeight = this.state.windows[maxIndex].data.length * this.opts.lineHeight;
        const heightBeforeEndSpacer = startSpacerHeight + fullWindowsHeight + lastWindowHeight;

        const currentDataTableHeightWithoutEndSpacer = heightBeforeEndSpacer + this.opts.lineHeight;
        if (currentDataTableHeightWithoutEndSpacer > this.state.maximumRenderedHeight) {
            this.state.maximumRenderedHeight = currentDataTableHeightWithoutEndSpacer;
        }
        return {
            endStatus: this.getEndStatus(),
            startStatus: this.state.logHasBeenFreed ? 'freed' : 'loading',
            endSpacerHeight: this.state.maximumRenderedHeight - heightBeforeEndSpacer,
            startSpacerHeight: this.windowHeight * minIndex,
        };
    }
    private getEndStatus(): LogDataRenderOptions['endStatus'] {
        if (this.state.logHasBeenFreed) {
            return 'freed';
        }
        if (this.state.lastLoaded === -1) {
            return 'loading';
        }
        switch (this.state.windows[this.state.lastLoaded].windowType) {
        case 'last':
            return 'finished-ended';
        case 'incomplete':
            return 'loading';
        case 'errored':
            return 'finished-errored';
        case 'limiting':
            return 'finished-limited';
        case 'completed':
            // This means all windows we want to have loaded have been loaded and there are more if we scroll down.
            // For that reason loading
            return 'loading';
        default:
            throw new Error('Invalid window type');
        }
    }

    private async flushUnstartedAndAwaitPendingTasks() {
        this.taskQueue.flushAndResolveUnstartedTasks();
        if (this.refetchTimeout) {
            clearTimeout(this.refetchTimeout);
            this.refetchTimeout = undefined;
        }
        await this.taskQueue.queueAndAwaitTask(() => {});
    }


    private determineWindowsToLoad() {
        const firstToLoad = this.getFirstWindowToLoad();
        const lastToLoad = this.getLastWindowToLoad();

        const isLoadedExactly = this.state.firstLoaded === firstToLoad && this.state.lastLoaded === lastToLoad;
        if (isLoadedExactly) {
            if (this.state.windows[lastToLoad].windowType === 'incomplete') {
                return { from: lastToLoad, to: lastToLoad };
            }
            return;
        }
        const hasNoOverlap = firstToLoad > this.state.lastLoaded || lastToLoad < this.state.firstLoaded;
        if (hasNoOverlap) {
            return { from: firstToLoad, to: lastToLoad };
        }
        if (firstToLoad < this.state.firstLoaded) {
            return { from: firstToLoad, to: this.state.firstLoaded - 1 };
        }
        if (this.state.lastLoaded < lastToLoad) {
            if (this.state.windows[this.state.lastLoaded].windowType === 'incomplete') {
                return { from: this.state.lastLoaded, to: lastToLoad };
            }
            return { from: this.state.lastLoaded + 1, to: lastToLoad };
        }
        throw new Error('Invalid situation while determining windows to load');
    }
    private dropLoadedOutOfBoundsWindows() {
        const firstToLoad = this.getFirstWindowToLoad();
        const lastToLoad = this.getLastWindowToLoad();

        const isLoadedExactly = this.state.firstLoaded === firstToLoad && this.state.lastLoaded === lastToLoad;
        if (isLoadedExactly) {
            return;
        }
        const hasNoOverlap = firstToLoad > this.state.lastLoaded || lastToLoad < this.state.firstLoaded;
        if (hasNoOverlap) {
            this.state.windows = {};
            const defaultState = this.getDefaultState();
            this.state.lastLoaded = defaultState.lastLoaded;
            this.state.firstLoaded = defaultState.firstLoaded;
            return;
        }
        if (lastToLoad < this.state.lastLoaded) {
            this.state.windows = objectOmit(
                this.state.windows,
                range(this.state.lastLoaded - lastToLoad, lastToLoad + 1)
            );
            this.state.lastLoaded = lastToLoad;
            return;
        }
        if (this.state.firstLoaded < firstToLoad) {
            this.state.windows = objectOmit(
                this.state.windows,
                range(firstToLoad - this.state.firstLoaded, this.state.firstLoaded)
            );
            this.state.firstLoaded = firstToLoad;
            return;
        }
        // No windows to drop.
        // This can happen at the very top when scrolling down, or at the very bottom when scrolling up.
        return;
    }
    private async loadWindows() {
        await this.taskQueue.queueAndAwaitTask(() => this.loadWindowsInner());
    }
    private async loadWindowsInner() {
        const toLoad = this.determineWindowsToLoad();
        if (!toLoad) {
            return;
        }
        const result = await this.opts.fetcher.getDataWindow(toLoad.from, toLoad.to);
        if (result === 'log-freed') {
            this.state.logHasBeenFreed = true;
            return;
        }
        const resultKeys = Object.keys(result);
        if (resultKeys.length === 1) {
            const key = Number(resultKeys[0]);
            const logHasNotChanged = result[key].windowType === 'incomplete' &&
                    this.state.windows[key]?.data.length === result[key].data.length;
            if (logHasNotChanged) {
                this.scheduleRefetch();
                this.emitter.emit('finish');
                return;
            }
        }
        Object.assign(this.state.windows, result);

        let needsUiUpdate = false;
        const firstToRender = this.getFirstWindowToRender();
        const lastToRender = this.getLastWindowToRender();
        Object.keys(result).map(Number).forEach(numKey => {
            if (numKey < this.state.firstLoaded) {
                this.state.firstLoaded = numKey;
            }
            if (numKey > this.state.lastLoaded) {
                this.state.lastLoaded = numKey;
            }
            if (numKey >= firstToRender && numKey <= lastToRender) {
                needsUiUpdate = true;
            }
        });
        if (this.state.lastLoaded !== toLoad.to || this.state.windows[toLoad.to].windowType === 'incomplete') {
            this.scheduleRefetch();
        }
        // check last window
        if (needsUiUpdate) {
            this.updateUi();
        }
        this.emitter.emit('finish');
    }

    private scheduleRefetch() {
        this.refetchTimeout = setTimeout(() => {
        // eslint-disable-next-line no-console
            void this.loadWindows().catch(err => console.error(err));
        }, this.opts.incompleteRetryMs);
    }

    private updateUi() {
        const windowsToRender = this.getWindowsToRender();
        this.opts.ui.windowsUpdated(windowsToRender, this.calculateRenderOptions(windowsToRender));
    }
    private getWindowIndexesToRender() {
        const prerender = this.opts.prerenderWindowsCount ?? 0;
        const count = this.windowsToRenderCount + 2 * prerender;
        const start = this.getFirstWindowToRender() - prerender;
        return range(count, start)
            .filter(key => this.state.windows[key]?.data.length);
    }
    private getWindowsToRender() {
        return this.getWindowIndexesToRender()
            .map((key): [number, DataT[]] => [ key, this.state.windows[key].data ]);
    }
    private getFirstWindowToRender() {
        return Math.floor(this.state.scrollPos / this.windowHeight);
    }
    private getLastWindowToRender() {
        return this.getFirstWindowToRender() + this.windowsToRenderCount;
    }
    private getFirstWindowToLoad() {
        return Math.max(0, this.getFirstWindowToRender() - this.opts.preloadWindowsCount);
    }
    private getLastWindowToLoad() {
        return this.getFirstWindowToRender() + this.windowsToRenderCount + this.opts.preloadWindowsCount;
    }


}
