/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import isEqual from 'lodash.isequal';


import { HlcfgPath } from '~sharedLib/types.ts';
import { getValue } from '~commonLib/objectUtils.ts';
import { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import { LeafPathAndSch } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/types.ts';
import { areArraysEqual } from '~frontendLib/arrayUtils.js';
import { HLCFG_OFF } from '~sharedRoot/constants/index.ts';
import { descriptiveHlcfgPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import {
    schemaIsExplicitLeafSchema,
    schemaIsImplicitLeafSchema
} from '~sharedLib/hlcfg/isHlcfgLeafSchemaPath.ts';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';
import { HlcfgInputTree } from '~sharedLib/HlcfgInputTree.ts';
import { DOES_NOT_PRODUCE_OFF_DIFF } from '~sharedLib/schemaTypes.ts';


export const findLeafPathAndSchema = (
    fullPath: HlcfgPath, fullSchema
): LeafPathAndSch | undefined => {
    for (let i = 1; i < fullPath.length; i++) {
        const path = fullPath.slice(0, fullPath.length - i);
        const schema = findSchemaByObjectPathAndSchema(descriptiveHlcfgPathToRealPath(path), fullSchema);
        if (schemaIsExplicitLeafSchema(schema)) {
            return { leafPath: path, leafSchema: schema, type: 'lifted' };
        }
    }
    const schema = findSchemaByObjectPathAndSchema(descriptiveHlcfgPathToRealPath(fullPath), fullSchema);
    if (schemaIsImplicitLeafSchema(schema) || !schema) {
        return { leafPath: fullPath, leafSchema: schema, type: 'regular' };
    }
    // no match means we need to drill down.
};

export const getHlcfgVal = (hlcfgTree: HlcfgInputTree, path: HlcfgPath) => {
    return getValue(hlcfgTree, path);
};
export const diffIsDisplayable = hlcfgSchema => (diff: HlcfgDiff) => {
    return !diffIsNotDisplayable(diff, hlcfgSchema);
};
const diffIsNotDisplayable = (diff: HlcfgDiff, hlcfgSchema) => {
    if (diffValIsEmpty(diff.fromValue) && diffValIsEmpty(diff.toValue)) {
        return true;
    }
    if (diff.hlcfgRealPath.at(-1) === HLCFG_OFF) {
        const schema = findSchemaByObjectPathAndSchema(diff.hlcfgRealPath.slice(0, -1), hlcfgSchema);
        if (schema?.[DOES_NOT_PRODUCE_OFF_DIFF]) {
            return true;
        }
    }
};
const diffValIsEmpty = value => {
    return value === undefined;
};

export const replaceValuesWithUndefinedIfBothDefault = (fromValue, toValue, leafSchema) => {
    const defaultValRaw = leafSchema?.[DEFAULT_SCHEMA_VALUE];
    const defaultVal = typeof leafSchema === 'object' && leafSchema.type === 'boolean' ?
        defaultValRaw || false :
        defaultValRaw;
    if ((fromValue === undefined || toValue === undefined) && defaultVal !== undefined) {
        const theDefinedValue = fromValue === undefined ? toValue : fromValue;
        if (isEqual(theDefinedValue, defaultVal)) {
            return { fromValue: undefined, toValue: undefined };
        }
        const fromOrDefault = fromValue === undefined ? defaultVal : fromValue;
        const toOrDefault = toValue === undefined ? defaultVal : toValue;
        return { fromValue: fromOrDefault, toValue: toOrDefault };
    }
    return { fromValue: fromValue, toValue: toValue };
};

export const arrayIsReordered = (fromArr = [], toArr = []) => {
    const fromFiltered = fromArr.filter(item => toArr.includes(item));
    const toFiltered = toArr.filter(item => fromArr.includes(item));
    return !areArraysEqual(fromFiltered, toFiltered);
};

export const isOffPath = (path: HlcfgPath) => path[path.length - 1] === HLCFG_OFF;
