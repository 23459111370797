/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

import { Scene } from '~frontendComponents/Scene/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { useHlcfgOffableValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';

import SuricataVars from './components/SuricataVars/index.js';


const SuricataVariables = () => {
    const suri = useHlcfgOffableValue(it => it.protection.suricata.service);
    return (
        <Scene>
            <Service
                id="Suricata.variables"
                on={suri.isOn}
                showService={(
                    <MDBRow>
                        <MDBCol
                            className="mb-2 mb-lg-4"
                            lg="6"
                            md="6"
                            sm="6"
                        >
                            <SuricataVars />
                        </MDBCol>
                        <MDBCol
                            className="mb-2 mb-lg-4"
                            lg="6"
                            md="6"
                            sm="6"
                        >
                            <SuricataVars ports />
                        </MDBCol>
                    </MDBRow>
                )}
                turnOff={suri.setOff}
                turnOn={suri.setOn}
            />
        </Scene>
    );
};

export default SuricataVariables;
