/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {  MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {  setClosedToPacketFilterRules } from '~frontendDucks/hlcfgEditor/index.js';
import { ALLOW_DNS_RULE_NAME, FIRST_HEADER_UUID, ALLOW_DNS_RULE_UUID  } from '~sharedConstants/index.ts';
import { saveChosenRuleUuid } from '~frontendDucks/packetFilterRules/index.js';
import { PROTECTION_PACKET_FILTER_SCENE_PATH } from '~frontendScenes/Protection/scenes/constants.js';


@withTranslation()
@connect(
    () => ({


    }),
    {
        doSaveChosenRuleUuid: saveChosenRuleUuid,
        doSetCloseToHeader: setClosedToPacketFilterRules

    }
)
class DnsRules extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            doSaveChosenRuleUuid: PropTypes.func,
            doSetCloseToHeader: PropTypes.func,
        };
    }

    onChangeEvent = () => {
        const { doSaveChosenRuleUuid, doSetCloseToHeader } = this.props;
        doSetCloseToHeader({ uuid: FIRST_HEADER_UUID, value: false });
        doSaveChosenRuleUuid(ALLOW_DNS_RULE_UUID);
    };

    render() {
        const { t, } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    {t('widgets:Dns.dnsRules.title')}
                </MDBCardHeader>
                <MDBCardBody>
                    {t('widgets:Dns.dnsRules.desc1')}
                    <div
                        className="d-inline-block"
                        onClick={this.onChangeEvent}
                    >
                        <Link to={{
                            pathname: PROTECTION_PACKET_FILTER_SCENE_PATH,
                            state: {
                                openFromLink: true
                            } }}
                        >
                            {ALLOW_DNS_RULE_NAME}
                        </Link>
                    </div>
                    {t('widgets:Dns.dnsRules.desc2')}
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default DnsRules;
