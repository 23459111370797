/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import IconWithTooltip from '~frontendComponents/IconWithTooltip/IconWithTooltip.js';
import Message from '~frontendComponents/Message/index.js';

import Icon, { IconNameType } from '../Icon/Icon.tsx';


export type OptionObjectType = {
    name: string,
    iconName: IconNameType,
    color?: string,
}

export const createOptionsArray = (optionsObjectArray: OptionObjectType[], tooltipTranslationPath: string) => {
    return optionsObjectArray.map((option: OptionObjectType) => ({
        id: option.name,
        icon: (
            <IconWithTooltip
                className={`icon--${option?.color}`}
                dontBlur
                iconSize="sm"
                name={option.iconName}
                tooltipText={`${tooltipTranslationPath}${option.name}.title`}
            />
        ),
        label: (
            <div className="profile__select">
                <div>
                    <Icon
                        className={`icon--${option?.color}`}
                        dontBlur
                        name={option.iconName}
                        size="sm"
                    />
                </div>
                <span className="profile__select--text">
                    <Message message={`${tooltipTranslationPath}${option.name}.title`} />
                </span>
            </div>
        ),
        value: option.name,
    }));
};

export default createOptionsArray;
