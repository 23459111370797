/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import {  MDBRow, MDBCol,  } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Scene } from '~frontendComponents/Scene/index.js';
import { ANTIVIRUS, PROXY } from '~frontendConstants/index.js';
import { getComponentEnabled, getProxyEnabled, setGlcfgObjectValue } from '~frontendDucks/hlcfgEditor/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { createNotification } from '~frontendLib/reactUtils.js';

import TlsInspection from './components/TlsInspection/index.js';
import ErrorProxySettings from './components/errorProxySettings.js';
import CwdbState from './components/CwdbState/index.js';
import Wpad from './components/Wpad/index.ts';
import ProxyLog from './components/ProxyLog/ProxyLog.tsx';


/**
 * Proxy scene component
 *
 * @returns {React.Component}
 */
@withTranslation()
@connect(
    (state) => ({
        enabled: getProxyEnabled(state),
        antivirus: getComponentEnabled(state, ANTIVIRUS),

    }),
    {
        setValue: setGlcfgObjectValue
    }
)
@withTranslation()
class Settings extends Component {
    static get propTypes() {
        return {
            enabled: PropTypes.bool,
            t: PropTypes.func,
            setValue: PropTypes.func,
            antivirus: PropTypes.bool,
        };
    }

    enableAdaptiveFirewall = () => {
        const { setValue, antivirus } = this.props;
        setValue(PROXY, false, '__off');
        if (antivirus) {
            createNotification({
                title: 'widgets:Proxy.antivirus.on.title',
                desc: 'widgets:Proxy.antivirus.on.desc',
                type: 'warning'
            });
        }
    };

    disableAdaptiveFirewall = () => {
        const { setValue } = this.props;
        setValue(PROXY, true, '__off');
    };
    render() {
        const { t, enabled } = this.props;
        return (
            <Scene>
                <Service
                    id="Proxy"
                    on={enabled}
                    showService={(
                        <>
                            <MDBRow>
                                <MDBCol
                                    className={classNames(
                                        'mb-2 mb-lg-4',
                                    )}

                                ><CwdbState />
                                </MDBCol>
                                <MDBCol
                                    className={classNames(
                                        'mb-2 mb-lg-4',
                                    )}

                                >
                                    <ProxyLog />
                                </MDBCol>
                                <MDBCol
                                    className={classNames(
                                        'mb-2 mb-lg-4',
                                    )}

                                >
                                    <Wpad />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol
                                    className="mb-2 mb-lg-4"

                                >
                                    <TlsInspection />
                                </MDBCol>
                                <MDBCol
                                    className={classNames(
                                        'mb-2 mb-lg-4',
                                    )}

                                >
                                    <ErrorProxySettings />
                                </MDBCol>
                            </MDBRow>
                        </>)}
                    turnOff={this.disableAdaptiveFirewall}
                    turnOn={this.enableAdaptiveFirewall}
                >
                    <h3>{t('widgets:Proxy.cwdb.title')}</h3>
                    <p className="mb-1">
                        {t('widgets:Proxy.cwdb.desc')}
                    </p>
                    <h3>{t('widgets:Proxy.wpad.title')}</h3>
                    <p className="mb-2">
                        {t('widgets:Proxy.wpad.desc')}
                    </p>
                    <h3>{t('widgets:Proxy.tls.title')}</h3>
                    <p className="mb-2">
                        {t('widgets:Proxy.tls.desc')}
                    </p>
                </Service>
            </Scene>
        );
    }
}

export default Settings;
