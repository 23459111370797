/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import QRCode from 'qrcode';


/**
 *  qrcode library is not reasonable about usage of async functions. Even though this is async function,
 *  it resolves even before we can log the promise.
 *  The function is actually synchronous, but is wrapped in a promise.
 *
 *  There is also another problem that Image can not be loaded synchronously even
 *  if the data is inlined and thus NOT being fetched. And I found no other way to render it.
 *  So this has to by async even if the qrcode library was not needlessly async...
 *
 *  Note that this was tested on URL with length of 48 with circular logo with diameter 64px, and scale 4.
 *  Which resulted in area of QR code 26896px and area of logo 3216px which is coverage of QR code little over 10%.
 *  QR code should be fine with coverage around 30%, see {@link https://www.npmjs.com/package/qrcode}.
 *  But exact formula to determine area of QR code is unknown, so it is up to the developer to ensure
 *  QR code they display is scannable.
 */
export const renderQrCode = async (canvas: HTMLCanvasElement, url: string, scale?: number|null) => {
    await QRCode.toCanvas(canvas, url, { scale, errorCorrectionLevel: 'H' });
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        throw new Error('Failed to get canvas context when rendering QR code');
    }

    await pngLogo.loaded;
    ctx.drawImage(
        pngLogo,
        Math.floor((canvas.width - pngLogo.width) / 2),
        Math.floor((canvas.height - pngLogo.height) / 2)
    );

};


type HtmlImageElementWithLoadedPromise = HTMLImageElement & {loaded: Promise<void>};
const pngLogo = new Image() as HtmlImageElementWithLoadedPromise;
pngLogo.loaded = new Promise((resolve, reject) => {
    pngLogo.onload = () => {
        resolve(undefined);
    };
    pngLogo.onerror = (event, source, lineno, colno, error) => {
        reject(error);
    };
    pngLogo.src = '/client/img/qrCodeLogo.png';
});
