/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useNetworkInterfacesQuery } from '~frontendQueries/system/hooks.ts';

import { LOOPBACK_NAME } from './systemComponents/index.js';


export const useNetworkInterfacesFromMyNode = () => {
    return filterDevices(useNetworkInterfacesQuery().data ?? []);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useNetworkInterfacesFromNode = (nodeId: string) => {
    // TODO: Make cluster interfaces work (they werent working before refactoring so WHEN is unspecified)

    return useNetworkInterfacesFromMyNode();
};


const filterDevices = <T extends {name?: string}>(data: T[]): T[] => data.filter(item => {
    if (item.name === undefined) {
        return false;
    }
    if (item.name === LOOPBACK_NAME) {
        return false;
    }
    if (item.name.startsWith('vlan') || item.name.startsWith('tun') || item.name.startsWith('tap')) {
        return false;
    }
    return true;
});
