/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import CodeBlock from '../CodeBlock/index.js';


const STYLE_MORE_INFORMATION = {
    marginBottom: 0,
    marginTop: '1rem',
};


const MoreInformation = ({ children, style }) => {
    if (!children) {
        return null;
    }
    return (
        <CodeBlock
            style={{
                ...STYLE_MORE_INFORMATION,
                ...style,
            }}
        >
            {children}
        </CodeBlock>
    );
};

MoreInformation.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
};

export default MoreInformation;
