/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';

import Service from '~frontendComponents/Service/index.ts';
import { useHlcfgOffableValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';

import FeedbackService from './components/FeedbackService/index.js';


const FeedbackScene = () => {
    const feedback = useHlcfgOffableValue(it => it.services.feedback);
    return (
        <Service
            id="Feedback"
            on={feedback.isOn}
            showService={(<FeedbackService />)}
            turnOff={feedback.setOff}
            turnOn={feedback.setOn}
        />
    );
};

export default FeedbackScene;
