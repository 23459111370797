/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';

import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import {
    getMyNodeState,
    getHealthIssues,
    getHealthIssuesRequest,
    getIsLoadingHealthIssues,
    getNodeState,
    getNodeStateErrorValue,
    getOtherNode,
    isNodeUnreachable,
    setClusterSetupModalOpen,
} from '~frontendDucks/clusterSetup/index.js';
import {
    getClusterSharedInterface,
    getGlcfgSchema,
    getGlcfgValue,
    getInterfacesNames,
    getIsCluster,
    setGlcfgValueScalar,
    setNormalize,
} from '~frontendDucks/hlcfgEditor/index.js';
import { SyncStep } from '~frontendComponents/ModalWindows/components/ClusterSetup/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { componentActionSequenceOpen } from '~frontendDucks/systemComponentAction/systemComponentAction.ts';
import {
    BACKUP,
    CLUSTER_SHARED,
    CLUSTER_STEP_A,
    CLUSTER_STEP_B,
    FIREWALL_CLUSTER_A_HOSTNAME,
    FIREWALL_CLUSTER_B_HOSTNAME,
    IS_CLUSTER,
    MASTER,
    NODE_A_ID,
    NODE_B_ID,
    PROXY,
} from '~frontendRoot/constants/index.js';


@withTranslation()
@connect(
    (state) => ({
        authenticationEnabled: getGlcfgValue(state, PROXY).authentication?.enabled,
        healthIssues: getHealthIssues(state),
        stepFromHlcfgA: getGlcfgValue(state, CLUSTER_STEP_A),
        isLoadingHealthIssues: getIsLoadingHealthIssues(state),
        stepFromHlcfgB: getGlcfgValue(state, CLUSTER_STEP_B),
        isCluster: getIsCluster(state),
        nodeUnreachable: isNodeUnreachable(state),
        nodeState: getNodeState(state),
        otherNode: getOtherNode(state),
        myNodeState: getMyNodeState(state),
        nodeStateError: getNodeStateErrorValue(state),
        interfacesNames: getInterfacesNames(state),
        clusterInterface: getClusterSharedInterface(state),
        clusterSharedInterface: getGlcfgValue(state, CLUSTER_SHARED),
        clusterSharedInterfaceSchema: getGlcfgSchema(state, CLUSTER_SHARED),
        hostnameNodeA: getGlcfgValue(state, FIREWALL_CLUSTER_A_HOSTNAME),
        hostnameNodeB: getGlcfgValue(state, FIREWALL_CLUSTER_B_HOSTNAME),

    }),
    {
        doSetClusterSetupModalOpen: setClusterSetupModalOpen,
        doSetGlcfgValue: setGlcfgValueScalar,
        setValue: setNormalize,
        getHealthIssues: getHealthIssuesRequest,
        setReplaceMasterConfirmModal: setModalState,
        doComponentActionOpen: componentActionSequenceOpen,
    }
)
class Cluster extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            doSetClusterSetupModalOpen: PropTypes.func,
            doSetGlcfgValue: PropTypes.func,
            getHealthIssues: PropTypes.func,
            isCluster: PropTypes.bool,
            healthIssues: PropTypes.array,
            nodeUnreachable: PropTypes.bool,
            nodeState: PropTypes.object,
            doComponentActionOpen: PropTypes.func,
            otherNode: PropTypes.string,
            setValue: PropTypes.func,
            nodeStateError: PropTypes.object,
            interfacesNames: PropTypes.object,
            isLoadingHealthIssues: PropTypes.bool,
            clusterSharedInterface: PropTypes.string,
            hostnameNodeA: PropTypes.string,
            hostnameNodeB: PropTypes.string,
            clusterSharedInterfaceSchema: PropTypes.object,
            clusterInterface: PropTypes.object,
            myNodeState: PropTypes.string,
        };
    }

    componentDidMount() {
        const { isCluster, getHealthIssues } = this.props;
        if (isCluster) {
            getHealthIssues();
        }
    }

    setModal = (open, adding) => {
        const { doSetClusterSetupModalOpen } = this.props;
        doSetClusterSetupModalOpen({ open, adding });
    };

    removeFromCluster = () => {
        const { doSetGlcfgValue, otherNode, setValue } = this.props;
        doSetGlcfgValue(IS_CLUSTER, false);
        setValue({ uuid: otherNode === NODE_B_ID ? NODE_B_ID : NODE_A_ID, key: 'uuid',
            value: `${otherNode === NODE_B_ID ? NODE_B_ID : NODE_A_ID}-uninitialized` });
    };

    replaceMaster = () => {
        const { doComponentActionOpen } = this.props;

        doComponentActionOpen({
            componentAction: 'restart',
            componentType: 'keepalived',
            componentId: null, replaceMaster: true });

    };

    retakeMaster = () => {
        const { doComponentActionOpen, otherNode } = this.props;
        doComponentActionOpen({
            node: otherNode,
            componentAction: 'restart',
            componentType: 'keepalived',
            componentId: null, replaceMaster: true });
    };

    healthIssuesUpdate = () => {
        const { getHealthIssues } = this.props;
        getHealthIssues();
    };


    render() {
        const { t, doSetGlcfgValue, healthIssues,
            isCluster, nodeUnreachable, getHealthIssues, nodeState,
            nodeStateError, interfacesNames, isLoadingHealthIssues, hostnameNodeA, myNodeState,
            hostnameNodeB, clusterInterface,  clusterSharedInterface, clusterSharedInterfaceSchema } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    <MDBRow>
                        <MDBCol lg="4">
                            {t('widgets:cluster.title')}
                        </MDBCol>
                        <MDBCol
                            className="device__icons"
                            lg="8"
                        >
                            <WhenAdvanced>
                                <MDBBtn
                                    onClick={() => {
                                        doSetGlcfgValue(CLUSTER_STEP_A, 0);
                                        doSetGlcfgValue(CLUSTER_STEP_B, 0);
                                    }}
                                > {t('widgets:cluster.resetSteps')}
                                </MDBBtn>
                            </WhenAdvanced>
                            {isCluster && (
                                <>
                                    <IconWithTooltip
                                        className="icon--textColor mr-2"
                                        iconSize="sm"
                                        link
                                        loading={isLoadingHealthIssues}
                                        name="reload"
                                        onClick={this.healthIssuesUpdate}
                                        tooltipText={t('widgets:cluster.stateRequest')}
                                        withoutTranslation
                                    />

                                    <IconWithTooltip
                                        className="icon--textColor mr-2"
                                        iconSize="sm"
                                        link
                                        name="server-remove"
                                        onClick={() => this.removeFromCluster()}
                                        tooltipText={t('widgets:cluster.remove')}
                                        withoutTranslation
                                    />
                                    {myNodeState === MASTER &&
                                        <IconWithTooltip
                                            className="icon--textColor mr-2"
                                            iconSize="sm"
                                            link
                                            name="chevron-right-box-outline"
                                            onClick={() => this.replaceMaster()}
                                            tooltipText={t('widgets:cluster.replace')}
                                            withoutTranslation
                                        />
                                    }
                                    {myNodeState === BACKUP &&
                                        <IconWithTooltip
                                            className="icon--textColor mr-2"
                                            iconSize="sm"
                                            link
                                            name="chevron-left-box-outline"
                                            onClick={() => this.retakeMaster()}
                                            tooltipText={t('widgets:cluster.retake')}
                                            withoutTranslation
                                        />
                                    }
                                    <IconWithTooltip
                                        className="icon--textColor mr-2"
                                        iconSize="sm"
                                        link
                                        name="settings-outline"
                                        onClick={() => this.setModal(true, false)}
                                        tooltipText={t('widgets:cluster.edit')}
                                        withoutTranslation
                                    />
                                </>)
                            }
                            {(!isCluster || nodeUnreachable) &&
                                <IconWithTooltip
                                    className="icon--textColor"
                                    iconSize="sm"
                                    link
                                    name="shape-square-plus"
                                    onClick={() => this.setModal(true, true)}
                                    tooltipText={t('widgets:cluster.join')}
                                    withoutTranslation
                                />
                            }
                        </MDBCol>
                    </MDBRow>
                </MDBCardHeader>
                <MDBCardBody>
                    {isCluster ? (
                        <SyncStep
                            className="p-0"
                            clusterInterface={clusterInterface}
                            clusterSharedInterface={clusterSharedInterface}
                            clusterSharedInterfaceSchema={clusterSharedInterfaceSchema}
                            getHealthIssues={getHealthIssues}
                            healthIssues={healthIssues}
                            hostnameNodeA={hostnameNodeA}
                            hostnameNodeB={hostnameNodeB}
                            interfacesNames={interfacesNames}
                            nodeState={nodeState}
                            nodeStateError={nodeStateError}
                            nodeUnreachable={nodeUnreachable}
                            t={t}
                            withoutSuccess={true}
                        />
                    ) :
                        (
                            <>
                                <MDBRow>
                                    <MDBCol>
                                        <p className="cluster__create--text">
                                            <strong>{t('widgets:cluster.title')} </strong>
                                            {t('widgets:cluster.desc')}
                                        </p>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol className="cluster__create">
                                        <MDBBtn
                                            color="secondary"
                                            onClick={() => this.setModal(true, false)}
                                            outline
                                        >
                                            {
                                                isCluster ? t('widgets:cluster.edit') :
                                                    t('widgets:cluster.create')
                                            }
                                        </MDBBtn>
                                    </MDBCol>

                                </MDBRow>
                            </>
                        )
                    }
                </MDBCardBody>

            </MDBCard>
        );
    }
}

export default Cluster;
