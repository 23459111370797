/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Scene } from '~frontendComponents/Scene/index.js';

import VpnScene from './components/VpnScene/index.js';


const VpnServer = () => (
    <Scene>
        <VpnScene />
    </Scene>
);

export default VpnServer;
