/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';

import { Select } from '~frontendComponents/Generic/index.js';
import { setGlcfgValueScalar, getGlcfgValue, getGlcfgSchema } from '~frontendDucks/hlcfgEditor/index.js';


@withTranslation()
@connect(
    (state,) => ({
        timeZone: getGlcfgValue(state, 'timezone'),
        schemaTimeZone: getGlcfgSchema(state, 'timezone'),

    }),
    {
        setValue: setGlcfgValueScalar,
    }
)
class Timezone extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            timeZone: PropTypes.string,
            schemaTimeZone: PropTypes.object,
            setValue: PropTypes.func,
        };
    }


    onChange = ({ value }) => {
        const { setValue } = this.props;
        setValue('timezone', value);
    };

    render() {
        const { t, timeZone, schemaTimeZone } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    {t('widgets:Ntp.timeZone.title')}
                </MDBCardHeader>
                <MDBCardBody>
                    <Select
                        id="timezone"
                        onChange={this.onChange}
                        paste={false}
                        schema={schemaTimeZone}
                        value={timeZone}
                    />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default Timezone;
