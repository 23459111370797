/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import glcfgDefinition from './glcfgDefinition/index.js';


export const findComponentsErrorsWarnings = ({ allErrorsOrWarnings, componentGlcfgPath, seenErrorsWarnings = [] }) => {
    const result = [];
    if (!componentGlcfgPath) {
        return result;
    }

    const allComponentsGlcfgPaths = [];

    if (!Array.isArray(componentGlcfgPath)) {
        allComponentsGlcfgPaths.push(componentGlcfgPath);
    } else {
        componentGlcfgPath.forEach(value => allComponentsGlcfgPaths.push(value));
    }
    for (const errorWarning of allErrorsOrWarnings) {
        for (const instance of errorWarning.hlcfgPaths) {
            for (const componentGlcfgPathValue of allComponentsGlcfgPaths) {
                if (Array.isArray(instance)) { // QUICK fix, not ideal
                    if (instance.join('.').startsWith(glcfgDefinition[componentGlcfgPathValue].hlcfgPath)) {
                        result.push(errorWarning);
                        if (!seenErrorsWarnings.includes(instance)) {
                            seenErrorsWarnings.push(instance);
                        }
                    }
                } else {
                    if (instance.startsWith(glcfgDefinition[componentGlcfgPathValue].hlcfgPath)) {
                        result.push(errorWarning);
                        if (!seenErrorsWarnings.includes(instance)) {
                            seenErrorsWarnings.push(instance);
                        }
                    }
                }
            }
        }
    }
    return result;
};
