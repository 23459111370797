/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {  MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Draggable } from '@hello-pangea/dnd';

import {  Icon, Select } from '~frontendComponents/Generic/index.js';
import {
    getHostUuid,
    deleteNormalize,
    setNormalize,
    getHostsTypes,
    addToNormalize,
    getTableSchema,
    duplicateFromNormalize
} from '~frontendDucks/hlcfgEditor/index.js';
import { getObjectMatch, } from '~frontendLib/objectUtils.js';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice/index.js';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { HOSTS } from '~frontendRoot/constants/index.js';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';


const makeMapStateToProps = () => {
    const getHost = getHostUuid();
    const mapStateToProps = (state, { uuid }) => {
        return {
            data: getHost(state, uuid),
            schema: getTableSchema(state, uuid),
            types: getHostsTypes(state)
        };
    };
    return mapStateToProps;
};

@withTranslation()
@connect(
    makeMapStateToProps,
    {
        add: addToNormalize,
        delRow: deleteNormalize,
        setValue: setNormalize,
        copy: duplicateFromNormalize
    }
)
class Row extends Component {
    static get propTypes() {
        return {
            dataIndex: PropTypes.number,
            add: PropTypes.func,
            delRow: PropTypes.func,
            setValue: PropTypes.func,
            data: PropTypes.object,
            t: PropTypes.func,
            search: PropTypes.string,
            copy: PropTypes.func,
            schema: PropTypes.object,
            spacing: PropTypes.string,
            types: PropTypes.array,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            matches: getObjectMatch({ object: props.data, searchValue: props.search, net: netaddr }),
        };
    }

    componentDidUpdate(prevProps) {
        const {  data, search, dataIndex } = this.props;
        if (search !== prevProps.search || dataIndex !== prevProps.dataIndex) {
            this.setState({
                matches: getObjectMatch({ object: data, searchValue: search, net: netaddr })
            });
        }
    }

    changeValue = ({ value, name,  }) => {
        const { setValue, data } = this.props;
        setValue({ uuid: data.id, key: name, value });
    };

    addRow = (addingAfter) => {
        const { add, data, types } = this.props;
        add({
            type: types[0], //just one type
            typeId: HOSTS,
            addingAfter,
            uuidToAddBefore: data.id,
            successText: 'widgets:Hosts.added',
        });

    };

    copyRow = () => {
        const { copy, data } = this.props;
        copy({
            parent: HOSTS,
            uuid: data.id,
        });
    };

    deleteRow = () => {
        const { delRow, data } = this.props;
        delRow({ type: HOSTS, value: data.id });
    };

    render() {
        const {
            dataIndex,
            t,
            data,
            schema,
            spacing,
        } = this.props;
        const {
            matches,
        } = this.state;
        if (!data?.id) {
            return (
                <tr
                    className="dataTableWidget__RowFirstRule"
                    key="newRule"
                >
                    <td colSpan="1"></td>
                    <td
                        className="dataTableWidget__RowFirstRuleRow"
                        colSpan="5"
                    >
                        <div className="pl-2">
                            <h2>{t('widgets:Hosts.host')}</h2>
                            <p className="dataTableWidget__Rowtext">
                                {t('widgets:Hosts.desc1')}
                                <MDBBtn
                                    className="dataTableWidget__RowAddButtons--button"
                                    data-cy="hostAdd"
                                    onClick={event => {
                                        event.preventDefault();
                                        this.addRow();
                                    }}
                                >
                                    <Icon name="plus" /> {t('widgets:Hosts.host')}
                                </MDBBtn>
                                {t('widgets:Hosts.desc2')}
                            </p>
                            <p>
                                {t('widgets:Hosts.desc3')}
                            (
                                <Icon
                                    name="menu"
                                    size="sm"
                                />)
                                {t('widgets:Hosts.desc4')}

                            </p>
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                data-cy="hostAdd"
                                onClick={event => {
                                    event.preventDefault();
                                    this.addRow();
                                }}
                            >
                                <Icon name="plus" /> {t('widgets:Hosts.host')}
                            </MDBBtn>
                        </div>
                    </td>
                </tr>
            );
        }
        const { domain, address, id, __off, error } = data;
        return (
            <Draggable
                draggableId={id}
                index={dataIndex}
                key={id}
            >
                {(provided) => (
                    <>
                        <tr
                            className="dataTableWidget__RowAdd"
                            key={id + 'add'}
                        >
                            <td
                                className="dataTableWidget__RowAddRule"
                                colSpan="6"
                            >
                                <div className="dataTableWidget__RowAddButtons">
                                    <MDBBtn
                                        className="dataTableWidget__RowAddButtons--button"
                                        data-cy="hostAdd"
                                        onClick={event => {
                                            event.preventDefault();
                                            this.addRow();
                                        }}
                                    >
                                        <Icon name="plus" /> {t('widgets:Hosts.host')}
                                    </MDBBtn>
                                </div>
                            </td>
                        </tr>
                        <tr
                            className={classNames(
                                'dataTableWidget__Row',
                                'profiles__row',
                                { 'dataTableWidget__Row--disable': __off },
                                { 'dataTableWidget__Row--match': matches.length },
                                { 'dataTableWidget__Row--error': error },
                                { [`dataTableWidget__cell--${spacing}`]: spacing },

                            )}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            data-cy={'row:' + id}
                            key={id}
                            style={
                                {
                                    ...provided.draggableProps.style
                                }
                            }
                        >
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    { 'dataTableWidget__cell--match': matches.length },
                                    { 'dataTableWidget__cell--error': error },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                                {...provided.dragHandleProps}
                            >
                                {dataIndex + 1}
                            </td>
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                <Select
                                    className="dataTableWidget__RowInput"
                                    disabled={__off}
                                    id={'domain' + dataIndex}
                                    isCreatable
                                    isMulti

                                    name="domain"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={this.changeValue}
                                    schema={schema.domain}
                                    value={domain}
                                />
                            </td>
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                <NetworkInterfaceDevice
                                    className="dataTableWidget__RowInput"
                                    disabled={__off}
                                    id={'hostAddress' +  id}
                                    iface={address}
                                    isCreatable
                                    isMulti

                                    name="address"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={this.changeValue}
                                    schema={schema.address}
                                />
                            </td>
                            <RowMenuAndSwitch
                                __off={__off}
                                copyFunc={this.copyRow}
                                deleteFunc={this.deleteRow}
                                id={'hostsOff' + id}
                                onChange={this.changeValue}
                                spacing={spacing}
                            />
                        </tr>
                        <tr
                            className="dataTableWidget__RowAddEnd"
                            key={id + 'addEnd'}
                        >
                            <td
                                className="dataTableWidget__RowAddRule"
                                colSpan="6"
                            >
                                <div className="dataTableWidget__RowAddButtons">
                                    <MDBBtn
                                        className="dataTableWidget__RowAddButtons--button"
                                        data-cy="hostAdd"
                                        onClick={event => {
                                            event.preventDefault();
                                            this.addRow(true);
                                        }}
                                    >
                                        <Icon name="plus" />{t('widgets:Hosts.host')}
                                    </MDBBtn>
                                </div>
                            </td>
                        </tr>
                    </>
                )}
            </Draggable>
        );
    }
}

export default Row;
