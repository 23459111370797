/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { FromToHlcfg, LeafDiffInfo } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/types.ts';
import { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import {
    arrayIsReordered,
    getHlcfgVal,
    replaceValuesWithUndefinedIfBothDefault
} from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/helpers.ts';
import { getValue } from '~commonLib/objectUtils.ts';
import { deepCloneAndMerge } from '~commonLib/deepCloneAndMerge/deepCloneAndMerge.ts';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { descriptiveHlcfgPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath.ts';


export const drilledLeafDiffInfoToRegularDiffs = (
    leafInfoList: LeafDiffInfo[], hlcfgTree: FromToHlcfg
): HlcfgDiff[] => {
    return leafInfoList.map(leafInfo => {

        const pathToLeafFromDiff = leafInfo.leafPath.slice(leafInfo.diff.hlcfgDescriptivePath.length);

        const fromDiff = getValue(leafInfo.diff.fromValue || {}, pathToLeafFromDiff);
        const toDiff = getValue(leafInfo.diff.toValue || {}, pathToLeafFromDiff);

        if (
            (typeof fromDiff === 'object' && fromDiff['$ids']) ||
            (typeof toDiff === 'object' && toDiff['$ids'])
        ) {
            return splitDiffWithRows(fromDiff, toDiff, leafInfo.leafPath);
        }

        const { fromValue, toValue } = replaceValuesWithUndefinedIfBothDefault(
            fromDiff,
            toDiff,
            leafInfo.leafSchema
        );

        return {
            fromValue: fromValue !== undefined ? deepCloneAndMerge(
                getHlcfgVal(hlcfgTree.from, descriptiveHlcfgPathToRealPath(leafInfo.leafPath)), fromValue
            ) :
                undefined,
            toValue: toValue !== undefined ? deepCloneAndMerge(
                getHlcfgVal(hlcfgTree.to, descriptiveHlcfgPathToRealPath(leafInfo.leafPath)), toValue
            ) :
                undefined,
            hlcfgRealPath: descriptiveHlcfgPathToRealPath(leafInfo.leafPath),
            hlcfgDescriptivePath: [ ...leafInfo.diff.hlcfgDescriptivePath, ...pathToLeafFromDiff ],
            alsoRemovesRefsFromPaths: [] // drilled diffs should never remove rows.
        };
    }).flat();
};

const splitDiffWithRows = (fromDiff, toDiff, leafPath): HlcfgDiff[] => {
    const ids = [ ...fromDiff?.['$ids'] || [], ...toDiff?.['$ids'] || [] ];
    const reorderDiff = arrayIsReordered(fromDiff?.['$ids'], toDiff?.['$ids']) ? [ {
        fromValue: fromDiff?.$ids,
        toValue: toDiff?.$ids,
        hlcfgRealPath: descriptiveHlcfgPathToRealPath(leafPath),
        hlcfgDescriptivePath: [ ...leafPath, '$ids' ],
        alsoRemovesRefsFromPaths: []
    } ] : [];
    return [
        ...reorderDiff,
        ...ids.map(id => {
            return {
                fromValue: fromDiff?.$byId[id],
                toValue: toDiff?.$byId[id],
                hlcfgRealPath: [
                    'tables',
                    hlcfgTableNameByRowId(id),
                    id
                ],
                hlcfgDescriptivePath: [ ...leafPath, '$byId', id ],
                alsoRemovesRefsFromPaths: []
            };
        }),
    ];
};
