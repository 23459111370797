/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';

import { RadioList, Select } from '~frontendComponents/Generic/index.js';
import {
    getDnsServerDisabled,
    getResolverNameservers,
    getResolverNameserversSchema,
    getResolverType,
    setGlcfgObjectValue,
} from '~frontendDucks/hlcfgEditor/index.js';
import Message from '~frontendComponents/Message/index.js';
import { DNS_TYPE_DHCP, DNS_TYPE_LOCAL_DNS, DNS_TYPE_NAMESERVERS,
    DNS_TYPE_INTERNAL_NETWORK_NAMESERVERS } from '~sharedConstants/index.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useDhcpOnExternal } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';


const getRadioOptions = ({ dnsServerDisabled, dhcpOnExternal }) => [
    DNS_TYPE_DHCP,
    DNS_TYPE_LOCAL_DNS,
    DNS_TYPE_NAMESERVERS,
    DNS_TYPE_INTERNAL_NETWORK_NAMESERVERS
].map(item => {
    if (item === DNS_TYPE_LOCAL_DNS) {
        return  { id: DNS_TYPE_LOCAL_DNS, label: <Message message="widgets:Resolver.type.localDns.title" />,
            disabled: dnsServerDisabled,
            tooltipText: <Message message="widgets:Resolver.type.localDns.desc" /> };
    }
    if (item === DNS_TYPE_DHCP) {
        return  { id: DNS_TYPE_DHCP, label: <Message message={`widgets:Resolver.type.${item}`} />,
            disabled: !dhcpOnExternal,
        };
    }
    return  { id: item, label: <Message message={`widgets:Resolver.type.${item}`} /> };
});

export const Resolver = () => {
    const nameservers = useSelector(getResolverNameservers);
    const selectedType = useSelector(getResolverType);
    const schema = useSelector(getResolverNameserversSchema);
    const dnsServerDisabled = useSelector(getDnsServerDisabled);
    const dhcpOnExternal = useDhcpOnExternal();

    const doSetGlcfgObjectValue = useDispatchCallback(setGlcfgObjectValue, []);

    const { t } = useTranslation();

    const onChangeEvent = useCallback(({ name, id }) => {
        doSetGlcfgObjectValue('resolver', id, name);
    }, [ doSetGlcfgObjectValue ]);
    const onChangeEventAddress = useCallback(({ value, name }) => {
        if (dhcpOnExternal && selectedType === DNS_TYPE_DHCP) {
            return;
        }
        doSetGlcfgObjectValue('resolver', value, name);
    }, [ dhcpOnExternal, selectedType, doSetGlcfgObjectValue ]);

    return (
        <MDBCard>
            <MDBCardHeader>
                {t('widgets:DnsServers.title')}
            </MDBCardHeader>
            <MDBCardBody>
                <p className="mb-1">{t('widgets:Dns.servers.address.title')}</p>
                <RadioList
                    className="mockForm__radioList mt-2 ml-3"
                    data={getRadioOptions({ dnsServerDisabled, dhcpOnExternal })}
                    name="type"
                    onChange={onChangeEvent}
                    selectedOptionId={selectedType}
                />
                <Select
                    disabled={selectedType !== DNS_TYPE_NAMESERVERS &&
                             selectedType !== DNS_TYPE_INTERNAL_NETWORK_NAMESERVERS &&
                             selectedType !== DNS_TYPE_DHCP}
                    id={'nameservers'}
                    isCreatable={!(dhcpOnExternal && selectedType === DNS_TYPE_DHCP)}
                    isMulti
                    label={t('widgets:Dhcpd.nameservers.title')}
                    name="nameservers"
                    noDropdownIndicator
                    noOptionsMessage
                    noWrap
                    onChange={onChangeEventAddress}
                    options={dhcpOnExternal && selectedType === DNS_TYPE_DHCP ? [ {
                        value: dhcpOnExternal.dns,
                        label: t('widgets:global.valueFromDhcp'),
                        tooltipValues: [ dhcpOnExternal.dns ],
                        notRemovable: true,
                    } ] : undefined}
                    placeholderAsValue
                    schema={schema}
                    value={dhcpOnExternal && selectedType === DNS_TYPE_DHCP ? [ dhcpOnExternal.dns ] : nameservers}
                />
            </MDBCardBody>
        </MDBCard>
    );
};
