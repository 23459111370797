/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createPathGetter, getValue, PathGetter } from '~commonLib/objectUtils.ts';
import { HlcfgEditorState, setHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { GetPathGetter, PathGetterResult, PathGetValueResult } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2Types.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.ts';


type RootState = {hlcfgEditor: HlcfgEditorState};
const getState = (rootState: RootState) => rootState.hlcfgEditor;

type Path = readonly string[]
const getWorkHlcfg = (rootState: RootState) => getState(rootState).hlcfgTree;
const getInitHlcfg = (rootState: RootState) => getState(rootState).initHlcfgTree;

export const hlcfgPathGetter = createPathGetter<HlcfgDirtyTree>();

type GetHlcfgOpts = {
    initial?: boolean
}
export const getHlcfgValue = <const T extends Path>(
    state: RootState, path: T, opts: GetHlcfgOpts = {}
): PathGetValueResult<T, HlcfgDirtyTree> => {
    const hlcfgTree = opts.initial ? getInitHlcfg(state) : getWorkHlcfg(state);
    if (!hlcfgTree) {
        throw new Error('Trying to use hlcfg when it is not initialized');
    }
    return getValue(hlcfgTree, path);
};
export const getHlcfgOffableIsEnabled = <T extends PathGetter>(
    state: RootState, getPathGetter: GetPathGetter<T, HlcfgDirtyTree>
) => {
    const path  = getPathGetter(hlcfgPathGetter).getPath() as ReturnType<T['getPath']>;
    const offable: any = getHlcfgValue(state, path);
    return offable && typeof offable === 'object' && !offable.__off;
};

type GetHlcfgValueSelector<T extends Path> = (state: RootState) => PathGetValueResult<T, HlcfgDirtyTree>
export const createGetHlcfgValue = <T extends Path>(path: T, opts?: GetHlcfgOpts): GetHlcfgValueSelector<T> =>
    state => getHlcfgValue(state, path, opts);


const useMemoedHlcfgValue = <T extends PathGetter>(
    getPathGetter: GetPathGetter<T, HlcfgDirtyTree>, opts?: GetHlcfgOpts
): {value: PathGetterResult<T, HlcfgDirtyTree>, path: Path}  => {
    const pathNoMemo  = getPathGetter(hlcfgPathGetter).getPath() as ReturnType<T['getPath']>;
    // The pathNoMemo IS the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const path = useMemo(() => pathNoMemo, pathNoMemo);
    const value = useSelector(useMemo(() => createGetHlcfgValue(path, opts), [ path, opts ]));
    return {
        path, value
    };
};

export const useHlcfgValue = <T extends PathGetter>(
    getPathGetter: GetPathGetter<T, HlcfgDirtyTree>, opts?: GetHlcfgOpts
) => {
    const { value, path } = useMemoedHlcfgValue(getPathGetter, opts);
    const setValue = useDispatchCallback((newValue: typeof value) => {
        return setHlcfgValue({ hlcfgPath: [ ...path ], value: newValue });
    }, [ path ]);
    return { value, setValue } as const;
};

export const useHlcfgOffableValue = <T extends PathGetter>(
    getPathGetter: GetPathGetter<T, HlcfgDirtyTree>, opts?: GetHlcfgOpts
) => {
    const { value, setValue } = useHlcfgValue(getPathGetter, opts);
    assert(value && typeof value === 'object', 'Non object can not be offable');
    const valueOffable: {__off?: boolean} & typeof value = value;

    const setOff = useCallback(() => {
        setValue({ ...value, __off: true });
    }, [ value, setValue ]);

    const setOn = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setValue({ ...value, __off: false });
    }, [ value, setValue ]);

    const inputSetValue = useCallback((params) => {
        assert(params.id, 'Must provide ID to inputSetValue');
        setValue({
            ...value,
            [params.id]: params.value
        });
    }, [ value, setValue ]);

    return {
        value, setValue, setOn, setOff,
        inputSetValue,
        isOn: !valueOffable.__off,
        isOff: !!valueOffable.__off
    };
};
