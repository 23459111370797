/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Select } from '~frontendComponents/Generic/index.js';
import { getDataHook, isIpRequest, getIsLoadingHook, getIpToFindHook, getError } from '~frontendDucks/isInAF/index.js';
import {
    getGlcfgValueHook,
    getInitGlcfgValueHook } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { ADAPTIVE_FIREWALL, BASIC_IP_SCHEMA } from '~frontendRoot/constants/index.js';
import type { PostResponseType } from '~commonLib/apiUtils.ts';
import { BackendApiDefinitionType, HlcfgInputTree } from '~frontendTypes/externalTypes.ts';
import Message from '~frontendRoot/components/Message/index.js';
import type { NetaddrIp4 } from '~sharedLib/Netaddr/NetaddrIp4.ts';
import { StringifyAddressReturnType } from '~frontendLib/addressUtils.ts';
import { NotUndefined } from '~commonLib/types.ts';


type AdaptiveFirewall = NotUndefined<HlcfgInputTree['protection']['adaptiveFirewall']>
interface GetInfoBoxToShowType {
    data?:  PostResponseType<BackendApiDefinitionType['/adaptiveFirewall/isAddressInAf']>,
    policy?: AdaptiveFirewall['policy'],
    initPolicy?: AdaptiveFirewall['policy'],

}

const PrimaryInfobox = ({ id }: {id: string }) => {
    return (
        <p
            className={
                classNames('infobox',
                    { 'infobox--primary': id.startsWith('isnt') || id === 'notfound' },
                    { 'infobox--success': !id.startsWith('isnt') && id !== 'notfound' })}
            data-cy={`infobox--${id}`}
        >
            <span className="headerInfobox">
                <Message message={`widgets:AdaptiveFirewall.isIn.${
                    id.startsWith('isnt') || id === 'notfound' ? 'notBlocking' : 'blocking'}`}
                />
            </span>
            <Message message={`widgets:AdaptiveFirewall.isIn.${id}`} />
        </p>
    );
};

const GetInfoboxToShow = ({ data, policy, initPolicy }: GetInfoBoxToShowType) => {
    if (!data) {
        return null;
    }
    let id = '';
    switch (true) {
    case data.severity === 0:
        id = 'notfound';
        break;
    case data.severity === 1000:
        id = 'blocked';
        break;
    case data.severity === 10:
        if (initPolicy === 'strict') {
            id = policy === 'strict' ? 'isAndWillBe' : 'isAndWontBe';
        } else {
            id = policy === 'strict' ? 'isntAndWillBe' : 'isntAndWontBe';
        }
        break;

    case data.severity === 100:
        if (initPolicy !== 'soft') {
            id = policy !== 'soft' ? 'isAndWillBe' : 'isAndWontBe';
        } else {
            id = policy !== 'soft' ? 'isntAndWillBe' : 'isntAndWontBe';
        }
        break;
    default:
        return null;
    }
    return (
        <PrimaryInfobox
            id={id}
        />
    );
};


interface AdaptiveFirewallIsInAFPureType {
    isLoading: boolean,
    error?: any, //AK-2469 create global error type
    onChange: (opts: { value: NetaddrIp4 }) => void,
    ip: StringifyAddressReturnType,
    data?: PostResponseType<BackendApiDefinitionType['/adaptiveFirewall/isAddressInAf']>,
    policy?: AdaptiveFirewall['policy'],
    initPolicy?: AdaptiveFirewall['policy'],
}

export const AdaptiveFirewallIsInAFPure = ({
    isLoading, error, onChange, ip, data,  policy, initPolicy }:
    AdaptiveFirewallIsInAFPureType) => {
    return (
        <>
            <Select
                disabled={isLoading}
                error={error}
                id={'isInAf'}
                isCreatable
                isMulti
                label={<Message message={'widgets:AdaptiveFirewall.isIn.label'} />}
                loading={isLoading}
                name="name"
                noDropdownIndicator
                noOptionsMessage
                onChange={onChange}
                schema={BASIC_IP_SCHEMA}
                value={ip}
            />
            <GetInfoboxToShow
                data={data}
                initPolicy={initPolicy}
                policy={policy}
            />
        </>
    );
};


const AdaptiveFirewallIsInAF = () => {
    const data: PostResponseType<BackendApiDefinitionType['/adaptiveFirewall/isAddressInAf']> | undefined =
        useSelector(getDataHook);
    const adaptiveFirewall: HlcfgInputTree['protection']['adaptiveFirewall'] =
     useSelector(getGlcfgValueHook(ADAPTIVE_FIREWALL));
    const adaptiveFirewallInit: HlcfgInputTree['protection']['adaptiveFirewall'] =
     useSelector(getInitGlcfgValueHook(ADAPTIVE_FIREWALL));


    const ip = useSelector(getIpToFindHook);

    const isLoading: boolean = useSelector(getIsLoadingHook);
    const error = useSelector(getError);

    const dispatch = useDispatch();
    const onChange = ({ value }) => {
        dispatch(isIpRequest({ ip: value }));
    };

    return (
        <MDBCard>
            <MDBCardTitle> <Message message={'widgets:AdaptiveFirewall.isIn.title'} /></MDBCardTitle>
            <MDBCardBody className={'relative'}>
                <AdaptiveFirewallIsInAFPure
                    data={data}
                    error={error}
                    initPolicy={adaptiveFirewallInit?.policy}
                    ip={ip}
                    isLoading={isLoading}
                    onChange={onChange}
                    policy={adaptiveFirewall?.policy}
                />
            </MDBCardBody>
        </MDBCard>
    );
};

export default AdaptiveFirewallIsInAF;
