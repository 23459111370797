/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getDataValuesHook, getDataValuesFirstFoundHook, getDataValuesLastFoundHook } from '~frontendRoot/ducks/imInAF/index.js';
import Moment from '~frontendRoot/components/Moment/index.js';
import { Icon } from '~frontendRoot/components/Generic/index.js';
import Message from '~frontendRoot/components/Message/index.js';


interface RowProps {
    spacing: string,
    uuid: string,
}

const Row = ({ spacing, uuid }: RowProps) => {
    const [ showNumberOfItem, setshowNumberOfItem ] = useState(0);
    const dataValues = useSelector(getDataValuesHook(uuid));
    const firstFound = useSelector(getDataValuesFirstFoundHook(uuid));
    const lastFound = useSelector(getDataValuesLastFoundHook(uuid));
    return (
        <>
            <tr
                className={classNames(
                    'dataTableWidget__Row',
                    'profiles__row',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                )}
                key={uuid}
                onClick={() => setshowNumberOfItem(showNumberOfItem ? 0 : 10)}

            >
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >
                    {uuid}
                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >

                    <Moment
                        format="DD.MM.YYYY HH:mm"
                        value={firstFound}
                    />
                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >
                    <Moment
                        format="DD.MM.YYYY HH:mm"
                        value={lastFound}
                    />

                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >
                    {dataValues?.length}
                    <Icon
                        className={'icon--textColor'}
                        name={showNumberOfItem ? 'chevron-up' : 'chevron-down'}
                        size="sm"
                    />
                </td>
            </tr>
            {Boolean(showNumberOfItem) &&
            dataValues.map((item, index) => {
                if (showNumberOfItem <= index) {
                    return null;
                }
                return (
                    <>
                        <tr key={item.timestamp}>
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                {index + 1}.
                            </td>

                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >

                                <Moment
                                    format="DD.MM.YYYY HH:mm"
                                    value={item.timestamp}
                                />
                            </td>
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >


                            </td>
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            />
                        </tr>
                        {showNumberOfItem < dataValues.length && index + 1 === showNumberOfItem &&
                    <tr>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                                'clicable'

                            )}
                            colSpan={4}
                        >
                            <span
                                className={'adaptiveImIn__showMore'}
                                onClick={() => setshowNumberOfItem(showNumberOfItem + 10)}
                            >
                                <Message message="widgets:global.showMore" />
                            </span>
                        </td>
                    </tr>}
                    </>
                );})
            }
        </>
    );
};

export default Row;
