/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { debounce } from 'throttle-debounce';


/**
 * Returns a debouncer-creating object.
 *
 * A regular debounce is a function wrapper that ensures that the function it is wrapping is not called with too high
 * intensity. It calls the function X miliseconds after the last call, unless there has been another call in the mean.
 *
 * A value debounce is a debounce function wrapper that also takes a value. If the value is not changed between calls,
 * the function is not considered to have been called.
 *
 * A debouncer is a function that performs debounces.
 *
 * Since this class remembers the last value, it is necessary to unregister it after a debounce is no longer needed,
 * usually in function React.Component.prototype.componentWillUnmount().
 *
 * @param {number} interval - debounce interval
 * @returns {object}
 */
export default class ValueDebouncer {
    constructor(interval = 300) {
        this.interval = interval;
        this.debouncers = {};
        this.cachedValues = {};
    }

    /**
     * Calls a debounce function. Registers a new debouncer if it doesn't exist yet.
     *
     * @param {string} id - id of the debouncer
     * @param {*} currentValue - current value, the function is called only when this values changes
     * @param {Function} fn - function to call
     * @param {Array} args - arguments
     */
    debounce(id, currentValue, fn, ...args) {
        if (!this.debouncers[id]) {
            this.debouncers[id] = debounce(this.interval, (debouncer, fn, args) => {
                if (debouncer !== this.debouncers[id]) {
                    return; // this debouncer was unregistered
                }
                fn(...args);
            });
        }
        const prevCacheValue = this.cachedValues[id];
        this.cachedValues[id] = currentValue;
        if (prevCacheValue === currentValue) {
            return;
        }
        this.debouncers[id](this.debouncers[id], fn, args);
    }

    /**
     * Unregisters a debouncer.
     *
     * @param {string} id - id of the debouncer
     */
    unregister(id) {
        this.debouncers[id] = null;
        this.cachedValues[id] = null;
    }
}
