/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import {
    MDBBtn } from 'mdbreact';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Overlay, Icon, Cards } from '~frontendComponents/Generic/index.js';
import { getQoSIds,
    getQoSProps,
    getQoSService, getQoSTypes,
    addToNormalize,
    setNormalize,
    deleteNormalize,
    setNormalizeOrder,
    getLowestPossibleClassId } from '~frontendDucks/hlcfgEditor/index.js';
import { DELETE_CONFIRM, QOS
} from '~frontendConstants/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';

import QoSService from './components/QoSService/index.js';


@withTranslation()
@connect(
    (state) => ({
        ids: getQoSIds(state),
        qoSProps: getQoSProps(state),
        data: getQoSService(state),
        types: getQoSTypes(state),
        lowestClassId: getLowestPossibleClassId(state)

    }),
    {
        add: addToNormalize,
        setValue: setNormalize,
        setOrder: setNormalizeOrder,
        delService: deleteNormalize,
        doSetModal: setModalState
    }
)
class QoSScene extends Component {
    static get propTypes() {
        return {
            ids: PropTypes.array,
            t: PropTypes.func,
            add: PropTypes.func,
            setValue: PropTypes.func,
            setOrder: PropTypes.func,
            qoSProps: PropTypes.object,
            doSetModal: PropTypes.func,
            delService: PropTypes.func,
            data: PropTypes.object,
            types: PropTypes.array,
            lowestClassId: PropTypes.number

        };
    }


    addQoS = () => {
        const { add, types, lowestClassId } = this.props;
        const newUuid = `${types[0]}:${uuidv4()}`;
        add({
            type: types[0], //just one type
            typeId: QOS,
            successText: 'widgets:QoS.added',
            newUuid
        });
        add({
            type: 'tcNode',
            key: 'rootNodeId',
            uuid: newUuid,
            array: false,
            extraValues: {
                classId: lowestClassId
            }
        });

    };

    deleteService = ({ uuid }) => {
        const { doSetModal, data  } = this.props;
        doSetModal({ modal: DELETE_CONFIRM, value: true, specialValues: { uuid, name: data.name,
            action: this.delete, service: 'QoS' } });
    };

    delete = ({ uuid }) => {
        const {  delService } = this.props;
        delService({ type: QOS, value: uuid });
    };

    setOrder = ({ order }) => {
        const { setOrder } = this.props;
        setOrder({ type: QOS, value: order });
    };

    render() {
        const { t, ids, qoSProps, setValue } = this.props;
        return (
            <>
                <div className="userNavigation">
                    <MDBBtn
                        className="navigation__button pulse"
                        onClick={this.addQoS}
                        size="sm"
                        type="button"
                    >
                        <Icon name="plus" />
                        {t('widgets:QoS.queue')}
                    </MDBBtn>
                </div>
                <div  className={classNames(
                    { 'withOverlay': !ids?.length }
                )}
                >
                    <Cards
                        deleteService={this.deleteService}
                        ids={ids}
                        menuItemProps={qoSProps}
                        setOrder={this.setOrder}
                        setValue={setValue}
                        type="qos"
                        withoutColorChange
                        wrapClassName="vpn__scene"
                    />


                    <QoSService />
                    {!ids?.length &&
                            <Overlay
                                active={!ids?.length}
                            >
                                <div className="dhcpd__overlay">
                                    <h2>{t('widgets:QoS.title')}</h2>
                                    <p className="mb-2">
                                        {t('widgets:QoS.desc')}
                                        <MDBBtn
                                            className="navigation__button"
                                            onClick={this.addQoS}
                                            size="sm"
                                            type="button"
                                        >
                                            <Icon name="plus" />
                                            {t('widgets:QoS.queue')}
                                        </MDBBtn>
                                    </p>
                                    <MDBBtn
                                        className="navigation__button pulse"
                                        onClick={this.addQoS}
                                        size="sm"
                                        type="button"
                                    >
                                        <Icon name="plus" />
                                        {t('widgets:QoS.queue')}
                                    </MDBBtn>

                                </div>
                            </Overlay>}

                </div>
            </>
        );
    }
}

export default QoSScene;
