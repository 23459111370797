/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useState } from 'react';
import { MDBCard, MDBCardBody, MDBRow, MDBCardTitle, MDBCol } from 'mdbreact';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon, Input, Select } from '~frontendComponents/Generic/index.js';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import { HlcfgTypeIpsec, HlcfgTypeIpsecSchema } from '~frontendTypes/externalTypes.ts';
import { InputCallbackFunction } from '~frontendConstants/types.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { UNSAFE_DH_GROUPS } from '~frontendConstants/constants.ts';


interface Phase1Props {
    schemas: HlcfgTypeIpsecSchema,
    data: HlcfgTypeIpsec,
    changeValue: InputCallbackFunction,
    timeoutValidator: InputCallbackFunction,
    setTimeout: InputCallbackFunction
}

const Phase1 = ({ schemas, data, changeValue, timeoutValidator, setTimeout }: Phase1Props) => {
    const [ show, setShow ] = useState(false);
    const { t } = useTranslation();
    const {
        ikeLifetimeSeconds, ikeEncryption, ikeDhGroup, ikeHashing, keyexchange } = data;
    return (
        <MDBCard className="card--withoutMH pb-0">
            <MDBCardTitle className={classNames(
                'cardHide__title',
                { 'cardHide__title--noBorderBottom': show },
            )}
            >
                <div
                    className="clicable"
                    onClick={() => setShow(!show)}
                >
                    <>
                        {t('widgets:Ipsec.faze.1')}
                        <Icon
                            name={show ? 'chevron-up' : 'chevron-down'}
                        />
                    </>
                </div>
            </MDBCardTitle>

            <MDBCardBody className={classNames('cardHide__body', 'pb-0',
                { 'cardHide__body--hide': !show },
                { 'cardHide__title--borderBottom': show })}
            >
                <MDBRow>
                    <MDBCol>
                        <Input
                            id="ikeLifetimeSeconds"
                            label={t('differs:tables.ipsec.ikeLifetimeSeconds')}
                            name="ikeLifetimeSeconds"
                            onChange={setTimeout}
                            placeholder={
                                valueFormatter.formatSeconds(schemas.ikeLifetimeSeconds[DEFAULT_SCHEMA_VALUE])}
                            schema={schemas.ikeLifetimeSeconds}
                            validator={timeoutValidator}
                            value={ikeLifetimeSeconds}
                        />
                    </MDBCol>
                    <MDBCol>

                        <Select
                            id="ikeEncryption"
                            label={t('differs:tables.ipsec.ikeEncryption.title')}
                            name="ikeEncryption"
                            onChange={changeValue}
                            paste={false}
                            schema={schemas.ikeEncryption}
                            value={ikeEncryption}
                        />
                    </MDBCol>
                    <MDBCol>
                        <Select
                            id="ikeHashing"
                            label={t('differs:tables.ipsec.ikeHashing.title')}
                            name="ikeHashing"
                            onChange={changeValue}
                            paste={false}
                            schema={schemas.ikeHashing}
                            value={ikeHashing}
                        />
                    </MDBCol>
                    <MDBCol>
                        <Select
                            id="ikeDhGroup"
                            label={t('differs:tables.ipsec.ikeDhGroup.title')}
                            name="ikeDhGroup"
                            onChange={changeValue}
                            paste={false}
                            schema={schemas.ikeDhGroup}
                            value={ikeDhGroup}
                            warning={ikeDhGroup === UNSAFE_DH_GROUPS ? t('global:DhGroupKeyWarning') : false}
                        />
                    </MDBCol>
                    <MDBCol>
                        <Select
                            id="keyexchange"
                            label={t('differs:tables.ipsec.keyexchange.title')}
                            name="keyexchange"
                            onChange={changeValue}
                            paste={false}
                            schema={schemas.keyexchange}
                            value={keyexchange}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default Phase1;
