/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCardHeader, MDBCard, MDBCardBody, MDBRow, MDBCol } from 'mdbreact';

import { setGlcfgValueScalar, getGlcfgValue, getGuiAddressesSchema,
    getGlcfgSchema } from '~frontendDucks/hlcfgEditor/index.js';
import  NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { Switch } from '~frontendComponents/Generic/index.js';
import { getCurrentUserName } from '~frontendDucks/userAuthentication/ducks/login.js';


@withTranslation()
@connect(
    (state,) => ({
        guiAddressesHTTPS: getGlcfgValue(state, 'guiAddressesHTTPS'),
        guiAddressesHTTP: getGlcfgValue(state, 'guiAddressesHTTP'),
        guiAddressesHTTPSchema: getGlcfgSchema(state, 'guiAddressesHTTP'),
        guiAddressesSchema: getGuiAddressesSchema(state),
        currentUserName: getCurrentUserName(state),


    }),
    {
        doSetValueScalar: setGlcfgValueScalar,

    }
)
class Interface extends Component {
    static get propTypes() {
        return {
            guiAddressesHTTPS: PropTypes.array,
            t: PropTypes.func,
            doSetValueScalar: PropTypes.func,
            guiAddressesSchema: PropTypes.object,
            guiAddressesHTTP: PropTypes.bool,
            guiAddressesHTTPSchema: PropTypes.object
        };
    }

    onChangeEvent = ({ id, value }) => {
        const { doSetValueScalar } = this.props;
        doSetValueScalar(id, value);
    };

    onChangeEventSwitch = ({ id, value }) => {
        const { doSetValueScalar } = this.props;
        doSetValueScalar(id, value);
    };


    render() {
        const { t, guiAddressesHTTPS, guiAddressesHTTP, guiAddressesSchema,
            guiAddressesHTTPSchema } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    {t('widgets:System.AdminInterface.interface.title')}
                </MDBCardHeader>
                <MDBCardBody>
                    <MDBRow>

                        <MDBCol
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <NetworkInterfaceDevice
                                id={'guiAddressesHTTPS'}
                                iface={stringifyAddress(guiAddressesHTTPS)}
                                isCreatable
                                isMulti
                                name={'guiAddressesHTTPS' + 'Name'}
                                onChange={this.onChangeEvent}
                                schema={guiAddressesSchema}
                                withAllValues
                                withAllValuesNetwork
                            />
                        </MDBCol>
                        <MDBCol
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <Switch
                                align="spaceBetween"
                                checked={guiAddressesHTTP}
                                id={'guiAddressesHTTP'}
                                label={t('widgets:System.AdminInterface.http.title')}
                                onChange={this.onChangeEventSwitch}
                                schema={guiAddressesHTTPSchema}

                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default Interface;
