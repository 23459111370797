/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getGlcfgValue, getIsCluster } from '~frontendDucks/hlcfgEditor/index.js';
import { getHealthIssues } from '~frontendDucks/clusterSetup/index.js';
import { typeApiError, typeProgressArray } from '~frontendRoot/types/index.js';
import { ACTION_SEQUENCE_SURICATA_RULES_UPDATE, NODE_A_ID, NODE_B_ID } from '~sharedConstants/index.ts';
import {
    getRulesUpdateError,
    getRulesUpdateIsAborted,
    getRulesUpdateIsLoading,
    getRulesUpdateIsOpen,
    getRulesUpdateProgress,
    rulesUpdateClose,
} from '~frontendDucks/suricataRulesUpdatePerform/index.js';
import { ActionSequenceModal } from '~frontendComponents/ActionSequence/index.js';
import Message from '~frontendComponents/Message/index.js';

import actionStepCfgApply from '../CfgActivationModal/lib/actionStepCfgApply.js';
import actionStepCfgGenerate from '../CfgActivationModal/lib/actionStepCfgGenerate.js';
import actionStepComponentsActivate from '../CfgActivationModal/lib/actionStepComponentsActivate.js';


const RulesUpdateAbortedTitle = () => (<Message message="actions:action.sequence.SuricataRulesUpdate.aborted.title" />);

const RulesUpdateDoneTitle = () => (<Message message="actions:action.sequence.SuricataRulesUpdate.done.title" />);

const RulesUpdateErrorTitle = () => (<Message message="actions:action.sequence.SuricataRulesUpdate.error.title" />);

const RulesUpdateLoadingTitle = () => (<Message message="actions:action.sequence.SuricataRulesUpdate.loading.title" />);


const SubActionStepProgress = ({ currentDone, actionStepObject }) => {
    const { StepProgress, StepDoneTitle, StepErrorTitle, StepLoadingTitle } = actionStepObject;
    switch (currentDone.type) {
    case 'start': return <StepLoadingTitle  event={currentDone.event} />;
    case 'progress': return <StepProgress event={currentDone.event} />;
    case 'error': return <StepErrorTitle  event={currentDone.event} />;
    case 'end': return <StepDoneTitle  event={currentDone.event} />;
    default: throw new Error(`Unsupported type of currentDone "${currentDone.type}"`);
    }
};

SubActionStepProgress.propTypes = {
    actionStepObject: PropTypes.object.isRequired,
    currentDone: PropTypes.object.isRequired,
};


const RulesUpdateStepProgress = ({ event }) => {
    const { currentDone } = event;
    switch (currentDone.description) {
    case 'generateLlcfg':
        return (
            <SubActionStepProgress
                actionStepObject={actionStepCfgGenerate}
                currentDone={currentDone}
            />
        );
    case 'applyLlcfg':
        return (
            <SubActionStepProgress
                actionStepObject={actionStepCfgApply}
                currentDone={currentDone}
            />
        );
    case 'activateComponents':
        return (
            <SubActionStepProgress
                actionStepObject={actionStepComponentsActivate}
                currentDone={currentDone}
            />
        );
    case 'downloading':
        return <Message message="actions:action.step.updateRules.downloading.title" />;
    case 'downloaded':
        return <Message message="actions:action.step.updateRules.downloaded.title" />;
    case 'parsing':
        return (
            <Message
                message="actions:action.step.updateRules.parsing.title"
                params={{ filename: currentDone.filename }}
            />
        );
    case 'parsed':
        return (
            <Message
                message="actions:action.step.updateRules.parsed.title"
                params={{ filename: currentDone.filename }}
            />
        );
    default:
        throw new Error('invalid step in rules update');
    }
};

const STEPS = {
    [ACTION_SEQUENCE_SURICATA_RULES_UPDATE]: {
        StepAbortedTitle: RulesUpdateAbortedTitle,
        StepDoneTitle: RulesUpdateDoneTitle,
        StepErrorTitle: RulesUpdateErrorTitle,
        StepLoadingTitle: RulesUpdateLoadingTitle,
        StepProgress: RulesUpdateStepProgress,
    },
};

/**
 * Renders configuration activation modal window.
 */
export const RulesUpdateModalBase = ({
    isOpen, isLoading, isAborted, error, onAbort, progress, isLoadingnodeA, isAbortednodeA, errornodeA, progressnodeA,
    isLoadingnodeB, isAbortednodeB, errornodeB, progressnodeB, doRulesUpdateClose, isCluster, hostnamenodeA,
    hostnamenodeB, healthIssues
}) => (
    <ActionSequenceModal
        {...{ isLoadingnodeA, isAbortednodeA, errornodeA, progressnodeA,
            isLoadingnodeB, isAbortednodeB, errornodeB, progressnodeB, isCluster, hostnamenodeA,
            hostnamenodeB, healthIssues }}
        error={error}
        isAborted={isAborted}
        isLoading={isLoading}
        isOpen={isOpen}
        onAbort={onAbort}
        onClose={doRulesUpdateClose}
        progress={progress}
        sequenceAbortedTitle={(<RulesUpdateAbortedTitle />)}
        sequenceDoneTitle={(<RulesUpdateDoneTitle />)}
        sequenceErrorTitle={(<RulesUpdateErrorTitle />)}
        sequenceLoadingTitle={(<RulesUpdateLoadingTitle />)}
        sequenceTitle={(<Message message="actions:action.sequence.SuricataRulesUpdate.modal.title" />)}
        steps={STEPS}
    />
);

RulesUpdateModalBase.propTypes = {
    doRulesUpdateClose: PropTypes.func.isRequired,
    error: typeApiError,
    errornodeA: typeApiError,
    errornodeB: typeApiError,
    isAborted: PropTypes.bool,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onAbort: PropTypes.func,
    progress: typeProgressArray.isRequired,
    isAbortednodeA: PropTypes.bool,
    isLoadingnodeA: PropTypes.bool,
    isOpennodeA: PropTypes.bool,
    onAbortnodeA: PropTypes.func,
    progressnodeA: typeProgressArray.isRequired,
    isAbortednodeB: PropTypes.bool,
    isLoadingnodeB: PropTypes.bool,
    isOpennodeB: PropTypes.bool,
    onAbortnodeB: PropTypes.func,
    progressnodeB: typeProgressArray.isRequired,
    isCluster: PropTypes.bool,
    hostnamenodeA: PropTypes.string,
    hostnamenodeB: PropTypes.string,
    healthIssues: PropTypes.array
};

/**
 * @see Redux.connect()
 */
const mapStateToProps = state => ({
    isOpen: getRulesUpdateIsOpen(state),
    isLoading: getRulesUpdateIsLoading(state),
    isAborted: getRulesUpdateIsAborted(state),
    error: getRulesUpdateError(state),
    progress: getRulesUpdateProgress(state),
    isLoadingnodeA: getRulesUpdateIsLoading(state, NODE_A_ID),
    isAbortednodeA: getRulesUpdateIsAborted(state, NODE_A_ID),
    errornodeA: getRulesUpdateError(state, NODE_A_ID),
    progressnodeA: getRulesUpdateProgress(state, NODE_A_ID),
    isLoadingnodeB: getRulesUpdateIsLoading(state, NODE_B_ID),
    isAbortednodeB: getRulesUpdateIsAborted(state, NODE_B_ID),
    errornodeB: getRulesUpdateError(state, NODE_B_ID),
    progressnodeB: getRulesUpdateProgress(state, NODE_B_ID),
    isCluster: getIsCluster(state),
    hostnamenodeA: getGlcfgValue(state, 'firewallClusterAHostname'),
    hostnamenodeB: getGlcfgValue(state, 'firewallClusterBHostname'),
    healthIssues: getHealthIssues(state)
});

const mapDispatchToProps = {
    doRulesUpdateClose: rulesUpdateClose,
};

/**
 * Activation modal window.
 */
const RulesUpdateModal = connect(mapStateToProps, mapDispatchToProps)(RulesUpdateModalBase);

export default RulesUpdateModal;
