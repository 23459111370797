/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';


import { Input, Select, Icon } from '~frontendComponents/Generic/index.js';
import {
    getDhcpdServiceGetterByUuid,
    getTableSchema,
    setNormalize,
} from '~frontendDucks/hlcfgEditor/index.js';
import { LEASE_TYPE, POOL_TYPE
} from '~frontendConstants/index.js';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import Divider from '~frontendRoot/components/Divider/index.js';
import { setTimeoutComponentFunction, setTimeoutComponentValidator } from '~frontendLib/timeUtils.ts';
import { DEFAULT_LEASE_PLACEHOLDER, DHCP_RELAY_TYPE, DHCP_SERVER_TYPE, EMPTY_IMMUTABLE_ARR } from '~sharedConstants/index.ts';

import { DhcpdDatatableContext } from '../DhcpdDatatableContext/index.ts';
import DhcpdDatatable from '../DhcpdDatatable/index.ts';
import DhcpLeases from '../DhcpdLeases/index.js';
import DhcpdRoutes from '../DhcpdRoutes/DhcpdRoutes.tsx';
import { DhcpdServerContext } from './DhcpdServerContext.ts';


const makeMapStateToProps = () => {
    const dhcpdServiceUuid = getDhcpdServiceGetterByUuid();
    const mapStateToProps = (state, { uuid }) => {
        return {
            data: dhcpdServiceUuid(state, uuid),
            schemas: getTableSchema(state, uuid),

        };
    };
    return mapStateToProps;
};
@withTranslation()
@connect(
    makeMapStateToProps,
    {
        setValue: setNormalize,

    }
)
class DhcpdService extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object,
            uuid: PropTypes.string,
            t: PropTypes.func,
            setValue: PropTypes.func,
            schemas: PropTypes.object,

        };
    }

    constructor(props) {
        super(props);
        this.state = {
            shownSettings: true,
        };
    }

    changeValue = ({ value, name, key }) => {
        const { setValue,  uuid } = this.props;
        setValue({ value, uuid, key: name || key });
    };

    setTimeout = setTimeoutComponentFunction({ setValue: this.changeValue });

    timeoutValidator = setTimeoutComponentValidator(this.props.t);

    hideSettings = () => {
        const { shownSettings } = this.state;
        this.setState({
            shownSettings: !shownSettings
        });
    };


    render() {
        const { t, data, uuid,
            schemas } = this.props;
        const { shownSettings } = this.state;
        const {  gateways, defaultDomain, nameservers, type = DHCP_SERVER_TYPE,
            __off, iface,
            relay,  pools, leases, leaseTime, subnetAddr, placeholders, routes } = data;
        return (
            <MDBRow>
                <MDBCol
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <MDBCard className="pt-0 ">
                        <MDBCardTitle className={classNames(
                            'cardHide__title',
                            { 'cardHide__title--noBorderBottom': shownSettings },

                        )}
                        >
                            <div
                                className="clicable"
                                onClick={this.hideSettings}
                            >
                                {t('widgets:Dhcpd.header.settings')}
                                <Icon
                                    name={shownSettings ? 'chevron-up' : 'chevron-down'}
                                />
                            </div>
                        </MDBCardTitle>
                        <MDBCardBody className={classNames('cardHide__body', 'pb-0',
                            { 'cardHide__body--hide': !shownSettings },
                            { 'cardHide__title--borderBottom': shownSettings })}
                        >
                            <MDBRow>
                                <MDBCol>
                                    <NetworkInterfaceDevice
                                        disabled={__off}
                                        id={'iface' + uuid}
                                        iface={iface}
                                        isMulti
                                        label={t('widgets:Dhcpd.iface.title')}
                                        name={'iface'}
                                        noDropdownIndicator
                                        onChange={this.changeValue}
                                        paste={false}
                                        schema={schemas.iface}
                                    />
                                    {type === DHCP_SERVER_TYPE &&
                                        <Select
                                            disabled={__off}
                                            id={'subnetAddr' + uuid}
                                            isCreatable
                                            isMulti

                                            label={t('widgets:Dhcpd.subnetAddr.title')}
                                            name="subnetAddr"
                                            noDropdownIndicator
                                            noOptionsMessage
                                            onChange={this.changeValue}
                                            placeholder={placeholders?.subnetAddr}
                                            placeholderAsValue
                                            schema={schemas.subnetAddr}
                                            value={subnetAddr}
                                        />
                                    }

                                </MDBCol>
                                <Divider vertical />
                                <MDBCol>
                                    {type === DHCP_RELAY_TYPE ?
                                        <Select
                                            disabled={__off}
                                            id={'relay' + uuid}
                                            isCreatable
                                            isMulti

                                            label={t('widgets:Dhcpd.relayIp.title')}
                                            name="relay"
                                            noDropdownIndicator
                                            noOptionsMessage
                                            onChange={this.changeValue}
                                            schema={schemas.relay}
                                            value={relay}
                                        /> :
                                        null
                                    }
                                    {type === DHCP_SERVER_TYPE ?
                                        <>
                                            <NetworkInterfaceDevice
                                                disabled={__off}
                                                id={'gateways' + uuid}
                                                iface={gateways}
                                                isCreatable
                                                isMulti

                                                label={t('widgets:Dhcpd.gateways.title')}
                                                name="gateways"
                                                noDropdownIndicator
                                                noOptionsMessage
                                                onChange={this.changeValue}
                                                placeholder={placeholders?.gateways}
                                                placeholderAsValue
                                                schema={schemas.gateways}
                                            />
                                            <Select
                                                disabled={__off}
                                                id={'defaultDomain' + uuid}
                                                isCreatable={!defaultDomain || defaultDomain?.[0] === ''}
                                                isMulti
                                                label={t('widgets:Dhcpd.defaultDomain.title')}
                                                name="defaultDomain"
                                                noDropdownIndicator
                                                noOptionsMessage
                                                noWrap
                                                onChange={this.changeValue}
                                                placeholder={placeholders?.defaultDomain}
                                                placeholderAsValue
                                                schema={schemas.defaultDomain}
                                                value={defaultDomain}
                                            />

                                        </> : null
                                    }
                                </MDBCol>
                                <MDBCol>
                                    {type === DHCP_SERVER_TYPE &&
                                    <>
                                        <NetworkInterfaceDevice
                                            disabled={__off}
                                            id={'nameservers' + uuid}
                                            iface={nameservers}
                                            isCreatable
                                            isMulti

                                            label={t('widgets:Dhcpd.nameservers.title')}
                                            name="nameservers"
                                            noDropdownIndicator
                                            noOptionsMessage
                                            noWrap
                                            onChange={this.changeValue}
                                            placeholder={placeholders?.nameservers}
                                            placeholderAsValue
                                            schema={schemas.nameservers}
                                        />
                                        <Input
                                            disabled={__off}
                                            id={'leaseTime' + uuid}
                                            label={t('widgets:Dhcpd.leaseTime.title')}
                                            name="leaseTime"
                                            onChange={this.setTimeout}
                                            placeholder={DEFAULT_LEASE_PLACEHOLDER}
                                            validator={this.timeoutValidator}
                                            value={leaseTime ? valueFormatter.formatSeconds(leaseTime) : ''}
                                        />
                                    </>
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBCol>
                                {type === DHCP_SERVER_TYPE &&
                                    <MDBRow className="dhcpd__tableRow">
                                        <MDBCol className={shownSettings ? 'dhcpd__card' : 'dhcpd__card--big'}>
                                            <DhcpdDatatableContext.Provider value={
                                                { parentUuid: uuid, type: POOL_TYPE, pools: true }
                                            }
                                            >
                                                <DhcpdDatatable
                                                    ids={pools}

                                                />
                                            </DhcpdDatatableContext.Provider>
                                        </MDBCol>
                                        <MDBCol className={shownSettings ? 'dhcpd__card' : 'dhcpd__card--big'}>
                                            <DhcpdDatatableContext.Provider value={
                                                { parentUuid: uuid, type: LEASE_TYPE }
                                            }
                                            >
                                                <DhcpdDatatable
                                                    ids={leases}
                                                />
                                            </DhcpdDatatableContext.Provider>

                                        </MDBCol>
                                    </MDBRow>
                                }
                            </MDBCol>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                {type === DHCP_SERVER_TYPE &&
                    <MDBCol size="12">
                        <DhcpdServerContext.Provider value={
                            { uuid: uuid }
                        }
                        >
                            <DhcpdRoutes
                                data={routes || EMPTY_IMMUTABLE_ARR}
                            />
                        </DhcpdServerContext.Provider>
                    </MDBCol>
                }
                {type === DHCP_SERVER_TYPE &&
                    <MDBCol size="12">
                        <DhcpLeases
                            uuid={uuid}
                        />
                    </MDBCol>
                }
            </MDBRow>
        );
    }
}

export default DhcpdService;
