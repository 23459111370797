/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact';
import { useTranslation } from 'react-i18next';

import { Switch } from '~frontendComponents/Generic/index.js';
import Divider from '~frontendRoot/components/Divider/index.js';
import { useHlcfgOffableValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';


const SafeSearch = () => {
    const { t } = useTranslation();
    const safesearch = useHlcfgOffableValue(it => it.services.dns.safesearch);
    return (
        <MDBCard>
            <MDBCardHeader>
                {t('widgets:Dns.safesearch.title')}
            </MDBCardHeader>
            <MDBCardBody className="safeSearch--card">
                {t('widgets:Dns.safesearch.desc')}

                <Switch
                    align="spaceBetween"
                    checked={safesearch.isOn}
                    className="mb-4"
                    id="safeSearchGlobal"
                    label={t('widgets:Dns.safesearch.title')}
                    name="enabled"
                    onChange={({ value }) => value ? safesearch.setOn() : safesearch.setOff()}
                />
                {safesearch.isOn &&
                    <div className="safeSearch">
                        <Divider />
                        <div className="pl-4 pr-4 pt-2">

                            <Switch
                                align="spaceBetween"
                                checked={safesearch.value.google}
                                className=""
                                id="google"
                                label={t('widgets:Dns.safesearch.google')}
                                name="google"
                                onChange={safesearch.inputSetValue}
                            />
                            <Switch
                                align="spaceBetween"
                                checked={safesearch.value.bing}
                                className=""
                                id="bing"
                                label={t('widgets:Dns.safesearch.bing')}
                                name="bing"
                                onChange={safesearch.inputSetValue}
                            />
                        </div>
                    </div>}
            </MDBCardBody>

        </MDBCard>
    );
};

export default SafeSearch;
