/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import {  MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol,
    MDBRow } from 'mdbreact';
import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {  Icon, Switch, Select, Input } from '~frontendComponents/Generic/index.js';
import {
    setNormalize,
    addToNormalize,
    duplicateFromNormalize,
    getTableSchema,
    getIsGoogleAuth,
    getIsGoogleAuthInit,
    getIsVpnUserFromInit,
} from '~frontendDucks/hlcfgEditor/index.js';
import { getVpnUser, makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { VPN_ROUTES_TYPE_PUSH_TO_USER, VPN_USER_QR_CODE } from '~frontendConstants/index.js';
import Message from '~frontendComponents/Message/index.js';
import { OPENVPN_USER_TYPE_CLIENT, OPENVPN_USER_TYPE_S2S } from '~sharedConstants/constants.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { vpnUserQrcodeRequest } from '~frontendDucks/vpnUserQrcode/index.ts';
import RowMenuAndSwitch from '~frontendComponents/RowMenuAndSwitch.tsx';
import { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import RowDivider, { RowDividerTypes } from '~frontendComponents/RowDivider.tsx';
import { HlcfgTableItemSchema } from '~frontendTypes/externalTypes.ts';

import VpnRouteTable from '../../../../VpnRouteTable/index.js';
import { VpnRoutingTableContext } from '../../../../VpnRouteTable/Row/index.ts';


export const VpnPushToUserContext = createContext({ search: '', uuid: '' } as { search: string, uuid: HlcfgRowId });

const VpnUserRow = ({ dataIndex, uuid, spacing }: CreateRowPropsType) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ routesOpen, setRoutesOpen ] = useBoolean();

    const { uuid: vpnUuid, search } = useContext(VpnPushToUserContext);
    const vpnUser = useMemo(getVpnUser, []);
    const getterSearch = useMemo(makeSelectSearchedTableItem, []);

    const data = useSelector(state => vpnUser(state, uuid));
    const schema: HlcfgTableItemSchema<'openvpnUser'> = useSelector(state => getTableSchema(state, uuid));
    const matches: string[] = useSelector(state => getterSearch(state, uuid, search));
    const isGoogleAuth: boolean = useSelector(state => getIsGoogleAuth(state, vpnUuid));
    const isGoogleAuthInit: boolean = useSelector (state => getIsGoogleAuthInit(state, vpnUuid));
    const isVpnUserFromInit: boolean = useSelector (state => getIsVpnUserFromInit(state, uuid));

    const changeValue = useCallback(({ value, name  }) => {
        dispatch(setNormalize({ uuid, key: name, value }));
    }, [ dispatch, uuid ]);

    const changeValueId = useCallback(({ value, name, id  }) => {
        dispatch(setNormalize({ uuid, key: name, subkey: id, value }));
    }, [ dispatch, uuid ]);

    const addRow = useCallback((addingAfter?: boolean) => {
        dispatch(addToNormalize({
            type: 'openvpnUser', //just one type
            addingAfter,
            key: 'pushToUser',
            uuidToAddBefore: uuid,
            uuid: vpnUuid,
            successText: 'widgets:Vpn.pushToUser.added',
            extraValues: {
                type: OPENVPN_USER_TYPE_CLIENT
            }
        }));
    }, [ dispatch, uuid, vpnUuid ]);

    const handleAddRow = useCallback((event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
        event.preventDefault();
        addRow();
    }, [ addRow ]);

    const addRowSite = useCallback((addingAfter?: boolean) => {
        dispatch(addToNormalize({
            type: 'openvpnUser', //just one type
            addingAfter,
            key: 'pushToUser',
            uuidToAddBefore: uuid,
            uuid: vpnUuid,
            successText: 'widgets:Vpn.pushToUser.added',
            extraValues: {
                type: OPENVPN_USER_TYPE_S2S
            }

        }));
    }, [ dispatch, uuid, vpnUuid ]);

    const handleAddRowSite = useCallback((event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
        event.preventDefault();
        addRowSite();
    }, [ addRowSite ]);

    const delRow = useCallback(() => {
        dispatch(setNormalize({
            uuid: vpnUuid, value: uuid, key: 'pushToUser'
        }));
    }, [ dispatch, uuid, vpnUuid ]);

    const copyRow = useCallback(() => {
        dispatch(duplicateFromNormalize({
            uuid: uuid,
            parentKey: 'pushToUser',
            parentUuid: vpnUuid,
        }));
    }, [ dispatch, uuid, vpnUuid ]);

    const openQrcode = useCallback(() => {
        dispatch(vpnUserQrcodeRequest({ openvpnUserId: uuid }));
        dispatch(setModalState({ modal: VPN_USER_QR_CODE, value: true, specialValues: {} }));
    }, [ dispatch, uuid ]);

    const [ types ] = useState<RowDividerTypes>([
        { addFunc: addRow, translation: 'widgets:Vpn.pushToUser.user' },
        { addFunc: addRowSite, translation: 'widgets:Vpn.pushToUser.s2s' },
    ]);

    if (!data?.id) {
        return (
            <tr
                className="dataTableWidget__RowFirstRule"
                key="newRoute"
            >
                <td colSpan={1}></td>
                <td
                    className="dataTableWidget__RowFirstRuleRow"
                    colSpan={6}
                >
                    <div className="pl-2">
                        <h2>{t('widgets:Vpn.pushToUser.title')}</h2>
                        <p className="dataTableWidget__Rowtext">
                            {t('widgets:Vpn.pushToUser.desc1')}
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                data-cy="addVpnUser1"
                                onClick={handleAddRow}
                            >
                                <Icon name="plus" /> {t('widgets:Vpn.pushToUser.user')}
                            </MDBBtn>
                        </p>
                        <p className="dataTableWidget__Rowtext">
                            {t('widgets:Vpn.pushToUser.desc2')}
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                data-cy="addVpnUserS2S1"
                                onClick={handleAddRowSite}
                            >
                                <Icon name="plus" /> {t('widgets:Vpn.pushToUser.s2s')}
                            </MDBBtn>
                        </p>
                        <p>
                            {t('widgets:Vpn.pushToUser.desc3')}
                        (
                            <Icon
                                name="menu"
                                size="sm"
                            />)
                            {t('widgets:Vpn.pushToUser.desc4')}

                        </p>
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            data-cy="addVpnUser2"
                            onClick={handleAddRow}
                        >
                            <Icon name="plus" /> {t('widgets:Vpn.pushToUser.user')}
                        </MDBBtn>
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            data-cy="addVpnUserS2S2"
                            onClick={handleAddRowSite}

                        >
                            <Icon name="plus" /> {t('widgets:Vpn.pushToUser.s2s')}
                        </MDBBtn>

                    </div>
                </td>
            </tr>
        );
    }
    const {  nameservers, defaultGateway, addresses, __off, commonName, routes,
        ntp, domain, force, useDefaults, type, id,
        siteToSiteNetworks, disabled } = data;
    return (
        <Draggable
            //it needs string
            draggableId={String(dataIndex)}
            index={dataIndex}
            key={dataIndex}
        >
            {(provided: DraggableProvided) => (

                <>
                    <RowDivider
                        id={id}
                        length={8}
                        types={types}
                    />
                    <tr
                        className={classNames(
                            'dataTableWidget__Row',
                            'profiles__row',
                            'dataTableWidget__Row--important',
                            'packetFilter__edit',
                            { 'dataTableWidget__Row--disable': __off },
                            { 'dataTableWidget__Row--match': matches.length },
                        )}
                        key={dataIndex}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { 'dataTableWidget__cell--match': matches.length },
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                            {...provided.dragHandleProps}
                        >

                            <Icon
                                name="drag"
                                size="sm"
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Select
                                disabled={__off}
                                id={'type' + uuid}
                                isRow
                                justIcon
                                name="type"
                                noDropdownIndicator
                                noOptionsMessage
                                noWrap
                                onChange={changeValue}
                                paste={false}
                                schema={schema?.type}
                                value={type}
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Switch
                                align="center"
                                checked={disabled}
                                className={classNames(
                                    'dataTableWidget__RowInput',
                                    'dataTableWidget__RowInput--sm',
                                )}
                                disabled={__off}
                                id={'disabled' + uuid}
                                inputStyle
                                mini
                                name={'disabled'}
                                onChange={changeValue}
                                tooltipText={disabled ? 'widgets:Vpn.pushToUser.columns.block.on' :
                                    'widgets:Vpn.pushToUser.columns.block.off'}
                                withoutBorder
                                withoutMinWidhtHeight
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Input
                                className={classNames(
                                    'dataTableWidget__RowInput',
                                )}
                                datacy={'userName' + dataIndex}
                                disabled={__off}
                                id={'userName' + uuid}
                                name="commonName"
                                onChange={changeValue}
                                value={commonName}
                                withoutBorder
                            />
                        </td>

                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'packetFilter__row',

                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                classNamePrefix="packetFilterSelect"
                                disabled={__off}
                                id={'userAddresses' +  uuid}
                                isCreatable
                                isMulti
                                name="addresses"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={changeValue}
                                schema={schema['addresses']}
                                value={addresses}
                            />
                        </td>

                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'packetFilter__row',

                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                classNamePrefix="packetFilterSelect"
                                disabled={__off || type === OPENVPN_USER_TYPE_CLIENT}
                                id={'userNetworks' +  uuid}
                                isCreatable
                                isMulti
                                name="siteToSiteNetworks"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={changeValue}
                                schema={schema['siteToSiteNetworks']}
                                tooltipText={type === OPENVPN_USER_TYPE_CLIENT ?
                                    'widgets:Vpn.pushToUser.notUsedNetwork' :
                                    undefined}
                                value={siteToSiteNetworks}
                            />
                        </td>
                        <td className={classNames(
                            'dataTableWidget__cell',
                            'dataTableWidget__cell--icon',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                        >
                            {(isGoogleAuth || isGoogleAuthInit) &&
                                <IconWithTooltip
                                    className={classNames(
                                        'ml-1',
                                        { 'icon--disabled': !isGoogleAuthInit || __off || !isVpnUserFromInit },
                                    )}
                                    link
                                    name="qrcode"
                                    onClick={isGoogleAuthInit &&
                                         !__off && isVpnUserFromInit ? openQrcode : undefined}
                                    tooltipText={isGoogleAuthInit && isVpnUserFromInit ? 'widgets:Vpn.qr.title' :
                                        'widgets:Vpn.qr.notActive'}
                                />}
                        </td>
                        <RowMenuAndSwitch
                            __off={__off}
                            copyFunc={copyRow}
                            deleteFunc={delRow}
                            id={'routeTableOff' + uuid}
                            onChange={changeValue}
                            settings={setRoutesOpen.swap}
                            spacing={spacing}
                        />
                    </tr>
                    <tr
                        className={classNames(
                            'dataTableWidget__RowAdd',
                            { 'displayNone': !routesOpen },
                            { 'bounce-in-top': routesOpen },
                        )}
                    >
                        {routesOpen &&
                        <>
                            <td></td>

                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'packetFilter__row',
                                    { 'm-0 p-0': !routesOpen  },
                                )}
                                colSpan={5}
                            >
                                <MDBRow>
                                    <MDBCol
                                        lg="8"
                                        md="8"
                                        sm="12"
                                    >
                                        <VpnRoutingTableContext.Provider
                                            value={
                                                {
                                                    type: VPN_ROUTES_TYPE_PUSH_TO_USER,
                                                    userUuid: uuid,
                                                    vpnUuid: vpnUuid
                                                }
                                            }
                                        >
                                            <VpnRouteTable
                                                className={classNames(
                                                    { 'remove-height': !routesOpen },
                                                )}
                                                data={routes}
                                            />
                                        </VpnRoutingTableContext.Provider>
                                    </MDBCol>
                                    <MDBCol
                                        lg="4"
                                        md="4"
                                        sm="12"
                                    >
                                        <MDBCard className={classNames(
                                            { 'remove-height': !routesOpen },
                                        )}
                                        >
                                            <MDBCardTitle className="vpn__pushToUserHeader">
                                                <div className="d-flex align-items-center">
                                                    <Message message="widgets:Vpn.pushToUser.title" />
                                                </div>
                                                <div>
                                                    <Switch
                                                        align="left"
                                                        checked={useDefaults}
                                                        className={classNames(
                                                            'mr-2',
                                                            'justify-content-center',
                                                            'mb-0',
                                                            'mt-0'
                                                        )}
                                                        disabled={__off}
                                                        id={'useDefaults' + uuid}
                                                        mini
                                                        name="useDefaults"
                                                        noticeMe={useDefaults}
                                                        onChange={changeValue}
                                                        schema={schema['useDefaults']}
                                                        tooltipText={t(`widgets:Vpn.pushToUser.${!useDefaults ?
                                                            'deactivate' : 'activate'}`)}
                                                        withoutBorder
                                                        withoutLabelMargins
                                                        withoutMinWidhtHeight
                                                    />
                                                </div>
                                            </MDBCardTitle>
                                            <MDBCardBody>
                                                <Select
                                                    disabled={__off || useDefaults}
                                                    id={'addresses'}
                                                    isCreatable
                                                    isMulti

                                                    label={
                                                        t('widgets:Vpn.pushToClient.nameservers.addresses.title')
                                                    }
                                                    message="dhcp-option DNS (windows only option)"
                                                    name="nameservers"
                                                    noDropdownIndicator
                                                    noOptionsMessage
                                                    onChange={changeValueId}
                                                    placeholderAsValue
                                                    schema={schema['nameservers'].properties.addresses}
                                                    value={nameservers}
                                                />
                                                <Switch
                                                    align="spaceBetween"
                                                    checked={force}
                                                    className="mb-4"
                                                    disabled={__off || useDefaults}
                                                    id={'force'}
                                                    label={t('widgets:Vpn.pushToClient.nameservers.force.title')}
                                                    message="push 'block-outside-dns'"
                                                    name={'nameservers'}
                                                    onChange={changeValueId}
                                                    schema={schema['nameservers'].properties.force}
                                                />
                                                <Switch
                                                    align="spaceBetween"
                                                    checked={defaultGateway}
                                                    className="mb-4"
                                                    disabled={__off || useDefaults}
                                                    id="defaultGatewayId"
                                                    label={t('widgets:Vpn.pushToClient.defaultGateway.title')}
                                                    message={'push "redirect-gateway def1"'}
                                                    name="defaultGateway"
                                                    onChange={changeValue}
                                                    schema={schema.defaultGateway}
                                                />
                                                <Select
                                                    disabled={__off || useDefaults}
                                                    id={'userNtp' +  uuid}
                                                    isCreatable
                                                    isMulti

                                                    label={t('widgets:Vpn.pushToClient.ntp.title')}
                                                    message="dhcp-option NTP (windows only option)"
                                                    name="ntp"
                                                    noDropdownIndicator
                                                    noOptionsMessage
                                                    onChange={changeValue}
                                                    placeholderAsValue
                                                    schema={schema['ntp']}
                                                    value={ntp}
                                                />
                                                <Select
                                                    className="mb-0 pb-0"
                                                    disabled={__off || useDefaults}
                                                    id={'userDomain' +  uuid}
                                                    isCreatable
                                                    isMulti

                                                    label={t('widgets:Vpn.pushToClient.domain.title')}
                                                    message="dhcp-option DOMAIN (windows only option)"
                                                    name="domain"
                                                    noDropdownIndicator
                                                    noOptionsMessage
                                                    onChange={changeValue}
                                                    placeholderAsValue
                                                    schema={schema['domain']}
                                                    value={domain}
                                                />
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </>
                        }
                    </tr>
                    <RowDivider
                        after
                        id={id}
                        length={8}
                        types={types}
                    />
                </>
            )}
        </Draggable>
    );
};

export default VpnUserRow;
