/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';

import { Icon } from '~frontendRoot/components/Generic/index.js';
import { buildnumParse } from '~commonLib/buildnumParse.ts';


interface IUpgradeFormat {
    from: string
    to: string
}

const UpgradeFormat = ({ from, to }: IUpgradeFormat) => {
    return (
        <>
            <span className="BoldVersion">
                {buildnumParse(from).productVersion}
            </span>
            <Icon
                className="ChangedVersionArrow"
                name="chevron-right"
                size="nav"
            />
            <span className="BoldVersion">
                {buildnumParse(to).productVersion}
            </span>
        </>
    );
};

export default UpgradeFormat;
