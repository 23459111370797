/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';

import { Scene } from '~frontendComponents/Scene/index.js';
import { WAF_PROFILES } from '~frontendConstants/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { useHlcfgOffableValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';

import PolicyProfiles from './PolicyProfiles.tsx';


const PolicyProfileService = () => {
    const proxy = useHlcfgOffableValue(it => it.protection.proxy);
    return (
        <Scene>
            <Service
                id={WAF_PROFILES}
                on={proxy.isOn}
                showService={(
                    <PolicyProfiles />
                )}
            />
        </Scene>
    );
};

export default PolicyProfileService;
