/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { memo, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import classNames from 'classnames';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles/index.js';

//https://github.com/gregnb/mui-datatables#api for options
const DatatableNoMemo = (props: {
data?: any[],
    columns: any[],
    options: object,
    title?: string,
    className?: string,
    isDraggable?: boolean,
    compact?: boolean,
}) => {
    const { compact, className, options, title, isDraggable, columns, data } = props;
    const muiTheme = useMemo(() => {
        return createTheme({
            overrides: {
                MuiPaper: {
                    elevation4: {
                        boxShadow: 'inset 0px 1px 0px 0px rgba(88, 74, 60, 0.1)',
                        borderRadius: 0,
                    }
                },
                MuiTableCell: {
                    head: {
                        padding: compact ?  '2px' : '8px 12px 8px 12px',
                        fontSize: compact ? '0.9rem' : '1rem',
                    }
                },
                MuiTable: {
                    root: {
                        fontFamily: 'Encode Sans, sans-serif !important'
                    }
                },
                MuiTableRow: {
                    head: {
                        height: 0
                    }
                },
                MuiTooltip: {
                    tooltip: {
                        fontSize: '0.825rem'
                    }
                },
            }
        });
    }, [ compact ]);
    return (
        <MuiThemeProvider theme={muiTheme}>
            <MUIDataTable.default
                className={classNames(
                    className,
                    'dataTableWidget',
                    { 'dataTableWidget--sortable': !isDraggable }
                )}
                columns={columns}
                data={data}
                options={options}
                title={title}
            />
        </MuiThemeProvider>
    );
};
const Datatable = memo(DatatableNoMemo);

export default Datatable;
