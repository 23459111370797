/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const CERTIFICATE_PKCS = 'x-pkcsCertificate';
export const CERTIFICATE_PRIVATE_KEY = 'x-privateKeyCertificate';
export const CERTIFICATE_PUBLIC = 'x-publicCertificate';
export const CLI_OPT_SHORTHAND = 'x-cli-shorthand';
export const CLI_OPT_AUTOCOMPLETE = 'x-cli-autocompleteFn';
export const DEFAULT_SCHEMA_VALUE = 'x-default';
export const DURATION_SCHEMA_VALUE = 'x-duration';
export const FILE_SCHEMA_VALUE = 'x-file';
export const PASSWORD_SCHEMA_VALUE = 'x-password';
export const PLACEHOLDER_SCHEMA_VALUE = 'x-placeholder';
export const REVERTED_BOOLEAN_SCHEMA_VALUE = 'x-revertedBoolean'; //For frontend display of value
export const NAME_GROUP = 'x-name-group';

/**
 * Flag for specifying that this address is a service address.
 * In cluster, service address may contain own address of one of the nodes.
 * The own address will be validated and generated only for relevant cluster node.
 */
export const SERVICE_ADDRESS = 'x-service-address';

export const nameGroups = <const>{
    namedObjects: 'namedObject',
};
export const SYSTEM_ONLY_SETTABLE_ENUMS = 'x-systemOnlySettableEnums'; //Enum values that should not be visible to user
export const TRANSLATION_SCHEMA_VALUE = 'x-translation';
export const WITHOUT_NAMED_OBJECTS = 'x-withoutNamedObjects'; //Not allowing reference to named objects in this schema
export const WITHOUT_ADDR_SELECTORS = 'x-withoutAddrSelectors'; //Not allowing address selectors
