/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Draggable } from '@hello-pangea/dnd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { NEW_ROW_CONSTANT } from '~frontendConstants/constants.ts';
import { DNS_PROXY_PROFILES } from '~frontendConstants/glcfgConstants.js';
import { getActiveCardHook } from '~frontendDucks/activeCards/activeCards.js';
import { closeHeaderProfile } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSetters.js';
import { addToNormalize, duplicateFromNormalize, setNormalize } from '~frontendDucks/hlcfgEditor/normalizeTableGettersAndSetters.js';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';

import { RenderDivider, RenderEmptyRule, RenderRule } from './renderers.tsx';


const Row = (props: { uuid: string, dataIndex: number, spacing: string }) => {

    const { uuid, dataIndex, spacing } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const activeProfile = useSelector(getActiveCardHook(DNS_PROXY_PROFILES));

    const addRow = useCallback((addingAfter) => {
        dispatch(addToNormalize(
            {
                type: 'dnsProxyRule',
                typeId: DNS_PROXY_PROFILES,
                addingAfter: typeof addingAfter === 'boolean',
                key: 'rules',
                uuid: activeProfile,
                uuidToAddBefore: uuid,
                successText: 'profile:rule.added',
                extraValues: {
                    action: 'resolve',
                }
            }
        ));
    }, [ activeProfile, uuid, dispatch ]);

    const addHeader = useCallback((addingAfter) => {
        dispatch(addToNormalize(
            {
                type: 'dnsProxyHeader',
                typeId: DNS_PROXY_PROFILES,
                addingAfter: typeof addingAfter === 'boolean',
                key: 'rules',
                uuid: activeProfile,
                uuidToAddBefore: uuid,
                successText: 'profile:header.added',
            }
        ));
    }, [ activeProfile, uuid, dispatch ]);

    const copyRow = useCallback(() => {
        dispatch(duplicateFromNormalize({
            uuid: uuid,
            parentKey: 'rules',
            parentUuid: activeProfile,
        }));
    }, [ activeProfile, uuid, dispatch ]);

    const deleteRow = useCallback(() => {
        dispatch(setNormalize({
            uuid: activeProfile,
            key: 'rules',
            value: uuid
        }));
    }, [ activeProfile, uuid, dispatch ]);

    const setValue = useCallback(({ name, value, key }) => {
        const isNumber = name === 'actionFakeTtl' && value !== undefined;
        dispatch(setNormalize({ value: isNumber ? parseInt(value) : value, key: key ?? name,
            uuid, emptyArrayAsUndefined: true }));
    }, [ uuid, dispatch ]);

    const closeRules = useCallback(({ value }) => {
        dispatch(closeHeaderProfile({ uuid, value: value, type: DNS_PROXY_PROFILES }));
    }, [ dispatch, uuid ]);

    const [ types ] = useState([
        { addFunc: addRow, translation: 'profile:profiles.rules.rule' },
        { addFunc: addHeader, translation: 'packetFilter:header' }
    ]);

    if (uuid === NEW_ROW_CONSTANT) {
        return (
            <RenderEmptyRule
                addHeader={addHeader}
                addRow={addRow}
                t={t}
            />
        );
    }

    return (
        <Draggable
            draggableId={uuid}
            index={dataIndex}
            isDragDisabled={false}
            key={uuid}
        >
            {(provided) => {
                if (hlcfgTableNameByRowId(uuid) === 'dnsProxyRule') {
                    return (
                        <RenderRule
                            copyRow={copyRow}
                            deleteRow={deleteRow}
                            provided={provided}
                            setValue={setValue}
                            spacing={spacing}
                            types={types}
                            uuid={uuid}
                        />
                    );
                }
                return (
                    <RenderDivider
                        closeRules={closeRules}
                        copyRow={copyRow}
                        deleteRow={deleteRow}
                        provided={provided}
                        setValue={setValue}
                        spacing={spacing}
                        types={types}
                        uuid={uuid}
                    />
                );
            }}
        </Draggable>
    );
};

export default Row;
