/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { pipe } from '../pipe.ts';


const QUOT_PLACEHOLDER = 'QUOT_PLACEHOLDER';

const STRING_BRACKET_NOTATION_REGEX = /^(.*?)\["(.+?)"\]/;
const NUMBER_BRACKET_NOTATION_REGEX = /^(.*?)\[([0-9]+)\]/;

export const jsonschemaPropertyPathToGetValueArrayPath = jsonSchemaPath => {
    let unparsedParts = replaceEscapedQuotesWithPlaceholder(jsonSchemaPath).split('.');
    const newPath = [];

    const main = () => {
        let i = 0;
        while (unparsedParts.length) {
            if (partHasBracketNotation(unparsedParts[0])) {
                parsePartWithBracketNotation();
            } else {
                newPath.push(unparsedParts.shift());
            }
            if (++i > 10000) {
                throw new Error('Max iteration count reached, this means there is a bug in this function');
            }
        }
        return finalizePathArr(newPath);
    };

    const parsePartWithBracketNotation = () => {
        if (partHasStringBracketNotation(unparsedParts[0])) {
            stringBracketNotationParser();
        } else {
            numberBracketNotationParser();
        }
    };

    const stringBracketNotationParser = () => {
        while (partHasIncompleteStringBracketNotation(unparsedParts[0])) {
            joinFirstTwoUnparsedParts();
        }
        completeStringBracketNotationParser();
    };

    const joinFirstTwoUnparsedParts = () => {
        const [ first, second, ...other ] = unparsedParts;
        unparsedParts = [ first + '.' + second, ...other ];
    };

    const getBracketNotationParser = (regex) => () => {
        const numberBracketMatches = unparsedParts[0].match(regex);
        if (numberBracketMatches) {
            const [ fullMatch, partOne, partTwo ] = numberBracketMatches;
            if (partHasBracketNotation(partOne)) {
                // this means that string bracket notation was found in this part
                // but there is also number bracket notation earlier in part, like match='[1][2][3]["a"]'
                // so we switch to number notation parser to ensure correct parsing order
                numberBracketNotationParser();
                return;
            }
            pushParts(partOne, partTwo);
            if (fullMatch.length === unparsedParts[0].length) {
                unparsedParts.shift();
            } else {
                unparsedParts[0] = unparsedParts[0].substr(fullMatch.length);
            }
        }
    };

    const pushParts = (partOne, partTwo) => {
        if (partOne) {
            newPath.push(partOne);
        }
        newPath.push(partTwo);
    };

    const completeStringBracketNotationParser =
        getBracketNotationParser(STRING_BRACKET_NOTATION_REGEX);

    const numberBracketNotationParser =
        getBracketNotationParser(NUMBER_BRACKET_NOTATION_REGEX);

    return main();
};

const replaceEscapedQuotesWithPlaceholder = string => string.replaceAll('\\"', QUOT_PLACEHOLDER);
const replaceQuotePlaceholderWithQuote = string => string.replaceAll(QUOT_PLACEHOLDER, '"');

const partHasBracketNotation = part => part.indexOf('[') !== -1;
const partHasStringBracketNotation = part => part.indexOf('["') !== -1;
const partHasIncompleteStringBracketNotation = part => partHasStringBracketNotation(part) && part.indexOf('"]') === -1;

const cutOffJsonSchemaInstancePart = path => path.slice(1);

const finalizePathArr = pipe(
    cutOffJsonSchemaInstancePart,
    pathArr => pathArr.map(replaceQuotePlaceholderWithQuote)
);
