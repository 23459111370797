/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBCardTitle, MDBCard } from 'mdbreact';
import { useTranslation } from 'react-i18next';


import {
    getRoutingTable,
    setNormalizeOrder,
} from '~frontendDucks/hlcfgEditor/index.js';
import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { SMALL_SIZE, ROUTING_TABLE, NEW_ROW_CONSTANT, SELECTABLE_TABLE_ROUTING } from '~frontendConstants/index.js';
import { InputSearch } from '~frontendComponents/Generic/index.js';
import { useBoolean, useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';

import Row from './Row/index.js';


const CreateRow = ({
    dataIndex,
    spacing,
    search, uuid }: CreateRowPropsType) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid}
            search={search}
            spacing={spacing}
            uuid={uuid}
        />
    );
};


const RoutingTable = ({ multihoming }: {multihoming: boolean}) => {
    const [ search, setSearch ] = useString('');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const routeTable: string[] = useSelector(getRoutingTable);
    const data = useMemo(() => routeTable.length ? routeTable : [ NEW_ROW_CONSTANT ], [ routeTable ]);
    const passReorderData = useCallback((value) => {
        dispatch(setNormalizeOrder({ type: ROUTING_TABLE, value }));
    }, [ dispatch ]);
    const [ update, setUpdate ] = useBoolean(false);
    useEffect(() => {
        if (multihoming) {
            setUpdate.swap();
        }
    }
    , [ multihoming, setUpdate ]);

    return (
        <MDBCard className={update ? 'routing-table-jump' : ''}>
            <MDBCardTitle className="profiles__title">
                {t('widgets:routeTable.title')}
                <InputSearch
                    className="mb-3 mt-0"
                    id="searchValueIDRoute"
                    search={search}
                    setter={setSearch}
                />
            </MDBCardTitle>
            <DatatableWidget
                columnsId={SELECTABLE_TABLE_ROUTING}
                createRow={CreateRow}
                data={data}
                passReorderData={passReorderData}
                search={search}
                spacing={SMALL_SIZE}
            />
        </MDBCard>

    );
};

export default RoutingTable;
