/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { HlcfgTypeOpenVpnUser, HlcfgTypeRoute } from '~backendLib/newHlcfgGetters/hlcfgPathsAndTypes.ts';
import { OPENVPN_USER_TYPE_S2S } from '~sharedRoot/constants/index.ts';
import { NetaddrIp4Data } from '~sharedLib/types.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';


type GeneratedRoute = Pick<HlcfgTypeRoute, 'gateway'|'destination'>

export const generateRouteForOpenVpnUser = (vpnUser: HlcfgTypeOpenVpnUser): GeneratedRoute[]|undefined => {
    if (vpnUser.type !== OPENVPN_USER_TYPE_S2S || !vpnUser.siteToSiteNetworks) {
        return;
    }
    return vpnUser.siteToSiteNetworks.map(destination => ({
        destination,
        gateway: vpnUser.addresses[0]
    }));
};


export const vpnGateway = (gateway?: NetaddrIp4Data) => {
    return gateway ? netaddr(gateway) : 'vpn_gateway';
};
