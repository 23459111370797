/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useEffect, useState } from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DEFAULT_PROFILE_CATEGORY_ACTION, DEFAULT_PROFILE_CATEGORY_ENABLE, CWV2_SUPERCATEGORIES } from '~sharedConstants/constants.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { TableSizeType } from '~frontendConstants/constants.ts';
import { ConfirmPopover, Switch } from '~frontendComponents/Generic/index.js';
import { setNormalize } from '~frontendDucks/hlcfgEditor/normalizeTableGettersAndSetters.js';
import { HlcfgTableItem } from '~frontendRoot/types/externalTypes.ts';
import { getTableItem, setProxyActionCategories, setProxyCategories } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSetters.js';
import { PROFILE_RULES } from '~frontendConstants/glcfgConstants.js';
import { CW_DEF, CW_LAYOUT } from '~libCw/cwCategories.ts';
import { UNKNOWN } from '~commonLib/constants.ts';
import { objectWithKeys } from '~commonLib/objectUtils.ts';


const areAllValuesTrue = (data: HlcfgTableItem<'profileRule'>['categories'], categories) => {
    return categories.every((key: string) => data?.[key] ?? DEFAULT_PROFILE_CATEGORY_ENABLE);
};

type CwCategoriesV2Type = {
    spacing: TableSizeType,
    byCategory: boolean,
    data?: Record<string, boolean>,
    actionData: HlcfgTableItem<'profileRule'>['action']['accordingToCategory'],
    uuid: string,
    action: string,
}

const CwCategoriesV2 = ({ spacing, byCategory, data, uuid, actionData, action }: CwCategoriesV2Type) => {
    const [ onlySuperCategories, setOnlySuperCategories ] = useBoolean();
    useEffect(() => {
        if (spacing === 'sm') {
            setOnlySuperCategories.on();
        } else {
            setOnlySuperCategories.off();
        }
    }, [ spacing, setOnlySuperCategories ]);

    return (
        <>
            <MDBRow>
                {Object.keys(CW_LAYOUT).map((zone) => {
                    if (zone === UNKNOWN) {
                        return;
                    }
                    return (
                        <MDBCol
                            key={zone}
                            size={zone === CW_DEF.zones.gray ? '6' : '3'}
                        >
                            <MDBRow>
                                {Object.keys(CW_LAYOUT[zone]).map((supCategory) => {
                                    if (supCategory === UNKNOWN) {
                                        return;
                                    }
                                    return (
                                        <SuperCategory
                                            action={action}
                                            actionData={actionData}
                                            byCategory={byCategory}
                                            data={data}
                                            key={supCategory}
                                            onlySuperCategories={onlySuperCategories}
                                            spacing={spacing}
                                            superCategory={supCategory}
                                            uuid={uuid}
                                            zone={zone}
                                        />
                                    );
                                })}
                            </MDBRow>
                        </MDBCol>
                    );
                })}
            </MDBRow>
            {spacing === 'sm' ?
                <div
                    className="categories__More clicable"
                    onClick={() => setOnlySuperCategories.swap()}
                >
                    <IconWithTooltip
                        className={'icon--black'}
                        iconSize="sm"
                        link
                        name={`chevron-${onlySuperCategories ? 'down' : 'up'}`}
                        tooltipPlace={'top'}
                        tooltipText={`widgets:global.${onlySuperCategories ? 'showMore' : 'showLess'}`}
                    />
                </div> :
                null
            }
        </>
    );
};

type SuperCategoryType = {
    superCategory: string,
    zone: string,
    onlySuperCategories: boolean,
    byCategory: boolean,
    spacing: TableSizeType,
    data?: Record<string, boolean>,
    uuid: string,
    actionData: HlcfgTableItem<'profileRule'>['action']['accordingToCwCategoryV2'],
    action: string,
}

const SuperCategory = ({ superCategory, zone, onlySuperCategories,
    byCategory, spacing, data, uuid, actionData, action }: SuperCategoryType) => {
    const currentSupercategory = CWV2_SUPERCATEGORIES[superCategory];
    const { t } = useTranslation();
    const allValues = areAllValuesTrue(data, currentSupercategory);

    return (
        <MDBCol
            className="categories__superCategory categories--unset"
            size={zone === CW_DEF.zones.gray ? '3' : '6'}
        >
            <MDBCard
                className={`categories__${zone}--header categories--unset`}
            >
                <MDBCardTitle className="p-1 categories__Title">
                    <MDBRow
                        between
                        className="m-0"
                    >
                        {t(`cwSubZones:${superCategory}`)}
                        {byCategory ?
                            <CWv2ActionButtons
                                blocked={currentSupercategory.some((category: string) =>
                                    actionData?.[category] === 'block')}
                                category={superCategory}
                                data={actionData}
                                header
                                mixed={currentSupercategory.some(item => actionData?.[item] === 'block') &&
                                    currentSupercategory.some(item => actionData?.[item] === undefined)
                                }
                                superCategory={superCategory}
                                uuid={uuid}
                            /> :
                            <CWv2CategoryButtons
                                action={action}
                                allValues={allValues}
                                category={superCategory}
                                data={data}
                                header
                                spacing={spacing}
                                superCategory={superCategory}
                                uuid={uuid}
                                zone={zone}
                            />
                        }
                    </MDBRow>
                </MDBCardTitle>
                {!onlySuperCategories ?
                    <MDBCardBody
                        className={`categories__${zone} py-1 categories--unset`}
                    >
                        {Object.keys(CW_LAYOUT[zone][superCategory]).map((index) => {
                            const category = CW_LAYOUT[zone][superCategory][index];
                            if (category === UNKNOWN) {
                                return;
                            }
                            return (
                                <MDBRow
                                    between
                                    className={'categories__Category'}
                                    key={category}
                                >
                                    <p className="m-0 px-1">
                                        {t(`cwCategories:${category}`)}
                                    </p>
                                    {byCategory ?
                                        <CWv2ActionButtons
                                            blocked={actionData?.[category] === 'block'}
                                            category={category}
                                            superCategory={superCategory}
                                            uuid={uuid}
                                        /> :
                                        <CWv2CategoryButtons
                                            action={action}
                                            category={category}
                                            data={data}
                                            spacing={spacing}
                                            superCategory={superCategory}
                                            uuid={uuid}
                                            zone={zone}
                                        />
                                    }
                                </MDBRow>
                            );
                        })}
                    </MDBCardBody> :
                    null}
            </MDBCard>
        </MDBCol>
    );
};

type CWv2ActionButtonsType = {
    blocked?: boolean,
    uuid: string,
    header?: boolean,
    category: string,
    superCategory: string,
    mixed?: boolean,
    data?: HlcfgTableItem<'profileRule'>['action']['accordingToCwCategoryV2'],
}

const CWv2ActionButtons = ({ blocked, uuid, header, category, superCategory, mixed }: CWv2ActionButtonsType) => {
    const dispatch = useDispatch();
    const row = useSelector(state => getTableItem(state, uuid));
    const data = row.action.accordingToCwCategoryV2;
    const [ confirmModal, setConfirmModal ] = useBoolean(false);
    const [ swapValue, setSwapValue ] = useState();

    const setValue = useCallback(({ name, value }) => {
        dispatch(setNormalize({ type: PROFILE_RULES, key: 'action', subkey: 'accordingToCwCategoryV2',
            value: value === DEFAULT_PROFILE_CATEGORY_ACTION ? undefined : value, subsubkey: name, uuid, }));
    }, [ dispatch, uuid ]);

    const handleChangeSupercategory = useCallback(({ value, name }) => {
        const supCats = objectWithKeys(CWV2_SUPERCATEGORIES[name], value ===
            DEFAULT_PROFILE_CATEGORY_ACTION ? undefined : value);
        dispatch(setProxyActionCategories({ value: { ...data, ...supCats }, uuid, type: PROFILE_RULES }));
    }, [ dispatch, uuid, data ]);

    const confirmAllow = CW_LAYOUT.red?.[superCategory] && (data?.[category] === 'block' || header && blocked);
    const confirmBlock = CW_LAYOUT.green?.[superCategory] && (!data?.[category] || header && !blocked);

    const handleSwap = useCallback((value) => {
        setSwapValue(() => value);
        if (value === 'allow' && confirmAllow || value === 'block' && confirmBlock) {
            setConfirmModal.on();
        } else {
            header ? handleChangeSupercategory({ value, name: category }) : setValue({ value, name: category });
            setConfirmModal.off();
        }
    }, [ setConfirmModal, handleChangeSupercategory, setValue, category, confirmAllow, confirmBlock, header ]);

    const handleOnSuccess = () => {
        setConfirmModal.off();
        return header ? handleChangeSupercategory({ value: swapValue, name: superCategory }) :
            setValue({ value: swapValue, name: category });
    };

    const getColor = (isGreen: boolean) => {
        if (header && mixed) {
            return 'secondary';
        }
        if (isGreen) {
            return blocked ? 'secondary' : 'green';
        } else {
            return blocked ? 'red' : 'secondary';
        }
    };
    return (
        <ConfirmPopover
            isOpen={confirmModal}
            onCancel={setConfirmModal.off}
            onSuccess={handleOnSuccess}
            text={`proxy:confirmAction.${swapValue}Action`}
            title={'proxy:confirmAction.title'}
        >
            <ActionIcon
                action={'allow'}
                color={getColor(true)}
                onClick={handleSwap}
            />
            <ActionIcon
                action={'block'}
                color={getColor(false)}
                onClick={handleSwap}
            />
        </ConfirmPopover>
    );
};

type ActionIconType = {
    action: string,
    color: string,
    onClick: (value:string) => void,
}

const ActionIcon = ({ action, color, onClick }: ActionIconType) => {
    const handleOnClick = () => onClick(action);
    return (
        <IconWithTooltip
            className={`icon--${color}`}
            iconSize="sx"
            name={action === 'allow' ? 'checkbox-marked-circle' : 'close-circle'}
            onClick={handleOnClick}
            tooltipPlace={'top'}
            tooltipText={`policy:${action}.title`}
        />
    );
};

type CWv2CategoryButtonsType = {
    allValues?: boolean,
    category: string,
    spacing: TableSizeType,
    header?: boolean,
    data?: Record<string, boolean>,
    superCategory: string,
    zone: string,
    uuid: string,
    action: string
}

const CWv2CategoryButtons = ({ spacing, header, zone, allValues,
    superCategory, data, category, uuid, action }: CWv2CategoryButtonsType) => {
    const dispatch = useDispatch();
    const [ confirmModal, setConfirmModal ] = useBoolean(false);
    const setValue = useCallback(({ name, value }) => {
        dispatch(setNormalize({ type: PROFILE_RULES, key: 'cwCategoriesV2', subkey: name,
            value: value === DEFAULT_PROFILE_CATEGORY_ENABLE ? undefined : value, uuid, }));
    }, [ uuid, dispatch ]);

    const handleChangeSupercategory = useCallback(({ value, name }) => {
        const supCats = objectWithKeys(CWV2_SUPERCATEGORIES[name], value ===
            DEFAULT_PROFILE_CATEGORY_ENABLE ? undefined : value);
        dispatch(setProxyCategories({ value: { ...data, ...supCats }, uuid, type: PROFILE_RULES }));
    }, [ dispatch, uuid, data ]);

    const isRed = CW_LAYOUT.red?.[superCategory];
    const isGreen = CW_LAYOUT.green?.[superCategory];

    const handleSwitch = useCallback(({ value, name }) => {
        if (action === 'block' ? (isRed && !value) || (isGreen && value) :
            (isRed && value) || (isGreen && !value)) {
            setConfirmModal.on();
        } else {
            header ? handleChangeSupercategory({ value, name }) : setValue({ value, name });
            setConfirmModal.off();
        }
    }, [ setConfirmModal, handleChangeSupercategory, setValue, header, action, isGreen, isRed ]);

    const handleOnSuccess = () => {
        setConfirmModal.off();
        return header ? handleChangeSupercategory({ value: !checked, name: category }) :
            setValue({ value: !checked, name: category });
    };

    const checked = header ? allValues :
        data?.[category] === undefined ? DEFAULT_PROFILE_CATEGORY_ENABLE : data?.[category];

    return (
        <ConfirmPopover
            isOpen={!CW_LAYOUT.gray[superCategory] && confirmModal}
            onCancel={setConfirmModal.off}
            onSuccess={handleOnSuccess}
            text={`proxy:confirmAction.${!checked ? 'do' : 'doNot'}.${action}Rule`}
            title={'proxy:confirmAction.title'}
        >
            <div className="categories__Radio">
                <Switch
                    checked={checked}
                    className="CategoriesSwitch"
                    id={uuid + category}
                    mini
                    name={category}
                    onChange={handleSwitch}
                    withoutBorder
                    withoutLabelMargins
                    withoutMinWidhtHeight
                />
                {spacing === 'sm' && header ?
                    `${CWV2_SUPERCATEGORIES[superCategory].filter((item: string) =>
                        data?.[item] !== false).length}/${CW_LAYOUT[zone][superCategory].length}` :
                    null}
            </div>
        </ConfirmPopover>
    );
};

export default CwCategoriesV2;
