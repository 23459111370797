/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn, } from 'mdbreact';

import Changes from '~changes/Changes/index.js';
import { getSelectedLanguage } from '~frontendDucks/language/index.js';
import {
    getCurrentInfoTab,
    setInfoTab,
    InfoTabs,
    usePrepareUpgradeCallback } from '~frontendDucks/upgrade/index.js';
import Message from '~frontendComponents/Message/index.js';
import { getUpgradeNotice, setGlcfgObjectValue } from '~frontendDucks/hlcfgEditor/index.js';
import {
    SYSTEM_UPGRADE_STATE_ERROR,
} from '~commonLib/constants.ts';
import { Input } from '~frontendRoot/components/Generic/index.js';
import { useDispatchCallback, useValueChangedEffect } from '~frontendLib/hooks/defaultHooks.ts';
import Loader from '~frontendComponents/Loader/Loader.tsx';
import { testProps } from '~commonLib/PageObjectMap.ts';
import {
    useChangelogQuery, useUpgradeStateIsOfTypeQuery, useUpgradeStateQuery
} from '~frontendQueries/upgrade/hooks.ts';


export const ReleaseNotes = () => {
    const { t } = useTranslation();
    const {
        data: isUpgradeStateError, error: apiError
    } = useUpgradeStateIsOfTypeQuery(SYSTEM_UPGRADE_STATE_ERROR);

    const isError = isUpgradeStateError || !!apiError;
    const upgradeNotice = useSelector(getUpgradeNotice);
    const openedTab = useSelector(getCurrentInfoTab);
    const openInfoTab = useDispatchCallback(setInfoTab, []);

    const setUpgrade = useDispatchCallback(({ value, id }) => {
        return setGlcfgObjectValue('upgrade', value, id);
    }, []);

    useValueChangedEffect(isError, (isNowError) => {
        if (isNowError) {
            openInfoTab('error');
        } else {
            openInfoTab('tutorial');
        }
    });

    return (
        <MDBCard className="w-100">
            <MDBCardHeader>
                <TabButton
                    message="upgrade:tutorial.title"
                    tabName="tutorial"
                />
                <TabButton
                    message="upgrade:changes"
                    tabName="changes"
                />
                <TabButton
                    message="upgrade:upgradeNotice.title"
                    tabName="upgradeNotice"
                />
                {isError && (
                    <TabButton
                        message="upgrade:error"
                        tabName="error"
                    />
                )}
            </MDBCardHeader>
            <MDBCardBody className="upgrade__cardBody">
                {openedTab === 'tutorial' ?
                    <>
                        <h2>{t('upgrade:tutorial.header')}</h2>
                        <p>
                            {t('upgrade:tutorial.first')}
                        </p>
                        <h2>{t('upgrade:tutorial.secondHeader')}</h2>
                        <p>
                            {t('upgrade:tutorial.second')}
                        </p>
                        <h2 />
                        <p>
                            {t('upgrade:tutorial.third')}
                        </p>
                    </> :
                    null}
                {openedTab === 'changes' && <ChangesTab />}
                {openedTab === 'error' && <ErrorTab />}
                {openedTab === 'upgradeNotice' ?
                    <>
                        <Input
                            id="upgradeNotice"
                            label={t('upgrade:upgradeNotice.title')}
                            name="upgradeNoticeName"
                            onChange={setUpgrade}
                            rows={5}
                            type="textarea"
                            value={upgradeNotice}
                        />
                    </> :
                    null}
            </MDBCardBody>
        </MDBCard>
    );
};

const TabButton = ({ tabName, message }: {tabName: InfoTabs, message: string}) => {
    const openedTab = useSelector(getCurrentInfoTab);
    const openInfoTab = useDispatchCallback(setInfoTab, []);
    return (
        <MDBBtn
            className={openedTab === tabName ?
                'upgrade__changesButton mr-1' :
                'mr-1'}
            color={'none' as any}
            onClick={event => {
                event.preventDefault();
                openInfoTab(tabName);
            }}
            size="sm"
            {...testProps(tabName + '--button')}
        >
            <Message message={message} />
        </MDBBtn>
    );
};

const ErrorTab = () => {
    const { t } = useTranslation();
    const { error, data: upgradeStateErrMsg } = useUpgradeStateQuery({ select: it => it.message });
    const manualUpgrade = usePrepareUpgradeCallback();
    return (
        <>
            <p className="infobox infobox--alert">
                {error?.message ? (<>{error.message}<br /> </>) : null}
                {upgradeStateErrMsg || <Message message="upgrade:upgrade.unknownError" />}
            </p>
            <MDBBtn
                color="primary"
                onClick={event => {
                    event.preventDefault();
                    manualUpgrade();
                }}
            >{t('upgrade:tryAgain')}
            </MDBBtn>
            <small><p>{t('upgrade:contactKernun')}</p></small>
        </>
    );
};
const ChangesTab = () => {
    const { t } = useTranslation();
    const language = useSelector(getSelectedLanguage);
    const { data: changesUpdated } = useUpgradeStateQuery({ select: it => it.changesUpdated });

    const { data, isLoading, refetch } = useChangelogQuery();

    useValueChangedEffect(changesUpdated, (newVal, prevVal) => {
        if (newVal === true && prevVal === false) {
            void refetch();
        }
    });

    if (isLoading) {
        return <Loader />;
    }
    return (
        <Changes
            changes={data}
            lang={language}
            t={t}
        />
    );
};
