/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { typeApiError, typeTimestamp } from '~frontendTypes/index.js';
import { databaseInfoRequest, getCwdbDatabaseInfo,
    getDatabaseLoading, getDatabaseError, getCwdbState
} from '~frontendDucks/protectionDatabases/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import { StateWidget } from '~frontendRoot/widgets/index.js';
import moment from '~commonLib/moment.ts';

import IsInCategories from '../IsInCategories/index.ts';


const StateMessage = ({ lastUpdate, dbVersion, cwdbState }) => (
    <>
        <span className="headerInfobox">
            <Message message={`widgets:cwdb.update.${lastUpdate ? 'lastUpdate' : 'dbVersion'}.${cwdbState}.title`} />
        </span>
        {lastUpdate ?
            <Message
                message={`widgets:cwdb.update.lastUpdate.${cwdbState}.desc`}
                params={{ time: moment(lastUpdate).fromNow() }}
            /> :
            <Message
                message={`widgets:cwdb.update.dbVersion.${cwdbState}.desc`}
                params={{ time: moment(dbVersion).fromNow() }}
            />
        }
    </>
);

StateMessage.propTypes = {
    lastUpdate: typeTimestamp,
    dbVersion: typeTimestamp,
    cwdbState: PropTypes.string,
};

@withTranslation()
@connect(
    state => ({
        cwdbDatabaseInfo: getCwdbDatabaseInfo(state),
        isLoading: getDatabaseLoading(state),
        error: getDatabaseError(state),
        cwdbState: getCwdbState(state)

    }),
    {
        getDatabaseState: databaseInfoRequest,
    }
)
class CwdbState extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            cwdbDatabaseInfo: PropTypes.object,
            getDatabaseState: PropTypes.func,
            error: typeApiError,
            isLoading: PropTypes.bool,
            cwdbState: PropTypes.string,
        };
    }

    componentDidMount() {
        const { getDatabaseState } = this.props;
        getDatabaseState();
    }

    reload = () => {
        const { getDatabaseState } = this.props;
        getDatabaseState();
    };

    render() {
        const { t, cwdbDatabaseInfo, error, isLoading, cwdbState } = this.props;
        const { lastUpdate, dbVersion } = cwdbDatabaseInfo;
        return (
            <MDBCard>
                <MDBCardTitle>
                    <MDBRow>
                        <MDBCol lg="10">
                            {t('widgets:cwdb.database')}
                            {(cwdbState !== 'info' || isLoading) &&
                            <IconWithTooltip
                                className="ml-2 mb-1 database__stateIcon"
                                datacy={'cwdbStateIcon'}
                                iconSize="sm"
                                link
                                name={cwdbState === 'error' ? 'alert-circle-outline' :
                                    cwdbState === 'warning' ? 'progress-alert' : 'check-circle-outline'}
                                tooltipText={t(`widgets:cwdb.update.lastUpdate.${cwdbState}.title`)}
                                withoutTranslation
                            />}
                        </MDBCol>
                        <MDBCol
                            className="database__reloadIcon"
                            lg="2"
                        >
                            <IconWithTooltip
                                datacy={isLoading ? 'loadingIcon' : 'updateIcon'}
                                iconSize="sm"
                                link
                                loading={isLoading}
                                name="reload"
                                onClick={this.reload}
                                tooltipText={t('widgets:global.reloadState')}
                                withoutTranslation
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardTitle>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol>
                            <StateWidget
                                apiError={error}
                                datacy={'cwdbStateInfobox'}
                                error={
                                    cwdbState === 'error' &&
                                        <StateMessage
                                            cwdbState={cwdbState}
                                            dbVersion={dbVersion}
                                            lastUpdate={lastUpdate}
                                        />
                                }
                                info={
                                    cwdbState === 'info' &&
                                        <StateMessage
                                            cwdbState={cwdbState}
                                        />
                                }
                                isLoading={isLoading}
                                success={
                                    cwdbState === 'success' &&
                                        <StateMessage
                                            cwdbState={cwdbState}
                                            dbVersion={dbVersion}
                                            lastUpdate={lastUpdate}
                                        />
                                }
                                warning={
                                    cwdbState === 'warning' &&
                                        <StateMessage
                                            cwdbState={cwdbState}
                                            dbVersion={dbVersion}
                                            lastUpdate={lastUpdate}
                                        />
                                }
                            />
                        </MDBCol>
                    </MDBRow>
                    <IsInCategories />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default CwdbState;
