/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import React, { useRef, useState } from 'react';
import { Benchmark } from 'react-component-benchmark';

import { MINUTE } from '~commonLib/constants.ts';
import { Select } from '~frontendComponents/Generic/index.js';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';


export const Benchmarks = () => {

    return (
        <section style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', padding: '1rem', }}>
            <BenchContainer
                Component={Select}
                componentProps={{
                    id: 'benchmarked-select',
                    isMulti: true,
                    options: [ { value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' } ],
                    value: [ 1, 2 ],
                }}
                title="Select"
            />
            <BenchContainer
                Component={SelectV2}
                componentProps={{ options: [ 1, 2, 3 ], value: [ 1, 2 ],
                    prepareOption: it => ({ label: it.toString(), value: it }),
                    stringifyForCopy: () => '',
                }}
                title="SelectV2"
            />
        </section>
    );

};


const BenchContainer = ({ title, Component, componentProps }: {
	title: string, Component: any, componentProps: object,
}) => {
    const ref: any = useRef();

    const [ result, setResult ] = useState({} as any);
    const [ samples, setSamples ] = useState(50);
    const handleStart = () => {
        ref.current.start();
    };
    return (
        <MDBCard>
            <MDBCardTitle>
                {title}
            </MDBCardTitle>
            <MDBCardBody>
                <input
                    onChange={ev => setSamples(parseInt(ev.target.value))}
                    value={samples}
                />
                <button onClick={handleStart}>Run</button>
                <div>
                    <div>Sample count: {result.sampleCount}</div>
                    <div>Max: {formatResultTime(result.max)}</div>
                    <div>Min: {formatResultTime(result.min)}</div>
                    <div>Median: {formatResultTime(result.median)}</div>
                    <div>Mean: {formatResultTime(result.mean)}</div>
                    <div>Standard deviation: {formatResultTime(result.stdDev)}</div>
                    <div>P99: {formatResultTime(result.p99)}</div>
                    <div>P95: {formatResultTime(result.p95)}</div>
                    <div>P70: {formatResultTime(result.p70)}</div>
                </div>
                <div style={{ height: '200px', overflow: 'hidden' }}>
                    <Component {...componentProps} />
                    <Benchmark
                        component={Component}
                        componentProps={componentProps}
                        onComplete={setResult}
                        ref={ref}
                        samples={samples}
                        timeout={2 * MINUTE}
                        type="mount"
                    />
                </div>
            </MDBCardBody>
        </MDBCard>
    );
};

const formatResultTime = (ms: undefined|number) => {
    if (!ms) {
        return undefined;
    }
    return ms.toFixed(1) + ' ms';
};
