/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import Message from '~frontendComponents/Message/index.js';
import { GIT_INITIAL_COMMIT_MESSAGE, GIT_UPGRADE_COMMIT_REGEX, SYSTEM_USER_NAME, INSTALLER_COMMIT_MESSAGE } from '~sharedConstants/index.ts';


const ColumnValueMessage = ({ value, row }) => {
    if (row.author === SYSTEM_USER_NAME) {
        if (value === GIT_INITIAL_COMMIT_MESSAGE) {
            return (
                <Message message="widgets:ConfigurationRevisionList.columns.commitMessage.initialCommitMessage.title" />
            );
        }
        if (value === INSTALLER_COMMIT_MESSAGE) {
            return (
                <Message message="widgets:ConfigurationRevisionList.columns.commitMessage.installerCommitMessage.title" />
            );
        }
        if (GIT_UPGRADE_COMMIT_REGEX.test(value)) {
            const matches = value.match(GIT_UPGRADE_COMMIT_REGEX);
            return (
                <Message
                    message="widgets:ConfigurationRevisionList.columns.commitMessage.upgradeCommitMessage"
                    params={{ from: matches[1], to: matches[2] }}
                />
            );
        }
    }
    if (row.author === 'root') {
        if (value === 'Manual edit by usage of editHlcfg.sh') {
            return (
                <Message message="widgets:ConfigurationRevisionList.columns.commitMessage.manualHlcfgEdit" />
            );
        }
    }
    return value;
};

ColumnValueMessage.propTypes = {
    value: PropTypes.string.isRequired,
};

export default ColumnValueMessage;
