/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getGlcfgValue, setGlcfgValueScalar } from '~frontendDucks/hlcfgEditor/index.js';
import Service from '~frontendComponents/Service/index.ts';

import { SnmpService } from './components/index.js';


@connect(
    (state) => ({
        snmpOff: getGlcfgValue(state, 'snmpOff'),
    }),
    {
        setValue: setGlcfgValueScalar,
    }
)
class SnmpScene extends Component {
    static get propTypes() {
        return {
            snmpOff: PropTypes.bool,
            setValue: PropTypes.func,
        };
    }

    enableService = () => {
        const { setValue } = this.props;
        setValue('snmpOff', undefined);
    };

    disableService = () => {
        const { setValue } = this.props;
        setValue('snmpOff', true);
    };

    render() {
        const { snmpOff } = this.props;
        return (
            <Service
                id="Snmp"
                on={!snmpOff}
                showService={(<SnmpService />)}
                turnOff={this.disableService}
                turnOn={this.enableService}
            />
        );
    }
}

export default SnmpScene;
