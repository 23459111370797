/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { adaptiveFirewallUpdateOpened } from '~frontendDucks/adaptiveFirewallUpdatePerform/index.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/index.js';
import { getHealthIssues } from '~frontendDucks/clusterSetup/index.js';
import { CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE, NODE_A_ID, NODE_B_ID } from '~frontendRoot/constants/index.js';
import { setModalState } from '~frontendRoot/ducks/modals/index.js';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import { ACTION_SEQUENCE_SURICATA_RULES_UPDATE } from '~sharedConstants/index.ts';
import { NODE_SELF } from '~commonLib/constants.ts';


const AdaptiveFirewallUpdatePerformIconBase = ({ doRulesUpdateOpened, isCluster, healthIssues,
    openSyncOrForThisNodeModal, t }) => (
    <IconWithTooltip
        iconSize="sm"
        link
        name="reload"
        onClick={() => {
            if (isCluster) {
                if (healthIssues.length) {
                    openSyncOrForThisNodeModal({
                        modal: CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE, value: true,
                        specialValues: { sequence: ACTION_SEQUENCE_SURICATA_RULES_UPDATE,
                            action: doRulesUpdateOpened }
                    });
                } else {
                    doRulesUpdateOpened({ nodes: [ NODE_A_ID, NODE_B_ID ] });
                }
            } else {
                doRulesUpdateOpened({ nodes: [ NODE_SELF ] });
            }
        }}
        tooltipText={t('widgets:Suricata.rules.updatePerformButton.desc')}
        withoutTranslation
    />
);

AdaptiveFirewallUpdatePerformIconBase.propTypes = {
    doRulesUpdateOpened: PropTypes.func.isRequired,
    isCluster: PropTypes.bool,
    healthIssues: PropTypes.array,
    openSyncOrForThisNodeModal: PropTypes.func,
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    isCluster: getIsCluster(state),
    healthIssues: getHealthIssues(state)
});

const mapDispatchToProps = {
    doRulesUpdateOpened: adaptiveFirewallUpdateOpened,
    openSyncOrForThisNodeModal: setModalState,
};

const AdaptiveFirewallUpdatePerformIcon = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(
    AdaptiveFirewallUpdatePerformIconBase
));

export default AdaptiveFirewallUpdatePerformIcon;
