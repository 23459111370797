/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { MDBCardTitle, MDBCard, } from 'mdbreact';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon, SelectColumns, InputSearch } from '~frontendComponents/Generic/index.js';
import {
    getProfileUuidRules,
    setNormalizeOrderProfileRules, isProfileAuthenticationEnabled } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import {
    NEW_ROW_CONSTANT,
    PROFILES,
    PROFILE_RULE_COLUMNS_GROUP,
    PROFILE_RULE_COLUMNS_USER,
    SELECTABLE_TABLE_PROFILES,
    TableSizeType,
    userSetting
} from '~frontendConstants/index.js';
import { getActiveCard } from '~frontendDucks/activeCards/index.js';
import { DatatableWidget } from '~frontendRoot/widgets/index.js';
import { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { useBoolean, useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { useUserSettingPathSetter } from '~frontendRoot/lib/hooks/userSettings.ts';

import Row from '../Row/index.ts';


const CreateRow = ({ dataIndex,
    spacing,
    uuid }: CreateRowPropsType) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid}
            spacing={spacing}
            uuid={uuid}
        />
    );
};


const ProfileRules = ({ spacing }: {spacing: TableSizeType}) => {
    const [ search, setSearch ] = useString('');
    const [ hide, setHide ] = useBoolean(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileAuthentication = useSelector(isProfileAuthenticationEnabled);
    const active = useSelector(state => getActiveCard(state, PROFILES));
    const profileRules = useSelector(getProfileUuidRules);
    const [ , setColumn ] = useUserSettingPathSetter(userSetting.columnsByTable);

    const doSetColumns = useCallback(({ value }) => {
        setColumn({ path: [ SELECTABLE_TABLE_PROFILES, value ], value: true });
    }, [ setColumn ]);

    useEffect(() => {
        if (profileAuthentication) {
            doSetColumns({ value: PROFILE_RULE_COLUMNS_GROUP });
            doSetColumns({ value: PROFILE_RULE_COLUMNS_USER });
        }
        //doSetColumns keeps rerendering and I dont know why
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ profileAuthentication ]);


    const passReorderData = useCallback((order) => {
        if (!order.includes(NEW_ROW_CONSTANT)) {
            dispatch(setNormalizeOrderProfileRules({ order, active }));
        }
    }, [ dispatch, active ]);

    const data = useMemo(() => profileRules?.length === 3 ?
        [ NEW_ROW_CONSTANT, ...profileRules ] : profileRules,
    [ profileRules ]);

    return (
        <MDBCard className={classNames('profile__card')}>
            <MDBCardTitle className={classNames(
                'profiles__title',
                'pt-0', 'pb-0',
                { 'profiles__title--noBorderBottom': !hide },
            )}
            >
                <div> {t('profile:profiles.rules.title')}
                    <Icon
                        name={hide ? 'chevron-down' : 'chevron-up'}
                        onClick={setHide.swap}
                    />
                </div>
                <div className="profiles__titleActions">

                    {!hide &&
                     <>
                         <SelectColumns id={SELECTABLE_TABLE_PROFILES} />
                         <InputSearch
                             className="mb-0 mt-0"
                             id="searchValueIDVlans"
                             search={search}
                             setter={setSearch}
                         />
                     </>
                    }
                </div>
            </MDBCardTitle>
            <div className={classNames(
                'profile__body',
                { 'profile__body--hide': hide },
                { 'profiles__title--borderBottom': !hide }
            )}
            >
                <DatatableWidget
                    columnsId={SELECTABLE_TABLE_PROFILES}
                    createRow={CreateRow}
                    data={data}
                    passReorderData={passReorderData}
                    search={search}
                    spacing={spacing}
                />

            </div>
        </MDBCard>

    );
};

export default ProfileRules;
