/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Cards, Icon, Switch } from '~frontendComponents/Generic/index.js';
import {
    getProfilesIds, getProfileProps,
    duplicateFromNormalize,
    addToNormalize,
    setNormalize,
    setNormalizeOrder } from '~frontendDucks/hlcfgEditor/index.js';
import { getActiveCard } from '~frontendDucks/activeCards/index.js';
import { DELETE_CONFIRM_PROFILE, PROFILES } from '~frontendConstants/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import TurnedOff from '~frontendRoot/components/TurnedOff/index.js';
import { DEFAULT_RULE_NAME, TLS_INSPECTION_UUID } from '~sharedConstants/index.ts';
import { useUserSettingToggleable } from '~frontendRoot/lib/hooks/userSettings.ts';
import { userSetting, SMALL_SIZE, MEDIUM_SIZE } from '~frontendRoot/constants/index.js';

import ProfileAddresses from './components/ProfileAddresses.tsx';
import ProfileRules from './components/ProfileRules/index.ts';
import ProfileSettings from './components/ProfileSettings/index.js';
import ProfileUsage from './components/ProfileUsage/index.js';


const Profiles = () => {
    const dispatch = useDispatch();
    const [ hyperCompactModeEnabled, toggleCompactMode ] = useUserSettingToggleable(userSetting.hyperCompactProxyTable);
    //Row tables, tables and other components are using this prop as class editor,
    //eg. `dataTableWidget__cell--${spacing}`
    const spacing = hyperCompactModeEnabled ? SMALL_SIZE : MEDIUM_SIZE;

    const { t } = useTranslation();
    const ids = useSelector(getProfilesIds);
    const profileProps = useSelector(getProfileProps);
    const active = useSelector((state) => getActiveCard(state, PROFILES));
    const addProfile = () => {
        const newUuid = `profile:${uuidv4()}`;
        dispatch(addToNormalize({
            type: 'profile',
            typeId: PROFILES,
            newUuid,
        }));
        dispatch(addToNormalize({
            type: 'profileRule',
            typeId: 'profile',
            key: 'rules',
            uuid: newUuid,
            uuidToAddBefore: TLS_INSPECTION_UUID,
            addingAfter: true,
            extraValues: {
                fake: true,
                type: 'rule',
                name: DEFAULT_RULE_NAME,
            }
        }));
    };

    const turnOffProfile = ({ value, uuid }) => {
        dispatch(setNormalize({ uuid: uuid || active, value, key: '__off' }));
    };

    const setProfileParameters = ({ value, key, uuid }) => {
        if (key === '__off') {
            turnOffProfile({ value, uuid });
        } else {
            dispatch(setNormalize({ uuid: active, value, key,  }));
        }
    };

    const copyService = ({ uuid }) => {
        dispatch(duplicateFromNormalize({
            uuid,
            parent: PROFILES
        }));
    };

    const deleteProfile = ({ uuid }) => {
        dispatch(setModalState({ value: true, modal: DELETE_CONFIRM_PROFILE, specialValues: { profile: uuid } }));
    };


    const setOrder = ({ order }) => {
        dispatch(setNormalizeOrder({ type: PROFILES, value: order }));
    };

    return (
        <>
            <div className="userNavigation">
                <Switch
                    align="spaceBetween"
                    bothActive
                    checked={spacing === SMALL_SIZE}
                    className="m-0 h-2 mr-1"
                    id="spacing"
                    inputStyle
                    isMessage
                    labelClassName="h-2 mt-0 mb-0"
                    messageOff={t('packetFilter:spacing.small')}
                    messageOn={t('packetFilter:spacing.normal')}
                    mini
                    onChange={toggleCompactMode}
                    switchClassName="h-2 hmi-2"
                    tooltipText={t('packetFilter:spacing.title')}
                />
                <MDBBtn
                    className="navigation__button"
                    id="addProfile"
                    onClick={addProfile}
                    size="sm"
                    type="button"
                >
                    <Icon name="plus" />
                    {t('profile:add')}
                </MDBBtn>
            </div>
            <div>
                <Cards
                    copyService={copyService}
                    deleteService={deleteProfile}
                    ids={ids}
                    menuItemProps={profileProps}
                    setOrder={setOrder}
                    setValue={setProfileParameters}
                    type={PROFILES}
                    wrapClassName="vpn__scene"
                />

                <div className="overlay--div mb-0">
                    <MDBRow>
                        <MDBCol size="12">
                            <ProfileSettings profile={active} />
                        </MDBCol>
                        <MDBCol size="12">
                            <ProfileAddresses
                                active={active}
                                spacing={spacing}
                            />
                        </MDBCol>
                        <MDBCol size="12">
                            <ProfileRules spacing={spacing} />
                        </MDBCol>
                        <MDBCol size="12">
                            <ProfileUsage />
                        </MDBCol>
                    </MDBRow>
                    <TurnedOff
                        disabled={profileProps[active]?.__off}
                        onChange={turnOffProfile}
                    />
                </div>
            </div>
        </>
    );

};

export default Profiles;
