/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React from 'react';

import ApiError from '~frontendComponents/Generic/ApiError/index.js';

import Message from '../Message/index.js';


// HighchartsReact throws an exception that we want to catch.
class ChartErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error, info) {
        this.setState({
            error,
            hasError: true,
            info,
        });
    }

    render() {
        const { error, hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            return (
                <ApiError
                    error={error}
                    title={(<Message message="reporter:chart.exception.title" />)}
                />
            );
        }
        return children;
    }
}

ChartErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ChartErrorBoundary;
