/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IconWithPopover from '~frontendComponents/IconWithPopover/index.js';
import { setGlcfgValueScalar, getGlcfgValue, getGlcfgSchema, } from '~frontendDucks/hlcfgEditor/index.js';
import { Input } from '~frontendComponents/Generic/index.js';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice/index.js';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';


@withTranslation()
@connect(
    (state, { list }) => ({
        listValue: getGlcfgValue(state, list),
        listSchema: getGlcfgSchema(state, list),
        listValueComment: getGlcfgValue(state, `${list}Comment`),
        suricataIpsMode: getGlcfgValue(state, 'suricataIpsMode'),


    }),
    {
        doSetGlcfgValueScalar: setGlcfgValueScalar
    }
)
class BlackList extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            doSetGlcfgValueScalar: PropTypes.func,
            listValue: PropTypes.array,
            listValueComment: PropTypes.string,
            list: PropTypes.string,
            listSchema: PropTypes.object,
            suricataIpsMode: PropTypes.bool,

        };
    }

    setValue = ({ id, value }) => {
        const { doSetGlcfgValueScalar } = this.props;
        doSetGlcfgValueScalar(id, value);
    };

    render() {
        const { t, listValue, listValueComment, list, listSchema, suricataIpsMode } = this.props;
        return (
            <>
                <MDBCard>
                    <MDBCardTitle className="ips__cardTitle">
                        {t(`ips:${!suricataIpsMode && list === 'suricataBlacklist' ? 'alertList' : list}.title`)}
                        <IconWithPopover
                            body={
                                t(`ips:${!suricataIpsMode && list === 'suricataBlacklist' ? 'alertList' : list}.desc`)
                            }
                            divClassName="ml-1"
                            header={
                                t(`ips:${!suricataIpsMode && list === 'suricataBlacklist' ? 'alertList' : list}.title`)
                            }
                            iconSize="sx"
                            name="information-outline"
                        />
                    </MDBCardTitle>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <NetworkInterfaceDevice
                                    id={list}
                                    iface={stringifyAddress(listValue)}
                                    isCreatable
                                    isMulti
                                    label={
                                        t(`ips:${!suricataIpsMode &&
                                            list === 'suricataBlacklist' ? 'alertList' : list}.title`)
                                    }
                                    name={list + 'Name'}
                                    noDropdownIndicator
                                    onChange={this.setValue}
                                    paste
                                    schema={listSchema}
                                />
                            </MDBCol>
                            <MDBCol
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <Input
                                    id={list + 'Comment'}
                                    label={t('ips:comment')}
                                    name="comment"
                                    onChange={this.setValue}
                                    rows={3}
                                    type="textarea"
                                    value={listValueComment}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
            </>
        );
    }
}

export default BlackList;
