/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDBRow, MDBCol } from 'mdbreact';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Scene } from '~frontendComponents/Scene/index.js';
import { Cards } from '~frontendComponents/Generic/index.js';
import { getComponentEnabled, getDashboardsIds, setGlcfgObjectValue } from '~frontendDucks/hlcfgEditor/index.js';
import { setDashboardsOrder } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { getActiveCard } from '~frontendRoot/ducks/activeCards/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { REPORTER } from '~frontendConstants/index.js';
import { createDashboardCopyWithFilter, createDashboardIdWithFilterValue } from '~frontendRoot/ducks/reporterEntities/index.js';

import DashboardObject from '../../lib/Dashboard/components/DashboardObject.js';
import TimeFilter from './TimeFilter.tsx';


const NOT_READY_DASHBOARDS = [
    'qosCharts',
    'vpnCharts',
    'dhcpdCharts',
    'honeypotCharts',
];

@withTranslation()
@connect(
    (state, { type }) => ({
        ids: getDashboardsIds(state, type),
        active: getActiveCard(state, type),
        enabled: getComponentEnabled(state, REPORTER)

    }),
    {
        setOrder: setDashboardsOrder,
        setValue: setGlcfgObjectValue,
        createDashboards: createDashboardCopyWithFilter

    }
)
class DashboardScene extends Component {
    static get propTypes() {
        return {
            ids: PropTypes.array,
            dashboardsDef: PropTypes.object,
            type: PropTypes.string,
            setOrder: PropTypes.func,
            active: PropTypes.string,
            setValue: PropTypes.func,
            enabled: PropTypes.bool,
            createDashboards: PropTypes.func,
            filterValues: PropTypes.array,
            t: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        const { createDashboards, dashboardsDef, filterValues } = props;
        Object.values(dashboardsDef).forEach(dash =>
            dash?.dashboardIds?.forEach(item => {
                if (item.columnName) {
                    filterValues?.forEach(filter =>
                        createDashboards(item.id, filter.filter, item.columnName));
                }}));
    }

    setOrder = ({ order }) => {
        const { setOrder, type } = this.props;
        setOrder({ order, type });
    };

    enableService = () => {
        const { setValue } = this.props;
        setValue(REPORTER, undefined, '__off');
    };

    disableService = () => {
        const { setValue } = this.props;
        setValue(REPORTER, true, '__off');
    };

    render() {
        const { dashboardsDef, ids, type, active, enabled, filterValues, t } = this.props;
        return (
            <Scene>
                <Service
                    fullyHide={enabled}
                    id="Reporter"
                    on={enabled}
                    showService={(
                        <>
                            <TimeFilter
                                active={dashboardsDef[active]}
                                filterValues={filterValues}
                            />

                            <Cards
                                ids={ids.filter(item => !NOT_READY_DASHBOARDS.includes(item))}
                                setOrder={this.setOrder}
                                simple
                                type={type}
                                wrapClassName="graph__scene"
                            />

                            <div
                                className="overlay--div"

                            >
                                {dashboardsDef[active] &&
                            <MDBRow>
                                {dashboardsDef[active].dashboardIds.map(dashboard => {
                                    if (filterValues && dashboard.columnName) {

                                        const filterCharts = filterValues.map(filterValue =>
                                            (
                                                <MDBCol
                                                    key={createDashboardIdWithFilterValue(dashboard.id,
                                                        filterValue.filter)}
                                                    size={
                                                        filterValue?.length ?
                                                            Math.floor(12 / filterValue.length).toString() :
                                                            ''
                                                    }

                                                >
                                                    <DashboardObject
                                                        color={filterValue.color}
                                                        dashboardId={
                                                            createDashboardIdWithFilterValue(dashboard.id,
                                                                filterValue.filter)
                                                        }
                                                        dashboardIds={
                                                            dashboardsDef[active].dashboardIds?.map(item =>
                                                                item.id)}
                                                        dashboardTitle={filterValue.name || t(dashboard.title,
                                                            { filterValue: filterValue.filter })}
                                                        filtered
                                                        key={createDashboardIdWithFilterValue(dashboard.id,
                                                            filterValue.filter)}
                                                        newCharts
                                                    />
                                                </MDBCol>
                                            ));
                                        return (
                                            <MDBCol
                                                key={dashboard.id}
                                                size="12"
                                            >
                                                <MDBRow>
                                                    {filterCharts}
                                                </MDBRow>
                                            </MDBCol>
                                        );
                                    }
                                    return (
                                        <MDBCol
                                            key={dashboard.id}
                                            lg="12"
                                        >
                                            <DashboardObject
                                                dashboardId={dashboard.id}
                                                dashboardIds={dashboardsDef[active].dashboardIds?.map(item => item.id)}
                                                key={dashboard.id}
                                                newCharts
                                            />
                                        </MDBCol>
                                    );})}

                            </MDBRow>
                                }

                            </div>
                        </>
                    )}
                    turnOff={this.disableService}
                    turnOn={this.enableService}
                />
            </Scene>
        );
    }
}

export default DashboardScene;
