/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import { LeafDiffInfo } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/types.ts';
import {
    findLeafPathAndSchema,
    isOffPath,
} from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/helpers.ts';
import { getObjectCrawler } from '~commonLib/objectUtils.ts';
import { HLCFG_OFF } from '~sharedRoot/constants/index.ts';
import { schemaIsLeafSchema } from '~sharedLib/hlcfg/isHlcfgLeafSchemaPath.ts';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';


export const hlcfgDiffToDiffLeafInfo = (diff: HlcfgDiff, hlcfgSchema): LeafDiffInfo[] => {
    const info = findLeafPathAndSchema(diff.hlcfgDescriptivePath, hlcfgSchema);
    if (info) {
        return [ {
            ...info,
            diff,
        } ];
    }

    const leavesInfo: LeafDiffInfo[] = [];
    const crawl = getObjectCrawler({
        matchValue: (value, path) => {
            const thisPath = [ ...diff.hlcfgRealPath, ...path ];
            const leafPath = [ ...diff.hlcfgDescriptivePath, ...path ];
            const sch = findSchemaByObjectPathAndSchema(thisPath, hlcfgSchema);
            if (sch && schemaIsLeafSchema(sch)) {
                leavesInfo.push({ leafPath, leafSchema: sch, diff, type: 'drilled' });
                return true;
            }
            if (!sch && isOffPath(thisPath)) {
                leavesInfo.push({ leafPath, leafSchema: undefined, diff, type: 'regular' });
                return true;
            }
            return false;
        },
        onValueMatched: (value, { abortBranchCrawl }) => abortBranchCrawl()
    });
    crawl(diff.toValue);
    addOffDiffIfNeccessary(diff, hlcfgSchema, leavesInfo);
    return leavesInfo;

};

const addOffDiffIfNeccessary = (diff: HlcfgDiff, hlcfgSchema, leavesInfo: LeafDiffInfo[]) => {
    const sch = findSchemaByObjectPathAndSchema(diff.hlcfgRealPath, hlcfgSchema);

    const isOffableNonRowSchema = sch && sch.type === 'object' && sch.properties?.[HLCFG_OFF];

    // there may be off diffs already present, we do not want to add additional diff.
    const noOffDiffsArePresent = leavesInfo.every(info => !isOffPath(info.leafPath));

    if (isOffableNonRowSchema && noOffDiffsArePresent) {
        if (diff.fromValue === undefined) {
            leavesInfo.push({
                leafPath: [ ...diff.hlcfgDescriptivePath, HLCFG_OFF ], leafSchema: undefined, type: 'regular',
                diff: {
                    fromValue: true,
                    hlcfgDescriptivePath: [ ...diff.hlcfgDescriptivePath, HLCFG_OFF ],
                    hlcfgRealPath: [ ...diff.hlcfgRealPath, HLCFG_OFF ],
                    alsoRemovesRefsFromPaths: []
                }
            });
        } else if (diff.toValue === undefined) {
            leavesInfo.push({
                leafPath: [ ...diff.hlcfgDescriptivePath, HLCFG_OFF ], leafSchema: undefined, type: 'regular',
                diff: {
                    toValue: true,
                    hlcfgDescriptivePath: [ ...diff.hlcfgDescriptivePath, HLCFG_OFF ],
                    hlcfgRealPath: [ ...diff.hlcfgRealPath, HLCFG_OFF ],
                    alsoRemovesRefsFromPaths: []
                }
            });
        }
    }
};
