/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createLazy } from '~frontendLib/createLazy.tsx';
import { Benchmarks } from '~frontendRoot/scenes/Develop/scenes/Benchmarks/Benchmarks.js';

import { createScene } from '../../../lib/scenes.js';


const DevelopInstall = createLazy(() => import('./DevelopInstall/index.js'), { skipPreload: true });
const MasterComponents = createLazy(() => import('./MasterComponents/index.js'), { skipPreload: true });
const Typography = createLazy(() => import('./TypographyScene/index.js'), { skipPreload: true });


const DEVELOP_INSTALL_SCENE = createScene({
    path: '/develop/develop-install',
    component: DevelopInstall,
    title: 'scenes:scenes.Develop.scenes.DevelopInstall.title',
});

const DEVELOP_MASTERCOMPONENTS_SCENE = createScene({
    path: '/develop/mastercomponents',
    component: MasterComponents,
    title: 'scenes:scenes.Develop.scenes.MasterComponents.title',
});

const DEVELOP_BENCHMARKS_SCENE = createScene({
    path: '/develop/benchmarks',
    component: Benchmarks,
    title: 'scenes:scenes.Develop.scenes.Benchmarks.title',
});

const DEVELOP_TYPOGRAPGY_SCENE = createScene({
    path: '/develop/typography',
    component: Typography,
    title: 'scenes:scenes.Develop.scenes.Typography.title',
});

const scenes = () => [
    DEVELOP_INSTALL_SCENE,
    DEVELOP_MASTERCOMPONENTS_SCENE,
    DEVELOP_BENCHMARKS_SCENE,
    DEVELOP_TYPOGRAPGY_SCENE,
];

export default scenes;
