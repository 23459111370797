/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {  MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol } from 'mdbreact';

import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import {  getGlcfgValue,
} from '~frontendDucks/hlcfgEditor/index.js';
import { Input } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';


@withTranslation()
@connect(
    (state) => ({
        ldap: getGlcfgValue(state, 'proxy').ldap,

    }),
    {
    }
)
class AuthenticationCache extends Component {
    static get propTypes() {
        return {
            ldap: PropTypes.object,
            t: PropTypes.func,
        };
    }


    render() {
        const { ldap, t, } = this.props;
        return (
            <WhenAdvanced>
                <MDBCard>
                    <MDBCardHeader>
                        <MDBRow>
                            <MDBCol lg="4">
                                <Message message="widgets:Authentication.cache.title" />
                            </MDBCol>
                            <MDBCol
                                className="authentication__loginIcons"
                                lg="8"
                            >
                                <IconWithTooltip
                                    className="icon--black"
                                    iconSize="sm"
                                    link
                                    name="trash-can-outline"
                                    tooltipText="widgets:Authentication.actions.clearCache"
                                />
                                {/*
                                <Switch
                                    align="right"
                                    checked={false} //TODO: backend to turn on cache
                                    className="pt-2"
                                    id="disabled"
                                    name="authentication"
                                    onChange={this.onChangeValue}
                                    withoutBorder
                                    withoutLabelMargins
                                    withoutMinWidhtHeight
                                    wrap={false}
                                />*/
                                }
                            </MDBCol>
                        </MDBRow>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <Input
                            className="w-50 mb-3"
                            disabled={true} //TODO: backend to turn on cache
                            id="authenticationDomain"
                            label={t('widgets:Authentication.cache.size')}
                            name="domain"
                            onChange={this.onChangeValue}
                            value={ldap?.cacheCapacity}
                        />
                        <Input
                            className="w-75 mb-3"
                            disabled={true} //TODO: backend to turn on cache
                            id="authenticationDomain"
                            label={t('widgets:Authentication.cache.timeLimitInactive')}
                            name="domain"
                            onChange={this.onChangeValue}
                            value={ldap?.cacheIdleTimeout}
                        />
                        <Input
                            className="w-75 mb-2"
                            disabled={true} //TODO: backend to turn on cache
                            id="authenticationDomain"
                            label={t('widgets:Authentication.cache.maxTimeIn')}
                            name="domain"
                            onChange={this.onChangeValue}
                            value={ldap?.cacheTotalTimeout}
                        />
                    </MDBCardBody>
                </MDBCard>
            </WhenAdvanced>
        );
    }
}

export default  AuthenticationCache;
