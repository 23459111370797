/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import { withTranslation } from 'react-i18next';

import { RadioList, Select } from '~frontendComponents/Generic/index.js';
import {
    getDnsServerAddresses,
    getDnsServerNameservers,
    getDnsServerNameserversSchema,
    getDnsServerType,
    setGlcfgObjectValue,
    getDnsServerAddressesSchema,
} from '~frontendDucks/hlcfgEditor/index.js';
import Message from '~frontendComponents/Message/index.js';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice/index.js';
import { DNS_TYPE_DHCP, DNS_TYPE_NAMESERVERS, DNS_TYPE_RECURSIVE } from '~sharedConstants/index.ts';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { DNS } from '~frontendRoot/constants/index.js';


const radioOptions =  [
    { id: DNS_TYPE_DHCP, label: <Message message="widgets:Dns.type.dhcp" /> },
    { id: DNS_TYPE_RECURSIVE, label: <Message message="widgets:Dns.type.recursive" /> },
    { id: DNS_TYPE_NAMESERVERS, label: <Message message="widgets:Dns.type.nameservers" />  },

];

@withTranslation()
@connect(
    (state) => ({
        selectedType: getDnsServerType(state),
        nameservers: getDnsServerNameservers(state),
        schema: getDnsServerNameserversSchema(state),
        addresses: getDnsServerAddresses(state),
        addressesSchema: getDnsServerAddressesSchema(state)

    }),
    {
        doSetGlcfgObjectValue: setGlcfgObjectValue,

    }
)
class DnsServer extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            selectedType: PropTypes.string,
            doSetGlcfgObjectValue: PropTypes.func,
            nameservers: PropTypes.array,
            schema: PropTypes.object,
            addresses: PropTypes.array,
            addressesSchema: PropTypes.object,
        };
    }

    onChangeEvent = ({ id, name }) => {
        const { doSetGlcfgObjectValue } = this.props;
        doSetGlcfgObjectValue(DNS, id, name);
    };

    onChangeEventIface = ({ value, name }) => {
        const { doSetGlcfgObjectValue } = this.props;
        doSetGlcfgObjectValue(DNS, value, name);
    };

    onChangeEventAddress = ({ value, name }) => {
        const { doSetGlcfgObjectValue } = this.props;
        doSetGlcfgObjectValue(DNS, value, name);
    };

    render() {
        const { t, selectedType, nameservers, schema, addresses,
            addressesSchema } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    {t('widgets:Dns.servers.title')}
                </MDBCardHeader>
                <MDBCardBody className="safeSearch--card">
                    <p className="mb-1">{t('widgets:Dns.servers.address.title')}</p>
                    <RadioList
                        className="mockForm__radioList mt-2 ml-3"
                        data={radioOptions}
                        name="type"
                        onChange={this.onChangeEvent}
                        selectedOptionId={selectedType}
                    />

                    <Select
                        disabled={selectedType !== DNS_TYPE_NAMESERVERS}
                        id={'nameservers'}
                        isCreatable
                        isMulti
                        label={t('widgets:Dns.servers.nameservers.title')}
                        name="nameservers"
                        noDropdownIndicator
                        noOptionsMessage
                        noWrap
                        onChange={this.onChangeEventAddress}
                        schema={schema}
                        value={nameservers}
                    />
                    <NetworkInterfaceDevice
                        id={'dnsAddresses'}
                        iface={stringifyAddress(addresses)}
                        isCreatable
                        isMulti
                        label={t('widgets:Dns.servers.addresses.title')}
                        name={'addresses'}
                        onChange={this.onChangeEventIface}
                        schema={addressesSchema}
                    />

                </MDBCardBody>

            </MDBCard>
        );
    }
}

export default DnsServer;
