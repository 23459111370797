/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import moment from 'moment';
import React, { useState } from 'react';
import { ValuesType } from 'utility-types';

import { TIME_FORMAT } from '~commonLib/moment.ts';
import { LogDataLoaderOptions, useLogDataLoader } from '~frontendRoot/ducks/logDataFetcher/useLogDataLoader.ts';
import { LogSessionInitializeParams } from '~sharedLib/guiLogs/types.ts';


const getDefaultInitParams = (): LogSessionInitializeParams => {
    return {
        // Only systemdTime time format is supported
        sinceTime: moment('0:00', 'h:mm').format(TIME_FORMAT.systemdTime),
        tailMode: true,
        units: [ 'ak-backend' ],
    };
};

// Loader options need to be passed as constant to loader hook. To avoid resetting the loader.
const LOADER_OPTIONS: LogDataLoaderOptions = {
    // Line height must be constant to allow list virtualization
    lineHeight: 5,
    // The biggest display height that can happen (css max-height). Real display height may be smaller than this.
    displayElementHeight: 200,

    preloadWindowsCount: 10,
    prerenderWindowsCount: 5,
};
export const LogDataExample = () => {
    // Init options need to be passed as constant to loader hook. To avoid resetting the loader.
    // Changing their reference will reset the loader and start new log session.
    const [ initParams, setInitParams ] = useState(getDefaultInitParams());
    const logData = useLogDataLoader(initParams, LOADER_OPTIONS);
    const setScroll = (event) => {
        // This is how scroll position within element is accessed and passed to loader
        logData.setScrollPosition(event.target.scrollTop);
    };
    const windowKeys = logData.windows.map(entry => entry[0]);
    return (
        <div>
            <div>Start status: {logData.startStatus}</div>
            <div>Start spacer height: {logData.startSpacerHeight}</div>
            <div>End status: {logData.endStatus}</div>
            <div>End spacer height: {logData.endSpacerHeight}</div>
            <div>Rendering windows: {Math.min(...windowKeys)} - {Math.max(...windowKeys)}</div>
            <div><button onClick={() => setInitParams(getDefaultInitParams())}>Reset log</button></div>
            {/* Spacers allow for smooth scrolling */}
            <div
                onScroll={setScroll}
                style={{ height: LOADER_OPTIONS.displayElementHeight, overflowY: 'scroll' }}
            >
                <div style={{ height: logData.startSpacerHeight }} />

                {/*
                Windows are provided as entries.
                To guarantee their sorting while preserving indexes allowing for easy rendering
                Even with kinda weird accessing via [0] and [1]
                */}
                {logData.windows.map(window => (
                    <LogDataWindow
                        dataWindow={window[1]}
                        key={`dataWindow-${window[0]}`}
                        windowIdx={window[0]}
                    />
                ))}

                {/* Spacers allow for smooth scrolling */}
                <div style={{ height: logData.endSpacerHeight }}>{logData.endStatus}</div>
            </div>

        </div>
    );
};

const LogDataWindowNoMemo = (props: {
    windowIdx: number,
    dataWindow: ValuesType<ReturnType<typeof useLogDataLoader>['windows']>[1]}) => {
    return (
        <div style={{ border: 'solid' }}>
            {props.dataWindow.map(
                (item, lineIdx) => (
                    <div
                        key={`dataWindow-${props.windowIdx}-${lineIdx}`}
                        style={{ height: LOADER_OPTIONS.lineHeight, fontSize: 5 }}
                    >{item.message}
                    </div>
                )
            )}
        </div>
    );
};

// Component displaying each window should be memoized. Each window has guaranteed referntial equality.
// Unless it has changed due to new lines in incomplete window or because it has been dropped and reloaded
// This allows us to skip rendering all windows again and again
const LogDataWindow = React.memo(LogDataWindowNoMemo);
