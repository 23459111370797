/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Service from '~frontendComponents/Service/index.ts';
import {
    getDnsServerDisabled, setGlcfgObjectValue
} from '~frontendDucks/hlcfgEditor/index.js';
import { Scene } from '~frontendComponents/Scene/index.js';
import { createNotification } from '~frontendRoot/lib/reactUtils.js';
import { DNS } from '~frontendRoot/constants/index.js';

import { Dns } from './components/index.js';


@withTranslation()
@connect(
    (state) => ({
        disabled: getDnsServerDisabled(state)
    }),
    {
        setValue: setGlcfgObjectValue

    }
)
class DnsScene extends Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            t: PropTypes.func,
            setValue: PropTypes.func,
        };
    }

    enableDns = () => {
        const { setValue } = this.props;
        setValue(DNS, undefined, '__off');
        createNotification({
            title: 'widgets:Dns.warningOn.title',
            desc: 'widgets:Dns.warningOn.desc',
            type: 'warning',
            persistent: true,
            group: 'dnsEnableDisable',
        });
    };

    disableDns = () => {
        const { setValue } = this.props;
        setValue(DNS, true, '__off');
        createNotification({
            title: 'widgets:Dns.warningOn.title',
            desc: 'widgets:Dns.warningOn.desc',
            type: 'warning',
            persistent: true,
            group: 'dnsEnableDisable',
        });
    };

    render() {
        const { t, disabled } = this.props;
        return (
            <Scene>
                <Service
                    id="Dns"
                    on={!disabled}
                    showService={(<Dns />)}
                    turnOff={this.disableDns}
                    turnOn={this.enableDns}
                >
                    <h3 className="mt-3">{t('widgets:Dns.safesearch.title')}</h3>
                    <p className="mb-2">
                        {t('widgets:Dns.safesearch.desc')}
                    </p>
                    <h3>{t('widgets:Dns.dnsCatch.title')}</h3>
                    <p className="mb-2">
                        {t('widgets:Dns.dnsCatch.desc')}
                    </p>
                </Service>
            </Scene>
        );
    }
}

export default DnsScene;
