/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setGlcfgObjectValue, getComponentEnabledHook, getProfilesWithAuthNames } from '~frontendDucks/hlcfgEditor/index.js';
import { PROXY, PROXY_AUTHENTICATION_KEY, PROFILE_STILL_WITH_AUTH } from '~frontendRoot/constants/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { setModalState } from '~frontendRoot/ducks/modals/index.js';

import AuthenticationService from './AuthenticationService.js';


const Authentication = () => {
    const dispatch = useDispatch();
    const authenticationEnabled: boolean = useSelector(getComponentEnabledHook(PROXY, PROXY_AUTHENTICATION_KEY));
    const profilesWithAuth: string[] = useSelector(getProfilesWithAuthNames);

    const enableService = () => {
        dispatch(setGlcfgObjectValue(PROXY, false, PROXY_AUTHENTICATION_KEY, '__off'));
    };

    const disableService = () => {
        dispatch(setGlcfgObjectValue(PROXY, true, PROXY_AUTHENTICATION_KEY, '__off'));
        if (profilesWithAuth.length) {
            dispatch(setModalState({ modal: PROFILE_STILL_WITH_AUTH, value: true }));
        }

    };
    return (
        <Service
            id="Authentication"
            on={authenticationEnabled}
            showService={(<AuthenticationService />)}
            turnOff={disableService}
            turnOn={enableService}
        />
    );
};

export default Authentication;
