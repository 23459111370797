/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { connect } from 'react-redux';
import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, } from 'mdbreact';
import PropTypes from 'prop-types';

import SuricataVariablesInput from '~frontendComponents/SuricataVariablesInput/index.js';
import { getSuricataPortVariables, getSuricataAddressVariables,
    getSuricataAddressVariablesSchema, getSuricataPortsVariablesSchema } from '~frontendDucks/hlcfgEditor/index.js';
import Message from '~frontendComponents/Message/index.js';
import { getIsAdvanced } from '~frontendDucks/dynamicSettings/dynamicSettings.js';
import { DEFAULT_SURICATA_VARIABLES, ADVANCED_SURICATA_VARIABLES, DEFAULT_SURICATA_PORTS,
    ADVANCED_SURICATA_PORTS } from '~frontendRoot/constants/index.js';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';


@connect(
    (state,) => ({
        showAdvancedSettings: getIsAdvanced(state),
        suricataAddressVariables: getSuricataAddressVariables(state),
        suricataPortVariables: getSuricataPortVariables(state),
        suricataSchema: getSuricataAddressVariablesSchema(state),
        suricataProtsSchema: getSuricataPortsVariablesSchema(state),

    }),
    {
    }
)
class SuricataVars extends Component {
    static get propTypes() {
        return {
            suricataAddressVariables: PropTypes.object,
            suricataPortVariables: PropTypes.object,
            ports: PropTypes.bool,
            suricataSchema: PropTypes.object,
            suricataProtsSchema: PropTypes.object,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            advanced: false
        };
    }

    switchAdvanced = () => {
        const { advanced } = this.state;
        this.setState({ advanced: !advanced });
    };

    render() {
        const { suricataAddressVariables, suricataPortVariables, ports, suricataSchema,
            suricataProtsSchema } = this.props;
        const { advanced } = this.state;
        const defaults = ports ? DEFAULT_SURICATA_PORTS : DEFAULT_SURICATA_VARIABLES;
        const advancedVars = ports ? ADVANCED_SURICATA_PORTS : ADVANCED_SURICATA_VARIABLES;
        return (
            <MDBCard className="card--withoutMH">
                <MDBCardTitle>
                    <Message message={`widgets:Suricata.variables.${ports ? 'portGroups' : 'addressGroups'}.title`} />
                </MDBCardTitle>
                <MDBCardBody>
                    {defaults.map(item => (
                        <SuricataVariablesInput
                            key={item}
                            name={item}
                            port={ports}
                            schema={(ports ? suricataProtsSchema : suricataSchema)?.properties?.[item]}
                            value={ports ? suricataPortVariables[item] : suricataAddressVariables[item]}
                        />
                    ))}
                    <div>
                        <IconWithTooltip
                            className="icon--secondary"
                            link
                            name={advanced ? 'chevron-up' : 'chevron-down'}
                            onClick={this.switchAdvanced}
                            tooltipText={`widgets:global.${advanced ? 'showLess' : 'showMore'}`}
                        />
                    </div>

                    {advanced && advancedVars.map(item => (
                        <SuricataVariablesInput
                            key={item}
                            name={item}
                            port={ports}
                            value={ports ? suricataPortVariables[item] : suricataAddressVariables[item]}
                        />
                    ))}


                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default SuricataVars;
