/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, createContext, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import {  MDBBtn } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Draggable } from '@hello-pangea/dnd';
import Svg from 'react-inlinesvg';

import logo from '~frontendRoot/img/svg/logo.svg';
import {  Icon, Select } from '~frontendComponents/Generic/index.js';
import {
    setNormalize,
    addToNormalize,
    duplicateFromNormalize,
    getTableSchema
} from '~frontendDucks/hlcfgEditor/index.js';
import { getOpenVpnRoutesUuid, getRoutesUuid } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import {
    VPN_ROUTES_TYPE_PUSH_TO_USER, VPN_ROUTES_TYPE_PUSH_TO_CLIENT,
    ROUTE, VPN_ROUTES_TYPE_SETTINGS, VPN_CLIENT_ROUTES_TYPE_SETTINGS, OPENVPN_USER
} from '~frontendConstants/index.js';
import { HlcfgRowId, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { makeSelectSearchedTableItem } from '~frontendRoot/ducks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import RowDivider, { RowDividerTypes } from '~frontendRoot/components/RowDivider.tsx';


export const vpnRoutesTypeToHlcfgTableMap = {
    [VPN_ROUTES_TYPE_SETTINGS]: hlcfgTableName.route,
    [VPN_CLIENT_ROUTES_TYPE_SETTINGS]: hlcfgTableName.route,
    [VPN_ROUTES_TYPE_PUSH_TO_CLIENT]: hlcfgTableName.vpnRoute,
    [VPN_ROUTES_TYPE_PUSH_TO_USER]: hlcfgTableName.vpnRoute,
};


export const VpnRoutingTableContext = createContext({ userUuid: '', vpnUuid: '', type: VPN_ROUTES_TYPE_PUSH_TO_USER,
    disabled: undefined } as {
    userUuid?: HlcfgRowId, vpnUuid: HlcfgRowId, type: typeof VPN_ROUTES_TYPE_PUSH_TO_USER |
     typeof VPN_ROUTES_TYPE_PUSH_TO_CLIENT | typeof VPN_ROUTES_TYPE_SETTINGS | typeof VPN_CLIENT_ROUTES_TYPE_SETTINGS,
     disabled?: boolean
});

const Row = ({ uuid, dataIndex, search, spacing }: CreateRowPropsType) => {
    const startsWithOpenVpnUser = typeof uuid === 'string' && uuid.startsWith(OPENVPN_USER);
    const getter = useMemo(() =>
        startsWithOpenVpnUser ? getOpenVpnRoutesUuid() : getRoutesUuid(), [ startsWithOpenVpnUser ]);
    const data = useSelector(state => getter(state, uuid));
    const schema = useSelector(state => getTableSchema(state, uuid));
    const getterSearch = useMemo(makeSelectSearchedTableItem, []);
    const matches = useSelector(state => getterSearch(state, uuid, search));

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { userUuid, vpnUuid, type, disabled } = useContext(VpnRoutingTableContext);

    const changeValue = useCallback(({ value, name }) => {
        dispatch(setNormalize({ type: ROUTE, uuid, key: name, value }));
    }, [ dispatch, uuid ]);


    const addRow = useCallback((addingAfter?: boolean) => {
        dispatch(addToNormalize({
            type: vpnRoutesTypeToHlcfgTableMap[type],
            addingAfter,
            uuidToAddBefore: uuid,
            key: type === VPN_ROUTES_TYPE_PUSH_TO_CLIENT ? 'pushToClient' : 'routes',
            subkey: type === VPN_ROUTES_TYPE_PUSH_TO_CLIENT ? 'routes' : undefined,
            uuid: type === VPN_ROUTES_TYPE_PUSH_TO_USER ? userUuid : vpnUuid,
            successText: 'widgets:routeTable.added',
        }));

    }, [ dispatch, uuid, userUuid, vpnUuid, type ]);


    const delRow = useCallback(() => {
        if (type !== VPN_ROUTES_TYPE_PUSH_TO_CLIENT) {
            dispatch(setNormalize({ uuid: type === VPN_ROUTES_TYPE_PUSH_TO_USER ? userUuid : vpnUuid
                , value: uuid, key: 'routes' }));
        } else {
            dispatch(setNormalize({ uuid: vpnUuid
                , value: uuid, key: 'pushToClient', subkey: 'routes' }));
        }

    }, [ dispatch, uuid, type, userUuid, vpnUuid ]);

    const duplicate = useCallback(() => {
        dispatch(duplicateFromNormalize({
            uuid: uuid,
            parentKey: type === VPN_ROUTES_TYPE_PUSH_TO_CLIENT ? 'pushToClient' : 'routes',
            parentSubkey: type === VPN_ROUTES_TYPE_PUSH_TO_CLIENT ? 'routes' : undefined,
            parentUuid: type === VPN_ROUTES_TYPE_PUSH_TO_USER ? userUuid : vpnUuid,
        }));
    }, [ dispatch, uuid, userUuid, vpnUuid, type ]);

    const [ types ] = useState<RowDividerTypes>([
        { addFunc: addRow, translation: 'widgets:routeTable.route' },
    ]);

    if (data.dontRender) {
        return null;
    }
    if (!data?.id) {
        return (
            <tr
                className="dataTableWidget__RowFirstRule"
                key="newRoute"
            >
                <td colSpan={1}></td>
                <td
                    className="dataTableWidget__RowFirstRuleRow"
                    colSpan={4}
                >
                    <div className="pl-2">
                        <h2>{t('widgets:routeTable.title')}</h2>
                        <p className="dataTableWidget__Rowtext">
                            {t('widgets:routeTable.desc1')}
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                disabled={disabled}
                                onClick={event => {
                                    event.preventDefault();
                                    addRow();
                                }}
                            >
                                <Icon name="plus" /> {t('widgets:routeTable.route')}
                            </MDBBtn>
                            {t('widgets:routeTable.desc2')}
                        </p>
                        <p>
                            {t('widgets:routeTable.desc3')}
                        (
                            <Icon
                                name="menu"
                                size="sm"
                            />)
                            {t('widgets:routeTable.desc4')}

                        </p>
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            disabled={disabled}
                            onClick={event => {
                                event.preventDefault();
                                addRow();
                            }}

                        >
                            <Icon name="plus" /> {t('widgets:routeTable.route')}
                        </MDBBtn>
                    </div>
                </td>
            </tr>
        );
    }
    const {  destination, gateway, __off, fake, id } = data;
    return (
        <Draggable
            //it needs string
            draggableId={String(dataIndex)}
            index={dataIndex}
            isDragDisabled={fake}
            key={dataIndex}
        >
            {(provided) => (

                <>
                    <RowDivider
                        id={id}
                        length={6}
                        types={types}
                    />

                    <tr
                        className={classNames(
                            'dataTableWidget__Row',
                            'profiles__row',
                            { 'dataTableWidget__Row--disable': __off || disabled },
                            { 'dataTableWidget__Row--match': matches.length },
                        )}
                        key={dataIndex}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { 'dataTableWidget__cell--match': matches.length },
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                            {...provided.dragHandleProps}
                        >
                            {
                                fake ?
                                    <Svg
                                        className={classNames(
                                            'p-1',
                                        )}
                                        height="32"
                                        src={logo}
                                        width="32"
                                    /> :

                                    <Icon
                                        name={fake ? 'account' : 'drag'}
                                        size="sm"
                                    />
                            }


                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                classNamePrefix="packetFilterSelect"
                                disabled={__off}
                                fake={fake}
                                id={'routeTableDestination' +  uuid}
                                isCreatable
                                isMulti
                                name="destination"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={changeValue}
                                schema={schema?.destination || schema?.siteToSiteNetworks}
                                tooltipText={fake ? 'widgets:Vpn.pushToUser.generated' : undefined}
                                value={destination}
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                disabled={__off}
                                fake={fake}
                                id={'routeTableGateway' +  uuid}
                                isCreatable
                                isMulti
                                name="gateway"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={changeValue}
                                schema={schema?.gateway}
                                tooltipText={fake ? 'widgets:Vpn.pushToUser.generated' : undefined}
                                value={gateway}
                            />
                        </td>
                        <RowMenuAndSwitch
                            __off={__off}
                            copyFunc={duplicate}
                            deleteFunc={delRow}
                            id={'routeTableOff' + uuid}
                            menu={!fake}
                            onChange={fake ? undefined : changeValue}
                            spacing={spacing}
                        />
                    </tr>
                    <RowDivider
                        after
                        id={id}
                        length={6}
                        types={types}

                    />

                </>
            )}
        </Draggable>
    );
};


export default Row;
