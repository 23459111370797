/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { AddressesSelector, ADDRESS_SELECTOR_KEY, getAddressesSelectorContent, isAddressesSelector } from '~sharedLib/addressesSelectorUtils.ts';
import { getStaticReferenceName, isStaticReference } from '~sharedLib/staticHlcfgReferenceUtils.js';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import {
    isNamedObject, namedObjectStringToObject, namedObjectToString, NamedObjectReference
} from '~sharedLib/namedObjectUtils.ts';
import { netservice,
    isNetserviceDataArray } from '~sharedLib/Netservice/Netservice.ts';
import { NetaddrDataObj, NetportSimpleData, NetserviceData } from '~sharedLib/types.ts';
import { NetType } from '~sharedLib/NetBase.ts';
import { netport } from '~sharedLib/Netport/Netport.ts';
import { isNetportSimpleData } from '~sharedLib/Netport/NetportSimple.ts';

/**
 * @description helper to ease transfer from older net* API to newer one.
 */
export const getNetStringify = net => {
    return val => {
        try {
            return `${net(val)}`;
        } catch (err) {
            return '';
        }
    };
};

export const parseAddress = (value, net = netaddr, nothingAsUndefined = false) => {
    if (nothingAsUndefined) {
        if (value === '') {
            return undefined;
        }
        if (!value.length) {
            return undefined;
        }
    }
    const parse = item => {
        return isAddressesSelector(item) ? item : isNamedObject(item) ? namedObjectStringToObject(item) :
            net(item);
    };
    if (Array.isArray(value)) {
        return value.map(parse) || [];
    }
    return parse(value);
};
const createString = (iface: string, selector: AddressesSelector[typeof ADDRESS_SELECTOR_KEY]) =>
    `${iface}_${selector.ipVersion}_${selector.addressType}`;

export const addressesSelectorToString = (object) => {
    if (object) {
        const selector = getAddressesSelectorContent(object) || {};
        if (selector.ifaceId) {
            return createString(selector.ifaceId, selector);
        }
        if (selector.ifaceType) {
            return createString(selector.ifaceType, selector);
        }
    }
    return '';
};

export const isSameSelector = (first, second) => {
    if (isAddressesSelector(first) && isAddressesSelector(second)) {
        return addressesSelectorToString(first) === addressesSelectorToString(second);
    }
    return false;
};

type NegatedNetaddrData<T extends StringifyAddressReturnType[] | StringifyAddr[]> = { list: T, negated?: boolean };


type StringifyAddr = NetaddrDataObj |
        AddressesSelector |
        NamedObjectReference;

type StringifySingle = NetserviceData |
        StringifyAddr |
        NetportSimpleData;

type StringifyAddressValueType = StringifySingle[] | StringifySingle |
        NegatedNetaddrData<StringifyAddr[]> |
        undefined

export type StringifyAddressReturnType = NegatedNetaddrData<StringifyAddressReturnType[]> |
        StringifyAddressReturnType[]|
        string |
        undefined

export const stringifyAddress = (value: StringifyAddressValueType, isArray = true,
    net: NetType = netaddr): StringifyAddressReturnType => {
    if (!value) {
        return undefined;
    }
    if (typeof value === 'object' && value !== null) {
        if ('list' in value) {
            if (value.list || value.negated) {
                return {
                    negated: value.negated,
                    list: stringifyAddress(value.list)
                } as NegatedNetaddrData<StringifyAddressReturnType[]>;
            }
        }
    }
    if (isStaticReference(value)) { // Static references in PF
        return [ getStaticReferenceName(value) ];
    }
    if (net === netservice) {
        if (isNetserviceDataArray(value)) {
            return getService(value);
        }

    }
    if (Array.isArray(value)) {
        return value.map(item => stringifyAddress(item, false, net)).filter(Boolean);
    }

    const netStringify = getNetStringify(net);

    const strVal = netStringify(value) || addressesSelectorToString(value) || namedObjectToString(value);

    if (isArray) {
        return strVal ? [ strVal ] : [];
    }
    return strVal || undefined;
};


const getService = (value: NetserviceData[]) => {
    return value.map(item => {
        if (isStaticReference(item?.ports)) {
            return getStaticReferenceName(item.ports);
        }
        const result: string[] = [];
        item?.ports.forEach(val => {
            if (!val) {
                return;
            }
            if (isNetportSimpleData(val)) {
                if (isStaticReference(val.port)) {
                    result.push(getStaticReferenceName(val.port));
                    return;
                }
            }
            const netPort = netport(val);
            if (!netPort.isEmpty()) {
                result.push(`${item.protocol}:${netPort.toString()}`);
            }
        });
        if (item?.ports?.length === 0) { //There are some protocols without ports e.g. ICMP
            result.push(item.protocol);
        }
        return result;
    }).flat();
};
