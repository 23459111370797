/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';


import Moment from '~frontendComponents/Moment/Moment.js';
import { cfgActivationRequest } from '~frontendDucks/cfgActivation/index.js';
import { ColumnValueUserName } from '~frontendComponents/FilteringTable/index.js';
import { getRows } from '~frontendRoot/ducks/cfgRevisionList/index.js';
import { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { buildnumParse } from '~commonLib/buildnumParse.ts';
import { useDispatchCallback } from '~frontendRoot/lib/hooks/defaultHooks.ts';

import ColumnValueChanged from '../ColumnValueChanged.js';
import ColumnValueMessage from '../ColumnValueMessage.js';
import UpgradeFormat from './UpgradeFormat.tsx';


interface IRow {
    date: string
    commitMessage: string
    author: string
    commitHash: string
    kafVersion: string
    actionSequenceId: string
    added: number
    removed: number
    hlcfgVersion: string
}

const Row = ({ dataIndex, spacing, uuid }: CreateRowPropsType) => {
    const row: IRow = useSelector(state => getRows(state)[dataIndex]);
    const rows = useSelector(getRows);
    const nextRowIdx = dataIndex + 1;

    const doCfgActivationRequest = useDispatchCallback(cfgActivationRequest, []);

    const hasVersionChanged = nextRowIdx >= rows.length ? false : rows[nextRowIdx].kafVersion !== row.kafVersion;


    if (!row) {
        return null;
    }
    const { date, commitMessage, author, commitHash, kafVersion } = row;
    return (
        <tr
            className={classNames(
                'dataTableWidget__Row',
                { 'dataTableWidget__Row--upgrade': hasVersionChanged },
            )}
            key={uuid}
        >
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >
            </td>
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                )}
            >
                <Moment
                    format="LLL"
                    value={date}
                />
            </td>
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >
                <ColumnValueChanged
                    otherProps={{
                        doCfgActivationRequest,
                    }}
                    row={row}
                />
            </td>
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >
                <ColumnValueMessage
                    row={row}
                    value={commitMessage}
                />
            </td>
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                )}
            >
                <ColumnValueUserName
                    value={author}
                />
            </td>
            <td className={classNames(
                'dataTableWidget__cell',
                { [`dataTableWidget__cell--${spacing}`]: spacing },

            )}
            >
                {hasVersionChanged ?
                    <UpgradeFormat
                        from={rows[nextRowIdx].kafVersion}
                        to={kafVersion}
                    /> :
                    buildnumParse(kafVersion).productVersion}
            </td>
            <td className={classNames(
                'dataTableWidget__cell',
                { [`dataTableWidget__cell--${spacing}`]: spacing },

            )}
            >
                {commitHash}
            </td>
        </tr>
    );
};

export default Row;
