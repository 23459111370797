/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {  MDBCardTitle, MDBCardBody, MDBCard, MDBRow, MDBCol } from 'mdbreact';

import { getQoSService
} from '~frontendDucks/hlcfgEditor/index.js';
import {   setActiveCard } from '~frontendDucks/activeCards/index.js';
import { getTreeChartHiddenNode, setTreeChartHidden } from '~frontendDucks/treeChartHidden/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { QOS } from '~frontendRoot/constants/index.js';


const makeMapStateToProps = () => {

    const mapStateToProps = (state, { hiddenTarget }) => {
        return {
            hidden: getTreeChartHiddenNode(state, 'qos', hiddenTarget),
            activeQoS: getQoSService(state)

        };
    };
    return mapStateToProps;
};

@withTranslation()
@connect(
    makeMapStateToProps,
    {
        setCard: setActiveCard,
        hide: setTreeChartHidden,

    }
)
class QoSVlan extends Component {
    static get propTypes() {
        return {
            uuids: PropTypes.array,
            hiddenTarget: PropTypes.string,
            setCard: PropTypes.func,
            t: PropTypes.func,
            activeQoS: PropTypes.object,

            hide: PropTypes.func,
            hidden: PropTypes.bool,
        };
    }

    changeActive = (value) => {
        const { setCard } = this.props;
        setCard({ type: QOS, value });
    };

    hideOrShow = () => {
        const { hide, hidden, hiddenTarget } = this.props;
        hide({ uuid: hiddenTarget, type: QOS, value: !hidden });
    };


    render() {
        const { uuids, hidden, t, activeQoS
        } = this.props;

        return (
            <MDBCard
                className="treeChart__card"
                style={
                    activeQoS.color ? {
                        boxShadow: `0 3px 6px ${activeQoS.color}`
                    } : {}
                }
            >

                <MDBCardTitle className="cardHide__title">{t('widgets:network.types.vlan')}</MDBCardTitle>
                <MDBCardBody>
                    <MDBRow>
                        {uuids.map(item => (
                            <div
                                className="p-2 clicable"
                                key={item}
                                onClick={() => this.changeActive(item.id)}
                            >
                                {item.name}
                            </div>
                        ))}
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>

                            <IconWithTooltip
                                link
                                name={hidden ? 'chevron-down' : 'chevron-up'}
                                onClick={this.hideOrShow}
                            />
                            {hidden &&
                            <div className="treeChart__number">
                                {uuids?.length}
                            </div>}
                        </MDBCol>
                    </MDBRow>

                </MDBCardBody>
            </MDBCard>

        );
    }
}

export default QoSVlan;
