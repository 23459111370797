/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { MDBBtn } from 'mdbreact';

import { dispatchOnEventInNamespaceAction, stopDispatchingOnEventInNamespaceAction } from '~frontendDucks/socketIO/index.js';
import {
    getSocketIOExampleDataLog,
    socketIOExampleAction,
    socketIOExampleSendMessage
} from '~frontendDucks/socketIOExample/index.js';
import moment from '~commonLib/moment.ts';
import { Input } from '~frontendComponents/Generic/index.js';


@connect(
    (state) => ({
        rows: getSocketIOExampleDataLog(state)
    }),
    {
        dispatchOnEventInNamespaceAction,
        stopDispatchingOnEventInNamespaceAction,
        socketIOExampleSendMessage,
    }
)

class SocketIOExample extends Component {
    static get propTypes() {
        return {
            dispatchOnEventInNamespaceAction: PropTypes.func,
            stopDispatchingOnEventInNamespaceAction: PropTypes.func,
            socketIOExampleSendMessage: PropTypes.func,
            rows: PropTypes.array
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            event: '',
            message: '',
            eventInput: '',
            namespace: '',
            namespaceInput: '',
        };

        // It is important to keep reference to the object for socket between updates
        // so socketIO saga can link subscriptions to unsubscriptions
        // much easier done in functional components
        this.state.objForSocket = this.getObjForSocket(this.state);
    }

    getObjForSocket(state) {
        return {
            namespace: state.namespace,
            eventsWithActions: [ {
                event: state.event,
                actionCreator: socketIOExampleAction
            } ]
        };
    }

    componentDidMount() {
        this.props.dispatchOnEventInNamespaceAction(this.state.objForSocket);
    }

    componentDidUpdate(prevProps, prevState) {
        const { namespace, event } = this.state;
        if (namespace !== prevState.namespace || event !== prevState.event) {
            this.setState({
                objForSocket: this.getObjForSocket(this.state),
            });
        }

        if (prevState.objForSocket !== this.state.objForSocket) {
            this.props.stopDispatchingOnEventInNamespaceAction(prevState.objForSocket);
            this.props.dispatchOnEventInNamespaceAction(this.state.objForSocket);
        }
    }

    componentWillUnmount() {
        this.props.stopDispatchingOnEventInNamespaceAction(this.state.objForSocket);
    }

    startListening() {
        const { namespaceInput, eventInput } = this.state;
        this.setState({
            event: eventInput,
            namespace: namespaceInput,
        });
    }

    async sendMessage() {
        const { namespaceInput, eventInput, message } = this.state;
        const { socketIOExampleSendMessage } = this.props;
        socketIOExampleSendMessage({
            event: eventInput,
            namespace: namespaceInput,
            data: `${moment().format()}: ${message}`
        });
    }

    render() {
        const { rows } = this.props;
        const { namespaceInput, eventInput, message, event, namespace } = this.state;
        return (
            <div>
                <Input
                    label="Namespace"
                    onChange={({ value }) => this.setState({ namespaceInput: value })}
                    value={namespaceInput}
                />
                <Input
                    label="Event"
                    onChange={({ value }) => this.setState({ eventInput: value })}
                    value={eventInput}
                />
                <Input
                    label="Message"
                    onChange={({ value }) => this.setState({ message: value })}
                    value={message}
                />
                <MDBBtn onClick={() => this.startListening()}>
                    {`Start listening on nsp: '${namespaceInput}', ev: '${eventInput}'`}
                </MDBBtn>
                <MDBBtn onClick={async () => this.sendMessage()}>
                    {`Send message to nsp: '${namespaceInput}', ev: '${eventInput}'`}
                </MDBBtn>
                <p>{`Listening on nsp: '${namespace}', ev: '${event}'`}</p>
                <div>
                    {rows.map((rowContent, index) => <p key={`SocketIOExample-row-${index}`}>{`"${rowContent}"`}</p>)}
                </div>
            </div>
        );
    }
}
export default SocketIOExample;
