/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from 'mdbreact';
import PropTypes, { InferProps } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { typeTimestamp } from '~frontendTypes/index.js';
import { getSuricataRulesUpdateState, getDatabaseLoading, getDatabaseError, getSuricataDatabaseInfo,
    databaseInfoRequest }
    from '~frontendDucks/protectionDatabases/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import { StateWidget } from '~frontendRoot/widgets/index.js';
import moment from '~commonLib/moment.ts';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/index.js';
import { Switch } from '~frontendComponents/Generic/index.js';
import { setGlcfgValueScalar  } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { getGlcfgValueHook, getInitGlcfgValueHook  } from '~frontendDucks/hlcfgEditor/index.js';
import { switchExpr } from '~sharedLib/helpers.ts';
import { SURICATA_AUTO_UPDATE, SURICATA_BETA_SERVER_ENABLED } from '~frontendRoot/constants/index.js';
import { useHlcfgOffableValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';

import SuricataRulesUpdatePerformIcon from '../SuricataRulesUpdatePerformIcon.js';


const StateMessageProps = {
    lastUpdate: typeTimestamp,
    dbVersion: typeTimestamp,
    suricataRulesState: PropTypes.string,
    info: PropTypes.bool,
};

const StateMessage = ({ lastUpdate, dbVersion, suricataRulesState, info }: InferProps<typeof StateMessageProps>) => (
    <>
        <span className="headerInfobox">
            <Message message={`ips:update.${info ? 'info' : lastUpdate ? 'lastUpdate' : 'dbVersion'
            }.${suricataRulesState}.title`}
            />
        </span>
        {!info && (lastUpdate ?
            <Message
                message={`ips:update.lastUpdate.${suricataRulesState}.desc`}
                params={{ time: moment(lastUpdate).fromNow() }}
            /> :
            dbVersion &&
            <Message
                message={`ips:update.dbVersion.${suricataRulesState}.desc`}
                params={{ time: moment(dbVersion).fromNow() }}
            />)
        }
    </>
);


const SuricataUpdates = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const suricataRulesInfo = useSelector(getSuricataDatabaseInfo);
    const isLoading = useSelector(getDatabaseLoading);
    const error = useSelector(getDatabaseError);
    const suricataRulesState = useSelector(getSuricataRulesUpdateState);
    const automaticUpdates = useSelector(getGlcfgValueHook('suricataAutoUpdate'));
    const enabledBetaServers = useSelector(getGlcfgValueHook('suricataBetaServerEnabled'));
    const enabledBetaServersFromInit = useSelector(getInitGlcfgValueHook('suricataBetaServerEnabled'));
    const suricataEnabled = useHlcfgOffableValue(it => it.protection.suricata.service).isOn;
    const automaticUpdatesFromInit = useSelector(getInitGlcfgValueHook('suricataAutoUpdate'));

    const setValue = useCallback(({ id, value }) => {
        dispatch(setGlcfgValueScalar(id, value));
    }, [ dispatch ]);

    useEffect(() => {
        dispatch(databaseInfoRequest());
    }, [ dispatch ]);

    const { updatedTimestamp } = suricataRulesInfo || {};
    const updatedAt = updatedTimestamp;
    const createdAt = updatedTimestamp;

    return (
        <MDBCard>
            <MDBCardTitle>
                <MDBRow>
                    <MDBCol lg="8">
                        {t('ips:update.title')}
                        <IconWithTooltip
                            className="ml-2 mb-1 database__stateIcon"
                            iconSize="sm"
                            link
                            name={switchExpr(
                                suricataRulesState,
                                {
                                    'error': 'alert-circle-outline',
                                    'warning': 'progress-alert',
                                    'success': 'check-circle-outline'
                                },
                                'check-circle-outline'
                            )}
                            tooltipText={t(
                                `widgets:AdaptiveFirewall.update.lastUpdate.${suricataRulesState}.title`
                            )}
                            withoutTranslation
                        />
                    </MDBCol>
                    <MDBCol
                        className="database__reloadIcon"
                        lg="4"
                    >
                        <SuricataRulesUpdatePerformIcon />
                    </MDBCol>
                </MDBRow>
            </MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <StateWidget
                            apiError={error}
                            error={suricataRulesState === 'error' &&
                                    <StateMessage
                                        dbVersion={createdAt}
                                        lastUpdate={updatedAt}
                                        suricataRulesState={suricataRulesState}
                                    />}
                            info={
                                !suricataEnabled &&
                                <StateMessage
                                    dbVersion={createdAt}
                                    info
                                    lastUpdate={updatedAt}
                                    suricataRulesState={'info'}
                                />
                            }
                            isLoading={isLoading}
                            success={
                                suricataRulesState === 'success' &&
                                 <StateMessage
                                     dbVersion={createdAt}
                                     lastUpdate={updatedAt}
                                     suricataRulesState={suricataRulesState}
                                 />
                            }
                            warning={
                                suricataRulesState === 'warning' &&
                                <StateMessage
                                    dbVersion={createdAt}
                                    lastUpdate={updatedAt}
                                    suricataRulesState={suricataRulesState}
                                />}
                        />
                    </MDBCol>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <WhenAdvanced showAnyway={enabledBetaServers ||
                         enabledBetaServersFromInit}
                        >
                            <Switch
                                align="spaceBetween"
                                checked={enabledBetaServers}
                                className="pt-2"
                                id={SURICATA_BETA_SERVER_ENABLED}
                                label={t('ips:betaServers')}
                                name="betaServersName"
                                onChange={setValue}
                                withoutFlexWrap
                            />
                        </WhenAdvanced>

                    </MDBCol>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <WhenAdvanced showAnyway={!automaticUpdates ||
                         !automaticUpdatesFromInit}
                        >
                            <Switch
                                align="spaceBetween"
                                checked={automaticUpdates}
                                className="pt-2"
                                id={SURICATA_AUTO_UPDATE}
                                label={t('ips:automaticUpdates')}
                                name="automaticUpdatesName"
                                onChange={setValue}
                                withoutFlexWrap
                            />
                        </WhenAdvanced>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};


export default SuricataUpdates;
