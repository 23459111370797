/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import Message from '../../../../Message/index.js';
import TitleAndDesc from '../../../../TitleAndDesc/index.js';


export const CfgGenerateStepProgress = ({ event }) => {
    const { all, done, currentDone } = event;
    if (!currentDone) {
        return (<Message message="actions:action.step.cfgGenerate.progress.starting" />);
    }
    if (!currentDone.result) {
        return (
            <Message
                message="actions:action.step.cfgGenerate.progress.started.title"
                params={{
                    all: {
                        message: all,
                        dontTranslate: true
                    },
                    done: {
                        message: done + 1,
                        dontTranslate: true
                    },
                    scriptName: {
                        message: `widgets:generators.${currentDone.script}.title`
                    }
                }}
                translateParams
            />
        );
    }
    return (
        <TitleAndDesc
            desc={<Message message="actions:action.step.cfgGenerate.progress.ended.desc" />}
            moreInformation={JSON.stringify(currentDone)}
            title={(
                <Message
                    message="actions:action.step.cfgGenerate.progress.ended.title"
                    params={{ all, done }}
                />
            )}
        />

    );
};

CfgGenerateStepProgress.propTypes = {
    event: PropTypes.object.isRequired,
};

const CfgGenerateStepAbortedTitle = () =>
    (<Message message="actions:action.step.cfgGenerate.aborted.title" />);

const CfgGenerateStepDoneTitle = () =>
    (<Message message="actions:action.step.cfgGenerate.done.title" />);

const CfgGenerateStepErrorTitle = () =>
    (<Message message="actions:action.step.cfgGenerate.error.title" />);

const CfgGenerateStepLoadingTitle = () =>
    (<Message message="actions:action.step.cfgGenerate.loading.title" />);

const actionStepCfgGenerate = {
    StepAbortedTitle: CfgGenerateStepAbortedTitle,
    StepDoneTitle: CfgGenerateStepDoneTitle,
    StepErrorTitle: CfgGenerateStepErrorTitle,
    StepLoadingTitle: CfgGenerateStepLoadingTitle,
    StepProgress: CfgGenerateStepProgress,
};

export default actionStepCfgGenerate;
