/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { MDBBtn, MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact';
import classNames from 'classnames';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { Component } from 'react';

import { MODAL_WIDTH_WIDE, SCROLLABLE_MAX_HEIGHT } from '../../constants/index.js';
import { typeStyle } from '../../types/index.js';
import MoreInformation from '../MoreInformation/index.js';
import Icon from '../Generic/Icon/index.ts';


const STYLE_MORE_INFORMATION = {
    maxWidth: MODAL_WIDTH_WIDE,
    maxHeight: SCROLLABLE_MAX_HEIGHT,
    overflow: 'auto',
};


const PopoverTitle = ({ icon, title }) => {
    return (
        <>
            {icon}
            <> </>
            {title}
        </>
    );
};

PopoverTitle.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.node.isRequired,
};


const PopoverDesc = ({ desc, moreInformation, styleMoreInformation, styleDesc }) => {
    if (!moreInformation) {
        return (
            <div style={styleDesc}>
                {desc}
            </div>
        );
    }
    const displayMoreInformation = typeof moreInformation === 'string' ?
        moreInformation.replace(/\n$/, '') :
        moreInformation;
    return (
        <>
            <div style={styleDesc}>
                {desc}
            </div>
            <MoreInformation
                style={{
                    ...STYLE_MORE_INFORMATION,
                    ...styleMoreInformation,
                }}
            >
                {displayMoreInformation}
            </MoreInformation>
        </>
    );
};

PopoverDesc.defaultProps = {
    styleMoreInformation: {},
};

PopoverDesc.propTypes = {
    desc: PropTypes.node,
    moreInformation: PropTypes.node,
    styleDesc: PropTypes.object,
    styleMoreInformation: PropTypes.object
};


const PopoverIcon = ({ icon, iconRight, isRight, noIcon, isLoading }) => {
    if (isLoading) {
        return (
            <Icon
                className="loader__svg m-0"
                name="loading"
            />
        );
    }
    if (noIcon || (iconRight && !isRight) || (!iconRight && isRight)) {
        return null;
    }
    if (icon) {
        return (
            <>
                {isRight ? ' ' : null}
                {icon}
                {isRight ? null : ' '}
            </>
        );
    }
    return null;
};

PopoverIcon.propTypes = {
    icon: PropTypes.node,
    iconRight: PropTypes.bool,
    isRight: PropTypes.bool,
    noIcon: PropTypes.bool,
    isLoading: PropTypes.bool,
};


const PopoverContent = ({ onlyIcon, title }) => {
    if (onlyIcon) {
        return null;
    }
    return title;
};

PopoverContent.propTypes = {
    onlyIcon: PropTypes.bool,
    title: PropTypes.node,
};

/**
 * Displays an icon with a title text. Together, they have a popover which displays a title text and an icon.
 *
 * mouseEnterDelay and mouseLeaveDelay: time in seconds
 */
class TitleAndDesc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPopover: false
        };
    }
    render() {
        const {
            desc, icon, onlyIcon, moreInformation, noIcon, styleMoreInformation, title, textTitle, iconRight,
            styleDesc, onClick, noBorder, color, outline, isLoading, id, divClassName, datacy
        } = this.props;
        const { openPopover } = this.state;
        const content =
        (
            <>
                <PopoverIcon
                    icon={icon}
                    iconRight={iconRight}
                    noIcon={noIcon}
                />
                <PopoverContent
                    onlyIcon={onlyIcon}
                    title={textTitle || title}
                />
                <PopoverIcon
                    icon={icon}
                    iconRight={iconRight}
                    isLoading={isLoading}
                    isRight
                    noIcon={noIcon}
                />
            </>
        );

        return (
            <Popover
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        arrowColor={'grey'}
                        arrowSize={10}
                        arrowStyle={{ opacity: 0.7 }}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        position={position}
                    >
                        <div
                            onMouseEnter={() => {
                                this.setState({
                                    openPopover: true
                                });
                            }}
                            onMouseLeave={() => {
                                this.setState({
                                    openPopover: false
                                });
                            }}
                        >
                            <MDBCard className="iconPopover__popover card--withoutMH">
                                <MDBCardHeader>{textTitle || title}</MDBCardHeader>
                                <MDBCardBody>
                                    <PopoverDesc
                                        desc={desc}
                                        moreInformation={moreInformation}
                                        styleDesc={styleDesc}
                                        styleMoreInformation={styleMoreInformation}
                                    />
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                    </ArrowContainer>
                )}
                isOpen={openPopover}
                positions={[ 'top', 'bottom' ]}
            >
                <div
                    className={classNames(
                        { 'fit-content': !divClassName },
                        'displayInline',
                        divClassName,

                    )}
                    onMouseEnter={() => {
                        this.setState({
                            openPopover: true
                        });
                    }}
                    onMouseLeave={() => {
                        this.setState({
                            openPopover: false
                        });
                    }}
                >
                    {onClick ?
                        <MDBBtn
                            color={color}
                            data-cy={datacy}
                            disabled={isLoading}
                            id={id}
                            onClick={onClick}
                            outline={outline || noBorder}
                        >
                            {content}
                        </MDBBtn> :
                        content
                    }
                </div>
            </Popover>
        );
    }
}

TitleAndDesc.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    desc: PropTypes.node,
    isLoading: PropTypes.bool,
    color: PropTypes.string,
    noBorder: PropTypes.bool,
    outline: PropTypes.bool,
    divClassName: PropTypes.string,
    icon: PropTypes.node,
    iconRight: PropTypes.bool,
    moreInformation: PropTypes.node,
    noIcon: PropTypes.bool,
    onlyIcon: PropTypes.bool,
    styleDesc: typeStyle,
    styleMoreInformation: typeStyle,
    textTitle: PropTypes.node,
    title: PropTypes.node.isRequired,
    datacy: PropTypes.string,
};

export default TitleAndDesc;
