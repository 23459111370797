/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';

import {  Icon,  } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';


export const getIcon = (state, __off) => {
    switch (state) {
    case 'UP':
    case 'UPUP':
        return (
            <Icon
                className={classNames(
                    'icon--green',
                    { 'icon--textColor': __off }
                )}
                name="circle"
                size="sx"
            />
        );
    case 'DOWN':
    case 'DOWNDOWN':
        return (
            <Icon
                className={classNames(
                    'icon--red',
                    { 'icon--textColor': __off }
                )}
                name="circle"
                size="sx"
            />
        );
    case 'DOWNUP':
    case 'UPDOWN':
        return (
            <Icon
                className={classNames(
                    'icon--primary',
                    { 'icon--textColor': __off }
                )}
                name="circle"
                size="sx"
            />
        );
    case 'hw': return (
        <IconWithTooltip
            className={classNames(
                'icon--secondary',
                'icon--help',
                { 'icon--textColor': __off }
            )}
            iconSize="sx"
            link
            name="expansion-card-variant"
            tooltipText="widgets:network.types.hw"
        />
    );
    case 'vlan': return (
        <IconWithTooltip
            className={classNames(
                'icon--secondary',
                'icon--help',
                { 'icon--textColor': __off }
            )}
            iconSize="sx"
            link
            name="lan"
            tooltipText="widgets:network.types.vlan"
        />
    );
    case 'bridge': return (
        <IconWithTooltip
            className={classNames(
                'icon--secondary',
                'icon--help',
                { 'icon--textColor': __off }
            )}
            iconSize="sx"
            link
            name="bridge"
            tooltipText="widgets:network.types.bridge"
        />
    );
    case 'bond': return (
        <IconWithTooltip
            className={classNames(
                'icon--secondary',
                'icon--help',
                { 'icon--textColor': __off }
            )}
            iconSize="sx"
            link
            name="merge"
            tooltipText="widgets:network.types.bond"
        />
    );
    case 'connected':
    case 'connectedconnected': {
        return (
            <Icon
                className={classNames(
                    'icon--green',
                    { 'icon--textColor': __off }
                )}
                name="ethernet"
                size="sx"
            />
        );}
    case 'connecteddisconnected':
    case 'disconnectedconnected': {
        return (
            <>
                <Icon
                    className={classNames(
                        'icon--green',
                        { 'icon--textColor': __off }
                    )}
                    name="ethernet"
                    size="sx"
                />
                <Icon
                    className={classNames(

                        { 'icon--textColor': __off }
                    )}
                    name="ethernet-cable-off"
                    size="sx"
                />
            </>
        );}
    case 'disconnected':
    case 'disconnecteddisconnected': {
        return (
            <Icon
                className={classNames(
                    { 'icon--textColor': __off }
                )}
                name="ethernet-cable-off"
                size="sx"
            />
        );
    }
    default: return <></>;
    }

};

export const getColumnsShow = (columns, toShow) =>
    columns?.find(item => item.title === toShow)?.selected;

export const getColumnsSelectedLength = (columns) =>
    columns?.filter(item => item.selected).length;
