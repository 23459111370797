/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import Message from '../../../../Message/index.js';


const ActionResultTitle = ({ hasFailed, componentId }) => {
    if (hasFailed) {
        if (componentId) {
            return (
                <Message message="modalWindows:SystemComponentActionModal.actionFailed.componentIdDefined.title" />
            );
        }
        return (<Message message="modalWindows:SystemComponentActionModal.actionFailed.componentIdUndefined.title" />);
    }
    if (componentId) {
        return (
            <Message message="modalWindows:SystemComponentActionModal.actionSuccessful.componentIdDefined.title" />
        );
    }
    return (<Message message="modalWindows:SystemComponentActionModal.actionSuccessful.componentIdUndefined.title" />);
};

ActionResultTitle.propTypes = {
    componentId: PropTypes.string,
    hasFailed: PropTypes.bool,
};

export default ActionResultTitle;
