/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import Message from '../../../../Message/index.js';
import { ComponentActionTitle } from '../../../../SystemComponents/SystemComponentActions.js';
import { ComponentNameTranslator } from '../../../../SystemComponents/ComponentNameTranslator.js';


const ActionResultDesc = ({ componentAction, componentType, componentId, hasFailed }) => {
    const params = { componentAction, componentId, componentType };
    if (hasFailed) {
        if (componentId) {
            return (
                <>
                    <Message
                        message="modalWindows:SystemComponentActionModal.actionFailed.componentIdDefined.desc1"
                    />
                    <ComponentActionTitle actionKey={componentAction} />
                    <Message
                        message="modalWindows:SystemComponentActionModal.actionFailed.componentIdDefined.desc2"
                    />
                    <ComponentNameTranslator componentType={componentType} />
                    <Message
                        message="modalWindows:SystemComponentActionModal.actionFailed.componentIdDefined.desc3"
                        params={params}
                    />
                </>
            );
        }
        return (
            <>
                <Message
                    message="modalWindows:SystemComponentActionModal.actionFailed.componentIdUndefined.desc1"
                />
                <ComponentActionTitle actionKey={componentAction} />
                <Message
                    message="modalWindows:SystemComponentActionModal.actionFailed.componentIdUndefined.desc2"
                />
                <ComponentNameTranslator componentType={componentType} />
                <Message
                    message="modalWindows:SystemComponentActionModal.actionFailed.componentIdUndefined.desc3"
                />
            </>
        );
    }
    if (componentId) {
        return (
            <>
                <Message
                    message="modalWindows:SystemComponentActionModal.actionSuccessful.componentIdDefined.desc1"
                />
                <ComponentActionTitle actionKey={componentAction} />
                <Message
                    message="modalWindows:SystemComponentActionModal.actionSuccessful.componentIdDefined.desc2"
                />
                <ComponentNameTranslator componentType={componentType} />
                <Message
                    message="modalWindows:SystemComponentActionModal.actionSuccessful.componentIdDefined.desc3"
                    params={params}
                />
            </>
        );
    }
    return (
        <>
            <Message
                message="modalWindows:SystemComponentActionModal.actionSuccessful.componentIdUndefined.desc1"
            />
            <ComponentActionTitle actionKey={componentAction} />
            <Message
                message="modalWindows:SystemComponentActionModal.actionSuccessful.componentIdUndefined.desc2"
            />
            <ComponentNameTranslator componentType={componentType} />
            <Message
                message="modalWindows:SystemComponentActionModal.actionSuccessful.componentIdUndefined.desc3"
            />
        </>
    );
};

ActionResultDesc.propTypes = {
    componentAction: PropTypes.string.isRequired,
    componentId: PropTypes.string,
    componentType: PropTypes.string.isRequired,
    hasFailed: PropTypes.bool,
};

export default ActionResultDesc;
