/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import { withTranslation } from 'react-i18next';


import SshInput from './components/SshInput.js';
import { SshKernunTech } from '../SshSettings/components/index.js';


@withTranslation()
class SshKeys extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
        };
    }

    render() {
        const { t } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    {t('widgets:Sshd.sshKeys.title')}
                </MDBCardHeader>
                <MDBCardBody>
                    <SshInput />
                    <SshKernunTech />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default SshKeys;
