/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { SyntheticEvent, memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import Svg from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DraggableProvided } from '@hello-pangea/dnd';
import assert from 'assert';

import { Icon, Input, Select } from '~frontendComponents/Generic/index.js';
import { IPV6_ENABLED, SMALL_SIZE } from '~frontendConstants/index.js';
import logo from '~frontendRoot/img/svg/logo.svg';
import {
    PACKET_FILTER_COLUMNS_ACTION,
    PACKET_FILTER_COLUMNS_ARROW,
    PACKET_FILTER_COLUMNS_DESTINATION,
    PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION,
    PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION_PORT,
    PACKET_FILTER_COLUMNS_DRAG,
    PACKET_FILTER_COLUMNS_GROUP,
    PACKET_FILTER_COLUMNS_INTERFACE,
    PACKET_FILTER_COLUMNS_LOG,
    PACKET_FILTER_COLUMNS_NAME,
    PACKET_FILTER_COLUMNS_QOS_NODES,
    PACKET_FILTER_COLUMNS_SERVICE,
    PACKET_FILTER_COLUMNS_SOURCE,
    PACKET_FILTER_COLUMNS_SOURCE_TRANSLATION,
    PACKET_FILTER_COLUMNS_TRANSLATION_ICON,
    PACKET_FILTER_COLUMNS_USER,
    PACKET_FILTER_COLUMNS_WEB_PROFILE,
    PF_SRC_TRANSLATION_DEFAULT,
    SECOND_DEFAULT_HEADER_UUID,
    SELECTABLE_TABLE_PACKET_FILTER,
    TableSizeType,
    userSetting
} from '~frontendConstants/constants.ts';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import {
    ACCEPT_ACTION,
    FORCE_ACCEPT_ACTION,
    HLCFG_OFF,
    TRANSPARENT_RULE_NAME,
    TRANSPARENT_S_RULE_NAME,
    WEB_ACTION,
} from '~sharedConstants/index.ts';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import { useUserSetting, useUserSettingToggleable } from '~frontendRoot/lib/hooks/userSettings.ts';
import { getColumnsUtils } from '~frontendRoot/lib/columnUtils.ts';
import RowDivider, { RowDividerTypes } from '~frontendRoot/components/RowDivider.tsx';
import { Offable } from '~frontendRoot/constants/types.ts';
import {
    getTableSchemaGetter, makeGetPacketFilterUuid, makeGetHasRuleError,
    getProfileProps, getGlcfgValue, getNamedObjectNetaddrAllValues,
    getHoneypotWithoutFormating, getQoSOptions
} from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { HlcfgTableItemSchema } from '~frontendRoot/types/externalTypes.ts';
import { useCombinedRefs } from '~frontendRoot/lib/reactUtils.js';
import { HlcfgRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { UseBooleanFuncType, useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { isNamedObjectStrRef } from '~sharedLib/namedObjectUtils.ts';
import { AnyFunc } from '~commonLib/types.ts';

import RuleDetail, { defaultOptions, getServiceOptions, getWebProfileOptions } from '../RuleDetail/index.js';
import GetIcon from '../GetIcon/index.js';


const getIconForTranslation = ({ sourceTranslation, destinationTranslation }) => {
    switch (true) {
    case Boolean(sourceTranslation && destinationTranslation?.address?.length):
        return 'SNAT REDIRECT';
    case Boolean(sourceTranslation):
        return 'SNAT';
    case Boolean(destinationTranslation?.address?.length):
        return 'REDIRECT';
    default:
        return null;
    }
};

type RenderEmptyUserRulesProps = {
    add: (event: SyntheticEvent<HTMLButtonElement, Event>) => void;
    addHeader: (event: SyntheticEvent<HTMLButtonElement, Event>) => void;
    uuid: string;
};


export const RenderEmptyUserRules = ({ add, addHeader, uuid }: RenderEmptyUserRulesProps) => {
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] =
     getColumnsUtils(allColumns, SELECTABLE_TABLE_PACKET_FILTER);
    const { t } = useTranslation();
    return (
        <tr
            className="packetFilter__firstRule"
            key={`newRule_${uuid}`}
        >
            <td />
            <td
                className="packetFilter__firstRuleRow"
                colSpan={getColumnsSelectedLength() - 1}
            >
                <div className="pl-2">
                    <h2>{t('scenes:scenes.Protection.scenes.PacketFilter.title')}</h2>
                    <p>
                        {t('packetFilter:packetFilter.descPart1')}
                        <MDBBtn
                            className="packetFilter__addButtons--button"
                            onClick={add}
                        >
                            <Icon name="plus" /> {t('packetFilter:rule')}
                        </MDBBtn>
                        {t('packetFilter:packetFilter.descPart2')}
                        <MDBBtn
                            className="packetFilter__addButtons--button"
                            data-cy="adding-header-button--empty"
                            onClick={addHeader}
                        >
                            <Icon name="plus" /> {t('packetFilter:header')}
                        </MDBBtn>
                        {t('packetFilter:packetFilter.descPart3')}
                    </p>
                    <p>
                        {t('packetFilter:packetFilter.addingDesc.part1')}
                    (
                        <Icon
                            name="menu"
                            size="sm"
                        />)
                        {t('packetFilter:packetFilter.addingDesc.part2')}

                    </p>
                    <MDBBtn
                        className="packetFilter__addButtons--button"
                        data-cy="adding-rule-button--empty"
                        onClick={add}
                    >
                        <Icon name="plus" /> {t('packetFilter:rule')}
                    </MDBBtn>
                    <MDBBtn
                        className="packetFilter__addButtons--button"
                        data-cy="adding-header-button--empty"
                        onClick={addHeader}
                    >
                        <Icon name="plus" /> {t('packetFilter:header')}
                    </MDBBtn>
                </div>
            </td>
        </tr>
    );
};


type RenderNftDividerProps = {
    provided: DraggableProvided,
    delRow: () => void;
    setValue: ({ value, name }: {value:string, name: string}) => void;
    spacing: TableSizeType;
    types: RowDividerTypes;
    uuid: HlcfgRowId;
    closeRules: ({ value }: {value:boolean}) => void;
    search?: string;
}

const RenderNftDividerNoMemo = ({
    provided,
    delRow,
    setValue,
    closeRules,
    spacing,
    types,
    uuid,
    search

}: RenderNftDividerProps) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'nftDivider'), 'Found not nftDivider uuid inside nftDivider renderer');
    const getter = useMemo(makeGetPacketFilterUuid, []);
    const getterError = useMemo(makeGetHasRuleError, []);
    const getterSearch = useMemo(makeSelectSearchedTableItem, []);
    const { color, fake, name, id, closed } = useSelector<any, Offable<'nftDivider'>>(state => getter(state, uuid));
    const hasError = useSelector<any, boolean>(state => getterError(state, uuid));
    const matches = useSelector(state => getterSearch(state, uuid, search));
    const schemas = useSelector<any, HlcfgTableItemSchema<'nftDivider'>>(getTableSchemaGetter(uuid));
    const ref = useCombinedRefs(provided.innerRef);
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_PACKET_FILTER);
    const [ stickyRuleNamePFDisabled ] = useUserSettingToggleable(userSetting.stickyRuleNamePF);
    const { t } = useTranslation();
    return (
        <>
            <RowDivider
                className={'dataTableWidget__RowAddPFButtons'}
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
                withoutButtons={!(!fake || id === SECOND_DEFAULT_HEADER_UUID)}
            />
            <tr
                ref={ref}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classNames(
                    'dataTableWidget__Row',
                    { 'dataTableWidget__cell--match': matches.length },
                    { 'dataTableWidget__Row--noticeMe': matches.length && closed },
                    'dataTableWidget__Row--important',
                    'dataTableWidget__header',
                    { 'dataTableWidget__Row--error': hasError },
                )}
                key={id}
                style={
                    {
                        backgroundColor: color,
                        ...provided.draggableProps.style
                    }
                }
            >
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'dataTableWidget__cell--error': hasError },
                        { [`dataTableWidget__cell--${spacing}`]: spacing }
                    )}
                    onClick={event => {
                        event.preventDefault();
                        closeRules({ value: !closed });
                    }}

                >
                    <Icon name={closed ? 'chevron-right' : 'chevron-down'} />
                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'dataTableWidget__cell--stickyName': !stickyRuleNamePFDisabled },
                        { [`dataTableWidget__cell--${spacing}`]: spacing }
                    )}
                    colSpan={2}
                    onClick={event => {
                        event.preventDefault();
                        if (fake) {
                            closeRules({ value: !closed });
                        }
                    }}
                >
                    <Input
                        className={classNames(
                            'dataTableWidget__RowInput',
                            'packetFilter__nameInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        disabled={fake}
                        id={'packetFilterName' + id}
                        inputClass={'dataTableWidget__cell--stickyName--label pl-0'}
                        isName
                        name="name"
                        onChange={setValue}
                        schema={schemas.name}
                        value={fake ? t('packetFilter:kernunRules') : name}
                        withoutBorder
                    />

                </td>
                <td colSpan={getColumnsSelectedLength() - 4} />
                <RowMenuAndSwitch
                    color={color}
                    colorFunc={setValue}
                    deleteButtonDisabled={closed}
                    deleteFunc={delRow}
                    id={'pfHeader' + id}
                    menu={!fake}
                    spacing={spacing}
                />
            </tr>
            <RowDivider
                after
                className={'dataTableWidget__RowAddPFButtons'}
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
        </>
    );
};
export const RenderNftDivider = memo(RenderNftDividerNoMemo);


type RenderNftRuleRowProps = {
    provided: DraggableProvided,
    delRow: () => void;
    copyRow: (rule: Offable<'nftRule'>) => void;
    setValue: ({ value, name }: {value:string, name: string}) => void;
    setValueId: ({ value, name, id }: {value:string, name: string, id: string}) => void;
    onChangeDestinationTranslationEvent: ({ empty, name }: {empty: boolean, name: string }) => void;
    spacing: TableSizeType;
    types: RowDividerTypes;
    uuid: HlcfgRowId;
    search?: string;
}
const RenderNftRuleRowNoMemo = ({
    provided,
    types,
    copyRow,
    uuid,
    search,
    setValue,
    setValueId,
    onChangeDestinationTranslationEvent,
    delRow,
    spacing,
}: RenderNftRuleRowProps) => {
    const [ showDetail, setShowDetail ] = useBoolean(false);
    const getter = useMemo(makeGetPacketFilterUuid, []);
    const getterError = useMemo(makeGetHasRuleError, []);
    const getterSearch = useMemo(makeSelectSearchedTableItem, []);
    // : any, because pls stop lying. (rule is not Offable<'nftRule'>, because the isIpv6 would crash)
    const rule: any =
         useSelector<any, Offable<'nftRule'>>(state => getter(state, uuid));
    const { closed, destinationAddress, fake,
        sourceAddress, id } = rule;
    const hasError = useSelector<any, boolean>(state => getterError(state, uuid));
    const matches = useSelector(state => getterSearch(state, uuid, search));
    const allValues = useSelector(state => getNamedObjectNetaddrAllValues(state));
    const ref = useCombinedRefs(provided.innerRef);
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_PACKET_FILTER);
    const ipv6Enabled = useSelector(state => getGlcfgValue(state, IPV6_ENABLED));
    const isIpv6 = useCallback(() => {
        return [ ...sourceAddress?.list ?? [], ...destinationAddress?.list ?? [] ].some(item => {
            if (item.includes('ipv4') || item.includes('REFERENCE')) {
                return false;
            }
            if (isNamedObjectStrRef(item)) {
                const found = allValues[item].flat().find((address: string) => netaddr(address).isIp6());
                return found;
            }
            if (item.includes('ipv6') || netaddr(item).isIp6()) {
                return true;
            }
        });
    }, [ destinationAddress, sourceAddress, allValues ]);

    const enabled = isIpv6() ? !rule[HLCFG_OFF] && ipv6Enabled : !rule[HLCFG_OFF];
    // Wrapper of td to pass to RenderRuleCells to avoid drilling all the provided props
    const DndHandleTd = useCallback((props: any) => {
        return (
            <td
                {...props}
                {...provided.dragHandleProps}
            >
                {props.children}
            </td>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, Object.values(provided.dragHandleProps ?? {}));
    if (closed) {
        //react beautiful dnd Draggable components require a HTMLElement to be provided
        //this is done using the innerRef property(ref)
        return (
            <tr
                className="dataTableWidget__Row--closed"
                ref={ref}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
            </tr>
        );
    }
    return (
        <>
            <RowDivider
                className={'dataTableWidget__RowAddPFButtons'}
                datacy="adding-button"
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
                withoutButtons={fake}
            />


            <tr
                ref={ref}
                {...provided.draggableProps}
                className={classNames(
                    'dataTableWidget__Row',
                    'packetFilter__edit',
                    'profiles__row',
                    'dataTableWidget__Row--important',
                    { 'dataTableWidget__Row--disable': !enabled },
                    { 'dataTableWidget__Row--error': hasError },
                    { 'dataTableWidget__Row--closed': closed },
                    { 'dataTableWidget__Row--match': matches.length },

                )}
                data-cy="packet-filter-row"
                key={id}
            >
                <RenderRuleCells
                    copyRow={copyRow}
                    delRow={delRow}
                    DndHandleTd={DndHandleTd}
                    onChangeDestinationTranslationEvent={onChangeDestinationTranslationEvent}
                    search={search}
                    setShowDetail={setShowDetail}
                    setValue={setValue}
                    setValueId={setValueId}
                    showDetail={showDetail}
                    spacing={spacing}
                    uuid={uuid}

                />
            </tr>

            <tr
                className={classNames(
                    'dataTableWidget__RowAdd',
                    'nft',
                    { 'bounce-in-top': showDetail },
                )}
            >
                {showDetail &&
                        <>
                            <td className="relative">


                            </td>
                            <td colSpan={getColumnsSelectedLength() - 2}>
                                <RuleDetail
                                    matches={matches}
                                    rule={rule}
                                    smallSpacing={spacing === SMALL_SIZE}
                                    uuid={id}
                                />
                            </td>
                            <td className="relative">


                            </td>
                        </>
                }
            </tr>
            <RowDivider
                after
                className={'dataTableWidget__RowAddPFButtons'}
                datacy="adding-button"
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
        </>
    );
};
type RenderRuleCellsProps = {
    delRow: () => void;
    copyRow: (rule: Offable<'nftRule'>) => void;
    setValue: ({ value, name }: {value:string, name: string}) => void;
    setValueId: ({ value, name, id }: {value:string, name: string, id: string}) => void;
    onChangeDestinationTranslationEvent: ({ empty, name }: {empty: boolean, name: string }) => void;
    spacing: TableSizeType;
    uuid: HlcfgRowId;
    search?: string;
    showDetail: boolean,
    setShowDetail: UseBooleanFuncType,
    DndHandleTd: AnyFunc
}

const RenderRuleCellsNoMemo = ({
    copyRow,
    uuid,
    search,
    setValue,
    setValueId,
    onChangeDestinationTranslationEvent,
    delRow,
    spacing,
    showDetail,
    setShowDetail,
    DndHandleTd,

}: RenderRuleCellsProps) => {
    const honeypotPorts = useSelector(getHoneypotWithoutFormating).ports;
    const guiAddressesHTTP = useSelector(state => getGlcfgValue(state, 'guiAddressesHTTP'));
    const qosOptions = useSelector(getQoSOptions);
    const getter = useMemo(makeGetPacketFilterUuid, []);
    const getterError = useMemo(makeGetHasRuleError, []);
    const getterSearch = useMemo(makeSelectSearchedTableItem, []);
    // : any, because pls stop lying. (rule is not Offable<'nftRule'>, because the isIpv6 would crash)
    const rule: any =
         useSelector<any, Offable<'nftRule'>>(state => getter(state, uuid));
    const { action, destinationAddress, destinationTranslation, fake,
        log, name, service, sourceAddress, sourceTranslation, id, iface, user, group, webProfile, qosNodes } = rule;
    const hasError = useSelector<any, boolean>(state => getterError(state, uuid));
    const matches = useSelector(state => getterSearch(state, uuid, search));
    const schemas = useSelector<any, HlcfgTableItemSchema<'nftRule'>>(getTableSchemaGetter(uuid));
    const profiles = useSelector(getProfileProps);
    const allValues = useSelector(state => getNamedObjectNetaddrAllValues(state));
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ getColumnsShow ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_PACKET_FILTER);
    const [ stickyRuleNamePFDisabled ] = useUserSettingToggleable(userSetting.stickyRuleNamePF);
    const { t } = useTranslation();
    const ipv6Enabled = useSelector(state => getGlcfgValue(state, IPV6_ENABLED));
    const isIpv6 = useCallback(() => {
        return [ ...sourceAddress?.list ?? [], ...destinationAddress?.list ?? [] ].some(item => {
            if (item.includes('ipv4') || item.includes('REFERENCE')) {
                return false;
            }
            if (isNamedObjectStrRef(item)) {
                const found = allValues[item].flat().find((address: string) => netaddr(address).isIp6());
                return found;
            }
            if (item.includes('ipv6') || netaddr(item).isIp6()) {
                return true;
            }
        });
    }, [ destinationAddress, sourceAddress, allValues ]);

    const enabled = isIpv6() ? !rule[HLCFG_OFF] && ipv6Enabled : !rule[HLCFG_OFF];
    const enableTooltip = isIpv6() && !ipv6Enabled;
    return (
        <>
            {getColumnsShow(PACKET_FILTER_COLUMNS_DRAG) &&
                            <DndHandleTd
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    { 'packetFilter__defaultCursor': fake },
                                    { 'dataTableWidget__cell--error': hasError },
                                    { 'dataTableWidget__cell--match': matches.length },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            > {
                                    fake ?
                                        <Svg
                                            className={classNames(
                                                'p-1',
                                                { 'opacity--04': !enabled }
                                            )}
                                            height={spacing === SMALL_SIZE ? '24' : '32'}
                                            src={logo}
                                            width="32"
                                        /> :

                                        <div
                                            className="dataTableWidget__cell--small"
                                        >
                                            <Icon
                                                className="packetFilter__icon"
                                                name="drag"
                                                size="sm"
                                            />
                                        </div>

                                }
                            </DndHandleTd>
            }
            {getColumnsShow(PACKET_FILTER_COLUMNS_ACTION) ?
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__defaultCursor',
                        'dataTableWidget__cell--icon',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <Select
                        className={classNames(
                            'dataTableWidget__RowInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        disabled={!enabled || fake}
                        id={'onChangeEvent' + id}
                        isRow
                        justIcon
                        name="action"
                        noDropdownIndicator
                        noOptionsMessage
                        noWrap
                        onChange={setValue}
                        paste={false}
                        schema={schemas?.action}
                        value={action}
                    />
                </td> :
                null}
            {getColumnsShow(PACKET_FILTER_COLUMNS_NAME) ?
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'dataTableWidget__cell--stickyName--whiteBg': !stickyRuleNamePFDisabled },
                        { 'dataTableWidget__cell--stickyName': !stickyRuleNamePFDisabled },
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >
                    <Input
                        className={classNames(
                            'dataTableWidget__RowInput',
                            'packetFilter__nameInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        disabled={fake || !enabled}
                        id={'packetFilterName' + uuid}
                        inputClass={'dataTableWidget__cell--stickyName--label'}
                        isName
                        name="name"
                        onChange={setValue}
                        schema={schemas?.name}
                        value={name}
                        withoutBorder
                        withoutPaddingLeft
                    />
                </td> :
                null}
            {getColumnsShow(PACKET_FILTER_COLUMNS_SOURCE) ?
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        'dataTableWidget__cell--leftPadding',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },


                    )}
                >
                    <NetworkInterfaceDevice
                        className={classNames(
                            'dataTableWidget__RowInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        classNamePrefix="packetFilterSelect"
                        disabled={!enabled}
                        fake={fake}
                        id="packetFilterSourceAddress"
                        iface={sourceAddress}
                        isCreatable
                        isMulti
                        isRow
                        maxValueShown={3}
                        name="sourceAddress"
                        noDropdownIndicator
                        noOptionsMessage
                        onChange={setValue}
                        schema={schemas.sourceAddress}
                        withAllValues
                    />
                </td> : null}
            {getColumnsShow(PACKET_FILTER_COLUMNS_ARROW) &&
                                    <td
                                        className={classNames(
                                            'dataTableWidget__cell',
                                            'packetFilter__defaultCursor',
                                            'dataTableWidget__cell--icon',
                                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                                        )}
                                    >
                                        <Icon
                                            name="arrow-right"
                                            size="sx"
                                        />
                                    </td>
            }
            {getColumnsShow(PACKET_FILTER_COLUMNS_DESTINATION) ?
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing }
                    )}

                >
                    <NetworkInterfaceDevice
                        className={classNames(
                            'dataTableWidget__RowInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        classNamePrefix="packetFilterSelect"
                        disabled={!enabled}
                        fake={fake}
                        id="packetFilterDestinationAddress"
                        iface={destinationAddress}
                        isCreatable
                        isMulti
                        isRow
                        maxValueShown={3}
                        name="destinationAddress"
                        noDropdownIndicator
                        noOptionsMessage
                        noWrap
                        onChange={setValue}
                        schema={schemas.destinationAddress}
                        withAllValues
                    />

                </td> : null}
            {getColumnsShow(PACKET_FILTER_COLUMNS_SERVICE) ?
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing }
                    )}
                >
                    <Select
                        className={classNames(
                            'dataTableWidget__RowInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        classNamePrefix="packetFilterSelect"
                        disabled={!enabled}
                        fake={fake}
                        id="packetFilterdestinationPort"
                        isCreatable
                        isMulti
                        isRow
                        maxValueShown={3}
                        name="service"
                        noDropdownIndicator
                        noWrap
                        onChange={setValue}
                        options={getServiceOptions({ fake, honeypotPorts, guiAddressesHTTP })}
                        paste={false}
                        schema={schemas.service}
                        value={service}
                    />
                </td> :
                null}
            {getColumnsShow(PACKET_FILTER_COLUMNS_WEB_PROFILE) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'packetFilter__row',
                                    { 'packetFilter__defaultCursor': fake },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing }
                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                    )}
                                    classNamePrefix="packetFilterSelect"
                                    color={profiles[webProfile]?.color}
                                    disabled={(fake &&
                                         ![ TRANSPARENT_RULE_NAME, TRANSPARENT_S_RULE_NAME ].includes(name)) ||
                                          action !== 'web' || !enabled}
                                    id="packetFilterUser"
                                    isCreatable={false}
                                    isRow
                                    name="webProfile"
                                    noDropdownIndicator
                                    noWrap
                                    onChange={setValue}
                                    options={getWebProfileOptions(profiles)}
                                    paste={false}
                                    schema={schemas.webProfile}
                                    //TODO: AK-3160
                                    //hacking around schema. We want to show it same as qosNodes that is array with max
                                    //1 value
                                    value={action !== 'web' ? '' : webProfile ? [ webProfile ] : []}
                                />
                            </td>
            }
            {getColumnsShow(PACKET_FILTER_COLUMNS_USER) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'packetFilter__row',
                                    { 'packetFilter__defaultCursor': fake },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing }
                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                    )}
                                    classNamePrefix="packetFilterSelect"
                                    disabled={!enabled || fake}
                                    id="packetFilterUser"
                                    isCreatable
                                    isMulti
                                    isRow
                                    maxValueShown={9}
                                    name="user"
                                    noDropdownIndicator
                                    noWrap
                                    onChange={setValue}
                                    paste={false}
                                    value={user}
                                />
                            </td>}
            {getColumnsShow(PACKET_FILTER_COLUMNS_GROUP) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'packetFilter__row',
                                    { 'packetFilter__defaultCursor': fake },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing }
                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        // I set this manually to sm, because it was rendering as md even in sm mode
                                        // and I have no clue why. It was only happening after refresh
                                        // when group col was enabled. It was not happening when group
                                        // column was initially toggled off, and then toggled on.
                                        // Its unlikely anybody actually has group col enabled, as it does nothing
                                        // So i'm just going with this quick workaround.
                                        'dataTableWidget__RowInput--sm',
                                    )}
                                    classNamePrefix="packetFilterSelect"
                                    disabled={!enabled || fake}
                                    id="packetFilterGroup"
                                    isCreatable
                                    isMulti
                                    isRow
                                    maxValueShown={9}
                                    name="group"
                                    noDropdownIndicator
                                    noWrap
                                    onChange={setValue}
                                    paste={false}
                                    value={group}
                                />
                            </td>}
            {getColumnsShow(PACKET_FILTER_COLUMNS_INTERFACE) ?
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >
                    <NetworkInterfaceDevice
                        className={classNames(
                            'dataTableWidget__RowInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        classNamePrefix="packetFilterSelect"
                        disabled={(fake || action === ACCEPT_ACTION ||
                                        action === FORCE_ACCEPT_ACTION)}
                        id="packetFilterInterface"
                        iface={iface}
                        isRow
                        maxValueShown={6}
                        name="iface"
                        noDropdownIndicator
                        onChange={setValue}
                        paste={false}
                        schema={schemas.iface}
                        singleInterface
                    />
                </td> : null}
            {getColumnsShow(PACKET_FILTER_COLUMNS_TRANSLATION_ICON) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'packetFilter__translation',
                                    'dataTableWidget__cell--icon',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                <GetIcon
                                    destinationTranslationAddress={destinationTranslation?.address}
                                    icon={getIconForTranslation({ sourceTranslation, destinationTranslation })
                                    }
                                    spacing={spacing}
                                    t={t}
                                />
                            </td>}
            {getColumnsShow(PACKET_FILTER_COLUMNS_SOURCE_TRANSLATION) ?
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <NetworkInterfaceDevice
                        className={classNames(
                            'dataTableWidget__RowInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        classNamePrefix="packetFilterSelect"
                        defaultValue={PF_SRC_TRANSLATION_DEFAULT}
                        disabled={(action !== ACCEPT_ACTION &&
                                        action !== FORCE_ACCEPT_ACTION) || !enabled}
                        fake={fake}
                        id="address"
                        iface={sourceTranslation}
                        isCreatable
                        isMulti
                        isRow
                        maxValueShown={6}
                        name="sourceTranslation"
                        noDropdownIndicator
                        noOptionsMessage
                        onChange={setValue}
                        options={defaultOptions}
                        schema={schemas.sourceTranslation?.anyOf?.[0]} // random position
                    />
                </td> : null}
            {getColumnsShow(PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION) ?
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <Select
                        className={classNames(
                            'dataTableWidget__RowInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        classNamePrefix="packetFilterSelect"
                        disabled={!enabled || action !== ACCEPT_ACTION}
                        fake={fake}
                        id="address"
                        isCreatable
                        isMulti
                        isRow
                        maxValueShown={6}
                        name="destinationTranslation"
                        noDropdownIndicator
                        noOptionsMessage
                        onBecomingEmpty={onChangeDestinationTranslationEvent}
                        onBecomingNotEmpty={onChangeDestinationTranslationEvent}
                        onChange={setValueId}
                        schema={schemas.destinationTranslation?.properties?.address}
                        value={
                            destinationTranslation?.address
                        }
                    />
                </td> : null}
            {getColumnsShow(PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION_PORT) ?
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >

                    <Select
                        className={classNames(
                            'dataTableWidget__RowInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        disabled={!enabled || fake ||
                                        !(Number(destinationTranslation?.address?.length) > 0) ||
                                        !service?.length || (action !== ACCEPT_ACTION &&
                                            action !== FORCE_ACCEPT_ACTION)}
                        id="port"
                        isCreatable
                        isMulti
                        isRow
                        name="destinationTranslation"
                        onChange={setValueId}
                        schema={schemas.destinationTranslation?.properties?.port}
                        value={destinationTranslation?.port}
                        withoutBorder
                    />
                </td> : null}
            {getColumnsShow(PACKET_FILTER_COLUMNS_QOS_NODES) ?
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <Select
                        className={classNames(
                            'dataTableWidget__RowInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        classNamePrefix="packetFilterSelect"
                        disabled={!enabled || action === WEB_ACTION}
                        editable={false}
                        fake={fake}
                        id={'qosNodes' + id}
                        isMulti
                        isRow
                        name="qosNodes"
                        noDropdownIndicator
                        onChange={setValue}
                        options={qosOptions}
                        paste={false}
                        schema={schemas.qosNodes}
                        value={
                            qosNodes
                        }
                    />
                </td> : null}
            {getColumnsShow(PACKET_FILTER_COLUMNS_LOG) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                        'packetFilter__log'
                                    )}
                                    disabled={!enabled}
                                    id={'log' + id}
                                    isRow
                                    justIcon
                                    name="log"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    noWrap
                                    onChange={setValue}
                                    paste={false}
                                    schema={schemas?.log}
                                    value={log}
                                />
                            </td>
            }
            <RowMenuAndSwitch
                __off={!enabled}
                copyFunc={copyRow}
                deleteButtonDisabled={fake}
                deleteFunc={delRow}
                disabled={enableTooltip}
                id={'off' + id}
                name={HLCFG_OFF}
                onChange={setValue}
                settings={setShowDetail.swap}
                showDetail={showDetail}
                spacing={spacing}
                tooltipText={enableTooltip ? 'packetFilter:ipv6Disabled' : undefined}
            />
        </>
    );
};
const RenderRuleCells = memo(RenderRuleCellsNoMemo);
export const RenderNftRule = memo(RenderNftRuleRowNoMemo);
