/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelector } from 'reselect';

import { EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';
import { Offable } from '~frontendRoot/constants/types.ts';

import { getDefaultNormalizedTableGetters } from './glcfgGettersAndSettersUtils.ts';


const {
    getItem: getTableItem,
} = getDefaultNormalizedTableGetters();

export const getNamedObjectNetaddrScalarUuid = () => {
    return createSelector([
        (state, uuid: string) => getTableItem(state, uuid),
    ], (scalar: Offable<'netaddrScalar'>) => {
        if (!scalar) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        return {
            ...scalar,
            value: stringifyAddress(scalar.value),
        };
    });
};

export const getNamedObjectNetaddrVectorUuid = () => {
    return createSelector([
        (state, uuid: string) => getTableItem(state, uuid),
    ], (vector: Offable<'netaddrVector'>) => {
        if (!vector) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        return {
            ...vector,
            value: stringifyAddress(vector.value),
        };
    });
};
