/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBCardBody } from 'mdbreact';
import classNames from 'classnames';

import  TreeChart  from '~frontendComponents/TreeChart/index.js';
import  TurnedOff  from '~frontendComponents/TurnedOff/index.js';
import {
    getQoSService,
    getQoSQueuesById,
    setNormalize,
    getTableById
} from '~frontendDucks/hlcfgEditor/index.js';
import { getActiveCard } from '~frontendDucks/activeCards/index.js';
import { QOS, QOS_NODE_TABLE_TYPE } from '~frontendConstants/glcfgConstants.js';

import QoSRoot from './components/QoSRoot.js';
import QoSLeaf from './components/QoSLeaf.js';
import QoSVlan from './components/QoSVlan.js';


const generateRootLabel = ({ uuid, withName }) => {
    return (
        <QoSRoot
            uuid={uuid}
            withName={withName}
        />
    );
};

generateRootLabel.propTypes = {
    uuid: PropTypes.string,
    withName: PropTypes.bool,

};

const generateLabel = ({ uuid, root, parentUuid, secondTreeParent }) => {
    return (
        <QoSLeaf
            parentUuid={parentUuid}
            root={root}
            secondTreeParent={secondTreeParent}
            uuid={uuid}

        />
    );
};

generateLabel.propTypes = {
    uuid: PropTypes.string,
    root: PropTypes.bool,
    parentUuid: PropTypes.string,
    secondTreeParent: PropTypes.string,
};


const generateLabelSecondTree = ({ uuids, root, hiddenTarget  }) => {
    return (
        <QoSVlan
            hiddenTarget={hiddenTarget}
            root={root}
            uuids={uuids}
        />
    );
};

generateLabelSecondTree.propTypes = {
    uuids: PropTypes.array,
    root: PropTypes.bool,
    hiddenTarget: PropTypes.string,
};

@connect(
    (state) => ({
        data: getQoSService(state),
        structure: getTableById(state, QOS_NODE_TABLE_TYPE),
        roots: getQoSQueuesById(state),
        uuid: getActiveCard(state, QOS),
    }),
    {
        setValue: setNormalize,
    }
)
class QoSBody extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object,
            uuid: PropTypes.string,
            setValue: PropTypes.func,
            structure: PropTypes.object,
            roots: PropTypes.object,
        };
    }


    changeValue = ({ value, name, listId }) => {
        const { setValue, uuid } = this.props;
        setValue({ value, uuid, key: name, subkey: listId });
    };

    render() {
        const { data, uuid,
            structure, roots
        } = this.props;
        const {
            rootNodeId,
            color,
            __off,
            secondTree
        } = data;
        return (
            <MDBCardBody className={classNames('pannable')}>
                <TreeChart
                    color={color}
                    generateLabelNode={generateLabel}
                    generateLabelSecondTree={generateLabelSecondTree}
                    generateRootLabel={generateRootLabel}
                    parentId={uuid}
                    roots={roots}
                    secondTree={secondTree}
                    startId={rootNodeId}
                    structure={structure}
                    type="qos"
                />
                <TurnedOff
                    disabled={__off}
                    onChange={this.changeValue}
                />
            </MDBCardBody>

        );
    }
}

export default QoSBody;
