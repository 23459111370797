/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { typeTimestamp } from '../../types/index.js';
import Moment from '../Moment/Moment.js';


const ColumnValueTimestamp = ({ value }) => (
    <Moment
        format="LLL"
        value={value}
    />
);

ColumnValueTimestamp.propTypes = {
    value: typeTimestamp,
};

export default ColumnValueTimestamp;
