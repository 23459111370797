/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { MDBBtn } from 'mdbreact';

import Message from '~frontendComponents/Message/index.js';


class PanAndZoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: {
                oldX: 0,
                oldY: 0,
                x: 0,
                y: 0,
                z: 1
            },
            panning: false,
        };
        this.myRef = createRef();

    }

    componentDidMount() {
        window.addEventListener('mouseup',  this.mouseup);
        window.addEventListener('mousemove', this.mousemove);
        this.center();

    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.mouseup);
        window.removeEventListener('mousemove', this.mousemove);
    }

    componentDidUpdate(prevProps) {
        const { uuid } = this.props;
        if (uuid !== prevProps.uuid) {
            this.setState({
                position: {
                    oldX: 0,
                    oldY: 0,
                    x: 0,
                    y: 0,
                    z: 1
                },
                panning: false,
            });
            this.center();
        }
    }

    center = () => {
        const rect = this.myRef.current.getBoundingClientRect();
        const centerRect = document.getElementById('panning-center').getBoundingClientRect();
        if (centerRect.left > rect.width) {
            const { position } = this.state;
            this.setState({
                position: {
                    ...position,
                    x: -(centerRect.left  - centerRect.width - rect.x)
                }
            });
        }
    };

    reset = () => {
        this.setState({
            position: {
                oldX: 0,
                oldY: 0,
                x: 0,
                y: 0,
                z: 1
            },
            panning: false,
        }, this.center);
    };

    mousemove = (event) => {
        const { panning, position } = this.state;
        if (panning) {
            this.setState({
                panning: true,
                position: {
                    ...position,
                    x: position.x + event.clientX - position.oldX,
                    y: position.y + event.clientY - position.oldY,
                    oldX: event.clientX,
                    oldY: event.clientY
                }
            });
        }
    };

    mouseup = () => {
        this.setState({
            panning: false
        });
    };


    onLoad = (event) => {
        this.setState({
            div: {
                width: event.target.naturalWidth,
                height: event.target.naturalHeight
            }
        });
    };

    onMouseDown = (event) => {
        const { position } = this.state;
        if (event.target.localName === 'button' ||
            event.target.localName === 'svg' ||
            event.target.localName === 'path' ||
            event.target.className === 'Ripple is-reppling') {
            return;
        }

        this.setState({
            panning: true,
            position: {
                ...position,
                oldX: event.clientX,
                oldY: event.clientY
            }
        });
    };

    onWheel = (event) => {
        if (event.deltaY) {
            const { position } = this.state;
            const sign = Math.sign(event.deltaY) / 10;
            const scale = 1 - sign;
            const rect = this.myRef.current.getBoundingClientRect();
            this.setState({
                position: {
                    ...position,
                    x: position.x * scale - (rect.width / 2 - event.clientX + rect.x) * sign,
                    y:
                  position.y * scale -
                  (rect.width / 2 - event.clientY + rect.y) *
                    sign,
                    z: position.z * scale
                }
            });
        }
    };

    render() {

        const { children } = this.props;
        const { position } = this.state;
        return (
            <div
                className="PanAndZoomImage-container"
                onMouseDown={this.onMouseDown}
                onWheel={this.onWheel}
                ref={this.myRef}
            >
                <MDBBtn
                    className="zIndex-15"
                    onClick={this.reset}
                    outline
                > <Message message="widgets:global.resetZoom" />
                </MDBBtn>
                <div
                    style={{
                        transform: `translate(${position.x}px, ${position.y}px) scale(${position.z})`
                    }}
                >
                    <div>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

PanAndZoom.propTypes = {
    children: PropTypes.node,
    uuid: PropTypes.string,
};

export default PanAndZoom;
