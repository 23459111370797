/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBCol } from 'mdbreact';

import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import {  testLicense, getIsLoadingTest, getTestError, getTestResult, doClearTest
} from '~frontendDucks/systemInfo/index.js';
import { InputFile, Icon } from '~frontendComponents/Generic/index.js';
import { typeApiError } from '~frontendRoot/types/index.js';
import { uploadFileRequest } from '~frontendDucks/uploadFile/index.js';
import { getGlcfgValue, getInitGlcfgValue, setGlcfgValueScalar
} from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { LICENSE } from '~frontendRoot/constants/index.js';
import { hlcfgStringPathGet } from '~frontendLib/hlcfg/utils.ts';


@withTranslation()
@connect(
    (state,) => ({
        isLoading: getIsLoadingTest(state),
        testResult: getTestResult(state),
        testError: getTestError(state),
        file: getGlcfgValue(state, LICENSE),
        fileInit: getInitGlcfgValue(state, LICENSE),

    }),
    {
        doTestLicense: testLicense,
        clearTest: doClearTest,
        uploadFile: uploadFileRequest,
        setValue: setGlcfgValueScalar,

    }
)
class License extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            doTestLicense: PropTypes.func,
            isLoading: PropTypes.bool,
            testResult: PropTypes.object,
            testError: typeApiError,
            file: PropTypes.object,
            clearTest: PropTypes.func,
            setValue: PropTypes.func,
            uploadFile: PropTypes.func,
            fileInit: PropTypes.object,
        };
    }


    clearTest = () => {
        const { clearTest } = this.props;
        clearTest();
    };

    setFile = (value) => {
        const { setValue } = this.props;
        setValue('license', value);
    };

    uploadFile = ({ value, id, name }) => {
        const { setValue, uploadFile, fileInit } = this.props;
        setValue('license', fileInit || undefined);
        if (value instanceof File) {
            uploadFile({ value, funcToCall: this.setFile, props: [ id, name ] });
        }
    };

    testLicense = () => {
        const { doTestLicense } = this.props;
        doTestLicense();
    };
    render() {
        const { t, isLoading, testError, testResult, file } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    <MDBRow>
                        <MDBCol lg="4">
                            {t('widgets:license.title')}
                        </MDBCol>
                        <MDBCol
                            className="device__icons"
                            lg="8"
                        >
                            <IconWithTooltip
                                className="icon--textColor"
                                iconSize="sm"
                                link
                                name={isLoading ? 'loading' : 'play'}
                                onClick={this.testLicense}
                                tooltipText={t('widgets:license.test')}
                                withoutTranslation
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardHeader>
                <MDBCardBody>
                    {testError || testResult ?
                        <p className={`infobox infobox--${testResult ? 'success' : 'alert'}`}>
                            {testResult ? t('widgets:license.success') : testError.message}
                            <Icon
                                className="license__closeTest"
                                name="close"
                                onClick={this.clearTest}
                                size="sx"
                            />

                        </p> : null}
                    <InputFile
                        className="license__upload"
                        fakeFile={file?.name}
                        hlcfgPath={hlcfgStringPathGet.system.license.getPath()}
                        id="license"
                        label={t('widgets:license.upload')}
                        name="licenseName"
                        onChange={this.uploadFile}
                    />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default License;
