/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';
import { addressesAreInAscendingOrder } from '~sharedLib/Netaddr/netaddrIpUtils.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import type { HlcfgTypeDhcpLease, HlcfgTypeDhcpPool } from '~backendLib/newHlcfgGetters/hlcfgPathsAndTypes.ts';


type SplitPool = {
    [NODE_A_ID]: HlcfgTypeDhcpPool,
    [NODE_B_ID]: HlcfgTypeDhcpPool,
}

export const splitDhcpPoolForCluster = (dhcpPool: HlcfgTypeDhcpPool, leases: HlcfgTypeDhcpLease[]): SplitPool => {
    const from = netaddr(dhcpPool.rangeFrom);
    const to = netaddr(dhcpPool.rangeTo);
    const leasesNetaddr = leases.map(lease => netaddr(lease.ip));
    const leasesWithin = leasesNetaddr.filter(
        lease => addressesAreInAscendingOrder([ from, lease, to ])
    );

    const fromInt = parseInt(from.toBits(), 2);
    const toInt = parseInt(to.toBits(), 2);

    const poolSize = (toInt - fromInt + 1) - leasesWithin.length;

    let addressCnt = 0;
    let currentAddr = from;
    while (addressCnt !== Math.ceil(poolSize / 2)) {
        const addressIsReserved = leasesWithin.some(lease => lease.isEqualTo(currentAddr));
        if (!addressIsReserved) {
            addressCnt++;
        }
        currentAddr = currentAddr.incrementIp();
    }

    const nodeATo = currentAddr.decrementIp();
    const nodeBFrom = currentAddr;
    return {
        [NODE_A_ID]: {
            ...dhcpPool,
            rangeFrom: from.toObject(),
            rangeTo: (nodeATo.isEqualTo(to) ? to.decrementIp() : nodeATo).toObject(),
        },
        [NODE_B_ID]: {
            ...dhcpPool,
            rangeFrom: (nodeBFrom.ipIsGreaterThan(to) ? to : nodeBFrom).toObject(),
            rangeTo: to.toObject(),
        },
    };

};
