/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardHeader } from 'mdbreact';

import { Input, Select } from '~frontendComponents/Generic/index.js';
import  NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import {
    getGlcfgValue,
    setGlcfgValueScalar,
    getGlcfgSchema
} from '~frontendDucks/hlcfgEditor/index.js';
import Message from '~frontendComponents/Message/index.js';
import glcfgDefinition from '~frontendLib/glcfgDefinition/index.js';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            username: getGlcfgValue(state, 'snmpUsername'),
            usernameSchema: getGlcfgSchema(state, 'snmpUsername'),

            rights: getGlcfgValue(state, 'snmpRights'),
            rightsSchema: getGlcfgSchema(state, 'snmpRights'),

            cipher: getGlcfgValue(state, 'snmpCipher'),
            cipherSchema: getGlcfgSchema(state, 'snmpCipher'),

            hash: getGlcfgValue(state, 'snmpHash'),
            hashSchema: getGlcfgSchema(state, 'snmpHash'),

            authPassword: getGlcfgValue(state, 'snmpAuthPassword'),
            authPasswordSchema: getGlcfgSchema(state, 'snmpAuthPassword'),

            encPassword: getGlcfgValue(state, 'snmpEncPassword'),
            encPasswordSchema: getGlcfgSchema(state, 'snmpEncPassword'),

            interfaces: getGlcfgValue(state, 'snmpInterfaces'),
            interfacesSchema: getGlcfgSchema(state, 'snmpInterfaces'),

            addresses: getGlcfgValue(state, 'snmpAddresses'),
            addressesSchema: getGlcfgSchema(state, 'snmpAddresses')

        };
    };
    return mapStateToProps;
};
@connect(
    makeMapStateToProps,
    {
        setValue: setGlcfgValueScalar,
    }
)
class SnpmService extends Component {
    static get propTypes() {
        return {
            setValue: PropTypes.func,
            username: PropTypes.string,
            authPassword: PropTypes.string,
            encPassword: PropTypes.string,
            rights: PropTypes.string,
            rightsSchema: PropTypes.object,
            hash: PropTypes.string,
            hashSchema: PropTypes.object,
            cipher: PropTypes.string,
            cipherSchema: PropTypes.object,
            addresses: PropTypes.array,
            addressesSchema: PropTypes.object

        };
    }

    changeValue = ({ value, name,  }) => {
        const { setValue } = this.props;
        setValue(name, value);
    };
    changeValueFalseyToUndefined = ({ value, name,  }) => {
        const { setValue } = this.props;
        setValue(name, value ? value : undefined);
    };

    changeValueAddress = ({ value, name,  }) => {
        const { setValue } = this.props;
        setValue(name, value);
    };

    render() {
        const {
            username, rights, rightsSchema, hash, hashSchema, cipher, cipherSchema, authPassword,
            encPassword, addresses, addressesSchema
        } = this.props;
        return (
            <MDBRow>
                <MDBCol
                    className="mb-4"
                    lg="8"
                    md="8"
                    sm="12"
                >
                    <MDBCard>
                        <MDBCardHeader>
                            <Message message="widgets:Snmp.title" />
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <NetworkInterfaceDevice
                                        id="snmpAddresses"
                                        iface={stringifyAddress(addresses)}
                                        isCreatable
                                        isMulti
                                        label={glcfgDefinition['snmpAddresses'].title}
                                        name="snmpAddresses"
                                        onChange={this.changeValueAddress}
                                        placeholder={'widgets:global.allAddresses'}
                                        schema={addressesSchema}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="12"
                                >
                                    <Select
                                        id="snmpRights"
                                        label={glcfgDefinition['snmpRights'].title}
                                        name="snmpRights"
                                        onChange={this.changeValue}
                                        paste={false}
                                        schema={rightsSchema}
                                        value={rights}
                                    />
                                </MDBCol>
                                <MDBCol>

                                    <Input
                                        autoComplete="off"
                                        id="snmpUsername"
                                        label={glcfgDefinition['snmpUsername'].title}
                                        name="snmpUsername"
                                        onChange={this.changeValue}
                                        value={username}

                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="12"
                                >
                                    <Select
                                        id="snmpHash"
                                        label={glcfgDefinition['snmpHash'].title}
                                        name="snmpHash"
                                        onChange={this.changeValue}
                                        paste={false}
                                        schema={hashSchema}
                                        value={hash}
                                    />
                                </MDBCol>
                                <MDBCol>

                                    <Input
                                        autoComplete="new-password"
                                        id="snmpAuthPassword"
                                        label={glcfgDefinition['snmpAuthPassword'].title}
                                        name="snmpAuthPassword"
                                        onChange={this.changeValue}
                                        type="password"
                                        value={authPassword}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol
                                    lg="4"
                                    md="4"
                                    sm="12"
                                >
                                    <Select
                                        id="snmpCipher"
                                        label={glcfgDefinition['snmpCipher'].title}
                                        name="snmpCipher"
                                        onChange={this.changeValue}
                                        paste={false}
                                        schema={cipherSchema}
                                        value={cipher}
                                    />
                                </MDBCol>
                                <MDBCol>

                                    <Input
                                        autoComplete="new-password"
                                        id="snmpEncPassword"
                                        label={glcfgDefinition['snmpEncPassword'].title}
                                        name="snmpEncPassword"
                                        onChange={this.changeValueFalseyToUndefined}
                                        type="password"
                                        value={encPassword}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>


        );
    }
}

export default SnpmService;
