/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import IconWithPopover from '~frontendComponents/IconWithPopover/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import { getHistoricalHlcfgTreesRequest } from '~frontendDucks/hlcfgDiffer/hlcfgDiffer.js';
import { SYSTEM_USER_NAME, CURRENT_HLCFG_VERSION } from '~sharedConstants/index.ts';


const ConfigurationChangedButtonDiffShort = ({ added, removed }) => {
    if (!added && !removed) {
        return (
            <IconWithPopover
                body={<Message message="widgets:ConfigurationRevisionList.columns.changed.unchanged.desc" />}
                header={<Message message="widgets:ConfigurationRevisionList.columns.changed.unchanged.title" />}
                iconSize="sm"
                name="crop-squere"
            />
        );
    }
    return (
        <IconWithPopover
            body={
                <Message
                    message="widgets:ConfigurationRevisionList.columns.changed.changed.desc"
                    params={{ added, removed }}
                />
            }
            className="fit-content"
            header={<Message message="widgets:ConfigurationRevisionList.columns.changed.changed.title" />}
            iconSize="sm"
            name="edit"
        />
    );
};

ConfigurationChangedButtonDiffShort.propTypes = {
    added: PropTypes.number.isRequired,
    removed: PropTypes.number.isRequired,
};

const ConfigurationChangedButtonDiffDisplayBase = ({
    added, removed, author, commitHash, doGetHistoricalHlcfgTreesRequest, hlcfgVersion
}) => {
    if ((!added && !removed) || hlcfgVersion !== CURRENT_HLCFG_VERSION || author === SYSTEM_USER_NAME) {
        // It is likely to be a configuration upgrade when the author is SYSTEM_USER_NAME
        return null;
    }
    return (
        <IconWithTooltip
            className="icon--secondary"
            iconSize="sm"
            link
            name="text"
            onClick={() => doGetHistoricalHlcfgTreesRequest(commitHash)}
            tooltipText={'widgets:ConfigurationRevisionList.columns.changed.displayDiff.desc'}
        />
    );
};

ConfigurationChangedButtonDiffDisplayBase.propTypes = {
    added: PropTypes.number.isRequired,
    author: PropTypes.string.isRequired,
    commitHash: PropTypes.string.isRequired,
    doGetHistoricalHlcfgTreesRequest: PropTypes.func.isRequired,
    hlcfgVersion: PropTypes.string,
    removed: PropTypes.number.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    doGetHistoricalHlcfgTreesRequest: getHistoricalHlcfgTreesRequest,
};

const ConfigurationChangedButtonDiffDisplay =
    connect(mapStateToProps, mapDispatchToProps)(ConfigurationChangedButtonDiffDisplayBase);

const ConfigurationChangedButtonActivationOutputDisplay = ({
    actionSequenceId, author, hlcfgVersion, otherProps
}) => {
    if (!actionSequenceId || author === SYSTEM_USER_NAME) {
        // there is no activation output when the author is a system user
        return null;
    }
    if (hlcfgVersion !== CURRENT_HLCFG_VERSION) {
        // This condition is not necessary but there is an assumption that when the hlcfg version does not correspond,
        // the replay version will not either. This assumption is false but it is faster to check this instead of
        // reading the stored files in order to obtain the reply version.
        return null;
    }
    return (
        <IconWithTooltip
            className="icon--secondary"
            iconSize="sm"
            link
            name="check"
            onClick={() => {
                if (actionSequenceId) {
                    otherProps.doCfgActivationRequest({ replayingActionSequenceId: actionSequenceId });
                }
            }}
            tooltipText="widgets:ConfigurationRevisionList.columns.changed.displayActivationOutput.desc"
        />
    );
};

ConfigurationChangedButtonActivationOutputDisplay.propTypes = {
    author: PropTypes.string.isRequired,
    actionSequenceId: PropTypes.string,
    hlcfgVersion: PropTypes.string,
    otherProps: PropTypes.object.isRequired,
};


const ColumnValueChanged = ({
    otherProps,
    row: { actionSequenceId, added, author, commitHash, removed, hlcfgVersion }
}) => (
    <>
        <ConfigurationChangedButtonDiffShort
            added={added}
            removed={removed}
        />
        <ConfigurationChangedButtonActivationOutputDisplay
            actionSequenceId={actionSequenceId}
            author={author}
            hlcfgVersion={hlcfgVersion}
            otherProps={otherProps}
        />
        <ConfigurationChangedButtonDiffDisplay
            added={added}
            author={author}
            commitHash={commitHash}
            hlcfgVersion={hlcfgVersion}
            removed={removed}
        />
    </>
);

ColumnValueChanged.propTypes = {
    otherProps: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
};

export default ColumnValueChanged;
