/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getGlcfgValue, setGlcfgProxyValue, setGlcfgValueScalar } from '~frontendDucks/hlcfgEditor/index.js';
import {  Select, Input } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';


export const selectOptionsList = [
    {
        id: '1',
        label: (
            <div className="packetFilter__selectIcon">
                <span><Message message="proxy:error.language.czech" /></span>
            </div>
        ),
        value: 'cs',
    },
    {
        id: '2',
        label: (
            <div className="packetFilter__selectIcon">
                <span><Message message="proxy:error.language.english" /></span>
            </div>
        ),
        value: 'en',
    },
];
/**
 * Proxy error settings component is use to set error variables to redux.
 *
 * @param {object} props
 * @param {object} [props.proxy] - redux proxy object with proxy params
 * @param {Function} [props.doSetGlcfgObjectValue] - redux function to set redux variables
 * @returns {React.Component}
 */
@withTranslation()
@connect(
    state => ({
        proxy: getGlcfgValue(state, 'proxy'),
        securityAdmin: getGlcfgValue(state, 'securityAdmin'),

    }),
    {
        doSetGlcfgProxyValue: setGlcfgProxyValue,
        doSetGlcfgValueScalar: setGlcfgValueScalar
    }
)
class errorProxySettings extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            proxy: PropTypes.object,
            doSetGlcfgProxyValue: PropTypes.func,
            securityAdmin: PropTypes.string,
            doSetGlcfgValueScalar: PropTypes.func,
        };
    }

    setProxy = ({ value, name }) => {
        const { doSetGlcfgProxyValue } = this.props;
        doSetGlcfgProxyValue(value, 'errorSettings', name, 'objectChange');
    };

    setSecurityAdmin = ({ value, name }) => {
        const { doSetGlcfgValueScalar } = this.props;
        doSetGlcfgValueScalar(name, value);
    };


    render() {
        const { t, proxy, securityAdmin } = this.props;
        const { errorSettings } = proxy;
        return (
            <MDBCard>
                <MDBCardTitle>
                    {t('proxy:error.title')}
                </MDBCardTitle>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol>
                            <Select
                                id="proxyLanguage"
                                label={t('proxy:error.language.title')}
                                name="language"
                                onChange={this. setProxy}
                                options={selectOptionsList}
                                paste={false}
                                value={errorSettings.language}
                            />
                        </MDBCol>
                        <MDBCol>
                            <Input
                                className="min-5"
                                id="securityAdmin"
                                label={t('proxy:error.securityAdmin')}
                                name="securityAdmin"
                                onChange={this.setSecurityAdmin}
                                value={securityAdmin}
                            />
                        </MDBCol>
                        <MDBCol
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <Input
                                id="contact"
                                label={t('proxy:error.contact.title')}
                                name="contact"
                                onChange={this.setProxy}
                                rows={3}
                                type="textarea"
                                value={errorSettings.contact}
                            />
                        </MDBCol>
                        <MDBCol>
                            <WhenAdvanced>
                                <Input
                                    id="logoId"
                                    label={t('proxy:error.logo.title')}
                                    name="logo"
                                    onChange={this.setProxy}
                                    rows={3}
                                    type="textarea"
                                    value={errorSettings.logo}
                                />
                            </WhenAdvanced>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default errorProxySettings;
