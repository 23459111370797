/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBRow,  MDBCardTitle } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';


import { Select } from '~frontendComponents/Generic/index.js';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import {
    ACCEPT_ACTION,
    FORCE_ACCEPT_ACTION,
    WEB_ACTION,
    DROP_ACTION
} from '~sharedConstants/index.ts';
import type { HlcfgSchemaJSON, HlcfgInputTree } from '~frontendTypes/externalTypes.ts';
import { InputCallbackFunction, SelectOptions } from '~frontendConstants/types.ts';
import { QOS } from '~frontendRoot/constants/index.js';


interface ActionProps {
    rule: HlcfgInputTree['tables']['nftRule'][''],
    disabled: boolean,
    doesMatch: (value: string) => boolean,
    getComponentError:  (value: string) => boolean,
    schemas?: HlcfgSchemaJSON['properties']['tables']['properties']['nftRule']['additionalProperties']['properties']
    onChangeEvent: InputCallbackFunction,
    onChangeEventId: InputCallbackFunction,
    onChangeDestinationTranslationEvent: InputCallbackFunction,
    destinationPortOptions: SelectOptions,
    profilesOptions: SelectOptions,
    defaultOptions: SelectOptions,
    qosOptions: SelectOptions,
    smallSpacing: boolean,
}

export const Action = ({ rule, disabled, doesMatch, getComponentError, schemas, smallSpacing,
    onChangeEvent, onChangeEventId, profilesOptions, defaultOptions, qosOptions,
    onChangeDestinationTranslationEvent }: ActionProps) => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            {rule.action !== DROP_ACTION &&
            <MDBCardTitle
                className={classNames(
                    { 'py-0': smallSpacing }
                )}
            >
                <MDBRow>
                    <MDBCol>
                        {t('packetFilter:actions.title')}
                    </MDBCol>
                </MDBRow>
            </MDBCardTitle>
            }
            <MDBCardBody className={smallSpacing ? 'px-2 py-0' : 'pt-2 pb-0'}>
                <MDBRow>
                    {rule.action === DROP_ACTION ?
                        <></>    :
                        rule.action === WEB_ACTION ?
                            <MDBCol
                                className={classNames(
                                    { 'packetFilter__ruleDetail--match': doesMatch('webProfile') }
                                )}
                                size={'12'}
                            >
                                <Select
                                    classNamePrefix="packetFilterSelect"
                                    disabled={rule.fake || rule.action !== WEB_ACTION}
                                    editable={false}
                                    error={getComponentError('webProfile')}
                                    formGroupClassName={smallSpacing ? 'mb-1' : 'mb-3'}
                                    id="packetFilterWebProfile"
                                    isMulti
                                    label={t('packetFilter:webProfile')}
                                    name="webProfile"
                                    onChange={onChangeEvent}
                                    options={profilesOptions}
                                    paste={false}
                                    schema={schemas?.webProfile}
                                    //TODO: AK-3160
                                    //hacking around schema. We want to show it same as qosNodes that is array with max
                                    //1 value
                                    value={rule?.webProfile ? [ rule?.webProfile ] : []}
                                />

                            </MDBCol> :
                            <>
                                <MDBCol
                                    className={classNames(
                                        { 'pr-1': smallSpacing },
                                        { 'packetFilter__ruleDetail--match': doesMatch('sourceTranslation') }
                                    )}
                                    size={'6'}
                                >
                                    <NetworkInterfaceDevice
                                        classNamePrefix="packetFilterSelect"
                                        disabled={(rule.action !== ACCEPT_ACTION && rule.action !==
                                                FORCE_ACCEPT_ACTION) ||
                                        disabled}
                                        error={getComponentError('sourceTranslation')}
                                        fake={rule.fake}
                                        formGroupClassName={smallSpacing ? 'mb-1' : 'mb-3'}
                                        iconName="drama-masks"
                                        id="address"
                                        iface={rule.sourceTranslation}
                                        isCreatable
                                        isMulti
                                        isSearchable
                                        label={
                                            t('packetFilter:source.translation')
                                        }
                                        maxNumberOfValues={1}
                                        name="sourceTranslation"
                                        noDropdownIndicator
                                        noOptionsMessage
                                        onChange={onChangeEvent}
                                        options={defaultOptions}
                                        paste={false}
                                        schema={schemas?.sourceTranslation?.anyOf?.[0]}
                                    />
                                </MDBCol>
                                <MDBCol
                                    className={classNames(
                                        { 'pl-1': smallSpacing },
                                        { 'packetFilter__ruleDetail--match':
                                                doesMatch('destinationTranslation') }
                                    )}
                                >
                                    <Select
                                        classNamePrefix="packetFilterSelect"
                                        disabled={rule.fake || rule.action !== ACCEPT_ACTION ||
                                            disabled}
                                        error={getComponentError('destinationTranslation')}
                                        formGroupClassName={smallSpacing ? 'mb-1' : 'mb-3'}
                                        iconName="call-missed"
                                        id="address"
                                        isCreatable
                                        isMulti
                                        label={t('packetFilter:destination.translation')}
                                        name="destinationTranslation"
                                        noDropdownIndicator
                                        noOptionsMessage
                                        onBecomingEmpty={onChangeDestinationTranslationEvent}
                                        onBecomingNotEmpty={onChangeDestinationTranslationEvent}
                                        onChange={onChangeEventId}
                                        paste={false}
                                        schema={schemas?.destinationTranslation?.properties?.address}
                                        value={
                                            rule?.destinationTranslation?.address
                                        }
                                    />

                                </MDBCol>
                                <MDBCol
                                    className={classNames(
                                        { 'pr-1': smallSpacing }
                                    )}
                                    size={'6'}
                                >

                                    <Select
                                        classNamePrefix="packetFilterSelect"
                                        disabled={rule.fake || disabled}
                                        editable={false}
                                        error={getComponentError(QOS)}
                                        formGroupClassName={smallSpacing ? 'mb-1' : 'mb-3'}
                                        id="qos"
                                        isMulti
                                        label={t('packetFilter:qosNodes')}
                                        name="qosNodes"
                                        onChange={onChangeEvent}
                                        options={qosOptions}
                                        paste={false}
                                        schema={schemas?.qosNodes}
                                        value={rule.qosNodes}
                                    />

                                </MDBCol>
                                <MDBCol
                                    className={classNames(
                                        { 'pl-1': smallSpacing }
                                    )}
                                >
                                    <Select
                                        disabled={rule.fake  ||
                            !rule.service?.length || rule.action !== ACCEPT_ACTION || disabled}
                                        formGroupClassName={smallSpacing ? 'mb-1' : 'mb-3'}
                                        id="port"
                                        isCreatable
                                        isMulti
                                        label={t('packetFilter:destination.portTranslation')}
                                        maxNumberOfValues={1}
                                        name="destinationTranslation"
                                        onChange={onChangeEventId}
                                        schema={schemas?.destinationTranslation?.properties?.port}
                                        value={rule.destinationTranslation?.port}
                                    />
                                </MDBCol>

                            </>
                    }
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
