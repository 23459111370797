/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useEffect } from 'react';
import { InferProps } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';

import Divider  from '~frontendComponents/Divider/index.js';
import {  Input, Icon, Select } from '~frontendComponents/Generic/index.js';
import { setNormalize, getTableSchemaGetter, getWafProfileUuid
} from '~frontendRoot/ducks/hlcfgEditor/index.js';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import { setTimeoutComponentFunction, setTimeoutComponentValidator } from '~frontendLib/timeUtils.ts';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/index.js';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { useBoolean } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { HlcfgTableItem, HlcfgTableItemSchema } from '~frontendRoot/types/externalTypes.ts';
import { selectOptionsList } from '~frontendRoot/scenes/Protection/scenes/Proxy/Settings/components/errorProxySettings.js';
import { OptionalString } from '~frontendRoot/constants/types.ts';


const WafProfileSettingsProps = {
    profile: OptionalString.isOptionalButNotNull,
};

const WafProfileSettings = ({ profile }: InferProps<typeof WafProfileSettingsProps>) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ show, setShow ] = useBoolean();

    const schema: HlcfgTableItemSchema<'wafProfile'> = useSelector(getTableSchemaGetter(profile));
    const { comment, timeout, errorSettings } =
        useSelector<any, HlcfgTableItem<'wafProfile'>>(getWafProfileUuid);


    useEffect(() => {
        setShow.off();
    }, [ profile, setShow ]);

    const setProfileParameters = useCallback(({ value, id }) => {
        dispatch(setNormalize({ uuid: profile, value, key: id }));
    }, [ profile, dispatch ]);

    const setProfileParametersWithName = useCallback(({ value, id, name }) => {
        dispatch(setNormalize({ uuid: profile, value, key: name, subkey: id }));
    }, [ profile, dispatch ]);

    const setTimeoutFunc = useCallback(({ value }) => {
        dispatch(setNormalize({ uuid: profile, value, key: 'timeout' }));
    }, [ profile, dispatch ]);


    const setTimeout = setTimeoutComponentFunction({ setValue: setTimeoutFunc, uuid: profile });


    const timeoutValidator = setTimeoutComponentValidator(t);
    return (
        <MDBCard>
            <MDBCardTitle className={classNames(
                'profiles__title',
                { 'profiles__title--noBorderBottom': show },
            )}
            >
                <div> {t('profile:settings.header')}
                    <Icon
                        name={show ? 'chevron-up' : 'chevron-down'}
                        onClick={setShow.swap}
                    />
                </div>
            </MDBCardTitle>
            <MDBCardBody
                className={classNames(
                    'profile__body',
                    'pt-0', 'pb-0',
                    { 'profile__body--hide': !show },
                    { 'profiles__title--borderBottom': show },
                )}
            >
                <MDBRow>
                    <MDBCol>
                        <span className="profile__headersText">{t('profile:profiles.profile')}</span>
                        <Input
                            id="comment"
                            label={t('profile:comment')}
                            name="parameters"
                            onChange={setProfileParameters}
                            type="textarea"
                            value={comment}
                        />
                    </MDBCol>
                    <Divider
                        vertical
                    />
                    <MDBCol>
                        <span className="profile__headersText">{t('profile:waf.errorPage.title')}</span>
                        <Select
                            id="language"
                            label={t('proxy:error.language.title')}
                            name="errorSettings"
                            onChange={setProfileParametersWithName}
                            options={selectOptionsList}
                            paste={false}
                            value={errorSettings?.language}
                        />
                        <Input
                            id="contact"
                            label={t('proxy:error.contact.title')}
                            name="errorSettings"
                            onChange={setProfileParametersWithName}
                            type="textarea"
                            value={errorSettings?.contact}
                        />

                    </MDBCol>
                    <Divider
                        vertical
                    />
                    <MDBCol>
                        <span className="profile__headersText">{t('profile:timeouts.title')}</span>
                        <Input
                            id="timeout"
                            label={t('profile:timeouts.both')}
                            name="parameters"
                            onChange={setTimeout}
                            schema={schema?.timeout}
                            validator={timeoutValidator}
                            value={valueFormatter.formatSeconds(timeout ||
                             schema?.timeout?.[DEFAULT_SCHEMA_VALUE])}
                        />
                    </MDBCol>
                    <WhenAdvanced>
                        <>
                            <Divider
                                vertical
                            />
                            <MDBCol>


                                <Input
                                    id="logo"
                                    label={t('proxy:error.logo.title')}
                                    name="errorSettings"
                                    onChange={setProfileParametersWithName}
                                    rows={3}
                                    type="textarea"
                                    value={errorSettings?.logo}
                                />

                            </MDBCol>
                        </>
                    </WhenAdvanced>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );

};

WafProfileSettings.propTypes = WafProfileSettingsProps;

export default WafProfileSettings;
