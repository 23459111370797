/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { SELECTABLE_COLUMNS, SelectableTables } from '~frontendRoot/constants/index.js';
import { UserSettings } from '~sharedLib/schemas/userSettings.schema.ts';


const columnsByTableTitle = {
    ...SELECTABLE_COLUMNS,
};
Object.entries(columnsByTableTitle).forEach(([ key, columns ]) => {
    columnsByTableTitle[key] = Object.fromEntries(columns.map(col => [ col.title, col ]));
});

export const getColumnsShow = (userSettingsColumns: UserSettings['columnsByTable'], table: SelectableTables) =>
    (title: string): boolean | undefined => {
        return userSettingsColumns?.[table]?.[title] ??
            columnsByTableTitle[table][title]?.selected;
    };

export const getColumnsSelectedLength =
     (userSettingsColumns: UserSettings['columnsByTable'], table: SelectableTables) =>
         () => {
             return SELECTABLE_COLUMNS[table].filter(item =>
                 userSettingsColumns?.[table]?.[item.title] ?? item.selected).length;
         };


type getColumnsUtilsRetType = [
    getColumnsShow: ReturnType<typeof getColumnsShow>,
    getColumnsSelectedLength:  ReturnType<typeof getColumnsSelectedLength>
]

export const getColumnsUtils = (
    userSettingsColumns: UserSettings['columnsByTable'],
    table: SelectableTables
): getColumnsUtilsRetType =>
    [ getColumnsShow(userSettingsColumns, table), getColumnsSelectedLength(userSettingsColumns, table) ];
