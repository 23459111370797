/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MDBBtn,  } from 'mdbreact';
import assert from 'assert';
import { ValuesType } from 'utility-types';

import { setSelected, getSelected,
    setInfoTab,
    getCurrentInfoTabIsError,
    TRANSIENT_UPGRADE_STATES,
    usePrepareUpgradeCallback,
    useUpgradeIsMutating } from '~frontendDucks/upgrade/upgrade.ts';
import { setModalState } from '~frontendDucks/modals/index.js';
import { Icon, Select  } from '~frontendComponents/Generic/index.js';
import { UPGRADE_CONFIRM_MODAL } from '~frontendConstants/constants.ts';
import { SYSTEM_UPGRADE_STATE_PREPARED, SYSTEM_UPGRADE_STATE_DOWNLOADING, SYSTEM_UPGRADE_STATE_PREPARING,
    SYSTEM_UPGRADE_STATE_ERROR,
    SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM, SYSTEM_UPGRADE_STATE_CONFIRMING,
    SYSTEM_UPGRADE_STATE_DOWNGRADING,
    SYSTEM_UPGRADE_STATE_COMMITTED,
    SYSTEM_UPGRADE_STATE_DOWNGRADED,
    SYSTEM_UPGRADE_STATE_FAILURE,
    SECOND,
    SYSTEM_UPGRADE_STATE_AWAITING
} from '~commonLib/constants.ts';
import { buildnumParse } from '~commonLib/buildnumParse.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { objectPick } from '~commonLib/objectUtils.ts';
import { noop } from '~commonLib/functionUtils.ts';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/WhenAdvanced.tsx';
import { testProps, testPropsStatus } from '~commonLib/PageObjectMap.ts';
import { useAvailableVersionsQuery, useNewerVersionAvailableQuery, useUpgradeStateQuery } from '~frontendQueries/upgrade/hooks.ts';

import { formatTimestamp, VersionCard, VersionDescription, VersionToDisplay } from './utils.tsx';
import { upgradeVersionCardMap } from './pageObjectMap.ts';
import { UPGRADE_BUTTON_TYPE as BUTTON, UPGRADE_DISPLAY_TYPE as DISPLAY } from './constants.ts';


type UpgradeCardProps = ReturnType<typeof upgradeCardModel>;
type UpgradeCardDisplayType = ValuesType<typeof DISPLAY>;
type UpgradeCardButtonType = ValuesType<typeof BUTTON>;
type UpgradeCardViewRepresentation = {
    button: UpgradeCardButtonType,
    displayType: UpgradeCardDisplayType,
    versionToDisplay?: VersionToDisplay,
};
type UpgradeCardBindings = ReturnType<typeof useUpgradeCardBindings>;

export const UpgradeCard = () => {
    const bindings = useUpgradeCardBindings();
    const model = upgradeCardModel(bindings);
    return <UpgradeCardView {...model} />;
};

const useUpgradeCardBindings = () => {
    const upgradeState = useUpgradeStateQuery();
    const {
        currentVersion, downgradeVersion,
        preparingVersion, lastChecked,
        state, preparedVersion,
    } = upgradeState.data ?? {};

    const refetchInterval =  state && TRANSIENT_UPGRADE_STATES.includes(state) ? 2 * SECOND : 60 * SECOND;

    // acessing .data and voiding it, because react-query gets confused if nothing gets accessed and
    // that causes re-render on every re-fetch even if nothing changes
    useUpgradeStateQuery({ refetchInterval: refetchInterval }).data;

    const isMutating = useUpgradeIsMutating();
    const availableList = useAvailableVersionsQuery().data;

    const newerVersionRes = useNewerVersionAvailableQuery();

    const actions: Record<ButtonAction, () => void> =  {
        openUpgradeConfirmModal: useDispatchCallback(
            () => setModalState({ modal: UPGRADE_CONFIRM_MODAL, value: true }), []
        ),
        openErrorTab: useDispatchCallback(() => setInfoTab('error'), []),
        prepareUpgrade: usePrepareUpgradeCallback(),

    };
    return {
        dispatchButtonAction: useDispatch(),
        availableListOptions: useMemo(() => availableListToOptions(availableList ?? []), [ availableList ]),
        preparingVersion,
        currentVersion,
        downgradeVersion,
        lastChecked,
        state,
        actions,
        preparedVersion,
        apiError: upgradeState.error,
        isNewerVersionAvailable: newerVersionRes.data?.newVersionAvailable,
        isLoading: isMutating || upgradeState.isFetching,
        newerVersion: newerVersionRes.data?.newerVersion,
        selected: useSelector(getSelected),
        setSelectedVersion: useDispatchCallback(({ value }) => setSelected(value), []),
        errorTabIsOpen: useSelector(getCurrentInfoTabIsError),
    };
};

const upgradeCardModel = (props: UpgradeCardBindings) => {
    const { versionToDisplay, displayType, button } = getUpgradeCardButtonType(props);
    const buttonDesc = BUTTON_DESCRIPTIONS[button];
    return {
        displayType,
        buttonType: button,
        buttonLoading: buttonDesc.loading || props.isLoading,
        buttonColor: buttonDesc.color ?? 'primary',
        buttonTitle: buttonDesc.title,
        buttonAction: props.actions[buttonDesc.action ?? ''] ?? noop,
        versionToDisplay,
        ...objectPick(props, [
            'lastChecked', 'availableListOptions', 'dispatchButtonAction',
            'selected', 'setSelectedVersion',
        ])

    };
};

const UpgradeCardViewNoMemo = (props: UpgradeCardProps) => {
    return (
        <VersionCard
            message="upgrade:upgrade.title"
            {...testProps(upgradeVersionCardMap.id)}
        >
            <UpgradeCardDescription {...props} />
            <UpgradeCardButton {...props} />
        </VersionCard>
    );
};
const UpgradeCardView = React.memo(UpgradeCardViewNoMemo);

const getUpgradeCardButtonType = ({
    selected, newerVersion, preparedVersion, preparingVersion,
    state = SYSTEM_UPGRADE_STATE_AWAITING, apiError, errorTabIsOpen
}: UpgradeCardBindings): UpgradeCardViewRepresentation => {

    const simpleType = BUTTON_BY_STATE[state];
    const getTargetVersionObj = () => {
        const selectedVersion = selected ? { buildnum: selected } : undefined;
        if ([ SYSTEM_UPGRADE_STATE_PREPARING, SYSTEM_UPGRADE_STATE_DOWNLOADING ].includes(state)) {
            return preparingVersion ?? preparedVersion ?? selectedVersion ?? newerVersion;
        }
        return selectedVersion ?? newerVersion ?? preparingVersion ?? preparedVersion;

    };
    const targetVersion = selected ?? newerVersion?.buildnum;
    const defaultDisplayVersion = getTargetVersionObj();
    if (apiError) {
        return {
            button: errorTabIsOpen ? BUTTON.errorTryAgain : BUTTON.error,
            displayType: DISPLAY.failedInfo,
        };
    }
    if (simpleType) {
        return { button: simpleType, displayType: DISPLAY.version, versionToDisplay: defaultDisplayVersion };
    }

    if (state === SYSTEM_UPGRADE_STATE_PREPARED) {
        assert(preparedVersion, 'Prepared version must exist if state is prepared');
        if (!targetVersion || targetVersion === preparedVersion?.buildnum) {
            return { button: BUTTON.upgrade, displayType: DISPLAY.version, versionToDisplay: preparedVersion };
        }
        if (targetVersion && targetVersion === newerVersion?.buildnum) {
            return { button: BUTTON.prepare, displayType: DISPLAY.newerVersion, versionToDisplay: newerVersion  };
        }
        return { button: BUTTON.prepare, displayType: DISPLAY.version, versionToDisplay: defaultDisplayVersion  };
    }
    if (state === SYSTEM_UPGRADE_STATE_ERROR || state === SYSTEM_UPGRADE_STATE_FAILURE) {
        const erroredVersion = preparedVersion ?? preparingVersion;

        if (targetVersion && erroredVersion && targetVersion !== erroredVersion?.buildnum) {
            // Selected version is different than the one that errored. So no error displayed on button.
            // Because we want user to upgrade to newer version anyway
            return { button: BUTTON.prepare, displayType: DISPLAY.version, versionToDisplay: defaultDisplayVersion };
        }
        if (!errorTabIsOpen) {
            return { button: BUTTON.error, displayType: DISPLAY.version, versionToDisplay: defaultDisplayVersion };
        }
        return { button: BUTTON.tryAgain, displayType: DISPLAY.version, versionToDisplay: defaultDisplayVersion };

    }
    const currentIsNewest = !newerVersion;
    if (currentIsNewest) {
        if (!targetVersion) {
            return { button: BUTTON.tryNow, displayType: DISPLAY.notFound };
        }
        if (targetVersion === preparedVersion?.buildnum) {
            if (preparedVersion.droppedAt) {
                return { button: BUTTON.tryAgain, displayType: DISPLAY.discarded, versionToDisplay: preparedVersion };
            }
            assert(false, 'Should not be reachable');
        } else {
            return { button: BUTTON.prepare, displayType: DISPLAY.version, versionToDisplay: defaultDisplayVersion };
        }
    }
    if (targetVersion && targetVersion === preparedVersion?.buildnum) {
        if (preparedVersion.droppedAt) {
            return { button: BUTTON.tryAgain, displayType: DISPLAY.version, versionToDisplay: preparedVersion };
        }
    }
    if (targetVersion === newerVersion.buildnum) {
        return { button: BUTTON.prepare, displayType: DISPLAY.newerVersion, versionToDisplay: newerVersion };
    }
    return { button: BUTTON.prepare, displayType: DISPLAY.version, versionToDisplay: defaultDisplayVersion };

};
const UpgradeCardDescription = (props: UpgradeCardProps) => {
    const { t }  = useTranslation();
    const testProps = testPropsStatus(upgradeVersionCardMap.child.infoDisplay.id, props.displayType);
    if (props.displayType === DISPLAY.notFound || props.displayType === DISPLAY.failedInfo) {
        return (
            <div
                className="toggleBox__text"
                {...testProps}
            >
                {props.displayType === DISPLAY.notFound ?
                    t('upgrade:upgrade.notFound') :
                    t('upgrade:upgrade.failedInfo')}
                <br />
                <br />

                <VersionDescription version={props.versionToDisplay} />
            </div>
        );
    }
    if (props.displayType === DISPLAY.discarded) {
        assert(props.versionToDisplay?.droppedAt, 'Version dropped at must be present here');
        assert(props.versionToDisplay?.droppedBy, 'Version dropped by must be present here');
        return (
            <div
                className="toggleBox__text"
                {...testProps}
            >
                {t('upgrade:upgrade.discard')}
                <br />
                <br />
                <VersionDescription version={props.versionToDisplay} />
            </div>
        );
    }
    return (
        <div
            className="upgrade__toggleBoxText toggleBox__text"
            {...testProps}
        >
            <VersionDescription version={props.versionToDisplay} />
            {props.displayType === DISPLAY.newerVersion ?
                t('upgrade:upgrade.newerVersion', {
                    version: buildnumParse(props.versionToDisplay?.buildnum).productVersion
                }) :
                null
            }
        </div>
    );
};

const UpgradeCardButton = ({
    availableListOptions, buttonType,
    setSelectedVersion, selected, buttonLoading, buttonColor, buttonAction, buttonTitle
}: UpgradeCardProps) => {
    const { t } = useTranslation();
    return (
        <>
            <WhenAdvanced>
                {availableListOptions ? (
                    <Select
                        id="selectVersion"
                        onChange={setSelectedVersion}
                        options={availableListOptions}
                        paste={false}
                        value={selected}

                    />
                ) : null
                }
            </WhenAdvanced>
            <MDBBtn
                className={'toggleBox__button'}
                color={buttonColor}
                disabled={buttonLoading}

                {...testPropsStatus(upgradeVersionCardMap.child.upgradeCardButton.id, buttonType)}
                onClick={buttonAction}
            >
                {buttonLoading ? (
                    <Icon
                        className="upgrade__loaderIcon"
                        name="loading"
                    />
                ) : null}
                {t(buttonTitle)}
            </MDBBtn>
        </>
    );
};

const availableListToOptions = (
    list: ReturnType<typeof useAvailableVersionsQuery<undefined>>['data']
) => {
    return (list || []).map(item => {
        const version = buildnumParse(item.version);
        const timeFormatted = formatTimestamp(version.timestamp);
        return {
            label: `${version.productVersion} (${timeFormatted})`,
            value: item.version,
            tooltipValues: [ timeFormatted, item.type ],
        };

    });
};


const BUTTON_BY_STATE = {
    [SYSTEM_UPGRADE_STATE_DOWNGRADING]: 'empty',
    [SYSTEM_UPGRADE_STATE_CONFIRMING]: 'confirming',
    [SYSTEM_UPGRADE_STATE_PREPARING]: 'preparing',
    [SYSTEM_UPGRADE_STATE_DOWNLOADING]: 'downloading',
    [SYSTEM_UPGRADE_STATE_COMMITTED]: 'empty',
    [SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM]: 'confirming',
    [SYSTEM_UPGRADE_STATE_DOWNGRADED]: 'empty',
} satisfies Record<string, UpgradeCardButtonType>;
type ButtonDescription = {
    title: string,
    actionCreator?: () => any,
    action?: ButtonAction,
    color?: 'red'|'primary',
    loading?: boolean
}
type ButtonAction = 'prepareUpgrade'|'openErrorTab'|'openUpgradeConfirmModal'
const BUTTON_DESCRIPTIONS: Record<UpgradeCardButtonType, ButtonDescription> = {
    [BUTTON.errorTryAgain]: {
        title: 'upgrade:upgrade.button.downloadAgain',
        action: 'prepareUpgrade',
        color: 'red',
    },
    [BUTTON.tryAgain]: {
        title: 'upgrade:upgrade.button.downloadAgain',
        action: 'prepareUpgrade',
    },
    [BUTTON.tryNow]: {
        title: 'upgrade:upgrade.button.manualUpdate',
        action: 'prepareUpgrade',
    },
    prepare: {
        title: 'upgrade:upgrade.button.prepare',
        action: 'prepareUpgrade',
    },
    error: {
        title: 'upgrade:upgrade.button.error',
        action: 'openErrorTab',
        color: 'red',
    },
    upgrade: {
        title: 'upgrade:upgrade.button.prepared',
        action: 'openUpgradeConfirmModal',
    },
    preparing: {
        title: 'upgrade:upgrade.button.preparing',
        loading: true,
    },
    downloading: {
        title: 'upgrade:upgrade.button.downloading',
        loading: true,
    },
    empty: {
        title: 'upgrade:upgrade.button.undefined',
        loading: true,
    },
    confirming: {
        title: 'upgrade:upgrade.button.confirming',
        loading: true,
    },
};
