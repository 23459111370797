/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getComponentAction, getComponentId, getComponentType } from '../../../../../ducks/systemComponentAction/index.js';
import ActionResultDesc from '../components/ActionResultDesc.js';
import ActionResultTitle from '../components/ActionResultTitle.js';
import SystemComponentActionSequenceTitle from '../components/SystemComponentActionSequenceTitle.js';


const ComponentActionStepAbortedTitle = () => 'ComponentActionStepAbortedTitle';


const ComponentActionStepDoneTitle = ({ componentId }) => (
    <ActionResultTitle
        componentId={componentId}
        hasFailed={false}
    />
);

ComponentActionStepDoneTitle.propTypes = {
    componentId: PropTypes.string,
};


const ComponentActionStepErrorTitle = ({ componentId }) => (
    <ActionResultTitle
        componentId={componentId}
        hasFailed={false}
    />
);

ComponentActionStepErrorTitle.propTypes = {
    componentId: PropTypes.string,
};


const ComponentActionStepLoadingTitle = ({ componentAction, componentId, componentType }) => (
    <SystemComponentActionSequenceTitle
        componentAction={componentAction}
        componentId={componentId}
        componentType={componentType}
    />
);

ComponentActionStepLoadingTitle.propTypes = {
    componentType: PropTypes.string.isRequired,
    componentId: PropTypes.string,
    componentAction: PropTypes.string.isRequired,
};


export const StepProgress = ({ componentType, componentId, componentAction, event }) => {
    const { type, data } = event.currentDone;
    switch (type) {
    case 'stdout':
        if (!data.stdout) {
            return null;
        }
        return (<pre>{data.stdout}</pre>);
    case 'stderr':
        if (!data.stderr) {
            return null;
        }
        return (<pre>{data.stderr}</pre>);
    case 'code':
        return (
            <ActionResultDesc
                componentAction={componentAction}
                componentId={componentId}
                componentType={componentType}
                hasFailed={!!data.code}
            />
        );
    default:
        throw new Error('Unsupported value of type ' + type);
    }
};

StepProgress.propTypes = {
    componentType: PropTypes.string.isRequired,
    componentId: PropTypes.string,
    componentAction: PropTypes.string.isRequired,
    event: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    componentAction: getComponentAction(state),
    componentId: getComponentId(state),
    componentType: getComponentType(state),
});


const actionStepComponentAction = {
    StepAbortedTitle: connect(mapStateToProps)(ComponentActionStepAbortedTitle),
    StepDoneTitle: connect(mapStateToProps)(ComponentActionStepDoneTitle),
    StepErrorTitle: connect(mapStateToProps)(ComponentActionStepErrorTitle),
    StepLoadingTitle: connect(mapStateToProps)(ComponentActionStepLoadingTitle),
    StepProgress: connect(mapStateToProps)(StepProgress),
};

export default actionStepComponentAction;
