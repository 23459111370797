/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import {  MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBBtn, MDBCol  } from 'mdbreact';

import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import { Switch, Input,  } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import { setGlcfgObjectValue, getGlcfgValue, getGlcfgSchema } from '~frontendDucks/hlcfgEditor/index.js';
import Pinger from '~frontendComponents/Pinger/index.js';
import { PRODUCTION_UPGRADE_HOST, EXPERIMENTAL_UPGRADE_HOST  } from '~sharedConstants/index.ts';
import { usePrepareUpgradeCallback } from '~frontendDucks/upgrade/upgrade.ts';
import { GW4 } from '~frontendConstants/index.js';


const CheckUpgradeButton = () => {
    const checkUpgrade = usePrepareUpgradeCallback();
    const { t } = useTranslation();
    return (
        <MDBBtn
            color="primary"
            onClick={checkUpgrade}
        >
            {t('upgrade:upgrade.button.manualUpdate')}
        </MDBBtn>
    );
};
/**
 * Upgrade component manage update of a AK system.
 *
 * @param {object} props
 * @returns {React.Component}
 */
@withTranslation()
@connect(
    state => ({
        upgrade: getGlcfgValue(state, 'upgrade'),
        schema: getGlcfgSchema(state, GW4),
    }),
    {
        doSetGlcfgObjectValue: setGlcfgObjectValue,
    }
)

class Settings extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            upgrade: PropTypes.object,
            doSetGlcfgObjectValue: PropTypes.func,
            schema: PropTypes.object,
        };
    }

    checkHandle = ({ value,  id }) => {
        const { doSetGlcfgObjectValue } = this.props;
        doSetGlcfgObjectValue('upgrade', value, id);
    };

    switchHandle = ({ value, id }) => {
        const { doSetGlcfgObjectValue } = this.props;
        doSetGlcfgObjectValue('upgrade', !value, id);
    };

    render() {
        const { t,
            upgrade, schema, } = this.props;
        const { auto, internal, experimental, isFiveMinuteTimer, withConfirming } = upgrade;
        return (
            <MDBCard className="upgrade__settings">
                <MDBCardHeader className="upgrade__settingsHeader"><Message message="upgrade:settings" />
                </MDBCardHeader>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol size="12">
                            <MDBRow>
                                <MDBCol size="12">
                                    <Switch
                                        align="spaceBetween"
                                        checked={auto}
                                        id="auto"
                                        label={t('upgrade:automaticUpgrade')}
                                        name="autoUpgrade"
                                        onChange={this.checkHandle}
                                    />
                                </MDBCol>
                                <WhenAdvanced>
                                    <MDBCol>
                                        <CheckUpgradeButton />
                                    </MDBCol>
                                </WhenAdvanced>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol size="12">
                            <Switch
                                align="spaceBetween"
                                checked={experimental}
                                id="experimental"
                                label={t('upgrade:experimentalUpgrade')}
                                name="experimentalUpgrade"
                                onChange={this.checkHandle}
                            />
                        </MDBCol>
                        <MDBCol  size="12">
                            <Switch
                                align="spaceBetween"
                                checked={withConfirming}
                                id="withConfirming"
                                label={t('upgrade:withConfirming')}
                                name="withConfirmingName"
                                onChange={this.checkHandle}
                            />
                        </MDBCol>
                        <MDBCol  size="12">
                            <WhenAdvanced>
                                <Switch
                                    align="spaceBetween"
                                    checked={internal}
                                    id="internal"
                                    label={t('upgrade:internalUpgrade')}
                                    name="internalUpgrade"
                                    onChange={this.checkHandle}
                                />
                            </WhenAdvanced>
                        </MDBCol>
                        <MDBCol  size="12">
                            <Switch
                                align="left"
                                bothActive
                                checked={!isFiveMinuteTimer}
                                className="upgrade__settingsLeft"
                                disabled={!withConfirming}
                                id="isFiveMinuteTimer"
                                isMessage
                                label={t('upgrade:waitingForConfirm')}
                                messageOff={t('upgrade:20min')}
                                messageOn={t('upgrade:5min')}
                                name="upgradeTimer"
                                onChange={this.switchHandle}
                                withoutBorder
                                withoutLabelMargins
                            />
                        </MDBCol>
                        <MDBCol>
                            <Input
                                className={'upgrade__settingsLeft upgrade__settingsPingMaxWidth'}
                                disabled={true}
                                endText={
                                    <Pinger
                                        address={internal ? EXPERIMENTAL_UPGRADE_HOST : PRODUCTION_UPGRADE_HOST}
                                        id="donwloadKernunPinger"
                                        schema={schema?.properties?.addr}
                                    />}
                                id="pingerForUpgrade"
                                label={t('upgrade:connection')}
                                name="pinger"
                                value={internal ? EXPERIMENTAL_UPGRADE_HOST : PRODUCTION_UPGRADE_HOST}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default Settings;
