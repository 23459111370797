/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {  MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Draggable } from '@hello-pangea/dnd';

import {  Icon, Select } from '~frontendComponents/Generic/index.js';
import {
    addToNormalize,
    deleteNormalize,
    setNormalize,
    duplicateFromNormalize,
    getRoutingTableChildTypes,
    getTableSchema
} from '~frontendDucks/hlcfgEditor/index.js';
import { getRoutesUuid } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { useCombinedRefs } from '~frontendLib/reactUtils.js';
import { getObjectMatch, } from '~frontendLib/objectUtils.js';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { ROUTING_TABLE } from '~frontendRoot/constants/index.js';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';


const makeMapStateToProps = () => {
    const getRoute = getRoutesUuid();
    const mapStateToProps = (state, { uuid }) => {
        return {
            data: getRoute(state, uuid),
            schema: getTableSchema(state, uuid),
            routingTableChildTypes: getRoutingTableChildTypes(state),

        };
    };
    return mapStateToProps;
};

@withTranslation()
@connect(
    makeMapStateToProps,
    {
        add: addToNormalize,
        delRow: deleteNormalize,
        setValue: setNormalize,
        copy: duplicateFromNormalize,
    }
)
class Row extends Component {
    static get propTypes() {
        return {
            dataIndex: PropTypes.number,
            add: PropTypes.func,
            delRow: PropTypes.func,
            setValue: PropTypes.func,
            data: PropTypes.object,
            t: PropTypes.func,
            search: PropTypes.string,
            selectedIndex: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            addRowByUuid: PropTypes.func,
            spacing: PropTypes.string,
            copy: PropTypes.func,
            schema: PropTypes.object,
            routingTableChildTypes: PropTypes.array,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            matches: getObjectMatch({ object: props.data, searchValue: props.search, net: netaddr }),
        };
    }

    componentDidUpdate(prevProps) {
        const { data, search, dataIndex } = this.props;

        if (search !== prevProps.search || dataIndex !== prevProps.dataIndex) {
            this.setState({
                matches: getObjectMatch({ object: data, searchValue: search, net: netaddr })
            });
        }
    }

    changeValue = ({ value, name,  }) => {
        const { setValue, data } = this.props;
        setValue({ uuid: data.id, key: name, value });
    };

    addRow = (addingAfter) => {
        const { add, data, routingTableChildTypes } = this.props;
        add({
            type: routingTableChildTypes[0], //just one type
            typeId: ROUTING_TABLE,
            addingAfter,
            uuidToAddBefore: data.id,
            successText: 'widgets:routeTable.added',
        });

    };

    copyRow = () => {
        const { copy, data } = this.props;
        copy({
            parent: ROUTING_TABLE,
            uuid: data.id,
        });
    };

    deleteRow = () => {
        const { data, delRow } = this.props;
        delRow({ type: ROUTING_TABLE, value: data.id });
    };

    render() {
        const {
            dataIndex,
            t,
            data,
            selectedIndex,
            spacing,
            schema,
        } = this.props;
        const {
            matches,
        } = this.state;
        if (!data?.id) {
            return (
                <tr
                    className="dataTableWidget__RowFirstRule"
                    key="newRoute"
                >
                    <td colSpan="1"></td>
                    <td
                        className="dataTableWidget__RowFirstRuleRow"
                        colSpan="4"
                    >
                        <div className="pl-2">
                            <h2>{t('widgets:routeTable.title')}</h2>
                            <p className="dataTableWidget__Rowtext">
                                {t('widgets:routeTable.desc1')}
                                <MDBBtn
                                    className="dataTableWidget__RowAddButtons--button"
                                    data-cy="routeAdd"
                                    onClick={event => {
                                        event.preventDefault();
                                        this.addRow();
                                    }}
                                >
                                    <Icon name="plus" /> {t('widgets:routeTable.route')}
                                </MDBBtn>
                                {t('widgets:routeTable.desc2')}
                            </p>
                            <p>
                                {t('widgets:routeTable.desc3')}
                            (
                                <Icon
                                    name="menu"
                                    size="sm"
                                />)
                                {t('widgets:routeTable.desc4')}

                            </p>
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                data-cy="routeAdd"
                                onClick={event => {
                                    event.preventDefault();
                                    this.addRow();
                                }}
                            >
                                <Icon name="plus" /> {t('widgets:routeTable.route')}
                            </MDBBtn>
                        </div>
                    </td>
                </tr>
            );
        }
        const { destination, gateway, id, __off, error } = data;
        return (
            <Draggable
                draggableId={id}
                index={dataIndex}
                key={id}
            >
                {(provided) => (

                    <>
                        <tr
                            className="dataTableWidget__RowAdd"
                            key={id + 'add'}
                        >
                            <td
                                className="dataTableWidget__RowAddRule"
                                colSpan="5"
                            >
                                <div className="dataTableWidget__RowAddButtons">
                                    <MDBBtn
                                        className="dataTableWidget__RowAddButtons--button"
                                        onClick={event => {
                                            event.preventDefault();
                                            this.addRow();
                                        }}
                                    >
                                        <Icon name="plus" /> {t('widgets:routeTable.route')}
                                    </MDBBtn>
                                </div>
                            </td>
                        </tr>
                        <tr
                            className={classNames(
                                'dataTableWidget__Row',
                                'profiles__row',
                                { 'dataTableWidget__Row--disable': __off },
                                { 'dataTableWidget__Row--match': matches.length },
                                { 'dataTableWidget__Row--error': error },
                                { 'dataTableWidget__Row--clicked': selectedIndex === dataIndex }
                            )}
                            key={dataIndex}
                            ref={useCombinedRefs(provided.innerRef)}
                            {...provided.draggableProps}
                            data-cy={'row:' + id}

                        >
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    { 'dataTableWidget__cell--match': matches.length },
                                    { 'dataTableWidget__cell--error': error },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                                {...provided.dragHandleProps}
                            >
                                <Icon
                                    name="drag"
                                    size="sm"
                                />
                            </td>
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                <Select
                                    className="dataTableWidget__RowInput"
                                    datacy={'routeDestination' + dataIndex}
                                    disabled={__off}
                                    id={'routeTableDestination' +  id}
                                    isCreatable
                                    isMulti
                                    name="destination"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={this.changeValue}
                                    schema={schema?.destination}
                                    value={destination}
                                />
                            </td>
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                <Select
                                    className="dataTableWidget__RowInput"
                                    datacy={'routeGateway' + dataIndex}
                                    disabled={__off}
                                    id={'routeTableGateway' +  id}
                                    isCreatable
                                    isMulti

                                    name="gateway"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={this.changeValue}
                                    schema={schema?.gateway}
                                    value={gateway}
                                />
                            </td>

                            <RowMenuAndSwitch
                                __off={__off}
                                copyFunc={this.copyRow}
                                deleteFunc={this.deleteRow}
                                id={'routeTableOff' + id}
                                onChange={this.changeValue}
                                spacing={spacing}
                            />
                        </tr>
                        <tr
                            className="dataTableWidget__RowAddEnd"
                            key={id + 'addEnd'}
                        >
                            <td
                                className="dataTableWidget__RowAddRule"
                                colSpan="5"
                            >
                                <div className="dataTableWidget__RowAddButtons">
                                    <MDBBtn
                                        className="dataTableWidget__RowAddButtons--button"
                                        data-cy="routeAdd"
                                        onClick={event => {
                                            event.preventDefault();
                                            this.addRow(true);
                                        }}
                                    >
                                        <Icon name="plus" />{t('widgets:routeTable.route')}
                                    </MDBBtn>
                                </div>
                            </td>
                        </tr>
                    </>
                )}
            </Draggable>
        );
    }
}


export default Row;
