/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createContext } from 'react';

import { SelectableTables } from '~frontendRoot/constants/index.js';


export const DhcpdDatatableContext = createContext({} as {
    pools: string, parentUuid: string, type: SelectableTables });
