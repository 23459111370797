/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import {
    MDBBtn
} from 'mdbreact';
import { useTranslation } from 'react-i18next';

import Service from '~frontendComponents/Service/index.ts';
import { Scene } from '~frontendComponents/Scene/index.js';
import { SYSTEM_COMPONENT_CALLHOME } from '~commonLib/systemComponentsDeclaration.ts';
import { componentActionSequenceOpen } from '~frontendRoot/ducks/systemComponentAction/systemComponentAction.ts';
import { useSystemComponentStatusQuery } from '~frontendQueries/system/hooks.ts';
import { useHlcfgOffableValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';

import { Ssh } from './components/index.js';


const SshScene = () => {
    const ssh = useHlcfgOffableValue(it => it.services.sshd);
    const callhomeStatus = useSystemComponentStatusQuery(SYSTEM_COMPONENT_CALLHOME).data;

    const enableCallhome = useCallback(() => {
        return componentActionSequenceOpen({
            componentAction: 'enable',
            componentType: SYSTEM_COMPONENT_CALLHOME,
            componentId: null,
        });
    }, []);
    const { t } = useTranslation();

    const running = ssh.isOn || callhomeStatus?.code === 0;
    return (
        <Scene>
            <Service
                hideOverlay={running}
                id="Sshd"
                on={ssh.isOn}
                showService={(<Ssh />)}
                turnOff={ssh.setOff}
                turnOn={ssh.setOn}
            >
                <h3 className="mt-3">{t('widgets:Sshd.sshInput.title')}</h3>
                <p className="mb-2">
                    {t('widgets:Sshd.sshInput.desc')}
                </p>
                <h2>{t('widgets:SystemComponents.components.RemoteHelp.title')}</h2>
                <article className="mt-3">
                    <p>
                        {t('widgets:SystemComponents.components.RemoteHelp.desc')}
                    </p>
                    <MDBBtn
                        className="navigation__button"
                        color="primary"
                        onClick={enableCallhome}
                        size="sm"
                        type="button"
                    >
                        {t('widgets:global.on')}
                    </MDBBtn>
                </article>
            </Service>
        </Scene>
    );
};

export default SshScene;
