/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { MEDIUM_SIZE, ADDRESSES_TYPE_SSH, ADDRESSES_TABLE, SSHD_SOCKETS } from '~frontendConstants/index.js';
import { SocketAddressesFuncContext } from '~frontendRoot/components/SocketAddresses/index.ts';
import SocketAddresses from '~frontendRoot/components/SocketAddresses/SocketAddresses.tsx';
import { addToNormalize, deleteNormalize, duplicateFromNormalize, setNormalizeOrder } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { getAddressTableSshSelector } from '~frontendRoot/ducks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';


const SshSettings = () => {
    const dispatch = useDispatch();

    const passReorderData = useCallback((value: HlcfgRowId[]) => {
        dispatch(setNormalizeOrder({ value, type: SSHD_SOCKETS }));
    }, [  dispatch ]);


    const add = useCallback((uuid: HlcfgRowId, addingAfter?: boolean) => {
        dispatch(addToNormalize({
            type: ADDRESSES_TABLE,
            addingAfter: typeof addingAfter === 'boolean',
            successText: 'profile:ssh.addressTable.added',
            uuidToAddBefore: uuid,
            typeId: SSHD_SOCKETS,
        }));
    }, [ dispatch ]);


    const copy = useCallback((uuid: HlcfgRowId) => {
        dispatch(duplicateFromNormalize({
            parent: SSHD_SOCKETS,
            uuid: uuid,
        }));
    }, [ dispatch ]);


    const del = useCallback((uuid: HlcfgRowId) => {
        dispatch(deleteNormalize({ type: SSHD_SOCKETS, value: uuid }));
    }, [ dispatch ]);

    return (
        <SocketAddressesFuncContext.Provider value={{
            add, copy, del, passReorderData,
            addressesType: ADDRESSES_TYPE_SSH,
            selector: getAddressTableSshSelector
        }}
        >
            <SocketAddresses
                spacing={MEDIUM_SIZE}
            />
        </SocketAddressesFuncContext.Provider>
    );
};

export default SshSettings;
