/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React from 'react';

import { SingleVersionChanges } from './SingleVersionChanges.js';
import { VersionHeadline } from './VersionHeadline.js';
import { Toggles } from './Toggles.js';


const Changes = ({ t, changes, lang }) => {
    return (
        <div className="changes">
            <VersionHeadline
                main={true}
                t={t}
                version={changes[0]?.version}
            />
            <Toggles t={t} />
            {changes.map((versionChanges, idx) => (
                <SingleVersionChanges
                    key={idx}
                    lang={lang}
                    t={t}
                    versionChanges={versionChanges}
                />
            ))}
        </div>
    );
};
Changes.propTypes = {
    t: PropTypes.func,
    changes: PropTypes.array,
    lang: PropTypes.string,
};


export default Changes;
