/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HLCFG_OFF, REDIRECT_DNS_RULE, REDIRECT_DNS_RULE_UUID  } from '~sharedConstants/index.ts';
import { Switch } from '~frontendComponents/Generic/index.js';
import {
    getDnsServerDnsCatch, setNormalize } from '~frontendDucks/hlcfgEditor/index.js';
import { PROTECTION_PACKET_FILTER_SCENE_PATH } from '~frontendScenes/Protection/scenes/constants.js';


@connect(
    (state) => ({
        dnsCatchValue: getDnsServerDnsCatch(state),
    }),
    {
        setValue: setNormalize,
    }
)
@withTranslation()
class DnsCatch extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            dnsCatchValue: PropTypes.bool,
            setValue: PropTypes.func,
        };
    }


    onChangeEvent = ({ value }) => {
        const { setValue } = this.props;
        setValue({
            key: HLCFG_OFF,
            value: !value,
            uuid: REDIRECT_DNS_RULE_UUID
        });
    };

    render() {
        const { t, dnsCatchValue } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    {t('widgets:Dns.dnsCatch.title')}
                </MDBCardHeader>
                <MDBCardBody>
                    {t('widgets:Dns.dnsCatch.desc')}
                    <Switch
                        align="spaceBetween"
                        checked={dnsCatchValue}
                        id="dnsCatchId"
                        label={t('widgets:Dns.dnsCatch.title')}
                        name="dnsCatch"
                        onChange={this.onChangeEvent}
                    />
                    {t('widgets:Dns.dnsRules.desc1')}
                    <div
                        className="d-inline-block"
                    >
                        <Link to={{
                            pathname: PROTECTION_PACKET_FILTER_SCENE_PATH,
                            state: {
                                openFromLink: true
                            } }}
                        >
                            {REDIRECT_DNS_RULE}
                        </Link>
                    </div>
                    {t('widgets:Dns.dnsRules.desc2')}
                </MDBCardBody>

            </MDBCard>
        );
    }
}

export default DnsCatch;
