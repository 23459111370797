/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Input, Loading, Modal } from '~frontendComponents/Generic/index.js';
import { CERTIFICATION_EXPIRATION } from '~frontendConstants/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { getDataKeyHook, getDataKeys, getIsLoading } from '~frontendRoot/ducks/certificationExpiration/index.js';
import { getGlcfgTree, getHlcfgSchema, getIncomprehensibleHlcfgNodes } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { HlcfgContext } from '~frontendRoot/layout/Application/components/Differs/Differs.tsx';
import DiffersPath from '~frontendRoot/layout/Application/components/Differs/DiffersPath.tsx';
import { transformGlcfgToHlcfg } from '~frontendRoot/lib/glcfgTransformers/index.js';
import { findTranslationByHlcfgPath } from '~sharedLib/hlcfg/findTranslationByHlcfgPath.ts';
import { HlcfgPath } from '~sharedLib/types.ts';
import Loader from '~frontendComponents/Loader/Loader.tsx';


const CertificationExpirationSingle = ({ item }: {item: string}) => {
    const certificate = useSelector(getDataKeyHook(item));
    const { t } = useTranslation();
    const translationLine = findTranslationByHlcfgPath(certificate?.hlcfgRealPath as HlcfgPath);
    return (
        <div
            className={classNames('certificationExpiration__single')}
            key={item}
        >
            <Input
                className={classNames('mb-2',
                    { 'certificationExpiration--warning': certificate.warning },
                    { 'certificationExpiration--error': certificate.error },)
                }
                disabled={true}
                expirationTime={certificate.expirationDate}
                fake={true}
                fakeFile={certificate?.locator.name}
                id={item}
                label={t(translationLine)}
                maxSize={2000000}
                name={item + 'name'}
                onChange={() => null}
                outline={false}
                type="file"
            />

            <DiffersPath
                cutLast={false}
                notClicable={false}
                paths={certificate?.descriptiveHlcfgPath || []}
            />

        </div>
    );
};

const CertificationExpirationMap = ({ data, isLoading }: {data: string[], isLoading: boolean}) => {
    const { t } = useTranslation();

    return (
        <Loading
            isLoading={isLoading}
        >
            <span>{t('widgets:CertificationExpiration.modal.body')}</span>
            <div className="certificationExpiration">

                {data.map(item => CertificationExpirationSingle({ item }))}
            </div>
        </Loading>
    );
};

const CertificationExpiration = () => {
    const data = useSelector(getDataKeys);
    const glcfgTree = useSelector(getGlcfgTree);
    const incomprehensibleHlcfgNodes = useSelector(getIncomprehensibleHlcfgNodes);
    const hlcfgTree = transformGlcfgToHlcfg(glcfgTree, incomprehensibleHlcfgNodes);
    const schema = useSelector(getHlcfgSchema);
    const isLoading = useSelector(getIsLoading);

    const dispatch = useDispatch();
    const confirm = () => {
        dispatch(setModalState({ modal: CERTIFICATION_EXPIRATION, value: false }));
    };

    const close = () => {
        dispatch(setModalState({ modal: CERTIFICATION_EXPIRATION, value: false }));
    };
    return (
        <Modal
            body={
                schema ? (
                    <HlcfgContext.Provider value={{ hlcfg: hlcfgTree, schema }}>
                        <CertificationExpirationMap
                            data={data}
                            isLoading={isLoading}
                        />
                    </HlcfgContext.Provider>
                ) : <Loader />
            }
            exitHandle={close}
            headerClose={true}
            headerText={'widgets:CertificationExpiration.modal.header'}
            modalOpen={true}
            negativeResponse={close}
            position="top-right"
            positiveResponse={confirm}
            positiveText={'widgets:CertificationExpiration.modal.positive'}
            size="fluid"
            withoutNegative
        />
    );
};

export default CertificationExpiration;
