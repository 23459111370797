/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCollapse, MDBFormInline, MDBNavbar, MDBNavbarBrand, MDBModalFooter, MDBBtn  } from 'mdbreact';
import PropTypes from 'prop-types';
import { withTranslation, useTranslation } from 'react-i18next';
import { Component } from 'react';
import { connect, useSelector } from 'react-redux';

import Logo from '~frontendComponents/Logo/index.js';
import { Modal, Tooltip } from '~frontendComponents/Generic/index.js';
import { SYSTEM_UPGRADE_STATE_PREPARED,  SYSTEM_UPGRADE_STATE_FAILURE, } from '~commonLib/constants.ts';
import { BACKUP, CERTIFICATION_EXPIRATION, FAILURE, FAULT, MASTER, STOP } from '~frontendConstants/index.js';
import { getGlcfgValue, getIsCluster, getProxyEnabled, resetSessionHlcfgRequest, getNeedsSessionReset } from '~frontendDucks/hlcfgEditor/index.js';
import { getHealthIssues, isNodeUnreachable, getHealthIssuesRequest, getMyNodeState } from '~frontendDucks/clusterSetup/index.js';
import { getCwdbState, getSuricataRulesUpdateState } from '~frontendDucks/protectionDatabases/index.js';
import { getHostname } from '~frontendDucks/systemInfo/index.js';
import { isData } from '~frontendRoot/ducks/imInAF/index.js';
import { getRecoveryMode } from '~frontendRoot/ducks/backup/index.js';
import ButtonWithLock from '~frontendComponents/Generic/ButtonWithLock/index.js';
import { iconShouldBeShown } from '~frontendRoot/ducks/certificationExpiration/index.js';
import { setModalState } from '~frontendRoot/ducks/modals/index.js';
import { useExpectedExceptionsComponents } from '~frontendDucks/systemComponents/systemComponents.ts';
import {
    useUpgradeCommitMutation, useUpgradeDiscardMutation, useUpgradeStateIsOfTypeQuery
} from '~frontendQueries/upgrade/hooks.ts';
import {
    useSystemComponentsListQuery, useSystemComponentStatusQuery
} from '~frontendQueries/system/hooks.ts';
import { getHlcfgOffableIsEnabled } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';

import NavBarIcon from '../NavBarIcon/NavBarIcon.tsx';
import CfgActivationButton from '../Header/components/CfgActivationButton/index.js';
import { useIsAnyUnknownInterface } from './hooks.ts';


// TODO: Make this dynamicaly from site map (navigation)

const capitalize = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);


export const getTranslationFromReactPath = (reactPath, translator) => {
    const values = reactPath.split('/');
    values.shift();
    const sceneNames = values.map(item => capitalize(item));
    if (sceneNames[2]) {
        // Lord have merce on my soul
        return `${translator(`scenes:scenes.${sceneNames[0]}.scenes.${sceneNames[1]}.title`,
            { defaultValue: translator('widgets:global.notFound') })} / ${translator(
            `scenes:scenes.${sceneNames[0]}.scenes.${sceneNames[1]}.scenes.${sceneNames[2]}.title`
            , { defaultValue: translator('widgets:global.notFound') }
        )}`;
    }
    if (sceneNames[0]) {
        return translator(`scenes:scenes.${sceneNames[0]}.scenes.${sceneNames[1]}.title`,
            { defaultValue: translator('widgets:global.notFound') });
    }
    return '';
};

@withTranslation()
@connect(
    state => ({
        healthIssues: getHealthIssues(state),
        nodeUnreachable: isNodeUnreachable(state),
        isCluster: getIsCluster(state),
        myNodeState: getMyNodeState(state),
        cwdbState: getCwdbState(state),
        proxyEnabled: getProxyEnabled(state),
        suricataEnabled: getHlcfgOffableIsEnabled(state, it => it.protection.suricata.service),
        suricataStatus: getSuricataRulesUpdateState(state),
        hostname: getHostname(state),
        amIInAf: isData(state),
        needsSessionReset: getNeedsSessionReset(state),
        recoveryMode: getRecoveryMode(state),
        iconShouldBeShown: iconShouldBeShown(state)

    }), {
        resetSessionHlcfg: resetSessionHlcfgRequest,
        getHealthIssues: getHealthIssuesRequest,
        setModalState: setModalState
    }
)

class NavBarTop extends Component {
    static get propTypes() {
        return {
            isCollapsed: PropTypes.bool.isRequired,
            isTreeDirty: PropTypes.bool.isRequired,
            t: PropTypes.func,
            getHealthIssues: PropTypes.func,
            healthIssues: PropTypes.array,
            nodeUnreachable: PropTypes.bool,
            isCluster: PropTypes.bool,
            cwdbState: PropTypes.string,
            proxyEnabled: PropTypes.bool,
            suricataEnabled: PropTypes.bool,
            suricataStatus: PropTypes.string,
            hostname: PropTypes.string,
            amIInAf: PropTypes.bool,
            resetSessionHlcfg: PropTypes.func,
            needsSessionReset: PropTypes.bool,
            recoveryMode: PropTypes.bool,
            iconShouldBeShown: PropTypes.bool,
            setModalState: PropTypes.func,
            myNodeState: PropTypes.string
        };
    }

    componentDidUpdate() {
        const { isCluster, getHealthIssues } = this.props;
        if (isCluster) {
            // This was under condition if route has changed. Which was needlessly causing re-renders of whole
            // NavBarTop so was removed.
            // If this now causes issues, it should be moved to its own component dedicated to doing
            // things when route has changed.
            getHealthIssues();
        }
    }

    openModal = () => {
        const { setModalState } = this.props;
        setModalState({ modal: CERTIFICATION_EXPIRATION, value: true });
    };

    static defaultProps = {
        isCollapsed: false,
    };

    render() {
        const { t, isCollapsed, isTreeDirty,
            healthIssues, nodeUnreachable,
            myNodeState, cwdbState, proxyEnabled, suricataEnabled, suricataStatus,
            hostname, amIInAf, resetSessionHlcfg,
            needsSessionReset, recoveryMode, iconShouldBeShown
        } = this.props;
        return (
            <>
                <MDBNavbar
                    className="navbar--horizontal"
                    color="default-color-dark"
                    dark
                    expand="md"
                >
                    <MDBNavbarBrand>
                        <Logo hostname={hostname} />
                    </MDBNavbarBrand>
                    <MDBCollapse
                        id="navbarCollapse"
                        isOpen={isCollapsed}
                        navbar
                    >
                        <MDBFormInline
                            className="searchForm"
                        >
                            {/* // Not used and makes some error in console
                            showTodos ?
                                <Select
                                    className="searchForm__select"
                                    dark
                                    id="searchForm__select"

                                    name="searchForm__select"
                                    // TODO: Make this dynamicaly from site map (navigation)
                                    onChange={this.onChangeEvent}
                                    options={selectOptionsList}
                                    placeholder={'navigation:search.title'}
                                /> :
                                null
                                */
                            }
                        </MDBFormInline>
                        {(needsSessionReset || recoveryMode) &&
                        <div className="navbarResetBtn">
                            <Tooltip content={t(`navbar:${recoveryMode ? 'recovery' : 'reset'}.desc`)}>

                                <ButtonWithLock
                                    color={recoveryMode ? undefined : ' primary'}
                                    messageOnLocked={`navbar:${recoveryMode ? 'recovery' : 'reset'}.title`}
                                    messageOnOpen={`navbar:${recoveryMode ? 'recovery' : 'reset'}.title`}
                                    onClick={() => resetSessionHlcfg()}
                                />
                            </Tooltip>
                        </div>}
                        <div className="navbarIcons">
                            <div className="navbarIcons__container">
                                <NavBarIcon
                                    represents="ClusterUnreachable"
                                    shouldDisplay={nodeUnreachable && !!healthIssues.length}
                                />
                                <NavBarIcon
                                    represents="SyncProblems"
                                    shouldDisplay={!nodeUnreachable && !!healthIssues.length}
                                />
                                <UpgradePreparedIcon />
                                <ExpectedExceptionsComponentsIcon />
                                <CallhomeIcon />
                                <SystemComponentsLoadingErrorIcon />
                                <NavBarIcon
                                    represents="SuricataError"
                                    shouldDisplay={suricataEnabled && suricataStatus === 'error'}
                                />
                                <NavBarIcon
                                    represents="SuricataWarning"
                                    shouldDisplay={suricataEnabled && suricataStatus === 'warning'}
                                />
                                <IsAnyUnknownIcon />
                                <NavBarIcon
                                    represents="ProxyError"
                                    shouldDisplay={proxyEnabled && cwdbState === 'error'}
                                />
                                <NavBarIcon
                                    represents="ProxyWarning"
                                    shouldDisplay={proxyEnabled && cwdbState === 'warning'}
                                />
                                <NavBarIcon
                                    represents="MyNodeIsMaster"
                                    shouldDisplay={myNodeState === MASTER}
                                />
                                <NavBarIcon
                                    represents="MyNodeIsBackup"
                                    shouldDisplay={myNodeState === BACKUP}
                                />
                                <NavBarIcon
                                    represents="MyNodeHasFault"
                                    shouldDisplay={myNodeState === FAULT}
                                />
                                <NavBarIcon
                                    represents="MyNodeHasStopped"
                                    shouldDisplay={myNodeState === STOP ||
                                    myNodeState === FAILURE}
                                />
                                <NavBarIcon
                                    represents="IAmInAF"
                                    shouldDisplay={amIInAf}
                                />
                                <NavBarIcon
                                    onClick={this.openModal}
                                    represents="CertificationExpiration"
                                    shouldDisplay={iconShouldBeShown}
                                />

                            </div>
                            <CfgActivationButton
                                isTreeDirty={isTreeDirty}
                            />
                        </div>
                    </MDBCollapse>
                </MDBNavbar>
                <UpgradeFailedModal />
            </>
        );
    }
}


const UpgradeFailedModal = () => {
    const {
        isLoading: stateLoading, data: isUpgradeFailed
    } = useUpgradeStateIsOfTypeQuery(SYSTEM_UPGRADE_STATE_FAILURE);
    const isFiveMinuteTimer = useSelector(state => getGlcfgValue(state, 'upgrade').isFiveMinuteTimer);
    const { mutate: discardUpgrade, isLoading: discardingLoading } = useUpgradeDiscardMutation();
    const { mutate: commitUpgrade, isLoading: commitLoading } = useUpgradeCommitMutation();
    const isLoading = stateLoading || discardingLoading || commitLoading;
    const { t } = useTranslation();
    return (
        <Modal
            bodyText="upgrade:failed.desc"
            headerText="upgrade:failed.title"
            modalOpen={isUpgradeFailed}
            position="top-right"
        >
            <MDBModalFooter>
                <MDBBtn
                    color="secondary"
                    disabled={isLoading}
                    loading={discardingLoading}
                    onClick={event => {
                        event.preventDefault();
                        discardUpgrade();
                    }}
                >
                    {t('upgrade:failed.discard')}
                </MDBBtn>
                <MDBBtn
                    color="primary"
                    disabled={isLoading}
                    loading={commitLoading}
                    onClick={event => {
                        event.preventDefault();
                        commitUpgrade({ timeout: isFiveMinuteTimer ? 5 : 20 });
                    }}
                >
                    {t('upgrade:failed.tryAgain')}
                </MDBBtn>
            </MDBModalFooter>
        </Modal>
    );
};
const IsAnyUnknownIcon = () => {
    const isAnyUnknow = useIsAnyUnknownInterface();
    return (
        <NavBarIcon
            represents="Unknown"
            shouldDisplay={isAnyUnknow}
        />
    );
};
const CallhomeIcon = () => {
    const callhomeStatus = useSystemComponentStatusQuery('callhome');
    return (
        <>
            <NavBarIcon
                represents="CallHomeOn"
                shouldDisplay={callhomeStatus.data?.code === 0}
            />
            <NavBarIcon
                represents="CallHomeOff"
                shouldDisplay={callhomeStatus.data?.code !== 0}
            />
        </>
    );
};
const SystemComponentsLoadingErrorIcon = () => {
    const loadingError = useSystemComponentsListQuery().isError;
    return (
        <NavBarIcon
            represents="LoadingError"
            shouldDisplay={loadingError}
        />
    );
};
const ExpectedExceptionsComponentsIcon = () => {
    const expectedExceptionsComponents = useExpectedExceptionsComponents();
    return (
        <NavBarIcon
            represents="ExpectedExceptionsComponents"
            shouldDisplay={expectedExceptionsComponents.length}
        />
    );
};
const UpgradePreparedIcon = () => {
    const { data: isUpgradePrepared } = useUpgradeStateIsOfTypeQuery(SYSTEM_UPGRADE_STATE_PREPARED);
    return (
        <NavBarIcon
            represents="UpgradePrepared"
            shouldDisplay={isUpgradePrepared}
        />
    );
};

export default NavBarTop;
