/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { withTranslation } from 'react-i18next';

import { getCurrentServerTimestamp } from '~frontendDucks/currentTime/currentTime.js';
import { Input, Select } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { getGlcfgSchema, getGlcfgValue, getIsCluster, setGlcfgValueScalar } from '~frontendDucks/hlcfgEditor/index.js';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { typeTimestamp } from '~frontendTypes/index.js';
import moment, { TIME_FORMAT } from '~commonLib/moment.ts';
import { getHealthIssues } from '~frontendDucks/clusterSetup/index.js';
import { ACTION_STEP_NTPD_TIMESTART, timeSyncOpened } from '~frontendDucks/ntpdTimeSync/ntpdTimeSync.ts';
import { setModalState } from '~frontendRoot/ducks/modals/index.js';
import { CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE, NODE_A_ID, NODE_B_ID } from '~frontendRoot/constants/index.js';


@withTranslation()
@connect(
    (state) => ({
        ntp1: getGlcfgValue(state, 'ntp1'),
        ntp2: getGlcfgValue(state, 'ntp2'),
        ntp1Schema: getGlcfgSchema(state, 'ntp1'),
        ntp2Schema: getGlcfgSchema(state, 'ntp2'),
        currentServerTimestamp: getCurrentServerTimestamp(state),
        isCluster: getIsCluster(state),
        healthIssues: getHealthIssues(state)

    }),
    {
        setValue: setGlcfgValueScalar,
        doTimeSyncOpened: timeSyncOpened,
        openSyncOrForThisNodeModal: setModalState,

    }
)
class NtpClient extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            setValue: PropTypes.func,
            ntp1: PropTypes.object,
            ntp1Schema: PropTypes.object,
            ntp2: PropTypes.object,
            ntp2Schema: PropTypes.object,
            currentServerTimestamp: typeTimestamp,
            doTimeSyncOpened: PropTypes.func,
            isCluster: PropTypes.bool,
            healthIssues: PropTypes.array,
            openSyncOrForThisNodeModal: PropTypes.func,
        };
    }

    onChangeEventAddress = ({ value, name }) => {
        const { setValue } = this.props;
        setValue(name, value);
    };

    updateTime = () => {
        const { isCluster, healthIssues, openSyncOrForThisNodeModal, doTimeSyncOpened } = this.props;
        if (isCluster) {
            if (healthIssues.length) {
                openSyncOrForThisNodeModal({
                    modal: CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE, value: true,
                    specialValues: { sequence: ACTION_STEP_NTPD_TIMESTART,
                        action: doTimeSyncOpened }
                });
            } else {
                doTimeSyncOpened({ nodes: [ NODE_A_ID, NODE_B_ID ] });
            }
        } else {
            doTimeSyncOpened({});
        }
    };

    render() {
        const { t, ntp1, ntp1Schema, ntp2, ntp2Schema, currentServerTimestamp } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    <MDBRow>
                        <MDBCol lg="8">
                            {t('widgets:Ntp.title')}
                        </MDBCol>
                        <MDBCol
                            className="device__icons"
                            lg="4"
                        >
                            <IconWithTooltip
                                iconSize="sm"
                                link
                                name="reload"
                                onClick={this.updateTime}
                                tooltipText={t('widgets:Ntp.timeRefresh.title')}
                                withoutTranslation
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardHeader>
                <MDBCardBody>
                    <Input
                        disabled
                        id="actualTime"
                        label={t('widgets:Ntp.time.title')}
                        value={(
                            moment(currentServerTimestamp).format(TIME_FORMAT.userDateTimeFull)
                        )}
                    />
                    <Select
                        id={'ntp1'}
                        isCreatable
                        isMulti

                        name="ntp1"
                        noDropdownIndicator
                        noOptionsMessage
                        onChange={this.onChangeEventAddress}
                        placeholderAsValue
                        schema={ntp1Schema}
                        value={stringifyAddress(ntp1)}
                    />
                    <Select
                        id={'ntp2'}
                        isCreatable
                        isMulti

                        name="ntp2"
                        noDropdownIndicator
                        noOptionsMessage
                        onChange={this.onChangeEventAddress}
                        placeholderAsValue
                        schema={ntp2Schema}
                        value={stringifyAddress(ntp2)}
                    />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default NtpClient;
