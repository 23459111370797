/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { COLOR_PRIMARY } from '../../constants/index.js';
import { loadSuricataRulesRequest, setFilters } from '../../ducks/suricataFilteringTable/suricataFilteringTable.js';
import ButtonWithTooltip from '../ButtonWithTooltip/ButtonWithTooltip.js';
import Message from '../Message/index.js';


const STYLE_SID_REDIRECT = {
    borderColor: 'transparent',
    color: COLOR_PRIMARY
};

const SidRedirectBase = withRouter(({ doLoadRules, doSetFilter, history, onClick, sidrev }) => {
    if (!sidrev) {
        return null;
    }
    const filters = {
        sidrev: sidrev
    };
    return (
        <ButtonWithTooltip
            desc={<Message message="ips:SuricataRulesList.sidRedirectionButton.desc" />}
            onClick={() => {
                doSetFilter(filters);
                history.push('/protection/ips');
                doLoadRules({ filters });
                if (onClick) {
                    onClick();
                }
            }}
            showTitleInDesc={false}
            size="small"
            style={STYLE_SID_REDIRECT}
            title={sidrev}
        />
    );
});

SidRedirectBase.propTypes = {
    doLoadRules: PropTypes.func,
    doSetFilter: PropTypes.func,
    onClick: PropTypes.func,
    sidrev: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    doLoadRules: loadSuricataRulesRequest,
    doSetFilter: setFilters,
};


const SidRedirect = connect(mapStateToProps, mapDispatchToProps)(SidRedirectBase);

SidRedirect.propTypes = {
    onClick: PropTypes.func,
    sidrev: PropTypes.string,
};

export default SidRedirect;
