/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';

import { Switch } from '~frontendComponents/Generic/index.js';
import { useHlcfgOffableValue, useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import Message from '~frontendComponents/Message/Message.tsx';


const SshKernunTech = () => {
    const ssh = useHlcfgOffableValue(it => it.services.sshd);
    const sshTech = useHlcfgValue(it => it.services.sshd.kernunTechnicians);
    return (
        <Switch
            align="spaceBetween"
            checked={sshTech.value}
            datacy="techAccessEnabled"
            disabled={ssh.isOff}
            id="techAccessEnabled"
            label={<Message message="widgets:Sshd.techAccess.title" />}
            onChange={({ value }) => sshTech.setValue(value)}

        />
    );
};


export default SshKernunTech;
