/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Select } from '~frontendComponents/Generic/index.js';
import { setNormalize
} from '~frontendDucks/hlcfgEditor/index.js';
import { DHCP_VALUE, NODE_A_LETTER, NODE_B_LETTER, HW_TYPE } from '~frontendConstants/index.js';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';
import {
    INTERFACES_COLUMNS_STATE,
    INTERFACES_COLUMNS_TYPE,
    INTERFACES_COLUMNS_NAME,
    INTERFACES_COLUMNS_IP4,
    INTERFACES_COLUMNS_WANLAN,
    INTERFACES_COLUMNS_VLANIFACE,
    INTERFACES_COLUMNS_ANTISPOOFING,
    INTERFACES_COLUMNS_IP6,
    INTERFACES_COLUMNS_MAC,
    SELECTABLE_TABLE_INTERFACES,
    userSetting,
    TableSizeType,
    INTERFACES_COLUMNS_PHYSICALIFACE,
    INTERFACES_COLUMNS_TAG,
    INTERFACES_COLUMNS_BOND_MODE,
} from '~frontendConstants/constants.ts';
import { useNetworkInterfacesFromNode } from '~frontendDucks/networkInterfaces.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { NODE_A_ID, NODE_B_ID } from '~sharedConstants/index.ts';
import { getColumnsUtils } from '~frontendRoot/lib/columnUtils.ts';
import { useUserSetting } from '~frontendRoot/lib/hooks/userSettings.ts';
import { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { ClusterOwnSelector } from '~commonLib/constants.ts';
import { HlcfgTableItemSchema } from '~frontendTypes/externalTypes.ts';

import { getIcon } from '../rowsUtils.js';


export const optionsFromHwDevices = (devices) => {
    return (devices || []).map(item => ({ label: item.name, value: item.name }));
};

type ClusterRowProps = {
    data: any; //HwIface + hwNetworkInterface
    uuid: HlcfgRowId;
    spacing: TableSizeType;
    needsUpdate: boolean;
    node: ClusterOwnSelector;
    matches: string[];
    schema: HlcfgTableItemSchema<'hwIface'>
}
const ClusterRow = ({
    data,
    uuid,
    spacing,
    needsUpdate,
    node,
    matches,
    schema }: ClusterRowProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ getColumnsShow ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_INTERFACES);
    const hwDevices = useNetworkInterfacesFromNode(node);
    const { name, address, address6, device,
        error, mac, dhcp, stateA, stateB, physicalLayerUpA, physicalLayerUpB,
        type } = data;

    const changeValueInterface = useCallback(({ value, name, id  }) => {
        dispatch(setNormalize({ value, uuid, key: name, subkey: id }));
    }, [ dispatch, uuid ]);

    const changeValueMac = useCallback(({ value, name, id  }) => {
        dispatch(setNormalize({ value: value[0]?.toLowerCase(), uuid, key: name, subkey: id }));
    }, [ dispatch, uuid ]);

    const changeValueNetAddr = useCallback(({ value, name, id  }) => {
        dispatch(setNormalize({ value, uuid, key: name, subkey: id }));
    }, [ dispatch, uuid ]);

    const changeValueNetAddrWithDhcp = useCallback(({ value, name, id  }) => {
        if (dhcp || value.includes(DHCP_VALUE)) {
            dispatch(setNormalize({ value: !dhcp, uuid, key: 'dhcp' }));
            return;
        }
        dispatch(setNormalize({ value: value.map(netaddr), uuid, key: name, subkey: id }));
    }, [ dispatch, uuid, dhcp ]);


    const physicalLayerUp = node === NODE_A_ID ? physicalLayerUpA : physicalLayerUpB;


    return (
        <tr
            className={classNames(
                'dataTableWidget__Row',
                { 'dataTableWidget__Row--match': matches },
                { 'dataTableWidget__Row--warning': needsUpdate },
                { 'dataTableWidget__Row--error': error },
                { [`dataTableWidget__cell--${spacing}`]: spacing },
                'network__row'
            )}
            key={uuid + node}
        >
            {getColumnsShow(INTERFACES_COLUMNS_STATE) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    'dataTableWidget__cell--icon',
                    { 'dataTableWidget__cell--error': error },
                    { 'dataTableWidget__cell--match': matches },
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >
                {
                    getIcon(node === NODE_A_ID ? stateA : stateB)
                }
                {
                    getIcon(physicalLayerUp ? physicalLayerUp ? 'connected' : 'disconnected' : '')
                }
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_TYPE) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    'dataTableWidget__cell--icon',
                    'dataTableWidget__cell--textAsIcon',
                    { 'dataTableWidget__cell--error': error },
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >
                <TextWithTooltip
                    className="textAsIcon textAsIcon--textColor"
                    text={node === NODE_A_ID ? NODE_A_LETTER : NODE_B_LETTER}
                    tooltipText={t(`widgets:network.cluster.${node}`)}
                    withoutTranslation
                />
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_NAME) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >{type === HW_TYPE &&
                <Select
                    className={classNames(
                        'dataTableWidget__RowInput',
                        'network__rowCell--clusterHw',
                        { 'h-2': name },
                    )}
                    id={node === NODE_A_ID ? NODE_A_ID : NODE_B_ID}
                    isRow
                    name="device"
                    noDropdownIndicator
                    noOptionsMessage
                    onChange={changeValueInterface}
                    options={optionsFromHwDevices(hwDevices)}
                    paste={false}
                    value={device?.[node === NODE_A_ID ? NODE_A_ID : NODE_B_ID]}
                />

                }
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_IP4) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >
                <Select
                    className="dataTableWidget__RowInput"
                    dhcp={dhcp}
                    id={node === NODE_A_ID ? NODE_A_ID : NODE_B_ID}
                    isCreatable={!dhcp}
                    isMulti
                    isRow
                    name="address"
                    noDropdownIndicator
                    noOptionsMessage
                    onChange={changeValueNetAddrWithDhcp}
                    options={[ { label: 'DHCP', value: DHCP_VALUE } ]}
                    schema={schema?.address?.additionalProperties}
                    value={dhcp ?
                        [ DHCP_VALUE ].concat(address[node]) :
                        address?.[node]}
                />
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_WANLAN) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                    'network__rowCell--rightBorder',
                )}
            >
                <IconWithTooltip
                    className="icon--secondary ml-2"
                    iconSize="sx"
                    link
                    name="minus"
                    tooltipText="widgets:network.wrongType"
                />
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_TAG) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                    'network__rowCell--leftPadding',
                )}
            >
                <IconWithTooltip
                    className="icon--secondary"
                    iconSize="sx"
                    link
                    name="minus"
                    tooltipText="widgets:network.wrongType"
                />
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_BOND_MODE) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                    'network__rowCell--leftPadding',
                )}
            >
                <IconWithTooltip
                    className="icon--secondary"
                    iconSize="sx"
                    link
                    name="minus"
                    tooltipText="widgets:network.wrongType"
                />
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_VLANIFACE) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >
                <IconWithTooltip
                    className="icon--secondary ml-2"
                    iconSize="sx"
                    link
                    name="minus"
                    tooltipText="widgets:network.wrongType"
                />
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_PHYSICALIFACE) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >
                <IconWithTooltip
                    className="icon--secondary ml-2"
                    iconSize="sx"
                    link
                    name="minus"
                    tooltipText="widgets:network.wrongType"
                />
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_ANTISPOOFING) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >
                <IconWithTooltip
                    className="icon--secondary ml-2"
                    iconSize="sx"
                    link
                    name="minus"
                    tooltipText="widgets:network.wrongType"
                />
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_IP6) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                )}
            >
                <Select
                    className="dataTableWidget__RowInput"
                    id={node === NODE_A_ID ? NODE_A_ID : NODE_B_ID}
                    isCreatable
                    isMulti
                    isRow
                    name="address6"
                    noDropdownIndicator
                    noOptionsMessage
                    onChange={changeValueNetAddr}
                    schema={schema?.address6?.additionalProperties}
                    value={(address6?.[node])}
                />
            </td>
            }
            {getColumnsShow(INTERFACES_COLUMNS_MAC) &&
            <td
                className={classNames(
                    'dataTableWidget__cell',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
            >
                <Select
                    className="dataTableWidget__RowInput"
                    id={node === NODE_A_ID ? NODE_A_ID : NODE_B_ID}
                    isCreatable={mac?.[node] === ''}
                    isMulti
                    isRow
                    name="mac"
                    noDropdownIndicator
                    noOptionsMessage
                    onChange={changeValueMac}
                    schema={schema.mac}
                    value={mac?.[node] ? [ mac[node] ] : []}
                />
            </td>
            }
            <td
                colSpan={4}
            >
            </td>
        </tr>
    );
};


export default ClusterRow;
