/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useState, useMemo, memo, createContext, useContext, ReactNode, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import classNames from 'classnames';

import {
    setNormalize,
    deleteNormalize,
    addToNormalize,
    closeHeader,
    makeSelectPacketFilterIsFake,
    duplicateStartPF
} from '~frontendDucks/hlcfgEditor/index.js';
import { NEW_ROW_CONSTANT, PACKET_FILTER_RULES, SECOND_DEFAULT_HEADER_UUID, SELECTABLE_TABLE_PACKET_FILTER, userSetting } from '~frontendConstants/index.js';
import type { RowDividerTypes } from '~frontendComponents/RowDivider.tsx';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import type { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import Loader from '~frontendComponents/Loader/index.js';
import RowDivider from '~frontendComponents/RowDivider.tsx';
import { useUserSetting } from '~frontendLib/hooks/userSettings.ts';
import { getColumnsUtils } from '~frontendLib/columnUtils.ts';

import { RenderNftDivider, RenderNftRule, RenderEmptyUserRules } from './renderers.tsx';


export const BatchRenderCtx = createContext({ maxIndexToRender: 0 });

export const BatchRenderWrapper = ({ children, maxCount, initialCount, step }: {
    children: ReactNode, maxCount: number, initialCount: number, step: number,
}) => {
    const [ toRender, setToRender ] = useState(initialCount);
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (toRender < maxCount) {
                setToRender(toRender + step);
            }
        }, 1);
        return () => {
            clearTimeout(timeout);
        };
    }, [ toRender, maxCount, step ]);
    return <BatchRenderCtx.Provider value={{ maxIndexToRender: toRender }}>{children}</BatchRenderCtx.Provider>;
};

const RowNoMemo = ({ uuid, dataIndex, spacing, search }: CreateRowPropsType) => {
    const dispatch = useDispatch();
    const getter = useMemo(makeSelectPacketFilterIsFake, []);
    const fake = useSelector(state => getter(state, uuid));

    const onChangeClosed = useCallback(({ value }) => {
        dispatch(closeHeader({ value, uuid: uuid }));

    }, [ uuid, dispatch ]);

    const setValue = useCallback(({ value, name }) => {
        dispatch(setNormalize({
            uuid,
            key: name,
            value,
        }));
    }, [ uuid, dispatch ]);

    const setValueId = useCallback(({ value, name, id }) => {
        dispatch(setNormalize({
            uuid,
            key: name,
            subkey: id,
            value,
        }));
    }, [ uuid, dispatch ]);

    const onChangeDestinationTranslationEvent = useCallback(({ empty, name }) => {
        dispatch(setNormalize({
            uuid,
            key: name,
            subkey: '__off',
            value: !!empty,
        }));

    }, [ uuid, dispatch ]);


    const addRowHeader = useCallback(() => {
        dispatch(addToNormalize({
            type: 'nftDivider', //just one type
            typeId: PACKET_FILTER_RULES,
            uuid: uuid,
            uuidToAddBefore: fake ? SECOND_DEFAULT_HEADER_UUID :
                uuid || SECOND_DEFAULT_HEADER_UUID,
            successText: 'packetFilter:added',
        }));

    }, [ dispatch, fake, uuid ]);

    const addRow = useCallback(() => {
        dispatch(addToNormalize({
            type: 'nftRule',
            typeId: PACKET_FILTER_RULES,
            uuidToAddBefore: fake ? SECOND_DEFAULT_HEADER_UUID :
                uuid || SECOND_DEFAULT_HEADER_UUID,
            successText: 'packetFilter:added',
        }));
    }, [ uuid, dispatch, fake ]);

    const copyRow = useCallback(() => {
        dispatch(duplicateStartPF(uuid));
    }, [ dispatch, uuid ]);

    const delRow = useCallback(() => {
        dispatch(deleteNormalize({ type: PACKET_FILTER_RULES, value: uuid }));
    }, [ uuid, dispatch ]);

    const [ types ] = useState<RowDividerTypes>([
        { addFunc: addRow, translation: 'profile:profiles.rules.rule' },
        { addFunc: addRowHeader, translation: 'packetFilter:header' }
    ]);

    if (uuid === NEW_ROW_CONSTANT) {
        return (
            <RenderEmptyUserRules
                add={addRow}
                addHeader={addRowHeader}
                uuid={uuid}
            />
        );
    }

    return (
        <Draggable
            draggableId={uuid}
            index={dataIndex}
            isDragDisabled={fake}
            key={uuid}
        >
            {(provided: DraggableProvided) =>  {
                if (hlcfgTableNameByRowId(uuid) === 'nftRule') {
                    return (
                        <RenderNftRule

                            copyRow={copyRow}
                            delRow={delRow}
                            onChangeDestinationTranslationEvent={onChangeDestinationTranslationEvent}
                            provided={provided}
                            search={search}
                            setValue={setValue}
                            setValueId={setValueId}
                            spacing={spacing}
                            types={types}
                            uuid={uuid}
                        />
                    );
                }
                return (
                    <RenderNftDivider

                        closeRules={onChangeClosed}
                        delRow={delRow}
                        provided={provided}
                        search={search}
                        setValue={setValue}
                        spacing={spacing}
                        types={types}
                        uuid={uuid}
                    />
                );
            }
            }
        </Draggable>
    );
};
const Row = memo(RowNoMemo);


const RowLoaderNoMemo = (props: Pick<CreateRowPropsType, 'uuid'|'spacing'>) => {
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_PACKET_FILTER);
    return (
        <>
            <RowDivider
                className={'dataTableWidget__RowAddPFButtons'}
                id={props.uuid}
                length={getColumnsSelectedLength()}
                types={[]}
            />
            <tr
                className={classNames(
                    'dataTableWidget__Row',
                    'packetFilter__edit',
                    'dataTableWidget__Row--disable',

                )}
            >
                <td
                    className={`dataTableWidget__cell--${props.spacing}`}
                    colSpan={getColumnsSelectedLength()}
                >
                    <Loader
                        className="relative"
                        // 24 is regular 1.5 rem, which is sm mode.
                        // Not the best solution, as it will kinda mess up heights if somebody zooms-out
                        // But the row will just expand to fit the loader SVG. So it works.
                        // Also it will kinda mess up in lg mode, as the loader rows will be smaller.
                        // But the lg mode has very loosely defined heights, so no sensible conversion works...
                        height={24}
                        width={24}
                    />
                </td>
            </tr>
        </>
    );
};
const RowLoader = React.memo(RowLoaderNoMemo);
const RowBatched = (props: CreateRowPropsType) => {
    const { maxIndexToRender } = useContext(BatchRenderCtx);
    // It is possible index of this row changes when header is collapsed or uncollapsed.
    // It would be wasteful to drop the rendered components if they suddenly had larger index than max index to render.
    const [ rendered, setRendered ] = useState(false);
    const shouldRender = rendered || props.dataIndex <= maxIndexToRender;
    if (!shouldRender) {
        return (
            <RowLoader
                spacing={props.spacing}
                uuid={props.uuid}
            />
        );
    }
    if (!rendered) {
        // Setting state in render is ugly and generally bad,
        // but this somehow speeds up the little renders between actual row renders.
        // And the RowBatched does not display as being rendered.
        // So here we go.
        setRendered(true);
    }
    return <Row {...props} />;
};
export default RowBatched;
