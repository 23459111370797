/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { integer, string, array, object, boolean, enumeration, record } from '~commonLib/schemaUtils.ts';


export const logInitializeParamsSchema = object({
    logSessionId: string({ maxLength: 64 }),
    bootRelative: integer({ maximum: 0 }),
    pids: array(integer({ minimum: 0 })),
    units: array(string()),
    grep: string(),
    grepFlags: object({
        ignoreCase: boolean(),
        invertMatch: boolean(),
        fixedStrings: boolean(),
    }, []),
    logPriority: integer({ maximum: 7, minimum: 0 }),
    sinceTime: string(),
    untilTime: string(),
    lineCount: integer({ minimum: 0 }),
    tailMode: boolean(),
}, []);

export const logLineSchema = object({
    message: string(),
    priority: integer(),
    syslogId: string(),
    bootRelative: integer(),
    hostname: string(),
    timestampUnixMicroS: integer(),
    pid: integer(),
    unit: string(),
}, [ 'message', 'priority', 'pid', 'unit', 'timestampUnixMicroS', 'hostname', 'bootRelative', 'syslogId' ]);
export const windowTypeSchema = enumeration([ 'completed', 'last', 'limiting', 'incomplete', 'errored' ]);
export const dataWindowsForGuiSchema = record(object({
    windowType: windowTypeSchema,
    data: array(logLineSchema),
}, [ 'data', 'windowType' ]));
