/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback } from 'react';


export const useSingleValueSelectWrapper = <T>(props: {onChange: (value: T|undefined) => void, value: T|undefined}) => {
    const { onChange, value } = props;
    return {
        onChange: useCallback((values: T[]) => {
            onChange(values.at(-1));
        }, [ onChange ]),
        value: value === undefined ? [] : [ value ],
        singleValueMode: true,
    };
};
