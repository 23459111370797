/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardHeader } from 'mdbreact';
import { withTranslation } from 'react-i18next';

import { Input, Select } from '~frontendComponents/Generic/index.js';
import  NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import {
    getHoneypot,
    getHoneypotSchema,
    setGlcfgObjectValue
} from '~frontendDucks/hlcfgEditor/index.js';
import Message from '~frontendComponents/Message/index.js';
import { setTimeoutComponentFunction, setTimeoutComponentValidator } from '~frontendLib/timeUtils.ts';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';

import { AddrInfo } from './components/index.ts';


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            honeypot: getHoneypot(state),
            honeypotSchema: getHoneypotSchema(state)


        };
    };
    return mapStateToProps;
};
@withTranslation()
@connect(
    makeMapStateToProps,
    {
        setValue: setGlcfgObjectValue,
    }
)
class HoneypotService extends Component {
    static get propTypes() {
        return {
            setValue: PropTypes.func,
            honeypot: PropTypes.object,
            honeypotSchema: PropTypes.object,
            t: PropTypes.func,
        };
    }

    changeValue = ({ value, name, key }) => {
        const { setValue } = this.props;
        setValue('honeypot', value, name || key);
    };

    changeValueAddress = ({ value, name,  }) => {
        const { setValue } = this.props;
        setValue('honeypot', value, name);
    };

    changeValuePorts = ({ value, name  }) => {
        const { setValue } = this.props;
        setValue('honeypot', value, name);
    };

    setTimeout = setTimeoutComponentFunction({ setValue: this.changeValue });

    timeoutValidator = setTimeoutComponentValidator(this.props.t);

    render() {
        const {
            honeypot, t, honeypotSchema
        } = this.props;
        return (
            <MDBRow>
                <MDBCol
                    className="mb-4"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <MDBCard>
                        <MDBCardHeader>
                            <Message message="widgets:Honeypot.title" />
                        </MDBCardHeader>
                        <MDBCardBody>

                            <NetworkInterfaceDevice
                                id={'addresses'}
                                iface={honeypot.addresses}
                                isCreatable
                                isMulti
                                label={t('widgets:Honeypot.addresses.title')}
                                name="addresses"
                                onChange={this.changeValueAddress}
                                schema={honeypotSchema?.addresses}
                            />

                            <Select
                                id="ports"
                                isCreatable
                                isMulti
                                label={t('widgets:Honeypot.ports.title')}
                                name="ports"
                                onChange={this.changeValuePorts}
                                schema={honeypotSchema?.ports}
                                value={honeypot.ports}
                            />

                            <Input
                                id="blacklistTime"
                                label={t('widgets:Honeypot.blacklistTime.title')}
                                name="blacklistTime"
                                onChange={this.setTimeout}
                                validator={this.timeoutValidator}
                                value={honeypot?.blacklistTime ?
                                    valueFormatter.formatSeconds(honeypot.blacklistTime) : ''}
                            />

                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol
                    className="mb-4"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <AddrInfo />
                </MDBCol>
            </MDBRow>


        );
    }
}

export default HoneypotService;
