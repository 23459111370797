/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBBtn, MDBCol, MDBModalFooter } from 'mdbreact';

import { getModalState, setModalState } from '~frontendRoot/ducks/modals/index.js';
import { DOWNGRADE_CONFIRM_MODAL } from '~frontendRoot/constants/index.js';
import Message from '~frontendRoot/components/Message/index.js';
import { useDowngradeMutation } from '~frontendQueries/upgrade/hooks.ts';

import DeploymentModal from './DeploymentModal.tsx';


export const DowngradeModal = () => {

    const dispatch = useDispatch();

    const downgradeConfirmModal = useSelector(state => getModalState(state, DOWNGRADE_CONFIRM_MODAL));
    const { mutate: commitDowngrade } = useDowngradeMutation();

    const onDowngrade = () => {
        commitDowngrade({});
        closeModal();
    };

    const closeModal = () => {
        dispatch(setModalState({ modal: DOWNGRADE_CONFIRM_MODAL, value: false }));
    };

    return (
        <DeploymentModal
            className="packetFilter__modal"
            headerText="upgrade:downgrade.header"
            modalOpen={downgradeConfirmModal}
            type={DOWNGRADE_CONFIRM_MODAL}
        >
            <MDBCol>
                <p className="infobox infobox--info mt-3 mb-3">
                    <Message message="upgrade:downgrade.desc" />
                </p>
            </MDBCol>
            <MDBModalFooter className="authentication__footer">
                <MDBBtn
                    color="secondary"
                    onClick={closeModal}
                >
                    <Message message="widgets:global.back" />
                </MDBBtn>
                <div>
                    <MDBBtn
                        color="primary"
                        onClick={onDowngrade}
                    >
                        <Message message="upgrade:downgrade.button" />
                    </MDBBtn>
                </div>
            </MDBModalFooter>
        </DeploymentModal>
    );
};
