/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {  MDBBtn } from 'mdbreact';
import { Draggable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {  Icon, Select, Input } from '~frontendComponents/Generic/index.js';
import {
    setNormalize,
    getPoolByUuid,
    getLeasesUuid,
    getTableSchema,
    addToNormalize,
    duplicateFromNormalize,
} from '~frontendDucks/hlcfgEditor/index.js';
import {
    DHCPD_RULE_COLUMNS_DRAG,
    DHCPD_RULE_COLUMNS_RANGETO,
    DHCPD_RULE_COLUMNS_RANGEFROM,
    DHCPD_RULE_COLUMNS_MAC,
    DHCPD_RULE_COLUMNS_ADDRESS,
    DHCPD_RULE_COLUMNS_COMMENT,
    DHCPD_RULE_COLUMNS_HOSTNAME,
    ACTUAL_LEASES_TYPE,
    NEW_ROW_CONSTANT,
    DHCPD_RULE_COLUMNS_ACTUAL_MAC,
    DHCPD_RULE_COLUMNS_ACTUAL_ADDRESS,
    POOL_TYPE,
    SELECTABLE_COLUMNS,
    userSetting
} from '~frontendConstants/constants.ts';
import { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { getColumnsShow } from '~frontendDucks/selectedColumns/index.js';
import { parseAddress } from '~frontendLib/addressUtils.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import RowMenuAndSwitch from '~frontendComponents/RowMenuAndSwitch.tsx';
import RowDivider, { RowDividerTypes } from '~frontendComponents/RowDivider.tsx';
import { makeSelectSearchedTableItem, makeSelectorGetFakeClusterPool } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';
import { ClusterOwnSelector, NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';
import { useUserSetting } from '~frontendLib/hooks/userSettings.ts';
import { getColumnsUtils } from '~frontendLib/columnUtils.ts';
import { useConstant } from '~frontendLib/hooks/defaultHooks.ts';
import { getObjectMatch } from '~frontendRoot/lib/objectUtils.js';
import { netaddr } from '~commonLib/Netaddr/Netaddr.ts';

import { DhcpdDatatableContext } from '../../../DhcpdDatatableContext/index.ts';
import  PoolRow from './PoolRow.tsx';


const Row = ({ spacing, dataIndex, search, uuid  }: CreateRowPropsType) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { type, parentUuid } = useContext(DhcpdDatatableContext);

    const poolsOrLeases = useMemo(() => {
        if (type === 'leases') {
            return getLeasesUuid();
        }
        else if (type === 'pools') {
            return getPoolByUuid();
        }
        else {
            const fakeLeases = (state, uuid) => uuid;
            return fakeLeases;
        }
    }, [ type ]);

    const getterSearch = useMemo(() => makeSelectSearchedTableItem([ 'id' ]), []);
    const data = useSelector(state => poolsOrLeases(state, uuid));
    const schemas = useSelector(state => getTableSchema(state, uuid));
    const getFakeClassPool = useMemo(makeSelectorGetFakeClusterPool, []);
    const pools = useSelector((state) => type === POOL_TYPE ? getFakeClassPool(state, uuid, parentUuid) :
        EMPTY_IMMUTABLE_OBJ);
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] = getColumnsUtils(allColumns, type);
    const columns = SELECTABLE_COLUMNS[type];
    const matchesPoolOrStatic = useSelector(state => getterSearch(state, uuid, search));
    const [ matches, setMatches ] = useState<string[]>([]);
    useEffect(() => {
        if (type === 'actualLeases')  {
            setMatches(getObjectMatch({
                object: data, searchValue: search, net: netaddr, exceptions: [], matchesProp: [] }));
        }
        else {
            setMatches(matchesPoolOrStatic);
        }
        // eslint requires also data as dependencies but for optimalization reasons and that we need to implement
        // better way for searching in table we will ignore it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ search ]);
    const changeValue = useCallback(({ value, name }) => {
        dispatch(setNormalize({ value, uuid, key: name }));
    }, [ dispatch, uuid ]);

    const changeValueMac = useCallback(({ value, name }) => {
        dispatch(setNormalize({ value: value?.replaceAll('-', ':').toLowerCase(), uuid, key: name }));
    }, [ dispatch, uuid ]);

    const addPoolOrStatic = useCallback((addingAfter: boolean) => {
        dispatch(addToNormalize({
            type: type === POOL_TYPE ? 'dhcpPool' : 'dhcpLease',
            addingAfter,
            uuidToAddBefore: uuid,
            key: type,
            uuid: parentUuid,
            successText: `widgets:Dhcpd.${type}.added`,
        }));
    }, [ dispatch, uuid, parentUuid, type ]);

    const handleAddPoolOrStatic = useCallback((event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
        event.preventDefault();
        addPoolOrStatic(false);
    }, [ addPoolOrStatic ]);

    const copyRow = useCallback(() => {
        dispatch(duplicateFromNormalize({
            uuid,
            parentUuid,
            parentKey: type,
        }));
    }, [ dispatch, uuid, parentUuid, type ]);


    const addToStatic = useCallback(() => {
        dispatch(addToNormalize({
            type: 'dhcpLease',
            key: 'leases',
            uuid: parentUuid,
            successText: 'widgets:Dhcpd.leases.added',
            extraValues: {
                ip: data.ip ? parseAddress(data.ip[0]) : undefined,
                mac: data.mac[0],
                comment: data.hostname[0]
            }
        }));
    }, [ dispatch, parentUuid, data ]);

    const delRow = useCallback(() => {
        dispatch(setNormalize({ uuid: parentUuid, value: uuid, key: type }));
    }, [ dispatch, uuid, parentUuid, type ]);

    const nodes = [ NODE_A_ID, NODE_B_ID ];

    const types = useConstant<RowDividerTypes>([
        { addFunc: addPoolOrStatic, translation: `widgets:Dhcpd.type.${type}` }
    ]);


    if (uuid === NEW_ROW_CONSTANT) {
        return (
            <tr
                className="dataTableWidget__RowFirstRule"
                key="newRule"
            >
                <td colSpan={1}></td>
                <td
                    className="dataTableWidget__RowFirstRuleRow"
                    colSpan={getColumnsSelectedLength() - 1}

                >
                    <div className="pl-2">
                        <h2>{t(`widgets:Dhcpd.${type}.title`)}</h2>
                        <p className="dataTableWidget__Rowtext">
                            {t(`widgets:Dhcpd.${type}.desc1`)}
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                onClick={handleAddPoolOrStatic}
                            >
                                <Icon name="plus" /> {t(`widgets:Dhcpd.type.${type}`)}
                            </MDBBtn>
                            {t(`widgets:Dhcpd.${type}.desc2`)}
                        </p>
                        <p>
                            {t(`widgets:Dhcpd.${type}.desc3`)}
                        (
                            <Icon
                                name="menu"
                                size="sm"
                            />)
                            {t(`widgets:Dhcpd.${type}.desc4`)}

                        </p>
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            onClick={handleAddPoolOrStatic}
                        >
                            <Icon name="plus" /> {t(`widgets:Dhcpd.type.${type}`)}
                        </MDBBtn>
                    </div>
                </td>
            </tr>
        );
    }
    const { ip, rangeFrom, rangeTo, begin, end,
        __off, error,  mac, comment, hostname,
    } = data;
    return (
        <Draggable
            draggableId={uuid}
            index={dataIndex}
            isDragDisabled={type === ACTUAL_LEASES_TYPE}
            key={uuid}
        >
            {(provided) => (
                <>
                    {type !== ACTUAL_LEASES_TYPE &&
                   (
                       <RowDivider
                           id={'rowDivider' + uuid}
                           length={getColumnsSelectedLength()}
                           types={types}
                       />
                   )}
                    <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={classNames(
                            'dataTableWidget__Row',
                            { 'dataTableWidget__Row--match': matches.length },
                            { 'dataTableWidget__Row--error': error },
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                            { 'dataTableWidget__Row--disable': __off && type !== ACTUAL_LEASES_TYPE },

                            'network__row',
                        )}
                        key={uuid}
                        style={
                            {
                                ...provided.draggableProps.style
                            }
                        }
                    >

                        {getColumnsShow(columns, DHCPD_RULE_COLUMNS_DRAG) &&
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { 'dataTableWidget__cell--error': error },
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                            {...provided.dragHandleProps}
                            colSpan={1}
                        >
                            {type !== ACTUAL_LEASES_TYPE &&
                            <Icon
                                name="drag"
                                size="sm"
                            />
                            }
                        </td>
                        }
                        {getColumnsShow(columns, DHCPD_RULE_COLUMNS_ACTUAL_ADDRESS) &&
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}

                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                classNamePrefix="packetFilterSelect"
                                disabled={__off && type !== ACTUAL_LEASES_TYPE}
                                fake={type === ACTUAL_LEASES_TYPE}
                                id={'ip' + uuid}
                                isMulti
                                isRow={type !== ACTUAL_LEASES_TYPE}
                                name="ip"
                                noDropdownIndicator
                                noOptionsMessage
                                noWrap
                                onChange={changeValue}
                                schema={schemas?.ip}
                                value={ip}
                            />
                        </td>
                        }
                        {getColumnsShow(columns, DHCPD_RULE_COLUMNS_ADDRESS) &&
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}

                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                classNamePrefix="packetFilterSelect"
                                disabled={__off && type !== ACTUAL_LEASES_TYPE}
                                fake={type === ACTUAL_LEASES_TYPE}
                                id={'ip' + uuid}
                                isCreatable={!ip || ip?.[0] === ''}
                                isMulti
                                isRow={type !== ACTUAL_LEASES_TYPE}
                                name="ip"
                                noDropdownIndicator
                                noOptionsMessage
                                noWrap
                                onChange={changeValue}
                                schema={schemas?.ip}
                                value={ip}
                            />
                        </td>
                        }
                        {getColumnsShow(columns, DHCPD_RULE_COLUMNS_MAC) &&
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },

                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                disabled={__off && type !== ACTUAL_LEASES_TYPE}
                                fake={type === ACTUAL_LEASES_TYPE}
                                id={'mac' + dataIndex}
                                isCreatable={!mac || mac?.[0] === ''}
                                isMulti
                                isRow={type !== ACTUAL_LEASES_TYPE}
                                name="mac"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={changeValueMac}
                                schema={schemas?.mac}
                                value={mac}
                            />
                        </td>
                        }
                        {getColumnsShow(columns, DHCPD_RULE_COLUMNS_ACTUAL_MAC) &&
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },

                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                disabled={true}
                                fake={true}
                                id={'mac' + dataIndex}
                                isCreatable={!mac || mac?.[0] === ''}
                                isMulti
                                isRow={type !== ACTUAL_LEASES_TYPE}
                                name="mac"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={changeValueMac}
                                schema={schemas?.mac}
                                value={mac}
                            />
                        </td>
                        }
                        {getColumnsShow(columns, DHCPD_RULE_COLUMNS_RANGEFROM) &&
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                disabled={__off}
                                id={'rangeFrom' + uuid}
                                isCreatable
                                isMulti
                                isRow
                                name="rangeFrom"
                                noDropdownIndicator
                                noOptionsMessage
                                noWrap
                                onChange={changeValue}
                                schema={schemas?.rangeFrom}
                                value={rangeFrom}
                            />
                        </td>
                        }
                        {getColumnsShow(columns, DHCPD_RULE_COLUMNS_RANGETO) &&
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                classNamePrefix="packetFilterSelect"
                                disabled={__off}
                                id={'rangeTo' + uuid}
                                isCreatable
                                isMulti
                                isRow
                                name="rangeTo"
                                noDropdownIndicator
                                noOptionsMessage
                                noWrap
                                onChange={changeValue}
                                schema={schemas?.rangeTo}
                                value={rangeTo}
                            />
                        </td>
                        }
                        {getColumnsShow(columns, DHCPD_RULE_COLUMNS_HOSTNAME) &&
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                fake={true}
                                isMulti
                                noDropdownIndicator
                                noOptionsMessage
                                noWrap
                                onChange={changeValue}
                                value={hostname}
                            />
                        </td>
                        }
                        {getColumnsShow(columns, DHCPD_RULE_COLUMNS_COMMENT) &&
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Input
                                className="dataTableWidget__RowInput"
                                disabled={__off}
                                id={'comment' + uuid}
                                name="comment"
                                onChange={changeValue}
                                value={comment}
                                withoutBorder
                                withoutPaddingLeft
                            />
                        </td>
                        }
                        {type === ACTUAL_LEASES_TYPE &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                {begin}
                            </td>
                        }
                        {type === ACTUAL_LEASES_TYPE &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                {end}
                            </td>
                        }
                        {type !== ACTUAL_LEASES_TYPE ?
                            <RowMenuAndSwitch
                                __off={__off}
                                copyFunc={copyRow}
                                deleteFunc={delRow}
                                id={'serviceOff' + uuid}
                                onChange={changeValue}
                                spacing={spacing}
                            /> :
                            <td className={classNames(
                                'dataTableWidget__cell',
                            )}
                            >
                                <IconWithTooltip
                                    className="icon--secondary"
                                    link
                                    name="content-save-cog-outline"
                                    onClick={addToStatic}
                                    tooltipText={'widgets:global.addToConf'}
                                />
                            </td>
                        }
                    </tr>
                    {nodes.map((node: ClusterOwnSelector) => (
                        <PoolRow
                            key={node + uuid}
                            node={node}
                            off={__off}
                            pool={pools[node]}
                            spacing={spacing}
                            uuid={uuid}
                        />
                    ))}
                    {type !== ACTUAL_LEASES_TYPE && (
                        <RowDivider
                            after
                            id={'rowDividerAfter' + uuid}
                            length={getColumnsSelectedLength()}
                            types={types}
                        />
                    )}
                </>
            )}
        </Draggable>
    );
};

export default Row;
