/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { default as Axios } from 'axios';
import getValue from 'get-value';

import { put, take, takeLatest, call } from '~commonLib/reduxSagaEffects.ts';

import { getApiError } from '../../lib/apiUtils.ts';
import { ReporterTemplates } from '../../../shared/lib/reporterLibrary/index.js';
import { APPLY_LANGUAGE_SUCCESS } from '../language/index.js';


// actions
export const LOAD_REPORTER_DB_STRUCTURE_REQUEST = 'ak/reporterDbStructure/LOAD_REPORTER_DB_STRUCTURE_REQUEST';
export const LOAD_REPORTER_DB_STRUCTURE_SUCCESS = 'ak/reporterDbStructure/LOAD_REPORTER_DB_STRUCTURE_SUCCESS';
export const LOAD_REPORTER_DB_STRUCTURE_FAILURE = 'ak/reporterDbStructure/LOAD_REPORTER_DB_STRUCTURE_FAILURE';
export const TRY_AGAIN_REPORTER_DB_STRUCTURE_REQUEST = 'ak/reporterDbStructure/TRY_AGAIN_REPORTER_DB_STRUCTURE_REQUEST';


// initial state
const initialState = {
    data: null,
    error: null,
    isLoading: false,
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case LOAD_REPORTER_DB_STRUCTURE_REQUEST:
        return { ...state, isLoading: true };
    case LOAD_REPORTER_DB_STRUCTURE_SUCCESS:
        return { ...state, isLoading: false, data: action.payload, error: null };
    case LOAD_REPORTER_DB_STRUCTURE_FAILURE:
        return { ...state, isLoading: false, error: action.payload };
    default: return state;
    }
};

export default reducer;


// This shouldn't exist here, but it probably shoudln't exist at all.
const reporterReporterTemplates = new ReporterTemplates();


// data accessors
const getState = rootState => rootState.reporterDbStructure;
export const getDataReporterDbStructure = rootState => getState(rootState).data;
export const getColumnsInfo = (rootState, databaseName) =>
    getValue(getDataReporterDbStructure(rootState), databaseName + '.columns') || {};
export const getError = rootState => getState(rootState).error;
export const getIsLoading = rootState => getState(rootState).isLoading;
export const getReporterReporterTemplates = () => reporterReporterTemplates; // this is potentially dangerou

// action creators
export const loadReporterTemplatesRequest = (data) =>
    ({ type: LOAD_REPORTER_DB_STRUCTURE_REQUEST, data });

export const loadReporterTemplatesSuccess = data =>
    ({ type: LOAD_REPORTER_DB_STRUCTURE_SUCCESS, payload: data });

export const loadReporterTemplatesFailure = error =>
    ({ type: LOAD_REPORTER_DB_STRUCTURE_FAILURE, payload: error });


export const tryAgainReporterTemplatesRequest = () =>
    ({ type: TRY_AGAIN_REPORTER_DB_STRUCTURE_REQUEST });


// API
const getReporterTemplates = async () =>
    Axios.get('/api/reporter/templatesCfg');


// side effects
/** Initialize the data */
export const workerReporterTemplatesLoad = function* ({ data }) {
    try {
        if (data.reporter) {
            reporterReporterTemplates.load(data.reporter);
        }
        if (!Object.keys(data).length) {
            yield put(loadReporterTemplatesFailure({ message: 'charts:templatesCfg.error', code: 500 }));
        } else {
            yield put(loadReporterTemplatesSuccess(data));
        }
    } catch (error) {
        yield put(loadReporterTemplatesFailure(getApiError(error)));
    }
};

export const workertryAgainReporterTemplatesLoad = function* () {
    try {
        const { data } = yield call(getReporterTemplates);
        yield workerReporterTemplatesLoad({ data });
    } catch (error) {
        yield put(loadReporterTemplatesFailure(getApiError(error)));
    }
};

let isInitialized = false;
let isInitializing = false;

/** Initialize the data unless already initialized. This saga finished when the data is initialized. */
export const workerInitializeReporterTemplates = function* () {
    if (isInitialized) {
        return;
    }
    if (!isInitializing) {
        isInitializing = true;
        yield put(loadReporterTemplatesRequest());
    }
    yield take([ LOAD_REPORTER_DB_STRUCTURE_SUCCESS, LOAD_REPORTER_DB_STRUCTURE_FAILURE ]);
    isInitialized = true;
};

export const sagas = [
    takeLatest(LOAD_REPORTER_DB_STRUCTURE_REQUEST, workerReporterTemplatesLoad),
    takeLatest(TRY_AGAIN_REPORTER_DB_STRUCTURE_REQUEST, workertryAgainReporterTemplatesLoad),
    takeLatest(APPLY_LANGUAGE_SUCCESS, workertryAgainReporterTemplatesLoad)
];
