/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useSelector } from 'react-redux';

import {  NETWORK_REPORT, NFT_REPORT, NETWORK_CARD_REPORT, NETWORK_CARD_DROP_PACKETS_REPORT } from '~frontendConstants/index.js';
import { DashboardDefinitionFilterValuesType, DashboardDefinitionType } from '~frontendRoot/constants/types.ts';
import { getInterfacesForCharts } from '~frontendRoot/ducks/reporterEntities/index.js';
import { DASHBOARD_SERVICE, DASHBOARD_SERVICE_DHCPD, DASHBOARD_SERVICE_IFACE,
    DASHBOARD_SERVICE_QOS, DASHBOARD_SERVICE_VPN } from '~sharedConstants/index.ts';

import DashboardScene from '../../DashboardScene.js';


const DASHBOARDS_DEF: DashboardDefinitionType = {
    [DASHBOARD_SERVICE_IFACE]: {
        dashboardIds: [ { id: NETWORK_REPORT },
            { id: NETWORK_CARD_REPORT, columnName: 'sysmon_ifaces.iface', },
            { id: NETWORK_CARD_DROP_PACKETS_REPORT } ],
        title: 'charts:main.title'
    },
    [DASHBOARD_SERVICE_QOS]:
        { dashboardIds: [ { id: NFT_REPORT } ], title: 'charts:main.title', },

    [DASHBOARD_SERVICE_VPN]:
        { dashboardIds: [ { id: NFT_REPORT } ], title: 'charts:main.title', },
    [DASHBOARD_SERVICE_DHCPD]:
        { dashboardIds: [ { id: NFT_REPORT } ], title: 'charts:main.title', },

};

const ServiceCharts = () => {
    const networkDevices: DashboardDefinitionFilterValuesType = useSelector(getInterfacesForCharts);
    return (
        <DashboardScene
            dashboardsDef={DASHBOARDS_DEF}
            filterValues={networkDevices}
            type={DASHBOARD_SERVICE}

        />
    );};

export default ServiceCharts;
