/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createReducer, createSelector } from '@reduxjs/toolkit';

import { put, takeEvery } from '~commonLib/reduxSagaEffects.ts';
import {
    PROFILE_RULE_COLUMNS_DRAG,
    PROFILE_RULE_COLUMNS_ACTION,
    PROFILE_RULE_COLUMNS_NAME,
    PROFILE_RULE_COLUMNS_USER,
    PROFILE_RULE_COLUMNS_GROUP,
    PROFILE_RULE_COLUMNS_CLIENT,
    PROFILE_RULE_COLUMNS_SERVER,
    PROFILE_RULE_COLUMNS_INTERVALS,
    PROFILE_RULE_COLUMNS_DAYS,
    PROFILE_RULE_COLUMNS_CATEGORY,
    PROFILE_RULE_COLUMNS_LOG,
    PROFILE_RULE_COLUMNS_ACTION_PARAM,
    PROFILE_RULE_COLUMNS_MENU,
    PROFILE_RULE_COLUMNS_ENABLED,
    PROFILE_RULE_COLUMNS_ADDRESSES,
    PROFILE_RULE_COLUMNS_PORT,
    PACKET_FILTER_COLUMNS_DRAG, PACKET_FILTER_COLUMNS_ACTION,
    PACKET_FILTER_COLUMNS_NAME,
    PACKET_FILTER_COLUMNS_SOURCE,
    PACKET_FILTER_COLUMNS_DESTINATION,
    PACKET_FILTER_COLUMNS_SERVICE,
    PACKET_FILTER_COLUMNS_TRANSLATION_ICON,
    PACKET_FILTER_COLUMNS_INTERFACE,
    PACKET_FILTER_COLUMNS_LOG,
    PACKET_FILTER_COLUMNS_SOURCE_TRANSLATION,
    PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION,
    PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION_PORT,
    PACKET_FILTER_COLUMNS_ARROW,
    PACKET_FILTER_COLUMNS_MENU,
    PACKET_FILTER_COLUMNS_USER,
    PACKET_FILTER_COLUMNS_GROUP,
    PACKET_FILTER_COLUMNS_WEB_PROFILE,
    PACKET_FILTER_COLUMNS_QOS_NODES,
    INTERFACES_COLUMNS_STATE,
    INTERFACES_COLUMNS_TYPE,
    INTERFACES_COLUMNS_NAME,
    INTERFACES_COLUMNS_IP4,
    INTERFACES_COLUMNS_WANLAN,
    INTERFACES_COLUMNS_VLANIFACE,
    INTERFACES_COLUMNS_ANTISPOOFING,
    INTERFACES_COLUMNS_IP6,
    INTERFACES_COLUMNS_PHYSICALIFACE,
    // INTERFACES_COLUMNS_MAC,
    INTERFACES_COLUMNS_MENU,

    CHANGES_COLUMNS_TIME,
    CHANGES_COLUMNS_NOTE,
    CHANGES_COLUMNS_REVISION,
    CHANGES_COLUMNS_USER,
    CHANGES_COLUMNS_VERSION,
    CHANGES_COLUMNS_CHANGED,

    //FAKES
    INTERFACES_COLUMNS_CLUSTER_ARROW,


    GLCFG_SETTER_PREFIX,
    WAF_PROFILE_RULE_COLUMNS_DRAG,
    WAF_PROFILE_RULE_COLUMNS_ACTION,
    WAF_PROFILE_RULE_COLUMNS_NAME,
    WAF_PROFILE_RULE_COLUMNS_CLIENT,
    WAF_PROFILE_RULE_COLUMNS_SERVER,
    WAF_PROFILE_RULE_COLUMNS_ADDRESS,
    WAF_PROFILE_RULE_COLUMNS_PORT,
    WAF_PROFILE_RULE_COLUMNS_LOG,
    WAF_PROFILE_RULE_COLUMNS_MENU,
    INTERFACES_COLUMNS_BOND_MODE,
    INTERFACES_COLUMNS_TAG,
    PROFILE_RULE_COLUMNS_POLITICS,
} from '~frontendConstants/constants.ts';
import { PROFILES } from '~frontendRoot/constants/index.js';
import { createSelectorArrayOfObjectsShallow } from '~frontendRoot/lib/reduxUtils.ts';


export const SET_COLUMNS = 'ak/SELECTED_COLUMNS/SET_COLUMNS';


// initial state
export const initialState = <const>{
    [PROFILES]: [ //ORDER MATTERS
        { title: PROFILE_RULE_COLUMNS_DRAG, selected: true, hideName: true  },
        { title: PROFILE_RULE_COLUMNS_ACTION,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_NAME,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_USER,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_GROUP,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_CLIENT,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_SERVER,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_INTERVALS,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_DAYS, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_POLITICS, selected: false, hideName: false },
        { title: PROFILE_RULE_COLUMNS_CATEGORY, selected: true, hideName: false  },
        //  { title: PROFILE_RULE_COLUMNS_USERAGENT, selected: false, hideName: false  },
        //  { title: PROFILE_RULE_COLUMNS_CONTENT_POLICY, selected: true, hideName: false  },
        { title: PROFILE_RULE_COLUMNS_LOG, selected: true, hideName: true },
        { title: PROFILE_RULE_COLUMNS_ACTION_PARAM, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_MENU, selected: true, hideName: true, disabled: true, menu: true },
    ],
    wafProfileRules: [ //ORDER MATTERS
        { title: WAF_PROFILE_RULE_COLUMNS_DRAG, selected: true, hideName: true },
        { title: WAF_PROFILE_RULE_COLUMNS_ACTION,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: WAF_PROFILE_RULE_COLUMNS_NAME, selected: true, hideName: false },
        { title: WAF_PROFILE_RULE_COLUMNS_CLIENT,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: WAF_PROFILE_RULE_COLUMNS_SERVER,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: WAF_PROFILE_RULE_COLUMNS_ADDRESS,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: WAF_PROFILE_RULE_COLUMNS_PORT,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: WAF_PROFILE_RULE_COLUMNS_LOG, selected: true, hideName: true },
        { title: WAF_PROFILE_RULE_COLUMNS_MENU, selected: true, hideName: true, disabled: true, menu: true },
    ],
    addressesPolicy: [
        { title: PROFILE_RULE_COLUMNS_DRAG, selected: true, hideName: true },
        { title: PROFILE_RULE_COLUMNS_ADDRESSES,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_PORT,
            props: { className: 'dataTableWidgetHeader--input' }, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_ENABLED, selected: true, hideName: true, disabled: true },
        { title: PROFILE_RULE_COLUMNS_MENU, selected: true, hideName: true, disabled: true, menu: true },
    ],
    packetFilter: [ //ORDER MATTERS
        { title: PACKET_FILTER_COLUMNS_DRAG,  selected: true, hideName: true },
        { title: PACKET_FILTER_COLUMNS_ACTION,  selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_NAME,  selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_SOURCE,  selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_ARROW, selected: true, hideName: true },
        { title: PACKET_FILTER_COLUMNS_DESTINATION, selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_SERVICE, selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_WEB_PROFILE, selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_USER, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_GROUP, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_INTERFACE, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_TRANSLATION_ICON,  selected: true, hideName: true },
        { title: PACKET_FILTER_COLUMNS_SOURCE_TRANSLATION,  selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION_PORT, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_QOS_NODES, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_LOG,  selected: true, hideName: true },
        { title: PACKET_FILTER_COLUMNS_MENU,  selected: true, hideName: true, disabled: true, menu: true },
    ],
    interfaces: [ //ORDER MATTERS
        { title: INTERFACES_COLUMNS_STATE,  selected: true, hideName: true  },
        { title: INTERFACES_COLUMNS_TYPE,  selected: true, hideName: true },
        { title: INTERFACES_COLUMNS_NAME,  selected: true, hideName: false,
            props: { className: 'dataTableWidgetHeader--input' } },
        { title: INTERFACES_COLUMNS_IP4,  selected: true, hideName: false,
            props: { className: 'dataTableWidgetHeader--input' }  },
        { title: INTERFACES_COLUMNS_WANLAN,  selected: true, hideName: false,
            props: { className: 'dataTableWidgetHeader--input' } },
        { title: INTERFACES_COLUMNS_TAG, selected: true, hideName: false,
            props: { className: 'dataTableWidgetHeader--input' } },
        { title: INTERFACES_COLUMNS_BOND_MODE, selected: true, hideName: false,
            props: { className: 'dataTableWidgetHeader--input' } },
        { title: INTERFACES_COLUMNS_VLANIFACE, selected: true, hideName: false,
            props: { className: 'dataTableWidgetHeader--input' } },
        { title: INTERFACES_COLUMNS_PHYSICALIFACE, selected: false, hideName: false },

        { title: INTERFACES_COLUMNS_ANTISPOOFING, selected: true, hideName: false,
            props: { className: 'dataTableWidgetHeader--input' } },
        { title: INTERFACES_COLUMNS_IP6, selected: true, hideName: false,
            props: { className: 'dataTableWidgetHeader--input' } },
        /*{ title: INTERFACES_COLUMNS_MAC, selected: true, hideName: false,
            props: { className: 'dataTableWidgetHeader--input' } },*/
        { title: INTERFACES_COLUMNS_CLUSTER_ARROW, selected: true, hideName: true, disabled: true, },
        { title: INTERFACES_COLUMNS_MENU,  selected: true, hideName: true, disabled: true, menu: true  },
    ],
    changes: [
        { title: PACKET_FILTER_COLUMNS_DRAG,  selected: true, hideName: true  },
        { title: CHANGES_COLUMNS_TIME,  selected: true, hideName: false  },
        { title: CHANGES_COLUMNS_CHANGED,  selected: true, hideName: false  },
        { title: CHANGES_COLUMNS_NOTE,  selected: true, hideName: false  },
        { title: CHANGES_COLUMNS_USER,  selected: true, hideName: false  },
        { title: CHANGES_COLUMNS_VERSION,  selected: true, hideName: false, showAdvanced: true  },
        { title: CHANGES_COLUMNS_REVISION,  selected: true, hideName: false  },
    ],

};

// reducer
const reducer = createReducer(initialState, {
    [SET_COLUMNS]: (state, action) => {
        state[action.id] = state[action.id].map(item => {
            if (typeof action.payload === 'object' && item.title === action.payload.col) {
                item.selected = action.payload.value;
            } else if (item.title === action.payload && action.value !== undefined) {
                item.selected = action.value;

            } else if (item.title === action.payload) {
                item.selected = !item.selected;
            }
            if (item.title === action.payload && action.disabled !== undefined) {
                item.disabled = action.disabled;
            }
            return item;
        });
    }

});
export default reducer;


export const getColumns = createSelector(
    (state, id) => state.selectedColumns[id],
    columns => columns
);
export const getColumnsHeaders = createSelectorArrayOfObjectsShallow(
    (state, id, justTitle) =>
        state.selectedColumns[id].filter(item =>
            item.selected && (!item.showAdvanced || state.dynamicSettings.isAdvanced)).map(item =>
            justTitle ?
                { title: item.title, selected: item.selected } :
                item),
    (selected) => selected
);

export const getColumnsHeadersGetter = (id, justTitle) =>
    (state) =>
        state.selectedColumns[id].filter(item =>
            item.selected && (!item.showAdvanced || state.dynamicSettings.isAdvanced)).map(item =>
            justTitle ?
                { title: item.title, selected: item.selected } :
                item);

//utils
export const getColumnsShow = (columns, toShow) =>
    columns?.find(item => item.title === toShow)?.selected;

export const getColumnsSelectedLength = (columns) =>
    columns?.filter(item => item.selected).length;


export const setColumns = (payload, id, value?: boolean, disabled?: boolean) =>
    ({ type: SET_COLUMNS, payload, id, value, disabled });

const workerSetIpv6Column = function* (props) {
    yield put(setColumns(INTERFACES_COLUMNS_IP6, 'interfaces', props.payload, !props.payload));
};

export const sagas = [
    takeEvery(`${GLCFG_SETTER_PREFIX}ipv6Enabled`, workerSetIpv6Column), //glcfgDefinition ipv6Enabled
];
