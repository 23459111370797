/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import {
    DEFAULT_SCHEMA_VALUE,
    PLACEHOLDER_SCHEMA_VALUE,
    SERVICE_ADDRESS,
    WITHOUT_ADDR_SELECTORS,
    WITHOUT_NAMED_OBJECTS
} from '~commonLib/schemaFlags.ts';
import { TypeNetaddr } from '~commonLib/Netaddr/types.ts';
import {
    netaddrDhcpSchema,
    netaddrDomainSchema,
    netaddrIp4Schema,
    netaddrIp6Schema, netaddrObjSchema
} from '~commonLib/ipUtils/netaddrObjSchema.ts';
import { FilterUndefined } from '~commonLib/types.ts';
import { notFalsey } from '~commonLib/arrayUtils.ts';


export const SCHEMA_TYPE_NETADDR = 'x-netaddr';

export type NetaddrAdditionalProps = {
    [PLACEHOLDER_SCHEMA_VALUE]?: string
    [DEFAULT_SCHEMA_VALUE]?: any,
    [WITHOUT_NAMED_OBJECTS]?: boolean,
    [WITHOUT_ADDR_SELECTORS]?: boolean,
    [SERVICE_ADDRESS]?: boolean,
    translation?: string
}

export const netaddrSchema = <const T extends TypeNetaddr, const T2 extends NetaddrAdditionalProps>(
    sch: T, additional: T2 = <T2>{}
) => {

    type CanBeAny = T extends { ip4?: undefined, ip6?: undefined, domain?: undefined, canBeFromDhcp?: undefined } ?
        true :
        false;
    type Addr = T extends {ip4: true} ? typeof netaddrIp4Schema : undefined;
    type Addr2 = T extends {ip6: true} ? typeof netaddrIp6Schema : undefined;
    type Addr3 = T extends {domain: true} ? typeof netaddrDomainSchema : undefined;
    type Addr4 = T extends {canBeFromDhcp: true} ? typeof netaddrDhcpSchema : undefined;

    type Ret = CanBeAny extends true ? [ typeof netaddrObjSchema ] : FilterUndefined<[Addr, Addr2, Addr3, Addr4]>


    const canBeAny = !(sch.ip4 || sch.ip6 || sch.domain || sch.canBeFromDhcp);

    const anyOf = [
        (canBeAny || sch.ip4) && netaddrIp4Schema,
        (canBeAny || sch.ip6) && netaddrIp6Schema,
        (canBeAny || sch.domain) && netaddrDomainSchema,
        (canBeAny || sch.canBeFromDhcp) && netaddrDhcpSchema,
    ].filter(notFalsey);
    assert(anyOf.length > 0);

    const ret = <const>{
        anyOf: anyOf as Ret,
        [SCHEMA_TYPE_NETADDR]: sch,
        ...additional
    };

    return ret;
};
