/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { Icon } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import { getTreeChartHidden } from '~frontendDucks/treeChartHidden/index.js';

import PanAndZoom from '../PanAndZoom/index.js';


const TreeNodeLabel = ({ uuid, generateLabelNode, parentUuid, root, secondTreeParent }) => {
    return (
        generateLabelNode({ uuid, parentUuid, root, secondTreeParent })
    );
};

TreeNodeLabel.propTypes = {
    uuid: PropTypes.string,
    generateLabelNode: PropTypes.func,
    parentUuid: PropTypes.string,
    root: PropTypes.bool,
    secondTreeParent: PropTypes.string,
};


const TreeRootLabel = ({ uuid, generateRootLabel, withName }) => {
    return (
        generateRootLabel({ uuid, withName })
    );
};

TreeRootLabel.propTypes = {
    uuid: PropTypes.string,
    generateRootLabel: PropTypes.func,
    withName: PropTypes.bool,
};

const TreeRootShortcut = ({ uuids, generateRootLabel, hiddenTarget }) => {
    return (
        generateRootLabel({ uuids, hiddenTarget })
    );
};

TreeRootShortcut.propTypes = {
    uuids: PropTypes.array,
    generateRootLabel: PropTypes.func,
    hiddenTarget: PropTypes.string,
};

const generateNodes = ({ structure, startId, root, generateLabelNode, hidden, secondTreeParent,
    secondTree, parentId, generateLabelSecondTree, generateRootLabel }) => {
    if (!structure) {
        return;
    }
    if (structure[startId]?.childrenIds) {
        const childs = structure[startId].childrenIds.map(node => {
            return (
                <TreeNode
                    id={node}
                    key={node}
                    label={
                        <TreeNodeLabel
                            generateLabelNode={generateLabelNode}
                            parentUuid={startId}
                            secondTreeParent={secondTreeParent}
                            uuid={node}
                        />}
                >

                    {!hidden[node] ? generateNodes({ structure, startId: node, generateLabelNode, hidden,
                        secondTreeParent }) : null}
                </TreeNode>
            );
        });
        if (root) {
            return (
                <TreeNode
                    id={startId}
                    label={
                        <TreeNodeLabel
                            generateLabelNode={generateLabelNode}
                            parentUuid={parentId}
                            secondTreeParent={secondTreeParent}
                            uuid={startId}
                        />}
                >
                    {!hidden[startId] ? childs : null}
                    {Boolean(secondTree?.length) &&
                             <TreeNode
                                 id={parentId + 'secondTree'}
                                 label={
                                     <TreeRootShortcut
                                         generateRootLabel={generateLabelSecondTree}
                                         hiddenTarget={parentId + 'secondTree'}
                                         uuids={secondTree}
                                     />}
                             >
                                 {hidden[parentId + 'secondTree'] ? null : //switched logic,default is not shown
                                     secondTree.map(item => (
                                         <TreeNode
                                             id={item.id}
                                             key={item.id}
                                             label={
                                                 <TreeRootLabel
                                                     generateRootLabel={generateRootLabel}
                                                     uuid={item.id}
                                                     withName
                                                 />}
                                         > {
                                                 generateNodes({
                                                     structure,
                                                     startId: item.rootNodeId,
                                                     root: true,
                                                     generateLabelNode: generateLabelNode,
                                                     hidden,
                                                     secondTreeParent: item.id
                                                 })}
                                         </TreeNode>
                                     ))}

                             </TreeNode>}
                </TreeNode>
            );
        }
        if (childs.length) {
            return childs;
        }
    }
    if (root) {
        return  (
            <TreeNode
                id={startId}
                label={
                    <TreeNodeLabel
                        generateLabelNode={generateLabelNode}
                        parentUuid={parentId}
                        root
                        secondTreeParent={secondTreeParent}
                        uuid={startId}
                    />}
            />
        );
    }
};

@connect((state, { type }) => ({
    hidden: getTreeChartHidden(state, type)
}))
class TreeChart extends Component {
    render() {

        const { structure, generateRootLabel, generateLabelNode, parentId, startId, color, disabled,
            hidden, secondTree, generateLabelSecondTree, roots } = this.props;
        return (
            <div className="position-relative h-100">
                <PanAndZoom uuid={parentId}>
                    <div className="treeChart p-2">
                        <Tree
                            label={
                                <TreeRootLabel
                                    generateRootLabel={generateRootLabel}
                                    uuid={parentId}
                                />}
                            lineBorderRadius={'12px'}
                            lineColor={color || '#bbc'}
                            lineWidth={'2px'}
                        >

                            {startId && generateNodes({
                                structure,
                                startId,
                                root: true,
                                generateLabelNode: generateLabelNode,
                                hidden,
                                secondTree,
                                generateLabelSecondTree,
                                parentId,
                                generateRootLabel,
                                roots
                            })}
                        </Tree>

                    </div>
                </PanAndZoom>
                {disabled &&
                <div
                    className={classNames('overlay', 'overlay--secondary', 'overlay--card',
                        { 'overlay--active userProfileOverlay': disabled })}
                >
                    <div className="overlay__text">
                        <Icon
                            name="connection"
                            size="xl"
                        />
                        <h2><Message message="widgets:global.turnedOff" /></h2>
                    </div>
                </div>}
            </div>
        );
    }
}

TreeChart.propTypes = {
    structure: PropTypes.object,
    generateRootLabel: PropTypes.func,
    generateLabelNode: PropTypes.func,
    generateLabelSecondTree: PropTypes.func,
    parentId: PropTypes.string,
    startId: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.object,
    secondTree: PropTypes.array,
    roots: PropTypes.object,
};

export default TreeChart;
