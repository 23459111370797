/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { MDBCol, MDBRow, MDBCard, MDBCardHeader, MDBCardBody, MDBBtn,  } from 'mdbreact';

import { setModalState } from '~frontendDucks/modals/index.js';
import { Icon, SvgInliner  } from '~frontendComponents/Generic/index.js';
import { COLOR_PRIMARY, DOWNGRADE_CONFIRM_MODAL } from '~frontendConstants/constants.ts';
import Message from '~frontendComponents/Message/index.js';
import LogoBlackImage from '~frontendRoot/img/svg/kernun-logo-black.svg';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { testProps } from '~commonLib/PageObjectMap.ts';
import { queries } from '~frontendQueries/queries.ts';
import { useQueriesReloader } from '~frontendQueries/utils.ts';
import { useUpgradeStateQuery } from '~frontendQueries/upgrade/hooks.ts';

import { VersionCard, VersionDescription } from './utils.tsx';
import { UpgradeCard } from './UpgradeCard.tsx';
import { upgradeSceneMap } from './pageObjectMap.ts';


export const SystemUpgrade = () => {
    return (
        <MDBCard
            className="w-100"
            {...testProps(upgradeSceneMap.child.upgradeState.id)}
        >
            <MDBCardHeader>
                <SystemUpgradeHeader />
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <DowngradeCard />
                    <Arrow type="left" />
                    <CurrentCard />
                    <Arrow type="right" />
                    <UpgradeCard />
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

const SystemUpgradeHeader = () => {
    const { isLoading, reload } = useQueriesReloader([
        queries.upgrade.newerVersionAvailable, queries.upgrade.availableVersions
    ]);

    return (
        <MDBRow>
            <MDBCol lg="8">
                <Message message="upgrade:deploymentState" />
            </MDBCol>
            <MDBCol
                className="card__header--actions"
                lg="4"
            >
                <IconWithTooltip
                    iconColor={COLOR_PRIMARY}
                    iconSize="sm"
                    loading={isLoading}
                    name="reload"
                    onClick={reload}
                    tooltipText={'upgrade:newerVersionRequest'}
                    {...testProps(
                        upgradeSceneMap.child.upgradeState.child.checkNewVersion.id,
                        { status: isLoading ? 'loading' : 'loaded' }
                    )}
                />
            </MDBCol>
        </MDBRow>
    );

};

const Arrow = ({ type }: {type: 'left'|'right'}) => {
    return (

        <MDBCol
            className="upgrade__arrow"
            lg="1"
            md="1"
            sm="1"
        >
            <Icon
                className="upgrade__arrowPosition"
                name={`arrow-${type}`}
            />
        </MDBCol>
    );
};


const DowngradeCard = () => {
    const { data: downgradeVersion } = useUpgradeStateQuery({ select: it => it.downgradeVersion });
    const downgradeAvailable = !!downgradeVersion;
    const { t } = useTranslation();
    const setDowngradeModal = useDispatchCallback(() => {
        return setModalState({ modal: DOWNGRADE_CONFIRM_MODAL, value: true });
    }, []);
    return (
        <VersionCard
            message="upgrade:downgrade.title"
        >
            <div className="upgrade__toggleBoxText toggleBox__text">
                <DowngradeCardDesc />
            </div>
            <MDBBtn
                className={'toggleBox__button'}
                data-cy={'downgrade'}
                disabled={!downgradeAvailable}
                onClick={setDowngradeModal}
                outline={true}
            >
                {t('upgrade:downgrade.button')}
            </MDBBtn>
        </VersionCard>
    );
};
const DowngradeCardDesc = () => {
    const { data: downgradeVersion } = useUpgradeStateQuery({ select: it => it.downgradeVersion });
    const { t } = useTranslation();
    if (!downgradeVersion) {
        return t('upgrade:downgrade.notFound');
    }
    return <VersionDescription version={downgradeVersion} />;
};

const CurrentCard = () => {
    return (
        <VersionCard
            isActive
            message="upgrade:currentVersion"
        >
            <div className="upgrade__toggleBoxText toggleBox__text">
                <CurrentCardDesc />
            </div>
            <SvgInliner
                className="logo__svg"
                src={LogoBlackImage}
            />
        </VersionCard>
    );
};
const CurrentCardDesc = () => {
    const { data: currentVersion } = useUpgradeStateQuery({ select: it => it.currentVersion });
    if (!currentVersion) {
        return null;
    }
    return <VersionDescription version={currentVersion} />;
};
