/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCardHeader, MDBCard, MDBCardBody, MDBRow, MDBCol } from 'mdbreact';
import { Progress } from 'react-sweet-progress';

import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import {  Input } from '~frontendComponents/Generic/index.js';
import {  getDatabaseInfo, getDatabaseLoading, getDatabaseUsageInPercent, getDatabaseError,
    getDatabaseState,  databaseInfoRequest, getDatabaseUsageInGigabites,
    databaseStateRequest } from '~frontendDucks/database/index.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/index.js';
import ApiError from '~frontendComponents/Generic/ApiError/index.js';
import { typeApiError } from '~frontendTypes/index.js';
import { MAX_DATABASE_SIZE_IN_PERCENT_BEFORE_ERROR, REPORTER } from '~frontendConstants/index.js';
import { getGlcfgValue, setGlcfgObjectValue } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { DEFAULT_REPORTER_DB_MAX_DAYS } from '~sharedConstants/index.ts';


@withTranslation()
@connect(
    (state,) => ({
        info: getDatabaseInfo(state),
        isLoading: getDatabaseLoading(state),
        stateIcon: getDatabaseState(state),
        isError: getDatabaseError(state),
        gigabit: getDatabaseUsageInGigabites(state),
        percent: getDatabaseUsageInPercent(state),
        reporter: getGlcfgValue(state, REPORTER)
    }),
    {
        doDatabaseInfoRequest: databaseInfoRequest,
        doDatabaseStateRequest: databaseStateRequest,
        setValue: setGlcfgObjectValue,

    }
)
class DatabaseState extends Component {
    static get propTypes() {
        return {
            info: PropTypes.object,
            percent: PropTypes.number,
            isLoading: PropTypes.bool,
            stateIcon: PropTypes.object,
            t: PropTypes.func,
            doDatabaseInfoRequest: PropTypes.func,
            doDatabaseStateRequest: PropTypes.func,
            gigabit: PropTypes.string,
            error: typeApiError,
            setValue: PropTypes.func,
            reporter: PropTypes.object,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            enabled: false,
            disabled: false,
            statsForNerds: false
        };
    }

    reload = () => {
        const { doDatabaseInfoRequest, doDatabaseStateRequest } = this.props;
        doDatabaseInfoRequest();
        doDatabaseStateRequest();
    };

    onChangeEvent = ({ value, name }) => {
        const { setValue } = this.props;
        setValue(REPORTER, value, name);
    };


    render() {
        const { t, info, isLoading, percent, error, stateIcon, gigabit,
            reporter } = this.props;
        const { statsForNerds } = this.state;

        return (
            <MDBCard>
                <MDBCardHeader>
                    <MDBRow>
                        <MDBCol lg="8">
                            {t('widgets:database.settings')}
                            <IconWithTooltip
                                className="ml-2 mb-1 database__stateIcon"
                                iconSize="sm"
                                link
                                name={stateIcon.icon}
                                tooltipText={t(`widgets:database.state.${stateIcon.text}`)}
                                withoutTranslation
                            />
                        </MDBCol>
                        <MDBCol
                            className="database__reloadIcon"
                            lg="4"
                        >
                            <IconWithTooltip
                                iconSize="sm"
                                link
                                loading={isLoading}
                                name="reload"
                                onClick={this.reload}
                                tooltipText={t('widgets:global.reload')}
                                withoutTranslation
                            />
                            <WhenAdvanced>
                                <IconWithTooltip
                                    iconSize="sm"
                                    link
                                    name="information-outline"
                                    onClick={() => this.setState({ statsForNerds: !statsForNerds })}
                                    tooltipText={t('widgets:global.infoForNerds')}
                                    withoutTranslation
                                />
                            </WhenAdvanced>

                        </MDBCol>

                    </MDBRow>
                </MDBCardHeader>
                <MDBCardBody>
                    <ApiError
                        data={stateIcon.showData}
                        error={error}
                        isLoading={isLoading}
                    >
                        {t('widgets:database.size')}
                        <Progress
                            percent={percent || 0}
                            status={percent < MAX_DATABASE_SIZE_IN_PERCENT_BEFORE_ERROR ?
                                'active' : 'error'}
                            theme={
                                {
                                    error: {
                                        symbol:
                                        <TextWithTooltip
                                            text={percent + ' %'}
                                            tooltipText={gigabit}
                                            withoutTranslation
                                        />,
                                        color: 'red'
                                    },
                                    active: {
                                        symbol:
                                        <TextWithTooltip
                                            text={percent + ' %'}
                                            tooltipText={gigabit}
                                            withoutTranslation
                                        />,
                                        color: 'green'
                                    },
                                }
                            }
                        />
                        <MDBRow>
                            <MDBCol>
                                <Input
                                    disabled={true}
                                    id="oldestRecord"
                                    label={t('widgets:database.oldest')}
                                    name="name"
                                    onChange={this.onChangeEvent}
                                    value={info.data_begin}
                                />
                            </MDBCol>
                            <MDBCol>
                                <Input
                                    disabled={true}
                                    id="newestRecord"
                                    label={t('widgets:database.newest')}
                                    name="name"
                                    onChange={this.onChangeEvent}
                                    value={info.data_end}
                                />
                            </MDBCol>
                        </MDBRow>

                        <Input
                            id="maxDayKeepInReporter"
                            label={t('widgets:database.numberOfDates')}
                            name="dbMaxDays"
                            number
                            onChange={this.onChangeEvent}
                            placeholder={DEFAULT_REPORTER_DB_MAX_DAYS}
                            useUndefined
                            value={reporter?.dbMaxDays}
                        />
                        <pre className={statsForNerds ? null : 'database__nerds'}>
                            {JSON.stringify(info, null, 2)}
                        </pre>
                    </ApiError>
                </MDBCardBody>
            </MDBCard>


        );
    }
}

export default DatabaseState;
