/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import { SYSTEM_USER_NAME } from '~sharedConstants/index.ts';

import Message from '../Message/index.js';
import TextWithTooltip from '../TextWithTooltip/index.js';


const ColumnValueUserName = ({ value }) => {
    if (value === SYSTEM_USER_NAME) {
        return (
            <TextWithTooltip
                desc={<Message message="widgets:ConfigurationRevisionList.columns.commitHash.systemUser.desc" />}
                icon="dns"
                text={value}
                tooltipText={(
                    <Message message="widgets:ConfigurationRevisionList.columns.commitHash.systemUser.desc" />
                )}
                withoutTranslation
            >
                <Message message="widgets:ConfigurationRevisionList.columns.commitHash.systemUser.title" />
            </TextWithTooltip>
        );
    }
    return (
        <TextWithTooltip
            icon="account-outline"
            text={value}
            tooltipText={(
                <Message
                    message="widgets:ConfigurationRevisionList.columns.commitHash.humanUser.desc"
                    params={{ userName: value }}
                />
            )}
            withoutTranslation
        />
    );
};

ColumnValueUserName.propTypes = {
    value: PropTypes.string.isRequired,
};

export default ColumnValueUserName;
