/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import { useTranslation } from 'react-i18next';

import { Scene } from '~frontendComponents/Scene/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { useHlcfgOffableValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';

import SuricataSettings from './components/SuricataSettings/index.js';
import SuricataUpdates from './components/SuricataUpdates/index.ts';
import BlackList from './components/BlackList/index.js';


const Suricata = () => {
    const { t } = useTranslation();
    const suri = useHlcfgOffableValue(it => it.protection.suricata.service);
    return (
        <Scene>
            <Service
                attention
                id="ips"
                on={suri.isOn}
                showService={(
                    <>
                        <MDBRow>
                            <MDBCol
                                className="mb-2 mb-lg-4"
                            >
                                <SuricataSettings />
                            </MDBCol>

                            <MDBCol
                                className="mb-2 mb-lg-4"
                            >
                                <SuricataUpdates />
                            </MDBCol>
                            <MDBCol
                                className="mb-2 mb-lg-4"
                            >
                                <BlackList list="suricataBlacklist" />
                            </MDBCol>
                            <MDBCol
                                className="mb-2 mb-lg-4"
                            >
                                <BlackList list="suricataWhitelist" />
                            </MDBCol>

                        </MDBRow>
                    </>
                )}
                translationFile={'ips'}
                turnOff={suri.setOff}
                turnOn={suri.setOn}
            >
                <>
                    <h3>{t('ips:database.title')}</h3>
                    <p className="mb-2">
                        {t('ips:database.desc')}
                    </p>
                    <h3>{t('ips:suricataBlacklist.title')}</h3>
                    <p className="mb-2">
                        {t('ips:suricataBlacklist.desc')}
                    </p>
                    <h3>{t('ips:suricataWhitelist.title')}</h3>
                    <p className="mb-2">
                        {t('ips:suricataWhitelist.desc')}
                    </p>
                </>
            </Service>
        </Scene>
    );
};

export default Suricata;
