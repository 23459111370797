/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Message from '~frontendComponents/Message/index.js';
import {
    ACTION_SEQUENCE_START,
    ADMIN_PASSWORD_RESET_FAIL,
    ADMIN_PASSWORD_RESET_SUCCESSFUL,
    LOGIN_DB_ERROR,
    LOGIN_DEACTIVATED_USER,
    LOGIN_PASSWORD_COMPARE_ERROR,
    LOGIN_SUCCESSFUL,
    LOGIN_WRONG_PASSWORD,
    LOGOUT_SUCCESSFUL, PASSWORD_CHANGE_FAILED,
    PASSWORD_CHANGE_SUCCESSFUL,
    PASSWORD_SELF_RESET_FAIL,
    PASSWORD_SELF_RESET_SUCCESSFUL,
    WRONG_USER_OR_PASSWORD,
} from '~sharedLib/logMessages.js';


//TODO: refactor this after AK-482 is done
const activityParser = {
    [LOGIN_SUCCESSFUL]: {
        icon: 'account-arrow-right-outline',
        title: <Message message="accounting:actions.login.success" />,
    },
    [LOGOUT_SUCCESSFUL]: {
        icon: 'account-arrow-left-outline',
        title: <Message message="accounting:actions.logout.success" />,
    },
    [LOGIN_WRONG_PASSWORD]: {
        icon: 'account-alert-outline',
        title: <Message message="accounting:actions.login.wrongPassword" />
    },
    [LOGIN_DEACTIVATED_USER]: {
        icon: 'account-alert-outline',
        title: <Message message="accounting:actions.login.deactivatedUser" />
    },
    [WRONG_USER_OR_PASSWORD]: {
        icon: 'account-alert-outline',
        title: <Message message="accounting:actions.login.wrongUserOrPassword" />
    },
    [LOGIN_DB_ERROR]: {
        icon: 'account-alert-outline',
        title: <Message message="accounting:actions.login.dbError" />
    },
    [LOGIN_PASSWORD_COMPARE_ERROR]: {
        icon: 'account-alert-outline',
        title: <Message message="accounting:actions.login.comparePasswordError" />,
    },
    [PASSWORD_SELF_RESET_SUCCESSFUL]: {
        icon: 'key-remove',
        title: <Message message="accounting:actions.passwordReset.byUser.success" />
    },
    [PASSWORD_SELF_RESET_FAIL]: {
        icon: 'key-remove',
        title: <Message message="accounting:actions.passwordReset.byUser.fail" />
    },
    [ADMIN_PASSWORD_RESET_SUCCESSFUL]: {
        icon: 'key-remove',
        title: <Message message="accounting:actions.passwordReset.byAdmin.success" />,
    },
    [ADMIN_PASSWORD_RESET_FAIL]: {
        icon: 'key-remove',
        title: <Message message="accounting:actions.passwordReset.byAdmin.fail" />,
    },
    [PASSWORD_CHANGE_SUCCESSFUL]: {
        icon: 'key-remove',
        title: <Message message="accounting:actions.passwordChange.success" />,
    },
    [PASSWORD_CHANGE_FAILED]: {
        icon: 'key-remove',
        title: <Message message="accounting:actions.passwordChange.fail" />,
    },
    [ACTION_SEQUENCE_START]: {
        icon: 'play',
        title: <Message message="accounting:actions.actionSequence.start" />,
    },

};


export const parseUserActivities = beActivitiesArray => {
    const result = [];
    if (!beActivitiesArray) {
        return result;
    }
    beActivitiesArray.map(beActivity => {
        result.push({
            title: activityParser[beActivity.notes]?.title || beActivity.notes,
            id: beActivity._id,
            icon: activityParser[beActivity.notes]?.icon,
            date: beActivity.timestamp,
        });
    });
    return result;
};
