/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { InputFile } from '~frontendComponents/Generic/index.js';
import { getProxyTlsClientGenerateCertificate, setGlcfgProxyFiles,
    getProxyTlsClientGenerateCertificateSchema } from '~frontendDucks/hlcfgEditor/index.js';
import { TLS_FILE_CA_CERT, TLS_FILE_CA_PRIV_KEY } from '~frontendConstants/index.js';
import { uploadFileRequest } from '~frontendDucks/uploadFile/index.js';
import {
    isInternallyManagedCertificateLocator,
    isStaticDefaultUploadLocator,
} from '~sharedLib/resourceLocatorUtils.ts';
import { hlcfgStringPathGet } from '~frontendLib/hlcfg/utils.ts';


@withTranslation()
@connect(
    state => ({
        files: getProxyTlsClientGenerateCertificate(state),
        schema: getProxyTlsClientGenerateCertificateSchema(state),
    }),
    {
        setValue: setGlcfgProxyFiles,
        uploadFile: uploadFileRequest,


    }
)
class TlsInspection extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            schema: PropTypes.object,
            setValue: PropTypes.func,
            files: PropTypes.object,
            uploadFile: PropTypes.func,
        };
    }

    setFile = (value, props) => {
        const { setValue } = this.props;
        setValue({ key: props[0], value });
    };


    uploadFile = ({ value, name }) => {
        const { setValue, uploadFile } = this.props;
        const isNonUpload = isStaticDefaultUploadLocator(value) || isInternallyManagedCertificateLocator(value);
        setValue({ value: isNonUpload ? value : undefined, key: name });
        if (value instanceof File) {
            uploadFile({ value, funcToCall: this.setFile, props: [ name ] });
        }
    };

    render() {
        const { t, files, schema } = this.props;
        return (
            <MDBCard>
                <MDBCardTitle>
                    {t('proxy:proxy.tlsInspection.title')}
                </MDBCardTitle>
                <MDBCardBody>
                    <MDBRow>
                        {[ { type: TLS_FILE_CA_CERT },
                            { type: TLS_FILE_CA_PRIV_KEY } ].map(key => (
                            <MDBCol
                                key={key.type}
                                lg="6"
                                md="12"
                                sm="12"
                            >
                                <InputFile
                                    className="license__upload"
                                    fakeFile={files[key.type]}
                                    hlcfgPath={[
                                        hlcfgStringPathGet.protection.proxy.tls.client.generateCertificate.getPath(),
                                        key.type
                                    ].join('.')}
                                    id={key.type}
                                    label={t(`proxy:files.${key.type}.title`)}
                                    message={t(`proxy:files.${key.type}.desc`)}
                                    name={name}
                                    onChange={({ value }) => this.uploadFile({ value, name: key.type })}
                                    renderId={key.type}
                                    schema={schema[key.type]}
                                />
                            </MDBCol>
                        ))}

                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default TlsInspection;
