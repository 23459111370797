/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardTitle, MDBCardBody, MDBCol, MDBRow, MDBBtn } from 'mdbreact';
import moment from 'moment';

import { Input, InputGroup, Checkbox, Switch, RadioList, Select, ToggleBox,
    InputTime } from '~frontendComponents/Generic/index.js';
import { createNotification } from '~frontendLib/reactUtils.js';
import SocketIOExample from '~frontendComponents/Examples/SocketIOExample.js';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';
import { UserSettingsExample } from '~frontendScenes/Develop/scenes/MasterComponents/UserSettingsExample.tsx';
import { QrCodeImage } from '~frontendComponents/QrCodeImage.tsx';
import { createOtpAuthUrl } from '~frontendLib/urlUtils.js';
import { NegatableNetaddrListSelect } from '~frontendComponents/Generic/SelectV2/NetaddrSelect.tsx';

import kernunLogoWhite from '../../../../img/svg/kernun-logo-white.svg';
import kernunLogoBlack from '../../../../img/svg/kernun-logo-black.svg';
import { LogDataExample } from './LogDataExample.tsx';


const dataRadioList = [
    {
        id: '1',
        label: 'A - default radio input',
        value: 'Radio 1 value',
    },
    {
        id: '2',
        label: `B - label of radio with some very long text which continues
                to end of line and more and more and more and more and more and more`,
        value: 'Radio 2 value',
    },
    {
        id: '3',
        label: 'C - disabled option default checked',
        value: 'Radio 3 value',
        disabled: true,
    },
];

const dataMessageList = [
    'Some message for input',
    `Long message with very long text which continues
        to end of line and more and more and more and more and more and more`,
    'Just message',
];

const selectOptionsList = [
    {
        id: '1000',
        label: 'Option 1',
        value: '1',
    },
    {
        id: '2',
        label: 'Option 2',
        value: '2',
        disabled: true,
    },
    {
        id: '3000',
        label: 'Option 3',
        value: '3',
    },
    {
        id: '4000',
        label: 'Option 4',
        value: '4',
    }
];

const negatableSchema = {
    'x-negatableNetaddrList': {
        ip4: true,
        cannotBeNetworkAddress: true
    }
};

@withTranslation()
class MasterComponents extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            masterComponentCheckboxId3: true,
            masterComponentCheckboxId4: true,
            masterComponentSwitchId1: true,
            masterComponentSwitchId2: true,
        };
    }

    onClickEvent = ({ value, id, name }) => {
        this.setState({
            value,
            id,
            name
        });
        // console.log('value', value, 'id', id, 'name', name);
    };

    onChangeEvent = ({ value, id }) => {
        this.setState({
            [id]: value,
        });
        //  console.log('value', value, 'id', id, 'name', name);
    };

    handleCheckboxChange = ({ checked, id }) => {
        this.setState({
            checked,
            id,
            name
        });
        //  console.log('checked', checked, 'value', value, 'id', id);

        this.setState({
            [id]: checked,
        });
    };

    handleSwitchChange = ({ value, id }) => {
        //  console.log('checked', checked, 'value', value, 'id', id);

        this.setState({
            [id]: value,
            value
        });
    };

    render() {
        const { t } = this.props;
        const { masterComponentSelectId04, masterComponentSelectId05, masterComponentSelectId06 } = this.state;
        return (
            <section>
                <MDBRow  className="m-5">

                    <MDBCol
                        sm="12"
                    >

                        <MDBCard>
                            <MDBCardTitle>
                        Input Component
                            </MDBCardTitle>
                            <MDBCardBody>

                                <MDBRow>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <InputTime
                                            id="masterComponentInputId1"
                                            label="Default input"
                                            value={moment()}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            className="mockForm__field"
                                            id="masterComponentInputId1"
                                            label="Default input"
                                            message="Test message with default text"
                                            onChange={this.onChangeEvent}
                                            value=""
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            error={dataMessageList}
                                            id="masterComponentInputId2"
                                            label="Default input filled"
                                            onChange={this.onChangeEvent}
                                            type="number"
                                            value="2"
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            className="mockForm__field"
                                            disabled
                                            id="masterComponentInputId3"
                                            label="Disabled input"
                                            onChange={this.onChangeEvent}
                                            value=""
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            disabled
                                            id="masterComponentInputId4"
                                            label="Disabled input filled"
                                            onChange={this.onChangeEvent}
                                            outline
                                            value="Filled Value"
                                        />
                                    </MDBCol>

                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            className="mockForm__field"
                                            id="masterComponentInputId5"
                                            label="Default with placeholder"
                                            loading
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            value=""
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            className="mockForm__field"
                                            id="masterComponentInputId6"
                                            label="Filled with placeholder"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            type="number"
                                            value={2}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            className="mockForm__field"
                                            disabled
                                            id="masterComponentInputId7"
                                            label="Disabled required with placeholder"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            value=""
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            className="mockForm__field"
                                            disabled
                                            id="masterComponentInputId8"
                                            label="Filled & disabled with placeholder"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            value="Filled Value"
                                        />
                                    </MDBCol>

                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            className="mockForm__field"
                                            id="masterComponentInputId9"
                                            label="Required with placeholder"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            value=""
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            id="masterComponentInputId10"
                                            label="Required filled"
                                            name="masterComponentInputName10"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            type="number"
                                            value={2}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            id="masterComponentInputId11"
                                            label="Required no value"
                                            name="masterComponentInputName11"
                                            onChange={this.onChangeEvent}
                                            required
                                            value=""
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            disabled
                                            id="masterComponentInputId12"
                                            label="Required disabled"
                                            name="masterComponentInputName12"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            value="Filled Value"
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            className="mockForm__field"
                                            id="masterComponentInputId13"
                                            label="Textarea with placeholder"
                                            name="masterComponentInputName13"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder in textarea which has 5 rows"
                                            rows={5}
                                            type="textarea"
                                            value=""
                                        />
                                    </MDBCol>

                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            id="masterComponentInputId14"
                                            label="Textarea default filled"
                                            name="masterComponentInputName14"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            rows={3}
                                            type="textarea"
                                            value="Textarea with filled text inside 3 rows"
                                        />
                                    </MDBCol>

                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            disabled
                                            id="masterComponentInputId15"
                                            label="Textarea required disabled with placeholder"
                                            name="masterComponentInputName15"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            rows={3}
                                            type="textarea"
                                            value=""
                                        />
                                    </MDBCol>

                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            disabled
                                            id="masterComponentInputId16"
                                            label="Textarea filled disabled"
                                            name="masterComponentInputName16"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            rows={3}
                                            type="textarea"
                                            value="Textarea with filled text inside 3 rows"
                                        />
                                    </MDBCol>

                                </MDBRow>

                                <MDBRow>
                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            className="mockForm__field"
                                            id="masterComponentInputId17"
                                            label="Textarea with placeholder"
                                            name="masterComponentInputName17"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder in textarea which has 5 rows"
                                            rows={5}
                                            type="textarea"
                                            value=""
                                            warning={dataMessageList}
                                        />
                                    </MDBCol>

                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            id="masterComponentInputId18"
                                            label="Textarea success filled"
                                            name="masterComponentInputName18"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            rows={3}
                                            success="Some success text message"
                                            type="textarea"
                                            value="Textarea with filled text inside 3 rows"
                                        />
                                    </MDBCol>

                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            error="Some error message text"
                                            id="masterComponentInputId19"
                                            label="Textarea required with error"
                                            name="masterComponentInputName19"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            rows={3}
                                            type="textarea"
                                            value=""
                                        />
                                    </MDBCol>

                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            disabled
                                            id="masterComponentInputId20"
                                            label="Textarea filled disabled"
                                            name="masterComponentInputName20"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            rows={3}
                                            type="textarea"
                                            value="Textarea with filled text inside 3 rows"
                                        />
                                    </MDBCol>

                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            id="masterComponentInputId21"
                                            label="Textarea required with tooltip"
                                            name="masterComponentInputName21"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            rows={3}
                                            tooltip={t('components:MasterComponents.Tooltip1')}
                                            type="textarea"
                                            value=""
                                        />
                                    </MDBCol>

                                    <MDBCol
                                        lg="6"
                                        xl="3"
                                    >
                                        <Input
                                            autoComplete="username"
                                            className="mockForm__field"
                                            error="Some error message text"
                                            id="masterComponentInputId20"
                                            label="Textarea filled with tooltip in error"
                                            loading
                                            name="masterComponentInputName20"
                                            onChange={this.onChangeEvent}
                                            placeholder="Some placeholder"
                                            required
                                            rows={3}
                                            tooltip={t('components:MasterComponents.Tooltip2')}
                                            type="textarea"
                                            value="Textarea with filled text inside 3 rows"
                                        />
                                    </MDBCol>

                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                </MDBRow>


                <MDBRow className="m-5">
                    <MDBCol
                        className="mb-md-5"
                        lg="4"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                Checkbox
                            </MDBCardTitle>
                            <MDBCardBody>
                                <Checkbox
                                    checked={this.state.masterComponentCheckboxId1}
                                    className="mockForm__field"
                                    id="masterComponentCheckboxId1"
                                    label="Checkbox"
                                    onChange={this.handleCheckboxChange}
                                />
                                <Checkbox
                                    checked={this.state.masterComponentCheckboxId2}
                                    className="mockForm__field"
                                    disabled
                                    id="masterComponentCheckboxId2"
                                    label="Checkbox disabled"
                                    onChange={this.handleCheckboxChange}
                                />
                                <Checkbox
                                    checked={this.state.masterComponentCheckboxId3}
                                    className="mockForm__field"
                                    id="masterComponentCheckboxId3"
                                    label={`Checkbox checked with very very long long
                                            label to more than 2 or 3 lines and more and more more`}
                                    onChange={this.handleCheckboxChange}
                                />
                                <Checkbox
                                    checked={this.state.masterComponentCheckboxId4}
                                    className="mockForm__field"
                                    disabled
                                    id="masterComponentCheckboxId3"
                                    label="Checkbox checked disabled"
                                    onChange={this.handleCheckboxChange}
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol
                        className="mb-md-5"
                        lg="4"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                Radio List
                            </MDBCardTitle>
                            <MDBCardBody>
                                <RadioList
                                    className="mockForm__radioList"
                                    data={dataRadioList}
                                    name="masterComponentRadioGroup2"
                                    onChange={this.onChangeEvent}
                                    selectedOptionId="3"
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol
                        className="mb-md-5"
                        lg="4"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                               Notifikace
                            </MDBCardTitle>
                            <MDBCardBody>
                                <MDBBtn onClick={() =>
                                    createNotification({ title: 'Success',
                                        desc: 'Long message about how action was successful', type: 'success' })}
                                > Success
                                </MDBBtn>
                                <MDBBtn onClick={() =>
                                    createNotification({ title: 'Danger',
                                        desc: 'Long message about how action failed ', type: 'danger' })}
                                > Alert
                                </MDBBtn>
                                <MDBBtn onClick={() =>
                                    createNotification({ title: 'Warning',
                                        desc: 'Long message about some warning that user should know',
                                        type: 'warning' })}
                                > Warning
                                </MDBBtn>
                                <MDBBtn onClick={() =>
                                    createNotification({ title: 'Info',
                                        desc: 'Long message about some informations', type: 'info' })}
                                > Info
                                </MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol
                        className="mb-md-5"
                        lg="4"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                Switch
                            </MDBCardTitle>
                            <MDBCardBody>
                                <Switch
                                    checked={this.state.masterComponentSwitchId1}
                                    className="mockForm__field"
                                    id="masterComponentSwitchId1"
                                    label="Switch - default is ON"
                                    onChange={this.handleSwitchChange}
                                />
                                <Switch
                                    checked={this.state.masterComponentSwitchId2}
                                    className="mockForm__field"
                                    disabled
                                    id="masterComponentSwitchId2"
                                    label="Switch - default ON disabled"
                                    onChange={this.handleSwitchChange}
                                />
                                <Switch
                                    align="right"
                                    checked={this.state.masterComponentSwitchId3}
                                    className="mockForm__field"
                                    id="masterComponentSwitchId3"
                                    label="Switch align right"
                                    onChange={this.handleSwitchChange}
                                />
                                <Switch
                                    align="center"
                                    checked={this.state.masterComponentSwitchId4}
                                    className="mockForm__field"
                                    disabled
                                    id="masterComponentSwitchId4"
                                    label="Switch align center disabled"
                                    onChange={this.handleSwitchChange}
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>


                    <MDBCol
                        className="mb-md-5"
                        lg="6"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                Input Group
                            </MDBCardTitle>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol
                                        lg="12"
                                        xl="12"
                                    >
                                        <InputGroup
                                            className="mockForm__field"
                                            icon="power"
                                            id="masterComponentInputGroupId01"
                                            label="Default inputGroup"
                                            onChange={this.onChangeEvent}
                                            onClick={this.onClickEvent}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="12"
                                        xl="12"
                                    >
                                        <InputGroup
                                            className="mockForm__field"
                                            icon="reload"
                                            id="masterComponentInputGroupId02"
                                            label="Required filled"
                                            onChange={this.onChangeEvent}
                                            onClick={this.onClickEvent}
                                            placeholder="Some placeholder"
                                            required
                                            value={2}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="12"
                                        xl="12"
                                    >
                                        <InputGroup
                                            className="mockForm__field"
                                            icon="reload"
                                            id="masterComponentInputGroupId02"
                                            label="Required placeholder"
                                            onChange={this.onChangeEvent}
                                            onClick={this.onClickEvent}
                                            placeholder="Some placeholder"
                                            required
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        lg="12"
                                        xl="12"
                                    >
                                        <InputGroup
                                            className="mockForm__field"
                                            disabled
                                            icon="account"
                                            id="masterComponentInputGroupId02"
                                            label="Required disabled"
                                            onChange={this.onChangeEvent}
                                            onClick={this.onClickEvent}
                                            placeholder="Some placeholder"
                                            required
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol
                        className="mb-md-5"
                        lg="6"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                Select
                            </MDBCardTitle>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol
                                        sm="12"
                                    >
                                        <Select
                                            className="mockForm__field"
                                            id="masterComponentSelectId01"
                                            label="Default select"
                                            name="masterComponentSelectName01"
                                            onChange={this.onChangeEvent}
                                            options={selectOptionsList}
                                            placeholder="Choose some option"
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        sm="12"
                                    >
                                        <Select
                                            className="mockForm__field"
                                            id="masterComponentSelectId02"
                                            label="Required select validating"
                                            loading
                                            name="masterComponentSelectName02"
                                            onChange={this.onChangeEvent}
                                            options={selectOptionsList}
                                            required
                                            value="1"
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        sm="12"
                                    >
                                        <Select
                                            className="mockForm__field"
                                            error="Some error message text"
                                            id="masterComponentSelectId03"
                                            label="Error required select"
                                            loading
                                            name="masterComponentSelectName03"
                                            onChange={this.onChangeEvent}
                                            options={selectOptionsList}
                                            required
                                            tooltip={t('components:MasterComponents.Tooltip1')}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        sm="12"
                                    >
                                        <Select
                                            className="mockForm__field"
                                            id="masterComponentSelectId04"

                                            label="Warning with search"
                                            name="masterComponentSelectName04"
                                            onChange={this.onChangeEvent}
                                            options={selectOptionsList}
                                            placeholder="Choose some option"
                                            value="3"
                                            warning={dataMessageList}
                                        />
                                    </MDBCol>
                                    <MDBCol
                                        sm="12"
                                    >
                                        <Select
                                            className="mockForm__field"
                                            disabled
                                            id="masterComponentSelectId05"
                                            label="Disabled select"
                                            name="masterComponentSelectName05"
                                            onChange={this.onChangeEvent}
                                            options={selectOptionsList}
                                            placeholder="Some choosed option"
                                            value="4"
                                        />

                                    </MDBCol>
                                    <MDBCol
                                        sm="12"
                                    >
                                        <Select
                                            className="mockForm__field"
                                            disabled
                                            id="masterComponentSelectId05"
                                            label="Disabled no value"
                                            name="masterComponentSelectName05"
                                            onChange={this.onChangeEvent}
                                            options={selectOptionsList}
                                        />

                                    </MDBCol>

                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol
                        className="mb-md-5"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                ToggleBox
                            </MDBCardTitle>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol>
                                        <ToggleBox
                                            description="some text"
                                            isOpen
                                            label="SomeLabel"
                                            onClick={() => null}
                                            svg={kernunLogoWhite}
                                        />
                                    </MDBCol>
                                    <MDBCol>
                                        <ToggleBox
                                            blockOpening
                                            description="Long message with very long text which continues
                                            to end of line and more and more and more and more and more and more"
                                            isActive
                                            isOpen
                                            label="SomeLabel"
                                            onClick={() => null}
                                            svg={kernunLogoBlack}
                                        />
                                    </MDBCol>
                                    <MDBCol>
                                        <ToggleBox
                                            description="Long message with very long text which continues
                                            to end of line and more and more and more and more and more and more"
                                            isActive
                                            label="SomeLabel"
                                            onClick={() => null}
                                            svg={kernunLogoBlack}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

                <MDBRow  className="m-5">
                    <MDBCol
                        className="mb-md-5"
                        lg="4"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                SocketIOExample
                            </MDBCardTitle>
                            <MDBCardBody>
                                <SocketIOExample />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol
                        className="mb-md-5"
                        lg="4"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                Select
                            </MDBCardTitle>
                            <MDBCardBody>
                                <Select
                                    id="masterComponentSelectId04"
                                    isCreatable
                                    isMulti

                                    label="Negatable input"
                                    name="masterComponentSelectName02"
                                    onChange={this.onChangeEvent}
                                    placeholder="Jakakoliv"
                                    schema={negatableSchema}
                                    value={stringifyAddress(masterComponentSelectId04)}
                                />
                                <Select
                                    id="masterComponentSelectId05"
                                    isCreatable
                                    isMulti

                                    label="Without placeholder"
                                    name="masterComponentSelectName02"
                                    onChange={this.onChangeEvent}
                                    schema={negatableSchema}
                                    value={stringifyAddress(masterComponentSelectId05)}
                                />
                                <Select
                                    id="masterComponentSelectId06"
                                    isCreatable
                                    isMulti

                                    name="masterComponentSelectName02"
                                    onChange={this.onChangeEvent}
                                    schema={negatableSchema}
                                    value={stringifyAddress(masterComponentSelectId06)}
                                />
                                {JSON.stringify(masterComponentSelectId04,
                                    masterComponentSelectId05, masterComponentSelectId06)}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol
                        className="mb-md-5"
                        lg="4"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                User settings example
                            </MDBCardTitle>
                            <MDBCardBody>
                                <UserSettingsExample />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol
                        className="mb-md-5"
                        lg="4"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                Qr Code example
                            </MDBCardTitle>
                            <MDBCardBody>
                                <QrCodeImage
                                    filename="QrCode.png"
                                    loading={false}
                                    scale={6}
                                    url={createOtpAuthUrl({
                                        label: 'label', secret: 'secret', issuer: 'issuer'
                                    })}
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>


                </MDBRow>
                <MDBRow className="m-5">
                    <MDBCol
                        className="mb-md-5"
                        lg="4"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                Log example
                            </MDBCardTitle>
                            <MDBCardBody>
                                <LogDataExample />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol
                        className="mb-md-5"
                        lg="4"
                        md="6"
                        xl="3"
                    >
                        <MDBCard>
                            <MDBCardTitle>
                                Negatable Netaddr SelectV2 example
                            </MDBCardTitle>
                            <MDBCardBody>
                                <NegatableExample />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

            </section>
        );
    }
}
const NegatableExample = () => {
    const [ value, setValue ] = useState(undefined);
    return (
        <NegatableNetaddrListSelect
            netaddrType={{ ip4: true, optionalMask: true }}
            onChange={setValue}
            value={value}
        />
    );

};

export default MasterComponents;
