/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import {  MDBBtn } from 'mdbreact';
import { Draggable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {  Icon, Select } from '~frontendComponents/Generic/index.js';
import {
    setNormalize,
    addToNormalize,
    duplicateFromNormalize,
    getTableSchema,
} from '~frontendDucks/hlcfgEditor/index.js';
import { getRoutesUuid, makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { ROUTE } from '~frontendConstants/index.js';
import RowMenuAndSwitch from '~frontendComponents/RowMenuAndSwitch.tsx';
import { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import RowDivider, { RowDividerTypes } from '~frontendComponents/RowDivider.tsx';
import { HlcfgTableItemSchema } from '~frontendRoot/types/externalTypes.ts';
import { useConstant } from '~frontendLib/hooks/defaultHooks.ts';

import { DhcpdServerContext } from '../../DhcpdService/index.ts';


const DhcpRouteRow = ({ dataIndex, uuid, spacing, search }: CreateRowPropsType) => {

    const { t } = useTranslation();
    const Dispatch = useDispatch();
    const { uuid: dhcpUuid } = useContext(DhcpdServerContext);


    const dhcpdRoutes = useMemo(getRoutesUuid, []);
    const getterSearch = useMemo(makeSelectSearchedTableItem, []);

    const data = useSelector(state => dhcpdRoutes(state, uuid));
    const schema: HlcfgTableItemSchema<'route'> = useSelector(state => getTableSchema(state, uuid));
    const matches: string[] = useSelector(state => getterSearch(state, uuid, search));

    const changeValue = useCallback(({ value, name }) => {
        Dispatch(setNormalize({ type: ROUTE, uuid, key: name, value }));
    }, [ Dispatch, uuid ]);

    const addRow = useCallback((addingAfter?: boolean) => {
        Dispatch(addToNormalize({
            type: 'route', //just one type
            addingAfter,
            uuidToAddBefore: uuid,
            key: 'routes',
            uuid: dhcpUuid,
            successText: 'widgets:routeTable.added',
        }));
    }, [ Dispatch, uuid, dhcpUuid ]);

    const handleAddRow = useCallback((event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
        event.preventDefault();
        addRow();
    }, [ addRow ]);

    const delRow = useCallback(() => {
        Dispatch(setNormalize({ uuid: dhcpUuid,
            value: uuid, key: 'routes' }));
    }, [ Dispatch, uuid, dhcpUuid ]);


    const duplicate = useCallback(() => {
        Dispatch(duplicateFromNormalize({
            uuid: uuid,
            parentKey: 'routes',
            parentUuid: dhcpUuid
        }));
    }, [ Dispatch, uuid, dhcpUuid ]);

    const  types  = useConstant<RowDividerTypes>([
        { addFunc: addRow, translation: 'widgets:routeTable.route' }
    ]);

    const columnMatches = [ 'destination', 'gateway' ];

    if (!data?.id) {
        return (
            <tr
                className="dataTableWidget__RowFirstRule"
                key="newRoute"
            >
                <td colSpan={1}></td>
                <td
                    className="dataTableWidget__RowFirstRuleRow"
                    colSpan={4}
                >
                    <div className="pl-2">
                        <h2>{t('widgets:routeTable.title')}</h2>
                        <p className="dataTableWidget__Rowtext">
                            {t('widgets:routeTable.desc1')}
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                onClick={handleAddRow}
                            >
                                <Icon name="plus" /> {t('widgets:routeTable.route')}
                            </MDBBtn>
                            {t('widgets:routeTable.desc2')}
                        </p>
                        <p>
                            {t('widgets:routeTable.desc3')}
                            <Icon
                                name="menu"
                                size="sm"
                            />
                            {t('widgets:routeTable.desc4')}

                        </p>
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            onClick={handleAddRow}

                        >
                            <Icon name="plus" /> {t('widgets:routeTable.route')}
                        </MDBBtn>
                    </div>
                </td>
            </tr>
        );
    }
    const {  destination, gateway, __off, fake, id } = data;
    return (
        <Draggable
            //it needs string
            draggableId={String(dataIndex)}
            index={dataIndex}
            isDragDisabled={fake}
            key={dataIndex}
        >
            {(provided) => (

                <>
                    <RowDivider
                        id={id}
                        length={8}
                        types={types}
                    />
                    <tr
                        className={classNames(
                            'dataTableWidget__Row',
                            'profiles__row',
                            { 'dataTableWidget__Row--disable': __off },
                            { 'dataTableWidget__Row--match': matches.some(
                                item => columnMatches.indexOf(item) >= 0
                            ) },
                        )}
                        key={dataIndex}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { 'dataTableWidget__cell--match': matches.some(
                                    item => columnMatches.indexOf(item) >= 0
                                ) },
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                            {...provided.dragHandleProps}
                        >
                            <Icon
                                name={fake ? 'account' : 'drag'}
                                size="sm"
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                disabled={__off}
                                fake={fake}
                                id={'routeTableDestination' +  uuid}
                                isCreatable
                                isMulti

                                name="destination"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={changeValue}
                                schema={schema?.destination}
                                value={destination}
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                disabled={__off}
                                fake={fake}
                                id={'routeTableGateway' +  uuid}
                                isCreatable
                                isMulti

                                name="gateway"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={changeValue}
                                schema={schema?.gateway}
                                value={gateway}
                            />
                        </td>
                        <RowMenuAndSwitch
                            __off={__off}
                            copyFunc={duplicate}
                            deleteFunc={delRow}
                            id={'dhcpdRoute' + uuid}
                            menu={!fake}
                            onChange={changeValue}
                            spacing={spacing}
                        />
                    </tr>
                    <RowDivider
                        after
                        id={id}
                        length={8}
                        types={types}
                    />
                </>
            )}
        </Draggable>
    );
};

export default DhcpRouteRow;
