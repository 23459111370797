/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { MDBCol, MDBRow, } from 'mdbreact';


import { Scene } from '../../../../components/Scene/index.js';
import { AuthenticationLogin, AuthenticationCache } from './components/index.js';


@withTranslation()
class AuthenticationService extends Component {
    render() {
        return (
            <Scene>
                <MDBRow>
                    <MDBCol
                        className="mb-4"
                        lg="5"
                        md="5"
                        sm="5"
                    >
                        <AuthenticationLogin />
                    </MDBCol>
                    <MDBCol
                        className="mb-4"
                        lg="5"
                        md="5"
                        sm="5"
                    >
                        <AuthenticationCache />
                    </MDBCol>
                    <MDBCol
                        className="mb-4"
                        lg="7"
                        md="7"
                        sm="7"
                    />
                </MDBRow>
            </Scene>
        );
    }
}

export default  AuthenticationService;
