/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBBtn, MDBModalFooter } from 'mdbreact';

import { Icon, Modal } from '~frontendComponents/Generic/index.js';
import { getModalState, getSpecialValues, setModalState } from '~frontendDucks/modals/index.js';
import { CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE } from '~frontendConstants/constants.ts';
import {
    getHealthIssues,
    getIsLoadingHealthIssues,
    getMyNode,
    getOtherNode,
    isNodeUnreachable,
} from '~frontendRoot/ducks/clusterSetup/index.js';
import Message from '~frontendComponents/Message/index.js';
import { NODE_A_ID, NODE_B_ID } from '~sharedConstants/index.ts';
import { NODE_SELF } from '~commonLib/constants.ts';
import { cfgActivationRequest } from '~frontendRoot/ducks/cfgActivation/index.js';

import { ConfirmationClusterActivation } from '../ChangesConfirmationModal/components/index.js';


const ClusterSyncOrSequenceJustForOne  = () => {
    const healthIssues = useSelector<any, any[]>(getHealthIssues);
    const nodeUnreachable = useSelector<any, boolean>(isNodeUnreachable);
    const isLoadingHealthIssues = useSelector<any, boolean>(getIsLoadingHealthIssues);
    const otherNode = useSelector<any, string>(getOtherNode);
    const myNode = useSelector<any, string>(getMyNode);
    const unsyncable = !healthIssues.length || nodeUnreachable ||
                    healthIssues.some(item => item.canNotBeAutoSynced) ||
                    isLoadingHealthIssues;
    const modalState = useSelector((state) => getModalState(state, CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE));

    const specialValues = useSelector(getSpecialValues);

    const dispatch = useDispatch();

    const closeModal = useCallback(() => {
        dispatch(setModalState({ modal: CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE,
            value: false, clearValues: true }));
    }, [ dispatch ]);

    const sync =  useCallback(() => {
        dispatch(cfgActivationRequest({ isOpen: true, nodes: [ otherNode, myNode ] }));
        closeModal();
    }, [ dispatch, otherNode, myNode, closeModal ]);

    const doAnyway = useCallback(event => {
        event.preventDefault();
        if (healthIssues.length) {
            specialValues.action({ nodes: [ NODE_SELF ] });
        } else {
            specialValues.action({ nodes: [ NODE_A_ID, NODE_B_ID ] });
        }
        closeModal();
    }, [ closeModal, healthIssues, specialValues ]);
    return (
        <Modal
            body={
                <ConfirmationClusterActivation
                    open={modalState}
                    type={specialValues.sequence}
                />
            }
            bodyText="widgets:cluster.needSync.desc"
            exitHandle={closeModal}
            headerClose
            headerText="widgets:cluster.needSync.title"
            modalOpen={modalState}
            position="top-right"
        >
            <MDBModalFooter>
                <MDBBtn
                    color="secondary"
                    onClick={doAnyway}
                >
                    <Message
                        message="modalWindows:ChangesConfirmationModal.doAction"
                    />
                </MDBBtn>
                <MDBBtn
                    color="primary"
                    disabled={unsyncable}
                    onClick={sync}
                >
                    <Message
                        message="modalWindows:ChangesConfirmationModal.needSync.title"
                    />
                    {isLoadingHealthIssues ?
                        <Icon
                            className="ml-1"
                            name="loading"
                        /> : null}
                </MDBBtn>
            </MDBModalFooter>
        </Modal>
    );
};

export default ClusterSyncOrSequenceJustForOne;
