/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getGlcfgSchemaHook, getGlcfgValueHook, setGlcfgValueScalar } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { GUI_PUB_KEY, GUI_PRIV_KEY } from '~frontendRoot/constants/index.js';
import { isInternallyManagedCertificateLocator, isStaticDefaultUploadLocator } from '~sharedLib/resourceLocatorUtils.ts';
import { uploadFileRequest } from '~frontendRoot/ducks/uploadFile/index.js';
import { Input } from '~frontendRoot/components/Generic/index.js';


const GuiKeys = () => {
    const guiPubkey = useSelector(getGlcfgValueHook(GUI_PUB_KEY));
    const guiPubkeySchema = useSelector(getGlcfgSchemaHook(GUI_PUB_KEY));

    const guiPrivkey = useSelector(getGlcfgValueHook(GUI_PRIV_KEY));
    const guiPrivkeySchema = useSelector(getGlcfgSchemaHook(GUI_PUB_KEY));

    const dispatch = useDispatch();
    const setFile = (value, props) => {
        dispatch(setGlcfgValueScalar(props[0], value));
    };


    const uploadFile = ({ value, id }) => {
        const isNonUpload = isStaticDefaultUploadLocator(value) || isInternallyManagedCertificateLocator(value);
        dispatch(setGlcfgValueScalar(id, isNonUpload ? value : undefined));
        if (value instanceof File) {
            dispatch(uploadFileRequest({ value, funcToCall: setFile, props: [ id ] }));
        }
    };

    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardHeader>
                {t('widgets:System.AdminInterface.guiKeys.title')}
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>

                    <MDBCol>
                        <Input
                            className="guiPubKey__upload"
                            fakeFile={guiPubkey?.name}
                            id={'guiPubkey'}
                            label={t('differs:system.guiPubkey')}
                            maxSize={2000000}
                            name={'guiPubKeyName'}
                            onChange={uploadFile}
                            outline={false}
                            schema={guiPubkeySchema}
                            type="file"
                        />
                    </MDBCol>
                    <MDBCol>
                        <Input
                            className="guiPrivkey__upload"
                            fakeFile={guiPrivkey?.name}
                            id={'guiPrivkey'}
                            label={t('differs:system.guiPrivkey')}
                            maxSize={2000000}
                            name={'guiPrivkeyName'}
                            onChange={uploadFile}
                            outline={false}
                            schema={guiPrivkeySchema}
                            type="file"
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default GuiKeys;
