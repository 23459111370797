/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    ACTION_STEP_COMPONENT_ACTION,
    getComponentAction, getComponentId, getComponentType, getError,
    getIsAborted, getIsConfirmed, getIsLoading, getIsOpen, getProgress,
    getIsReplaceMaster, componentActionSequenceClose, componentActionSequenceConfirm,
    componentActionSequenceConfirmReplace
} from '~frontendDucks/systemComponentAction/systemComponentAction.ts';
import { typeApiError, typeProgressArray } from '~frontendTypes/index.js';

import { ActionSequenceModal } from '../../../ActionSequence/index.js';
import Message from '../../../Message/index.js';
import actionStepComponentAction from './lib/actionStepComponentAction.js';
import SystemComponentActionSequenceTitle from './components/SystemComponentActionSequenceTitle.js';


const SystemComponentActionAbortedTitle = () =>
    <Message message="actions:action.sequence.SystemComponentAction.aborted.title" />;

const SystemComponentActionConfirmTitle = ({ isReplaceMaster }) => {
    if (isReplaceMaster) {
        return (
            <Message message="widgets:cluster.modal.replace.body" />
        );
    } else {
        return (
            <Message message="actions:action.sequence.SystemComponentAction.confirm.title" />
        );
    }
};

const SystemComponentActionDoneTitle = ({ isReplaceMaster }) => {
    if (isReplaceMaster) {
        return (
            <Message message="widgets:cluster.modal.replace.done" />
        );
    } else {
        return (
            <Message message="actions:action.sequence.SystemComponentAction.done.title" />
        );
    }
};


const SystemComponentActionErrorTitle = ({ isReplaceMaster }) => {
    if (isReplaceMaster) {
        return (
            <Message message="widgets:cluster.modal.replace.error" />
        );
    } else {
        return (
            <Message message="actions:action.sequence.SystemComponentAction.error.title" />

        );
    }
};

const SystemComponentActionLoadingTitle =  ({ isReplaceMaster }) => {
    if (isReplaceMaster) {
        return (
            <Message message="widgets:cluster.modal.replace.loading" />
        );
    } else {
        return (
            <Message message="actions:action.sequence.SystemComponentAction.loading.title" />
        );
    }
};

const STEPS = {
    [ACTION_STEP_COMPONENT_ACTION]: actionStepComponentAction,
};

const SystemComponentActionModalBase = ({
    componentAction, componentId, componentType,
    doComponentActionSequenceClose, doComponentActionSequenceConfirm,
    error, isAborted, isConfirmed, isLoading, isOpen, progress, isReplaceMaster,
    doComponentActionSequenceConfirmReplace
}) => {
    return (
        <ActionSequenceModal
            error={error}
            isAborted={isAborted}
            isConfirmed={isConfirmed}
            isLoading={isLoading}
            isOpen={isOpen}
            maskClosable={false}
            onClose={doComponentActionSequenceClose}
            onConfirm={() => isReplaceMaster ? doComponentActionSequenceConfirmReplace() :
                doComponentActionSequenceConfirm()}
            progress={progress}
            sequenceAbortedTitle={<SystemComponentActionAbortedTitle />}
            sequenceConfirmTitle={<SystemComponentActionConfirmTitle isReplaceMaster={isReplaceMaster} />}
            sequenceDoneTitle={<SystemComponentActionDoneTitle isReplaceMaster={isReplaceMaster} />}
            sequenceErrorTitle={<SystemComponentActionErrorTitle isReplaceMaster={isReplaceMaster} />}
            sequenceLoadingTitle={<SystemComponentActionLoadingTitle isReplaceMaster={isReplaceMaster} />}
            sequenceTitle={(
                <SystemComponentActionSequenceTitle
                    componentAction={componentAction}
                    componentId={componentId}
                    componentType={componentType}
                    isReplaceMaster={isReplaceMaster}
                />
            )}
            steps={STEPS}
        />
    );};

SystemComponentActionModalBase.propTypes = {
    doComponentActionSequenceClose: PropTypes.func.isRequired,
    doComponentActionSequenceConfirm: PropTypes.func.isRequired,
    isAborted: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    error: typeApiError,
    progress: typeProgressArray.isRequired,
    componentAction: PropTypes.string.isRequired,
    componentId: PropTypes.string,
    componentType: PropTypes.string.isRequired,
    isReplaceMaster: PropTypes.bool,
    doComponentActionSequenceConfirmReplace: PropTypes.func,
};


const mapStateToProps = state => ({
    isOpen: getIsOpen(state),
    isLoading: getIsLoading(state),
    isAborted: getIsAborted(state),
    error: getError(state),
    progress: getProgress(state),
    isConfirmed: getIsConfirmed(state),
    componentType: getComponentType(state),
    componentId: getComponentId(state),
    componentAction: getComponentAction(state),
    isReplaceMaster: getIsReplaceMaster(state)
});

const mapDispatchToProps = {
    doComponentActionSequenceClose: componentActionSequenceClose,
    doComponentActionSequenceConfirm: componentActionSequenceConfirm,
    doComponentActionSequenceConfirmReplace: componentActionSequenceConfirmReplace,

};

const SystemComponentActionModal = connect(mapStateToProps, mapDispatchToProps)(SystemComponentActionModalBase);

export default SystemComponentActionModal;
