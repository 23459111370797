/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { HlcfgPath, TFunctionTranslatableArgs, TranslatableTArgs } from '~sharedLib/types.ts';
import { notEmpty } from '~commonLib/arrayUtils.ts';


export interface HlcfgVerificationItem {
    title: TFunctionTranslatableArgs,
    desc: TFunctionTranslatableArgs,
    hlcfgPaths: HlcfgPath[]
    options?: any,
    advancedDesc?: TFunctionTranslatableArgs
}

const deduplicateHlcfgPaths = (hlcfgPaths: AcceptedHlcfgPaths): HlcfgPath[] => {
    return Array.from(
        new Set(hlcfgPaths.filter(notEmpty).map(item => JSON.stringify(item))) as Iterable<string>,
        elem => JSON.parse(elem)
    );
};

/**
 * Helper to make verification error
 */
const createHlcfgVerificationError = (
    hlcfgPaths: AcceptedHlcfgPaths, title: TFunctionTranslatableArgs,
    desc: TFunctionTranslatableArgs,
    options?: any, advancedDesc?: TFunctionTranslatableArgs
): HlcfgVerificationItem => {
    return { title, desc, hlcfgPaths: deduplicateHlcfgPaths(hlcfgPaths), options, advancedDesc };
};

type AcceptedHlcfgPaths = (HlcfgPath|undefined)[];
/**
 * Simpler helper to make verification error
 */
export const makeHlcfgError = (
    hlcfgPaths: AcceptedHlcfgPaths, translationPath: string, translationArgs?: TranslatableTArgs
): HlcfgVerificationItem => ({
    title: translationArgs ? [ `${translationPath}.title`, translationArgs ] : [ `${translationPath}.title` ],
    desc: translationArgs ? [ `${translationPath}.desc`, translationArgs ] : [ `${translationPath}.desc` ],
    // sorting is not neccessary, but it is hard to handle inside tests if it is not there...
    hlcfgPaths: deduplicateHlcfgPaths([ hlcfgPaths ].flat().filter(notEmpty)).sort(),
});

export default createHlcfgVerificationError;
