/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

import { Scene } from '~frontendRoot/components/Scene/index.js';


const Dashboard = () =>
    (
        <Scene>
            <MDBRow>
                <MDBCol />
                <MDBCol />
                <MDBCol />
            </MDBRow>
        </Scene>
    );


export default Dashboard;
