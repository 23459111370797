/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


import {
    getGlcfgValue,
    setGlcfgValueScalar,
    getGlcfgSchema
} from '~frontendDucks/hlcfgEditor/index.js';
import { getCurrentUserName } from '~frontendDucks/userAuthentication/ducks/login.js';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice/index.js';
import { getHlcfgOffableIsEnabled } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';


@withTranslation()
@connect(
    state => ({
        suricataEnabled: getHlcfgOffableIsEnabled(state, it => it.protection.suricata.service),
        suricataInterfaces: getGlcfgValue(state, 'suricataInterfaces'),
        suricataInterfacesSchema: getGlcfgSchema(state, 'suricataInterfaces'),
        currentUserName: getCurrentUserName(state),
    }),
    {
        doSetValueScalar: setGlcfgValueScalar,

    }
)
class SuricataSettings extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            suricataEnabled: PropTypes.bool,
            doSetValueScalar: PropTypes.func,
            suricataInterfaces: PropTypes.array,
            suricataInterfacesSchema: PropTypes.object
        };
    }

    setValue = ({ value, id }) => {
        const { doSetValueScalar, } = this.props;
        doSetValueScalar(id, value);
    };

    render() {
        const { t, suricataEnabled, suricataInterfaces,
            suricataInterfacesSchema } = this.props;
        return (
            <MDBCard>
                <MDBCardTitle>
                    {t('ips:suricata.title')}
                </MDBCardTitle>
                <MDBCardBody>
                    <NetworkInterfaceDevice
                        disabled={!suricataEnabled}
                        id={'suricataInterfaces'}
                        iface={suricataInterfaces}
                        name={'interface'}
                        onChange={this.setValue}
                        paste={false}
                        schema={suricataInterfacesSchema}
                    />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default SuricataSettings;
