/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

import { Scene } from '~frontendComponents/Scene/index.js';

import NamedObjectNetaddr from './NamedObjectNetaddr.tsx';


class NamedObjectNetaddrScene extends Component {

    render() {
        return (
            <Scene>
                <MDBRow className="namedObjects__row">
                    <MDBCol>
                        <NamedObjectNetaddr isScalar />
                    </MDBCol>
                    <MDBCol>
                        <NamedObjectNetaddr />
                    </MDBCol>
                </MDBRow>
            </Scene>
        );
    }
}

export default NamedObjectNetaddrScene;
