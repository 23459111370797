/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * @file
 * This file contains API endpoints that are not used by Redux Saga.
 * Endpoints that are used by Redux Saga are located in directory '../ducks/'.
 */

import { default as Axios } from 'axios';


export const pingAddress = async address => {
    const { data } = await Axios.get(
        '/api/utils/ping',
        {
            params: {
                address,
            },
        }
    );
    return data;
};

export const startActionSequence = async (actionSequenceType, breakLock, axios) => {
    const { data } = await axios.post(
        `/api/actionSequence/start/${actionSequenceType}`,
        {},
        { params: { breakLock } }
    );
    return data;
};

export const endActionSequence = async (actionSequenceType, actionSequenceId, axios) => {
    const { data } = await axios.post(`/api/actionSequence/end/${actionSequenceType}/${actionSequenceId}`, {});
    return data;
};

export const replayStartActionSequence = async (actionSequenceType, actionSequenceId) => {
    const { data } = await Axios.post(`/api/actionSequence/replayStart/${actionSequenceType}/${actionSequenceId}`, {});
    return data;
};

export const replayEndActionSequence = async (actionSequenceType, actionSequenceId) => {
    const { data } = await Axios.post(`/api/actionSequence/replayEnd/${actionSequenceType}/${actionSequenceId}`, {});
    return data;
};
